import { createSlice } from "@reduxjs/toolkit";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "./authenticationSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  rRoomUsers: [],
  propertyUsers: [],
  customers: [],
};

const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearUserState: () => initialState,
    getRroomUsers: (state, { payload }) => {
      state.rRoomUsers = payload;
    },
    getPropertyUsers: (state, { payload }) => {
      state.propertyUsers = payload;
    },
    getCustomers: (state, { payload }) => {
      state.customers = payload;
    },
  },
});

export const { clearUserState, getRroomUsers, getPropertyUsers, getCustomers } =
  usersSlice.actions;

// Action creators are generated for each case reducer function
export const usersSeletor = (state) => state.users;

export default usersSlice.reducer;

export const getRroomUsersAction = () => {
  return async (dispatch) => {
    try {
      const res = await services.user.getRroomsUsersService();
      if (res.status === 200) {
        await dispatch(getRroomUsers(res?.data?.data));
      }
    } catch (error) {}
  };
};

export const getPropertyUsersAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await services.user.getPropertyUsersServiceByProperty(id);
      if (res.status === 200) {
        await dispatch(getPropertyUsers(res?.data?.result));
      }
    } catch (error) {}
  };
};

export const getCustomersAction = () => {
  return async (dispatch) => {
    try {
      const res = await services.user.getCustomersService();
      if (res.status === 200) {
        await dispatch(getCustomers(res.data?.data));
      }
    } catch (error) {}
  };
};
