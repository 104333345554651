import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import ReactGA from "react-ga4";

import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
const LaundryService = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
  }, [userDetail]);

  const [tableData, setTableData] = useState([]);
  const [showTables, setShowTables] = useState([]);
  const { AlertIcon } = Imagesdata || {};

  const [providers, setProviders] = useState([]);
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      property_id: userDetail?.propertyId,
      name: payload.laundryService,
      price: payload.laundryServicePrice,
      providerId: payload.provider,
    };

    try {
      let res = {};
      if (editMode) {
        const editPayload = {
          body: realPayload,
          id: selectedData?.id,
        };
        res = await services.globalService.updateLaundryServicesService(
          editPayload
        );
      } else {
        res = await services.globalService.createLaundryServicesService(
          realPayload
        );
      }
      if (res.status === 201 || res.status === 200) {
        reset();
        setShow(false);
        setEditMode(false);
        getDataHandler(userDetail?.propertyId);
        setSelectedData({});
      }
    } catch (error) {}
  };

  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);

    setValue("laundryService", data.name);
    setValue("laundryServicePrice", data.price);
    setValue("provider", data.providerId);
    setEditMode(true);
  };

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getLaundryServicesByIdService(
        id
      );
      const provider =
        await services.globalService.getLaundryProviderByIdService(id);

      if (res.status === 200) {
        setTableData(res.data.data);
        setProviders(provider.data.data);
      }
    } catch (error) {}
  };

  const deleteHandler = async (id) => {
    try {
      let res = {};
      res = await services.globalService.deleteLaundryServicesByIdService(id);
      if (res.status === 200) {
        getDataHandler(userDetail?.propertyId);
      }
    } catch (error) {}
  };
  const searchfilter = (text) => {
    let filter = [];
    if (text == 0) {
      filter = tableData;
    } else {
      filter = tableData?.filter((item) => item.providerId == text);
    }
    setShowTables(filter);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "laundry_service" });
    setShowTables(tableData);
  }, [tableData]);

  return (
    <Layout
      title="Laundry Service"
      openHandler={() => setShow(true)}
      type={true}
      searchfilter={searchfilter}
      optionData={providers}
    >
      <Table striped bordered hover className="common-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Service Provider</th>
            <th>Service Name</th>
            <th>Price(₹)</th>
            {userDetail?.Role?.canEdit == 1 &&
              userDetail?.Role?.canDelete == 1 && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {showTables.map((data, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{providers.find((i) => i.id == data.providerId)?.name}</td>
              <td>{data.name}</td>
              <td>₹{data.price}</td>
              {userDetail?.Role?.canEdit == 1 &&
                userDetail?.Role?.canDelete == 1 && (
                  <td>
                    {userDetail?.Role?.canEdit == 1 && (
                      <Button
                        variant="primary"
                        className="mr-2 btn-sm"
                        onClick={() => editHandler(data)}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    )}
                    {userDetail?.Role?.canDelete == 1 && (
                      <Button
                        variant="primary"
                        className=" btn-sm"
                        onClick={() => deleteHandler(data?.id)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </Button>
                    )}
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Laundry Service" : "Create Laundry Service"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <label>Select Provider</label>
                  <Controller
                    control={control}
                    name="provider"
                    render={({ field: { onChange, value } }) => (
                      <select onChange={onChange} value={value}>
                        <option selected value="" disabled>
                          Select Provider
                        </option>
                        {providers?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                            {/* {item?.providerCode +
                              ":-" +
                              item.name?.split(" ")[0]} */}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors?.provider?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"provider"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="laundryService"
                    text="Laundry Service Name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter laundry service"
                    field={"laundryService"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.laundryService?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"laundryService"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="laundryServicePrice"
                    text="Laundry Service Price(₹)"
                    type="text"
                    max={10}
                    min={1}
                    placeholder="Enter laundry service price(₹)"
                    field={"laundryServicePrice"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.laundryServicePrice?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"laundryServicePrice"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default LaundryService;
