import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import validation from "./validation";
import ImageUploadBox from "../../components/imageUploadBox";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";

import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { baseImgUrl } from "../../API/client/api";
import ReactGA from "react-ga4";

const MenuCard = () => {
  const [tableData, setTableData] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "kitchen_menu" });
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
  }, [userDetail]);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { handleSubmit, reset } = useForm({ ...validation, mode: "onSubmit" });

  const [imageData, setImageData] = useState([]);
  const imageHandler = (data) => {
    setImageData((preValue) => {
      return [...preValue, data];
    });
  };

  const onSubmit = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("property_id", userDetail?.propertyId);
    bodyFormData.append("status", "active");
    if (imageData.length) {
      imageData.map((data, key) => {
        if (key == 0) {
          bodyFormData.append(`menu_card`, data);
        } else {
          bodyFormData.append(`menu_card`, data);
        }
      });
    }

    try {
      let res = await services.globalService.createMenuCardService(
        bodyFormData
      );
      if (res.status === 201) {
        reset();
        setImageData([]);
        setShow(false);
        getDataHandler(userDetail?.propertyId);
      }
    } catch (error) {}
  };

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getMenuCardByIdService(id);
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  const [showImgPopupNew, setCencelImgPopup] = useState(false);
  const showImgPopup = () => {
    setCencelImgPopup(true);
  };

  const viewHandler = (data) => {
    showImgPopup();
    setSelectedData(data);
  };

  const deleteHandler = async (data) => {
    try {
      const res = await services.globalService.deleteMenuCardByIdService(
        data.id
      );
      if (res.status === 200) {
        getDataHandler(userDetail?.propertyId);
      }
    } catch (error) {}
  };

  return (
    <Layout title="Menu Card" openHandler={() => setShow(true)}>
      <Row>
        <Col xs={12}>
          <div className="categoryWrap menuCardWrap">
            <h3>Menu Card Details</h3>
            <ul className="d-flex">
              {tableData.map((data) => (
                <li>
                  <img
                    onClick={() => viewHandler(data)}
                    src={`${baseImgUrl}${data.menuCard}`}
                  />
                  {userDetail.Role?.canDelete == 1 && (
                    <div
                      className="dltIcon"
                      onClick={() => deleteHandler(data)}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload menu card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <ImageUploadBox imageHandler={imageHandler} />
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2"
                >
                  <Button className="width100" variant="primary" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      {/* show image popup */}
      <Modal
        show={showImgPopupNew}
        onHide={() => {
          setCencelImgPopup(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fullImgModal">
            <img alt="" src={`${baseImgUrl}${selectedData?.menuCard}`} />
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default MenuCard;
