import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import { getBunquetBookings } from "API/services/newServices";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDownloadExcel } from "react-export-table-to-excel";

const useBanquetBooking = () => {
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "bookingDetails",
    sheet: "bookingDetails",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { userDetail } = useSelector(authenticationSeletor);

  const { AlertIcon } = Imagesdata;

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);

  // states
  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [reportState, setReportState] = useState(false);

  const getDataHandler = async () => {
    const res = await getBunquetBookings(userDetail.propertyId);
    if (res) {
      setAllData(res);
      setTableData(res);
    }
  };
  const collectPaymentPress = (row) => {
    setShow(true);
    setSelected(row);
  };

  const edithandler = (item) => {
    addBooking(item);
  };

  const STORY_HEADERS = [
    {
      prop: "enquiryCode",
      title: "Enquiry Code",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "bookingCode",
      title: "Booking Id",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <Link to={`/banquet/banquet-bookings/details/${row.banquetBookingId}`}>
          {row.bookingCode}
        </Link>
      ),
    },
    {
      prop: "customerName",
      title: "Customer Name",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "mobile",
      title: "Mobile",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "email",
      title: "Email ID",
      isFilterable: true,
      isSortable: true,
    },

    {
      prop: "gst",
      title: "GST Number",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "pan",
      title: "Pan Number",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "address",
      title: "Address",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "remark",
      title: "Remark",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "companyName",
      title: "Company Name",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "contactPerson",
      title: "Contact Person",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "bookedBy",
      title: "Booked By",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "reserveBookingDate",
      title: "Booking Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.reserveBookingDate).format("DD MMM, YYYY"),
    },
    {
      prop: "menuName",
      title: "Menu Name",
      isSortable: true,
      isFilterable: true,
    },
    {
      prop: "createdAt",
      title: "Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.createdAt).format("DD MMM, YYYY"),
      cellProps: { className: "text-center" },
    },
    ...(!reportState
      ? [
          {
            prop: "action",
            title: "Action",
            isSortable: true,
            isFilterable: true,
            cellProps: { className: "text-center", style: { minWidth: 220 } },
            cell: (row) => (
              <>
                <Button
                  className="btn btn-sm mr-2"
                  onClick={() => edithandler(row)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                </Button>
                <Button
                  variant="success"
                  className="btn btn-sm mr-2"
                  onClick={() => {
                    navigate(`/Invoice`, {
                      state: {
                        ...row,
                        baseAmount: row.subTotal,
                        extra1: { type: [] },
                        extra2: { type: [] },
                        foodData: 0,
                        banquetData: true,
                      },
                    });
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-download" />
                </Button>
                {!!row.dueAmount ? (
                  <Button
                    className="btn btn-sm"
                    onClick={() => collectPaymentPress(row)}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-wallet" /> Collect
                    Payment
                  </Button>
                ) : (
                  <Button
                    variant="info"
                    className="btn btn-sm"
                    onClick={() =>
                      navigate("/Food-Invoice", {
                        state: { ...row, banquetData: true },
                      })
                    }
                  >
                    <FontAwesomeIcon icon="fa-solid fa-download" /> Payment
                    Invoice
                  </Button>
                )}
                {/* <Button variant="danger" className="btn btn-sm">
              <FontAwesomeIcon icon="fa-solid fa-trash" />
            </Button> */}
              </>
            ),
          },
        ]
      : []),
  ];
  const addBooking = (item = false) => {
    navigate(
      `/banquet/add-banquet-bookings/${item.banquetBookingId ?? ""}${
        item.banquetEnquiryId ? `?fromEnquiry=${item.banquetEnquiryId}` : ""
      }`
    );
  };

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setReportState(true);
    } else {
      setReportState(false);
    }
  }, [id]);
  useEffect(() => {
    getDataHandler();
  }, []);

  const searchfilter = (id) => {
    if (id) {
      const filterData = allData.filter((i) => i.bookingStatus == id);
      setTableData(filterData);
    } else {
      setTableData(allData);
    }
  };
  return {
    tableData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    STORY_HEADERS,
    addBooking,
    reportState,
    AlertIcon,
    show,
    setShow,
    selected,
    userDetail,
    getDataHandler,
    searchfilter,
    onDownload,
    tableRef,
  };
};

export default useBanquetBooking;
