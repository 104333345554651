import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import { Button, Modal, Col } from "react-bootstrap";
import services from "../../API/services";
import Moment from "react-moment";
import { baseUrl } from "../../API/client/api";
import { toast } from "react-toastify";
import { useDownloadExcel } from "react-export-table-to-excel";

const CustomerEnquiry = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [enquiryData, setEnquiryData] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [value, setValue] = useState(0);
  const [filterEnquiry, setFilterEnquiry] = useState([]);

  const submitAssign = async (e, data) => {
    setValue(e.target.value);
    const payload = {
      assign_to: e.target.value,
    };
    try {
      const res = await fetch(`${baseUrl}customer/enquiry-assign/${data.id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (res.status === 200) {
        getEnquiry();
        toast.success("User Assigned to this Property*", {
          autoClose: 1000,
          pauseOnHover: true,
        });
      }
    } catch (error) {
      toast.success("Some Error Occured*", {
        autoClose: 1000,
        pauseOnHover: true,
      });
    }
  };

  useEffect(() => {
    getEnquiry();
    getUsers();
  }, []);

  const getEnquiry = async () => {
    try {
      const res = await services.property.getEnquiryService();
      if (res.status === 200) {
        setEnquiryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const getUsers = async () => {
    try {
      const res = await services.auth.getAllRroomsUser();
      if (res.status === 200) {
        setAdminUsers(res?.data?.data);
      }
    } catch (error) {}
  };

  const deleteHandler = async () => {
    try {
      const res = await services.property.deletePropertyCategoryService(
        selectedData.id
      );

      if (res.status === 200) {
        setShowDelete(false);
      }
    } catch (error) {}
  };

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "FoodOrders",
    sheet: "FoodOrders",
  });
  const searchfilter = (text) => {
    const results = enquiryData.filter((order) => {
      if (text === "") return enquiryData;
      return (
        order.city +
        order.stateId +
        order.id +
        order.RroomsUser?.userCode +
        order.email +
        order.mobile +
        order.firstName +
        order.RroomsUser?.firstName
      )
        .toString()
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterEnquiry(results);
  };

  useEffect(() => {
    setFilterEnquiry(enquiryData);
  }, [enquiryData]);
  const [filterDate, setFilterDate] = useState(new Date());

  const dateFilter = () => {
    const results = enquiryData.filter((order) => {
      return (
        new Date(order?.createdAt).toJSON().slice(0, 10) >=
        new Date(filterDate).toJSON().slice(0, 10)
      );
    });
    setFilterEnquiry(results);
  };
  useEffect(() => {
    dateFilter();
  }, [filterDate]);
  return (
    <Layout
      title="Customer Enquiry"
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      searchfilter={searchfilter}
      setFilterDate={setFilterDate}
      filterDate={filterDate}
    >
      <div className="onboardDetails">
        <div className="wrap">
          <div className="inner">
            <div className="table-box scrollTable">
              <table ref={tableRef}>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Property Name</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Pincode</th>
                    <th>State</th>
                    <th>Enquiry Date</th>
                    <th>Assign To</th>
                  </tr>
                </thead>
                <tbody>
                  {filterEnquiry?.map((data, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{data.firstName + " " + data.lastName}</td>
                      <td>{data.email}</td>
                      <td>{data.mobile}</td>
                      <td>{data.propertyName}</td>
                      <td>{data.addressLine1 + " " + data.addressLine2}</td>
                      <td>{data.city}</td>
                      <td>{data.pincode}</td>
                      <td>{data.stateId}</td>
                      <td>
                        <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                      </td>
                      <td>
                        {data?.assignTo === 0 ? (
                          <select
                            value={value}
                            onChange={(e) => submitAssign(e, data)}
                            className="px-2 py-1 border"
                            style={{ outline: 0 }}
                          >
                            <option selected value={""}>
                              Select User
                            </option>
                            {adminUsers?.map((innerData, key) => (
                              <option key={key} value={innerData.id}>
                                {innerData.firstName} :{innerData.userCode}
                              </option>
                            ))}
                          </select>
                        ) : (
                          data?.RroomsUser?.firstName +
                          ": " +
                          data?.RroomsUser?.userCode
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="deleteModal">
              <span>Are you sure? Do you want to delete this record?</span>
              <span>You will not be able to recover this record!</span>
            </div>
            <Button
              variant="primary"
              className="btn-sm"
              type="button"
              onClick={deleteHandler}
            >
              Yes , Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default CustomerEnquiry;
