import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .trim()
    .max(100)
    .email(addUserValidation.valid_email)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addUserValidation.valid_email
    )
    .required(addUserValidation.email_required),

  mobile: Yup.string()
    .required(addUserValidation.phone_invalid)
    .matches(/^\+?[0-9]{10}$/, "Enter Correct Mobile Number*"),
  mobileQuery: Yup.string()
    .required(addUserValidation.phone_invalid)
    .matches(/^\+?[0-9]{10}$/, "Enter Correct Mobile Number*"),
  propertyName: Yup.string().required(addUserValidation.propertyName),
  address: Yup.string().required("Enter Property Address*"),
  propertyContent: Yup.string().required("Enter Property Content*"),
  // logo: Yup.mixed().test(
  //   "file",
  //   "Please Select Property Logo only png formate*",
  //   (value) => {
  //     if (value?.length > 0) {
  //       return value && value[0].type === "image/png";
  //     }
  //     return false;
  //   }
  // ),
  gst: Yup.string()
    .required("Please Enter GST No*")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter Correct GST Number*"
    ),
});

export default { resolver: yupResolver(validation) };
