import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({

  lastName: Yup.string()
    .trim()
    .required(addUserValidation.addUser.last_name_required)
    .matches(/^[A-Za-z ]+$/, addUserValidation.valid_name)
    .max(200, addUserValidation.max_lastname_length),
});

export default { resolver: yupResolver(validation) };
