import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  categoryName: Yup.string().required(addUserValidation.categoryName),
  maxPrice: Yup.string().required("Enter Maximum Price*"),
});

export default { resolver: yupResolver(validation) };
