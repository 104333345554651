import axios from "axios";

export const hotelFinanceURL = "https://staging-api.rrooms.in/";
export const mainUrl = "https://api.rrooms.in/";

const api = axios.create({
  baseURL: `${mainUrl}api/`,
  // baseURL: `${hotelFinanceURL}api/`,
});
export default api;

export const baseUrl = `${mainUrl}api/`;
export const baseImgUrl = `${mainUrl}images/`;
