import { useEffect, useMemo } from "react";
import useHomeController from "../../controllers/Home.controllers";
import ReactGA from "react-ga4";

const Landing = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "dashboard" });
  }, []);
  // custom hooks import
  const {
    booking,
    setBookingDates,
    activeBtn,
    tabBookingFilter,
    bookingSource,
    setBookingOption,
    bookingOption,
    userDetail,
    roomStatus,
    foodStatus,
    foodChart,
    foodRevenue,
    bookingChart,
    bookingRevenue,
    chartOption,
    changeSourceByOption,
    options,
    optionsFood,
    optionsBookRev,
    optionsFoodRev,
    propertyData,
    tabFoodFilter,
    navigateBooking,
    Chart,
    RenderBox,
    foodLoading,
    bookingLoading,
    roomLoading,
    propertyLoading,
    foodSource,
    setFoodOption,
    foodOption,
    bookingStatic,
    setFoodDates,
  } = useHomeController();
  const RenderFoodSource = useMemo(() => {
    return (
      <div className="form-group">
        <select
          name="boookingOrder"
          onChange={({ target }) => {
            setFoodOption(target.value);
          }}
        >
          {foodSource.map((item) => (
            <option
              selected={item.id == foodOption}
              value={item.id}
              key={item.id}
            >
              {item.title}
            </option>
          ))}
        </select>
      </div>
    );
  }, [foodOption]);
  const RenderBookingStatus = useMemo(() => {
    return (
      <div className="form-group">
        <select
          name="boookingOrder"
          onChange={(e) => changeSourceByOption(e.target.value)}
        >
          <option selected={"All" == chartOption} value={"All"}>
            All
          </option>
          {booking.map((item, ind) => {
            const { bookingStatus } = item;
            let bookingName = bookingStatus;
            if (
              bookingStatus == "Pending" ||
              bookingStatus == "Call Not Picked"
            ) {
              return;
            }
            if (bookingStatus == "Confirmed") {
              bookingName = "Upcoming";
            }
            if (bookingStatus == "Checked-In") {
              bookingName = "In-House";
            }
            return (
              <option
                key={ind}
                selected={bookingStatus == chartOption}
                value={bookingStatus}
              >
                {bookingName}
              </option>
            );
          })}
        </select>
      </div>
    );
  }, [booking]);

  return (
    <>
      <div className="dashboard">
        <div className="breadcrumb flex">
          <ul className="flex">
            <li>
              <a>Dashboard</a>
            </li>
          </ul>
        </div>

        {userDetail?.logintype === "rroomUser" && (
          <div className="boxWrap">
            <div className="boxWrapHeader">
              <h2>Properties</h2>
              {/* <div className="timeTabDashboard">
                {tabBookingFilter.map((item, ind) => {
                  const { title, start, end, titlePrt } = item;
                  if (titlePrt !== "Reset") {
                    return (
                      <button
                        key={ind}
                        className={activeBtn.properties === title && "active"}
                        onClick={() => setPropertyDates(start, end, title)}
                      >
                        {title}
                      </button>
                    );
                  } else if (
                    titlePrt === "Reset" &&
                    activeBtn.properties != ""
                  ) {
                    return (
                      <button
                        key={ind}
                        className={activeBtn.properties === title && "active"}
                        onClick={() => setPropertyDates("", "", "")}
                      >
                        {titlePrt}
                      </button>
                    );
                  }
                })}
              </div> */}
            </div>
            <ul className="flex">
              <RenderBox
                title="Requested"
                count={propertyData?.request}
                loading={propertyLoading}
              />
              <RenderBox
                title="Active"
                count={propertyData?.active}
                loading={propertyLoading}
              />
              <RenderBox
                title="Inactive"
                count={propertyData.inActive}
                loading={propertyLoading}
              />
              <RenderBox
                title="Total"
                count={propertyData.total}
                loading={propertyLoading}
              />
            </ul>
          </div>
        )}
        <>
          <div className="boxWrap">
            <div className="boxWrapHeader">
              <h2>Rooms</h2>
              <div className="timeTabDashboard">
                <button className="active">Today</button>
              </div>
            </div>
            <ul className="flex">
              {roomStatus.map((item, ind) => (
                <RenderBox
                  key={ind}
                  title={item.title}
                  count={item.count}
                  loading={roomLoading}
                />
              ))}
            </ul>
          </div>

          <div className="boxWrap">
            <div className="boxWrapHeader">
              <h2>Booking</h2>
              <div className="form-group">
                <select
                  name="boookingOrder"
                  onChange={({ target }) =>
                    setBookingOption(JSON.parse(target.value))
                  }
                >
                  {bookingSource.map((item, key) => (
                    <option
                      selected={item.title === bookingOption.title}
                      value={JSON.stringify(item)}
                      key={key}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="timeTabDashboard">
                {tabBookingFilter.map((item, ind) => {
                  const { title, start, end } = item;
                  return (
                    <button
                      key={ind}
                      className={activeBtn.booking === title && "active"}
                      onClick={() => setBookingDates(start, end, title)}
                    >
                      {title}
                    </button>
                  );
                })}
              </div>
            </div>
            <ul className="flex">
              {booking?.length > 0
                ? booking.map((item, ind) => {
                    const { bookingStatus } = item;

                    if (
                      activeBtn.booking === "Today" &&
                      bookingStatus === "For-Checkout"
                    ) {
                      return;
                    }
                    const ifPress =
                      bookingStatus === "For-Checkout" &&
                      userDetail.logintype == "propertyUser";

                    let bookingName = bookingStatus;
                    if (
                      bookingStatus == "Pending" ||
                      bookingStatus == "Call Not Picked"
                    ) {
                      return;
                    }
                    if (bookingStatus == "Confirmed") {
                      bookingName = "Upcoming";
                    }
                    if (bookingStatus == "Checked-In") {
                      bookingName = "In-House";
                    }

                    return (
                      <RenderBox
                        loading={bookingLoading}
                        handler={ifPress ? navigateBooking : false}
                        style={{
                          cursor: ifPress ? "pointer" : "auto",
                        }}
                        bg={
                          ifPress && item[bookingOption.count]
                            ? "red"
                            : "#00a3ff"
                        }
                        key={ind}
                        title={bookingName}
                        count={
                          bookingName === "For-Checkout"
                            ? item.totalBooking
                            : item[bookingOption.count]
                        }
                        amount={
                          bookingName === "For-Checkout"
                            ? item.totalCollectedAmount
                            : item[bookingOption.amount]
                        }
                      />
                    );
                  })
                : bookingStatic.map((item, ind) => {
                    return (
                      <RenderBox
                        loading={bookingLoading}
                        key={ind}
                        title={item}
                        count={0}
                        amount={0}
                      />
                    );
                  })}
            </ul>
          </div>
        </>
        {userDetail?.logintype != "rroomUser" && (
          <div className="boxWrap">
            <div className="boxWrapHeader">
              <h2>Food Orders</h2>
              {RenderFoodSource}
              <div className="timeTabDashboard">
                {tabFoodFilter.map((item, ind) => {
                  const { title, start, end } = item;
                  return (
                    <button
                      key={ind}
                      className={activeBtn.foodTab === title && "active"}
                      onClick={() => setFoodDates(start, end, title)}
                    >
                      {title}
                    </button>
                  );
                })}
              </div>
            </div>
            <ul className="flex">
              {foodStatus.map((item, ind) => {
                return (
                  <RenderBox
                    loading={foodLoading}
                    key={ind}
                    title={item.title}
                    amount={item.amount}
                    count={item.count}
                  />
                );
              })}
            </ul>
          </div>
        )}
        {userDetail?.logintype === "rroomUser" && (
          <div className="boxWrap">
            <div className="boxWrapHeader">
              <h2>Application</h2>
            </div>
            <ul className="flex">
              <li>
                <div className="d-flex">
                  <h4>0</h4>
                  <span>New Downloads </span>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <h4>0</h4>
                  <span>Total Downloaded</span>
                </div>
              </li>
            </ul>
          </div>
        )}
        {userDetail?.logintype === "propertyUser" && (
          <div className="flex">
            {bookingChart.length ? (
              <div className="chart">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="350px"
                  data={bookingChart}
                  options={options}
                />
                <div className="timeTabDashboard">
                  {tabBookingFilter.map((item, ind) => {
                    const { title, start, end } = item;
                    return (
                      <button
                        key={ind}
                        className={activeBtn.booking === title && "active"}
                        onClick={() => setBookingDates(start, end, title)}
                      >
                        {title}
                      </button>
                    );
                  })}
                </div>
                {RenderBookingStatus}
              </div>
            ) : null}
            {foodChart.length ? (
              <div className="chart">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="350px"
                  data={foodChart}
                  options={optionsFood}
                />
                <div className="timeTabDashboard">
                  <button className={"active"}>Today</button>
                </div>
                {RenderFoodSource}
              </div>
            ) : null}
            {bookingRevenue.length ? (
              <div className="chart">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="350px"
                  data={bookingRevenue}
                  options={optionsBookRev}
                />
                <div className="timeTabDashboard">
                  {tabBookingFilter.map((item, ind) => {
                    const { title, start, end } = item;
                    return (
                      <button
                        key={ind}
                        className={activeBtn.booking === title && "active"}
                        onClick={() => setBookingDates(start, end, title)}
                      >
                        {title}
                      </button>
                    );
                  })}
                </div>
                {RenderBookingStatus}
              </div>
            ) : null}
            {foodRevenue.length ? (
              <div className="chart">
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="350px"
                  data={foodRevenue}
                  options={optionsFoodRev}
                />
                <div className="timeTabDashboard">
                  <button className="active">Today</button>
                </div>
                {RenderFoodSource}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default Landing;
