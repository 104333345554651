import AuthDictionary from "./AuthDictionary";
import UserDictionary from "./UserDictionary";
import PropertyDictionary from "./PropertyDictionary";
import GlobalDictinary from "./globalDictinary";

const Dictionary = {
  auth: AuthDictionary,
  user: UserDictionary,
  property: PropertyDictionary,
  global:GlobalDictinary
};

export default Dictionary;
