import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Services from "../../API/services";
import moment from "moment";
import { Margin, usePDF } from "react-to-pdf";
import { useLocation } from "react-router-dom";
import { showPrice } from "utils/utils";

const FoodInvoiceBill = () => {
  const { state } = useLocation();
  const { item, booking, allOrders } = state;

  const [textInputs, setTextInputs] = useState({
    billNumber: "",
    name: "",
    gst: "",
  });
  const { userDetail } = useSelector(authenticationSeletor);
  const [propertyData, setPropertyData] = useState({});

  let totalGst = 0;
  let netAmt = 0;
  allOrders.forEach((item) =>
    item.orderItems.forEach((order) => {
      totalGst = totalGst + order.taxAmount;
      netAmt = netAmt + order.totalAmount;
    })
  );
  const cgstVal = totalGst / 2;
  const sgstVal = totalGst / 2;

  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "roomInvoice.pdf",
    page: {
      margin: Margin.SMALL,
      format: "A6",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
  });

  useEffect(() => {
    getPropertyDetail();
  }, []);

  const getPropertyDetail = async () => {
    try {
      const res = await Services.property.getSinglePropertyService(
        userDetail?.propertyId,
        true
      );
      if (res.status === 200) {
        setPropertyData(res?.data?.data);
      }
    } catch (error) {}
  };

  return (
    <Layout
      title="Food Invoice"
      openHandler={false}
      buttonTitle="Edit Invoice"
      extraButton="Print Invoice"
      extraBtnHandler={toPDF}
    >
      <div className="container foodInvoice">
        <div className="row flex justify-content-center align-items-center">
          <div className="col-4 bg-white py-2 foodPdf" ref={targetRef}>
            <span className="text-black gstText" style={{ fontSize: "10px" }}>
              GSTIN No.: {propertyData?.gstNumber}
            </span>
            <div className="text-center text-black content">
              <span>{propertyData?.name}</span>
              <br />
              <span>{propertyData?.address}</span>
              <span>Contact No.:{propertyData?.ownerMobile}</span>
              <span>
                Room Service - <small>(Sale Bill)</small>
              </span>
            </div>
            <div className="borderedDiv">
              <p>
                Bill Number:
                <input
                  type="text"
                  className="noCenter"
                  style={{
                    borderWidth: textInputs.billNumber.length > 0 ? 0 : 1,
                  }}
                  value={textInputs.billNumber}
                  onChange={(e) =>
                    setTextInputs({ ...textInputs, billNumber: e.target.value })
                  }
                />
              </p>
              <p>
                Date:{" "}
                <span>{moment(item?.createdAt).format("YYYY-MM-DD")}</span>
              </p>
            </div>
            <div className="borderedDiv">
              <p>
                Room Number: <span>{item.roomNumber}</span>
              </p>
              <p>
                Booking ID: <span>{item?.bookingId}</span>
              </p>
            </div>
            {allOrders?.map((mainItem) => (
              <>
                <div className="borderedDiv">
                  <p>
                    Order Number: <span>{mainItem.id}</span>
                  </p>
                </div>
                <div className="borderedDiv ">
                  <p className="w-25">Item Name</p>
                  <p className="w-25 justify-content-end">Rate</p>
                  <p className="w-25 justify-content-center">Qty</p>
                  <p className="w-25 justify-content-center">Tax</p>
                  <p className="w-25 justify-content-end">Amount</p>
                </div>
                {mainItem?.orderItems?.map((data, ind) => (
                  <div className="borderedDiv" key={ind}>
                    <p className="w-25">{data?.name}</p>
                    <p className="w-25 justify-content-end">
                      {showPrice(data?.price)}
                    </p>
                    <p className="w-25 justify-content-center">{data?.qty}</p>
                    <p className="w-25 justify-content-center">
                      {showPrice(data?.taxAmount)}
                    </p>
                    <p className="w-25 justify-content-end">
                      {showPrice(data?.qty * data?.price)}
                    </p>
                  </div>
                ))}
              </>
            ))}

            <div className="borderedDiv flex-column px-3">
              <p className="justify-content-between w-100">
                Gross Amount: <span>{showPrice(netAmt - totalGst)}</span>
              </p>
              <p className="justify-content-between w-100">
                SGST:
                <span>{showPrice(sgstVal)}</span>
              </p>
              <p className="justify-content-between w-100">
                CGST:
                <span>{showPrice(cgstVal)}</span>
              </p>
              <p className="justify-content-between w-100">
                Net Amount: <span>{showPrice(netAmt)}</span>
              </p>
            </div>
            <div className="borderedDiv flex-column align-items-start">
              <p>
                Customer Name:{" "}
                <input
                  type="text"
                  className="noCenter mw-100"
                  style={{ borderWidth: textInputs.name.length > 0 ? 0 : 1 }}
                  value={textInputs.name}
                  onChange={(e) =>
                    setTextInputs({ ...textInputs, name: e.target.value })
                  }
                />
              </p>
              <p>
                Customer GST:
                <input
                  type="text"
                  className="noCenter mw-100"
                  style={{ borderWidth: textInputs.gst.length > 0 ? 0 : 1 }}
                  value={textInputs.gst}
                  onChange={(e) =>
                    setTextInputs({ ...textInputs, gst: e.target.value })
                  }
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FoodInvoiceBill;
