import moment from "moment";
import { debounce } from "utils/utils";

const { getGuestDetails } = require("API/services/newServices");
const { useState, useEffect, useRef, useMemo } = require("react");
const { useSelector } = require("react-redux");
const { authenticationSeletor } = require("store/slices/authenticationSlice");

const useGuestDetails = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  const tableRef = useRef();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [firstTime, setFirstTime] = useState(true);

  const [guests, setGuest] = useState([]);
  const [params, setParams] = useState({
    propertyId: userDetail.propertyId,
  });

  const STORY_HEADERS = [
    {
      prop: "otherPersonName",
      title: "Guest Name",
      isFilterable: true,
      isSortable: true,
      cell: (row) => row.otherPersonName ?? "N/A",
    },
    {
      prop: "otherPersonNumber",
      title: "Mobile Number",
      isFilterable: true,
      isSortable: true,
      cell: (row) => row.otherPersonNumber ?? "N/A",
    },
    {
      prop: "bookingCode",
      title: "Booking Code",
      isFilterable: true,
      isSortable: true,
      cellProps: { className: "text-center" },
      cell: (row) => row.bookingCode ?? "N/A",
    },
  ];

  const getDataHanderler = async () => {
    const res = await getGuestDetails(params);
    if (res) {
      setGuest(res.data);
      setFirstTime(false);
    }
  };
  const searchfilter = debounce((mobile) => {
    setParams({
      propertyId: userDetail.propertyId,
      mobile,
    });
  }, 1000);
  const codeFilter = debounce((bookingCode) => {
    setParams({
      propertyId: userDetail.propertyId,
      bookingCode,
    });
  }, 1000);

  useEffect(() => {
    getDataHanderler();
  }, [params]);
  useMemo(() => {
    if (!firstTime) {
      setParams({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        propertyId: userDetail.propertyId,
      });
    }
  }, [endDate, startDate]);
  return {
    guests,
    tableRef,
    STORY_HEADERS,
    searchfilter,
    codeFilter,
    startDate,
    endDate,
    firstTime,
    setEndDate,
    setStartDate,
  };
};

export default useGuestDetails;
