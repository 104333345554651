import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, Form, Row, Col, Container } from "react-bootstrap";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

const BreakFast = () => {
  const [tableData, setTableData] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  const [showNewPopup, setShowNewPopup] = useState(false);
  const [selectedRoom, setSelectRoom] = useState({});
  const [propertyData, setPropertyData] = useState({});
  const [fieldPrice, setFieldPrice] = useState({
    cp: 0,
    ap: 0,
    map: 0,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    let editPayload = {
      id: selectedRoom.id,
      body: {
        breakFastPrice: fieldPrice?.cp,
        ap: fieldPrice?.ap,
        map: fieldPrice?.map,
        propertyId: userDetail?.propertyId,
        categoryId: selectedRoom?.categoryId,
      },
    };

    try {
      const res = await services.property.updateRRoomService(editPayload);
      if (res.status === 200) {
        setShowNewPopup(false);
        getRoomCategory();
        getPropertyDetail();
        toast.success("Meal Plan Price Updated Successfull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      } else {
        toast.success("Some Error Please try again Later !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast.success("Some Error Please try again Later !", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const getRoomCategory = async () => {
    try {
      const res = await services.property.getRoomCategoryService();
      if (res.status === 200) {
        setTableData(res?.data?.data);
      }
    } catch (error) {}
  };
  const getPropertyDetail = async () => {
    try {
      const res = await services.property.getSinglePropertyService(
        userDetail?.propertyId
      );
      if (res.status === 200) {
        setPropertyData(res?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "meal_plan_setting" });
    getPropertyDetail();
    window.scrollTo(0, 0);
    getRoomCategory();
  }, []);

  const openRoomPricePUp = (item) => {
    setSelectRoom(item);
    setFieldPrice({
      cp: item?.breakFastPrice,
      ap: item?.ap,
      map: item?.map,
    });
    setShowNewPopup(true);
  };
  return (
    <Layout title="Meal Plan Settings" openHandler={false}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="categoryWrap">
              <h3>Meal Plan</h3>
              <ul className="d-flex">
                {propertyData?.Rooms?.map((item, ind) => (
                  <li
                    key={ind}
                    onClick={
                      userDetail.Role?.canEdit == 1
                        ? () => openRoomPricePUp(item)
                        : () => false
                    }
                    className="w-100 flex justify-content-between p-3"
                    role="button"
                  >
                    <span>
                      {tableData?.find((i) => i.id === item.categoryId)?.name}
                    </span>
                    <div className="flex" style={{ gap: "2rem" }}>
                      <span>
                        CP: {` \u20B9 ` + item?.breakFastPrice ?? "0.00"}
                      </span>
                      <span>AP: {` \u20B9 ` + item?.ap ?? "0.00"} </span>
                      <span>MAP: {` \u20B9 ` + item?.map ?? "0.00"} </span>
                      {userDetail.Role?.canEdit == 1 && (
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square"
                          color="#3699ff"
                          className="fs-5"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showNewPopup}
        onHide={() => {
          setShowNewPopup(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkInModal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="color-black"
          >
            Edit Meal Plan Price
          </Modal.Title>
          <button
            className="closeButton"
            onClick={() => setShowNewPopup(false)}
          >
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="categoryWrap taxRooms">
                <Form className="popup-form" onSubmit={(e) => onSubmit(e)}>
                  <Row>
                    <Col xs={4} className="form-group">
                      <label for="Breackfast">Edit CP Price*</label>
                      <input
                        type="number"
                        id="Breackfast"
                        required
                        placeholder="Breackfast CP Price"
                        value={fieldPrice.cp}
                        onChange={(e) =>
                          setFieldPrice({ ...fieldPrice, cp: e.target.value })
                        }
                      />
                    </Col>
                    <Col xs={4} className="form-group">
                      <label for="Breackfast">Edit AP Price*</label>
                      <input
                        type="number"
                        id="Breackfast"
                        required
                        placeholder="Breackfast AP Price"
                        value={fieldPrice.ap}
                        onChange={(e) =>
                          setFieldPrice({ ...fieldPrice, ap: e.target.value })
                        }
                      />
                    </Col>
                    <Col xs={4} className="form-group">
                      <label for="Breackfast">Edit MAP Price*</label>
                      <input
                        type="number"
                        id="Breackfast"
                        required
                        placeholder="Breackfast MAP Price"
                        value={fieldPrice.map}
                        onChange={(e) =>
                          setFieldPrice({ ...fieldPrice, map: e.target.value })
                        }
                      />
                    </Col>
                    <Col
                      xs={3}
                      className="form-group flex justify-content-between mt-2"
                    >
                      <Button
                        className="width100"
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <div className="flex justify-content-between">
                  <span>
                    CP: {` \u20B9 ` + selectedRoom?.breakFastPrice ?? "0.00"}
                  </span>
                  <span>AP: {` \u20B9 ` + selectedRoom?.ap ?? "0.00"} </span>
                  <span>MAP: {` \u20B9 ` + selectedRoom?.map ?? "0.00"} </span>
                </div>
                {/* <p className="mt-2">
                  Current Price:{" "}
                  {`\u20B9 ` + selectedRoom?.breakFastPrice?.toFixed(2)}{" "}
                </p> */}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default BreakFast;
