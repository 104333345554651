import { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";

import Sidebar from "../../layout/sidebar";
import Header from "../../layout/header";
import { getConfigModule } from "store/slices/PropertyData";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";

const Home = lazy(() => import("../home"));
const AllBookings = lazy(() => import("./AllBookings"));
const BookingDetails = lazy(() => import("./BookingDetails"));
const Rooms = lazy(() => import("./Rooms"));

export const TAB_KEYS = {
  ALL: "all-bookings",
  ROOMS: "rooms",
};

const BOOKING_TABS = [
  { key: TAB_KEYS.ALL, title: "Dashboard" },
  { key: TAB_KEYS.ROOMS, title: "Rooms" },
];

const Dashboard = () => {
  const [toggleSideBar, setToggleSideBar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TAB_KEYS.ALL);
  const { userDetail } = useSelector(authenticationSeletor);
  const dispatch = useDispatch();
  const { assignedModules } = useSelector((s) => s.AllData);

  useEffect(() => {
    if (location.pathname.includes("rooms")) {
      setActiveTab(TAB_KEYS.ROOMS);
    } else {
      setActiveTab(TAB_KEYS.ALL);
    }
    if (location.pathname === "/dashboard") {
      navigate(`${location.pathname}/home`);
    }
    if (!assignedModules.length) dispatch(getConfigModule(userDetail));
  }, [location]);

  return (
    <div className={`dashboard-wrap flex ${toggleSideBar ? "" : "closeBar"}`}>
      <Sidebar sideAction={setToggleSideBar} sideVal={toggleSideBar} />
      <div className="main-content">
        <Header setToggleSideBar={setToggleSideBar} sideVal={toggleSideBar} />
        <div className="all-bookings">
          <div className="booking-tabs hideTabs">
            <Tabs
              activeKey={activeTab}
              className="tabs"
              defaultActiveKey={TAB_KEYS.ALL}
              onSelect={(tabKey) => {
                navigate(`/dashboard/${tabKey}`);
              }}
            >
              {BOOKING_TABS.map((tab, ind) => {
                return <Tab key={ind} eventKey={tab.key} title={tab.title} />;
              })}
            </Tabs>
            <Button variant="primary">New Booking</Button>
          </div>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path={`/${TAB_KEYS.ALL}`} element={<AllBookings />} />
              <Route path={`/${TAB_KEYS.ROOMS}`} element={<Rooms />} />
              <Route
                path="/all-bookings/:bookingId"
                element={<BookingDetails />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
