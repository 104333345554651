import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  fullName: Yup.string().required(addUserValidation.name_require),
  email: Yup.string()
    .label("Email")
    .trim()
    .max(100)
    .email(addUserValidation.valid_email)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addUserValidation.valid_email
    )
    .required(addUserValidation.email_required),
  phoneNumber: Yup.number()
    .required(addUserValidation.phone_invalid)
    .test(
      "len",
      "Phone number needs to be excatly 10 digits",
      (val) => val.toString().length === 10
    ),
});

export default { resolver: yupResolver(validation) };
