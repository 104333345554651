import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import RenderBox from "../../components/RenderBox";
import { summaryService } from "../../API/services/newServices";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";

const HotelSummery = () => {
  const [renderSummary, setRenderSummary] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  const [searchSelectVal, setSearchSelectVal] = useState({});

  const getDataHandler = async (id) => {
    const res = await summaryService(id ?? "");
    if (res) {
      setRenderSummary([
        {
          title: "Upcoming Total Sales",
          para: "Total Commission",
          count: res.data.upcoming_sale,
          amount: res.data.upcoming_sale_commission,
        },
        {
          title: "Checked-In Total Sales",
          para: "Total Commission",
          count: res.data.checked_in_sale,
          amount: res.data.checked_in_commission,
        },
        {
          title: "Checked-Out Total Sales",
          para: "Total Commission",
          count: res.data.checked_out_sale,
          amount: res.data.checked_out_commission,
        },
      ]);
    }
  };
  const changeHandler = (item) => {
    setSearchSelectVal(item);
    getDataHandler(item.id);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "summary" });
    getDataHandler(userDetail?.propertyId);
  }, []);
  return (
    <Layout
      title={"Hotel Summary"}
      wantSearchableListFor={
        userDetail?.logintype === "rroomUser" ? "property" : ""
      }
      searchSelectVal={searchSelectVal}
      changeHandler={changeHandler}
    >
      <div className="dashboard">
        <div className="boxWrap">
          <div className="boxWrapHeader">
            <h2>Summary</h2>
          </div>
          {renderSummary.length > 0 ? (
            <ul className="flex">
              {renderSummary.map((item, ind) => (
                <RenderBox
                  key={ind}
                  style={{ width: "calc(25% - 2.5rem)" }}
                  title={item.title}
                  para={item.para}
                  amount={item.amount}
                  count={item.count}
                />
              ))}
            </ul>
          ) : (
            <div
              className="flex flex-column align-center justify-content-center full-width text-black"
              style={{ height: "10rem" }}
            >
              <FontAwesomeIcon
                className="mb-2"
                icon="fa-solid fa-ban"
                style={{ width: "40px", height: "40px" }}
                beat
              />
              <span>
                {userDetail?.logintype === "rroomUser"
                  ? "Please Select Property to See Details."
                  : "Data not available"}
              </span>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default HotelSummery;
