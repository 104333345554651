import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validation = Yup.object().shape({
  itemName: Yup.string().required("Please enter Food Item name*"),
  itemPrice: Yup.string().required("Please enter Food Item price*"),
  gstPercent: Yup.string().required("Please enter Food GST percentage*"),
  gstType: Yup.string().required("Please select Food GST Type*"),
});

const schema = Yup.object({
  inputs: Yup.array().of(validation),
});

export default { resolver: yupResolver(schema) };
