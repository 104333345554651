import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const gstNumberPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const validation = Yup.object().shape({
  // gst: Yup.string()
  //   .nullable()
  //   .matches(gstNumberPattern, "Enter Correct GST Number"),
});

export default { resolver: yupResolver(validation) };
