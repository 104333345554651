import React, { useEffect, useMemo, useRef, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { Controller } from "react-hook-form";
import moment from "moment";
import Moment from "react-moment";
import {
  createInventoryOptions,
  createOptions,
  showPrice,
} from "../../utils/utils";
import Select from "react-select";
import { useDownloadExcel } from "react-export-table-to-excel";
import GlobalDictinary from "API/dictionary/globalDictinary";
import ReactGA from "react-ga4";
import {
  addInAndOutStockService,
  getSupplierItem,
} from "API/services/newServices";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validation1 = Yup.object().shape({
  price: Yup.string().required("Please Enter Price*"),
  totalAmount: Yup.string().required("Please Enter Total Amount*"),
  quantity: Yup.string().required("Please Enter Quntity*"),
  unit: Yup.string().required("Please Enter Unit*"),
  supplierId: Yup.object().required("Please select Supplier *"),
  item: Yup.object().required("Please select Item*"),
  // expDate: Yup.string().required("Please select expiry date*"),
  selectCategory: Yup.string().required("Please select Category*"),
  stockStatus: Yup.string().required("Please select Stock type*"),
});
const validation2 = Yup.object().shape({
  price: Yup.string().required("Please Enter Price*"),
  quantity: Yup.string().required("Please Enter Quntity*"),
  supplierId: Yup.object().required("Please select Supplier *"),
  item: Yup.object().required("Please select Item*"),
  stockStatus: Yup.string().required("Please select Stock type*"),
  selectReason: Yup.string().required("Please select reason to out*"),
  remark: Yup.string().required("Enter Remark*"),
});

const STOCK_STATUS = ["IN", "OUT"];

const instockValidation = { resolver: yupResolver(validation1) };
const outstockValidation = { resolver: yupResolver(validation2) };

const Inventory = () => {
  const [tableData, setTableData] = useState([]);
  const [stockTableData, setStockTableData] = useState([]);
  const [stockTableDataFilter, setStockTableDataFilter] = useState([]);
  const [suppilerData, setSuppilerData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [validation, setValidation] = useState(instockValidation);

  const { userDetail } = useSelector(authenticationSeletor);

  const getEmplyoee = async () => {
    try {
      const data = await services.property.getInventoryEmplyoee();
      const onlyProperty = data?.data?.result?.filter((item) => {
        return item?.propertyId === userDetail?.propertyId;
      });
      const dataSet = createOptions(onlyProperty, "firstName", "id");
      setSuppilerData(dataSet);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
    getDataHandlers(userDetail?.propertyId);
    getDataHandlerSupplier(userDetail?.propertyId);
  }, []);

  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [itemDataMaster, setItemDataMaster] = useState([]);
  const [remarkShow, setRemarkShow] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getInventoryCategoryService(id);
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  const onSubmit = async (payload) => {
    const stockSt = +payload.stockStatus;

    let realPayload = {
      propertyId: userDetail?.propertyId,
      categoryId: payload.selectCategory,
      employeeId: 0,
      suplierId: payload?.supplierId?.value,
      itemName: payload.item?.label,
      itemId: payload.item?.value,
      // avaiableQuantity: payload.avaiableQuantity,
      remark: payload.remark ?? "",
      status: 0,
      reasonToOutStock: payload.selectReason,
      unit: payload.unit,
      quantity: +payload.quantity,
      price: +payload.price,
      totalAmount: payload.totalAmount,
      mfdDate: moment().format("YYYY-MM-DD"),
      expDate: moment(payload.expDate).format("YYYY-MM-DD"),
      createdBy: userDetail.id,
      inStock: true,
    };

    if (stockSt == 2) {
      realPayload = {
        propertyId: userDetail?.propertyId,
        employeeId: payload?.supplierId?.value,
        itemId: payload.item?.value,
        quantity: payload.quantity,
        remark: payload.remark ?? "",
        status: 0,
        reasonToOutStock: payload.selectReason,
        updatedBy: userDetail.id,
      };
    }

    const res = await addInAndOutStockService(realPayload);
    if (res) {
      reset();
      // setEditMode(false);
      getDataHandlers(userDetail?.propertyId);
      setShow(false);
      // setSelectedData({});
    }
  };

  const { quantity, price, selectCategory, stockStatus, supplierId } = watch();
  useEffect(() => {
    if (show) {
      setValue("selectCategory", "");
      setValue("item", "");
      setValue("supplierId", "");
      if (+stockStatus == 2) {
        getEmplyoee();
        setRemarkShow(true);
        setValidation(outstockValidation);
      } else {
        getDataHandlerSupplier(userDetail.propertyId);
        setRemarkShow(false);
        setValidation(instockValidation);
      }
      setItemsData([]);
    }
  }, [stockStatus]);
  useMemo(() => {
    setValue("totalAmount", quantity * price);
  }, [quantity, price]);

  const getDataHandlers = async (id) => {
    try {
      const res = await services.globalService.getInStockService(id);
      if (res.status === 200) {
        setStockTableData(res.data.data);
      }
    } catch (error) {}
  };

  const getDataHandlerSupplier = async (id) => {
    try {
      const res = await services.globalService.getSupplierByPropertyIdService(
        id
      );
      if (res.status === 200) {
        const dataSet = createOptions(res.data.data, "name", "id");
        setSuppilerData(dataSet);
      }
    } catch (error) {}
  };

  const getDataHandlerItems = async (id) => {
    const res = await getSupplierItem(id);
    if (res) {
      setItemDataMaster(res);
      setItemsData(
        createInventoryOptions(res, "InventoryItem", "itemId", "itemName")
      );
    }
  };
  const getItemsByCategory = async (id) => {
    const res =
      await services.globalService.getInventoryItemByPropertyIdService(
        userDetail.propertyId
      );
    if (res.status === 200) {
      const onlyInstocks = res.data.data.filter((i) =>
        stockTableData.some((item) => item.itemId == i.id)
      );
      const filtered = onlyInstocks.filter((i) => i.categoryId == id);
      setItemDataMaster(onlyInstocks);
      setItemsData(createOptions(filtered, "itemName", "id"));
    }
  };

  useEffect(() => {
    if (supplierId?.value && show && !remarkShow) {
      getDataHandlerItems(supplierId?.value);
    }
  }, [supplierId?.value]);
  useEffect(() => {
    if (selectCategory && show) {
      if (+stockStatus == 1) {
        const filter = itemDataMaster.filter(
          (i) => i.categoryId == selectCategory
        );
        setItemsData(
          createInventoryOptions(filter, "InventoryItem", "itemId", "itemName")
        );
      } else {
        getItemsByCategory(selectCategory);
      }
    }
  }, [selectCategory]);

  const itemChangeHandler = (data) => {
    if (remarkShow) {
      let getSelected = itemDataMaster.find(
        (dataSet) => dataSet.id == data.value
      );
      setValue("price", getSelected.price);
    } else {
      let getSelected = itemDataMaster.find(
        (dataSet) => dataSet.itemId == data.value
      );
      setValue("unit", getSelected.InventoryItem?.unit);
      setValue("price", getSelected.InventoryItem?.price);
    }
  };

  const tableRef = useRef();
  const exportTable = () => {
    window.open(
      GlobalDictinary.exportInventoryExcle(
        userDetail.propertyId,
        fromDate,
        toDate
      )
    );
  };
  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: "SearchResults",
  //   sheet: "SearchResults",
  // });
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "inventory" });
    setStockTableDataFilter(stockTableData);
  }, [stockTableData]);

  const searchfilter = (text, fromHeads = false, innerKey = null) => {
    const resultsData = stockTableData.filter((order) => {
      if (text === "") return stockTableData;
      // 0 in
      // 1 out
      let params = innerKey ? order[fromHeads]?.[innerKey] : order[fromHeads];
      if (fromHeads === "stockType") {
        params = STOCK_STATUS[order.stockType];
      }
      if (!fromHeads) {
        params =
          order?.itemId +
          order?.itemName +
          order?.categoryId +
          order.unit +
          order.price +
          order.totalAmount +
          order.quantity +
          order?.Suplier?.name +
          order?.InventoryCategory?.name;
      }
      return params?.toString().toLowerCase()?.includes(text?.toLowerCase());
    });

    setStockTableDataFilter(resultsData);
  };

  const setChangeHeadsVal = (e) => {
    const name = e.target.name;
    let innerKey = null;
    if (name === "Suplier" || name === "InventoryCategory") {
      innerKey = "name";
    }
    searchfilter(e.target.value, name, innerKey);
  };

  return (
    <Layout
      title="Inventory"
      openHandler={() => setShow(true)}
      extraBtnHandler={exportTable}
      extraButton={fromDate || toDate ? "Export Table" : false}
      searchfilter={searchfilter}
      searchType="date"
      setFromDate={setFromDate}
      setToDate={setToDate}
      fromDate={fromDate}
      toDate={toDate}
    >
      <div className="scrollTable">
        <Table striped bordered hover className="common-table" ref={tableRef}>
          <thead>
            <tr>
              <th>
                <span>Supplier Name/Id</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="Suplier"
                  type="text"
                  placeholder="Supplier Name/Id"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Category</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="InventoryCategory"
                  type="text"
                  placeholder="Category"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Item</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="itemName"
                  type="text"
                  placeholder="Item"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Unit</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="unit"
                  type="text"
                  placeholder="Unit"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Quantity</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="quantity"
                  type="number"
                  placeholder="Quantity"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Availabe Quantity</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="avaiableQuantity"
                  type="number"
                  placeholder="Availabe Quantity"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Price</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="price"
                  type="number"
                  placeholder="Price"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Total amount</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="totalAmount"
                  type="number"
                  placeholder="Total amount"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Exp.Date</span>
              </th>
              <th>
                <span>Date</span>
              </th>
              <th>
                <span>Status</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="stockType"
                  type="text"
                  placeholder="Status"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Remark</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="remarks"
                  type="text"
                  placeholder="Remark"
                  className="pl-1"
                />
              </th>
              <th>
                <span>Reason for out stock</span>
                <input
                  onChange={setChangeHeadsVal}
                  name="reasonToOutStock"
                  type="text"
                  placeholder="Reason for out"
                  className="pl-1"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {stockTableDataFilter.map((data, key) => (
              <tr key={key}>
                <td>
                  {suppilerData.find((i) => i.value == data?.suplierId)?.label}{" "}
                  #{data?.suplierId}
                </td>
                <td>{data.InventoryCategory?.name}</td>
                <td>{data.itemName}</td>
                <td>{data.unit}</td>
                <td>{data.quantity ?? "N/A"}</td>
                <td>{data.avaiableQuantity ?? "N/A"}</td>
                <td>{showPrice(data.price)}</td>
                <td>{showPrice(data.totalAmount)}</td>
                {/* <td>
                    {data.mfdDate && (
                      <Moment format="DD/MM/YYYY">{data.mfdDate}</Moment>
                    )}
                  </td> */}
                <td>
                  {data.expDate && (
                    <Moment format="DD MMM, YYYY">{data.expDate}</Moment>
                  )}
                </td>
                <td>
                  {data.createdAt && (
                    <Moment format="DD MMM, YYYY">{data.createdAt}</Moment>
                  )}
                </td>
                <td
                  className={`inStock fw-bold ${
                    !data.stockType ? "text-success" : "text-danger"
                  }`}
                >
                  {STOCK_STATUS[data.stockType]}
                </td>
                <td>{data.remarks ?? "N/A"}</td>
                <td>{data.reasonToOutStock ?? "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setValue("selectCategory", "");
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit In Stock Form" : "Add Stock"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm instock"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <label>Select Stock Status</label>
                  <select {...register("stockStatus")}>
                    <option selected value="" disabled>
                      Select
                    </option>
                    <option value={1}>In-Stock</option>
                    <option value={2}>out-Stock</option>
                  </select>
                  {errors?.stockStatus?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"stockStatus"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <label>Select {remarkShow ? "Employee" : "Supplier"}</label>
                  <Controller
                    control={control}
                    name="supplierId"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={suppilerData}
                        placeholder={"Select"}
                        value={value}
                        onChange={onChange}
                        isSearchable={true}
                      />
                    )}
                  />
                  {errors?.supplierId?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"supplierId"} />
                      </span>
                    </div>
                  )}
                </Col>
                {/* {!remarkShow && ( */}
                <Col xs={6} className="form-group">
                  <label>Select Category</label>
                  <select {...register("selectCategory")}>
                    <option selected value="" disabled>
                      Select
                    </option>
                    {tableData.map((data, key) => (
                      <option value={data.id} key={key}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  {errors?.selectCategory?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"selectCategory"} />
                      </span>
                    </div>
                  )}
                </Col>
                {/* )} */}
                <Col xs={6} className="form-group">
                  <label>Item</label>
                  <Controller
                    control={control}
                    name="item"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={itemsData}
                        placeholder={"Select"}
                        value={value}
                        onChange={(dataSet) => {
                          onChange(dataSet);
                          itemChangeHandler(dataSet);
                        }}
                        isSearchable={true}
                      />
                    )}
                  />
                  {errors?.item?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"item"} />
                      </span>
                    </div>
                  )}
                </Col>
                {!remarkShow && (
                  <Col xs={3} className="form-group">
                    <InputField
                      htmlFor="unit"
                      text="Unit"
                      type="text"
                      // placeholder="gm"
                      field={"unit"}
                      register={register}
                      errors={errors}
                      disabled={true}
                    />
                    {errors?.unit?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"unit"} />
                        </span>
                      </div>
                    )}
                  </Col>
                )}
                <Col xs={3} className="form-group">
                  <InputField
                    htmlFor="price"
                    text="Price"
                    type="text"
                    // placeholder="₹500.00"
                    field={"price"}
                    register={register}
                    errors={errors}
                    disabled={true}
                  />
                  {errors?.price?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"price"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="quantity"
                    text="Quantity"
                    type="number"
                    placeholder="Enter quantity"
                    field={"quantity"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.quantity?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"quantity"} />
                      </span>
                    </div>
                  )}
                </Col>
                {/* <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="avaiableQuantity"
                    text="Avaiable Quantity"
                    type="number"
                    placeholder="Enter Avaiable Quantity"
                    field={"avaiableQuantity"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.avaiableQuantity?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"avaiableQuantity"}
                        />
                      </span>
                    </div>
                  )}
                </Col> */}

                {/* <Col xs={6} className="form-group">
                  <label>Mfg. Date</label>
                  <Controller
                    control={control}
                    name="mfgDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        className="form-control"
                        customclassName="form-control"
                        onChange={(date) => onChange(date)}
                        selected={value}
                      />
                    )}
                  />
                  {errors?.mfgDate?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"mfgDate"} />
                      </span>
                    </div>
                  )}
                </Col> */}
                {!remarkShow && (
                  <Col xs={6} className="form-group">
                    <label>Exp. Date</label>
                    <Controller
                      control={control}
                      name="expDate"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className="form-control"
                          customclassName="form-control"
                          onChange={(date) => onChange(date)}
                          selected={value}
                        />
                      )}
                    />
                    {errors?.expDate?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"expDate"} />
                        </span>
                      </div>
                    )}
                  </Col>
                )}

                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="totalAmount"
                    text="Total Amount"
                    type="number"
                    autoFocus={true}
                    // placeholder="₹5,000.00"
                    field={"totalAmount"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.totalAmount?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"totalAmount"} />
                      </span>
                    </div>
                  )}
                </Col>

                {remarkShow && (
                  <>
                    <Col xs={6} className="form-group">
                      <label>Reason to out stock</label>
                      <select {...register("selectReason")}>
                        <option value="" selected>
                          select reason
                        </option>
                        <option value="consume">Consume</option>
                        <option value="expired">Expired</option>
                        <option value="damage">Damage</option>
                        <option value="transfer">Transfer</option>
                      </select>
                      {errors?.selectReason?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"selectReason"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={12} className="form-group">
                      <InputField
                        htmlFor="remark"
                        text="Remark"
                        type="textArea"
                        autoFocus={true}
                        placeholder="reason to out stock"
                        field={"remark"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.remark?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"remark"} />
                          </span>
                        </div>
                      )}
                    </Col>
                  </>
                )}
                {
                  //item && (
                  // <label>
                  //   Available Stock:{" "}
                  //   {itemDataMaster.find((i) => i.id == item?.value)?.quantity -
                  //     quantity}
                  // </label>
                  // )
                }
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  {/* <Button className="greyBtn" variant="outline-primary">Cancel</Button> */}
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showOutStock}
        onHide={() => {
          setShowOutStock(false);
          reset();
          setEditModes(false);
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editModes ? "Edit Out Stock Form" : "Out Stock Form"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmitsOutstock)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <label>Select Employee</label>
                  <select {...register("selectEmployeeId")}>
                    {usersDetail?.map((item) => (
                      <option value={item?.id}>
                        {item?.firstName + "-" + item?.userCode}
                      </option>
                    ))}
                  </select>
                  {errors?.selectEmployeeId?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"selectEmployeeId"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <label>Select Category</label>
                  <select {...register("selectCategory")}>
                    <select disabled selected>
                      Select
                    </select>
                    {tableData.map((data, key) => (
                      <option value={data.id} key={key}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  {errors?.selectCategory?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"selectCategory"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <label>Item</label>
                  <Controller
                    control={control}
                    name="item"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={itemsData}
                        placeholder={"Select"}
                        value={value}
                        onChange={(dataSet) => {
                          onChange(dataSet);
                          itemChangeHandler(dataSet);
                        }}
                        isSearchable={true}
                      />
                    )}
                  />
                  {errors?.item?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"item"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="quantity"
                    text="Quantity"
                    type="text"
                    placeholder="Enter quantity"
                    field={"quantity"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.quantity?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"quantity"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="unit"
                    text="Unit"
                    type="text"
                    placeholder="gm"
                    field={"unit"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.unit?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"unit"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <label>Reason to out stock</label>
                  <select {...register("selectReason")}>
                    <option>select reason</option>
                    <option value="consume">Consume</option>
                    <option value="expired">Expired</option>
                    <option value="damage">Damage</option>
                    <option value="transfer">Transfer</option>
                  </select>
                  {errors?.selectReason?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"selectReason"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <label>Remark</label>
                  <textarea
                    register={register}
                    {...register("remark")}
                    placeholder={"Note*"}
                  ></textarea>

                  {errors?.remark?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"remark"} />
                      </span>
                    </div>
                  )}
                </Col>
                {item && (
                  <label>
                    Available Stock:{" "}
                    {itemDataMaster.find((i) => i.id == item?.value)?.quantity -
                      quantity}
                  </label>
                )}
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal> */}
    </Layout>
  );
};

export default Inventory;
