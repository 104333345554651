import {
  Button,
  Form,
  Modal,
  Col,
  Row,
  Table,
  Container,
} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import Layout from "../../layout";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  invoiceDataForRrooms,
  invoiceFinanceService,
  invoicePayment,
  invoicePaymentOffline,
  invoicePaymentVerify,
} from "../../API/services/newServices";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { InputField } from "components/InputField/InputField";
import { Imagesdata } from "assets/images/imageData";
import validation from "./Validation";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { showPrice } from "utils/utils";
import {
  limitItemsBooking,
  paymentInvoiceStatus,
  paymentMainModes,
  paymentModes,
} from "constant/constants";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { useDownloadExcel } from "react-export-table-to-excel";

function InvoiceFinance() {
  const tableRef = useRef();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    ...validation,
  });

  const { AlertIcon } = Imagesdata;

  const [show, setShow] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showProof, setShowProof] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [paymentTypeNumber, setPaymentTypeNumber] = useState("");
  const [searchSelectVal, setSearchSelectVal] = useState({});

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [firstTime, setFirstTime] = useState(true);

  const [tableData, setTableData] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  const [limit, setLimit] = useState(25);

  const getDataHandler = async (id, status = null) => {
    let res = {};
    if (userDetail?.logintype === "rroomUser") {
      res = await invoiceDataForRrooms(
        firstTime
          ? `?${id ? `propertyId=${id}&` : ""}${
              status ? `paymentStatus=${status}` : ""
            }&page=0&size=${limit}`
          : `?${id ? `propertyId=${id}&` : ""}${
              status ? `paymentStatus=${status}` : ""
            }fromDate=${moment(startDate).format("YYYY-DD-MM")}&toDate=${moment(
              endDate
            ).format("YYYY-DD-MM")}&page=0&size=${limit}`
      );
    } else {
      res = await invoiceFinanceService(id);
    }
    if (res) {
      setTableData(res.data);
    }
  };

  useMemo(() => {
    getDataHandler(userDetail?.propertyId);
  }, [startDate, endDate, limit]);

  const onHide = () => {
    setShow(false);
    reset();
    setPaymentTypeNumber("");
    setValue("paymentMode", "");
  };

  const onSubmit = async (payload) => {
    const formData = new FormData();
    formData.append("invoice_id", selectedItem.invoice_id);
    formData.append("payment_source", payload.paymentMode);
    formData.append("payment_date", moment(payload.date).format("YYYY-MM-DD"));
    formData.append("ref_number", payload.payNumber);
    formData.append("file", payload.proof[0]);

    try {
      const res = await invoicePaymentOffline(formData);
      if (res) {
        getDataHandler(userDetail?.propertyId);
        onHide();
      }
    } catch (error) {}
  };

  const verifyPayment = async (row) => {
    const param = { invoice_id: row.invoice_id, payment_status: 1 };
    const res = await invoicePaymentVerify(param);
    if (res) {
      getDataHandler(searchSelectVal.id);
      toast.success("Payment Verified Success", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const onlineSubmit = async (item) => {
    try {
      const res = await invoicePayment(item.invoice_id);
      if (res.status == 200) {
        window.open(
          res.data.data.data.instrumentResponse.redirectInfo.url,
          "_self"
        );
      }
    } catch (error) {}
  };
  const paymentStart = (item) => {
    setShow(true);
    setSelectedItem(item);
  };
  const openDetails = (item) => {
    setSelectedItem(item);
    setShowDetailsModal(true);
  };
  const hideProof = () => {
    setShowProof(false);
    setShowDetailsModal(true);
  };
  const STORY_HEADERS = [
    {
      prop: "invoice_id",
      title: "Invoice Number",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <span
          className={
            row.paymentStatus == "pending" ? "text-black" : "text-primary"
          }
          style={{
            cursor: row.paymentStatus == "pending" ? "auto" : "pointer",
          }}
          onClick={
            row.paymentStatus == "pending" ? () => {} : () => openDetails(row)
          }
        >
          {row.invoice_id}
        </span>
      ),
    },
    ...(userDetail?.logintype === "rroomUser"
      ? [
          {
            prop: "name",
            title: "Property Name",
            isFilterable: true,
            isSortable: true,
            cell: (row) => row.PropertyMaster?.name,
          },
          {
            prop: "propertyCode",
            title: "Property Code",
            isFilterable: true,
            isSortable: true,
            cell: (row) => row.PropertyMaster?.propertyCode,
          },
          {
            prop: "totalPayableAmount",
            title: "Invoice Amount",
            isFilterable: true,
            isSortable: true,
            cell: (row) => showPrice(row.totalPayableAmount),
          },
          {
            prop: "merchantTransactionId",
            title: "Transaction ID",
            isFilterable: true,
            isSortable: true,
            cell: (row) => row.merchantTransactionId ?? "N/A",
          },
          {
            prop: "paymentMode",
            title: "Payment Mode",
            isFilterable: true,
            isSortable: true,
            cell: (row) =>
              row.paymentMode !== null
                ? paymentMainModes[row.paymentMode]
                : "N/A",
          },
          {
            prop: "paymentSource",
            title: "Payment Source",
            isFilterable: true,
            isSortable: true,
            cell: (row) => row.paymentSource ?? "N/A",
          },
          {
            prop: "paymentDate",
            title: "Payment Date",
            isFilterable: true,
            isSortable: true,
            cell: (row) =>
              row.paymentDate
                ? moment(row.paymentDate).format("DD MMM, YYYY")
                : moment(row.createdAt).format("DD MMM, YYYY"),
          },
        ]
      : [
          {
            prop: "totalCommission",
            title: "Total Amount(commision)",
            isFilterable: true,
            isSortable: true,
            cell: (row) => showPrice(row.totalCommission),
          },
          {
            prop: "sgstAmount",
            title: "SGST",
            isFilterable: true,
            isSortable: true,
            cell: (row) => showPrice(row.sgstAmount),
          },
          {
            prop: "cgstAmount",
            title: "CGST",
            isFilterable: true,
            isSortable: true,
            cell: (row) => showPrice(row.cgstAmount),
          },
          {
            prop: "totalPayableAmount",
            title: "Net Payable Amount",
            isFilterable: true,
            isSortable: true,
            cell: (row) => showPrice(row.totalPayableAmount),
          },
          {
            prop: "totalSale",
            title: "Total Sale",
            isFilterable: true,
            isSortable: true,
            cell: (row) => showPrice(row?.totalSale ?? 0),
          },
          {
            prop: "invoiceMonth",
            title: "Invoice Month",
            isSortable: true,
            isFilterable: true,
            cell: (row) => moment(row.invoiceMonth).format("MMMM, YYYY"),
          },
        ]),
    {
      prop: "paymentStatus",
      title: "Payment Status",
      isSortable: true,
      isFilterable: true,
      // cell: (row) => capitalize(row.paymentStatus),
    },
    {
      prop: "action",
      title: "Action",
      isSortable: true,
      isFilterable: true,
      cell: (row) => {
        return (
          <>
            {userDetail?.logintype === "rroomUser" ? (
              row.paymentStatus === "inreview" && (
                <Button
                  onClick={() => verifyPayment(row)}
                  variant="success"
                  className="btn-sm mr-2"
                >
                  Verify
                </Button>
              )
            ) : (
              <>
                {row.paymentStatus === "pending" ? (
                  <>
                    <Button
                      variant="danger"
                      className="btn-sm mr-2"
                      onClick={() => onlineSubmit(row)}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-money-bill" /> Pay
                      Online
                    </Button>
                    <Button
                      variant="danger"
                      className="btn-sm mr-2"
                      onClick={() => paymentStart(row)}
                    >
                      Submit Payment Record
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => openDetails(row)}
                    className="btn-sm mr-2"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-eye" />
                  </Button>
                )}

                <Button
                  onClick={() =>
                    navigate(
                      `/hotel-finance/invoice-formate/${row.invoice_id}/${userDetail?.propertyId}`
                    )
                  }
                  variant="success"
                  className="btn-sm"
                >
                  <FontAwesomeIcon icon="fa-solid fa-download" />
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "invoice_hotel_finance" });
  }, []);

  const { paymentMode } = watch();

  useMemo(() => {
    if (paymentMode) {
      const text =
        paymentMode === "Bank Transfer"
          ? "UTR Number"
          : paymentMode === "UPI"
          ? "Transaction Id"
          : "Cheque Number";
      setPaymentTypeNumber(text);
    }
  }, [paymentMode]);

  const changeHandler = (item) => {
    setSearchSelectVal(item);
    getDataHandler(item.id);
  };

  const searchfilter = (value = null) => {
    getDataHandler(0, value);
  };
  const changeHandlerState = () => {
    setFirstTime(false);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "invoice-details",
    sheet: "invoice-details",
  });
  return (
    <Layout
      title={"Hotel Invoice"}
      searchfilter={startDate}
      type
      fromDate={startDate}
      toDate={endDate}
      setToDate={setEndDate}
      setFromDate={setStartDate}
      searchType={"date"}
      openHandler={onDownload}
      buttonTitle="Export Table"
      searchSelectVal={searchSelectVal}
      changeHandler={changeHandler}
      setSearchfilter={searchfilter}
      optionData={paymentInvoiceStatus}
      changeHandlerState={changeHandlerState}
      wantSearchableListFor={
        userDetail?.logintype === "rroomUser" ? "property" : ""
      }
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10000,
              options: [1000, 2000, 10000],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <select
                className="selectTableItemsSelect"
                onChange={({ target }) => setLimit(target.value)}
              >
                {limitItemsBooking.map((item, ind) => (
                  <option value={item} key={ind}>
                    {item}
                  </option>
                ))}
              </select>
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <div
            className={`scrollTable invoiceTable ${
              userDetail?.logintype === "rroomUser" && "isRoomAdmin"
            }`}
          >
            <Table className="food-report" ref={tableRef}>
              <TableHeader />
              <TableBody />
            </Table>
          </div>
        </DatatableWrapper>
      </Row>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="checkInModal"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Payment</Modal.Title>
          <button className="closeButton" onClick={onHide}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
            {/* <Form.Label className="label m-0 p-0">
              Select Payment Mode:
              <small className="text-danger fw-bold fs-6">*</small>
            </Form.Label>
            <div className="d-flex gap-3">
              <div
                className="radio-group d-flex gap-3 align-items-center w-50 px-2 py-1"
                style={{
                  backgroundColor: paymentMode == 1 ? "#e3f1ff" : "#fff",
                }}
                onClick={() => {
                  setValidationObj(validation2);
                  setPaymentMode(1);
                }}
              >
                <input
                  className="w-auto"
                  type="radio"
                  value={1}
                  checked={paymentMode == 1}
                  id="online"
                />
                <label htmlFor="online">
                  <FontAwesomeIcon
                    icon="fa-solid fa-credit-card"
                    color="#007bff"
                  />{" "}
                  Online
                </label>
              </div>
              <div
                className="radio-group d-flex gap-3 align-items-center w-50 px-2 py-1"
                style={{
                  backgroundColor: paymentMode == 2 ? "#e3f1ff" : "#fff",
                }}
                onClick={() => {
                  setPaymentMode(2);
                  setValidationObj(validation);
                }}
              >
                <input
                  className="w-auto"
                  type="radio"
                  value={2}
                  checked={paymentMode == 2}
                  id="offline"
                />
                <label htmlFor="offline">
                  <FontAwesomeIcon
                    icon="fa-solid fa-money-bill"
                    color="#007bff"
                  />{" "}
                  Offline
                </label>
              </div>
            </div> */}
            <div className="form-group mb-0 mx-2">
              <Form.Label className="label">
                Select Payment Source{" "}
                <small className="text-danger fw-bold fs-6">*</small>
              </Form.Label>
              <Controller
                control={control}
                name="paymentMode"
                rules={{ required: "Please Select Payment Method" }}
                render={({ field: { onChange, value } }) => (
                  <select
                    {...register("paymentMode")}
                    value={value}
                    onChange={onChange}
                    className="form-control"
                  >
                    {paymentModes.map((item, ind) => (
                      <option
                        selected={ind == 0}
                        disabled={ind == 0}
                        value={ind == 0 ? "" : item}
                        key={ind}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                )}
              />
              {errors?.paymentMode?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"paymentMode"} />
                  </span>
                </div>
              )}
            </div>
            {paymentTypeNumber.length > 0 ? (
              <div className="form-group mb-0 mx-2">
                <InputField
                  htmlFor="payNumber"
                  text={paymentTypeNumber}
                  placeholder={"Enter " + paymentTypeNumber}
                  type="text"
                  field={"payNumber"}
                  customclassName="form-control"
                  register={register}
                  errors={errors}
                  notnull
                />
                {errors?.payNumber?.message && (
                  <div className="error-text">
                    <img alt="" src={AlertIcon} />
                    <span>
                      <ErrorMessage errors={errors} name={"payNumber"} />
                    </span>
                  </div>
                )}
              </div>
            ) : null}
            <div className="form-group mb-0 mx-2">
              <InputField
                htmlFor="proof"
                text="Upload payment proof image"
                type="file"
                field={"proof"}
                customclassName="form-control"
                register={register}
                errors={errors}
                notnull
              />
              {errors?.proof?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"proof"} />
                  </span>
                </div>
              )}
            </div>
            <div className="form-group mb-0 mx-2">
              <InputField
                htmlFor="date"
                text="Select Payment Date"
                type="date"
                field={"date"}
                customclassName="form-control"
                register={register}
                errors={errors}
                notnull
              />
              {errors?.date?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"date"} />
                  </span>
                </div>
              )}
            </div>

            <Modal.Footer>
              <Button type="submit">Submit</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="checkInModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Invoice Payment Details
          </Modal.Title>
          <button
            className="closeButton"
            onClick={() => setShowDetailsModal(false)}
          >
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container className="invoicePaymentDetails">
            <Row>
              <Col>Invoice Number:</Col>
              <Col className="text-end text-primary">
                #{selectedItem.invoice_id}
              </Col>
            </Row>
            <Row>
              <Col>Amount:</Col>
              <Col className="text-end">
                {showPrice(selectedItem.totalPayableAmount)}
              </Col>
            </Row>
            <Row>
              <Col>Transaction ID:</Col>
              <Col className="text-end">
                {selectedItem.merchantTransactionId ?? "N/A"}
              </Col>
            </Row>
            <Row>
              <Col>Payment Mode:</Col>
              <Col className="text-end">
                {paymentMainModes[selectedItem.paymentMode]}
              </Col>
            </Row>
            <Row>
              <Col>Payment Source:</Col>
              <Col className="text-end text-success text-capitalize">
                {selectedItem.paymentSource != "ONLINE" ? (
                  <FontAwesomeIcon icon="fa-solid fa-money-check" />
                ) : (
                  <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" />
                )}{" "}
                {selectedItem.paymentSource}
              </Col>
            </Row>
            <Row>
              <Col>Payment Status:</Col>
              <Col className="text-end text-danger text-capitalize">
                {selectedItem.paymentStatus}
              </Col>
            </Row>
            <Row>
              <Col>Payment Proof:</Col>
              <Col className="text-end">
                <Button
                  className="btn-sm"
                  onClick={() => {
                    setShowProof(true);
                    setShowDetailsModal(false);
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-eye" /> Show
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>Payment Date:</Col>
              <Col className="text-end">
                {moment(selectedItem.createdAt).format("DD-MM-YYYY HH:MM A")}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showProof}
        onHide={hideProof}
        aria-labelledby="contained-modal-title-vcenter"
        className="checkInModal"
        backdropClassName="bg-dark"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Proof Image
          </Modal.Title>
          <button className="closeButton" onClick={hideProof}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container className="invoicePaymentDetails">
            <img alt="" src={selectedItem.documentUrl} />
          </Container>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default InvoiceFinance;
