import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../layout";
import { Button, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Services from "../../API/services";
import moment from "moment";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import ReactGA from "react-ga4";

const ORDER_STATUS = {
  BOOKING: 1,
  FOOD: 2,
};

const PAYMENT_MODES_TEXT = [
  "",
  "PREPAID",
  "CASH",
  "CARD",
  "FOC",
  "UPI",
  "PAYLATER",
  "PREPAID",
];

const PaymentReport = () => {
  const [paymentSet, setPaymentSet] = useState([]);
  const [foodData, setFoodData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterDate, setFilterDate] = useState({
    toDate: null,
    fromDate: null,
  });
  const [activeTab, setActiveTab] = useState(ORDER_STATUS.BOOKING);
  const [exportFilter, setExportFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [show, setShow] = useState(false);

  const { userDetail } = useSelector(authenticationSeletor);

  const dateFilter = async (filterDate) => {
    try {
      const payload = {
        fromDate: filterDate.fromDate,
        toDate: filterDate.toDate,
        propertyId: userDetail?.propertyId,
      };
      const prtPayload =
        userDetail.logintype == "rroomUser"
          ? "?"
          : `?property_id=${userDetail?.propertyId}${
              filterDate.fromDate
                ? "&from_at=" + moment(filterDate.fromDate).format("YYYY-MM-DD")
                : ""
            }${
              filterDate.toDate
                ? "&to_at=" + moment(filterDate.toDate).format("YYYY-MM-DD")
                : ""
            }`;
      let res = {};
      if (activeTab == 2) {
        res = await Services.globalService.getFoodPaymentDetailsData(
          prtPayload
        );
      } else {
        res = await Services.globalService.getPaymentDetailsData(payload);
      }
      if (res.status === 200) {
        setPaymentSet(res.data.data);
      }
    } catch (error) {}
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    // ...validation,
  });

  const onSubmit = async (payload) => {
    onHide();
  };

  useMemo(() => {
    dateFilter(filterDate);
  }, [filterDate]);

  const getData = async () => {
    try {
      const payload = {
        propertyId: userDetail?.propertyId,
      };
      const res = await Services.globalService.getPaymentDetailsData(payload);
      if (res.status === 200) {
        setPaymentSet(res.data.data);
      }
    } catch (error) {}
  };
  const getFoodData = async () => {
    const payload =
      userDetail.logintype == "rroomUser"
        ? ""
        : `?property_id=${userDetail?.propertyId}`;
    try {
      const res = await Services.globalService.getFoodPaymentDetailsData(
        payload
      );
      if (res.status === 200) {
        setFoodData(res.data.data);
        paymentSet[ORDER_STATUS.BOOKING] = res.data.data;
      }
    } catch (error) {}
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "payment_report" });
    getData();
    getFoodData();
  }, []);

  const onHide = () => {
    setShow(false);
    // reset();
    // setPaymentTypeNumber("");
    // setValue("paymentMode", "");
  };

  const editHandler = () => {
    setShow(true);
  };

  useEffect(() => {
    if (activeTab == 1) {
      setFilterData(paymentSet);
    } else if (activeTab == 2) {
      setFilterData(foodData);
    }
  }, [paymentSet, activeTab]);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "SearchResults",
    sheet: "SearchResults",
  });

  const propertyStatusTabs = useMemo(
    () => [
      {
        key: ORDER_STATUS.BOOKING,
        title: `Booking Payment`,
      },

      {
        key: ORDER_STATUS.FOOD,
        title: `Food Payment`,
      },
    ],
    [paymentSet]
  );
  const searchfilter = (text) => {
    const renderData = activeTab == 2 ? foodData : paymentSet;
    const resultsData = renderData.filter((order) => {
      if (text === "") return renderData;
      const filterStates =
        activeTab == 2
          ? order?.transactionId +
            order?.paymentAmount +
            order?.FoodOrder?.roomNumber +
            PAYMENT_MODES_TEXT[order?.paymentMode]
          : order?.transactionID +
            order?.bookingId +
            order?.paymentAmount +
            order?.BookingHotel?.assignRoomNo +
            PAYMENT_MODES_TEXT[order?.paymentMode] +
            order?.BookingHotel?.bookingCode;
      return filterStates
        ?.toString()
        .toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterData(resultsData);
  };
  const exportHandler = async () => {
    window.open(
      `https://api.rrooms.in/api/kpi/${
        activeTab == ORDER_STATUS.BOOKING
          ? "booking-payment-report"
          : "booking-food-payment-report"
      }?fromDate=${moment(exportFilter.startDate).format(
        "YYYY-MM-DD"
      )}&toDate=${moment(exportFilter.endDate).format(
        "YYYY-MM-DD"
      )}&propertyId=${userDetail?.propertyId}`,
      "_blank"
    );
  };
  return (
    <Layout
      title="Payment Report"
      extraBtnHandler={exportHandler}
      extraButton="Export Table"
      searchfilter={searchfilter}
      setFromDate={(date) =>
        setExportFilter({ ...exportFilter, startDate: date })
      }
      setToDate={(date) => setExportFilter({ ...exportFilter, endDate: date })}
      dateFilter={exportFilter}
      searchType={"date"}
      fromDate={exportFilter.startDate}
      toDate={exportFilter.endDate}
    >
      <div className="flex align-content-center">
        <div className="flex justify-content-between foodOrderTabs">
          <Tabs
            className="tabs mb-2"
            activeKey={activeTab}
            defaultActiveKey={ORDER_STATUS.UPCOMING}
            onSelect={(tabKey) => setActiveTab(tabKey)}
          >
            {propertyStatusTabs.map((tab, ind) => {
              return <Tab eventKey={tab.key} key={ind} title={tab.title} />;
            })}
          </Tabs>
        </div>
        <Col className="dateFilterDetails flex gap-3 justify-content-end align-items-center">
          <div className="form-group flex gap-3 m-0">
            <label
              className="flex gap-2 align-items-center text-black"
              htmlFor="fromDate"
            >
              <i className="fa fa-calendar  text-success"></i>From Date
            </label>
            <ReactDatePicker
              id="fromDate"
              className="form-control"
              customclassName="form-control"
              wrapperClassName="detailDatePicker"
              onChange={(date) =>
                setFilterDate({ ...filterDate, fromDate: date })
              }
              selected={filterDate.fromDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Date"
            />
          </div>
          <div className="form-group flex gap-3 m-0">
            <label
              className="flex gap-2 align-items-center text-black"
              htmlFor="toDate"
            >
              <i className="fa fa-calendar  text-danger"></i>To Date
            </label>
            <ReactDatePicker
              id="toDate"
              className="form-control"
              customclassName="form-control"
              wrapperClassName="detailDatePicker"
              onChange={(date) =>
                setFilterDate({ ...filterDate, toDate: date })
              }
              selected={filterDate.toDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Date"
            />
          </div>
          {(filterDate.fromDate || filterDate.toDate) && (
            <button
              className="btn-sm btn-danger"
              onClick={() => {
                getData();
                setFilterDate({ toDate: null, fromDate: null });
              }}
            >
              Reset
            </button>
          )}
        </Col>
      </div>
      <table
        className="common-table bordered w-100 table-hover bg-white"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Payment Mode</th>
            <th>Payment Date</th>
            <th>Transaction ID</th>
            <th>Amount</th>
            <th>Room Number</th>
            {activeTab != 2 && <th>Booking Id</th>}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filterData?.length > 0 ? (
            [...filterData]?.reverse()?.map((item, ind) => {
              return (
                <tr className="text-black" key={ind}>
                  <td>{ind + 1}</td>
                  <td>{PAYMENT_MODES_TEXT[item?.paymentMode]}</td>
                  <td>{moment(item?.createdAt).format("DD MMM, YYYY")}</td>
                  <td>{item?.transactionID ?? item?.transactionId ?? "N/A"}</td>
                  <td>₹ {item?.paymentAmount ?? "N/A"}</td>
                  <td>
                    {activeTab == 2
                      ? item?.FoodOrder?.roomNumber
                      : item?.BookingHotel?.assignRoomNo ?? "N/A"}
                  </td>
                  {activeTab != 2 && (
                    <td>#{item?.BookingHotel?.bookingCode ?? "N/A"}</td>
                  )}
                  <td>
                    <Button
                      className="btn-sm"
                      onClick={() => editHandler(item)}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={12}>
                <div
                  className="flex flex-column align-center justify-content-center full-width"
                  style={{ height: "10rem" }}
                >
                  <FontAwesomeIcon
                    className="mb-2"
                    icon="fa-solid fa-ban"
                    style={{ width: "40px", height: "40px" }}
                    beat
                  />
                  <span>No Data</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="checkInModal"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Payment Mode
          </Modal.Title>
          <button className="closeButton" onClick={onHide}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12} className="form-group">
              <label htmlFor="">Select Payment Mode</label>
              <select {...register("paymentMode")} className="form-control">
                <option selected value="">
                  Select Payment Mode
                </option>
                {PAYMENT_MODES_TEXT.map((mode, index) => (
                  <option value={mode} key={index}>
                    {mode}
                  </option>
                ))}
              </select>
            </Col>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default PaymentReport;
