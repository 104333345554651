import { createSlice } from "@reduxjs/toolkit";

const ModalSlice = createSlice({
  name: "modalSlice",
  initialState: { showModal: false, loading: false },
  reducers: {
    setShowModal: (state, { payload }) => {
      state.showModal = payload;
    },
    setLoadingApi: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const { setShowModal, setLoadingApi } = ModalSlice.actions;

export default ModalSlice.reducer;
