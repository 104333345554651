import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Services from "API/services";
import {
  assingModules,
  getAssingModules,
  getModules,
} from "API/services/newServices";
import Layout from "layout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authenticationSeletor } from "store/slices/authenticationSlice";

function ModuleConfiguration() {
  const { userDetail } = useSelector(authenticationSeletor);
  const { id } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [selected, setSelected] = useState([]);
  const [user, setUser] = useState({});
  const [tableData, setTableData] = useState([]);

  const getDataHandler = async () => {
    const param = { page: 0, size: 100 };
    const res = await getModules(param);
    if (res) {
      setTableData(res);
    }
  };
  const getSelectedUser = async () => {
    const res = await Services.auth.getPropetyUserData(id);
    if (res.status === 200) {
      setUser(res.data.data);
    }
  };

  useEffect(() => {
    if (id) {
      getSelectedUser();
    }
    getDataHandler();
  }, []);
  const usersModule = async () => {
    const res = await getAssingModules({ id, property: userDetail.propertyId });
    if (res) {
      const final = res.map((i) => ({
        group_id: i.groupId,
        active_menu_ids: JSON.parse(i.activeMenuIds),
      }));
      setSelected(final);
    }
  };
  useEffect(() => {
    usersModule();
  }, []);
  const changeHandler = (group, menu, allMenus = false) => {
    if (!menu) {
      if (selected.some((i) => i.group_id == group)) {
        setSelected(selected.filter((i) => i.group_id != group));
      } else {
        setSelected([
          ...selected,
          { group_id: group, active_menu_ids: allMenus.map((i) => i.id) },
        ]);
      }
    } else {
      const temp = selected.find((i) => i.group_id == group);
      let finalData = [];
      if (selected.some((i) => i.active_menu_ids.includes(+menu))) {
        const ids = temp.active_menu_ids.filter((m) => m != menu);
        finalData = selected.map((i) => {
          if (i.group_id == group) {
            return { ...temp, active_menu_ids: ids };
          }
          return i;
        });
      } else {
        const tempData = [...selected];
        if (!temp) {
          setSelected([
            ...selected,
            {
              group_id: group,
              active_menu_ids: [+menu],
            },
          ]);
          return;
        }
        temp.active_menu_ids = [...temp.active_menu_ids, +menu];

        finalData = tempData.map((i) => {
          if (i.group_id == group) {
            return temp;
          }
          return i;
        });
      }

      setSelected(finalData.filter((i) => !!i.active_menu_ids.length));
    }
  };
  const editStateManage = async () => {
    if (editMode) {
      const param = {
        editMode: false,
        id,
        body: {
          property_id: userDetail.propertyId,
          property_user_id: id,
          data: selected,
        },
      };
      const res = await assingModules(param);
      if (res) {
        toast.success("Module confirguration setting updated");
      }
    }
    setEditMode(!editMode);
  };

  return (
    <Layout
      title={`Module Configuration ${id ? `(Name: ${user.firstName})` : ""}`}
      openHandler={editStateManage}
      buttonTitle={editMode ? "Save" : "Edit"}
    >
      <ul className="Module mt-2">
        {tableData.map((item, ind) => {
          return (
            <li key={ind} className="Module_Item mb-4 border rounded shadow-sm">
              <label htmlFor={`module${ind}`} className={`activeLable mainLi`}>
                <input
                  id={`module${ind}`}
                  type="checkbox"
                  value={item.id}
                  disabled={!editMode}
                  checked={selected.some((i) => i.group_id == item.id)}
                  onChange={({ target }) => {
                    changeHandler(target.value, false, item?.menues);
                  }}
                />
                {item?.groupName}
              </label>
              <ul className="Module_inner p-2">
                {!item?.menues.length ? (
                  <div className="flex flex-column align-center justify-content-center w-100 text-black">
                    <FontAwesomeIcon
                      className="mb-2"
                      icon="fa-solid fa-ban"
                      size="lg"
                      beat
                    />
                    <span style={{ fontSize: ".8rem" }}>
                      Menu not Available
                    </span>
                  </div>
                ) : (
                  item?.menues.map((sub, ind1) => {
                    return (
                      <li key={ind1}>
                        <label
                          htmlFor={`module_sub${sub.id}`}
                          className={`activeLable`}
                        >
                          <input
                            checked={selected.some((i) =>
                              i.active_menu_ids.includes(sub.id)
                            )}
                            onChange={({ target }) =>
                              changeHandler(item.id, target.value)
                            }
                            id={`module_sub${sub.id}`}
                            type="checkbox"
                            disabled={!editMode}
                            value={sub.id}
                          />
                          {sub.menuName}
                        </label>
                      </li>
                    );
                  })
                )}
              </ul>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
}

export default ModuleConfiguration;
