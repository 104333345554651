import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import Services from "../../API/services";
import { useEffect, useMemo, useRef, useState } from "react";
import Layout from "../../layout";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import validation from "./validation";
import validation2 from "./validation2";
import { Imagesdata } from "../../assets/images/imageData";
import { ErrorMessage } from "@hookform/error-message";
import { InputField } from "../../components/InputField/InputField";
import { toast } from "react-toastify";

const ORDER_STATUS = {
  CITY: 1,
  LOCALITY: 2,
};

// Then, use it in a component.
export default function FoodReport() {
  const [activeTab, setActiveTab] = useState(ORDER_STATUS.CITY);
  const [tableData, setTableData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [localityData, setLocalityData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectItem, setSelectItem] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [importByExcelState, setImportByExcelState] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const { AlertIcon } = Imagesdata;
  const [validations, setValidations] = useState(validation);
  const formateTable = useRef();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    ...validations,
    mode: "onSubmit",
  });

  const [selectedItemAction, setSelectedItemAction] = useState([]);
  const getStateData = async () => {
    try {
      const res = await Services.globalService.getAllState();
      if (res.status === 200) {
        setStateData(res.data.data);
      }
    } catch (error) {}
  };
  const getDataHandler = async () => {
    try {
      const res = await Services.globalService.getAllCities();
      if (res.status === 200) {
        setCityData(res.data.data);
      }
    } catch (error) {}
  };
  const getDataHandlerLocality = async () => {
    try {
      const res = await Services.globalService.getAllLocality();
      if (res.status === 200) {
        setLocalityData(res.data.data);
      }
    } catch (error) {}
  };
  const onSubmit = async (payload) => {
    try {
      const { city, state, locality, cityCode } = payload;
      let res = {};
      let params = {};
      if (activeTab == ORDER_STATUS.CITY) {
        params = {
          name: city,
          state_id: state,
          state_code: cityCode,
          country_id: 101,
        };
      } else {
        const stateArr = state.split(",");
        params = {
          name: locality,
          city_id: stateArr[0],
          state_id: stateArr[1],
          pin_code: city,
        };
      }
      if (editMode) {
        if (activeTab == ORDER_STATUS.CITY) {
          res = await Services.globalService.editCity(selectItem.id, params);
        } else {
          res = await Services.globalService.editLocality(
            selectItem.id,
            params
          );
        }
        setEditMode(false);
      } else {
        if (activeTab == ORDER_STATUS.CITY) {
          res = await Services.globalService.createCity(params);
        } else {
          res = await Services.globalService.createLocality(params);
        }
      }
      if (res.status == 200) {
        reset();
        setShow(false);
        if (activeTab == ORDER_STATUS.CITY) {
          getDataHandler();
        } else {
          getDataHandlerLocality();
        }
        toast.success(
          `Details Successfully ${!editMode ? "Submitted" : "Updated"} !`,
          {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          }
        );
      }
    } catch (error) {
      toast.error("some error occured", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const { onDownload: excelDownLoadFormate } = useDownloadExcel({
    currentTableRef: formateTable.current,
    filename: "bulk Menu Items",
    sheet: "FoodOrders",
  });

  const editHandler = (data) => {
    setShow(true);
    setEditMode(true);
    setSelectItem(data);
    if (activeTab == ORDER_STATUS.CITY) {
      setValue("city", data.name);
      setValue("state", data.state_id);
      setValue("cityCode", data.state_code);
    } else {
      setValue("locality", data.name);
      setValue("city", data.pin_code);
      setValue("state", data.city_id + "," + data.state_id);
    }
  };
  const deleteHandler = async (id) => {
    if (!window.confirm("Are You sure want to delete city data")) {
      return;
    }
    try {
      let res = {};
      if (activeTab == ORDER_STATUS.CITY) {
        res = await Services.globalService.deleteCityData(id);
      } else {
        res = await Services.globalService.deleteLocality(id);
      }
      if (res.status == 200) {
        if (activeTab == ORDER_STATUS.CITY) {
          getDataHandler();
        } else {
          getDataHandlerLocality();
        }
        toast.success("successfully Deleted", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  const cityLocalityState = useMemo(
    () => [
      {
        key: ORDER_STATUS.CITY,
        title: `City & State`,
      },

      {
        key: ORDER_STATUS.LOCALITY,
        title: `Locality`,
      },
    ],
    [tableData]
  );
  useEffect(() => {
    getDataHandler();
    getDataHandlerLocality();
    getStateData();
  }, []);
  useEffect(() => {
    if (activeTab == ORDER_STATUS.CITY) {
      setValidations(validation);
      setTableData(cityData);
    } else {
      setValidations(validation2);
      setTableData(localityData);
    }
  }, [cityData, localityData, activeTab]);
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "cityLocality",
    sheet: "cityLocality",
  });
  const selectAllRef = useRef();

  const selectAll = (e) => {
    if (e.target.checked) {
      setSelectedItemAction(tableData.map((i) => i.id));
    } else {
      setSelectedItemAction([]);
    }
  };

  const importByExcel = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setExcelData(data);
    });
  };
  const excelSubmit = async () => {
    let count = 0;

    excelData?.map(async (item) => {
      let realPayload = {};
      if (activeTab == ORDER_STATUS.CITY) {
        realPayload = {
          name: item["City Name"],
          state_id: item["State Id"],
          state_code: item["City Code"],
          country_id: 101,
        };
      } else {
        realPayload = {
          name: item["Locality Name"],
          city_id: item["City Id"],
          state_id: item["State Id"],
          pin_code: item["Pincode"],
        };
      }
      let res = {};
      try {
        if (activeTab == ORDER_STATUS.CITY) {
          res = await Services.globalService.createCity(realPayload);
        } else {
          res = await Services.globalService.createLocality(realPayload);
        }
        if (res.status == 200) {
          count++;
        }
      } catch (error) {}
      if (count == excelData?.length) {
        getDataHandlerLocality();
        setShow(false);
        setImportByExcelState(false);
        setExcelData([]);
        if (activeTab == ORDER_STATUS.CITY) {
          getDataHandler();
        } else {
          getDataHandlerLocality();
        }
        toast.success(`Details Successfully Submitted!`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    });
  };
  const selectedItem = (id) => {
    if (selectedItemAction.includes(id)) {
      selectAllRef.current.checked = false;
      setSelectedItemAction(selectedItemAction.filter((i) => i != id));
    } else {
      setSelectedItemAction([...selectedItemAction, id]);
    }
  };
  const deleteSelectedItem = async () => {
    if (!window.confirm("Are You sure want to delete city data")) {
      return;
    }
    let count = 0;
    selectedItemAction.map(async (item) => {
      try {
        const res = await Services.globalService.deleteLocality(item);
        if (res.status == 200) {
          count++;
        }
      } catch (error) {}
      if (count == selectedItemAction.length) {
        getDataHandlerLocality();
        toast.success(`Locality Deleted Successfully!`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        count = 0;
        setSelectedItemAction([]);
      }
    });
  };
  const STORY_HEADERS = [
    ...(activeTab == ORDER_STATUS.LOCALITY
      ? [
          {
            prop: "check",
            title: (
              <div
                className="checkboxes__item"
                style={{ position: "absolute", left: 56 }}
              >
                <label className="checkbox style-c">
                  <input
                    type="checkbox"
                    onChange={selectAll}
                    ref={selectAllRef}
                  />
                  <div className="checkbox__checkmark"></div>
                </label>
              </div>
            ),
            cell: (row) => (
              <div className="checkboxes__item">
                <label className="checkbox style-c">
                  <input
                    type="checkbox"
                    checked={selectedItemAction.includes(row.id)}
                    onChange={(e) => selectedItem(row.id)}
                  />
                  <div className="checkbox__checkmark"></div>
                </label>
              </div>
            ),
          },
        ]
      : []),
    {
      prop: activeTab == ORDER_STATUS.CITY ? "id" : "city_id",
      title: activeTab == ORDER_STATUS.CITY ? "City Id" : "City Name",
      isFilterable: true,
      isSortable: true,
      cell: (row) =>
        activeTab == ORDER_STATUS.CITY
          ? row.id
          : cityData.find((i) => i.id == row.city_id)?.name,
    },
    {
      prop: "name",
      title: activeTab == ORDER_STATUS.CITY ? "City Name" : "Locality",
      isFilterable: true,
      isSortable: true,
    },
    // activeTab == ORDER_STATUS.LOCALITY && {
    //   prop: "city_id",
    //   title: "City",
    //   isFilterable: true,
    //   isSortable: true,
    // },
    {
      prop: activeTab == ORDER_STATUS.CITY ? "state_code" : "pin_code",
      title: activeTab == ORDER_STATUS.CITY ? "City Code" : "Pincode",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: activeTab == ORDER_STATUS.CITY ? "State" : "state_id",
      title: activeTab == ORDER_STATUS.CITY ? "State Name" : "State",
      isFilterable: true,
      isSortable: true,
      cell: (row) => stateData.find((i) => i.id == row.state_id)?.name,
    },
    {
      prop: "action",
      title: "Action",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <div className="button-group position-relative flex gap-2 justify-content-center">
          <Button
            variant="primary"
            className="btn-sm"
            onClick={() => editHandler(row)}
          >
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Button>
          {activeTab == ORDER_STATUS.LOCALITY && (
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => deleteHandler(row.id)}
            >
              <i className="fa fa-trash-o" aria-hidden="true"></i>
            </Button>
          )}
        </div>
      ),
    },
  ];
  return (
    <Layout
      title="City & Locality"
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      openHandler={() => setShow(!show)}
      secondBtn={() => setImportByExcelState(true)}
      secondbuttonTitle="Import Table"
    >
      {selectedItemAction.length > 0 && (
        <Button
          className="floatingButton"
          variant="danger"
          onClick={deleteSelectedItem}
        >
          Delete Selected Item
        </Button>
      )}
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 100,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col className="flex justify-content-between foodOrderTabs">
              <Tabs
                className="tabs mb-2"
                activeKey={activeTab}
                defaultActiveKey={ORDER_STATUS.CITY}
                onSelect={(tabKey) => setActiveTab(tabKey)}
              >
                {cityLocalityState.map((tab, ind) => {
                  return <Tab eventKey={tab.key} key={ind} title={tab.title} />;
                })}
              </Tabs>
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <Table className="food-report position-relative" ref={tableRef}>
            <TableHeader />
            <TableBody />
          </Table>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
      <Modal
        backdrop="static"
        show={show}
        onHide={() => {
          setShow(false);
          reset();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Details" : "Create Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={4} className="form-group">
                  <label>
                    {activeTab == ORDER_STATUS.CITY ? "State" : "City"} Name
                  </label>
                  <select register={register} {...register("state")}>
                    <option selected disabled value="">
                      Select {activeTab == ORDER_STATUS.CITY ? "State" : "City"}
                    </option>

                    {activeTab == ORDER_STATUS.CITY
                      ? stateData?.map((item, ind) => (
                          <option value={item.id} key={ind}>
                            {item.name}
                          </option>
                        ))
                      : cityData?.map((item, ind) => (
                          <option
                            value={item.id + "," + item?.state_id}
                            key={ind}
                          >
                            {item.name}
                          </option>
                        ))}
                  </select>
                  {errors?.state?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"state"} />
                      </span>
                    </div>
                  )}
                </Col>
                {activeTab == ORDER_STATUS.LOCALITY && (
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="locality"
                      text="Locality Name"
                      type="text"
                      placeholder="Enter Locality Name"
                      field={"locality"}
                      register={register}
                      errors={errors}
                      notnull
                    />
                    {errors?.locality?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"locality"} />
                        </span>
                      </div>
                    )}
                  </Col>
                )}
                <Col xs={4} className="form-group">
                  <InputField
                    htmlFor="city"
                    text={
                      activeTab == ORDER_STATUS.CITY ? "City Name" : "Pincode"
                    }
                    type="text"
                    placeholder={
                      activeTab == ORDER_STATUS.CITY
                        ? "Enter City Name"
                        : "Enter Pincode"
                    }
                    field={"city"}
                    max={activeTab == ORDER_STATUS.CITY ? 300 : 6}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.city?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"city"} />
                      </span>
                    </div>
                  )}
                </Col>
                {activeTab == ORDER_STATUS.CITY && (
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="cityCode"
                      text={"City Code"}
                      type="text"
                      placeholder={"Enter City Code"}
                      field={"cityCode"}
                      register={register}
                      errors={errors}
                      notnull
                    />
                    {errors?.cityCode?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"cityCode"} />
                        </span>
                      </div>
                    )}
                  </Col>
                )}

                <Row className="justify-content-center">
                  <Col xs={1}>
                    <Button type="submit">Submit</Button>
                  </Col>
                </Row>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={importByExcelState}
        onHide={() => {
          setImportByExcelState(false);
          setExcelData([]);
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Bulk Locality Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {excelData?.length > 0 ? (
            <>
              <div className="flex justify-content-end mb-2">
                <button className="btn-sm btn-primary" onClick={excelSubmit}>
                  Submit
                </button>
              </div>
              <Table striped bordered hover className="common-table">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>State Name</th>
                    <th>
                      {activeTab == ORDER_STATUS.CITY ? "City Name" : "City Id"}
                    </th>
                    <th>
                      {activeTab == ORDER_STATUS.CITY
                        ? "City Code"
                        : "Locality Name"}
                    </th>
                    {activeTab == ORDER_STATUS.LOCALITY && <th>Pincode</th>}
                  </tr>
                </thead>
                <tbody>
                  {excelData?.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td className="text-center">{key + 1}</td>
                        <td>
                          {
                            stateData.find((i) => i.id == data["State Id"])
                              ?.name
                          }
                        </td>
                        <td>
                          {activeTab == ORDER_STATUS.CITY
                            ? data["City Name"]
                            : cityData.find((i) => i.id == data["City Id"])
                                ?.name}
                        </td>
                        <td>
                          {activeTab == ORDER_STATUS.CITY
                            ? data["City Code"]
                            : data["Locality Name"]}
                        </td>
                        {activeTab == ORDER_STATUS.LOCALITY && (
                          <td>{data.Pincode}</td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <div className="flex gap-1 justify-content-between align-items-center ">
                <div className="flex form-group flex-column ">
                  <label htmlFor="excel">Select Excel File</label>
                  <input
                    type="file"
                    id="excel"
                    onChange={(e) => importByExcel(e.target.files[0])}
                  />
                </div>
                <button
                  className="btn-sm btn-primary text-white"
                  onClick={excelDownLoadFormate}
                >
                  Download Formate
                </button>
              </div>
              <table className="table border common-table" ref={formateTable}>
                <thead>
                  <tr>
                    <th>State ID</th>
                    <th>
                      {activeTab == ORDER_STATUS.CITY ? "City Name" : "City Id"}
                    </th>
                    <th>
                      {activeTab == ORDER_STATUS.CITY
                        ? "City Code"
                        : "Locality Name"}
                    </th>
                    {activeTab == ORDER_STATUS.LOCALITY && <th>Pincode</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>State ID</td>
                    <td>
                      {activeTab == ORDER_STATUS.CITY ? "City Name" : "City Id"}
                    </td>
                    <td>
                      {activeTab == ORDER_STATUS.CITY
                        ? "City Code"
                        : "Locality Name"}
                    </td>
                    {activeTab == ORDER_STATUS.LOCALITY && <td>Pincode</td>}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
