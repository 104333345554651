import { toast } from "react-toastify";

const AsyncHandler = (handler) => {
  return (params) =>
    Promise.resolve(handler(params)).catch((err) => {
      if (
        err?.response?.data?.message === "No user found by this mobile number"
      ) {
        return false;
      }

      if (err.config?.method == "post") {
        toast.error("Something went wrong, Please try again later");
      }
      return false;
    });
};

export default AsyncHandler;
