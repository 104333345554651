import AuthService from "./AuthService";
import UserService from "./UserService";
import PropertyService from "./PropertyService";
import GlobalService from "./globalService";
const Services = {
  auth: AuthService,
  user: UserService,
  property: PropertyService,
  globalService: GlobalService
};

export default Services;
