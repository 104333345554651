import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import services from "../../API/services";
import { InputField } from "../../components/InputField/InputField";
import {
  saveUserData,
  loginSuccess,
} from "../../store/slices/authenticationSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { Logolandingpage, AlertIcon, loginImg } = Imagesdata || {};
  const [phoneVal, SetPhoneVal] = useState("");
  const [realPhoneVal, SetRealPhoneVal] = useState("");
  const [showScreen, setShowScreen] = useState("");
  const [userData, setUserData] = useState({});
  const [otp, setOtp] = useState("");
  const [defaultOtp, setDefaultOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otherLogins, setOtherLogins] = useState(true);
  const [resendOtp, setResendOtp] = useState(false);
  const [count, setCount] = useState(30);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const timerForOtp = () => {
    const countRef = setTimeout(() => {
      setCount(() => count - 1);
    }, 1000);
    if (count <= 0) {
      clearTimeout(countRef);
      setResendOtp(true);
    }
  };

  useEffect(() => {
    if (showScreen === "otp") timerForOtp();
  }, [showScreen, count]);

  const loginHndler = async () => {
    let payload = { mobile: realPhoneVal };
    if (realPhoneVal.length != 10) {
      toast.error("Enter Correct Mobile Number*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }
    try {
      const res = await services.auth.userLogin(payload);
      if (res.status === 200) {
        if (!res.data.isNewUser) {
          // if (res.data.data.name === null) {
          //   setShowScreen("signup");
          //   setDefaultOtp(res.data.otp);
          // } else {
          setShowScreen("otp");
          setUserData(res.data.data);
          // }
        } else {
          setShowScreen("signup");
          setDefaultOtp(res.data.otp);
          setValue("phoneNumber", realPhoneVal);
        }
      }
    } catch (error) {}
  };
  const changeHandler = (value, country) => {
    SetPhoneVal(value);
    SetRealPhoneVal(value.replace(country.dialCode, ""));
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    try {
      const res = await services.auth.userLogin({ mobile: realPhoneVal });
      if (res.status === 200) {
        toast.success(`OTP resend on ${realPhoneVal}*`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        setCount(30);
        setResendOtp(false);
      }
    } catch (error) {}
  };

  const verifyHandler = async (payload) => {
    let payloadMain = {};
    if (showScreen === "otp") {
      if (otp.length < 4) {
        toast.error("Please Enter OTP*", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        return;
      }
      payloadMain = {
        mobile: userData.mobile,
        name: userData.name,
        email: userData.email,
        otp: otp,
        platform: 1,
      };
      try {
        const res = await services.auth.userVerify(payloadMain);
        if (res.status === 200 && res.data.success === true) {
          if (res?.data?.data.name && res?.data?.data.email) {
            dispatch(
              loginSuccess({
                data: { ...res?.data?.data, logintype: "normalUser" },
                token: res?.data?.token,
              })
            );
          } else {
            const {
              createdAt,
              deletedAt,
              id,
              lastLogged,
              mobile,
              otp,
              password,
              updatedAt,
            } = res?.data?.data;
            const { name, email } = payloadMain;
            dispatch(
              loginSuccess({
                data: {
                  createdAt,
                  deletedAt,
                  id,
                  name,
                  email,
                  lastLogged,
                  mobile,
                  otp,
                  password,
                  updatedAt,
                  logintype: "normalUser",
                },
                token: res?.data?.token,
              })
            );
          }
          navigate("/");
        } else {
        }
      } catch (error) {
        if (error.response.status === 400) {
          toast.error("Wrong OTP*", {
            autoClose: 2000,
            pauseOnHover: true,
            // position: "top-right",
          });
        }
      }
    } else {
      payloadMain = {
        mobile: payload.phoneNumber,
        name: payload.name,
        email: payload.email,
        otp: defaultOtp,
      };
      setUserData(payloadMain);
      setShowScreen("otp");
    }
  };
  return (
    <section className="loginSection flex flex-column flex-md-row">
      <div className="container-lg">
        <div className="topbar d-flex headerTop">
          <figure className="dashboardLogo">
            <a href="/">
              <img alt="" src={Logolandingpage} alt="Main logo" />
              {/* <h2>RRooms</h2> */}
            </a>
          </figure>
          <p>
            We make your stay as enjoyable & comfortable as you can imagine.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="wrapper loginWrapper d-flex">
          <div className="content">
            <h1>
              Don't think, <br /> Book in a blink !
            </h1>
            <p>
              "Sign up with us now and enjoy substantial discounts and savings
              on every stay with RROOMS."
            </p>
          </div>
          <div className="d-flex align-items-end loginContainer">
            {" "}
            <div className="img d-flex align-items-center">
              <figure>
                <img alt="" src={loginImg} alt="" />
              </figure>
            </div>
            <div className="form-wrap">
              <div className="head" style={{ textTransform: "uppercase" }}>
                Sign up & Get 30% off on your first RRooms booking
              </div>
              <div className="form-inner">
                {showScreen === "" && (
                  <form>
                    <h2>Login / Signup</h2>
                    <div className="form-group">
                      <PhoneInput
                        country={"in"}
                        onlyCountries={["in"]}
                        value={phoneVal}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <button type="button" onClick={loginHndler}>
                        Verify Number
                      </button>
                    </div>
                  </form>
                )}
                {showScreen === "signup" && (
                  <form onSubmit={handleSubmit(verifyHandler)}>
                    <h2>Login / Signup</h2>
                    <div className="form-group">
                      <InputField
                        htmlFor="name"
                        text="Name"
                        type="text"
                        placeholder="Enter Name"
                        field={"name"}
                        register={register}
                        errors={errors}
                        autoFocus={true}
                        customclassName="form-control"
                      />
                      {errors?.name?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"name"} />
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <InputField
                        htmlFor="email"
                        text="Email"
                        type="text"
                        placeholder="Enter email"
                        field={"email"}
                        register={register}
                        errors={errors}
                        customclassName="form-control"
                      />
                      {errors?.email?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"email"} />
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <InputField
                        htmlFor="phoneNumber"
                        text="Phone Number"
                        type="text"
                        placeholder="Enter Phone Number"
                        field={"phoneNumber"}
                        register={register}
                        errors={errors}
                        disabled={true}
                        customclassName="form-control"
                      />
                      {errors?.phoneNumber?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"phoneNumber"}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <button type="submit">Sign Up</button>
                    </div>
                  </form>
                )}
                {showScreen === "otp" && (
                  <form>
                    <h2>Verify OTP</h2>
                    <div className="form-group">
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props) => (
                          <input
                            {...props}
                            type="number"
                            className="Otp-form-control"
                          />
                        )}
                      />
                    </div>
                    <p className="otpEditMobile">
                      {phoneVal}{" "}
                      <button type="button" onClick={() => setShowScreen("")}>
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                      </button>
                    </p>
                    <div className="form-group">
                      <button type="button" onClick={verifyHandler}>
                        Verify OTP
                      </button>
                    </div>
                    {resendOtp ? (
                      <a
                        href="#"
                        className="termsparagraph m-0"
                        style={{ color: "#ff0018" }}
                        onClick={(e) => resendOTP(e)}
                      >
                        Resend OTP
                      </a>
                    ) : (
                      <p className="m-0 termsparagraph">
                        Resend OTP in {`00:${count > 9 ? count : `0${count}`}`}
                      </p>
                    )}
                  </form>
                )}
              </div>
              <p className="termsparagraph">
                By proceeding, you agree to RRooms's{" "}
                {/* <Link to="/Rrooms-privacy-policy">Privacy Policy</Link>,{" "} */}
                <a href="https://www.rrooms.in/Rrooms-privacy-policy/">
                  Read all Hotel policies
                </a>
                <Link to="/user-agreement">User Agreement</Link> and{" "}
                <Link to="/terms-and-conditions">Terms of Service</Link>
              </p>
              {/* <div
                className="notCustomerBtn"
                onClick={() => setOtherLogins(!otherLogins)}
              >
                Not a Customer{" "}
                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
              </div> */}
              {/* {otherLogins ? (
                <> */}
              <div className="tag" onClick={() => setOtherLogins(!otherLogins)}>
                <span>Or sign in as</span>
              </div>
              <ul
                className="login-btn-list"
                style={{ visibility: otherLogins && "hidden" }}
              >
                <li>
                  <Link to="/admin-login" className="registerBtn">
                    Admin{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="registerBtn">
                    Property{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </li>
              </ul>
              {/* </>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
