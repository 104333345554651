import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Table } from "react-bootstrap";
import { baseUrl } from "../../API/client/api";
import Moment from "react-moment";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";

const SearchResult = () => {
  const [results, setResults] = useState([]);
  const [searches, setSearches] = useState([]);

  const getData = async () => {
    const res = await fetch(`${baseUrl}rrooms-property/search-logs`);
    const data = await res.json();
    setResults(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "SearchResults",
    sheet: "SearchResults",
  });
  useEffect(() => {
    setSearches(results);
  }, [results]);
  const [filterDate, setFilterDate] = useState(new Date());

  const dateFilter = () => {
    const resultsData = results.filter((order) => {
      return (
        new Date(order?.createdAt).toJSON().slice(0, 10) >=
        new Date(filterDate).toJSON().slice(0, 10)
      );
    });
    setSearches(resultsData);
  };
  useEffect(() => {
    dateFilter();
  }, [filterDate]);

  const searchfilter = (text) => {
    const resultsData = results.filter((order) => {
      const data = JSON.parse(order.request);
      if (text === "") return results;
      return data?.query?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setSearches(resultsData);
  };
  return (
    <Layout
      title="Search Result"
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      searchfilter={searchfilter}
      setFilterDate={setFilterDate}
      filterDate={filterDate}
    >
      <Table className="common-table" striped bordered hover ref={tableRef}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Request Search</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {searches?.map((data, key) => {
            if (data.request) {
              const reqData = JSON.parse(data.request);
              let arr = [];
              for (let i in reqData) {
                arr.push([[i], reqData[i]]);
              }
              if (reqData?.query) {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <span
                        className="text-success fw-bold"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        {reqData?.query}
                      </span>
                    </td>
                    <td>
                      <Moment format="Do MMMM YYYY">{data?.createdAt}</Moment>
                    </td>
                    <td>
                      <Moment format="hh:m A">{data?.createdAt}</Moment>
                    </td>
                  </tr>
                );
              }
            }
          })}
        </tbody>
      </Table>
    </Layout>
  );
};

export default SearchResult;
