import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import services from "../../API/services";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useMemo } from "react";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { Margin, Resolution, usePDF } from "react-to-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { InputField } from "../../components/InputField/InputField";
import { createLogs } from "../../store/slices/PropertyData";
import {
  ORDER_STATUS,
  USER_DEG_TEXT,
  renderTags,
  statusfood,
  statusfoodColor,
} from "constant/constants";
import ReactGA from "react-ga4";
import { showPrice } from "utils/utils";

const FoodsOrders = () => {
  const tableRef = useRef();
  const { userDetail } = useSelector(authenticationSeletor);
  const [activeTab, setActiveTab] = useState(ORDER_STATUS.KOT);
  const [itemsModal, setItemsModal] = useState(false);
  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectItemsShow, setSelectItemsShow] = useState([]);
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [orderAmt, setOrderAmt] = useState(0);
  const [filterOrders, setFilterOrders] = useState([]);
  const [BookingId, setBookingId] = useState([]);
  const [loginUsers, setLoginUsers] = useState([]);
  const [ncType, setNcType] = useState(false);
  const [ncPriceData, setNcPriceData] = useState({});
  const [roomErrorShow, setRoomErrorShow] = useState(false);
  const [filterDate, setFilterDate] = useState(new Date());
  const [itemInput, setItemInput] = useState([
    { itemName: "", qty: 1, price: "", amount: "" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    ...validation,
    mode: "onSubmit",
    defaultValues: {
      roomNo: "",
      guestName: "",
    },
  });

  const { roomNo } = watch();
  useEffect(() => {
    if (ncType) {
      setRoomErrorShow(false);
    } else if (!ncType && roomNo != "") {
      setRoomErrorShow(false);
    } else {
      setRoomErrorShow(true);
    }
    if (roomNo == "") {
      setBookingId("");
    }
  }, [ncType, roomNo]);

  const onSubmit = async (payload) => {
    if (roomErrorShow) {
      setRoomErrorShow(true);
      return;
    }
    setSubmitDisabled(true);
    let goBack;
    itemInput.forEach((e) => {
      if (
        e.qty.length <= 0 ||
        !e.qty ||
        !e.itemName ||
        e.itemName.length <= 0
      ) {
        goBack = true;
        return;
      }
    });
    if (goBack) {
      setSubmitDisabled(false);
      return;
    }
    let items = [];

    itemInput.map((item) => {
      const tempPrice = item?.itemName?.finalPrice || item?.itemName?.price;
      items.push({
        id: Number(item?.itemName?.value),
        qty: Number(item.qty),
        name: item?.itemName?.name,
        price: tempPrice,
        amountBeforeTax: item?.itemName?.amountBeforeTax,
        taxAmount: item?.itemName?.taxAmount * Number(item.qty),
        propertyId: item?.itemName?.propertyId,
        totalAmount: tempPrice * Number(item.qty),
      });
    });
    const realPayload = {
      user_id: 0,
      booking_id: BookingId.id ?? 0,
      room_number: payload.roomNo,
      order_amount: Number(orderAmt),
      order_items: items,
      created_by: userDetail?.id,
      nc_type: ncType ? ncType : "0",
      otherGuestName:
        payload.otherName ?? BookingId.otherPersonName ?? BookingId.User?.name,
    };
    if (ncType) {
      realPayload.remark = payload.remark;
    }
    try {
      let res = {};
      let msg = "";
      if (editMode) {
        res = await services.property.updateFoodOrders(
          realPayload,
          selectedData.id
        );
        msg = "Updated";
      } else {
        res = await services.property.createFoodOrders(realPayload);
        msg = "Placed";
      }
      if (res.status === 200) {
        setSubmitDisabled(false);
        reset();
        setNcType(false);
        setShow(false);
        setEditMode(false);
        getDataHandler();
        setBookingId({});
        setItemInput([{ itemName: "", qty: 1, price: "", amount: "" }]);
        toast.success(`Food Order Successfully ${msg}*`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        const params = {
          bookingId: BookingId?.id ?? 0,
          action: `Food Order ${msg}`,
          actionBy: userDetail?.firstName + " " + userDetail?.lastName,
          userType: "propertyUser",
        };
        dispatch(createLogs(params));
      }
    } catch (error) {
      setSubmitDisabled(false);
    }
  };
  const getNcPrice = async () => {
    try {
      const res = await services.property.getNcTypeByProperty(
        userDetail?.propertyId
      );

      if (res.status === 200) {
        setNcPriceData(res.data.data[0]);
      }
    } catch (error) {}
  };
  const getRecentBooking = async () => {
    try {
      const res = await services.globalService.getBookingPropertyIdService(
        userDetail?.propertyId,
        `?page=0&size=100&bookingStatus=2`
      );
      const foodResponse = await services.property.getFoodItemsProperty(
        `?property_id=${userDetail?.propertyId}`
      );
      if (res.status === 200) {
        // setAllBookingData(res.data.data);
        const rooms = [];
        res.data.data.map((item) =>
          item.assignRoomNo?.split(",").map((data) => rooms.push(data))
        );
        setRoomNumbers(rooms);
        setBookingData(res.data.data);
        let itemsArr = [];
        foodResponse.data.data?.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
            price: item.finalPrice,
            ...item,
          };
          itemsArr.push(obj);
        });
        setFoodItems(itemsArr);
      }
    } catch (error) {}
  };

  const watchData = watch();
  useEffect(() => {
    if (watchData?.roomNo) {
      const bookData = bookingData?.find((item) =>
        item?.assignRoomNo.includes(watchData?.roomNo)
      );
      setBookingId(bookData);
    }
  }, [watchData?.roomNo]);
  useEffect(() => {
    let totalAmount = 0;
    itemInput.map((item) => {
      totalAmount += item.amount;
    });
    if (ncType) {
      setOrderAmt(
        Math.round(totalAmount - (totalAmount * ncPriceData?.ncType) / 100)
      );
    } else {
      setOrderAmt(totalAmount);
    }
  }, [itemInput, ncType]);

  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);
    setValue("guestName", data.bookingId);
    setValue("roomNo", data.roomNumber);
    let editItemsData = [];
    data.orderItems?.map((item) => {
      editItemsData.push({
        itemName: {
          value: item.id,
          label: foodItems.find((i) => i.value == item.id)?.label,
          price: foodItems.find((i) => i.value == item.id)?.price,
          ...item,
        },
        qty: item.qty,
        price: item.price,
        amount: item.price * item.qty,
      });
    });
    const bookData = bookingData?.find((item) =>
      item?.assignRoomNo.includes(data?.roomNumber)
    );

    setBookingId(bookData);
    setItemInput(editItemsData);
    setEditMode(true);
  };

  const getDataHandler = async () => {
    try {
      const res = await services.property.getFoodOrdersByProperty(
        userDetail?.propertyId
      );
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDataHandler();
    getRecentBooking();
    getNcPrice();
  }, []);

  const foodOrderData = useMemo(() => {
    return tableData?.reduce(
      (acc, booking) => {
        const { orderStatus, paymentStatus, ncType } = booking || {};
        if (ncType == "1") {
          acc[ORDER_STATUS.NCKOT].push(booking);
        }
        if (orderStatus === ORDER_STATUS.KOT) {
          acc[ORDER_STATUS.KOT].push(booking);
        } else if (orderStatus === ORDER_STATUS.PROCESS) {
          acc[ORDER_STATUS.PROCESS].push(booking);
        } else if (orderStatus === ORDER_STATUS.DELIVERED) {
          acc[ORDER_STATUS.DELIVERED].push(booking);
        } else if (
          orderStatus === ORDER_STATUS.COMPLETE &&
          paymentStatus === 1
        ) {
          acc[ORDER_STATUS.COMPLETE].push(booking);
        } else if (orderStatus === ORDER_STATUS.CANCELED) {
          acc[ORDER_STATUS.CANCELED].push(booking);
        } else {
          acc[ORDER_STATUS.ALL].push(booking);
        }
        return acc;
      },
      {
        [ORDER_STATUS.KOT]: [],
        [ORDER_STATUS.PROCESS]: [],
        [ORDER_STATUS.DELIVERED]: [],
        [ORDER_STATUS.COMPLETE]: [],
        [ORDER_STATUS.CANCELED]: [],
        [ORDER_STATUS.NCKOT]: [],
        [ORDER_STATUS.ALL]: tableData,
      }
    );
  }, [tableData]);

  const propertyStatusTabs = useMemo(
    () => [
      {
        key: ORDER_STATUS.KOT,
        title: `KOT (${foodOrderData[ORDER_STATUS.KOT]?.length})`,
      },

      {
        key: ORDER_STATUS.PROCESS,
        title: `In-process (${foodOrderData[ORDER_STATUS.PROCESS]?.length})`,
      },
      {
        key: ORDER_STATUS.DELIVERED,
        title: `Delivered (${foodOrderData[ORDER_STATUS.DELIVERED]?.length})`,
      },
      // {
      //   key: ORDER_STATUS.COMPLETE,
      //   title: `Completed (${foodOrderData[ORDER_STATUS.COMPLETE]?.length})`,
      // },
      {
        key: ORDER_STATUS.CANCELED,
        title: `Cancelled (${foodOrderData[ORDER_STATUS.CANCELED]?.length})`,
      },
      {
        key: ORDER_STATUS.NCKOT,
        title: `NC KOT (${foodOrderData[ORDER_STATUS.NCKOT]?.length})`,
      },
      {
        key: ORDER_STATUS.ALL,
        title: `All (${tableData?.length})`,
      },
    ],
    [foodOrderData]
  );

  const changeHandler = (e, index, isSearch = false) => {
    let tempData = [...itemInput];
    if (isSearch) {
      tempData[index].itemName = e;
      tempData[index].price = e?.finalPrice?.toString();
      tempData[index].amount = tempData[index].qty * tempData[index].price;
    } else {
      tempData[index][e.target.name] = e.target.value;
      tempData[index].amount = tempData[index].qty * tempData[index].price;
    }
    setItemInput(tempData);
  };

  const getAllUser = async () => {
    try {
      const res = await services.property.getInventoryEmplyoee();
      if (res.status === 200) {
        setLoginUsers(res.data.result);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllUser();
  }, []);

  const closeHandler = (index) => {
    let tempData = [...itemInput];
    tempData.splice(index, 1);
    setItemInput(tempData);
  };

  const changeStatus = async ({ id, bookingId }, status) => {
    // ["KOT", "In-process", "Delivered ", "Complete", "Canceled"];
    setIsLoading(true);
    const orderMessages = [
      "Food Order Confirmed Successfull !",
      "Food Order Delivered Successfull !",
      "",
      "Food Order Cancelled Successfull !",
    ];
    try {
      if (!window.confirm("Are You Sure Want to Change Status")) {
        setIsLoading(false);
        return;
      }
      const realPayload = {
        order_status: statusfood.indexOf(status) + 1,
      };

      const res = await services.property.updateFoodOrderStatus(
        realPayload,
        id
      );
      if (res.status == 200) {
        getDataHandler();
        toast.success(orderMessages[statusfood.indexOf(status)], {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        const params = {
          bookingId: bookingId,
          action: orderMessages[statusfood.indexOf(status)],
          actionBy: userDetail?.firstName + " " + userDetail?.lastName,
          userType: "propertyUser",
        };
        dispatch(createLogs(params));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "food-reciept.pdf",
    page: { margin: Margin.NONE, format: "A4" },
    resolution: Resolution.EXTREME,
    canvas: { mimeType: "image/png", qualityRatio: 1 },
  });
  const printKot = () => {
    toPDF();
    const params = {
      bookingId: selectItemsShow?.bookingId,
      action: "Food KOT Print",
      actionBy: userDetail?.firstName + " " + userDetail?.lastName,
      userType: "propertyUser",
    };
    dispatch(createLogs(params));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "FoodOrders",
    sheet: "FoodOrders",
  });

  const dateFilter = () => {
    const resultsData = foodOrderData[activeTab].filter((order) => {
      return (
        moment(order?.createdAt).format("DD-MM-YYYY") ==
        moment(filterDate).format("DD-MM-YYYY")
      );
    });

    setFilterOrders(resultsData);
  };
  useEffect(() => {
    dateFilter();
  }, [filterDate]);
  const searchfilter = (text) => {
    const results = foodOrderData[activeTab].filter((order) => {
      if (text === "") return foodOrderData[activeTab];
      return (
        order.bookingId +
        order.BookingHotel?.bookingCode +
        order.roomNumber +
        order.id +
        Math.round(order.orderAmount + order.orderAmount * 0.05) +
        renderTags?.find((i) => i.id == order.ncType)?.name +
        loginUsers.find((i) => i.id == order.createdBy)?.firstName +
        order.otherGuestName
      )
        .toString()
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterOrders(results);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "kitchen_orders" });
    setFilterOrders(foodOrderData[activeTab]);
  }, [activeTab, tableData]);

  const quatitySetup = (item, state, index) => {
    const { price } = item;
    let tempQty = itemInput.find((item, i) => i === index)?.qty;
    let tempData = [...itemInput];
    let k = 0;

    if (state) {
      k = tempQty + 1;
    } else {
      if (tempQty > 1) {
        k = tempQty - 1;
      } else {
        k = tempQty;
      }
    }
    tempData[index].qty = k;
    tempData[index].amount = k * price;
    setItemInput(tempData);
  };
  return (
    <Layout
      title="Food Orders"
      openHandler={() => setShow(true)}
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      searchfilter={searchfilter}
      setFilterDate={setFilterDate}
      filterDate={filterDate}
      dateFilterText="Select Date:"
    >
      <div className="flex justify-content-between foodOrderTabs">
        <Tabs
          className="tabs mb-2"
          activeKey={activeTab}
          defaultActiveKey={ORDER_STATUS.UPCOMING}
          onSelect={(tabKey) => setActiveTab(tabKey)}
        >
          {propertyStatusTabs.map((tab, ind) => {
            return <Tab eventKey={tab.key} key={ind} title={tab.title} />;
          })}
        </Tabs>
      </div>
      <Table striped bordered hover className="common-table" ref={tableRef}>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Order ID</th>
            <th>Created By</th>
            <th>KOT Type</th>
            <th>Booking ID</th>
            <th>Guest</th>
            <th>Items</th>
            <th>Room No.</th>
            <th>Amount</th>
            {/* <th>Payment Status</th> */}
            <th>Order Date</th>
            {(activeTab == ORDER_STATUS.DELIVERED ||
              activeTab == ORDER_STATUS.COMPLETE ||
              activeTab == ORDER_STATUS.ALL) && (
              <th>
                {activeTab == ORDER_STATUS.COMPLETE
                  ? "Completed "
                  : "Delivered "}
                Date
              </th>
            )}

            <th>Order Status</th>
            {!(activeTab > 1 && activeTab < 5) && (
              <th style={{ minWidth: 200 }}>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {filterOrders?.length > 0 ? (
            [...filterOrders]?.reverse()?.map((data, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.id}</td>
                  <td>
                    {data.userId
                      ? "Guest"
                      : loginUsers.find((i) => i.id == data.createdBy)
                          ?.firstName ?? "N/A"}
                  </td>
                  <td>
                    {renderTags?.find((i) => i.id == data.ncType)?.name ??
                      "Room"}
                  </td>
                  <td>
                    {data.BookingHotel?.bookingCode ? (
                      <Link to={`/dashboard/all-bookings/${data?.bookingId}`}>
                        #{data.BookingHotel?.bookingCode ?? "N/A"}
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="text-capitalize">
                    {data.otherGuestName ?? "N/A"}
                  </td>
                  <td>
                    <button
                      className="showItemsBtn"
                      onClick={() => {
                        setSelectItemsShow(data);
                        setItemsModal(true);
                      }}
                    >
                      Show Items
                    </button>
                  </td>
                  <td>{data.roomNumber}</td>
                  <td>
                    &#x20B9;
                    {data.orderAmount}
                  </td>
                  <td>
                    {moment(data.createdAt).format("D MMM, YYYY HH:MM A")}
                  </td>
                  {(activeTab == ORDER_STATUS.DELIVERED ||
                    activeTab == ORDER_STATUS.COMPLETE ||
                    activeTab == ORDER_STATUS.ALL) && (
                    <td>
                      {moment(data.updatedAt).format("D MMM, YYYY HH:MM A")}
                    </td>
                  )}

                  <td>
                    <span
                      className="deliver fw-medium"
                      style={{ color: statusfoodColor[data.orderStatus] }}
                    >
                      {statusfood[data.orderStatus]}
                    </span>
                  </td>
                  {!(activeTab > 1 && activeTab < 5) && (
                    <td className="text-end">
                      {userDetail.Role?.canEdit == 1 && data.orderStatus < 3 ? (
                        <Button
                          disabled={isLoading}
                          variant="primary"
                          className="btn-sm mr-2"
                          onClick={!isLoading ? () => editHandler(data) : null}
                        >
                          <i
                            className="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      ) : null}
                      {data.orderStatus === 0 || data.orderStatus === 1 ? (
                        <Button
                          disabled={isLoading}
                          variant="primary"
                          className="btn-sm mr-2"
                          onClick={
                            !isLoading
                              ? () =>
                                  changeStatus(
                                    data,
                                    statusfood[data.orderStatus]
                                  )
                              : null
                          }
                        >
                          Confirm
                        </Button>
                      ) : null}
                      {(USER_DEG_TEXT[userDetail?.designation] === "AGM" ||
                        (userDetail.role == 3 &&
                          (data.orderStatus > 4 || data.orderStatus < 2))) && (
                        <Button
                          disabled={isLoading}
                          variant="danger"
                          className="btn-sm"
                          onClick={
                            !isLoading
                              ? () => changeStatus(data, "Complete")
                              : null
                          }
                        >
                          Cancel
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={13}>
                <div
                  className="flex flex-column align-center justify-content-center full-width"
                  style={{ height: "10rem" }}
                >
                  <FontAwesomeIcon
                    className="mb-2"
                    icon="fa-solid fa-ban"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span>No Data</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setItemInput([{ itemName: "", qty: 1, price: "", amount: "" }]);
          reset();
          setBookingId({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit " : "Add "}Food Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Row className="flex align-items-start">
                  <Col
                    xs={12}
                    className="flex gap-2 align-items-start mb-1 flex-column"
                  >
                    <div className="flex align-items-center ">
                      {renderTags?.map((item) => (
                        <label
                          htmlFor={item.id}
                          className={`mb-0 checkLable ${
                            ncType == item.id && "activeLable"
                          }`}
                          key={item.id}
                        >
                          <input
                            hidden
                            type="checkbox"
                            id={item.id}
                            name="ncType"
                            checked={ncType == item.id}
                            onChange={(e) => {
                              setNcType(ncType == item.id ? false : item.id);
                            }}
                          />
                          {item.name}
                        </label>
                      ))}
                    </div>
                    {ncType && (
                      <Row className="form-group w-100 m-0">
                        <Col xs={6}>
                          <InputField
                            htmlFor="otherName"
                            text={
                              ncType == 1
                                ? "Guest Name"
                                : ncType == 2
                                ? "Staff"
                                : ncType == 3
                                ? "Table"
                                : "Direct"
                            }
                            type="text"
                            autoFocus={true}
                            placeholder={`Enter ${
                              ncType == 1
                                ? "Guest Name"
                                : ncType == 2
                                ? "Staff"
                                : ncType == 3
                                ? "Table"
                                : "Direct"
                            }`}
                            field={"otherName"}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                        <Col xs={6}>
                          <InputField
                            htmlFor="remark"
                            text={"Remark"}
                            type="text"
                            autoFocus={true}
                            placeholder={"Enter Remark"}
                            field={"remark"}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <Col xs={6} className="form-group">
                  <label>Room Number</label>
                  <Controller
                    control={control}
                    name="roomNo"
                    render={({ field: { onChange, value } }) => (
                      <select onChange={onChange} value={value}>
                        <option selected value="">
                          Select Room
                        </option>
                        {roomNumbers?.map((item, key) => (
                          <option value={item} key={key}>
                            {item}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {roomErrorShow && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>Please Select Room</span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group m-0">
                  <label>Booking Code</label>
                  {/* {Object?.keys(BookingId ?? {})?.length !== 0 && ( */}
                  <input
                    type="text"
                    name="qty"
                    placeholder="Enter Quantity"
                    value={`${BookingId?.bookingCode ?? ""}-${
                      BookingId?.otherPersonName ?? BookingId?.User?.name ?? ""
                    }`}
                    disabled
                  />
                  {/* )} */}
                </Col>

                {itemInput.map((item, ind) => {
                  return (
                    <Col xs={12} className="addItems" key={ind}>
                      <Row>
                        {itemInput.length > 1 && (
                          <Col xs={12}>
                            <div
                              className={`cutIcon`}
                              onClick={() => closeHandler(ind)}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                          </Col>
                        )}
                        <Col xs={5} className="form-group m-0">
                          <label>Item Name</label>
                          <Select
                            options={foodItems}
                            placeholder={"Select"}
                            value={item.itemName}
                            onChange={(dataSet) => {
                              changeHandler(dataSet, ind, true);
                            }}
                            isSearchable={true}
                          />
                          {item.itemName.length <= 0 && (
                            <div className="error-text">
                              <img alt="" src={AlertIcon} />
                              <span>select Item*</span>
                            </div>
                          )}
                        </Col>
                        <Col xs={3} className="form-group m-0">
                          <label>Quantity</label>
                          <div className="d-flex justify-content-between incDec">
                            <button
                              className="incDecBtn"
                              type="button"
                              onClick={() => quatitySetup(item, false, ind)}
                            >
                              -
                            </button>
                            <input
                              className="text-center"
                              type="number"
                              name="qty"
                              placeholder="Enter Quantity"
                              value={item.qty}
                              disabled
                            />
                            <button
                              className="incDecBtn"
                              type="button"
                              onClick={() => quatitySetup(item, true, ind)}
                            >
                              +
                            </button>
                          </div>
                          {item.qty.length <= 0 && (
                            <div className="error-text">
                              <img alt="" src={AlertIcon} />
                              <span>Enter Quantity*</span>
                            </div>
                          )}
                        </Col>
                        <Col xs={2} className="form-group m-0">
                          <label htmlFor="itemPrice">Item Price</label>
                          <input
                            id="itemPrice"
                            type="number"
                            name="price"
                            placeholder="Price"
                            onChange={(e) => changeHandler(e, ind)}
                            value={item.price}
                            disabled
                          />
                        </Col>
                        <Col xs={2} className="form-group">
                          <label htmlFor="totalAmount">Total</label>
                          <input
                            id="totalAmount"
                            type="number"
                            name="amount"
                            placeholder="Amount"
                            value={item.amount}
                            disabled
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2 align-center"
                >
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() =>
                      setItemInput([
                        ...itemInput,
                        { itemName: "", qty: 1, price: "", amount: "" },
                      ])
                    }
                  >
                    + Add Item
                  </Button>
                  <h6 className="d-flex flex-column">
                    <span> Grand Total - ₹ {orderAmt}</span>
                    {/* <span style={{ fontSize: ".75rem", color: "#666" }}>
                      Including GST
                    </span> */}
                  </h6>
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-end mt-4"
                >
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="print-box" style={{ position: "fixed", zIndex: "-999" }}>
        <Row id="pdf" ref={targetRef}>
          <Col xs={8}>
            <Row className="mb-2">
              <Col xs={12}>
                <span
                  style={{ fontSize: "16px", display: "block" }}
                  className="text-black"
                >
                  Order Number: {selectItemsShow?.id}
                </span>
                <span
                  style={{ fontSize: "16px", display: "block" }}
                  className="text-black"
                >
                  Room Number: {selectItemsShow?.roomNumber}
                </span>
                <span
                  style={{ fontSize: "16px", display: "block" }}
                  className="text-black"
                >
                  Booking ID: {selectItemsShow?.BookingHotel?.bookingCode}
                </span>
                <span
                  style={{ fontSize: "16px", display: "block" }}
                  className="text-black"
                >
                  Guest Name:
                  {selectItemsShow.otherGuestName ?? "N/A"}
                </span>
              </Col>
              {/* <Col xs={6} className="text-end">
                <span className="text-black">
                  Room Number: {selectItemsShow?.roomNumber}
                </span>
              </Col>
              <Col xs={6}>
                <span className="text-black">
                  Booking ID: {selectItemsShow?.BookingHotel?.bookingCode}
                </span>
              </Col>
              <Col xs={6} className="text-end">
                <span className="text-black">
                  Guest Name:
                  {allBookingData.find(
                    (i) => i.id === selectItemsShow.bookingId
                  )?.otherPersonName ??
                    allBookingData.find(
                      (i) => i.id === selectItemsShow.bookingId
                    )?.User?.name ??
                    "N/A"}
                </span>
              </Col> */}
            </Row>
            <Table striped hover className="modal_Table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {selectItemsShow?.orderItems?.map((item, ind) => (
                  <tr key={ind}>
                    <td>
                      <strong style={{ fontSize: "14px" }}>{item.name}</strong>
                    </td>
                    <td>
                      <strong style={{ fontSize: "14px" }}>{item.qty}</strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {selectItemsShow?.ncType !== "0" && (
              <Col xs={6}>
                <span style={{ fontSize: "16px" }}>
                  Remark : {selectItemsShow?.remark}
                </span>
              </Col>
            )}
            {selectItemsShow.ncType == 1 && (
              <Col xs={6} className="d-flex justify-content-end">
                <span style={{ fontSize: "16px" }}>Non Chargable KOT</span>
              </Col>
            )}
            <div className="flex justify-content-between">
              <span
                style={{ fontSize: "16px" }}
                className="text-black text-end"
              >
                Created By:{" "}
                {selectItemsShow.userId
                  ? "Guest"
                  : loginUsers.find((i) => i.id == selectItemsShow.createdBy)
                      ?.firstName +
                      " " +
                      loginUsers.find((i) => i.id == selectItemsShow.createdBy)
                        ?.lastName ?? "Guest"}
              </span>
              <span
                style={{ fontSize: "16px" }}
                className="text-black text-end"
              >
                Date & Time:{" "}
                {moment(selectItemsShow.createdAt).format(
                  "D MMM, YYYY HH:MM A"
                )}
              </span>
            </div>
          </Col>
          <Col xs={4}></Col>
        </Row>
      </div>
      <Modal show={itemsModal} onHide={() => setItemsModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Items</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Row id="pdf">
            <Row className="mb-2">
              <Col xs={6}>
                <span className="text-black">
                  Order Number: {selectItemsShow?.id}
                </span>
              </Col>
              <Col xs={6} className="text-end">
                <span className="text-black">
                  Room Number: {selectItemsShow?.roomNumber}
                </span>
              </Col>
              <Col xs={6}>
                <span className="text-black">
                  Booking ID: {selectItemsShow?.BookingHotel?.bookingCode}
                </span>
              </Col>
              <Col xs={6} className="text-end">
                <span className="text-black">
                  Guest Name:
                  {selectItemsShow.otherGuestName ?? "N/A"}
                </span>
              </Col>
            </Row>
            <Table striped hover className="modal_Table common-table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selectItemsShow?.orderItems?.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item.name}</td>
                    <td className="text-center">{showPrice(item.price)}</td>
                    <td className="text-center">{item.qty}</td>
                    <td className="text-center">
                      {showPrice(item.price * item.qty)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {selectItemsShow?.ncType !== "0" && (
              <Col xs={6}>
                <span>Remark : {selectItemsShow?.remark}</span>
              </Col>
            )}
            {selectItemsShow.ncType == 1 && (
              <Col xs={6} className="d-flex justify-content-end">
                <span>Non Chargable KOT</span>
              </Col>
            )}
            <div className="flex justify-content-between">
              <span className="text-black text-end">
                Created By:{" "}
                {selectItemsShow.userId
                  ? "Guest"
                  : loginUsers.find((i) => i.id == selectItemsShow.createdBy)
                      ?.firstName +
                      " " +
                      loginUsers.find((i) => i.id == selectItemsShow.createdBy)
                        ?.lastName ?? "Guest"}
              </span>
              <span className="text-black text-end">
                Date & Time:{" "}
                {moment(selectItemsShow.createdAt).format(
                  "D MMM, YYYY HH:MM A"
                )}
              </span>
            </div>
            <Row className="d-flex justify-content-end mt-2">
              <div md={8}>
                <span className="text-success text-end border border-success px-2 py-1 rounded-sm ">
                  Order Amount : {showPrice(selectItemsShow.orderAmount)}
                </span>
              </div>
            </Row>
          </Row>

          <Row className="d-flex justify-content-center pr-2">
            <Col xs={2}>
              <Button variant="primary" className="btn-sm" onClick={printKot}>
                Print
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default FoodsOrders;
