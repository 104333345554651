import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import {
  getRroomUsersAction,
  usersSeletor,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
const FoodCategory = () => {
  const dispatch = useDispatch();
  const { rRoomUsers } = useSelector(usersSeletor);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getRroomUsersAction());
  }, []);

  useEffect(() => {
    setUserList(rRoomUsers);
  }, [rRoomUsers]);

  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);
  const [userList, setUserList] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      name: payload.firstName + " " + payload.lastName,
      email: payload.email,
      mobile: payload.phoneNumber,
      role: 1,
      password: payload.password,
      designation: payload.designation,
    };
    try {
      const res = await services.user.addRRoomUserService(realPayload);

      if (res.status === 200) {
        reset();
        dispatch(getRroomUsersAction());
        setShow(false);
      }
    } catch (error) {}
  };

  const onError = (error) => {};

  return (
    <Layout title="Food Category" openHandler={() => setShow(true)}>
      <Row>
        <Col xs={12}>
          <div className="categoryWrap">
            <h3>Food Category</h3>
            <ul className="d-flex">
              <li>Drinks</li>
              <li>Carbs</li>
              <li>Fruits and vegetables</li>
              <li>Meat, fish or eggs</li>
              <li>Dairy</li>
              <li>Fats</li>
              <li>High-sugar foods</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create new food category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="categoryName"
                    text="Category name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter Category name"
                    field={"categoryName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.categoryName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"categoryName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2"
                >
                  <Button className="width100" variant="primary" type="submit">
                    Create
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default FoodCategory;
