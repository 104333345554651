import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm, SubmitHandler } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "../taxSettings/validation";
import { Button, Form, Row, Col } from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import { baseUrl } from "../../API/client/api";
import { toast } from "react-toastify";
const TaxSettings = () => {
  const [roomPrice, setRoomPrice] = useState({});

  const getRoomApi = async () => {
    try {
      const res = await fetch(`${baseUrl}rrooms-property/room-price-setting/1`);
      const data = await res.json();
      setRoomPrice(data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getRoomApi();
    window.scrollTo(0, 0);
  }, []);
  const { AlertIcon } = Imagesdata || {};
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      title: "Room Price Updated",
      amount: Number(payload?.taxPercantage),
    };

    try {
      const res = await fetch(
        `${baseUrl}rrooms-property/room-price-setting/1`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(realPayload),
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        reset();
        getRoomApi();
        toast.success("Room Amount Tax Updated SuccessFull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  return (
    <Layout title="Room Price Settings">
      <Row>
        <Col xs={12}>
          <div className="categoryWrap taxRooms">
            <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={9} className="form-group">
                  <InputField
                    htmlFor="taxPercantage"
                    text="Room Price Percentage"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter Price %"
                    field={"taxPercantage"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.taxPercantage?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"taxPercantage"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  xs={3}
                  className="form-group flex justify-content-between mt-2"
                >
                  <Button className="width100" variant="primary" type="submit">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
            <div className="flex gap-2">
              <span>Current Price Percentage:</span>
              <p>{roomPrice?.amount} %</p>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default TaxSettings;
