import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  categoryName: Yup.string()
    .trim()
    .required(addUserValidation.require)
    .min(3)
    .max(100)
    .matches(/^[a-zA-Z\s]+$/, "Enter Correct Category Name*"),
});

export default { resolver: yupResolver(validation) };
