import React from "react";

const InputComponent = ({
  value,
  errors,
  name,
  autoFocus,
  register,
  type = "text",
  placeholder,
  disabled = false,
  customClass,
  borderVis = true,
}) => {
  return (
    <input
      style={{
        borderWidth: borderVis ? 1 : 2,
        borderColor: borderVis ? "black" : "transparent",
      }}
      value={value}
      className="invoiceInput"
      autoComplete="off"
      disabled={disabled}
      autoFocus={autoFocus}
      type={type}
      placeholder={placeholder}
      {...register(name)}
    />
  );
};

export default InputComponent;
