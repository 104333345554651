import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function IconLink({ type = "link", children, action = "/", active = false }) {
  return type === "button" ? (
    <button
      type="button"
      className="d-flex justify-content-between align-items-center"
      onClick={action}
    >
      <div>{children}</div>
      {active ? (
        <FontAwesomeIcon icon="fa fa-angle-down" />
      ) : (
        <FontAwesomeIcon icon="fa fa-angle-right" />
      )}
    </button>
  ) : (
    <Link to={action}>{children}</Link>
  );
}

export default IconLink;
