import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validation = Yup.object().shape({
  // supplierName: Yup.string().required("Please Enter Supplier Name*"),
  // suplieremail: Yup.string()
  //   .nullable()
  //   .transform((curr, orig) => (orig === "" ? null : curr))
  //   .matches(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //     "Enter Correct Email ID*"
  //   ),
  // suplierPhoneNumber: Yup.string()
  //   .required("Please Enter Mobile Number*")
  //   .matches(/^[0-9]+$/, "Enter Correct Mobile Number*")
  //   .matches(/^\d{10}$/, "Enter Correct Mobile Number*"),
  aadhaarNumber: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(/^\d{10}$/, "Enter Correct Mobile Number*"),
  // .min(10, "Enter Correct Mobile Number*")
  // .max(10, "Enter Correct Mobile Number*"),
  // panNo: Yup.string()
  //   .required("Please Enter Pan No*")
  //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please Enter Correct Pan Number*"),
  gstno: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter Correct GST Number*"
    ),

  // bankName: Yup.string().required("Please Enter Bank Name*"),
  // branchName: Yup.string().required("Please Enter Branch Name*"),
  // accountHolder: Yup.string()
  //   .required("Please Enter Account Holder*")
  //   .matches(/^[a-zA-Z\s]+$/, "Please Enter Correct Name*"),
  // accountNumber: Yup.string().required("Please Enter Account Number*"),
  // ifsc: Yup.string().required("Please Enter IFSC*"),
  // address: Yup.string().required("Please Enter Address*"),
});

export default { resolver: yupResolver(validation) };
