import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getBunquetBookingsPayment } from "API/services/newServices";
import { showPrice } from "utils/utils";
import { Margin, usePDF } from "react-to-pdf";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import { useDownloadExcel } from "react-export-table-to-excel";

const validation = Yup.object().shape({
  tableNo: Yup.string().required("Enter Table Number*"),
  occupancy: Yup.string().required("Enter Table Occupancy*"),
});

const rules = { resolver: yupResolver(validation) };

const useBanquetPayment = (getHandlerApi, ifBanquet) => {
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "bookingDetails",
    sheet: "bookingDetails",
  });

  const { AlertIcon } = Imagesdata;

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit", ...rules });

  const onSubmit = async (payload) => {
    hideAddModalHanlder();
  };

  // states
  const [tableData, setTableData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);

  let STORY_HEADERS = [];
  if (ifBanquet) {
    STORY_HEADERS = [
      {
        prop: "banquetBookingId",
        title: "Booking Code",
        isFilterable: true,
        isSortable: true,
      },
      {
        prop: "paidAmount",
        title: "Amount",
        isFilterable: true,
        isSortable: true,
        cell: (row) => showPrice(row.paidAmount),
      },
      {
        prop: "paymentSource",
        title: "Payment Source",
        isFilterable: true,
        isSortable: true,
      },
      {
        prop: "referenceNumber",
        title: "Reference Number",
        isFilterable: true,
        isSortable: true,
      },

      {
        prop: "paymentDate",
        title: "Payment Date",
        isSortable: true,
        isFilterable: true,
        cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
      },
      {
        prop: "createdAt",
        title: "Date",
        isSortable: true,
        isFilterable: true,
        cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
      },
    ];
  } else {
    STORY_HEADERS = [
      {
        prop: "orderId",
        title: "Order Code",
        isFilterable: true,
        isSortable: true,
      },
      {
        prop: "paymentAmount",
        title: "Amount",
        isFilterable: true,
        isSortable: true,
        cell: (row) => showPrice(row.paymentAmount),
      },
      {
        prop: "paymentMode",
        title: "Payment Source",
        isFilterable: true,
        isSortable: true,
      },
      {
        prop: "transactionId",
        title: "Transaction ID",
        isFilterable: true,
        isSortable: true,
      },

      {
        prop: "paymentDate",
        title: "Payment Date",
        isSortable: true,
        isFilterable: true,
        cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
      },
      {
        prop: "createdAt",
        title: "Date",
        isSortable: true,
        isFilterable: true,
        cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
      },
    ];
  }
  const openAddModalHanlder = () => {
    setOpenAddModal(true);
  };
  const hideAddModalHanlder = () => {
    setOpenAddModal(false);
    reset();
  };
  const { userDetail } = useSelector(authenticationSeletor);
  const getDataHandler = async () => {
    const res = await getHandlerApi({
      propertyId: userDetail.propertyId,
    });
    if (res) {
      setTableData(res);
    }
  };
  useEffect(() => {
    getDataHandler();
  }, []);
  return {
    STORY_HEADERS,
    tableData,
    tableRef,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    handleSubmit,
    onSubmit,
    AlertIcon,
    onDownload,
  };
};

export default useBanquetPayment;
