import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import Services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import ReactGA from "react-ga4";

const STORY_HEADERS = [
  {
    prop: "id",
    title: "item ID",
    isFilterable: true,
    isSortable: true,
    cell: (row) => `#${row.id}`,
  },
  {
    prop: "name",
    title: "Item Name",
    isFilterable: true,
    isSortable: true,
  },
  {
    prop: "price",
    title: "Price",
    isFilterable: true,
    isSortable: true,
    cell: (row) => `₹${row.price}`,
  },
  {
    prop: "definedQty",
    title: "Quantity",
    isSortable: true,
    isFilterable: true,
    // cell: (row) => row.definedQty,
  },
  {
    prop: "totalAmount",
    title: "amount",
    isFilterable: true,
    isSortable: true,
    cell: (row) => `₹${row.price * row.definedQty}`,
  },
  {
    prop: "createdAt",
    title: "Date",
    isSortable: true,
    isFilterable: true,
    cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
  },
];

// Then, use it in a component.
export default function FoodReport() {
  const { userDetail } = useSelector(authenticationSeletor);
  const [tableData, setTableData] = useState([]);
  const [bookings, setbookings] = useState([]);
  const [filterDate, setFilterDate] = useState(new Date());

  const getDataHandler = async () => {
    try {
      const res = await Services.property.getFoodOrdersByProperty(
        userDetail?.propertyId
      );
      if (res.status === 200) {
        setbookings(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "food_item_report" });
    getDataHandler();
  }, []);
  useEffect(() => {
    const filterOrders = bookings?.filter(
      (item) =>
        moment(item.createdAt).format("D-M-YYYY") ==
        moment(filterDate).format("D-M-YYYY")
    );
    let items = [];
    filterOrders?.map((data) => {
      data.orderItems?.map((item) => {
        let checkData = items.find((i) => i.id === item.id);
        if (checkData?.id) {
          let temp = items.filter((i) => i.id != item.id);
          items = [
            ...temp,
            { ...item, definedQty: item.qty + checkData.definedQty },
          ];
        } else {
          items.push({ ...item, definedQty: item.qty });
        }
      });
    });
    setTableData(items);
  }, [filterDate, bookings]);
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Food_Orders_Details",
    sheet: "Food_Orders_Details",
  });
  return (
    <Layout
      title="Food Items Report"
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      filterDate={filterDate}
      setFilterDate={setFilterDate}
      dateFilterText="Select Date :"
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <Table className="food-report" ref={tableRef}>
            <TableHeader />
            <TableBody />
          </Table>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
    </Layout>
  );
}
