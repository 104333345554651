const Messages = {
  pass_required: "Please enter valid password",
  min_pass_length: "Password must contain minimum 6 characters",
  max_pass_length: "Password  should not be greater than 10 characters",
  strong_pass:
    "Must contain at least one character in capital one in lowercase and one numeric.",
  email_required: "Email is required",
  valid_email: "Must be a valid email",
  name_require: "Please enter full name",
  valid_name: "No special character, numbers or emojis allowed in name",
  phone_invalid: "This phone number is invalid!",
  valid_number: "Only numbers are allowed",
  min_phone_length: "Phone number should have atleast 7 characters or more",
  max_phone_length: "Phone number should not be greater than 12 Characters",
  dob_required: "Please enter date of birth",
  valid_dob: "Date of birth cant be future date",
  check_required: "Accept Terms and Conditions",
  max_firstname_length: "First name should not be greater than 200 Characters",
  max_lastname_length: "Last name should not be greater than 200 Characters",
  min_name_length:
    "Please enter valid full Name. full name should have atleast 3 characters or more",
  max_name_length: "Full name should not be greater than 100 nharacters",
  otp_invalid: "Too short. The verification code is 6-digits.",
  sign_up_alert: "Sign Up message alert",
  verify_alert: "Resend limit exceeded",
  addUser: {
    first_name_required: "Enter a first name.",
    last_name_required: "Enter a last name.",
    email_required: "Enter an email.",
  },
  designation: "Please Select designation.",
  password: "Enter password.",
  panNumber: "Enter Pan Number.",
  adharNumber: "Enter Adhar Number.",
  lessee: "Enter lessee Number.",
  gstNumber: "Enter GST Number.",
  tanNumber: "Enter Tan Number.",
  propertyName: "Enter a Property Name.",
  latitude: "Enter a latitude.",
  postalCode: "Enter a Postal Code.",
  longitude: "Enter a longitude.",
  propertyDescription: "Enter property Description.",
  propertyAddress: "Enter a Property Address.",
  address: "Enter a Address.",
  firmType: "Select a Firm type.",
  category: "Select a category.",
  roomNumbers: "Select a Room numbers.",
  roomDescription: "Enter property Description.",
  roomDescriptionLength: "Property Description Maximum 1500 allowed*",
  categoryName: "Enter Category name.",
  amenitieName: "Enter Amenitie name.",
  amenitieIcon: "Enter Amenitie icon.",
  require: "This Field is required.",
  payment_Details: {
    card_number_required: "Enter a card number.",
    valid_card_number: "Please enter valid card number",
    card_number_length: "Card number must be 16 characters",

    cardholder_name_required: "Enter a cardholder name.",
    min_cardholder_name_length:
      "Cardholder name should have atleast 3 characters or more",
    max_cardholder_name_length:
      "Cardholder name should not be greater than 200 Characters",

    expiration_date_required: "Enter a expiration date.",
    valid_expire_date: "Please enter valid expiry date",

    cvc_required: "Enter a CVC.",
    min_cvc_length: "CVC should have atleast 3 characters or more",
    max_cvc_length: "CVC should not be greater than 5 Characters",
    address_required: "Enter a street address.",
    min_address_length:
      "Street address should have atleast 3 characters or more",
    max_adress_length:
      "Street address should not be greater than 500 Characters",
    valid_address: "Please enter valid address",

    city_required: "Enter a city.",
    min_city_length: "City should have atleast 3 characters or more",
    max_city_length: "City should not be greater than 50 Characters",

    country_required: "Please select country.",

    postal_code_required: "Enter a postal code.",
    min_postal_length: "Postal should have atleast 5 numbers or more",
    max_postal_length: "Postal should not be greater than 10 numbers",

    state_required: "Enter a state.",
    firmTypeEntity: "Select Firm Type*",
  },
};

export default Messages;
