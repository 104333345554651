import React, { useEffect, useState, useRef, useMemo } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Slider from "react-slick";
import services from "../../API/services";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LandingHeader from "../../components/landingHeader";
import Bookhotel from "../bookhotel";
import Moment from "react-moment";
import { Row, Col, Modal } from "react-bootstrap";
import { Rating } from "@smastrom/react-rating";
import soldOut from "../../assets/images/soldOut.png";

import "@smastrom/react-rating/style.css";
import ReactDatePicker from "react-datepicker";
import FrontFooter from "../../layout/footer/FrontFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { baseImgUrl } from "../../API/client/api";
import { getNextDay, isJsonString } from "utils/utils";
import { propertyPolicy } from "constant/constants";
import ReactGA from "react-ga4";

const { sliderImgOnly } = Imagesdata || {};

// const reviewKeynumber = {
//   1: "Very Poor",
//   2: "Poor",
//   3: "Good",
//   4: "Very Good",
//   5: "Excellent",
// };

const Hotaldetails = () => {
  const [propertyData, setPropertyData] = useState({});
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [cancelShare, setShowShare] = useState(false);
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(getNextDay());
  const [OurRooms, setOurRooms] = useState([]);
  const [showImagesModal, setShowImagesModal] = useState(false);

  const [showMore, setShowMore] = useState(-1);

  const params = useParams();

  const [showPolicy, setShowPolicy] = useState([]);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [onTopShow, setTopShow] = useState(false);
  const selectRoomRef = useRef(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const showShare = () => {
    setShowShare(!cancelShare);
  };

  const wrapper = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (propertyData?.id) {
      getReview(propertyData.id);
    }
  }, [propertyData]);

  const getReview = async (id) => {
    try {
      const res =
        await services.globalService.getCustomerReviewPropertyIdService(id);

      if (res.status === 200) {
        setTableData(res?.data?.data);
      }
    } catch (error) {}
  };

  const bookHandler = (data, slot, priceLast) => {
    const dataSet = categoryData.find((innerData) => {
      if (innerData.id === data.categoryId) {
        return innerData.name;
      }
    });

    //alok

    navigate(`/book-hotel/${data.id}/`, {
      state: {
        hotelData: {
          ...data,
          categoryName: dataSet?.name,
          propertyName: propertyData?.name,
          address: propertyData?.address,
          city: propertyData?.city,
          landmark: propertyData?.landmark,
          partialPayment: propertyData?.partialPayment,
          slot: slot,
          priceLast: priceLast,
        },
        categoryData,
      },
    });
    // } else {
    //   toast.error("Please Login First");
    // }
  };

  const settingsImgSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
  };

  const settingsNavSlider = {
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    draggable: true,
    infinite: true,
    swipe: true,
    touchMove: true,
    useTransform: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "hotel_details" });
    if (params.id) {
      getAmenities();
      getPropertyDetail(params.id);
      getRoomCategory();
    } else {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (document.querySelector(".modal-backdrop")) {
      document.querySelector(".modal-backdrop").style.opacity = 0.8;
    }
  }, [showImagesModal]);

  const getRoomCategory = async () => {
    try {
      const res = await services.property.getRoomCategoryService();
      if (res.status === 200) {
        setCategoryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const getPropertyDetail = async (id) => {
    try {
      const res = await services.property.getSinglePropertyService(id);
      if (res.status === 200) {
        let data = res?.data?.data;
        setPropertyData(data);
        setFirstTime(true);
        let policy = [data.bookingPolicy];
        if (isJsonString(data.bookingPolicy)) {
          policy = JSON.parse(data.bookingPolicy);
        }
        setShowPolicy(policy);
      }
    } catch (error) {}
  };

  const getAmenities = async () => {
    try {
      const res = await services.property.getAmenitiesService();
      if (res.status === 200) {
        setAmenitiesData(res?.data?.data);
      }
    } catch (error) {}
  };

  const toDateRef = useRef(null);
  const [firstTime, setFirstTime] = useState(false);

  const dateFilter = async () => {
    const fd = fromdate
      ? `&fromDate=${fromdate?.toISOString().substring(0, 10)}  00:00:00`
      : "";

    const td = todate
      ? `&toDate=${todate?.toISOString().substring(0, 10)}  00:00:00`
      : "";
    try {
      const res = await services.property.getRroomsPropertyByFilter(
        `/${params.id}?${fd}${td}`
      );
      setOurRooms(res.data.data.Rooms);
    } catch (error) {}
  };

  useMemo(() => {
    if (firstTime) dateFilter();
  }, [fromdate, todate]);

  const amenitiesRender = (dataType, dataset) => {
    let selectedAminits = [];
    if (dataType === "property") {
      amenitiesData.map((data) => {
        propertyData.PropertyAmenities.filter((innerData) => {
          if (innerData.propertyAmenitiesId === data.id) {
            selectedAminits.push(data);
          }
        });
      });
    } else {
      amenitiesData.map((data) => {
        dataset.filter((innerData) => {
          if (innerData.amenitiesId === data.id) {
            selectedAminits.push(data);
          }
        });
      });
    }
    return selectedAminits;
  };

  const isBookHotel = location.pathname.includes("book-hotel");

  const setShowMoreFun = (index) => {
    if (index === showMore) {
      setShowMore(-1);
    } else {
      setShowMore(index);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let el = selectRoomRef.current;
      var _x = 0;
      var _y = 0;
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
      }
      const scrollTop = window.scrollY;
      if (_y < scrollTop) {
        setTopShow(true);
      } else {
        setTopShow(false);
      }
    });
  }, []);

  const profileImg = propertyData?.PropertyImage?.length
    ? propertyData?.PropertyImage.find(
        (i) => i.id === propertyData?.profileImageID
      )
    : null;
  const imageList = propertyData?.PropertyImage || [];
  if (profileImg) {
    imageList.unshift(profileImg);
  }

  return isBookHotel ? (
    <Bookhotel />
  ) : (
    <div className="hotalList hotalListHeight">
      <LandingHeader />
      {/* banner section */}
      <div className="homeBanner"></div>
      {/* details wrapper section start here */}
      <div className="detailsWrapper">
        <div className="container flex">
          <div className="propertyName">
            <div className="col-11">
              <h2>{propertyData.name}</h2>
              <a href="javascript:void(0)" className="address d-flex">
                {propertyData.address}
              </a>
            </div>
            <div className="col-1">
              <div className="shareBtn detailBtn" onClick={() => showShare()}>
                <i className="fa fa-share-alt" aria-hidden="true"></i>
              </div>
              {cancelShare && (
                <div className="sharesBtns">
                  <a
                    target="_blank"
                    href={`mailto:?Subject=Best Deals on Hotel Booking&Body=I%20saw%20best%20price%20room%20i%20thought%20you%20would%20like%20 https://rrooms.in/hotel-details/${propertyData?.id}`}
                  >
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  </a>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?text=https://rrooms.in/hotel-details/${propertyData?.id}`}
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                  <a
                    target="_blank"
                    href={`http://www.facebook.com/sharer.php?u=https://rrooms.in/hotel-details/${propertyData?.id}`}
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="col_">
            <div className="flex justify-content-between sliderSections mb-2">
              <div className="big-slider position-relative">
                <Slider
                  {...settingsImgSlider}
                  asNavFor={nav2}
                  ref={(slider) => setSlider1(slider)}
                >
                  {/* {(showImages?.length > 0
                    ? showImages
                    : propertyData?.PropertyImage */}
                  {imageList?.map((img, index) => (
                    <div key={index}>
                      <figure className="sliderImg">
                        <img
                          src={
                            img.image != ""
                              ? `${baseImgUrl}${img.image}`
                              : sliderImgOnly
                          }
                          alt=""
                        />
                      </figure>
                    </div>
                  ))}
                </Slider>
                {/* <div className="category_images gap-3">
                  <button
                    style={{
                      borderColor: imgFilter !== 0 ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter(0)}
                  >
                    All
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Facade" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Facade")}
                  >
                    Facade
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Lobby" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Lobby")}
                  >
                    Lobby
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "DReception" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("DReception")}
                  >
                    Reception
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Room" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Room")}
                  >
                    Room
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Washroom" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Washroom")}
                  >
                    Washroom
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Others" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Others")}
                  >
                    Others
                  </button>
                </div> */}
              </div>
              <Modal
                show={showImagesModal}
                onHide={() => setShowImagesModal(false)}
                size="lg"
                className="checkInModal showImgDetails bg-transparent"
                animation
                centered
              >
                <Modal.Header>
                  <button
                    className="closeButton"
                    onClick={() => setShowImagesModal(false)}
                  >
                    X
                  </button>
                </Modal.Header>
                <Modal.Body className="bg-transparent">
                  <div className="imagesOnModal">
                    <Slider
                      {...settingsImgSlider}
                      asNavFor={nav2}
                      ref={(slider) => setSlider1(slider)}
                    >
                      {propertyData?.PropertyImage?.map((item, ind) => {
                        return (
                          <figure className="sliderImg" key={ind}>
                            <img
                              src={
                                item.image != ""
                                  ? baseImgUrl + item.image
                                  : sliderImgOnly
                              }
                              alt=""
                            />
                          </figure>
                        );
                      })}
                    </Slider>
                    <div className="navSliderParent">
                      <div className="navSlider">
                        <Slider
                          {...settingsNavSlider}
                          asNavFor={nav1}
                          ref={(slider) => setSlider2(slider)}
                        >
                          {propertyData?.PropertyImage?.map((img, index) => (
                            <div key={index}>
                              <figure className="sliderImg_tab">
                                <img
                                  src={
                                    img.image != ""
                                      ? `${baseImgUrl}${img.image}`
                                      : sliderImgOnly
                                  }
                                  alt=""
                                />
                              </figure>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <div className="showImages">
                {propertyData?.PropertyImage?.slice(0, 4)?.map((item, ind) => {
                  return (
                    <figure
                      className="sliderImg"
                      key={ind}
                      onClick={() => {
                        ind === 3 && setShowImagesModal(true);
                      }}
                      style={{ cursor: ind === 3 && "pointer" }}
                    >
                      <img
                        src={
                          item.image != ""
                            ? `${baseImgUrl + item.image}`
                            : sliderImgOnly
                        }
                        alt=""
                      />
                    </figure>
                  );
                })}
              </div>
            </div>
            <Row>
              <Col xs={12}>
                <div className="mainBook detailbook-sec">
                  <div className="hotelsBox">
                    <div className="bookingTags">
                      {propertyData?.coupleFriendly == 1 && (
                        <div className="tag">
                          <i className="bi bi-hearts"></i>
                          <div className="content">
                            <p>Couple Friendly</p>
                            <span>
                              We Provide Couple friendly hourly hotels for both
                              unmarried and married couples with our completely
                              secure and safe bookings.
                            </span>
                          </div>
                        </div>
                      )}
                      {propertyData?.locaidAccept == 1 && (
                        <div className="tag">
                          <FontAwesomeIcon icon="fa-solid fa-id-card" />
                          <div className="content">
                            <p>Local ID Accepted</p>
                            <span>
                              We accept Same City Guests with hassle free
                              check-in.
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="col_ position-relative">
              <ul className="highlight">
                <h2>Amenities</h2>
                <li className="flex">
                  {propertyData.PropertyAmenities
                    ? amenitiesRender("property").map((data, key) => (
                        <div key={key}>
                          <img
                            alt=""
                            className="mr-2"
                            src={baseImgUrl + data.icon}
                          />{" "}
                          {data.name}
                        </div>
                      ))
                    : null}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* select rooms section start here */}

      <div
        className={`selects-rooms customSelectRooms mt-1 ${
          onTopShow ? "active" : ""
        }`}
        ref={selectRoomRef}
      >
        <div className={onTopShow ? `activeRoomHeader` : `container`}>
          <div className={`selectRoomHeader bg-white flex`}>
            <h2>Select Rooms</h2>
            <Col className="dateFilterDetails flex gap-3 justify-content-end">
              <div className="form-group flex gap-2">
                <label
                  className="flex gap-2 align-items-center"
                  htmlFor="fromDate"
                >
                  <i className="fa fa-calendar  text-success"></i>From:
                </label>

                <ReactDatePicker
                  id="fromDate"
                  className="form-control"
                  customclassName="form-control"
                  wrapperClassName="detailDatePicker"
                  onChange={(date) => setFromDate(date)}
                  selected={fromdate}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="form-group flex gap-2">
                <label
                  className="flex gap-2 align-items-center"
                  htmlFor="toDate"
                >
                  <i className="fa fa-calendar  text-danger"></i>To:
                </label>

                <ReactDatePicker
                  ref={toDateRef}
                  id="toDate"
                  className="form-control"
                  customclassName="form-control"
                  wrapperClassName="detailDatePicker"
                  onChange={(date) => setToDate(date)}
                  selected={todate}
                  minDate={fromdate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </Col>
          </div>
        </div>
        <div className="container">
          <Row className="gap-4">
            {propertyData?.Rooms?.length > 0 ? (
              (OurRooms?.length > 0 ? OurRooms : propertyData.Rooms).map(
                (data, ind1) => (
                  <div className="roomsBox" key={ind1}>
                    {/* <h2>Deluxe Room</h2> */}
                    <div className="wrap flex">
                      <div className="col_ position-relative">
                        <div className="roomSlider">
                          <Slider {...settingsImgSlider}>
                            {data?.RoomImages.length ? (
                              data?.RoomImages.map((innerData, key) => (
                                <div key={key}>
                                  <figure className="sliderImg">
                                    <img
                                      src={`${baseImgUrl}${innerData.imageName}`}
                                      alt=""
                                    />
                                  </figure>
                                </div>
                              ))
                            ) : (
                              <div>
                                <figure className="sliderImg">
                                  <img alt="" src={sliderImgOnly} />
                                </figure>
                              </div>
                            )}
                          </Slider>
                        </div>
                      </div>
                      <div className="col_ position-relative">
                        <div className="wraps">
                          <div className="left-content">
                            {categoryData.map((innerData, key1) => {
                              if (innerData.id === data.categoryId) {
                                return <h3 key={key1}>{innerData.name}</h3>;
                              }
                            })}
                            <ul className="roomDesctiptionLess m-0 py-0 pl-2">
                              {data.roomDescription?.split(",").map((item) => (
                                <li
                                  className="m-0 ml-2 text-dark"
                                  style={{
                                    fontSize: ".7rem",
                                    letterSpacing: 0.5,
                                    listStyle: "circle",
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        {data?.avaiableRooms > 0 ? null : (
                          <div className="position-absolute top-0 end-0 sold-out">
                            {data?.status ? null : (
                              <img alt="" src={soldOut} alt="sold out img" />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="buttons flex justify-content-between border-top align-items-center">
                      <div className="flex flex-column">
                        <span className="text-primary flex gap-2 align-items-end">
                          <p className="m-0 fw-medium">
                            ₹ {data?.regularPrice ?? 0}
                          </p>
                          <small>
                            <del className="text-danger">
                              ₹
                              {Math.round(
                                data?.regularPrice / ((100 - 20) / 100)
                              )}
                            </del>
                          </small>
                          <span className="text-success fw-medium">
                            20% OFF{" "}
                          </span>
                        </span>
                        <span className="text-black"> Per Night </span>
                      </div>

                      <button
                        className="p-2 bg-black text-white border-0"
                        onClick={(e) => {
                          e.preventDefault();
                          bookHandler(data, 24, data?.regularPrice);
                        }}
                        disabled={!data?.avaiableRooms}
                        style={{ opacity: data?.avaiableRooms ? 1 : 0.4 }}
                      >
                        Book Now
                      </button>
                    </div>
                    <div className="roomDetails">
                      <div className="amenities">
                        <h5>Room Amenities</h5>
                        <ul>
                          {data.RoomAmenities
                            ? amenitiesRender(
                                "room",
                                showMore === ind1
                                  ? data.RoomAmenities
                                  : data.RoomAmenities?.slice(0, 4)
                              ).map((data, key3) => (
                                <li key={key3}>
                                  <img
                                    alt=""
                                    src={`${baseImgUrl}${data.icon}`}
                                  />
                                  <span>{data.name}</span>
                                </li>
                              ))
                            : null}
                          {data.RoomAmenities?.length > 4 && (
                            <li
                              onClick={() => setShowMoreFun(ind1)}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="bi bi-three-dots"
                                style={{ fontSize: "1rem" }}
                              ></i>
                              <span>
                                show {showMore !== ind1 ? "More" : "Less"}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <p className="p-2 rounded bg-white">Rooms Not Available!</p>
            )}
          </Row>
        </div>
      </div>
      <div className="detailsWrapper">
        <div className="container flex">
          <div className="prop-description d-flex">
            <div className="w-75">
              <h2>Description</h2>
              <p className="text-justify pr-4">
                {propertyData.propertyDescription}
              </p>
            </div>
            <div className="w-25">
              {propertyData.latitude ? (
                <iframe
                  src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2116.0067224963614!2d${propertyData.longitude}!3d${propertyData.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUyJzU0LjUiTiA4MMKwNTgnNDAuNSJF!5e0!3m2!1sen!2sin!4v1693811926541!5m2!1sen!2sin`}
                  loading="lazy"
                  width={"100%"}
                  height={"100%"}
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* policy section start here */}
      <div className="policy-section">
        <div className="container flex">
          <div className="policybox">
            <div className="heading">
              <h2>Hotel Policies</h2>
            </div>
            <h4>Restrictions</h4>
            <ul>
              {showPolicy.map((item, ind) => (
                <li key={ind}>{isNaN(item) ? item : propertyPolicy[item]}</li>
              ))}
              <li>
                Guests can check in using any one of the following original ID
                proofs - Passport, Adhaar Card, Driving Licence, Voter ID
              </li>
              <li>
                Couples over 18 years of age are welcome on Local/Outstation ID
              </li>
              <li>Softcopy of ID proofs not accepted.</li>
              <li>
                The hotel reserves the right of admission and RRooms will not be
                responsible for any check-in denied by the hotel due to the
                aforesaid reason.
              </li>
            </ul>

            <h4>Hotel Cancellation Policy</h4>
            <ul>
              <li>
                {" "}
                Refund will be provided only if cancellation is done 24 hours
                prior to selected check-in time.
              </li>
              <li>
                {" "}
                In case booking has been done within 24 hours of check-in time,
                the refund will be provided only if the booking is cancelled
                within 15 minutes from the time of booking.
              </li>
              <li>
                {" "}
                There will be no refund, If you do not show up at the hotel.
              </li>
              <li>
                {" "}
                There will be no refund if you decide to cancel the booking in
                the middle of your stay.
              </li>
              <li>
                {" "}
                If eligible, refund will be initiated, which will reflect in
                your account within 5-7 business days.
              </li>
            </ul>
            <div className="moreBtns">
              {/* <a href="https://www.rrooms.in/Rrooms-privacy-policy/">
                Read all Hotel policiees
              </a> */}
            </div>
          </div>
          <div className="faqbox">
            <div className="heading">
              <h2>Frequesntly Asked Questions</h2>
            </div>
            <div className="accordian first">
              <div className="box">
                <div className="tab-head d-flex">
                  <div className="icon">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <h3>Why should we book rooms with RRooms </h3>
                </div>
                <div className="tab-content">
                  <p>
                    RRooms services are a must to avail for everyone out there
                    looking for hotels because -We offer a vast array of hotels
                    and accommodations, providing you with an extensive
                    selection of options to choose from. We have exclusive deals
                    and discounts, helping you secure the best possible rates
                    for your room. Once you've chosen your ideal room, you can
                    complete the booking process within the app very smoothly. A
                    powerful price comparison option that allows you to compare
                    rates from various booking platforms and hotels directly.
                  </p>
                </div>
              </div>
              <div className="box">
                <div className="tab-head d-flex">
                  <div className="icon">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <h3>
                    Will RRooms allow local people to book hotel rooms with
                    local Id proofs?{" "}
                  </h3>
                </div>
                <div className="tab-content">
                  <p>
                    Yes, definitely. Anyone with a local id is welcomed in most
                    of our partner hotels. However, please do check for Local ID
                    Accepted tag on hotel allowing couples on local ID while
                    booking.
                  </p>
                </div>
              </div>
              <div className="box">
                <div className="tab-head d-flex">
                  <div className="icon">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <h3>RRooms partnered hotels are safe?</h3>
                </div>
                <div className="tab-content">
                  <p>
                    We take utmost care while listing any hotel on our website.
                    All the hotels are completely safe and secure. We have a
                    rigorous quality control process for every hotel when they
                    come on-board. We ensure a hassle-free check-in procedure
                    for our customers.
                  </p>
                </div>
              </div>
              <div className="box">
                <div className="tab-head flex">
                  <div className="icon">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                  <h3>Are unmarried couples allowed to check-in? </h3>
                </div>
                <div className="tab-content">
                  <p>
                    Of course, unmarried couples are allowed to book hotel rooms
                    as per their convenience. Just search us for us online and
                    proceed with the bookings. We assure you that your stay with
                    us will be the most memorable one for both of you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="policy-section" ref={wrapper}>
        <div className="container flex">
          <div className="policybox w-100">
            <div className="heading">
              <h5>Customer Reviews and Ratings</h5>
            </div>
            {tableData?.length > 0 ? (
              tableData.map((data, key) => {
                if (data?.id) {
                  return (
                    <div className="customerReview" key={key}>
                      <h4>{data.User?.name?.toUpperCase()}</h4>
                      <div className="starsLists">
                        <Rating
                          style={{ maxWidth: 120 }}
                          value={data.rating}
                          readOnly
                        />
                      </div>
                      <div className="date">
                        {data.createdAt && (
                          <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                        )}
                      </div>
                      <p>{data.review}</p>
                    </div>
                  );
                }
              })
            ) : (
              <p className="text-white fs-6 bg-primary py-1 px-3 d-inline rounded">
                New Listing
              </p>
            )}
          </div>
        </div>
      </div>

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Hotaldetails;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
};
