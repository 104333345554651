import Layout from "layout";
import useResturantOrders from "../../controllers/Resturant.controller";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ErrorMessage } from "@hookform/error-message";
import { showPrice } from "utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ResturantGuestType,
  banquetPaymentMode,
  resturantStatus,
} from "constant/constants";
import { InputField } from "components/InputField/InputField";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { resturantOrderPayment } from "API/services/newServices";
import moment from "moment";
import ReactGA from "react-ga4";

const validation = Yup.object().shape({
  paymentMode: Yup.string().required("Please Select Payment Method*"),
  amount: Yup.string().required("Please Enter amount*"),
  reference: Yup.string().required("Please Enter Reference*"),
});
const validationWithoutTrID = Yup.object().shape({
  paymentMode: Yup.string().required("Please Select Payment Method*"),
  amount: Yup.string().required("Please Enter amount*"),
});

const rules = { resolver: yupResolver(validation) };
const rules1 = { resolver: yupResolver(validationWithoutTrID) };

const ResturantOrders = () => {
  const {
    tableData,
    loginUsers,
    STORY_HEADERS,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    foodItems,
    handleAddItem,
    fields,
    deleteItem,
    quantityHanlder,
    roomNumbers,
    roomState,
    corporateState,
    reportState,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchfilter,
    show,
    userDetail,
    getDataHandler,
    setShow,
    selected,
    setSelected,
    selectItemsShow,
    itemsModal,
    setItemsModal,
    foodItemModalRef,
    foodItemPrint,
    tables,
    onDownload,
    tableRef,
  } = useResturantOrders();

  const [rule, setRule] = useState(rules);

  const {
    register: payRegister,
    handleSubmit: paySubmit,
    reset,
    setValue,
    watch,
    formState: { errors: payError },
  } = useForm({ mode: "onSubmit", ...rule });
  const onHide = () => {
    setShow(false);
    setSelected({});
    reset();
  };
  const onSubmitPayment = async (payload) => {
    const param = {
      orderId: selected.id,
      transactionId: payload.reference,
      propertyId: userDetail.propertyId,
      paymentAmount: selected.orderAmount,
      paymentMode: payload.paymentMode,
    };
    const res = await resturantOrderPayment(param);
    if (res) {
      onHide();
      getDataHandler();
      setSelected({});
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: reportState ? "restaurant_order_report" : "restaurant_orders",
    });
    if (selected) {
      setValue("amount", selected.orderAmount);
    }
  }, [selected]);

  const { paymentMode } = watch();

  useMemo(() => {
    if (paymentMode === "Cash") {
      setRule(rules1);
    } else {
      setRule(rules);
    }
  }, [paymentMode]);

  return (
    <Layout
      title={
        reportState ? "Restaurant Orders Reporting" : "Restaurant Food Orders"
      }
      openHandler={
        reportState ? () => onDownload() : () => openAddModalHanlder()
      }
      setToDate={setEndDate}
      setFromDate={setStartDate}
      searchType={"date"}
      toDate={endDate}
      fromDate={startDate}
      searchfilter={!reportState ? false : startDate}
      type={reportState}
      optionData={resturantStatus}
      setSearchfilter={searchfilter}
      buttonTitle={reportState ? "Export Table" : "Add"}
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <div className="scrollTable">
            <Table className="food-report" ref={tableRef}>
              <TableHeader />
              <TableBody />
            </Table>
          </div>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
      <Modal
        show={openAddModal}
        onHide={hideAddModalHanlder}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit " : "Add "} Restaurant Food Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={6} className="form-group">
                      <label>Select Guest Type</label>
                      <select {...register(`guestType`)}>
                        {ResturantGuestType.map((item, ind) => (
                          <option
                            key={ind}
                            selected={ind == 0}
                            value={ind ? ind : ""}
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                      {errors?.guestType?.message && (
                        <div className="error-text">
                          <img src={AlertIcon} alt="error-icons" />
                          <span>
                            <ErrorMessage errors={errors} name={`guestType`} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        text={"Table Number"}
                        field="table"
                        type="options"
                        notnull
                        errors={errors}
                        register={register}
                        options={tables}
                      />
                      {errors?.table?.message && (
                        <div className="error-text">
                          <img src={AlertIcon} alt="error-icons" />
                          <span>
                            <ErrorMessage errors={errors} name={`table`} />
                          </span>
                        </div>
                      )}
                    </Col>

                    {roomState ? (
                      <>
                        <Col xs={6} className="form-group">
                          <label>Select Room Number</label>
                          <select {...register(`room`)}>
                            <option selected value="" disabled>
                              Select Room Number
                            </option>
                            {roomNumbers?.map((item, key) => (
                              <option value={item} key={key}>
                                {item}
                              </option>
                            ))}
                          </select>

                          {errors?.room?.message && (
                            <div className="error-text">
                              <img src={AlertIcon} alt="error-icons" />
                              <span>
                                <ErrorMessage errors={errors} name={`room`} />
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col xs={6} className="form-group">
                          <InputField
                            text={"Booking Code"}
                            field="bookingCode"
                            type="text"
                            placeholder="Enter Booking Code"
                            notnull
                            errors={errors}
                            register={register}
                          />

                          {errors?.bookingCode?.message && (
                            <div className="error-text">
                              <img src={AlertIcon} alt="error-icons" />
                              <span>
                                <ErrorMessage
                                  errors={errors}
                                  name={`bookingCode`}
                                />
                              </span>
                            </div>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={6} className="form-group">
                          <InputField
                            text={"Guest Name"}
                            field="guestName"
                            type="text"
                            placeholder="Enter Guest Name"
                            notnull
                            errors={errors}
                            register={register}
                          />

                          {errors?.guestName?.message && (
                            <div className="error-text">
                              <img src={AlertIcon} alt="error-icons" />
                              <span>
                                <ErrorMessage
                                  errors={errors}
                                  name={`guestName`}
                                />
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col xs={6} className="form-group">
                          <InputField
                            text={"Guest Mobile Number"}
                            field="mobileNumber"
                            type="text"
                            placeholder="Enter Guest Mobile Number"
                            notnull
                            errors={errors}
                            max={10}
                            register={register}
                          />

                          {errors?.mobileNumber?.message && (
                            <div className="error-text">
                              <img src={AlertIcon} alt="error-icons" />
                              <span>
                                <ErrorMessage
                                  errors={errors}
                                  name={`mobileNumber`}
                                />
                              </span>
                            </div>
                          )}
                        </Col>
                        {corporateState && (
                          <Col xs={6} className="form-group">
                            <InputField
                              text={"Enter Gst"}
                              field="gst"
                              type="text"
                              placeholder="Enter Guest GST Number"
                              notnull
                              errors={errors}
                              register={register}
                            />

                            {errors?.gst?.message && (
                              <div className="error-text">
                                <img src={AlertIcon} alt="error-icons" />
                                <span>
                                  <ErrorMessage errors={errors} name={`gst`} />
                                </span>
                              </div>
                            )}
                          </Col>
                        )}
                      </>
                    )}

                    <Col xs={12}>
                      <label>Food Items</label>
                      <table className="table-bordered table-hover w-100 text-center common-table">
                        <thead>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </thead>
                        <tbody>
                          {fields.map((item, ind) => (
                            <tr key={ind}>
                              <td>{item.name}</td>
                              <td>{item.price}</td>
                              <td>
                                <div className="quantityHandler">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      quantityHanlder(item, ind, false)
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    value={item.quantity}
                                    readOnly
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      quantityHanlder(item, ind, true)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="text-center">
                                {item.total}
                                <button
                                  type="button"
                                  className="btn text-danger p-0 ml-2"
                                  onClick={() => deleteItem(ind)}
                                >
                                  <FontAwesomeIcon
                                    size="lg"
                                    icon="fa-solid fa-trash-can"
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {errors?.foodItems?.message && (
                        <div className="error-text">
                          <img src={AlertIcon} alt="error-icons" />
                          <span>
                            <ErrorMessage errors={errors} name={`foodItems`} />
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col
                      xs={12}
                      className="form-group flex justify-content-between mt-4"
                    >
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={6}
                  style={{ padding: "0 0rem 0 1rem", height: "80vh" }}
                  className="border mb-2 shadow rounded overflow-hidden"
                >
                  <div className="flex justify-content-between align-items-center p-2">
                    <h5 className="text-center text-black">Food Items</h5>
                    <div className="form-group">
                      <InputField
                        type="search"
                        placeholder="Food name/code"
                        field="searchItems"
                        errors={errors}
                        register={register}
                        customClass={"p-1 m-0 bg-white"}
                      />
                    </div>
                  </div>
                  <div
                    className="foodItemsShow flex row-gap-3 column-gap-2 overflow-auto h-100"
                    style={{ padding: "1rem .5rem 5rem .5rem" }}
                  >
                    {foodItems.length > 0 ? (
                      foodItems.map((item) => {
                        return (
                          <div
                            style={{
                              cursor: "pointer",
                              boxShadow:
                                fields.some((i) => i.itemId === item.id) &&
                                "3px 3px 4px rgba(255, 180, 0, 1), -3px -3px 4px rgba(255, 180, 0, 1)",
                            }}
                            className="rounded-md px-4 py-1 border border-black"
                            key={item.id}
                            onClick={() => handleAddItem(item)}
                          >
                            <div className="item-name">
                              <h4>{item.name}</h4>
                              <p className="m-0">{showPrice(item.price)}</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="flex flex-column align-center justify-content-center full-width text-black w-100"
                        style={{ height: "10rem" }}
                      >
                        <FontAwesomeIcon
                          className="mb-2"
                          icon="fa-solid fa-ban"
                          style={{ width: "40px", height: "40px" }}
                          beat
                        />
                        <span>Data not available</span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkInModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Collect Payment
          </Modal.Title>
          <button className="closeButton" onClick={onHide}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={paySubmit(onSubmitPayment)}
          >
            <Col xs={12} className="form-group">
              <Form.Label className="label">Select Payment Mode</Form.Label>
              <select
                {...payRegister("paymentMode")}
                className="form-control pb-2"
              >
                <option selected disabled value="">
                  select
                </option>
                {banquetPaymentMode.map((mode, ind) => (
                  <option value={mode} key={ind}>
                    {mode}
                  </option>
                ))}
              </select>

              {payError?.paymentMode?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={payError} name={"paymentMode"} />
                  </span>
                </div>
              )}
            </Col>

            <Col xs={12} className="form-group">
              <InputField
                text="Amount"
                type="number"
                field="amount"
                autoFocus
                register={payRegister}
                errors={payError}
                placeholder={"amount"}
                disabled
              />
              {payError?.amount?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={payError} name={"amount"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={12} className="form-group">
              <InputField
                text="Transaction Id"
                type="text"
                field="reference"
                autoFocus
                register={payRegister}
                errors={payError}
                placeholder={"Transaction Id"}
              />
              {payError?.reference?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={payError} name={"reference"} />
                  </span>
                </div>
              )}
            </Col>

            <Modal.Footer>
              <Button type="submit">Pay</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={itemsModal} onHide={() => setItemsModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Items</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Row id="pdf" ref={foodItemModalRef}>
            <Row className="mb-2">
              <Col xs={6}>
                <span className="text-black">
                  Order Number: {selectItemsShow?.id}
                </span>
              </Col>
              <Col xs={6} className="text-end">
                <span className="text-black ">
                  Order Type: {ResturantGuestType[selectItemsShow.orderType]}
                </span>
              </Col>
              {selectItemsShow.orderType == 2 && (
                <>
                  <Col xs={6} className="text-end">
                    <span className="text-black">
                      Room Number: {selectItemsShow?.roomNumber}
                    </span>
                  </Col>
                  <Col xs={6}>
                    <span className="text-black">
                      Booking ID: {selectItemsShow?.bookingId}
                    </span>
                  </Col>
                </>
              )}
              <Col xs={6}>
                <span className="text-black">
                  Guest Name:
                  {selectItemsShow.otherGuestName}
                </span>
              </Col>
            </Row>
            <Table striped hover className="modal_Table common-table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selectItemsShow?.orderItems?.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item.name}</td>
                    <td className="text-center">{showPrice(item.price)}</td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-center">
                      {showPrice(item.price * item.quantity)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {selectItemsShow?.ncType !== "0" && (
              <Col xs={6}>
                <span>Remark : {selectItemsShow?.remark}</span>
              </Col>
            )}

            <div className="flex justify-content-between">
              <span className="text-black text-end">
                Created By:{" "}
                {selectItemsShow.userId
                  ? "Guest"
                  : loginUsers.find((i) => i.id == selectItemsShow.createdBy)
                      ?.firstName +
                      " " +
                      loginUsers.find((i) => i.id == selectItemsShow.createdBy)
                        ?.lastName ?? "Guest"}
              </span>
              <span className="text-black text-end">
                Date & Time:{" "}
                {moment(selectItemsShow?.createdAt).format("DD-MM-YY HH:MM A")}
              </span>
            </div>
            <Row className="d-flex justify-content-end mt-2">
              <div md={8}>
                <span className="text-success text-end border border-success px-2 py-1 rounded-sm ">
                  Order Amount : {showPrice(selectItemsShow.orderAmount)}
                </span>
              </div>
            </Row>
          </Row>

          <Row className="d-flex justify-content-center pr-2">
            <Col xs={2}>
              <Button
                variant="primary"
                className="btn-sm"
                onClick={foodItemPrint}
              >
                Print
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default ResturantOrders;
