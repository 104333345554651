import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

const HelpCenter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>Help Center</h1>
          <p>
            We are dedicated to providing excellent service and assistance to
            our guests and website visitors. Whether you have questions about
            booking a room, need information about our facilities, or want to
            know more about our policies, you'll find answers to frequently
            asked questions and helpful resources here.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have specific questions or concerns, please don't hesitate to
            contact our dedicated customer support team. We are here to assist
            you
          </p>

          <h2>Contact Information:</h2>
          <p className="flex flex-column">
            <span>Phone: +917377378030</span>
            <span>
              Email: <a href="mailTo:contact@rrooms.in">contact@rrooms.in</a>
            </span>
          </p>

          <h2>Live Chat</h2>
          <p>
            Click the chat icon in the bottom right corner to chat with a
            representative during our business hours.
          </p>
          <h2>Visit Us</h2>
          <p>
            If you prefer to speak with someone in person, feel free to visit
            our property, and our front desk staff will be happy to assist you.
          </p>
          <h2>Feedback and Suggestions</h2>
          <p>
            Your feedback is important to us. If you have any suggestions for
            improving our website or services or if you encountered any issues
            while using our website, please let us know. We value your input and
            strive to enhance the guest experience continually.
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default HelpCenter;
