import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link } from "react-router-dom";
import Layout from "../../layout";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import {
  getPropertyUsersAction,
  usersSeletor,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getRroomPropertyAction,
  propertySeletor,
} from "../../store/slices/propertySlice";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Moment from "react-moment";
import ReactGA from "react-ga4";

const UserLogins = () => {
  const dispatch = useDispatch();
  const { propertyUsers } = useSelector(usersSeletor);
  const { rRoomProperty } = useSelector(propertySeletor);
  const { userDetail } = useSelector(authenticationSeletor);
  const [propertyList, setPropertyList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPropertyUsersAction(userDetail.propertyId));
    dispatch(getRroomPropertyAction(userDetail.propertyId));
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "user_logins" });
    let getUsers = [];
    propertyUsers.map((data) => {
      if (userDetail?.propertyId == data.propertyId) {
        getUsers.push(data);
      }
    });
    setUserList(
      userDetail?.logintype == "rroomUser" ? propertyUsers : getUsers
    );
  }, [propertyUsers, userDetail]);
  useEffect(() => {
    setPropertyList(rRoomProperty);
  }, [rRoomProperty]);

  const { AlertIcon } = Imagesdata || {};

  const [show, setShow] = useState(false);
  const [userList, setUserList] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      // userCode: "PUVIKAS00088",
      firstName: payload.firstName,
      lastName: payload.lastName,
      name: payload.firstName + " " + payload.lastName,
      email: payload.email,
      mobile: payload.phoneNumber,
      role: 1,
      password: payload.password,
      propertyId:
        userDetail?.logintype == "rroomUser"
          ? payload.property
          : userDetail?.propertyId,
      designation: payload.designation,
    };
    try {
      const res = await services.user.addPropertyUserService(realPayload);
      if (res.status === 200) {
        reset();
        dispatch(getPropertyUsersAction());
        setShow(false);
      }
    } catch (error) {}
  };

  return (
    <Layout title="Property Users" openHandler={() => setShow(true)}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No</th>
            {userDetail?.logintype == "rroomUser" ? (
              <th>Property Name</th>
            ) : null}
            <th>User Code</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
            <th>mobile</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {[...userList].reverse().map((data, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              {/* <td>{data.id}</td> */}
              {userDetail?.logintype == "rroomUser" ? (
                <td>
                  {propertyList.map((dataInner) => {
                    if (dataInner.id == data.propertyId) {
                      return dataInner.name;
                    }
                  })}
                </td>
              ) : null}
              <td>{data.userCode}</td>
              <td>{data.firstName + " " + data.lastName}</td>
              <td>{data.designation}</td>
              <td>{data.email}</td>
              <td>{data.mobile}</td>
              <td>
                <Moment format="MMMM Do YYYY, h:mm:ss a">
                  {data.updatedAt}
                </Moment>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New user details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <p>New user details</p>
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="firstName"
                    text="First Name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter first name"
                    field={"firstName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.firstName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"firstName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="lastName"
                    text="Last Name"
                    type="text"
                    placeholder="Enter Last name"
                    field={"lastName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.lastName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"lastName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="phoneNumber"
                    text="Phone No."
                    type="text"
                    placeholder="Enter phone number"
                    field={"phoneNumber"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.phoneNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"phoneNumber"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="email"
                    text="Email address "
                    type="email"
                    placeholder="Enter email address"
                    field={"email"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.email?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"email"} />
                      </span>
                    </div>
                  )}
                </Col>
                {userDetail?.logintype == "rroomUser" ? (
                  <Col xs={12} className="form-group">
                    <label>Property</label>
                    <select register={register} {...register("property")}>
                      <option disabled selected value="">
                        Select property
                      </option>
                      {propertyList.map((innerData, key) => (
                        <option key={key} value={innerData.id}>
                          {innerData.name}
                        </option>
                      ))}
                      {/* <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={6}>6</option>
                    <option value={8}>8</option>
                    <option value={10}>10</option> */}
                    </select>
                    {/* {errors?.designation?.message && (
                    <div className="error-text">
                      <img alt=""src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"designation"} />
                      </span>
                    </div>
                  )} */}
                  </Col>
                ) : null}
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="designation"
                    text="Designation"
                    type="text"
                    placeholder="Enter designation"
                    field={"designation"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.designation?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"designation"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="password"
                    text="Create Password"
                    type="password"
                    placeholder="Enter new password"
                    field={"password"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.password?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"password"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="confirmPassword"
                    text="Confirm Password"
                    type="password"
                    placeholder="Enter confirm password"
                    field={"confirmPassword"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.confirmPassword?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"confirmPassword"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default UserLogins;
