import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>Careers</h1>
          <p>
            We're thrilled that you're interested in joining our team and
            becoming a part of the exciting world of hospitality. At RRooms, we
            believe that our success is built upon the talent, dedication, and
            passion of our employees. We strive to create an environment where
            every team member can thrive and grow in their career.
          </p>
          <h1>Why Join Our Team:</h1>
          <h2>Passion for Hospitality</h2>
          <p>
            We are driven by a genuine passion for providing exceptional service
            and creating memorable experiences for our guests.
          </p>
          <h2>Team Collaboration</h2>
          <p>
            Our team values collaboration and teamwork. We believe that working
            together fosters creativity and innovation.
          </p>
          <h2>Continuous Learning</h2>
          <p>
            We are committed to helping our employees grow both personally and
            professionally. We offer ongoing training and development
            opportunities.
          </p>
          <h2>Diverse and Inclusive</h2>
          <p>
            We embrace diversity and inclusion, recognizing that our differences
            make us stronger. We celebrate unique perspectives and backgrounds.
          </p>
          <h2>Guest-Centric</h2>
          <p>
            Our focus is on our guests, and we take pride in going above and
            beyond to exceed their expectations.
          </p>
          <h1>Our Current Job Openings</h1>
          <h2>
            Explore the exciting career opportunities we have available at
            RRooms
          </h2>
          <ol>
            <li className="flex align-items-center gap-2">
              <span>Front Desk Associate:</span>
              <p className="m-0">
                Join our front desk team and be the first point of contact for
                our guests. Provide exceptional service and assist with
                check-ins, check-outs, and guest inquiries
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Housekeeping:</span>
              <p className="m-0">
                Play a crucial role in maintaining the cleanliness and comfort
                of our hotel rooms and public areas. Attention to detail is key
                in this role
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Restaurant Staff:</span>
              <p className="m-0">
                If you're passionate about food and beverage service, consider
                joining our restaurant team. We have positions available for
                servers, bartenders, and kitchen staff.
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Event Coordinator:</span>
              <p className="m-0">
                Help us create unforgettable events for our guests. If you have
                a knack for organization and enjoy working with clients, this
                could be the role for you.
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Management Positions:</span>
              <p className="m-0">
                We offer various management opportunities in departments such as
                operations, sales, and guest services. If you have leadership
                experience, we'd love to hear from you.
              </p>
            </li>
          </ol>

          <h1>How to Apply:</h1>
          <h2>To apply for a position at RRooms, please follow these steps</h2>

          <ol>
            <li className="flex align-items-center gap-2">
              <span>Visit Our Careers Page:</span>
              <p className="m-0">
                Go to our website's Careers section to view current job
                openings.
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Select a Job:</span>
              <p className="m-0">
                Click on the job listing that interests you to learn more about
                the position, qualifications, and responsibilities.
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Apply Online:</span>
              <p className="m-0">
                Submit your application and resume directly through our online
                application portal. Make sure to include a cover letter
                highlighting your relevant experience and why you want to join
                our team.
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Interview Process:</span>
              <p className="m-0">
                If your qualifications match our requirements, we will reach out
                to schedule an interview. Be prepared to discuss your skills,
                experience, and passion for hospitality.
              </p>
            </li>
            <li className="flex align-items-center gap-2">
              <span>Join Our Team:</span>
              <p className="m-0">
                Congratulations! If you are selected to join our team, we will
                provide you with all the necessary information for on-boarding
                and training.
              </p>
            </li>
          </ol>

          <p>
            At RRooms, we are committed to creating a positive and rewarding
            work environment for our employees. Join us in delivering
            exceptional hospitality experiences and building a career you can be
            proud of. We look forward to welcoming you to our team and working
            together to make each guest's stay memorable. Thank you for
            considering a career with us!
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Career;
