const AuthDictionary = {
  propertyLogin: () => "auth/signin-rrooms-user",
  userLogin: () => "auth/otpGen",
  userVerify: () => "auth/otpVerify",
  mainPropertyLogin: () => "auth/signin-property-user",
  editUser: (id) => `auth/update-user/${id}`,
  getUserUrl: (id) => `auth/customers/${id}`,
  getRoles: () => `auth/roles`,
  getRoomsUser: () => `auth/rrooms-users`,
  getApp: () => `auth/get-app-url`,
  forgotPasswordPropery: (id) =>
    `auth/property-users/generate-otp-password/${id}`,
  updatePasswordPropery: (id) => `auth/property-users/update-password/${id}`,
  forgotPasswordRrooms: (id) => `auth/rrooms-users/generate-otp-password/${id}`,
  updatePasswordRrooms: (id) => `auth/rrooms-users/update-password/${id}`,
  getPropetyUser: (id) => `auth/property-users/${id}`,
};

export default AuthDictionary;
