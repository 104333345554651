import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "../../layout";
import Moment from "react-moment";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { Controller, useForm } from "react-hook-form";
import { Imagesdata } from "../../assets/images/imageData";
import ReactGA from "react-ga4";

const FocRequest = () => {
  const [focData, setFocData] = useState([]);
  const [filterFoc, setFilterFoc] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);

  const { AlertIcon } = Imagesdata;
  const [selectItem, setSelectItem] = useState({});
  const [show, setShow] = useState(false);

  const { control, handleSubmit, getValues } = useForm();

  const getData = async () => {
    try {
      const res = await Services.property.getFocRequest(userDetail?.propertyId);
      if (res.status == 200) {
        setFocData(res?.data?.data);
      }
    } catch (error) {}
  };
  const rejectHandler = async (data, approve) => {
    try {
      const res = await Services.property.editFocRequest(data.id, {
        booking_id: data.bookingId,
        status: approve,
      });
      if (res.status == 200) {
        getData();
      }
    } catch (error) {}
  };
  const editHandler = async (data, approve) => {
    const { amount } = getValues();

    try {
      const res = await Services.property.editFocRequest(data.id, {
        booking_id: selectItem?.bookingId,
        status: approve,
      });
      if (res.status == 200) {
        const body = {
          dueAmount: selectItem?.BookingHotel?.dueAmount - Number(amount),
          collectedPayment: 0,
        };
        const realBody = {
          id: selectItem?.bookingId,
          body: body,
        };
        const res = await Services.property.updateBookingService(realBody);
        if (res.status === 200) {
          getData();
          setShow(false);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "foc_requests" });
    getData();
  }, []);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "SearchResults",
    sheet: "SearchResults",
  });

  const onAccept = (data) => {
    setSelectItem(data);
    setShow(true);
  };

  useEffect(() => {
    setFilterFoc(focData);
  }, [focData]);
  const [filterDate, setFilterDate] = useState(new Date());

  const dateFilter = () => {
    const resultsData = focData?.filter((order) => {
      return (
        new Date(order?.createdAt).toJSON().slice(0, 10) >=
        new Date(filterDate).toJSON().slice(0, 10)
      );
    });
    setFilterFoc(resultsData);
  };
  // const sendFoc = async (payload) => {
  //   const realPayload = {
  //     property_id: userDetail?.propertyId,
  //     booking_id: id,
  //     amount: bookingAmout,
  //     remark: payload?.Remarks,
  //   };
  //   try {
  //     const res = await services.globalService.createFocRequest(realPayload);
  //     if (res.status === 201) {
  //       const params = {
  //         bookingId: bookingDetails.id,
  //         action: `Request For FOC`,
  //         actionBy: userDetail?.firstName + " " + userDetail?.lastName,
  //         userType: "propertyUser",
  //       };
  //       dispatch(createLogs(params));
  //       reset();
  //       onHide();
  //       toast.success("FOC Request Send SuccessFull !", {
  //         autoClose: 1000,
  //         pauseOnHover: true,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {}
  // };
  useEffect(() => {
    dateFilter();
  }, [filterDate]);

  const searchfilter = (text) => {
    const resultsData = focData.filter((order) => {
      if (text === "") return focData;
      return (
        order?.BookingHotel?.bookingCode +
        order?.bookingId +
        order?.amount +
        order?.remark +
        order?.status
      )
        ?.toString()
        .toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterFoc(resultsData);
  };

  return (
    <>
      <Layout
        title="Foc Request"
        extraBtnHandler={onDownload}
        extraButton="Export Table"
        searchfilter={searchfilter}
        setFilterDate={setFilterDate}
        filterDate={filterDate}
      >
        <Table
          striped
          bordered
          hover
          className="inventoryTable common-table"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Booking Id</th>
              <th>Amount</th>
              <th>Request Date</th>
              <th>Remark</th>
              <th>Request Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filterFoc?.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>#{item.BookingHotel?.bookingCode}</td>
                  <td>{item.amount}</td>
                  <td>
                    <Moment format="DD MMM, YYYY">{item.createdAt}</Moment>
                  </td>
                  <td>{item?.remark ?? "N/A"}</td>
                  <td className="text-capitalize">{item.status}</td>
                  <td className="text-center">
                    {item.status === "pending" ? (
                      <>
                        <Button
                          variant="primary"
                          className="mr-2 btn-sm"
                          onClick={() => onAccept(item)}
                        >
                          {item.status === "pending" ? "Approve" : "Approved"}
                        </Button>
                        <Button
                          variant="danger"
                          className="btn-sm"
                          onClick={() => rejectHandler(item, false)}
                        >
                          {item.status === "pending" ? "Reject" : "Rejected"}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant={
                          item.status === "approved" ? "primary" : "danger"
                        }
                        className="btn-sm text-capitalize"
                        disabled
                      >
                        {item.status}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Modal
          size="md"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checkInModal"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Approve Foc Request
            </Modal.Title>
            <button className="closeButton" onClick={() => setShow(false)}>
              X
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form
              className="popup-form onboardForm"
              onSubmit={handleSubmit(() => editHandler(selectItem, true))}
            >
              <Col xs={12} className="form-group">
                <label htmlFor={"amount"} className="label form-label">
                  Amount
                  <small className="text-danger fw-bold fs-6">*</small>
                </label>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: "Enter Amount to Accept" }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <div className="inputWrap mb24">
                        <input
                          value={value}
                          className={`formControl border`}
                          autoComplete="off"
                          type="number"
                          placeholder="Enter Amount"
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                        />
                        {error && (
                          <div className="error-text">
                            <img alt="" src={AlertIcon} />
                            <span>{error.message}</span>
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </Col>
              <div className="amount-section">
                <span>Amount</span>
                <span className="amount">
                  ₹ {selectItem?.BookingHotel?.dueAmount}
                </span>
              </div>
              <Modal.Footer>
                <Button type="submit">Pay</Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
};
export default FocRequest;
