import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Services from "API/services";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import {
  addResturantOrder,
  deleteResturantOrder,
  getResturantOrder,
  getResturantTable,
} from "API/services/newServices";
import { useNavigate, useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { showPrice } from "utils/utils";
import { ResturantGuestType, resturantStatus } from "constant/constants";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { createLogs } from "store/slices/PropertyData";
import { useDownloadExcel } from "react-export-table-to-excel";

const validation = Yup.object().shape({
  room: Yup.string().required("Please Select Room Number*"),
  guestType: Yup.string().required("Please Select Guest Type*"),
  table: Yup.string().required("Please Select table*"),
});
const validation2 = Yup.object().shape({
  guestName: Yup.string().required("Please Enter Guest Name*"),
  mobileNumber: Yup.string()
    .required("Please Enter Mobile Number*")
    .length(10, "Enter Correct Mobile Number*"),
  guestType: Yup.string().required("Please Select Guest Type*"),
  table: Yup.string().required("Please Select table*"),
});

const useResturantOrders = () => {
  const [rule, setRule] = useState(validation);
  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    ...{ resolver: yupResolver(rule) },
  });
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "bookingDetails",
    sheet: "bookingDetails",
  });
  const { userDetail } = useSelector(authenticationSeletor);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // states
  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [selectItemsShow, setSelectItemsShow] = useState([]);
  const [itemsModal, setItemsModal] = useState(false);
  const [tables, setTables] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [allFoodItems, setAllFoodItems] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [reportState, setReportState] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [booking, setBooking] = useState(0);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loginUsers, setLoginUsers] = useState([]);

  const { fields, append, remove, update } = useFieldArray({
    name: "foodItems",
    control,
  });
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [corporateState, setCorporateState] = useState(false);
  const [roomState, setRoomState] = useState(false);

  const { AlertIcon } = Imagesdata;

  const { toPDF, targetRef } = usePDF({
    filename: "banquet-Bookings.pdf",
    page: { margin: Margin.MEDIUM, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 10,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });
  const { room, guestType, searchItems } = watch();
  useEffect(() => {
    if (room) {
      const bookData = bookingData?.find((item) =>
        item?.assignRoomNo.includes(room)
      );
      if (!!bookData) {
        setBooking(bookData);
        setValue(
          "bookingCode",
          `${bookData.bookingCode}-${
            bookData.User ? bookData.User.name : bookData.otherPersonName
          }`
        );
      }
    }
  }, [room]);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setReportState(true);
    } else {
      setReportState(false);
    }
  }, [id]);
  const getRecentBooking = async () => {
    try {
      const res = await Services.globalService.getBookingPropertyIdService(
        userDetail?.propertyId,
        `?page=0&size=100&bookingStatus=2`
      );
      if (res.status === 200) {
        const rooms = [];
        res.data.data.map((item) =>
          item.assignRoomNo?.split(",").map((data) => rooms.push(data))
        );
        setRoomNumbers(rooms);
        setBookingData(res.data.data);
      }
    } catch (error) {}
  };

  const deleteHandler = async (id) => {
    if (!window.confirm("Are You sure want to delete menu item")) {
      return;
    }
    const res = await deleteResturantOrder(id);
    if (res) {
      getDataHandler();
    }
  };
  const editHandler = (row) => {
    setEditMode(true);
    setValue("guestType", row.orderType);
    setValue("room", row.roomNumber);
    setValue("guestName", row.otherGuestName);
    setValue("mobileNumber", row.customerMobile);
    setValue("table", row.tableNumber);
    if (row.orderItems.length) {
      row.orderItems.map((item) => {
        append({
          itemId: item.id,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          total: item.price * item.quantity,
          amountBeforeTax: item?.amountBeforeTax,
          taxAmount: item?.taxAmount,
        });
      });
    }
    setSelected(row);
    openAddModalHanlder();
  };
  const collectPaymentPress = (row) => {
    setShow(true);
    setSelected(row);
  };

  const getAllUser = async () => {
    try {
      const res = await Services.property.getInventoryEmplyoee();
      if (res.status === 200) {
        setLoginUsers(res.data.result);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllUser();
  }, []);
  const changeStatus = async (row, cancle = 0) => {
    let orderStatus = cancle ? cancle : row.orderStatus + 1;
    if (orderStatus == 3 && !row.paymentStatus) {
      toast.error("Please Collect Amount to complete this order");
      return;
    }
    const orderMessage = `Food Order ${resturantStatus[orderStatus].name} Successfull !`;

    try {
      if (!window.confirm("Are You Sure Want to Change Status")) {
        return;
      }
      const realPayload = {
        editMode: true,
        id: row.id,
        body: {
          orderStatus,
        },
      };
      const res = await addResturantOrder(realPayload);
      if (res) {
        getDataHandler();
        toast.success(orderMessage);
      }
    } catch (error) {}
  };

  const STORY_HEADERS = [
    {
      prop: "id",
      title: "Order Id",
      isFilterable: true,
      isSortable: true,
      cell: (row) => `#${row.id}`,
    },
    {
      prop: "otherGuestName",
      title: "Name",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "tableNumber",
      title: "Table",
    },
    {
      prop: "orderItems",
      title: "Orders",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <button
          className="showItemsBtn"
          onClick={() => {
            setSelectItemsShow(row);
            setItemsModal(true);
          }}
        >
          <FontAwesomeIcon icon="fa-solid fa-eye" /> Items
        </button>
      ),
    },
    {
      prop: "orderType",
      title: "Guest Type",
      isFilterable: true,
      isSortable: true,
      cell: (row) => ResturantGuestType[row.orderType] ?? "N/A",
    },

    {
      prop: "customerGst",
      title: "GST No.",
      isSortable: true,
      isFilterable: true,
      cell: (row) => row.customerGst ?? "N/A",
    },
    {
      prop: "customerMobile",
      title: "Mobile Number",
      isSortable: true,
      isFilterable: true,
      cell: (row) => row.customerMobile ?? "N/A",
    },
    {
      prop: "orderAmount",
      title: "Total Amount",
      isFilterable: true,
      isSortable: true,
      cell: (row) => showPrice(row.orderAmount),
    },
    {
      prop: "paidAmount",
      title: "Paid Amount",
      isFilterable: true,
      isSortable: true,
      cell: (row) => showPrice(row.paidAmount),
    },
    {
      prop: "paymentStatus",
      title: "Payment Status",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (row.paymentStatus ? "Confirm" : "Pending"),
    },
    {
      prop: "orderStatus",
      title: "Order Status",
      isFilterable: true,
      isSortable: true,
      cellProps: { className: "text-success" },
      cell: (row) => (
        <span style={{ color: resturantStatus[row.orderStatus].color }}>
          {resturantStatus[row.orderStatus].name}
        </span>
      ),
    },
    {
      prop: "createdAt",
      title: "Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    ...(!reportState
      ? [
          {
            prop: "action",
            title: "Action",
            isSortable: true,
            isFilterable: true,
            cellProps: { className: "text-end", style: { minWidth: 350 } },
            cell: (row) => (
              <>
                {row.orderStatus < 3 && (
                  <>
                    <Button
                      className="btn btn-sm mr-1"
                      variant="success"
                      onClick={() => changeStatus(row)}
                    >
                      Confirm <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </Button>
                    {!row.orderStatus && (
                      <Button
                        className="btn btn-sm mr-1"
                        variant="danger"
                        onClick={() => changeStatus(row, 4)}
                      >
                        Cancel{" "}
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                      </Button>
                    )}
                    {row.paidAmount < row.orderAmount && (
                      <Button
                        className="btn btn-sm mr-1"
                        onClick={() => collectPaymentPress(row)}
                      >
                        Collect{" "}
                        <FontAwesomeIcon icon="fa-solid fa-money-bill-1-wave" />
                      </Button>
                    )}
                    <Button
                      className="btn-sm mr-1"
                      onClick={() => editHandler(row)}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    </Button>
                    {userDetail.role == 3 && (
                      <Button
                        className="btn-sm mr-1"
                        variant="danger"
                        onClick={() => deleteHandler(row.id)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash" />
                      </Button>
                    )}
                  </>
                )}

                {row.orderStatus === 3 && (
                  <Button
                    variant="success"
                    className="btn btn-sm mr-1"
                    onClick={() => {
                      navigate("/Food-Invoice", {
                        state: { ...row, banquetData: false },
                      });
                    }}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-download" />
                  </Button>
                )}
              </>
            ),
          },
        ]
      : []),
  ];
  const openAddModalHanlder = () => {
    setOpenAddModal(true);
  };
  const hideAddModalHanlder = () => {
    setOpenAddModal(false);
    reset();
    remove();
  };
  const getDataHandler = async () => {
    const res = await getResturantOrder(userDetail.propertyId);
    if (res) {
      setAllData(res);
      setTableData(res);
    }
  };

  const getTables = async () => {
    const res = await getResturantTable(userDetail.propertyId);
    if (res) {
      setTables(
        res.map((item) => ({
          value: item.tableNumber,
          label: item.tableNumber,
        }))
      );
    }
  };

  const onSubmit = async (payload) => {
    if (payload.foodItems.length < 1) {
      setError("foodItems", {
        type: "required",
        message: "Please add food items*",
      });
      return;
    }
    const orderItems = [];
    let orderAmount = 0;
    fields.map((item) => {
      orderItems.push({
        id: item.itemId,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        amountBeforeTax: item?.amountBeforeTax,
        taxAmount: item?.taxAmount * item.quantity,
        propertyId: userDetail?.propertyId,
        totalAmount: item.price * item.quantity,
      });
      orderAmount += +item.total;
    });
    const param = {
      editMode,
      id: selected.id,
      body: {
        propertyId: userDetail.propertyId,
        roomNumber: payload.room ?? "",
        orderAmount,
        paidAmount: 0,
        paymentStatus: 0,
        orderStatus: 0,
        orderNote: "N/A",
        tableNumber: payload.table,
        orderType: payload.guestType,
        otherGuestName:
          payload.guestType == 2
            ? booking.User?.name ?? booking.otherPersonName
            : payload.guestName ?? "",
        orderItems,
        remark: "N/A",
        createdBy: userDetail.id,
        customerMobile:
          payload.guestType == 2
            ? booking.User?.mobile ?? booking.otherPersonNumber
            : payload.mobileNumber ?? "",
        customerEmail: payload.guestType == 2 ? booking.User?.email ?? "" : "",
        customerGst: payload.gst ?? "",
        bookingId: booking.id,
      },
    };

    const res = await addResturantOrder(param);
    if (res) {
      if (!editMode && payload.guestType == 2) {
        foodOrderForRoom(payload, orderItems, orderAmount);
      }
      setEditMode(false);
      getDataHandler();
      hideAddModalHanlder();
      toast.success(
        `Food Order Successfully ${editMode ? "Updated" : "Placed"}*`
      );
    }
  };

  const foodOrderForRoom = async (payload, orderItems, orderAmount) => {
    const items = [];
    orderItems.map((item) => {
      items.push({
        id: item.id,
        qty: item.quantity,
        name: item.name,
        price: item.price,
        totalAmount: item.price * item.quantity,
      });
    });
    const realPayload = {
      user_id: 0,
      booking_id: booking.id,
      room_number: payload.room,
      order_amount: Number(orderAmount),
      order_items: items,
      created_by: userDetail?.id,
      nc_type: "0",
    };

    try {
      const res = await Services.property.createFoodOrders(realPayload);
      if (res.status === 200) {
        const params = {
          bookingId: booking.id,
          action: `Food Order Placed*`,
          actionBy: userDetail?.firstName + " " + userDetail?.lastName,
          userType: "propertyUser",
        };
        dispatch(createLogs(params));
      }
    } catch (error) {}
  };

  const handleAddItem = async (item) => {
    if (fields.some((i) => i.itemId == item.id)) {
      quantityHanlder(
        fields.find((i) => i.itemId == item.id),
        0,
        true
      );
    } else {
      append({
        itemId: item.id,
        name: item.name,
        price: item.price,
        quantity: 1,
        total: item.price * 1,
        amountBeforeTax: item.amountBeforeTax,
        taxAmount: item.taxAmount,
      });
    }
  };
  const deleteItem = (index) => {
    if (window.confirm("Are you sure to delete this item?")) {
      remove(index);
    }
  };

  const quantityHanlder = async (item, index, isIncrement) => {
    let temp = item;
    if (isIncrement) {
      temp.quantity++;
      temp.total = temp.price * temp.quantity;
    } else {
      if (item.quantity > 1) {
        temp.quantity--;
        temp.total = temp.price * temp.quantity;
      } else {
        deleteItem(index);
        return;
      }
    }
    update(index, temp);
  };

  const getFoodItems = async () => {
    if (foodItems.length > 2) {
      return;
    }
    try {
      const res = await Services.property.getFoodItemsProperty(
        `?property_id=${userDetail?.propertyId}`
      );
      if (res.status === 200) {
        setFoodItems(res.data.data);
        setAllFoodItems(res.data.data);
      }
    } catch (error) {}
  };

  const filterFoodItems = (query) => {
    let text = query?.toLowerCase();
    const results = allFoodItems.filter((item) => {
      if (text == "") return allFoodItems;

      return (item.itemCode + item.name).toLowerCase().includes(text);
    });
    setFoodItems(results);
  };

  useEffect(() => {
    getDataHandler();
  }, []);
  useEffect(() => {
    if (searchItems) {
      filterFoodItems(searchItems);
    }
  }, [searchItems]);
  useEffect(() => {
    if (openAddModal) {
      getFoodItems();
      getTables();
    }
  }, [openAddModal]);

  useEffect(() => {
    if (guestType == 2) {
      setRule(validation);
      getRecentBooking();
      setRoomState(true);
    } else if (guestType == 4) {
      setCorporateState(true);
    } else {
      setRule(validation2);
      setCorporateState(false);
      setRoomState(false);
    }
  }, [guestType]);
  const searchfilter = (id) => {
    if (id) {
      const filterData = allData.filter((i) => i.orderStatus == id);
      setTableData(filterData);
    } else {
      setTableData(allData);
    }
  };
  const { toPDF: foodItemPrint, targetRef: foodItemModalRef } = usePDF({
    method: "open",
    filename: "foodReciept.pdf",
    page: { margin: Margin.MEDIUM, format: "A5" },
    // resolution: Resolution.EXTREME,
    canvas: { mimeType: "image/png", qualityRatio: 1 },
  });
  return {
    foodItemModalRef,
    foodItemPrint,
    STORY_HEADERS,
    tableData,
    tableRef,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    foodItems,
    handleAddItem,
    fields,
    quantityHanlder,
    deleteItem,
    roomNumbers,
    roomState,
    corporateState,
    filterFoodItems,
    reportState,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    tableRef,
    onDownload,
    searchfilter,
    userDetail,
    getDataHandler,
    setShow,
    selected,
    setSelected,
    show,
    selectItemsShow,
    itemsModal,
    setItemsModal,
    loginUsers,
    tables,
  };
};

export default useResturantOrders;
