import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import {
  getPropertyUsersAction,
  usersSeletor,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { toast } from "react-toastify";
import decodeEntities from "../../Common/DecodeHtml";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import {
  USER_DEG_TEXT,
  USER_DEG_TEXT_WITH_ANUM,
} from "../../constant/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiSelectInput from "../../components/multiSelect";
import { assignUnassignPropertyUser } from "../../API/services/newServices";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const PropertyUsers = () => {
  const dispatch = useDispatch();
  const { propertyUsers } = useSelector(usersSeletor);
  const { showProperties } = useSelector((s) => s.AllData);
  const { userDetail } = useSelector(authenticationSeletor);
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "property_users" });
    setPropertyList(showProperties);
  }, [showProperties]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPropertyUsersAction(userDetail.propertyId));
  }, []);

  useEffect(() => {
    let getUsers = [];
    propertyUsers.map((data) => {
      if (userDetail?.propertyId == data.propertyId) {
        getUsers.push(data);
      }
    });
    setUserList(
      userDetail?.logintype == "rroomUser" ? propertyUsers : getUsers
    );
  }, [propertyUsers, userDetail]);

  const { AlertIcon } = Imagesdata || {};

  const [show, setShow] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showUserList, setShowUserList] = useState([]);
  const [selectData, setSelectData] = useState({});
  const [showAllRoles, setShowAllRoles] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [onlyPropertyAssign, setOnlyPropertyAssign] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const assignUnassign = async (e) => {
    e.preventDefault();
    const res = await assignUnassignPropertyUser({
      assigneProperty: selectedItems,
      status: true,
      createdBy: userDetail?.id,
      propertyUserId: selectData.id,
    });
    if (onlyPropertyAssign && res.status === 200) {
      dispatch(getPropertyUsersAction(userDetail.propertyId));
      toast.success(`Property assigned to user successfull!`, {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
    setOnlyPropertyAssign(false);
    setSelectedItems([]);
    setShow(false);
  };

  const onSubmit = async (payload) => {
    const realPayload = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      name: payload.firstName + " " + payload.lastName,
      email: payload.email,
      mobile: payload.phoneNumber,
      role: Number(payload.role),
      password: payload.password,
      propertyId:
        userDetail?.logintype == "rroomUser"
          ? payload.property
          : userDetail?.propertyId,
      assignedProperty: payload.assignedProperty,
      designation: payload.designation,
      // agreement: 0,
    };

    try {
      let msg = "";
      let res = {};
      if (editMode) {
        realPayload.updatedBy =
          userDetail?.logintype != "rroomUser" ? userDetail?.id : 0;
        res = await services.user.editPrtUserService(
          selectData.id,
          realPayload
        );
        msg = "Updated";
      } else {
        realPayload.createdBy =
          userDetail?.logintype != "rroomUser" ? userDetail?.id : 0;
        res = await services.user.addPropertyUserService(realPayload);
        msg = "Created";
      }

      setEditMode(false);

      if (res.status === 200) {
        if (!res.data.status) {
          toast.error(`user with email and phone already exist*`);
          return;
        }
        reset();
        dispatch(getPropertyUsersAction(userDetail.propertyId));
        setShow(false);
        setValue("designation", "");
        setValue("role", "");
        toast.success(`Property User ${msg} SuccessFull !`);
      }
    } catch (error) {}
  };
  const getRoles = async () => {
    if (showAllRoles.length > 4) {
      return;
    }
    const res = await services.auth.getAllRoles();
    if (res.status === 200) {
      setShowAllRoles(res.data.data);
    }
  };

  // const filterData = [
  //   { name: "Department", id: "department" },
  //   { name: "Manager", id: "manager" },
  //   { name: "Designation", id: "designation" },
  // ];
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure want to delete User")) {
      try {
        const res = await services.auth.deletePrtUser(id);
        if (res.status == 200) {
          dispatch(getPropertyUsersAction(userDetail.propertyId));
          toast.success("User Deleted Successfull !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      } catch (error) {}
    }
  };
  const editHandler = async (data, forAssign = false) => {
    setShow(true);
    setSelectData(data);
    if (forAssign) {
      setOnlyPropertyAssign(true);
      setSelectedItems(
        data.UserProperties ? data.UserProperties?.map((i) => i.propertyId) : []
      );
      return;
    }
    getRoles();
    setOnlyPropertyAssign(false);
    setEditMode(true);
    setValue("firstName", data.firstName);
    setValue("lastName", data.lastName);
    setValue("email", data.email);
    setValue("phoneNumber", data.mobile);
    setValue("designation", data.designation);
    setValue("password", data.password);
    setValue("confirmPassword", data.password);
    setValue("role", data.Role.id);
    setValue("property", data.propertyId);
  };
  const searchfilter = (text) => {
    const results = userList.filter((item) => {
      if (text == "") return userList;
      return (
        USER_DEG_TEXT[item?.designation] +
        item?.firstName +
        item?.lastName +
        item?.email +
        item?.mobile +
        item?.userCode +
        item?.Role?.roleName
      )
        ?.toString()
        .toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setShowUserList(results);
  };
  useEffect(() => {
    setShowUserList(userList);
  }, [userList]);

  const updateSelectedProperty = (properties) => {
    setValue("assignedProperty", properties);
  };
  const openAddHandler = () => {
    getRoles();
    setShow(true);
  };
  const changeHandler = ({ value }) => {
    const results = userList.filter((item) => {
      if (!value) return userList;
      return item.propertyId == value;
    });
    setShowUserList(results);
  };

  return (
    <Layout
      wantSearchableListFor="property"
      title="Property Users"
      openHandler={openAddHandler}
      extraButton="Export Table"
      extraBtnHandler={onDownload}
      searchfilter={searchfilter}
      changeHandler={changeHandler}
    >
      <div className="scrollTable propertyUserTable">
        <Table striped bordered hover className="common-table" ref={tableRef}>
          <thead>
            <tr>
              <th>S.No</th>
              {userDetail?.logintype == "rroomUser" && <th>Property Name</th>}
              <th>User Code</th>
              <th>Name</th>
              <th>Designation</th>
              <th style={{ minWidth: 150 }}>Role</th>
              <th>Created By</th>
              <th>Edited By</th>
              <th>Email</th>
              <th>mobile</th>
              {userDetail.Role.id === 3 && <th>Password</th>}
              {userDetail.Role?.canEdit == 1 &&
                userDetail.Role?.canDelete == 1 && (
                  <th
                    style={{
                      minWidth:
                        userDetail?.logintype == "rroomUser" ? "auto" : 330,
                    }}
                  >
                    Action
                  </th>
                )}
            </tr>
          </thead>
          <tbody>
            {[...showUserList].reverse().map((data, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                {userDetail?.logintype == "rroomUser" && (
                  <td>
                    {propertyList.map((dataInner) => {
                      if (dataInner.id == data.propertyId) {
                        return dataInner.name;
                      }
                    })}
                  </td>
                )}
                <td>{data.userCode}</td>
                <td>{data.firstName + " " + data.lastName}</td>
                <td>{USER_DEG_TEXT[data.designation]}</td>
                <td>{data.Role?.roleName}</td>
                <td>
                  {data.createdBy
                    ? userList?.find((i) => i.id == data.createdBy)
                        ?.firstName ?? "N/A"
                    : "RROOMS"}
                </td>
                <td>
                  {data.updatedBy
                    ? userList?.find((i) => i.id == data.updatedBy)
                        ?.firstName ?? "N/A"
                    : "RROOMS"}
                </td>
                <td>
                  {"123".includes(userDetail.role)
                    ? data.email
                      ? decodeEntities(data.email)
                      : "N/A"
                    : "******@gmail.com"}
                </td>
                <td>
                  {"123".includes(userDetail.role) ? data.mobile : "**********"}
                </td>
                {userDetail.Role.id === 3 && <td>******</td>}
                {userDetail.Role?.canEdit == 1 &&
                  userDetail.Role?.canDelete == 1 && (
                    <td style={{ minWidth: 250 }}>
                      {userDetail.Role?.canEdit == 1 && (
                        <>
                          {userDetail?.logintype == "rroomUser" ? null : (
                            <>
                              <Button
                                variant="success"
                                className="mr-2 btn-sm"
                                onClick={() =>
                                  navigate(
                                    `/property-users/module-configuration/${data.id}`
                                  )
                                }
                              >
                                Config Menu{" "}
                                <FontAwesomeIcon icon="fa-solid fa-bars" />
                              </Button>
                              <Button
                                variant="primary"
                                className="mr-2 btn-sm"
                                onClick={() => editHandler(data, true)}
                              >
                                Assign{" "}
                                <FontAwesomeIcon icon="fa-solid fa-building" />
                              </Button>
                            </>
                          )}
                          <Button
                            variant="primary"
                            className="mr-2 btn-sm"
                            onClick={() => editHandler(data)}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                          </Button>
                        </>
                      )}

                      {userDetail.Role?.canDelete == 1 && (
                        <Button
                          variant="danger"
                          className="mr-2 btn-sm"
                          onClick={() => deleteHandler(data.id)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </Button>
                      )}
                    </td>
                  )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setValue("designation", "");
          setValue("role", "");
          setOnlyPropertyAssign(false);
          setEditMode(false);
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {onlyPropertyAssign ? "Assign Property" : "User details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={
              onlyPropertyAssign ? assignUnassign : handleSubmit(onSubmit)
            }
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <p>
                    {onlyPropertyAssign ? "Assign Property" : "User details"}
                  </p>
                </Col>
                {!onlyPropertyAssign && (
                  <>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="firstName"
                        text="First Name"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter first name"
                        field={"firstName"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.firstName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"firstName"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="lastName"
                        text="Last Name"
                        type="text"
                        placeholder="Enter Last name"
                        field={"lastName"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.lastName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"lastName"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="phoneNumber"
                        text="Phone No."
                        type="text"
                        placeholder="Enter phone number"
                        field={"phoneNumber"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.phoneNumber?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"phoneNumber"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="email"
                        text="Email address "
                        type="email"
                        placeholder="Enter email address"
                        field={"email"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.email?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"email"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    {userDetail?.logintype == "rroomUser" ? (
                      <Col xs={12} className="form-group">
                        <label>Property</label>
                        <select register={register} {...register("property")}>
                          <option disabled selected value="">
                            Select property
                          </option>
                          {propertyList.map((innerData, key) => (
                            <option key={key} value={innerData.id}>
                              {innerData.name}
                            </option>
                          ))}
                        </select>
                      </Col>
                    ) : null}
                    <Col xs={6} className="form-group flex flex-column">
                      <label>Role</label>
                      <select
                        style={{ height: 45, outline: 0 }}
                        register={register}
                        {...register("role")}
                        className={`formControl border`}
                      >
                        <option disabled selected value="">
                          Select User Role
                        </option>

                        {userDetail.role == 3
                          ? showAllRoles?.map((item) => {
                              if (item.id > 3) {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.roleName}
                                  </option>
                                );
                              }
                            })
                          : showAllRoles?.map((item) => {
                              if (item.id > 3 && item.id > userDetail.role) {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.roleName}
                                  </option>
                                );
                              }
                            })}
                      </select>
                      {errors?.role?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"role"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group flex flex-column">
                      <label>Designation</label>
                      <select
                        style={{ height: 45, outline: 0 }}
                        register={register}
                        {...register("designation")}
                        className={`formControl border`}
                      >
                        <option disabled selected value="">
                          Select User Designation
                        </option>
                        {userDetail.designation == "1"
                          ? USER_DEG_TEXT_WITH_ANUM.map((item, ind) => {
                              return (
                                <option value={item.value} key={ind}>
                                  {item.name}
                                </option>
                              );
                            })
                          : USER_DEG_TEXT_WITH_ANUM.map((item, ind) => {
                              if (userDetail.designation < item.value) {
                                return (
                                  <option value={item.value} key={ind}>
                                    {item.name}
                                  </option>
                                );
                              }
                            })}
                      </select>
                      {errors?.designation?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"designation"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    {!editMode && (
                      <>
                        <Col xs={6} className="form-group">
                          <InputField
                            htmlFor="password"
                            text="Create Password"
                            type="password"
                            placeholder="Enter new password"
                            field={"password"}
                            register={register}
                            errors={errors}
                          />
                          {errors?.password?.message && (
                            <div className="error-text">
                              <img alt="" src={AlertIcon} />
                              <span>
                                <ErrorMessage
                                  errors={errors}
                                  name={"password"}
                                />
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col xs={6} className="form-group">
                          <InputField
                            htmlFor="confirmPassword"
                            text="Confirm Password"
                            type="password"
                            placeholder="Enter confirm password"
                            field={"confirmPassword"}
                            register={register}
                            errors={errors}
                          />
                          {errors?.confirmPassword?.message && (
                            <div className="error-text">
                              <img alt="" src={AlertIcon} />
                              <span>
                                <ErrorMessage
                                  errors={errors}
                                  name={"confirmPassword"}
                                />
                              </span>
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                  </>
                )}

                {!editMode && (
                  <MultiSelectInput
                    onChange={updateSelectedProperty}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                )}
                <Col xs={12} className="form-group">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default PropertyUsers;
