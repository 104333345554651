import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authenticationSeletor } from "../store/slices/authenticationSlice";

const ProtectedRoute = ({ children }) => {
  const [searchParams] = useSearchParams();
  const user_id = !!searchParams.get("user_id");
  const fromMobile = searchParams.get("platform") == "mobile_app";
  const navigate = useNavigate();
  const { userDetail } = useSelector(authenticationSeletor);

  const fromMobileAuth = () => {
    if (userDetail.id && userDetail.logintype !== "normalUser") {
      return;
    }
    if (!fromMobile || !user_id || userDetail?.logintype === "normalUser") {
      navigate("/");
      return;
    }
  };
  useEffect(() => {
    fromMobileAuth();
  }, [userDetail, fromMobile, user_id]);
  return <>{children}</>;
};

export default ProtectedRoute;
