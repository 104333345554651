import { createSlice } from "@reduxjs/toolkit";
import services from "../../API/services";
import PropertyService from "API/services/PropertyService";
import { getAssingModules } from "API/services/newServices";
export const initialState = {
  allProperty: [],
  bookingLogs: [],
  showProperties: [],
  assignedModules: [],
};

const propertyData = createSlice({
  name: "propertyData",
  initialState,
  reducers: {
    getallProperty: (state, { payload }) => {
      state.allProperty = payload;
    },
    getShowProperty: (state, { payload }) => {
      state.showProperties = payload;
    },
    getAllLogById: (state, { payload }) => {
      state.bookingLogs = payload;
    },
    setAssignedModules: (state, { payload }) => {
      state.assignedModules = payload;
    },
  },
});

export const {
  getallProperty,
  getAllLogById,
  getShowProperty,
  setAssignedModules,
} = propertyData.actions;

// Action creators are generated for each case reducer function
export const propertyAllData = (state) => state?.AllData;

export default propertyData.reducer;

export const getProperties = (userInfo) => {
  return async (dispatch) => {
    try {
      const authRes = await services.auth.mainPropertyLoginService(userInfo);
      if (authRes.status == 200) {
        // const res = await services.property.getRroomsPropertyService();
        // if (res.status === 200) {
        //   const data = res.data.data?.filter(
        //     (item) =>
        //       item.ownerEmail?.toLowerCase() === userInfo?.email?.toLowerCase()
        //   );
        //   if (data?.length) {
        await dispatch(getallProperty(authRes.data.data.userProperty));
        return {
          userId: authRes.data?.data?.id,
          isOwner: true,
          ownerWithacceptAgreemnet: authRes.data?.data?.agreement,
          role: authRes.data?.data?.role,
          propertiesCount: authRes.data.data.userProperty.length,
        };
        //   } else {
        //     return false;
        //   }
        // }
      } else {
        return "Pass";
      }
    } catch (error) {
      return "Pass";
    }
  };
};
export const getActivity = (id) => {
  return async (dispatch) => {
    try {
      const res = await services.property.getActivityByBooking(id);
      if (res.status == 200) {
        dispatch(getAllLogById(res.data.data));
      }
    } catch (error) {}
  };
};
export const createLogs = (params) => {
  return async (dispatch) => {
    try {
      const res = await services.property.createActivity(params);
      if (res.status == 200) {
        dispatch(getActivity(params?.bookingId));
      }
    } catch (error) {}
  };
};
export const getPropertiesAll = () => {
  return async (dispatch) => {
    try {
      const res = await PropertyService.getRroomsPropertyService();
      if (res.status === 200) {
        const itemsArr = [];
        res?.data.data?.map((item) => {
          let obj = {
            value: item.id,
            label: item.name + ": #" + item.propertyCode,
            ...item,
          };
          itemsArr.push(obj);
        });
        dispatch(getShowProperty(itemsArr));
        return true;
      }
    } catch (error) {}
  };
};
export const getConfigModule = (userDetail) => {
  return async (dispatch) => {
    if (userDetail.logintype === "rroomUser" || userDetail.role < 4) {
      return;
    }
    try {
      const res = await getAssingModules({
        id: userDetail.id,
        property: userDetail.propertyId,
      });
      if (res) {
        dispatch(setAssignedModules(res));
      }
    } catch (error) {}
  };
};
