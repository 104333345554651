import { baseUrl } from "API/client/api";
import moment from "moment";

const GlobalDictinary = {
  createInventoryCategory: () => "inventory/category",
  getInventoryCategory: (id) => `inventory/category-property/${id}`,
  editInventoryCategory: (id) => `inventory/category/${id}`,
  supplier: () => "inventory/supliers",
  supplierByPropertyId: (id) => `inventory/supliers-property/${id}`,
  editSupplier: (id) => `inventory/supliers/${id}`,
  InStock: () => "inventory/in-stock",
  // InStockByPropertyId: (id) => `inventory/in-stock-property/${id}`,
  InStockByPropertyId: (id) => `inventory/inventory-stock-property/${id}`,
  editInStock: (id) => `inventory/in-stock/${id}`,
  outStock: () => "inventory/out-stock",
  getOutStockByPropertyId: (id) => `inventory/out-stock-property/${id}`,
  editOutStock: (id) => `inventory/out-stock/${id}`,

  getInventoryItem: () => "inventory/items",
  getInventoryItemByPropertyId: (id) => `inventory/items-property/${id}`,
  updateCheckInStatus: (id) => `booking/room-booking/${id}`,
  payment: (id) => `booking/payment`,
  getSingleBookingDetail: (id) => `booking/room-booking/${id}`,
  addGuset: () => `booking/room-booking/add-guest-user`,

  getRoomByPropertyId: (id) => `rrooms-property/room/property/${id}`,
  getRoomById: (id) => `rrooms-property/room/${id}`,
  getBookingPropertyId: (id, query) =>
    `booking/room-booking/by-property/${id}${query ?? ""}`,
  getBookingsFilter: () => `booking/download-report`,
  getAllBooking: () => `booking/room-booking/`,

  getCustomerReviewById: (id) => `rrooms-property/ratings?property_id=${id}`,
  createCustomerReview: () => "rrooms-property/ratings",

  getCouponCodeById: (id) => `rrooms-property/coupon?property_id=${id}`,
  createCouponCode: () => "rrooms-property/coupon",
  getCouponByUserId: (id) => `rrooms-property/coupon-userid/${id}?offerMode=2`,
  updateCouponCode: (id) => `rrooms-property/coupon/${id}`,
  deleteCouponCodeById: (id) => `rrooms-property/coupon/${id}`,
  assignRoom: () => `booking/assign-rooms`,
  applyCoupon: (data) =>
    `rrooms-property/coupon/validate/${data.propertyId}/${data.coupon}`,
  coupons: (id) => `rrooms-property/coupon?property_id=${id}`,
  getMenuCardById: (id) => `rrooms-property/menu-card/${id}`,
  createMenuCard: () => "rrooms-property/menu-card",
  updateMenuCard: (id) => `rrooms-property/menu-card/${id}`,
  deleteMenuCardById: (id) => `rrooms-property/menu-card/${id}`,

  // laundry provider
  getLaundryProviderById: (id) =>
    `rrooms-property/laundary-provider?property_id=${id}`,
  createLaundryProvider: () => "rrooms-property/laundary-provider",
  updateLaundryProvider: (id) => `rrooms-property/laundary-provider/${id}`,
  deleteLaundryProviderById: (id) => `rrooms-property/laundary-provider/${id}`,
  getLaundryServiceResById: (id) =>
    `rrooms-property/laundary-request?property_id=${id}`,
  // laundry service
  getLaundryServiceById: (id) =>
    `rrooms-property/laundary-service?property_id=${id}`,
  createLaundryService: () => "rrooms-property/laundary-service",
  updateLaundryService: (id) => `rrooms-property/laundary-service/${id}`,
  deleteLaundryServiceById: (id) => `rrooms-property/laundary-service/${id}`,
  changeRoomStatus: () => `rrooms-property/room-detail-status`,
  createLaundryReq: () => `rrooms-property/laundary-request`,
  changeStatusLaunrdry: (id) =>
    `rrooms-property/laundary-request/change-status/${id}`,
  menuCardCategory: () => `rrooms-property/food-item-category`,
  focRequest: () => "booking/foc-request",
  getServiceTax: () => "rrooms-property/service-tax/1",
  getPaymentDetails: () => "/booking/payment-details",
  // getPaymentDetails: () =>`rrooms-property/food-order-payment?booking_id=${id}`,
  getFoodPaymentDetails: (params) =>
    `rrooms-property/food-order-payment${params}`,
  getAllStateUrl: () => `country/state/`,
  getAllCitiesUrl: () => `country/city/`,
  getAllCitiesByStateUrl: (id) => `country/city-state/${id}`,
  getAllLocalityUrl: () => `country/locality/`,
  localityByCity: (id) => `country/locality-city/${id}`,
  getTaxUrl: () => `rrooms-property/service-tax/1`,
  exportBookingExle: (quaryParam) => `kpi/booking-report${quaryParam}`,
  exportInventoryExcle: (id, fromDate, toDate) =>
    `${baseUrl}kpi/inventory-report?propertyId=${id}&fromDate=${moment(
      fromDate
    ).format("YYYY-MM-DD")}&toDate=${moment(fromDate).format("YYYY-MM-DD")}`,
};

export default GlobalDictinary;
