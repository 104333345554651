import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Services from "../../API/services";
import moment from "moment";
import { Margin, usePDF } from "react-to-pdf";
import { useLocation } from "react-router-dom";
import { showDate, showPrice } from "utils/utils";
import { ResturantGuestType } from "constant/constants";
import {
  getBunquetBookingsPayment,
  getResturantOrderPayment,
} from "API/services/newServices";

const FoodInvoice = () => {
  const { state } = useLocation();
  const [textInputs, setTextInputs] = useState({
    billNumber: "",
    name: "",
    gst: "",
  });
  const { userDetail } = useSelector(authenticationSeletor);
  const [propertyData, setPropertyData] = useState({});
  const [masterData, setMasterData] = useState([]);

  let totalGst = 0;
  let netAmt = 0;
  state.orderItems?.forEach((order) => {
    totalGst = totalGst + order.taxAmount;
    netAmt = netAmt + order.totalAmount;
  });

  const cgstVal = totalGst / 2;
  const sgstVal = totalGst / 2;

  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "resturant-food-invoice.pdf",
    page: {
      margin: Margin.SMALL,
      format: "A6",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
  });

  const getDataToShow = async () => {
    let res = false;
    if (state.banquetData) {
      res = await getBunquetBookingsPayment({
        propertyId: userDetail.propertyId,
        orderId: state.banquetBookingId,
      });
    } else {
      let res1 = await getResturantOrderPayment({
        propertyId: userDetail.propertyId,
        orderId: state.id,
      });
      res = res1.result;
    }
    if (res) {
      setMasterData(res);
    }
  };

  const getPropertyDetail = async () => {
    try {
      const res = await Services.property.getSinglePropertyService(
        userDetail?.propertyId
      );
      if (res.status === 200) {
        setPropertyData(res?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getDataToShow();
    getPropertyDetail();
  }, []);
  useEffect(() => {
    if (state) {
      setTextInputs({
        billNumber: "",
        name:
          (state.banquetData ? state.customerName : state.otherGuestName) ?? "",
        gst: (state.banquetData ? state.gst : state.customerGst) ?? "",
      });
    }
  }, [state]);

  return (
    <Layout
      title="Bill Invoice"
      openHandler={false}
      buttonTitle="Edit Invoice"
      extraButton="Print Invoice"
      extraBtnHandler={toPDF}
    >
      <div className="foodInvoice">
        <div className="flex justify-content-center align-items-center">
          <div
            className="col-4 py-3 bg-white foodPdf shadow rounded-lg"
            ref={targetRef}
          >
            <span className="text-black gstText" style={{ fontSize: "10px" }}>
              GSTIN No.: {propertyData?.gstNumber}
            </span>
            <div className="text-center text-black content mb-2">
              <span className="mb-2">{propertyData?.name}</span>
              <span>{propertyData?.address}</span>
              <span>Contact No.:{propertyData?.ownerMobile}</span>
              <span>
                {state.banquetData
                  ? "Banquet Payment Reciept"
                  : "Resturant Food - Sale Bill"}
              </span>
            </div>
            <div className="borderedDiv">
              <p>
                Bill Number:
                <input
                  type="text"
                  className="noCenter"
                  style={{
                    borderWidth: textInputs.billNumber.length > 0 ? 0 : 1,
                  }}
                  value={textInputs.billNumber}
                  onChange={(e) =>
                    setTextInputs({ ...textInputs, billNumber: e.target.value })
                  }
                />
              </p>
              <p>
                Date:{" "}
                <span>{moment(state?.createdAt).format("YYYY-MM-DD")}</span>
              </p>
            </div>
            {!state.banquetData && (
              <>
                {state.orderType == 2 ? (
                  <div className="borderedDiv">
                    <p>
                      Room Number: <span>{state.roomNumber}</span>
                    </p>
                    <p>
                      Booking ID: <span>{state?.bookingId}</span>
                    </p>
                  </div>
                ) : (
                  <div className="borderedDiv">
                    <p>
                      Guest Type:{" "}
                      <span>{ResturantGuestType[state.orderType]}</span>
                    </p>
                  </div>
                )}
              </>
            )}
            <>
              <div className="borderedDiv">
                <p>
                  Order Id:{" "}
                  <span>
                    {state.banquetData ? state.bookingCode : state.id}
                  </span>
                </p>
              </div>
              {!state.banquetData && (
                <>
                  <div className="borderedDiv ">
                    <p className="w-25">Item Name</p>
                    <p className="w-25 justify-content-end">Rate</p>
                    <p className="w-25 justify-content-center">Qty</p>
                    <p className="w-25 justify-content-center">Tax</p>
                    <p className="w-25 justify-content-end">Amount</p>
                  </div>
                  {state?.orderItems?.map((data, ind) => (
                    <div className="borderedDiv border-dark border-1" key={ind}>
                      <p className="w-25">{data?.name}</p>
                      <p className="w-25 justify-content-end">
                        ₹ {data?.price}
                      </p>
                      <p className="w-25 justify-content-center">
                        {data?.quantity}
                      </p>
                      <p className="w-25 justify-content-center">
                        {data?.taxAmount}
                      </p>
                      <p className="w-25 justify-content-end">
                        {showPrice(data.totalAmount)}
                      </p>
                    </div>
                  ))}
                  <div className="borderedDiv flex-column px-3">
                    <p className="justify-content-between w-100">
                      Payment Mode:{" "}
                      <span>
                        {state.banquetData
                          ? masterData?.[0]?.paymentSource
                          : masterData?.[0]?.paymentMode}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </>

            {!state.banquetData ? (
              <div className="borderedDiv flex-column px-3">
                <p className="justify-content-between w-100">
                  Gross Amount: <span>₹ {(netAmt - totalGst)?.toFixed(2)}</span>
                </p>
                <p className="justify-content-between w-100">
                  SGST:
                  <span>₹ {sgstVal?.toFixed(2)}</span>
                </p>
                <p className="justify-content-between w-100">
                  CGST:
                  <span>₹ {cgstVal?.toFixed(2)}</span>
                </p>
                <p className="justify-content-between w-100">
                  Net Amount: <span>₹ {Number(netAmt)?.toFixed(2) ?? 0}</span>
                </p>
              </div>
            ) : (
              <>
                <div className="borderedDiv flex-column px-3">
                  <p className="justify-content-between w-100">
                    Amount: <span>{showPrice(state.subTotal)}</span>
                  </p>
                  <p className="justify-content-between w-100">
                    Total Tax:
                    <span>{showPrice(state.totalTax)}</span>
                  </p>

                  <p className="justify-content-between w-100">
                    Total Amount: <span>{showPrice(state.finalAmount)}</span>
                  </p>
                </div>
                <div className="borderedDiv ">
                  <p className="w-25">Payment Mode</p>
                  <p className="w-25 justify-content-center">Amout</p>
                  <p className="w-25 justify-content-end">Date</p>
                </div>
                {masterData?.map((data, ind) => (
                  <div className="borderedDiv border-dark border-1" key={ind}>
                    <p className="w-25">{data?.paymentSource}</p>
                    <p className="w-25 justify-content-center">
                      {showPrice(data.paidAmount)}
                    </p>
                    <p className="w-25 justify-content-end">
                      {showDate(data.paymentDate)}
                    </p>
                  </div>
                ))}
              </>
            )}

            <div className="borderedDiv flex-column align-items-start">
              <p>
                Customer Name:{" "}
                <input
                  type="text"
                  className="noCenter mw-100"
                  style={{ borderWidth: textInputs.name.length > 0 ? 0 : 1 }}
                  value={textInputs.name}
                  onChange={(e) =>
                    setTextInputs({ ...textInputs, name: e.target.value })
                  }
                />
              </p>
              <p>
                Customer GST:
                <input
                  type="text"
                  className="noCenter mw-100"
                  style={{ borderWidth: textInputs.gst.length > 0 ? 0 : 1 }}
                  value={textInputs.gst}
                  onChange={(e) =>
                    setTextInputs({ ...textInputs, gst: e.target.value })
                  }
                />
              </p>
            </div>
            <p className="fs-6 text-black text-end">
              Date: <span>{moment().format("YYYY-MM-DD")}</span>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FoodInvoice;
