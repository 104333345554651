import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

function PartnershipPrograms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>PARTNERSHIP PROGRAM</h1>
          <h2>"Hotelier Partnership Program: Growing Together"</h2>
          <p>
            This is a dedicated space for hotel owners and managers to explore
            exciting collaboration opportunities at RRooms. We believe that
            success in the hospitality industry is built on strong partnerships
            and mutually beneficial relationships. Our partnership program is
            designed to offer hoteliers a range of benefits, resources, and
            opportunities to enhance their businesses and reach a broader
            audience.
          </p>
          <h1>Our “Hotelier Partnership Program" aims to:</h1>

          <ol className="bulletNotHide">
            <li>
              <h2>Introducing Our Partnerships:</h2> Discover the various types
              of partnerships available to hoteliers through our platform.
              Whether you're looking to expand your property's visibility,
              increase bookings, or access valuable resources, we have
              partnership options tailored to your needs.
            </li>
            <li>
              <h2>Highlight Success Stories:</h2> Explore real-life success
              stories of hoteliers who have leveraged our partnership program to
              achieve remarkable results. Learn how they unlocked new growth
              opportunities and increased revenue.
            </li>
            <li>
              <h2>Promoting Collaboration Opportunities:</h2> Stay up-to-date
              with our latest collaboration opportunities, including
              co-marketing campaigns, exclusive offers, and joint initiatives
              with other travel and hospitality partners.
            </li>
            <li>
              <h2>Access Marketing Resources:</h2> Gain access to a wealth of
              marketing resources, including promotional materials, marketing
              guides, and best practices to help you effectively promote your
              property.
            </li>
            <li>
              <h2>Maximize Your Exposure:</h2> Learn how our partnership program
              can boost your property's online presence and visibility, reaching
              a broader audience of travelers.
            </li>
            <li>
              <h2>Training and Support:</h2> Benefit from training sessions,
              webinars, and support to make the most of your partnership with
              RRooms. We're here to assist you every step of the way.
            </li>
            <li>
              <h2>Revenue Growth:</h2> Discover strategies to increase your
              property's revenue through partnership-driven initiatives,
              targeted marketing efforts, and access to exclusive promotions.
            </li>
            <li>
              <h2>Community Engagement:</h2> Connect with fellow hoteliers and
              partners within our platform's community. Share insights, exchange
              ideas, and collaborate with like-minded professionals.
            </li>
            <li>
              <h2>Tailored Solutions:</h2> Our partnership program is flexible
              and can be customized to meet your specific goals and business
              objectives. We'll work with you to create a partnership plan that
              aligns with your property's unique needs.
            </li>
            <li>
              <h2> Commitment to Success:</h2> At RRooms, we are dedicated to
              the success of our partners. We view our partnership program as a
              collaborative journey towards mutual growth and prosperity.
            </li>
          </ol>
          <p>
            Join us in the "Partnership Program" to explore a world of
            possibilities, strengthen your business, and unlock new
            opportunities in the dynamic hospitality industry. We believe that
            together, we can achieve more and create memorable experiences for
            travelers worldwide.
          </p>
          <p>
            Stay tuned for updates, partnership announcements, and success
            stories that showcase the power of collaboration. Your success is
            our success, and we look forward to growing together.
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
}

export default PartnershipPrograms;
