import { Button, Row, Table } from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import Layout from "../../layout";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { saleFinance } from "../../API/services/newServices";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendPreviousMonth, showPrice } from "utils/utils";
import { hotelFinanceURL } from "API/client/api";
import urls from "../../API/dictionary/PropertyDictionary";
import ReactGA from "react-ga4";

function Sale() {
  const { userDetail } = useSelector(authenticationSeletor);
  const tableRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [firstTime, setFirstTime] = useState(true);
  const [searchSelectVal, setSearchSelectVal] = useState({
    id: userDetail?.propertyId,
  });

  const getDataHandler = async (endDate, prt) => {
    const res = await saleFinance({
      id: prt,
      date: sendPreviousMonth(endDate, firstTime),
    });
    if (res) {
      setDate(date);
      setFirstTime(false);
      setTableData([res.data]);
    }
  };
  const getDownload = async () => {
    window.open(
      hotelFinanceURL +
        urls.saleFinanceDownload(
          userDetail?.propertyId,
          tableData[0].from_date,
          tableData[0].to_date
        ),
      "_blank",
      "noopener,noreferrer"
    );
  };
  const STORY_HEADERS = [
    {
      prop: "month",
      title: "Month",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "from_date",
      title: "From Date",
      isFilterable: true,
      isSortable: true,
      cell: (row) => moment(row.from_date).format("DD-MM-YYYY"),
    },
    {
      prop: "to_date",
      title: "To Date",
      isFilterable: true,
      isSortable: true,
      cell: (row) => moment(row.to_date).format("DD-MM-YYYY"),
    },
    {
      prop: "total_sale",
      title: "Amount",
      isFilterable: true,
      isSortable: true,
      cell: (row) => showPrice(row.total_sale),
    },
    {
      prop: "definedQty",
      title: "Actions",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <Button variant="success" className="btn-sm" onClick={getDownload}>
          <FontAwesomeIcon icon="fa-solid fa-download" />
        </Button>
      ),
    },
  ];
  const changeHandler = (item) => {
    setSearchSelectVal(item);
    getDataHandler(date, item.id);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "sale_hotel_finance" });
    if (userDetail?.logintype !== "rroomUser") {
      getDataHandler(date, searchSelectVal.id);
    }
  }, [date]);
  return (
    <Layout
      title={"Hotel Sale"}
      filterDate={date}
      withoutDate={true}
      setFilterDate={setDate}
      dateFormate={"MMM-yyyy"}
      dateFilterText="Select Month"
      wantSearchableListFor={
        userDetail?.logintype === "rroomUser" ? "property" : ""
      }
      searchSelectVal={searchSelectVal}
      changeHandler={changeHandler}
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end"></Row>
          <Table className="food-report" ref={tableRef} bordered>
            <TableHeader />
            <TableBody
              labels={{
                noResults:
                  userDetail?.logintype === "rroomUser"
                    ? "Select Property To show Results"
                    : "Result not found",
              }}
            />
          </Table>
        </DatatableWrapper>{" "}
        :
      </Row>
    </Layout>
  );
}

export default Sale;
