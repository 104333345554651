import React, { useEffect, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Imagesdata } from "../../assets/images/imageData";
const shortid = require("shortid");

const ImageUploadBox = ({
  imageHandler,
  selectedImages = [],
  deleteHandler = () => {},
  heading = "Add Images",
  htmlFor,
  activeImageState,
  setProfileImg,
  profileImgId,
}) => {
  const { dummyImg } = Imagesdata || {};
  const [selectedfile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);

  useEffect(() => {
    if (selectedImages.length) {
      SetSelectedFile(selectedImages);
    }
  }, [selectedImages]);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    let images = [];
    if (
      e.target.files.length + selectedfile.length <= 5 &&
      selectedfile.length < 5
    ) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (
          /\.(png|jpg|jpeg|gif|bmp)$/i.test(e.target.files[i].name) == false
        ) {
          toast.error("Please Select Only Images", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
          continue;
        } else if (e.target.files[i]?.size > 600000) {
          toast.error("Image Size Must be Less than 600 KB", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
          continue;
        }

        images.push(e.target.files[i]);
        let reader = new FileReader();
        let file = e.target.files[i];
        imageHandler(file);
        reader.onloadend = () => {
          SetSelectedFile((preValue) => {
            return [
              ...preValue,
              {
                id: shortid.generate(),
                filename: e.target.files[i].name,
                filetype: e.target.files[i].type,
                fileimage: reader.result,
                datetime:
                  e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
                filesize: filesizes(e.target.files[i].size),
              },
            ];
          });
        };
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      toast.error("You can Select max 5 & min 1 Image", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      deleteHandler(id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  const FileUploadSubmit = async (e) => {
    e.preventDefault();

    // form reset on submit
    e.target.reset();
    if (selectedfile.length > 0) {
      for (let index = 0; index < selectedfile.length; index++) {
        SetFiles((preValue) => {
          return [...preValue, selectedfile[index]];
        });
      }
      SetSelectedFile([]);
    } else {
      alert("Please select file");
    }
  };

  const DeleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = Files.filter((data) => data.id !== id);
      SetFiles(result);
    } else {
      // alert('No');
    }
  };

  return (
    <div className="fileupload-view">
      <div className="uploadimg-card card">
        <div className="card-body">
          <div className="kb-data-box">
            <div className="flex justify-content-between w-100">
              <span>{heading}:</span>
              <div className="kb-file-upload">
                <label className="file-upload-box" htmlFor={htmlFor}>
                  <input
                    type="file"
                    id={htmlFor}
                    className="file-upload-input"
                    onChange={InputChange}
                    multiple
                  />
                  <div className="text-white gap-2 flex align-items-center">
                    <FontAwesomeIcon icon="fa-solid fa-upload" />
                    <span>Upload Images</span>
                  </div>
                </label>
              </div>
            </div>
            <div className="kb-attach-box">
              {selectedfile?.map((data) => {
                const { id, fileimage } = data;
                return (
                  <div className="file-atc-box" key={id}>
                    {fileimage ? (
                      <label
                        className={`file-image ${
                          profileImgId == id && activeImageState
                            ? "selectedImg"
                            : activeImageState
                            ? "activeAddImg"
                            : ""
                        }`}
                        htmlFor={id}
                      >
                        <img alt="" src={fileimage} alt="" />
                        <input
                          hidden
                          id={id}
                          type="checkbox"
                          disabled={!activeImageState}
                          onChange={() =>
                            setProfileImg(profileImgId == id ? null : id)
                          }
                        />
                      </label>
                    ) : (
                      <div className="file-image">
                        <img
                          alt=""
                          src={dummyImg}
                          alt=""
                          style={{ height: "35%" }}
                        />
                      </div>
                    )}
                    <div className="file-detail">
                      <div className="file-actions justify-content-center mt-1">
                        <button
                          type="button"
                          className="file-action-btn"
                          onClick={() => DeleteSelectFile(id)}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-trash-can"
                            color="red"
                            size="xl"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {Files.length > 0 ? (
              <div className="kb-attach-box">
                <hr />
                {Files?.map((data, index) => {
                  const {
                    id,
                    filename,
                    filetype,
                    fileimage,
                    datetime,
                    filesize,
                  } = data;
                  return (
                    <div className="file-atc-box" key={index}>
                      {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                        <div className="file-image">
                          {" "}
                          <img alt="" src={fileimage} alt="" />
                        </div>
                      ) : (
                        <div className="file-image">
                          <i className="far fa-file-alt"></i>
                        </div>
                      )}
                      <div className="file-detail">
                        <h6>{filename}</h6>
                        <p>
                          <span>Size : {filesize}</span>
                          <span className="ml-3">
                            Modified Time : {datetime}
                          </span>
                        </p>
                        <div className="file-actions">
                          <button
                            className="file-action-btn"
                            onClick={() => DeleteFile(id)}
                          >
                            Delete
                          </button>
                          <a
                            href={fileimage}
                            className="file-action-btn"
                            download={filename}
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadBox;
