import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Select from "react-select";
import { createOptions } from "../../utils/utils";
import Moment from "react-moment";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import ReactGA from "react-ga4";

const Laundry = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
    getDataHandlerServiceProvide(userDetail?.propertyId);
    getDataHandlerService(userDetail?.propertyId);
  }, [userDetail]);

  const [laundryServices, setLaundryServices] = useState([]);
  const { AlertIcon } = Imagesdata || {};
  const [exportFilter, setExportFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [show, setShow] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [statusPopup, setStatusPopup] = useState(false);
  const [statusRemark, setStatusRemark] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataFilter, setTableDataFilter] = useState([]);
  const [ServiceData, setServiceData] = useState([]);
  const [serviceMaster, setServiceMaster] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [inQty, setInQty] = useState(null);
  const [items, setItems] = useState([
    { serviceName: "", quantity: "", price: "" },
  ]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    try {
      let service = [];
      items.map((dataSet) => {
        if (dataSet.price) {
          let obj = {
            service_id: dataSet.serviceName.value,
            quantity: dataSet.quantity,
            total_service_amount: dataSet.quantity * dataSet.price,
          };
          service.push(obj);
        }
      });
      const realPayload = {
        property_id: userDetail?.propertyId,
        provider_id: payload.selectProvider,
        remark: payload.remark,
        service: service,
        createdBy: userDetail?.id,
      };
      let res = await services.globalService.createLaundryReqService(
        realPayload
      );
      if (res.status === 201 || res.status === 200) {
        reset();
        setShow(false);
        setEditMode(false);
        setItems([{ serviceName: "", quantity: "", price: "" }]);
        setSelectedData({});
        getDataHandler(userDetail?.propertyId);
      }
    } catch (error) {}
  };

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getLaundryServiceResByIdService(
        id
      );
      if (res.status === 200) {
        setTableData(res.data.data);
        const request = res?.data?.data?.map((item) => item?.LaundaryRequests);
        const req2 = request?.map((item) => {
          let myData = 0;
          item?.map(
            (data) => (myData += data?.quantity * data?.LaundaryService?.price)
          );
          return { amt: myData, id: item[0]?.orderId };
        });

        setTotalPrice(req2);
      }
    } catch (error) {}
  };

  const getDataHandlerServiceProvide = async (id) => {
    try {
      const res = await services.globalService.getLaundryProviderByIdService(
        id
      );
      if (res.status === 200) {
        setLaundryServices(res.data.data);
      }
    } catch (error) {}
  };

  const getDataHandlerService = async (id) => {
    try {
      const res = await services.globalService.getLaundryServicesByIdService(
        id
      );
      if (res.status === 200) {
        setServiceMaster(res.data.data);
        const dataSet = createOptions(res.data.data, "name", "id");
        setServiceData(dataSet);
      }
    } catch (error) {}
  };

  const closeHandler = (index) => {
    let tempData = [...items];
    tempData.splice(index, 1);
    setItems(tempData);
  };

  const changeHandler = (e, index) => {
    let tempData = [...items];
    tempData[index][e.target.name] = e.target.value;
    tempData[index].createdBy = userDetail?.id;
    setItems(tempData);
  };

  const selectChangeHandler = (data, index) => {
    let tempData = [...items];
    tempData[index].serviceName = data;
    const getSelectEdData = serviceMaster.find((dataSet) => {
      if (dataSet.id == data.value) {
        return dataSet;
      }
    });
    tempData[index].price = getSelectEdData.price;
    setItems(tempData);
  };

  const statusChangeHandler = async () => {
    let payload = {
      id: selectedData?.id,
      body: {
        remark: statusRemark,
        in_qty: inQty,
        updated_by: userDetail?.id,
      },
    };

    try {
      const res = await services.globalService.changeStatusLaunrdryService(
        payload
      );
      if (res.status === 200) {
        getDataHandler(userDetail?.propertyId);
        setStatusPopup(false);
        setSelectedData({});
        setStatusRemark("");
        setInQty(null);
      }
    } catch (error) {}
  };
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "laundry",
    sheet: "laundry",
  });
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "laundry" });
    setTableDataFilter(tableData);
  }, [tableData]);
  const [filterDate, setFilterDate] = useState(new Date());
  const searchfilter = (text) => {
    const resultsData = tableData.filter((order) => {
      if (text === "") return tableData;
      return (
        order?.LaundaryRequests[0]?.LaundaryService?.name +
        order?.LaundaryRequests[0]?.LaundaryProvider?.name +
        order?.LaundaryRequests[0]?.remark +
        order?.LaundaryRequests[0]?.status +
        order?.createdBy +
        order?.totalAmount +
        order?.id
      )
        ?.toString()
        .toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setTableDataFilter(resultsData);
  };
  const dateFilter = () => {
    const resultsData = tableData.filter((order) => {
      return (
        new Date(order?.createdAt).toJSON().slice(0, 10) >=
        new Date(filterDate).toJSON().slice(0, 10)
      );
    });
    setTableDataFilter(resultsData);
  };
  useEffect(() => {
    dateFilter();
  }, [filterDate]);

  const exportHandler = async () => {
    window.open(
      `https://api.rrooms.in/api/kpi/loundary-report?fromDate=${moment(
        exportFilter.startDate
      ).format("YYYY-MM-DD")}&toDate=${moment(exportFilter.endDate).format(
        "YYYY-MM-DD"
      )}&propertyId=${userDetail?.propertyId}`,
      "_blank"
    );
  };
  return (
    <Layout
      title="Laundry"
      openHandler={() => setShow(true)}
      extraBtnHandler={exportHandler}
      extraButton="Export Table"
      searchfilter={searchfilter}
      // setFilterDate={setFilterDate}
      // filterDate={filterDate}
      setToDate={(date) => setExportFilter({ ...exportFilter, endDate: date })}
      dateFilter={exportFilter}
      searchType={"date"}
      fromDate={exportFilter.startDate}
      toDate={exportFilter.endDate}
    >
      <Table striped bordered hover className="inventoryTable" ref={tableRef}>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Service Provider Name</th>
            <th>Order Id</th>
            <th>Date</th>
            <th>Remark</th>
            <th>Status</th>
            <th>Total Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableDataFilter?.map((data, key) => {
            return (
              <tr
                key={key}
                className={`
                    ${
                      data.LaundaryRequests?.filter((i) => i.quantity > i.inQty)
                        ?.length > 0
                        ? "noQtyAlert"
                        : ""
                    }
                `}
              >
                <td>{key + 1}</td>
                <td>{data?.LaundaryRequests[0]?.LaundaryProvider?.name}</td>
                <td>{data?.LaundaryRequests[0]?.orderId}</td>
                <td>
                  <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                </td>
                <td>{data?.LaundaryRequests[0]?.remark ?? "N/A"}</td>
                <td
                  className={
                    data?.LaundaryRequests[0]?.status == "out"
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  {data?.LaundaryRequests[0]?.status?.toUpperCase() ?? "N/A"}
                </td>
                <td>
                  ₹ {totalPrice?.find((i) => i.id === data?.id).amt?.toFixed(2)}
                </td>
                <td>
                  <Button
                    variant="primary"
                    className=" btn-sm"
                    onClick={() => {
                      setViewPopup(true);
                      setSelectedData(data);
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal
        backdrop="static"
        show={show}
        onHide={() => {
          setShow(false);
          reset();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <label>Select Service Provider</label>
                  <select register={register} {...register("selectProvider")}>
                    <option selected disabled>
                      Select
                    </option>
                    {laundryServices.map((data, key) => (
                      <option key={key} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  {errors?.selectProvider?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"selectProvider"} />
                      </span>
                    </div>
                  )}
                </Col>

                {items.map((data, i) => (
                  <Col xs={12} className="addItems">
                    <Row>
                      {items.length > 1 ? (
                        <Col xs={12}>
                          <div
                            className="cutIcon"
                            onClick={() => closeHandler(i)}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={12} className="form-group">
                        <label>Service name</label>
                        <Select
                          options={ServiceData}
                          placeholder={"Select"}
                          value={data.serviceName}
                          onChange={(selectData) =>
                            selectChangeHandler(selectData, i)
                          }
                          isSearchable={true}
                        />
                      </Col>
                      <Col xs={6} className="form-group">
                        <label>Quantity</label>
                        <input
                          {...register("quantity")}
                          type="number"
                          className="form-control"
                          placeholder="Enter quantity"
                          name="quantity"
                          errors={errors}
                          value={data.quantity}
                          onChange={(e) => changeHandler(e, i)}
                        />
                        {errors?.quantity?.message && (
                          <div className="error-text">
                            <img alt="" src={AlertIcon} />
                            <span>
                              <ErrorMessage errors={errors} name={"quantity"} />
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col xs={6} className="form-group">
                        <label>Price</label>
                        <input
                          {...register("price")}
                          type="number"
                          className="form-control"
                          placeholder="Enter price"
                          name="price"
                          value={data.price}
                          disabled
                        />
                        {errors?.price?.message && (
                          <div className="error-text">
                            <img alt="" src={AlertIcon} />
                            <span>
                              <ErrorMessage errors={errors} name={"price"} />
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ))}

                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2 align-center"
                >
                  <Button
                    variant="primary"
                    type="button"
                    className="buttonCenter"
                    onClick={() =>
                      setItems([
                        ...items,
                        { serviceName: "", quantity: "", price: "" },
                      ])
                    }
                  >
                    + Add Item
                  </Button>
                </Col>
                <Col xs={12} className="form-group">
                  <label>Remark</label>
                  <textarea
                    register={register}
                    {...register("remark")}
                  ></textarea>
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button className="greyBtn" variant="outline-primary">
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={viewPopup}
        onHide={() => {
          setViewPopup(false);
          setSelectedData({});
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form">
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <Table striped bordered hover className="inventoryTable">
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Out-Qty</th>
                        <th>In-Qty</th>
                        <th>Price</th>
                        <th>Total Amount</th>
                        <th>Remark</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedData?.LaundaryRequests?.map((data, key) => (
                        <tr key={key}>
                          <td>{data?.LaundaryService?.name}</td>
                          <td>{data?.quantity}</td>
                          <td>{data?.inQty ?? 0}</td>
                          <td>₹ {data?.LaundaryService?.price}</td>
                          <td>
                            ₹ {data?.LaundaryService?.price * data?.quantity}
                          </td>
                          <td>{data?.remark ?? "N/A"}</td>
                          <td>
                            {data?.status !== "in" && (
                              <Button
                                variant="primary"
                                className="mr-2 btn-sm"
                                onClick={() => {
                                  setStatusPopup(true);
                                  setSelectedData(data);
                                  setViewPopup(false);
                                }}
                              >
                                Change Status
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="text-black">
                  Total Quantity:{" "}
                  {selectedData?.LaundaryRequests?.reduce(
                    (acc, item) => {
                      return Number(acc) + Number(item.quantity);
                    },
                    [0]
                  )}
                </Col>
                <Col xs={6} className="text-black text-end">
                  Total Amount: ₹
                  {totalPrice
                    ?.find((i) => i.id === selectedData?.id)
                    ?.amt?.toFixed(2)}
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={statusPopup}
        onHide={() => {
          setStatusPopup(false);
          setSelectedData({});
          setStatusRemark("");
          setInQty(null);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form onboardForm">
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <label>Remark</label>
                  <textarea
                    placeholder="Remarks"
                    value={statusRemark}
                    onChange={(e) => setStatusRemark(e.target.value)}
                  ></textarea>
                </Col>
                <Col xs={12} className="form-group">
                  <label>In-Quantity</label>
                  <input
                    placeholder="Enter In Quatity"
                    type="number"
                    onChange={(e) => setInQty(e.target.value)}
                    value={inQty}
                  />
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button
                    className="greyBtn"
                    variant="outline-primary"
                    onClick={() => {
                      setStatusPopup(false);
                      setSelectedData({});
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={statusChangeHandler}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Laundry;
