import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validation = Yup.object().shape({
  laundryService: Yup.string()
    .required("Please Enter service Name*")
    .matches(/^[a-zA-Z\s]+$/, "Enter Correct Name*"),
  laundryServicePrice: Yup.string()
    .required("Please Enter service Price*")
    .matches(/^((?!0)[0-9]+)$/, "Enter Correct Price*"),
  provider: Yup.string().required("Please Select Provider*"),
});

export default { resolver: yupResolver(validation) };
