import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .trim()
    .max(100)
    .email(addUserValidation.valid_email)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addUserValidation.valid_email
    )
    .required(addUserValidation.email_required),

  firstName: Yup.string()
    .trim()
    .required(addUserValidation.addUser.first_name_required)
    .matches(/^[A-Za-z ]+$/, addUserValidation.valid_name)
    .max(200, addUserValidation.max_firstname_length),

  lastName: Yup.string()
    .trim()
    .required(addUserValidation.addUser.last_name_required)
    .matches(/^[A-Za-z ]+$/, addUserValidation.valid_name)
    .max(200, addUserValidation.max_lastname_length),
  phoneNumber: Yup.string()
    .required(addUserValidation.phone_invalid)
    .matches(/^\+?[0-9]{10}$/, "Enter Correct Mobile Number*"),
  designation: Yup.string().required(addUserValidation.designation),
  // password: Yup.string().required(addUserValidation.password).min(8),
  // confirmPassword: Yup.string()
  //   .label("confirm password")
  //   .required()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match"),
  role: Yup.string().required("Please Select User Role"),
});

export default { resolver: yupResolver(validation) };
