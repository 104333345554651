import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  category: Yup.string().required(addUserValidation.category),
  // roomNumbers: Yup.string().required(addUserValidation.roomNumbers),
  roomDescription: Yup.string()
    .required(addUserValidation.roomDescriptionLength)
    .max(1500, addUserValidation.roomDescriptionLength),
  regularPrice: Yup.string().required(addUserValidation.require),
  amenities: Yup.array().min(1).of(Yup.string().required()).required(),
});

export default { resolver: yupResolver(validation) };
