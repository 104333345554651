import { useEffect, useState } from "react";
import {
  getFoodDashboardService,
  getPropertyDashboardService,
  getRoomDashboardService,
  homeBookingService,
} from "../API/services/newServices";
import moment from "moment";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../store/slices/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";
import RenderBox from "../components/RenderBox";

const ORDER_STATUS = {
  KOT: 0,
  PROCESS: 1,
  DELIVERED: 2,
  COMPLETE: 3,
  CANCELED: 4,
  ALL: 5,
  NCKOT: 6,
};

const useHomeController = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([]);
  const bookingStatic = [
    "Upcoming",
    "In-House",
    "Checked-Out",
    "Cancelled",
    "No-Show",
    "For-Checkout",
  ];
  const [food, setFood] = useState([]);
  const [foodRevenue, setFoodRevenue] = useState([]);
  const [roomStatus, setRoomStatus] = useState([]);
  const [foodStatus, setFoodStatus] = useState([]);
  const [foodChart, setFoodChart] = useState([]);
  const [bookingChart, setBookingChart] = useState([]);
  const [bookingRevenue, setBookingRevenue] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const foodSource = [
    { id: 0, title: "Room KOT" },
    { id: 1, title: "NC KOT" },
    { id: 2, title: "Restaurant" },
    { id: 3, title: "Banquet" },
    { id: 4, title: "Other" },
  ];

  const [chartOption, setChartOption] = useState("All");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateFood, setStartDateFood] = useState(new Date());
  const [endDateFood, setEndDateFood] = useState(new Date());
  const [startDatePrt, setStartDatePrt] = useState(null);
  const [endDatePrt, setEndDatePrt] = useState(null);

  const [bookingLoading, setBookingLoading] = useState(false);
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [roomLoading, setRoomLoading] = useState(false);
  const [foodLoading, setFoodLoading] = useState(false);
  const [bookingOption, setBookingOption] = useState({
    title: "Total",
    count: "totalBooking",
    amount: "totalCollectedAmount",
  });
  const [foodOption, setFoodOption] = useState(0);

  const today = new Date();

  const [activeBtn, setActiveBtn] = useState({
    properties: "",
    booking: "Today",
    rrooms: "Today",
    application: "Today",
    foodTab: "Today",
  });
  const options = {
    title: "Bookings",
    pieHole: 0.4,
    is3D: false,
    colors: ["#0cab25", "orange", "red"],
  };
  const optionsFood = {
    title: "Food Orders",
    pieHole: 0.4,
    is3D: false,
  };

  const optionsBookRev = {
    title: "Booking Revenue",
    pieHole: 0.4,
    is3D: false,
    colors: ["#0cab25", "orange", "red"],
  };
  const optionsFoodRev = {
    title: "Food Orders Revenue",
    pieHole: 0.4,
    is3D: false,
  };

  const tabBookingFilter = [
    { title: "Today", start: today, end: today },
    {
      title: "MTD",
      start: new Date(new Date().setDate(today.getDate() - 30)),
      end: today,
    },
    {
      title: "YTD",
      start: new Date(new Date().setFullYear(today.getFullYear() - 1)),
      end: today,
    },
    {
      title: "LMTD",
      titlePrt: "Reset",
      end: new Date(new Date().setDate(today.getDate() - 30)),
      start: new Date(new Date().setDate(today.getDate() - 60)),
    },
  ];
  const tabFoodFilter = [
    { title: "Today", start: today, end: today },
    {
      title: "MTD",
      start: new Date(new Date().setDate(today.getDate() - 30)),
      end: today,
    },
    // {
    //   title: "YTD",
    //   start: new Date(new Date().setFullYear(today.getFullYear() - 1)),
    //   end: today,
    // },
    // {
    //   title: "LMTD",
    //   titlePrt: "Reset",
    //   end: new Date(new Date().setDate(today.getDate() - 30)),
    //   start: new Date(new Date().setDate(today.getDate() - 60)),
    // },
  ];
  const bookingSource = [
    {
      title: "All",
      count: "totalBooking",
      amount: "totalCollectedAmount",
    },
    {
      title: "RRooms",
      count: "rroomsTotalBooking",
      amount: "rroomsTotalCollectedAmount",
    },
    {
      title: "Walk In",
      count: "walkInTotalBooking",
      amount: "walkInTotalCollectedAmount",
    },
    {
      title: "OTA",
      count: "otaTotalBooking",
      amount: "otaTotalCollectedAmount",
    },
  ];

  const changeSources = (booking, ifAll = chartOption) => {
    if (ifAll == "All") {
      const walkIn = booking.reduce(
        (acc, next) => acc + (next.walkInTotalBooking ?? 0),
        0
      );
      const rrooms = booking.reduce(
        (acc, next) => acc + (next.rroomsTotalBooking ?? 0),
        0
      );
      const ota = booking.reduce(
        (acc, next) => acc + (next.otaTotalBooking ?? 0),
        0
      );
      const walkInAmt = booking.reduce(
        (acc, next) => acc + (next.walkInTotalCollectedAmount ?? 0),
        0
      );
      const rroomsAmt = booking.reduce(
        (acc, next) => acc + (next.rroomsTotalCollectedAmount ?? 0),
        0
      );
      const otaAmt = booking.reduce(
        (acc, next) => acc + (next.otaTotalCollectedAmount ?? 0),
        0
      );
      setBookingChart([
        ["Task", "Hours per Day"],
        [`Walk-In (${Math.round(walkIn)})`, walkIn],
        [`RRooms (${Math.round(rrooms)})`, rrooms],
        [`OTA (${Math.round(ota)})`, ota],
      ]);
      setBookingRevenue([
        ["Task", "Hours per Day"],
        [`Walk-In (${Math.round(walkInAmt)})`, walkInAmt],
        [`RRooms (${Math.round(rroomsAmt)})`, rroomsAmt],
        [`OTA (${Math.round(otaAmt)})`, otaAmt],
      ]);
    } else {
      setBookingChart([
        ["Task", "Hours per Day"],
        [
          `Walk-In (${Math.round(booking.walkInTotalBooking)})`,
          booking.walkInTotalBooking,
        ],
        [
          `RRooms (${Math.round(booking.rroomsTotalBooking)})`,
          booking.rroomsTotalBooking,
        ],
        [
          `OTA (${Math.round(booking.otaTotalBooking)})`,
          booking.otaTotalBooking,
        ],
      ]);
      setBookingRevenue([
        ["Task", "Hours per Day"],
        [
          `Walk-In (${Math.round(booking.walkInTotalCollectedAmount)})`,
          booking.walkInTotalCollectedAmount,
        ],
        [
          `RRooms (${Math.round(booking.rroomsTotalCollectedAmount)})`,
          booking.rroomsTotalCollectedAmount,
        ],
        [
          `OTA (${Math.round(booking.otaTotalCollectedAmount)})`,
          booking.otaTotalCollectedAmount,
        ],
      ]);
    }
  };

  const changeSourceByOption = (item) => {
    setChartOption(item);
    if (item != "All") {
      const book = booking.find((i) => i.bookingStatus == item);
      changeSources(book, item);
    } else {
      changeSources(booking, item);
    }
  };

  const getBookingCount = async (params) => {
    setBookingLoading(true);
    const res = await homeBookingService(params);
    if (res) {
      const booking = res.data.data;
      setBooking(booking);
      changeSources(booking);
    }
    setTimeout(() => {
      setBookingLoading(false);
    }, 200);
  };
  const getPropertyCount = async (params) => {
    setPropertyLoading(true);
    const res = await getPropertyDashboardService(params);
    if (res) {
      const prt = res.data.data;
      const active =
        prt?.activeInactiveProprty.find((i) => i.status == 1)?.totalProperty ??
        0;
      const inActive =
        prt?.activeInactiveProprty.find((i) => i.status == 0)?.totalProperty ??
        0;
      const request =
        prt?.approvedRejected.find((i) => i.approved == 0)?.totalProperty ?? 0;

      const total = request + active + inActive;
      setPropertyData({ request, active, inActive, total });
    }
    setTimeout(() => {
      setPropertyLoading(false);
    }, 200);
  };
  const getRoomCount = async (params) => {
    setRoomLoading(true);
    const res = await getRoomDashboardService(params);
    if (res) {
      const rooms = res.data?.data;
      setRoomStatus([
        {
          title: "Available",
          count: rooms.find((i) => i.status == 0)?.totalRooms ?? 0,
        },
        {
          title: "Occupied",
          count: rooms.find((i) => i.status == 1)?.totalRooms ?? 0,
        },
        {
          title: "Dirty",
          count: rooms.find((i) => i.status == 2)?.totalRooms ?? 0,
        },
        {
          title: "Blocked",
          count: rooms.find((i) => i.status == 3)?.totalRooms ?? 0,
        },
        {
          title: "Total",
          count: rooms.reduce((acc, next) => acc + next.totalRooms, 0),
        },
      ]);
    }
    setTimeout(() => {
      setRoomLoading(false);
    }, 200);
  };

  const getFoodOrders = async (param) => {
    setFoodLoading(true);
    const body = {
      id: userDetail.propertyId ?? 0,
      ...param,
    };

    const res = await getFoodDashboardService(body);
    if (res) {
      const {
        cancelled,
        cancelledAmount,
        completed,
        completedAmount,
        delivered,
        deliveredAmount,
        inKot,
        inKotAmount,
        inProcess,
        inProcessAmount,
        totalOrder,
        totalOrderAmount,
      } = res.data.data;
      setFood(food);
      setFoodStatus([
        {
          title: "KOT",
          count: inKot,
          amount: inKotAmount,
        },
        {
          title: "In-process",
          count: inProcess,
          amount: inProcessAmount,
        },
        {
          title: "Delivered",
          count: delivered,
          amount: deliveredAmount,
        },
        {
          title: "Completed",
          count: completed,
          amount: completedAmount,
        },
        {
          title: "Cancelled",
          count: cancelled,
          amount: cancelledAmount,
        },
        {
          title: "Total",
          count: totalOrder,
          amount: totalOrderAmount,
        },
      ]);
      setFoodChart([
        ["Task", "Hours per Day"],
        // ["Swiggy", foodType.swiggy],
        // ["Zomato", foodType.zomato],
        // ["Restaurant", foodType.resturant],
        ["KOT", inKot],
        ["In-process", inProcess],
        ["Delivered ", delivered],
        ["Completed ", completed],
        ["Cancelled", cancelled],
      ]);
      setFoodRevenue([
        ["Task", "Hours per Day"],
        ["KOT", inKotAmount],
        ["In-process", inProcessAmount],
        ["Delivered ", deliveredAmount],
        ["Completed ", completedAmount],
        ["Cancelled", cancelledAmount],
      ]);
    }
    setTimeout(() => {
      setFoodLoading(false);
    }, 200);
  };

  useEffect(() => {
    getBookingCount({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      propertyId: userDetail.propertyId ?? 0,
    });
  }, [startDate, endDate]);
  useEffect(() => {
    if (userDetail?.logintype === "rroomUser") {
      getPropertyCount({
        startDate: startDatePrt,
        endDate: endDatePrt,
      });
    }
  }, [startDatePrt, endDatePrt]);
  useEffect(() => {
    getRoomCount({
      propertyId: userDetail.propertyId ?? 0,
      //   status: "",
      //   categoryId: "",
    });
  }, []);

  useEffect(() => {
    if (userDetail.logintype !== "rroomUser")
      getFoodOrders({
        nc: foodOption,
        from: startDateFood,
        end: endDateFood,
      });
  }, [foodOption, activeBtn.foodTab]);

  const setBookingDates = (from, end, btn) => {
    setActiveBtn({ ...activeBtn, booking: btn });
    setStartDate(from);
    setEndDate(end);
  };
  const setFoodDates = (from, end, btn) => {
    setActiveBtn({ ...activeBtn, foodTab: btn });
    setStartDateFood(from);
    setEndDateFood(end);
  };
  const setPropertyDates = (from, end, btn) => {
    setActiveBtn({ ...activeBtn, properties: btn });
    setStartDatePrt(from);
    setEndDatePrt(end);
  };
  const setBookingSource = (from, end, btn) => {
    setActiveBtn({ ...activeBtn, booking: btn });
    setStartDate(from);
    setEndDate(end);
  };

  const navigateBooking = () => {
    navigate(`/dashboard/all-bookings?query=inHouse`);
  };

  return {
    getBookingCount,
    booking,
    setActiveBtn,
    activeBtn,
    tabBookingFilter,
    setBookingDates,
    setBookingSource,
    bookingSource,
    bookingLoading,
    setBookingOption,
    bookingOption,
    userDetail,
    roomLoading,
    roomStatus,
    food,
    foodStatus,
    foodLoading,
    ORDER_STATUS,
    foodChart,
    foodRevenue,
    bookingChart,
    bookingRevenue,
    changeSources,
    changeSourceByOption,
    chartOption,
    options,
    optionsFood,
    optionsBookRev,
    optionsFoodRev,
    propertyLoading,
    propertyData,
    setPropertyDates,
    navigateBooking,
    Chart,
    RenderBox,
    foodSource,
    setFoodOption,
    foodOption,
    bookingStatic,
    tabFoodFilter,
    setFoodDates,
  };
};

export default useHomeController;
