import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validation = Yup.object().shape({
  taxPercantage: Yup.string()
    .required("Please Enter Percentage*")
    .matches(
      /^[1-9]\d?$|^100$/,
      "Percentage Should be a Number & between 1 - 100*"
    ),
});
const obj = { resolver: yupResolver(validation) };
export default obj;
