import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import {
  addBunquetEnquiryService,
  createLogsOnbanquetEnquiry,
  getBunquetEnquiryService,
  getFunctions,
  getLogsOnbanquetEnquiry,
  getVenue,
} from "API/services/newServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Margin, usePDF } from "react-to-pdf";
import { createOptions } from "utils/utils";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useParams } from "react-router-dom";

const validation = Yup.object().shape({
  guestCount: Yup.string().required("Enter number of guest*"),
  eventDate: Yup.string().required("Select event date*"),
  name: Yup.string().required("Enter customer name*"),
  mobileNumber: Yup.string()
    .required("Enter customer phone number*")
    .length(10, "Enter Correct Mobile Number*"),
  // email: Yup.string()
  //   .required("Enter customer email*")
  //   .matches(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //     "Enter Correct Email ID*"
  //   ),
  // followUpDate: Yup.string().required("Enter follow up date*"),
  venue: Yup.object().required("Select venue*"),
  function: Yup.object().required("Select function*"),
});
const statusValidation = Yup.object().shape({
  status: Yup.object().required("Select Status*"),
  // followUpDate: Yup.string().required("Enter follow up date*"),
});

const STATUS = [
  { value: 1, label: "Won", color: "green" },
  { value: 2, label: "Loss", color: "red" },
  { value: 3, label: "Follow Up", color: "#f78000" },
];

const useBanquetEnquiry = () => {
  const { id } = useParams();
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "bookingDetails",
    sheet: "bookingDetails",
  });

  const { AlertIcon } = Imagesdata;
  const { userDetail } = useSelector(authenticationSeletor);

  const [validationFinal, setValidationFinal] = useState(validation);
  const [venue, setVenue] = useState([]);
  const [functionNames, setFunctionNames] = useState([]);
  const [reportState, setReportState] = useState(false);
  const [findDataByMobile, setFindDataByMobile] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    ...{ resolver: yupResolver(validationFinal) },
  });
  const getVenues = async () => {
    const res = await getVenue(userDetail.propertyId);
    if (res) {
      setVenue(createOptions(res, "venueName", "venueId"));
    }
  };
  const getFunction = async () => {
    const res = await getFunctions(userDetail.propertyId);
    if (res) {
      setFunctionNames(createOptions(res, "functionName", "id"));
    }
  };

  const watchData = watch();

  // states
  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [enquiryLogs, setEnquiryLogs] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changeMode, setChangeMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [logsModal, setLogsModal] = useState(false);

  const { toPDF, targetRef } = usePDF({
    filename: "user-activity.pdf",
    page: { margin: Margin.MEDIUM, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 10,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  const openAddModalHanlder = () => {
    setOpenAddModal(true);
  };
  const hideAddModalHanlder = () => {
    setValidationFinal(validation);
    setOpenAddModal(false);
    reset();
    setChangeMode(false);
    setEditMode(false);
    setFindDataByMobile([]);
  };

  const getDataHanlder = async () => {
    const res = await getBunquetEnquiryService(userDetail.propertyId);
    if (res) {
      setTableData(res);
      setAllData(res);
    }
  };
  const creatEnquiryLog = async (
    banquetEnquiryId,
    followUpDate,
    status,
    remark
  ) => {
    const param = {
      propertyId: userDetail.propertyId,
      banquetEnquiryId,
      status,
      followUpDate,
      remark,
      createdBy: userDetail.name,
    };
    createLogsOnbanquetEnquiry(param);
  };
  const onSubmit = async (payload) => {
    let action = editMode
      ? "Enquiry Created -"
      : changeMode
      ? "Enquiry status change -"
      : "Enquiry Created -";

    const params = changeMode
      ? {
          status: payload.status.value,
          remark: payload.query,
          followUpDate: payload.status.value != 3 ? "" : payload.followUpDate,
          query: payload.query,
        }
      : {
          propertyId: userDetail.propertyId,
          enquiryType: payload.venue.label,
          functionType: payload.function.label,
          numberOfGuest: payload.guestCount,
          eventDate: payload.eventDate,
          name: payload.name,
          mobile: payload.mobileNumber,
          email: payload.email,
          query: payload.query,
          status: editMode ? selectedData.status : 3,
          followUpDate: payload.followUpDate,
          createdBy: userDetail.name,
        };

    const res = await addBunquetEnquiryService({
      body: params,
      editMode: editMode || changeMode,
      id: selectedData.banquetEnquiryId,
    });
    if (res) {
      creatEnquiryLog(
        changeMode ? selectedData.banquetEnquiryId : res.data.banquetEnquiryId,
        params.followUpDate,
        params.status,
        action + params.query
      );
      hideAddModalHanlder();
      getDataHanlder();
    }
  };

  const editHandler = (item, confirmMode) => {
    if (confirmMode) {
      setChangeMode(true);
      setValidationFinal(statusValidation);
    } else {
      setEditMode(true);
      setValidationFinal(validation);
      setValue("email", item.email);
      setValue("eventDate", moment(item.eventDate).format("YYYY-MM-DD"));
      setValue("followUpDate", moment(item.followUpDate).format("YYYY-MM-DD"));
      setValue("function", { label: "Wedding", value: "Wedding" });
      setValue("venue", { label: "Reception", value: "Reception" });
      setValue("guestCount", item.numberOfGuest);
      setValue("name", item.name);
      setValue("query", item.query);
      setValue("mobileNumber", item.mobile);
    }
    setSelectedData(item);
    openAddModalHanlder();
  };

  const searchfilter = (id) => {
    if (id) {
      const filterData = allData.filter((i) => i.status == id);
      setTableData(filterData);
    } else {
      setTableData(allData);
    }
  };

  const showLogs = async (id) => {
    const res = await getLogsOnbanquetEnquiry(id);
    if (res) {
      setEnquiryLogs(res);
      setLogsModal(true);
    }
  };

  const STORY_HEADERS = [
    {
      prop: "enquiryCode",
      title: "Enquiry Code",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "name",
      title: "Customer Name",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "mobile",
      title: "Customer Mobile",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "email",
      title: "Customer Email",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "enquiryType",
      title: "Enquiry Type",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "functionType",
      title: "Function Type",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "eventDate",
      title: "Event Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.createdAt).format("DD MMM, YYYY"),
    },
    {
      prop: "numberOfGuest",
      title: "Guest",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "query",
      title: "Query",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "status",
      title: "status",
      isFilterable: true,
      isSortable: true,
      cell: (row) => {
        const tempStatus = STATUS.find((i) => i.value == row.status);
        return (
          <span style={{ color: tempStatus?.color }}>{tempStatus?.label}</span>
        );
      },
    },
    {
      prop: "followUpDate",
      title: "Follow Up Date",
      isFilterable: true,
      isSortable: true,
      cell: (row) => moment(row.followUpDate).format("DD MMM, YYYY"),
    },
    {
      prop: "remark",
      title: "Remark",
      isFilterable: true,
      isSortable: true,
      cell: (row) => row.remark ?? "N/A",
    },
    {
      prop: "createdBy",
      title: "Created By",
      isFilterable: true,
      isSortable: true,
    },

    {
      prop: "createdAt",
      title: "Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.createdAt).format("DD MMM, YYYY"),
    },
    ...(!reportState
      ? [
          {
            prop: "Action",
            title: "Action",
            isSortable: true,
            isFilterable: true,
            cellProps: { style: { minWidth: 190 } },
            cell: (row) => (
              <>
                <Button
                  onClick={() => showLogs(row.banquetEnquiryId)}
                  className="btn btn-sm mr-1"
                >
                  <FontAwesomeIcon icon="fa-solid fa-eye" />
                </Button>
                <Button
                  onClick={() => editHandler(row, true)}
                  className="btn btn-sm mr-1"
                >
                  Change Status
                </Button>
                <Button
                  onClick={() => editHandler(row, false)}
                  className="btn btn-sm"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];

  const hideLogsModal = () => {
    setLogsModal(false);
  };

  const showFindEnquiry = () => {
    setTableData(findDataByMobile);
    setOpenAddModal(false);
  };
  const clearFindDataToShowAllTableData = () => {
    setTableData(allData);
    setFindDataByMobile([]);
    setOpenAddModal(true);
  };

  useEffect(() => {
    if (id) {
      setReportState(true);
    } else {
      setReportState(false);
    }
  }, [id]);
  useEffect(() => {
    if (watchData.mobileNumber?.length == 10) {
      setFindDataByMobile(
        allData.filter((i) => i.mobile == watchData.mobileNumber) ?? []
      );
    }
  }, [watchData.mobileNumber]);
  useEffect(() => {
    getVenues();
    getFunction();
    getDataHanlder();
  }, []);
  return {
    STORY_HEADERS,
    tableData,
    tableRef,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    STATUS,
    changeMode,
    control,
    logsModal,
    hideLogsModal,
    enquiryLogs,
    toPDF,
    targetRef,
    venue,
    functionNames,
    watchData,
    showLogs,
    editHandler,
    tableRef,
    onDownload,
    reportState,
    searchfilter,
    findDataByMobile,
    showFindEnquiry,
    clearFindDataToShowAllTableData,
  };
};

export default useBanquetEnquiry;
