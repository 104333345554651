import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  createOptions,
  getPercentageFinal,
  getReverseOnlyAmount,
  getReverseTaxOnly,
  getWithTaxAmout,
  showFullDateByDateAndTime,
} from "utils/utils";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import { Margin, usePDF } from "react-to-pdf";
import {
  addBanquetBookingServices,
  addBanquetBookingVenue,
  addBunquetBookings,
  getBanquetBookingDetails,
  getBanquetBookingServices,
  getBanquetBookingVenue,
  getBunquetEnquiryService,
  getFunctions,
  getMenuCategory,
  getMenuItem,
  getMenuName,
  getVenue,
} from "API/services/newServices";
import { toast } from "react-toastify";

const validation = Yup.object().shape({
  name: Yup.string().required("Enter Name*"),
  mobileNumber: Yup.string()
    .required("Enter Mobile Number*")
    .length(10, "Enter Correct Mobile Number*")
    .matches(/^[0-9]+$/, "Enter Correct Mobile Number*"),
  AlternateMobileNumber: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .length(10, "Enter Correct Mobile Number*")
    .matches(/^[0-9]+$/, "Enter Correct Mobile Number*"),
  pan: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(
      /^[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/,
      "Please Enter Correct Pan Number*"
    ),
  gst: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter Correct GST Number*"
    ),

  // company: Yup.string().required("Enter Comapany name*"),
  numberOfGuests: Yup.string().required("Enter Number of Guests*"),
  contactPerson: Yup.string().required("Enter contact person*"),
  bookedBy: Yup.string().required("Enter Booked by name*"),
  address: Yup.string().required("Enter Customer Address*"),
  // remark: Yup.string().required("Required Field*"),
  enquiry: Yup.object().required("Please Select Enquiry*"),
  // menu: Yup.string().required("Required Field*"),
  menuType: Yup.object().required("Select Menu Type*"),
  menuName: Yup.object().required("Select Menu Name*"),
  dateOfBooking: Yup.string().required("Enter Date of booking*"),
  // menuFile: Yup.mixed().test("file", "Upload Food Menu*", (value) => {
  //   if (value?.length > 0) {
  //     return value && value[0].type === "application/pdf";
  //   }
  //   return false;
  // }),
});
const validationVenue = Yup.object().shape({
  venue: Yup.object().required("Please Select Venue*"),
  functionName: Yup.object().required("Please Select Function*"),
  session: Yup.object().required("Please Select Session*"),
  toDate: Yup.string().required("Select Date*"),
  fromDate: Yup.string().required("Select Date*"),
  rate: Yup.string().required("Enter Amount*"),
  amount: Yup.string().required("Enter Amount*"),
});
const validationService = Yup.object().shape({
  dateOfBooking: Yup.string().required("Enter Date of booking*"),
  rateService: Yup.string().required("Enter Service rate*"),
  // quantityService: Yup.string().required("Enter Service Quntity*"),
  // expQuantityService: Yup.string().required("Enter expected Service Quntity*"),
  // discountService: Yup.string().required("Enter Service*"),
  amountService: Yup.string().required("Required Field*"),
  instructionService: Yup.string().required("Enter Service instruction*"),
  // igstPercentage: Yup.string().required("Required Field*"),
  // venues: Yup.string().required("Required Field*"),
  // services: Yup.string().required("Required Field*"),
});

const menuTypes = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
  { label: "Mixed ", value: "Mixed " },
];

const useBanquetBookingAddEdit = (fromMobile, fromEnquiry) => {
  const { banquetId } = useParams();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { userDetail } = useSelector(authenticationSeletor);
  const [venueAmout, setVenueAmount] = useState({
    withoutTax: 0,
    tax: 0,
    total: 0,
  });
  const [servicesAmout, setServicesAmount] = useState({
    withoutTax: 0,
    tax: 0,
    total: 0,
  });
  const [finalAmount, setFinalAmount] = useState({
    withoutTax: 0,
    tax: 0,
    total: 0,
  });
  const [dueAmount, setDueAmount] = useState(0);
  const [netPayableAmount, setNetPayableAmount] = useState(0);
  const [foodMenus, setFoodMenu] = useState([]);

  const { AlertIcon } = Imagesdata;

  const navigate = useNavigate();
  const [selectedValidation, setSelectedValidation] = useState(validation);
  const [valState, setValState] = useState(0);
  const [foodItems, setFoodItems] = useState([]);
  const [showFoodItems, setShowFoodItems] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [venue, setVenue] = useState([]);
  const [functionNames, setFunctionNames] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [services, setServices] = useState([]);
  const [venues, setVenues] = useState([]);

  const [show, setShow] = useState(false);

  const FormInputs = [
    {
      name: "enquiry",
      text: "Select Enquiry",
      type: "options",
      options: enquiries,
      serchable: true,
    },
    { name: "name", placeholder: "Enter Name", text: "Name", type: "text" },
    {
      name: "mobileNumber",
      placeholder: "Enter Mobile Number",
      text: "Mobile Number",
      type: "text",
      length: 10,
    },
    {
      name: "AlternateMobileNumber",
      placeholder: "Enter Alternate Mobile Number",
      text: "Alternate Mobile Number",
      type: "text",
      length: 10,
    },
    { name: "email", placeholder: "Enter Email", text: "Email", type: "text" },
    {
      name: "pan",
      placeholder: "Enter Pan Number",
      text: "Pan Number",
      type: "text",
    },
    {
      name: "gst",
      placeholder: "Enter GST Number",
      text: "GST Number",
      type: "text",
    },

    {
      name: "company",
      placeholder: "Enter Company Name",
      text: "Company Name",
      type: "text",
    },
    {
      name: "contactPerson",
      placeholder: "Enter Contact Person",
      text: "Contact Person",
      type: "text",
    },
    {
      name: "bookedBy",
      placeholder: "Enter Booked BY",
      text: "Booked BY",
      type: "text",
    },
    // {
    //   name: "menu",
    //   text: "Menu Type",
    //   type: "options",
    //   options: [{ value: 1, label: "label" }],
    // },

    {
      name: "numberOfGuests",
      text: "Enter Number of Guest",
      type: "number",
      placeholder: "Enter Number of Guest",
    },
    {
      name: "address",
      placeholder: "Enter Address",
      text: "Address",
      type: "textArea",
      col: 6,
    },
    {
      name: "remark",
      placeholder: "Enter Remark",
      text: "Remark",
      type: "textArea",
      col: 6,
    },
  ];
  const sessions = [
    { label: "Hi-Tea", value: "Hi-Tea" },
    { label: "Lunch", value: "Lunch" },
    { label: "Dinner", value: "Dinner" },
    { label: "Breakfast", value: "Breakfast" },
  ];
  const VenueInputs = [
    {
      name: "venue",
      text: "Venue",
      type: "options",
      options: venue,
      serchable: true,
    },
    {
      name: "functionName",
      text: "Function",
      type: "options",
      options: functionNames,
      serchable: true,
    },
    {
      name: "session",
      text: "Session",
      type: "options",
      options: sessions,
      serchable: true,
    },

    {
      name: "toDate",
      text: "Select From Date Time",
      type: "datetime-local",
    },
    {
      name: "fromDate",
      text: "Select To Date Time",
      type: "datetime-local",
    },
    {
      name: "rate",
      placeholder: "Enter Rate",
      text: "Rate",
      type: "number",
    },
    {
      name: "discount",
      placeholder: "Enter Discount",
      text: "Discount",
      type: "number",
      notNull: false,
    },
    {
      name: "amount",
      placeholder: "Enter Amount",
      text: "Amount",
      type: "number",
      disabled: true,
    },
    {
      name: "gstPercentage",
      placeholder: "Enter GST Percentage",
      text: "GST Percentage",
      type: "number",
      notNull: false,
      disabled: true,
    },
  ];
  const bookServiceInputs = [
    {
      name: "dateOfBooking",
      text: "Date Of Booking",
      type: "date",
    },

    {
      name: "rateService",
      placeholder: "Enter Rate",
      text: "Rate",
      type: "number",
    },
    {
      name: "quantityService",
      placeholder: "Enter Quantity ",
      text: "Quantity ",
      type: "number",
      notNull: false,
    },
    {
      name: "expQuantityService",
      placeholder: "Enter Expected Quantity ",
      text: "Expected Quantity",
      type: "number",
      notNull: false,
    },
    {
      name: "discountService",
      placeholder: "Enter Discount",
      text: "Discount",
      type: "number",
      notNull: false,
    },
    {
      name: "gstPercentageService",
      placeholder: "Enter GST Percentage",
      text: "GST Percentage",
      type: "number",
      notNull: false,
    },
    {
      name: "amountService",
      placeholder: "Enter Amount",
      text: "Amount",
      type: "number",
      disabled: true,
    },
    {
      name: "instructionService",
      text: "Special Instructions",
      type: "textArea",
      col: 8,
    },
    // {
    //   name: "igstPercentage",
    //   placeholder: "Enter IGST Percentage",
    //   text: "IGST Percentage",
    //   type: "number",
    // },
  ];

  const [listEditState, setListEditState] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    ...{ resolver: yupResolver(selectedValidation) },
  });
  const { fields, append, remove, replace, update } = useFieldArray({
    name: "venues",
    control,
  });
  const {
    fields: fieldsServices,
    append: appendServices,
    remove: removeServices,
    replace: replaceServices,
    update: updateService,
  } = useFieldArray({
    name: "services",
    control,
  });

  const {
    fields: fieldsFood,
    append: appendFood,
    remove: removeFood,
    replace: replaceFood,
  } = useFieldArray({
    name: "foodItems",
    control,
  });

  const getCategory = async () => {
    const res = await getMenuCategory(userDetail.propertyId);
    if (res) {
      setCategories(createOptions(res, "categoryName", "id"));
    }
  };
  const getMenus = async () => {
    const res = await getMenuName(userDetail.propertyId);

    if (res) {
      setFoodMenu(createOptions(res, "menuName", "id"));
    }
  };
  const getVenues = async () => {
    const res = await getVenue(userDetail.propertyId);
    if (res) {
      setVenue(createOptions(res, "venueName", "venueId"));
    }
  };
  const getFunction = async () => {
    const res = await getFunctions(userDetail.propertyId);
    if (res) {
      setFunctionNames(createOptions(res, "functionName", "id"));
    }
  };

  const getEnquiries = async () => {
    const res = await getBunquetEnquiryService(userDetail.propertyId);
    if (res) {
      const filter = res.map((i) => ({
        value: i.banquetEnquiryId,
        label: i.enquiryCode + "-" + i.name,
        ...i,
      }));
      setEnquiries(filter);
    }
  };

  const onSubmit = async (payload) => {
    if (!payload.menuFile.length && !editMode) {
      toast.error("upload food menu items file*");
      return;
    }
    if (!payload.foodItems.length) {
      toast.error("Add Food items*");
      return;
    }
    if (!payload.venues?.length) {
      toast.error("Add Venues*");
      return;
    }
    if (!payload.services?.length) {
      toast.error("Add services*");
      return;
    }

    const menuItems = payload.foodItems.map((i) => i.name);
    const formData = new FormData();
    formData.append("propertyId", userDetail.propertyId);
    formData.append("enquiryCode", payload.enquiry.enquiryCode);
    if (!editMode) {
      formData.append("banquetEnquiryId", payload.enquiry.value);
    }
    formData.append("customerName", payload.name);
    formData.append("mobile", payload.mobileNumber);
    formData.append("altmobile", payload.AlternateMobileNumber);
    formData.append("email", payload.email);
    formData.append("pan", payload.pan);
    formData.append("gst", payload.gst);
    formData.append("address", payload.address);
    formData.append("remark", payload.remark);
    formData.append("numberOfGuests", payload.numberOfGuests);
    formData.append("companyName", payload.company);
    formData.append("contactPerson", payload.contactPerson);
    formData.append("bookedBy", payload.bookedBy);
    formData.append("reserveBookingDate", new Date(payload.dateOfBooking));
    formData.append("menuType", payload.menuType?.label);
    formData.append("menuName", payload.menuName?.label);
    formData.append("menuItems", menuItems);
    formData.append("venueTaxableAmount", venueAmout.withoutTax);
    formData.append("venueTaxAmount", venueAmout.tax);
    formData.append("venueTotalAmount", venueAmout.total);
    formData.append("serviceTaxableAmount", servicesAmout.withoutTax);
    formData.append("serviceTaxAmount", servicesAmout.tax);
    formData.append("serviceTotalAmount", servicesAmout.total);
    formData.append("subTotal", finalAmount.withoutTax);
    formData.append("totalTax", finalAmount.tax);
    formData.append("extraCharge", payload.extraAmount);
    formData.append("finalAmount", netPayableAmount);
    formData.append("paidAmount", payload.paidAmout);
    formData.append("dueAmount", dueAmount);
    formData.append("paymentStatus", 0);
    formData.append("bookingStatus", 0);
    formData.append("functionId", 1);
    formData.append("venueId", 2);
    formData.append("file", payload.menuFile[0]);

    const res = await addBunquetBookings({
      body: formData,
      id: state?.banquetBookingId,
      editMode,
    });
    if (res) {
      const banquetBookingId = res.data.banquetBookingId;
      fields.map(async (item) => {
        const venueParams = {
          propertyId: userDetail.propertyId,
          banquetBookingId,
          reservedDate: moment(item.fromDate).format("YYYY-MM-DD"),
          reserveStartTime: moment(item.fromDate).format("HH:MM:SS"),
          reserveEndTime: moment(item.toDate).format("HH:MM:SS"),
          session: item.session.value,
          serviceName: item.venue.label,
          functionName: item.functionName.label,
          functionId: item.functionName.value,
          venueId: item.venue.value,
          rate: item.rate,
          discountPercentage: item.discount,
          gstPercentage: item.gstPercentage,
          totalAmount: item.amount,
        };
        if (item?.venueId) {
          await addBanquetBookingVenue({
            body: venueParams,
            id: item?.venueId,
            editMode,
          });
        } else {
          await addBanquetBookingVenue({
            body: venueParams,
            id: item?.venueId,
            editMode: false,
          });
        }
      });

      fieldsServices.map(async (item) => {
        const servicesParams = {
          propertyId: userDetail.propertyId,
          banquetBookingId,
          reservedDate: moment(item.dateOfBooking).format("YYYY-MM-DD"),
          serviceName: item.instructionService,
          rate: item.rateService,
          quantity: item.quantityService,
          expectedQuantity: item.expQuantityService,
          discountPercentage: item.discountService,
          gstPercentage: item.gstPercentageService,
          totalAmount: item.amountService,
        };
        if (item?.servicesId) {
          await addBanquetBookingServices({
            body: servicesParams,
            id: item?.servicesId,
            editMode,
          });
        } else {
          await addBanquetBookingServices({
            body: servicesParams,
            id: item?.servicesId,
            editMode: false,
          });
        }
      });

      goBanquetBooking();
    }
  };

  // states
  const [reportState, setReportState] = useState(false);
  // const { state } = useLocation();
  const [state, setState] = useState(false);

  const getBookingData = async () => {
    const res = await getBanquetBookingDetails(banquetId);
    if (res) {
      setState(res);
    }
  };

  const setVenuesAndServiceAtEditTime = () => {
    const serviceArr = services.map((item) => ({
      dateOfBooking: item.reservedDate,
      instructionService: item.serviceName,
      rateService: item.rate,
      quantityService: item.quantity,
      expQuantityService: item.expectedQuantity,
      discountService: item.discountPercentage,
      gstPercentageService: item.gstPercentage,
      amountService: item.totalAmount,
      servicesId: item.bookedServiceId,
    }));
    const venueArr = venues.map((i) => ({
      venue: venue.find((data) => data?.value == i.venueId),
      functionName: functionNames.find((data) => data.value == i.functionId),
      session: sessions.find((data) => data?.label == i.session),
      toDate: showFullDateByDateAndTime(i.reservedDate, i.reserveEndTime),
      fromDate: showFullDateByDateAndTime(i.reservedDate, i.reserveStartTime),
      rate: i.rate,
      discount: i.discountPercentage,
      amount: i.totalAmount,
      gstPercentage: i.gstPercentage,
      venueId: i.bookedVenueId,
    }));
    replaceServices(serviceArr);
    replace(venueArr);
  };

  const edithandler = () => {
    setVenuesAndServiceAtEditTime();
    setValue("name", state.customerName);
    setValue("mobileNumber", state.mobile);
    setValue("AlternateMobileNumber", state.altmobile);
    setValue("email", state.email);
    setValue("pan", state.pan);
    setValue("gst", state.gst);
    setValue("company", state.companyName);
    setValue("contactPerson", state.contactPerson);
    setValue("bookedBy", state.bookedBy);
    setValue(
      "menuName",
      foodMenus.find((i) => i.label == state.menuName)
    );
    setValue(
      "menuType",
      menuTypes.find((i) => i.label == state.menuType)
    );
    setValue(
      "enquiry",
      enquiries.find((i) => i.value == state.enquiryCode)
    );
    setValue("numberOfGuests", state.numberOfGuests);
    setValue("address", state.address);
    setValue("remark", state.remark);
    setValue("extraAmount", state.extraCharge);
    setValue("paidAmout", state.paidAmount);
    setValue("showEnquiry", state.enquiryCode);
    setValue(
      "dateOfBooking",
      moment(state.reserveBookingDate).format("yyyy-MM-DD")
    );
  };

  const editService = (item, ind) => {
    setListEditState(true);
    setSelectedIndex(ind);
    setValue("dateOfBooking", moment(item.dateOfBooking).format("yyyy-MM-DD"));
    setValue("instructionService", item.instructionService);
    setValue("rateService", item.rateService);
    setValue("quantityService", item.quantityService);
    setValue("expQuantityService", item.expQuantityService);
    setValue("discountService", item.discountService);
    setValue("gstPercentageService", item.gstPercentageService);
    setValue("amountService", item.amountService);
    setValue("servicesId", item.servicesId?.toString());
  };
  const editVenue = (item, ind) => {
    setListEditState(true);
    setSelectedIndex(ind);
    setValue("venue", item.venue);
    setValue("functionName", item.functionName);
    setValue("session", item.session);
    setValue("toDate", item.toDate);
    setValue("fromDate", item.fromDate);
    setValue("rate", item.rate);
    setValue("discount", item.discount);
    setValue("amount", item.amount);
    setValue("venueId", item.venueId?.toString());
    setValue("gstPercentage", item.gstPercentage);
  };

  const getServices = async (id) => {
    const res = await getBanquetBookingServices(id);
    if (res) setServices(res);
  };
  const getVenueItem = async (id) => {
    const res = await getBanquetBookingVenue(id);
    if (res) setVenues(res);
  };

  useEffect(() => {
    if (!!state) {
      edithandler();
    }
  }, [state, enquiries, foodMenus]);
  useEffect(() => {
    if (!!state) {
      setEditMode(true);
      getServices(state.banquetBookingId);
      getVenueItem(state.banquetBookingId);
    }
  }, [state]);
  useEffect(() => {
    if (!!state) {
      setVenuesAndServiceAtEditTime();
    }
  }, [state, services, venues, functionNames, venue]);

  useEffect(() => {
    if (!!banquetId) {
      getBookingData();
    }
  }, []);

  const goBanquetBooking = () => {
    if (fromMobile) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ callback: "success" })
      );
    }
    navigate("/banquet/banquet-bookings");
  };

  const handleAddItem = async () => {
    const {
      venue,
      session,
      toDate,
      fromDate,
      rate,
      discount,
      amount,
      gstPercentage,
      functionName,
      venueId,
    } = getValues();
    const totalAmount = getWithTaxAmout(amount, gstPercentage);

    if (listEditState) {
      update(selectedIndex, {
        venue,
        session,
        toDate,
        fromDate,
        rate,
        discount,
        amount: totalAmount,
        gstPercentage,
        functionName,
        venueId,
      });
      setListEditState(false);
      setSelectedIndex(-1);
    } else {
      append({
        venue,
        session,
        toDate,
        fromDate,
        rate,
        discount,
        amount: totalAmount,
        gstPercentage,
        functionName,
        // netAmount: 0,
      });
    }

    setValue("venue", null);
    setValue("functionName", null);
    setValue("session", null);
    setValue("gstPercentage", "");
    setValue("toDate", "");
    setValue("fromDate", "");
    setValue("rate", "");
    setValue("discount", "");
    setValue("amount", "");
  };

  const handleAddItemFood = async (item) => {
    if (!fieldsFood.find((i) => i.itemId == item.id)) {
      appendFood({
        itemId: item.id,
        name: item.itemName,
      });
    }
  };
  const handleAddItemServices = () => {
    const {
      dateOfBooking,
      instructionService,
      rateService,
      quantityService,
      expQuantityService,
      discountService,
      gstPercentageService,
      amountService,
      servicesId,
    } = getValues();
    const totalAmount = getWithTaxAmout(amountService, gstPercentageService);
    if (listEditState) {
      updateService(selectedIndex, {
        dateOfBooking,
        instructionService,
        rateService,
        quantityService,
        expQuantityService,
        discountService,
        gstPercentageService,
        amountService: totalAmount,
        servicesId,
      });
      setListEditState(false);
      setSelectedIndex(-1);
    } else {
      appendServices({
        dateOfBooking,
        instructionService,
        rateService,
        quantityService,
        expQuantityService,
        discountService,
        gstPercentageService,
        amountService: totalAmount,
      });
    }
    setValue("rateService", "");
    setValue("quantityService", "");
    setValue("expQuantityService", "");
    setValue("discountService", "");
    setValue("amountService", "");
    setValue("instructionService", "");
  };
  const deleteItem = (index) => {
    if (window.confirm("Are you sure to delete this item?")) {
      remove(index);
      return;
    }
  };
  const deleteItemFood = (index) => {
    if (window.confirm("Are you sure to delete this item?")) {
      removeFood(index);
      return;
    }
  };
  const deleteItemService = (index) => {
    if (window.confirm("Are you sure to delete this item?")) {
      removeServices(index);
      return;
    }
  };

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setReportState(true);
    } else {
      setReportState(false);
    }
  }, [id]);
  useEffect(() => {
    getEnquiries();
    getCategory();
    getVenues();
    getMenus();
    getFunction();
  }, []);
  useMemo(() => {
    if (valState == 1) {
      handleSubmit(handleAddItem)();
    } else if (valState == 2) {
      handleSubmit(handleAddItemServices)();
    } else if (valState == 3) {
      handleSubmit(onSubmit)();
    }
  }, [selectedValidation, valState]);

  const changeValidation = (state) => {
    if (state == 1) {
      if (valState == 1) {
        handleSubmit(handleAddItem)();
        return;
      }
      setSelectedValidation(validationVenue);
    } else if (state == 2) {
      if (valState == 2) {
        handleSubmit(handleAddItemServices)();
        return;
      }
      setSelectedValidation(validationService);
    } else if (state == 3) {
      if (valState == 3) {
        handleSubmit(onSubmit)();
        return;
      }
      setSelectedValidation(validation);
    }
    setValState(state);
  };

  const {
    rate,
    discount,
    rateService,
    discountService,
    quantityService,
    extraAmount,
    paidAmout,
    category,
    venue: venueWatch,
    enquiry,
  } = watch();

  useEffect(() => {
    if (rate || discount) {
      setValue("amount", getPercentageFinal(rate, discount));
    }
    if (rateService || discountService) {
      setValue(
        "amountService",
        getPercentageFinal(rateService, discountService) * quantityService
      );
    }
    window.scrollTo(0, 0);
  }, [rate, discount, rateService, discountService, quantityService]);

  const getFoodItems = async () => {
    const res = await getMenuItem(userDetail?.propertyId);
    if (res) {
      if (!!state) {
        const foods = state.menuItems?.split(",").map((food) => {
          const temp = res.find((i) => i.itemName == food);
          return { itemId: temp.id, name: temp.itemName };
        });
        replaceFood(foods);
      }
      setShowFoodItems(res);
      setFoodItems(res);
    }
  };

  useEffect(() => {
    getFoodItems();
  }, [state]);
  useEffect(() => {
    if (!!Object.keys(enquiry ?? {})?.length) {
      setValue("name", enquiry.name);
      setValue("mobileNumber", enquiry.mobile);
      setValue("email", enquiry.email);
      setValue("bookedBy", enquiry.createdBy);
      setValue("numberOfGuests", enquiry.numberOfGuest);
      setValue("remark", enquiry.remark);
    }
  }, [enquiry]);
  useEffect(() => {
    if (fromEnquiry) {
      setValue(
        "enquiry",
        enquiries.find((i) => i.value == fromEnquiry)
      );
    }
  }, [fromEnquiry, enquiries]);

  useMemo(() => {
    const total = fields.reduce((acc, arr) => (acc += +arr.amount), 0);
    const tax = fields.reduce(
      (acc, arr) => (acc += +getReverseTaxOnly(arr.amount, arr.gstPercentage)),
      0
    );
    const withoutTax = fields.reduce(
      (acc, arr) =>
        (acc += +getReverseOnlyAmount(arr.amount, arr.gstPercentage)),
      0
    );
    setVenueAmount({ tax, total, withoutTax });
  }, [fields]);
  useMemo(() => {
    const total = fieldsServices.reduce(
      (acc, arr) => (acc += +arr.amountService),
      0
    );
    const tax = fieldsServices.reduce(
      (acc, arr) =>
        (acc += +getReverseTaxOnly(
          arr.amountService,
          +arr.gstPercentageService
        )),
      0
    );
    const withoutTax = fieldsServices.reduce(
      (acc, arr) =>
        (acc += +getReverseOnlyAmount(
          arr.amountService,
          +arr.gstPercentageService
        )),
      0
    );
    setServicesAmount({ tax, total, withoutTax });
  }, [fieldsServices]);

  useMemo(() => {
    const total = venueAmout.total + servicesAmout.total;
    setFinalAmount({
      tax: venueAmout.tax + servicesAmout.tax,
      total,
      withoutTax: venueAmout.withoutTax + servicesAmout.withoutTax,
    });
    setNetPayableAmount(+extraAmount + +total);
  }, [venueAmout, servicesAmout]);
  useMemo(() => {
    const payble = +extraAmount + +finalAmount.total;
    setNetPayableAmount(payble);
    setDueAmount(payble - paidAmout);
  }, [extraAmount, paidAmout]);
  useMemo(() => {
    if (category) {
      const items = foodItems.filter((i) => i.menuCategoryId == category);
      setShowFoodItems(items);
    } else {
      setShowFoodItems(foodItems);
    }
  }, [category]);
  useMemo(() => {
    if (venueWatch) {
      setValue("gstPercentage", 10);
    }
  }, [venueWatch]);

  const { toPDF, targetRef } = usePDF({
    filename: "banquet-Bookings.pdf",
    page: { margin: Margin.MEDIUM, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 2,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  return {
    goBanquetBooking,
    register,
    errors,
    handleSubmit,
    onSubmit,
    AlertIcon,
    FormInputs,
    VenueInputs,
    fields,
    deleteItem,
    handleAddItem,
    bookServiceInputs,
    fieldsServices,
    handleAddItemServices,
    deleteItemService,
    showFoodItems,
    fieldsFood,
    handleAddItemFood,
    deleteItemFood,
    toPDF,
    targetRef,
    changeValidation,
    venueAmout,
    servicesAmout,
    finalAmount,
    dueAmount,
    netPayableAmount,
    control,
    categories,
    reportState,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    show,
    setShow,
    foodMenus,
    userDetail,
    editMode,
    setValue,
    enquiries,
    editService,
    editVenue,
    menuTypes,
  };
};

export default useBanquetBookingAddEdit;
