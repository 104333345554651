import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation2 = Yup.object().shape({
  city: Yup.string().required("Please Enter Pincode"),
  state: Yup.string().required("Please Select City Name"),
  locality: Yup.string().required("Please Enter Locality Name"),
});

export default { resolver: yupResolver(validation2) };
