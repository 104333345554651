const UserDictionary = {
  addRRoomUser: () => "auth/rrooms-users",
  getRroomsUsers: () => "auth/rrooms-users",
  delRroomsUsers: (id) => `auth/rrooms-users/${id}`,
  delPropertyUsers: (id) => `auth/property-users/${id}`,
  getPropertyUsers: () => "auth/property-users",
  // getPropertyUsersByPrt: (id) => `auth/property-users-by-property/${id}`,
  getPropertyUsersByPrt: () => `auth/property-users`,
  getCustomers: () => "auth/customers",
};

export default UserDictionary;
