import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link } from "react-router-dom";
import Layout from "../../layout";
import { useForm, SubmitHandler } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import {
  getRroomUsersAction,
  usersSeletor,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";

const InventoryCategory = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "inventory_category" });
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
  }, [userDetail]);
  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      name: payload.categoryName,
      propertyId: userDetail?.propertyId,
      createdBy: userDetail.id,
      status: "active",
    };
    try {
      let res = {};
      if (editMode) {
        const editPayload = {
          body: realPayload,
          id: selectedData?.id,
        };
        res = await services.globalService.editInventoryCategoryService(
          editPayload
        );
      } else {
        res = await services.globalService.createInventoryCategoryService(
          realPayload
        );
      }
      if (res.status === 200) {
        reset();
        setEditMode(false);
        getDataHandler(userDetail?.propertyId);
        setShow(false);
        setSelectedData({});
      }
    } catch (error) {}
  };

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getInventoryCategoryService(id);
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);
    setValue("categoryName", data.name);
    setEditMode(true);
  };

  return (
    <Layout title="Inventory Category" openHandler={() => setShow(true)}>
      <Row>
        <Col xs={12}>
          <div className="categoryWrap">
            <h3>Inventory Category</h3>
            <ul className="d-flex">
              {tableData.map((data, key) => (
                <li
                  key={key}
                  className="d-flex align-items-center"
                  onClick={
                    userDetail?.Role?.canEdit == 1
                      ? () => editHandler(data)
                      : () => false
                  }
                >
                  <span>{data.name}</span>
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen-to-square"
                    color="#0076FC"
                  />
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit category" : "Create Inventory category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="categoryName"
                    text="Category name"
                    type="text"
                    max={100}
                    autoFocus={true}
                    placeholder="Enter Category name"
                    field={"categoryName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.categoryName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"categoryName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2"
                >
                  <Button className="width100" variant="primary" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default InventoryCategory;
