import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link, useNavigate } from "react-router-dom";
import { getRroomPropertyAction } from "../../store/slices/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../store/slices/propertySlice";
import { Controller, useForm } from "react-hook-form";
import validation from "../../components/landingHeader/validation";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import { ErrorMessage } from "@hookform/error-message";
import Services from "../../API/services";
import { setShowModal } from "../../store/slices/modalState";

function FrontFooter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    Logolandingpage,
    FooterLogo,
    AlertIcon,
    GooglePlay,
    ApplePlay,
    ScanCode,
  } = Imagesdata || {};
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { showModal } = useSelector((s) => s.showModal);

  const hotelListingNav = (query) => {
    dispatch(setSearch(query));
    dispatch(
      getRroomPropertyAction(
        `?order_direction=asc${query ? `&query=${query}` : ""} `
      )
    );
    navigate("/hotal-listings");
    window.scrollTo(0, 0);
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const getState = async () => {
    try {
      const state = await Services.globalService.getAllState();
      if (state.status === 200) {
        setStateList(state.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getState();
  }, []);
  const getCity = async (id) => {
    try {
      const state = await Services.globalService.getAllCitiesByState(id);
      if (state.status === 200) {
        setCityList(state.data.data);
      }
    } catch (error) {}
  };
  const { state } = watch();

  useEffect(() => {
    if (state) {
      getCity(state);
    }
  }, [state]);

  const onSubmit = async (payload) => {
    const realPayload = {
      first_name: payload.firstName,
      last_name: payload.lastName,
      email: payload.email,
      mobile: payload?.phoneNumber.toString(),
      property_name: payload.propertyName,
      address_line_1: payload.address,
      address_line_2: payload.addressLine,
      state_id: payload.state,
      city: payload.city,
      pincode: payload.postalCode,
    };
    try {
      const res = await Services.property.createEnquiryService(realPayload);
      if (res.status === 201) {
        reset();
        dispatch(setShowModal(false));
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="purchase-box flex">
        <div className="content">
          <h3>
            <strong>
              Download the RROOMS mobile App now and get upto 30% discount on
              your first booking
            </strong>{" "}
            Download RRooms app for exciting offers.
          </h3>
        </div>
        <div className="image">
          <ul>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.rrooms"
                target="_black"
              >
                <img alt="" src={GooglePlay} alt="" />
              </a>
            </li>
            <li>
              <a href="">
                <img alt="" src={ApplePlay} alt="" />
              </a>
            </li>
            <li>
              <a href="">
                <img alt="" src={ScanCode} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footerLanding">
        <div className="top">
          <div className="container flex">
            <div className="footerTabs listHotelFooter">
              <p>
                Are you a Hotelier? <br /> Join RROOMS
              </p>
              <ul>
                We are rapidly growing in every major cities, We have great
                partnerships with over 4000+ Hotels, You are welcome to become a
                part of RROOMS.
                <button onClick={() => dispatch(setShowModal(!showModal))}>
                  List Your Hotel <i className="bi bi-building"></i>
                </button>
              </ul>
            </div>

            <div className="footerTabs">
              <p>More for RRooms</p>
              <ul>
                <li>
                  <Link to="/about-us">About RRooms</Link>
                </li>
                <li>
                  <Link to="/Blog">Blog</Link>
                </li>
                <li>
                  <Link to="/career">Careers</Link>
                </li>
                <li>
                  <Link to="/How-it-Works">How it works</Link>
                </li>
              </ul>
            </div>
            <div className="footerTabs">
              <p>Support</p>
              <ul>
                <li>
                  <Link to="/help-center">Help Center</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/user-agreement">User Agreement</Link>
                </li>
                <li>
                  {/* <Link to="/Rrooms-privacy-policy">Privacy Policy</Link> */}
                  <a href="https://www.rrooms.in/Rrooms-privacy-policy/">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and condition</Link>
                </li>
                <li>
                  <Link to="/cancellation-refund-policy">
                    Refund and Cancellation Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footerTabs">
              <p>Partner with us</p>
              <ul>
                <li>
                  <Link to="/Partnership-program">Partnership Programs</Link>
                </li>
                <li>
                  <Link to="/Affiliate-program">Affiliate Program</Link>
                </li>
                <li>
                  <Link to="/Promotion-events">Promotion and Events</Link>
                </li>
                <li>
                  <Link to="/Community">Community</Link>
                </li>
              </ul>
            </div>
            <div className="footerTabs">
              <p>ABOUT RROOMS</p>
              <ul>
                Welcome to RRooms, your gateway to seamless travel experiences
                around the world. With its extensive reach, user-friendly
                interface, and commitment to excellence, RRooms offers hoteliers
                a reliable and efficient channel to connect with a diverse range
                of travelers.
              </ul>
            </div>
          </div>
        </div>
        <div className="middTop">
          <div className="container">
            <p>Stay Connected</p>
            <ul className="flex gap-4">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61551425862894"
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/rroomspvtltd/"
                  target="_blank"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/" target="_blank">
                  <i className="bi bi-twitter-x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://web.whatsapp.com/send/?phone=9190123 90125&text= hi I have a Query in booking of Rooms please contact me."
                  target="_blank"
                >
                  <i className="bi bi-whatsapp"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/social-rrooms-3b0567293/"
                  target="_blank"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCFZH4rXtrgEl9bBAPy-TtwQ"
                  target="_blank"
                >
                  <i className="bi bi-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="center">
          <div className="container">
            <h3>RROOMS Hotels</h3>
            <div className="cityShows flex justify-content-between">
              <ul>
                <li onClick={() => hotelListingNav("Lucknow")}>
                  Hotels near me
                </li>
                <li onClick={() => hotelListingNav("Goa")}>Hotels in Goa</li>
                <li onClick={() => hotelListingNav("Puri")}>Hotels in Puri</li>
                <li onClick={() => hotelListingNav("Mahabaleshwar")}>
                  Hotels in Mahabaleshwar
                </li>
                <li onClick={() => hotelListingNav("Jaipur")}>
                  Hotels in Jaipur
                </li>
                <li onClick={() => hotelListingNav("Shimla")}>
                  Hotels in Shimla
                </li>
                <li onClick={() => hotelListingNav("Manali")}>
                  Hotels in Manali
                </li>
                <li onClick={() => hotelListingNav("Udaipur")}>
                  Hotels in Udaipur
                </li>
                <li onClick={() => hotelListingNav("Mussoorie")}>
                  Hotels in Mussoorie
                </li>
              </ul>
              <ul>
                <li onClick={() => hotelListingNav("Pondicherry")}>
                  Hotels in Pondicherry
                </li>
                <li onClick={() => hotelListingNav("Delhi")}>
                  Hotels in Delhi
                </li>
                <li onClick={() => hotelListingNav("Mumbai")}>
                  Hotels in Mumbai
                </li>
                <li onClick={() => hotelListingNav("Nainital")}>
                  Hotels in Nainital
                </li>
                <li onClick={() => hotelListingNav("Lonavala")}>
                  Hotels in Lonavala
                </li>
                <li onClick={() => hotelListingNav("Munnar")}>
                  Hotels in Munnar
                </li>
                <li onClick={() => hotelListingNav("Bangalore")}>
                  Hotels in Bangalore
                </li>
                <li onClick={() => hotelListingNav("Mysore")}>
                  Hotels in Mysore
                </li>
                <li onClick={() => hotelListingNav("Darjeeling")}>
                  Hotels in Darjeeling
                </li>
              </ul>
              <ul>
                <li onClick={() => hotelListingNav("Kodaikanal")}>
                  Hotels in Kodaikanal
                </li>
                <li onClick={() => hotelListingNav("Hyderabad")}>
                  Hotels in Hyderabad
                </li>
                <li onClick={() => hotelListingNav("Pune")}>Hotels in Pune</li>
                <li onClick={() => hotelListingNav("Chandigarh")}>
                  Hotels in Chandigarh
                </li>
                <li onClick={() => hotelListingNav("Shirdi")}>
                  Hotels in Shirdi
                </li>
                <li onClick={() => hotelListingNav("Agra")}>Hotels in Agra</li>
                <li onClick={() => hotelListingNav("Gangtok")}>
                  Hotels in Gangtok
                </li>
                <li onClick={() => hotelListingNav("Coorg")}>
                  Hotels in Coorg
                </li>
                <li onClick={() => hotelListingNav("Chennai")}>
                  Hotels in Chennai
                </li>
              </ul>
              <ul>
                <li onClick={() => hotelListingNav("Tirupati")}>
                  Hotels in Tirupati
                </li>
                <li onClick={() => hotelListingNav("Dalhousie")}>
                  Hotels in Dalhousie
                </li>
                <li onClick={() => hotelListingNav("Haridwar")}>
                  Hotels in Haridwar
                </li>
                <li onClick={() => hotelListingNav("Kolkata")}>
                  Hotels in Kolkata
                </li>
                <li onClick={() => hotelListingNav("Ahmedabad")}>
                  Hotels in Ahmedabad
                </li>
                <li onClick={() => hotelListingNav("Shillong")}>
                  Hotels in Shillong
                </li>

                <li onClick={() => hotelListingNav("Rishikesh")}>
                  Hotels in Rishikesh
                </li>
                <li onClick={() => hotelListingNav("Varanasi")}>
                  Hotels in Varanasi
                </li>
                <li onClick={() => hotelListingNav("Gurgaon")}>
                  Hotels in Gurgaon
                </li>
              </ul>
              <ul>
                <li onClick={() => hotelListingNav("Mandarmoni")}>
                  Hotels in Mandarmoni
                </li>
                <li onClick={() => hotelListingNav("Daman")}>
                  Hotels in Daman
                </li>
                <li onClick={() => hotelListingNav("Yercaud")}>
                  Hotels in Yercaud
                </li>
                <li onClick={() => hotelListingNav("Amritsar")}>
                  Hotels in Amritsar
                </li>
                <li onClick={() => hotelListingNav("Madurai")}>
                  Hotels in Madurai
                </li>
                <li onClick={() => hotelListingNav("Coimbatore")}>
                  Hotels in Coimbatore
                </li>
                <li onClick={() => hotelListingNav("Kasauli")}>
                  Hotels in Kasauli
                </li>
                <li onClick={() => hotelListingNav("Dehradun")}>
                  Hotels in Dehradun
                </li>
                <li onClick={() => hotelListingNav("Mount Abu")}>
                  Hotels in Mount Abu
                </li>
                <li onClick={() => hotelListingNav("Hotels")}>
                  All Cities Hotels
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container">
            <div className="wrap flex">
              <figure>
                <Link href="/">
                  <img alt="" src={Logolandingpage} alt="" />
                  <img alt="" src={FooterLogo} alt="" />
                </Link>
              </figure>
              <ul className="flex">
                <li>
                  <Link to="/">Docs</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/">Licenses</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={() => {
            dispatch(setShowModal(!showModal));
            reset();
          }}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create enquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              className="popup-form frontFormAddMember"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Container>
                <Row>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="firstName"
                      text="First Name"
                      type="text"
                      autoFocus={true}
                      placeholder="Enter first name"
                      field={"firstName"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.firstName?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"firstName"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="lastName"
                      text="Last Name"
                      type="text"
                      placeholder="Enter Last name"
                      field={"lastName"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.lastName?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"lastName"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="phoneNumber"
                      text="Phone No."
                      type="text"
                      max={10}
                      placeholder="Enter phone number"
                      field={"phoneNumber"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.phoneNumber?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"phoneNumber"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="email"
                      text="Email address "
                      type="email"
                      placeholder="Enter email address"
                      field={"email"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.email?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"email"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} className="form-group">
                    <InputField
                      htmlFor="address"
                      text="Property Address"
                      type="text"
                      placeholder="Enter property address Line 1"
                      field={"address"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.address?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"address"} />
                        </span>
                      </div>
                    )}
                    <br />
                    <InputField
                      htmlFor="addressLine"
                      text=""
                      type="text"
                      placeholder="Enter property address Line 2"
                      field={"addressLine"}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>State</label>
                    <Controller
                      control={control}
                      {...register("state")}
                      name={"state"}
                      render={({ field: { onChange, value } }) => (
                        <select
                          onChange={onChange}
                          value={value}
                          className="form-control"
                        >
                          <option selected disabled>
                            Select State
                          </option>
                          {stateList?.map((data, key) => (
                            <option key={key} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>City</label>
                    <Controller
                      control={control}
                      name={"city"}
                      render={({ field: { onChange, value, ref } }) => (
                        <select
                          onChange={onChange}
                          value={value}
                          className="form-control"
                        >
                          <option selected disabled>
                            Select City
                          </option>
                          {cityList.map((data, key) => (
                            <option key={key} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.city?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"city"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="postalCode"
                      text="Postal Code"
                      type="text"
                      placeholder="Enter Postal Code"
                      field={"postalCode"}
                      max={6}
                      register={register}
                      errors={errors}
                    />
                    {errors?.postalCode?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"postalCode"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} className="form-group">
                    <InputField
                      htmlFor="propertyName"
                      text="Property Name"
                      type="text"
                      placeholder="Enter Property Name"
                      field={"propertyName"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.propertyName?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"propertyName"} />
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col xs={12} className="form-group">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default FrontFooter;
