import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import services from "../../API/services";
import { useDispatch } from "react-redux";
import { getRroomPropertyAction } from "../../store/slices/propertySlice";
import Moment from "react-moment";
import ImageUploadBox from "../../components/imageUploadBox";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import { arrayColumn, isJsonString } from "../../utils/utils";
import { baseImgUrl, baseUrl } from "../../API/client/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Step3 from "./Step3";
import { useMemo } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import {
  TRAVELLER_CHOICE_TEXT,
  propertyPolicy,
} from "../../constant/constants";
import Services from "../../API/services";

const PROPERTY_STATUS = {
  ACTIVE: 3,
  INACTIVE: 4,
  APPROVED: 1,
  REJECTED: 2,
  PENDING: 0,
  ALL: -1,
};

const Property = () => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [imageData1, setImageData1] = useState([]);
  const [imageData2, setImageData2] = useState([]);
  const [imageData3, setImageData3] = useState([]);
  const [imageData4, setImageData4] = useState([]);
  const [imageData5, setImageData5] = useState([]);
  const [imageData6, setImageData6] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [selectedAminites, setselectedAminites] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [imageSet1, setImageSet1] = useState([]);
  const [imageSet2, setImageSet2] = useState([]);
  const [imageSet3, setImageSet3] = useState([]);
  const [imageSet4, setImageSet4] = useState([]);
  const [imageSet5, setImageSet5] = useState([]);
  const [imageSet6, setImageSet6] = useState([]);
  const [localityData, setLocalityData] = useState([]);

  const [policyText, setPolicyText] = useState([]);

  const [Couple, setCouple] = useState(false);
  const [Local, setLocal] = useState(false);
  const [Partial, setPartial] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [step, setStep] = useState(1);
  const [editDropDown, setEditDropDown] = useState(-1);
  const [topValueForEditList, setTopValueForEditList] = useState(0);
  const [activeTab, setActiveTab] = useState(PROPERTY_STATUS.PENDING);
  const [showData, setShowData] = useState([]);
  const [travellerData, setTravellerData] = useState([]);
  const [profileImg, setProfileImg] = useState(0);
  const [activeImageState, setActiveImageState] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const getPropertyData = async () => {
    try {
      const res = await services.property.getRroomsPropertyService();
      if (res.status === 200) {
        setPropertyList(res?.data?.data);
      }
    } catch (error) {}
  };
  const getPropertyCategory = async () => {
    try {
      const res = await services.property.getPropertyCategoryService();
      if (res.status === 200) {
        setCategoryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const getAmenities = async () => {
    try {
      const res = await services.property.getAmenitiesService();
      if (res.status === 200) {
        setAmenitiesData(res?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getPropertyData();
    getPropertyCategory();
    getAmenities();
  }, []);

  const resetImages = () => {
    setImageSet1([]);
    setImageSet2([]);
    setImageSet3([]);
    setImageSet4([]);
    setImageSet5([]);
    setImageSet6([]);
    setImageData1([]);
    setImageData2([]);
    setImageData3([]);
    setImageData4([]);
    setImageData5([]);
    setImageData6([]);
  };

  const onHide = () => {
    setShow(false);
    reset();
    resetImages();
    setEditMode(false);
    setselectedAminites([]);
    setPartial(false);
    setCouple(false);
    setLocal(false);
    setTravellerData([]);
    setActiveImageState(false);
    setProfileImg(null);
    setPolicyText([]);
  };

  const onSubmit = async () => {
    const {
      adharNumber,
      amenities,
      bookingPolicy,
      city,
      email,
      firmType,
      firstName,
      lastName,
      latitude,
      longitude,
      panNumber,
      phoneNumber,
      postalCode,
      propertyAddress,
      propertyDescription,
      propertyEmail,
      propertyLandmark,
      propertyName,
      propertyPhone,
      state,
      noOfRooms,
      Locality,
    } = getValues();
    policyText.push(bookingPolicy);
    const policy = JSON.stringify(policyText);
    if (step === 1) {
      let bodyFormData1 = new FormData();
      bodyFormData1.append("name", propertyName);
      bodyFormData1.append("address", propertyAddress);
      bodyFormData1.append("propertyCategoryId", firmType);
      bodyFormData1.append("latitude", latitude);
      bodyFormData1.append("longitude", longitude);
      bodyFormData1.append("ownerFirstName", firstName);
      bodyFormData1.append("ownerLastName", lastName);
      bodyFormData1.append("ownerMobile", phoneNumber);
      bodyFormData1.append("ownerEmail", email);
      bodyFormData1.append("ownerPan", panNumber);
      bodyFormData1.append("ownerAdhar", adharNumber);
      bodyFormData1.append("pincode", postalCode);
      bodyFormData1.append("countryId", "IN");
      bodyFormData1.append("city", city);
      bodyFormData1.append("noOfRooms", noOfRooms);
      bodyFormData1.append("stateId", state);
      bodyFormData1.append("propertyDescription", propertyDescription);
      bodyFormData1.append("propertyMobileNumber", propertyPhone);
      bodyFormData1.append("propertyEmailId", propertyEmail);
      bodyFormData1.append("landmark", propertyLandmark);
      bodyFormData1.append("bookingPolicy", policy);
      bodyFormData1.append("status", 1);
      bodyFormData1.append("locaidAccept", Local ? 1 : 0);
      bodyFormData1.append("coupleFriendly", Couple ? 1 : 0);
      bodyFormData1.append("partialPayment", Partial ? 1 : 0);
      bodyFormData1.append("status", 1);
      bodyFormData1.append("locality", Locality);
      bodyFormData1.append("travellerChoice", travellerData?.toString());
      for (let i = 0; i < amenities.length; i++) {
        bodyFormData1.append(`amenities[${i}]`, amenities[i]);
      }
      try {
        let res = {};
        if (editMode) {
          res = await services.property.updateRRoomPropertyService({
            id: selectedData?.id,
            body: bodyFormData1,
          });
          setStep(1);
        } else {
          res = await services.property.createRRoomPropertyService(
            bodyFormData1
          );
          setSelectedData(res.data.data);
        }
        if (res.status === true || res.status === 200) {
          if (!editMode) {
            setStep(2);
            toast.success("Property Details Successfully Created !", {
              autoClose: 1000,
              pauseOnHover: true,
              position: "top-right",
            });
          } else {
            setShow(false);
            toast.success("Property Details Successfully Updated !", {
              autoClose: 1000,
              pauseOnHover: true,
              position: "top-right",
            });
          }
        }
        setPolicyText([]);
        getPropertyData();
        setPartial(false);
        setCouple(false);
        setLocal(false);
      } catch (error) {
        if (error.response.status === 409) {
          toast.error("Property Email or Mobile no. already Exist*", {
            autoClose: 2000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      }
    } else if (step === 2) {
      let bodyFormData2 = new FormData();

      if (imageData1.length) {
        imageData1.map((dataImg) => {
          bodyFormData2.append("images", dataImg);
          bodyFormData2.append("imageTitle", "Facade");
        });
      }
      if (imageData2.length) {
        imageData2.map((dataImg) => {
          bodyFormData2.append("images", dataImg);
          bodyFormData2.append("imageTitle", "Lobby");
        });
      }
      if (imageData3.length) {
        imageData3.map((dataImg) => {
          bodyFormData2.append("images", dataImg);
          bodyFormData2.append("imageTitle", "DReception");
        });
      }
      if (imageData4.length) {
        imageData4.map((dataImg) => {
          bodyFormData2.append("images", dataImg);
          bodyFormData2.append("imageTitle", "Room");
        });
      }
      if (imageData5.length) {
        imageData5.map((dataImg) => {
          bodyFormData2.append("images", dataImg);
          bodyFormData2.append("imageTitle", "Washroom");
        });
      }
      if (imageData6.length) {
        imageData6.map((dataImg) => {
          bodyFormData2.append("images", dataImg);
          bodyFormData2.append("imageTitle", "Others");
        });
      }
      if (profileImg) {
        changeHeroImg();
      }
      try {
        let res = await services.property.updateRRoomPropertyService({
          id: selectedData?.id,
          body: bodyFormData2,
        });
        if (res.status === true || res.status === 200) {
          resetImages();
          getPropertyData();
          if (!editMode) {
            toast.success("Property Images Successfully Created !", {
              autoClose: 1000,
              pauseOnHover: true,
              position: "top-right",
            });
            setStep(3);
          } else {
            toast.success("Property Images Successfully Updated !", {
              autoClose: 1000,
              pauseOnHover: true,
              position: "top-right",
            });
            setShow(false);
            setStep(1);
          }
        }
      } catch (error) {}
    }
  };

  const changeHeroImg = async () => {
    try {
      const res = await services.property.updatePropertyProfile(
        selectedData?.id,
        { profileImageID: profileImg }
      );

      if (res.status === 200) {
        onHide();
      }
    } catch (error) {}
  };

  useEffect(() => {
    setTravellerHandler(7);
  }, [Couple]);

  const setTravellerHandler = (item) => {
    if (travellerData.includes(item)) {
      setTravellerData(() => travellerData.filter((i) => i !== item));
    } else {
      setTravellerData([...travellerData, item]);
    }
  };

  const changeStatus = async (data) => {
    if (window.confirm("Are You Sure Want to change Status")) {
      try {
        const bodyData = {
          status: data?.status == 0 ? 1 : 0,
          id: data?.id,
        };
        const res = await fetch(`${baseUrl}rrooms-property/property-status`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });
        const datas = await res.json();
        toast.success("Property Status Changed !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        getPropertyData();
      } catch (error) {}
    }
  };

  const setPolicyHandler = (checkbox) => {
    if (checkbox.checked) {
      setPolicyText([...policyText, checkbox.value]);
    } else {
      setPolicyText((preVal) => preVal.filter((i) => i != checkbox.value));
    }
  };

  const imageHandler1 = (data) => {
    setImageData1((preValue) => {
      return [...preValue, data];
    });
  };
  const imageHandler2 = (data) => {
    setImageData2((preValue) => {
      return [...preValue, data];
    });
  };
  const imageHandler3 = (data) => {
    setImageData3((preValue) => {
      return [...preValue, data];
    });
  };
  const imageHandler4 = (data) => {
    setImageData4((preValue) => {
      return [...preValue, data];
    });
  };
  const imageHandler5 = (data) => {
    setImageData5((preValue) => {
      return [...preValue, data];
    });
  };
  const imageHandler6 = (data) => {
    setImageData6((preValue) => {
      return [...preValue, data];
    });
  };

  const deleteHandler = async () => {
    try {
      const res = await services.property.deleteRRoomPropertyService(
        selectedData.id
      );

      if (res.status === 200) {
        dispatch(getRroomPropertyAction());
        getPropertyData();
        setShowDelete(false);
        toast.success("SuccesFully Deleted Property!", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  const editHandler = async (item, step) => {
    try {
      const {
        data: { data },
      } = await services.property.getPropertyCategoryServiceId(item.id);

      setEditDropDown(-1);
      setSelectedData(data);
      setStep(step);

      let policy = data.bookingPolicy;

      if (data.bookingPolicy && isJsonString(data.bookingPolicy)) {
        const json = JSON.parse(data.bookingPolicy);
        policy = json.pop();
        setPolicyText(json);
      }

      if (data.partialPayment) setPartial(true);
      if (data.coupleFriendly) setCouple(true);
      if (data.locaidAccept) setLocal(true);

      setShow(true);
      setEditMode(true);

      setValue("propertyName", data.name);
      setValue("gstNumber", data.gstNumber);
      setValue("tanNumber", data.tanNumber);
      setValue("propertyDescription", data.propertyDescription);
      setValue("longitude", data.longitude);
      setValue("noOfRooms", data?.noOfRooms);
      setValue("latitude", data.latitude);
      setValue("propertyAddress", data.address);
      setValue("Locality", data.locality);
      setValue("state", data.stateId);

      setValue("city", data.city?.[0].id);
      setValue("postalCode", data.pincode);
      setValue("firstName", data.ownerFirstName);
      setValue("lastName", data.ownerLastName);
      setValue("panNumber", data.ownerPan);
      setValue("adharNumber", data.ownerAdhar);
      setValue("email", data.ownerEmail);
      setValue("phoneNumber", data.ownerMobile);
      setValue("firmType", data.propertyCategoryId);
      setValue("propertyEmail", data.propertyEmailId);
      setValue("propertyPhone", data.propertyMobileNumber);
      setValue("propertyLandmark", data.landmark);
      setValue("bookingPolicy", policy);
      setValue("PropertyPanNumber", data.PropertyPanNumber);
      setValue("firmTypeEntity", data.firmType);
      setValue(
        "amenities",
        arrayColumn(data.PropertyAmenities, "propertyAmenitiesId")
      );
      setselectedAminites(
        arrayColumn(data.PropertyAmenities, "propertyAmenitiesId")
      );
      let imagData1 = [];
      let imagData2 = [];
      let imagData3 = [];
      let imagData4 = [];
      let imagData5 = [];
      let imagData6 = [];
      data?.PropertyImages.map((data) => {
        let obj = {
          id: data.id,
          filename: data.image,
          filetype: "",
          fileimage: `${baseImgUrl}${data.image}`,
          datetime: "",
          filesize: "",
          title: data?.title,
        };

        if (obj?.title === "Facade" || obj?.title?.charAt(0) === "F") {
          imagData1.push(obj);
        } else if (obj?.title === "Lobby" || obj?.title?.charAt(0) === "L") {
          imagData2.push(obj);
        } else if (
          obj?.title === "DReception" ||
          obj?.title?.charAt(0) === "D" ||
          obj?.title === "Reception"
        ) {
          imagData3.push(obj);
        } else if (obj?.title === "Room" || obj?.title?.charAt(0) === "R") {
          imagData4.push(obj);
        } else if (obj?.title === "Washroom" || obj?.title?.charAt(0) === "W") {
          imagData5.push(obj);
        } else {
          imagData6.push(obj);
        }
      });

      setImageSet1(imagData1);
      setImageSet2(imagData2);
      setImageSet3(imagData3);
      setImageSet4(imagData4);
      setImageSet5(imagData5);
      setImageSet6(imagData6);
    } catch (error) {}
  };

  const changeHandler = (e, data) => {
    if (e.target.checked) {
      setselectedAminites([...selectedAminites, Number(e.target.value)]);
    } else {
      const resultfilter = selectedAminites.filter((d, index) => {
        return d !== Number(e.target.value);
      });
      setselectedAminites(resultfilter);
    }
  };

  const deleteImageHandler = async (id) => {
    try {
      const res = await services.property.deletePropertyImageService(id);
      if (res.status === 200) {
        dispatch(getRroomPropertyAction());
        toast.success("Image Successfully Deleted !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        setImageSet1(() => imageSet1.filter((i) => i.id !== id));
        setImageSet2(() => imageSet2.filter((i) => i.id !== id));
        setImageSet3(() => imageSet3.filter((i) => i.id !== id));
        setImageSet4(() => imageSet4.filter((i) => i.id !== id));
        setImageSet5(() => imageSet5.filter((i) => i.id !== id));
        setImageSet6(() => imageSet6.filter((i) => i.id !== id));
      }
    } catch (error) {}
  };

  const propertyDataObj = useMemo(() => {
    return propertyList?.reduce(
      (acc, propertyList) => {
        const { approved, status } = propertyList || {};

        if (approved === PROPERTY_STATUS.APPROVED) {
          acc[PROPERTY_STATUS.APPROVED].push(propertyList);
        } else if (approved === PROPERTY_STATUS.REJECTED) {
          acc[PROPERTY_STATUS.REJECTED].push(propertyList);
        } else if (approved === PROPERTY_STATUS.PENDING) {
          acc[PROPERTY_STATUS.PENDING].push(propertyList);
        }
        if (status === 0) {
          acc[PROPERTY_STATUS.INACTIVE].push(propertyList);
        } else if (status === 1 && approved === PROPERTY_STATUS.APPROVED) {
          acc[PROPERTY_STATUS.ACTIVE].push(propertyList);
        }
        return acc;
      },
      {
        [PROPERTY_STATUS.ACTIVE]: [],
        [PROPERTY_STATUS.INACTIVE]: [],
        [PROPERTY_STATUS.APPROVED]: [],
        [PROPERTY_STATUS.REJECTED]: [],
        [PROPERTY_STATUS.PENDING]: [],
        [PROPERTY_STATUS.ALL]: propertyList,
      }
    );
  }, [propertyList]);

  const propertyStatusTabs = useMemo(
    () => [
      {
        key: PROPERTY_STATUS.PENDING,
        title: `Requested (${
          propertyDataObj[PROPERTY_STATUS.PENDING]?.length
        })`,
      },
      {
        key: PROPERTY_STATUS.APPROVED,
        title: `Approved (${
          propertyDataObj[PROPERTY_STATUS.APPROVED]?.length
        })`,
      },
      {
        key: PROPERTY_STATUS.REJECTED,
        title: `Rejected (${
          propertyDataObj[PROPERTY_STATUS.REJECTED]?.length
        })`,
      },
      {
        key: PROPERTY_STATUS.ACTIVE,
        title: `Active (${propertyDataObj[PROPERTY_STATUS.ACTIVE]?.length})`,
      },
      {
        key: PROPERTY_STATUS.INACTIVE,
        title: `Inactive (${
          propertyDataObj[PROPERTY_STATUS.INACTIVE]?.length
        })`,
      },

      {
        key: PROPERTY_STATUS.ALL,
        title: `All (${propertyList?.length})`,
      },
    ],
    [propertyDataObj]
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const reApprove = async (approveSelect) => {
    const check = window.confirm(
      "Are you Sure Want to Re-Approve this Property*"
    );
    if (!check) {
      return;
    }
    try {
      const bodyData = {
        approved: 1,
        id: approveSelect,
      };
      const res = await fetch(`${baseUrl}rrooms-property/property-status`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      if (res.status === 200) {
        toast.success("Property Approved SuccessFull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        getPropertyData();
      }
    } catch (error) {}
  };

  const getState = async () => {
    try {
      const state = await Services.globalService.getAllState();
      if (state.status === 200) {
        setStateList(state.data.data);
      }
    } catch (error) {}
  };
  const getLocality = async (id) => {
    try {
      const locality = await Services.globalService.getLocalityByCity(id);
      if (locality.status === 200) {
        setLocalityData(locality.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getState();
  }, []);
  const getCity = async (id) => {
    try {
      const state = await Services.globalService.getAllCitiesByState(id);
      if (state.status === 200) {
        setCityList(state.data.data);
      }
    } catch (error) {}
  };
  const { city, state } = watch();

  useEffect(() => {
    if (state && show) {
      getCity(state);
    }
  }, [state]);

  useEffect(() => {
    if (city && show) {
      getLocality(city);
    }
  }, [city]);

  const searchfilter = (query) => {
    let text = query?.toLowerCase();
    const results = propertyDataObj[activeTab].filter((item) => {
      if (text == "") return propertyDataObj[activeTab];

      return (
        // item?.stateId?.toLowerCase()?.includes(text) ||
        item?.city?.name?.toLowerCase()?.includes(text) ||
        item?.name?.toLowerCase()?.includes(text) ||
        item?.propertyCode?.toLowerCase()?.includes(text) ||
        item?.noOfRooms?.toString()?.includes(text)
      );
    });
    setShowData(results);
  };

  useEffect(() => {
    setShowData(propertyDataObj[activeTab]);
  }, [activeTab, propertyList]);

  return (
    <Layout
      title="RRooms Properties"
      openHandler={() => setShow(true)}
      searchfilter={searchfilter}
      searchType="search"
    >
      <div className="onboardDetails">
        <div className="wrap">
          <div className="inner">
            <div className="flex justify-content-between">
              <Tabs
                className="tabs mb-2"
                activeKey={activeTab}
                defaultActiveKey={PROPERTY_STATUS.UPCOMING}
                onSelect={(tabKey) => setActiveTab(tabKey)}
              >
                {propertyStatusTabs.map((tab, ind) => {
                  return <Tab eventKey={tab.key} key={ind} title={tab.title} />;
                })}
              </Tabs>
              <div>
                <button
                  onClick={onDownload}
                  className="btn-success btn-sm fw-normal mr-2"
                >
                  Export Table
                </button>
              </div>
            </div>
            <div className="scrollTable">
              <table className="table-hover common-table" ref={tableRef}>
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Property Code</th>
                    <th style={{ minWidth: 150 }}>Property Name</th>
                    <th>Onboard date</th>
                    <th>No. of Rooms</th>
                    <th>Location</th>
                    <th>Contract Acceptance</th>
                    <th>Approval Status</th>
                    <th>Property Status</th>
                    <th style={{ minWidth: 250 }}>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyDataObj[activeTab]?.length > 0 ? (
                    showData?.map((data, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{data.propertyCode ?? "N/A"}</td>
                        <td>
                          <Link to={`/property-details/${data.id}`}>
                            {data.name}
                          </Link>
                        </td>
                        <td>
                          <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                        </td>
                        <td>{data.noOfRooms}</td>
                        {/* <td>{data.city?.name}</td> */}
                        <td>{data.locality}</td>
                        <td
                          className={`text-center
                            ${
                              data.PropertyUser?.agreement
                                ? "text-success"
                                : "text-danger"
                            }
                          `}
                        >
                          {data.PropertyUser?.agreement
                            ? "Accepted"
                            : "Pending"}
                        </td>
                        <td
                          className={
                            data.approved == 1
                              ? "text-success"
                              : data.approved == 2
                              ? "text-danger"
                              : "text-warning"
                          }
                        >
                          {data.approved == 1
                            ? "Approved"
                            : data.approved == 2
                            ? "Rejected"
                            : "Pending"}
                        </td>
                        <td
                          className={
                            data.status == 1 ? "text-success" : "text-danger"
                          }
                        >
                          {data.status == 1 ? "Active" : "Inactive"}
                        </td>
                        <td>
                          <div className="button-group">
                            <Button
                              variant="primary"
                              className="btn-sm mr-2"
                              onClick={(e) => {
                                if (window.innerHeight <= e.clientY + 190) {
                                  setTopValueForEditList(e.clientY - 200);
                                } else {
                                  setTopValueForEditList(e.clientY);
                                }
                                setEditDropDown(
                                  key === editDropDown ? -1 : key
                                );
                              }}
                            >
                              <i
                                style={{ pointerEvents: "none" }}
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </Button>
                            {data.approved == 2 && (
                              <Button
                                variant="primary"
                                className="btn-sm mr-2"
                                onClick={() => reApprove(data?.id)}
                              >
                                Re-Approval
                              </Button>
                            )}

                            {data.approved != 2 ? (
                              <>
                                <Button
                                  variant="primary"
                                  className="btn-sm mr-2"
                                  onClick={() => {
                                    setShowDelete(true);
                                    setSelectedData(data);
                                  }}
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-sm"
                                  onClick={() => changeStatus(data)}
                                >
                                  Change Status
                                </Button>
                              </>
                            ) : null}
                            {editDropDown === key && (
                              <>
                                <div
                                  className="editDrop"
                                  style={{
                                    top: `${topValueForEditList + 15}px`,
                                  }}
                                >
                                  <button
                                    className="btn p-0 m-0 position-absolute end-0 p-2 top-0"
                                    onClick={() => setEditDropDown(-1)}
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-circle-xmark"
                                      size="xl"
                                    />
                                  </button>
                                  <Button
                                    className="btn-sm mt-3"
                                    onClick={() => editHandler(data, 1)}
                                  >
                                    Edit Property Details
                                  </Button>
                                  <hr />
                                  <Button
                                    className="btn-sm"
                                    onClick={() => editHandler(data, 2)}
                                  >
                                    Edit Images
                                  </Button>
                                  <hr />
                                  <Button
                                    className="btn-sm"
                                    onClick={() => editHandler(data, 3)}
                                  >
                                    Edit Legal Entity
                                  </Button>
                                </div>
                                <div
                                  className="position-fixed bg-dark w-100 vh-100 top-0 start-0 "
                                  style={{ zIndex: 9999, opacity: 0.4 }}
                                ></div>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <div
                          className="flex flex-column align-center justify-content-center full-width"
                          style={{ height: "10rem" }}
                        >
                          <FontAwesomeIcon
                            className="mb-2"
                            icon="fa-solid fa-ban"
                            style={{ width: "40px", height: "40px" }}
                            beat
                          />
                          <span>No Data</span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="deleteModal">
              <span>Are you sure? Do you want to delete this record?</span>
              <span>You will not be able to recover this record!</span>
            </div>
            <Button
              variant="primary"
              className="btn-sm"
              type="button"
              onClick={deleteHandler}
            >
              Yes , Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        size="xl"
        className="modal_add_property"
        onShow={() => {
          editMode ? setStep(step) : setStep(1);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Property" : "Property Onboarding"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!editMode && (
            <Row
              xs={12}
              className="gap-5 align-items-center justify-content-center flex"
            >
              <div className="flex gap-1 align-items-center justify-content-center w-25">
                <span>Step:</span>
                <div
                  className={`stepOnboard ${step === 1 ? "" : "bg-secondary"}`}
                >
                  {(step === 2 || step === 3) && (
                    <FontAwesomeIcon
                      icon="fa-solid fa-check stepicon"
                      size="xl"
                      color="#000"
                    />
                  )}
                  <p>1</p>
                </div>
              </div>
              <div className="flex gap-1 align-items-center justify-content-center w-25">
                <span>Step:</span>
                <div
                  className={`stepOnboard ${step === 2 ? "" : "bg-secondary"}`}
                >
                  {step === 3 && (
                    <FontAwesomeIcon
                      icon="fa-solid fa-check stepicon"
                      size="xl"
                      color="#000"
                    />
                  )}
                  <p>2</p>
                </div>
              </div>
              <div className="flex gap-1 align-items-center justify-content-center w-25">
                <span>Step:</span>
                <div
                  className={`stepOnboard ${step === 3 ? "" : "bg-secondary"}`}
                >
                  <p>3</p>
                </div>
              </div>
            </Row>
          )}
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              {step === 1 && (
                <Row>
                  <Col xs={12} className="form-group">
                    <h6>Property Detail</h6>
                  </Col>

                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="propertyName"
                      text="Property Name *"
                      type="text"
                      placeholder="Enter property name"
                      field={"propertyName"}
                      register={register}
                      errors={errors}
                      autoFocus={true}
                    />
                    {errors?.propertyName?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"propertyName"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>Category</label>
                    <Controller
                      control={control}
                      name={"firmType"}
                      render={({ field: { onChange, value } }) => (
                        <select value={value} onChange={onChange}>
                          <option selected value={""} disabled>
                            Select
                          </option>
                          {categoryData.map((innerData, key) => (
                            <option key={key} value={innerData.id}>
                              {innerData.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.firmType?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"firmType"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="noOfRooms"
                      text="Number Of Rooms"
                      type="number"
                      placeholder="Number Of Rooms"
                      field={"noOfRooms"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.noOfRooms?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"noOfRooms"} />
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col xs={12} className="form-group">
                    <label>Property Description</label>
                    <textarea
                      register={register}
                      {...register("propertyDescription")}
                      placeholder={"Enter property description"}
                    ></textarea>
                    {errors?.propertyDescription?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage
                            errors={errors}
                            name={"propertyDescription"}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="latitude"
                      text="Latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      field={"latitude"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.latitude?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"latitude"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="longitude"
                      text="Longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      field={"longitude"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.longitude?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"longitude"} />
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="propertyEmail"
                      text="Property Email"
                      type="text"
                      placeholder="Enter Property Email"
                      field={"propertyEmail"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.propertyEmail?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage
                            errors={errors}
                            name={"propertyEmail"}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="propertyPhone"
                      text="Property Mobile"
                      type="text"
                      placeholder="Enter Property Mobile No."
                      field={"propertyPhone"}
                      register={register}
                      errors={errors}
                      max={10}
                    />
                    {errors?.propertyPhone?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage
                            errors={errors}
                            name={"propertyPhone"}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="propertyLandmark"
                      text="Property Landmark"
                      type="text"
                      placeholder="Enter Property Landmark"
                      field={"propertyLandmark"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.propertyLandmark?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage
                            errors={errors}
                            name={"propertyLandmark"}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>State</label>
                    <Controller
                      control={control}
                      name={"state"}
                      render={({ field: { onChange, value } }) => (
                        <select onChange={onChange} value={value}>
                          <option selected disabled>
                            Select State
                          </option>
                          {stateList?.map((data, key) => (
                            <option key={key} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>City</label>
                    <Controller
                      control={control}
                      name={"city"}
                      render={({ field: { onChange, value } }) => (
                        <select onChange={onChange} value={value}>
                          <option selected disabled>
                            Select City
                          </option>
                          {cityList.map((data, key) => (
                            <option key={key} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.city?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"city"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>Locality</label>
                    <Controller
                      control={control}
                      name={"Locality"}
                      render={({ field: { onChange, value } }) => (
                        <select value={value} onChange={onChange}>
                          <option selected value={""} disabled>
                            Select
                          </option>
                          {localityData.map((innerData, key) => (
                            <option key={key} value={innerData.name}>
                              {innerData.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.Locality?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"Locality"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="form-group">
                    <InputField
                      htmlFor="postalCode"
                      text="Postal Code"
                      type="text"
                      placeholder="Enter Postal Code"
                      field={"postalCode"}
                      register={register}
                      errors={errors}
                      max={6}
                    />
                    {errors?.postalCode?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"postalCode"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} className="form-group">
                    <label>Property Address *</label>
                    <textarea
                      register={register}
                      {...register("propertyAddress")}
                    ></textarea>
                    {errors?.propertyAddress?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage
                            errors={errors}
                            name={"propertyAddress"}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12}>
                    <div className="form-group full">
                      <label>Amenities</label>
                      <ul className="flex">
                        {amenitiesData.map((c, i) => (
                          <li key={i} className="amns">
                            <input
                              id={`amn${i}`}
                              type="checkbox"
                              value={c.id}
                              checked={selectedAminites.includes(c.id)}
                              {...register("amenities", {
                                onChange: (e) => changeHandler(e, c),
                              })}
                            />

                            <label
                              htmlFor={`amn${i}`}
                              className={
                                selectedAminites.includes(c.id)
                                  ? `activeLable`
                                  : ""
                              }
                            >
                              {c.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                      {errors?.amenities?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>Please Select Amenities*</span>
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col xs={12} className="form-group">
                    <label htmlFor="bookingPolicy">Hotel Policy</label>
                    <textarea
                      register={register}
                      {...register("bookingPolicy")}
                      placeholder={"Enter Hotel Policy"}
                      style={{ height: "auto" }}
                    ></textarea>

                    {errors?.bookingPolicy?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage
                            errors={errors}
                            name={"bookingPolicy"}
                          />
                        </span>
                      </div>
                    )}
                  </Col>
                  {propertyPolicy.map((item, index) => (
                    <Col
                      xs={12}
                      className="flex align-items-center mb-1"
                      key={index}
                    >
                      <input
                        onChange={(e) => setPolicyHandler(e.target)}
                        type="checkbox"
                        id={`policy${index}`}
                        value={index}
                        name={`policy${index}`}
                        checked={policyText.includes(index.toString())}
                      />
                      <label
                        className="ml-2 m-0 text-black"
                        htmlFor={`policy${index}`}
                      >
                        {item}
                      </label>
                    </Col>
                  ))}
                  <Col xs={12} className="form-group">
                    <p>Owner Details</p>
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="firstName"
                      text="First Name"
                      type="text"
                      placeholder="Enter first name"
                      field={"firstName"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.firstName?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"firstName"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="lastName"
                      text="Last Name"
                      type="text"
                      placeholder="Enter Last name"
                      field={"lastName"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.lastName?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"lastName"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="panNumber"
                      text="PAN No.*"
                      type="text"
                      placeholder="Enter PAN number"
                      field={"panNumber"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.panNumber?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"panNumber"} />
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="adharNumber"
                      text="Adhar No. *"
                      type="text"
                      placeholder="Enter adhar number"
                      field={"adharNumber"}
                      register={register}
                      errors={errors}
                      max={12}
                    />
                    {errors?.adharNumber?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"adharNumber"} />
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="phoneNumber"
                      text="Contact No. *"
                      type="text"
                      placeholder="Enter phone number"
                      field={"phoneNumber"}
                      register={register}
                      errors={errors}
                      max={10}
                    />
                    {errors?.phoneNumber?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"phoneNumber"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="email"
                      text="Email ID"
                      type="email"
                      placeholder="Enter email address"
                      field={"email"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.email?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"email"} />
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col xs={12}>
                    <div className="form-group full">
                      <label>Traveller Choice</label>
                      <ul className="flex">
                        <li className="amns">
                          <input
                            type="checkbox"
                            id="Couple"
                            checked={Couple}
                            onChange={() => setCouple(!Couple)}
                          />
                          <label
                            className={`checkLable ${
                              Couple ? "activeLable" : ""
                            }`}
                            htmlFor="Couple"
                          >
                            Couple Friendly
                          </label>
                        </li>

                        <li className="amns">
                          <input
                            type="checkbox"
                            id="Local"
                            checked={Local}
                            onChange={() => setLocal(!Local)}
                          />
                          <label
                            className={`checkLable ${
                              Local ? "activeLable" : ""
                            }`}
                            htmlFor="Local"
                          >
                            Local ID proof Accept
                          </label>
                        </li>
                        <li className="amns">
                          <input
                            type="checkbox"
                            id="Partial"
                            checked={Partial}
                            onChange={() => setPartial(!Partial)}
                          />
                          <label
                            className={`checkLable ${
                              Partial ? "activeLable" : ""
                            }`}
                            htmlFor="Partial"
                          >
                            Partial Payment Accept
                          </label>
                        </li>

                        <li className="amns">
                          <input
                            type="checkbox"
                            id="short"
                            checked={travellerData.includes(
                              TRAVELLER_CHOICE_TEXT.short
                            )}
                            onChange={() => setTravellerHandler(1)}
                          />
                          <label
                            className={`checkLable ${
                              travellerData.includes(
                                TRAVELLER_CHOICE_TEXT.short
                              )
                                ? "activeLable"
                                : ""
                            }`}
                            htmlFor="short"
                          >
                            Short-Stays
                          </label>
                        </li>
                        <li className="amns">
                          <input
                            type="checkbox"
                            id="corporate"
                            checked={travellerData.includes(
                              TRAVELLER_CHOICE_TEXT.Corporate
                            )}
                            onChange={() => setTravellerHandler(2)}
                          />
                          <label
                            className={`checkLable ${
                              travellerData.includes(
                                TRAVELLER_CHOICE_TEXT.Corporate
                              )
                                ? "activeLable"
                                : ""
                            }`}
                            htmlFor="corporate"
                          >
                            Corporate Deal
                          </label>
                        </li>

                        <li className="amns">
                          <input
                            type="checkbox"
                            id="familyVacation"
                            checked={travellerData.includes(
                              TRAVELLER_CHOICE_TEXT.FamilyVacation
                            )}
                            onChange={() => setTravellerHandler(3)}
                          />
                          <label
                            className={`checkLable ${
                              travellerData.includes(
                                TRAVELLER_CHOICE_TEXT.FamilyVacation
                              )
                                ? "activeLable"
                                : ""
                            }`}
                            htmlFor="familyVacation"
                          >
                            Family Vacation
                          </label>
                        </li>
                        <li className="amns">
                          <input
                            type="checkbox"
                            id="checkIn"
                            checked={travellerData.includes(
                              TRAVELLER_CHOICE_TEXT.GuaranteedCheckIn
                            )}
                            onChange={() => setTravellerHandler(4)}
                          />
                          <label
                            className={`checkLable ${
                              travellerData.includes(
                                TRAVELLER_CHOICE_TEXT.GuaranteedCheckIn
                              )
                                ? "activeLable"
                                : ""
                            }`}
                            htmlFor="checkIn"
                          >
                            Guaranteed Check-In
                          </label>
                        </li>
                        <li className="amns">
                          <input
                            type="checkbox"
                            id="groupCheckIn"
                            checked={travellerData.includes(
                              TRAVELLER_CHOICE_TEXT.GroupCheckIn
                            )}
                            onChange={() => setTravellerHandler(5)}
                          />
                          <label
                            className={`checkLable ${
                              travellerData.includes(
                                TRAVELLER_CHOICE_TEXT.GroupCheckIn
                              )
                                ? "activeLable"
                                : ""
                            }`}
                            htmlFor="groupCheckIn"
                          >
                            Group Check-In
                          </label>
                        </li>
                        <li className="amns">
                          <input
                            type="checkbox"
                            id="solo"
                            checked={travellerData.includes(
                              TRAVELLER_CHOICE_TEXT.SoloTraveler
                            )}
                            onChange={() => setTravellerHandler(6)}
                          />
                          <label
                            className={`checkLable ${
                              travellerData.includes(
                                TRAVELLER_CHOICE_TEXT.SoloTraveler
                              )
                                ? "activeLable"
                                : ""
                            }`}
                            htmlFor="solo"
                          >
                            Solo Traveler
                          </label>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              )}
              {step === 2 && (
                <Row>
                  {imageData1?.length ||
                  imageData1?.length ||
                  imageData1?.length ||
                  imageData1?.length ||
                  imageData1?.length ||
                  imageData1?.length ? null : (
                    <Col>
                      <button
                        type="button"
                        className={`btn ${
                          activeImageState ? "btn-success" : "btn-danger"
                        }`}
                        style={{
                          padding: 3,
                          fontSize: ".85rem",
                          marginTop: -20,
                        }}
                        onClick={
                          activeImageState
                            ? changeHeroImg
                            : () => setActiveImageState(!activeImageState)
                        }
                      >
                        {activeImageState
                          ? "Save Profile Image"
                          : "Choose Profile Image"}
                      </button>
                    </Col>
                  )}
                  <Col xs={12}>
                    <ImageUploadBox
                      activeImageState={activeImageState}
                      setProfileImg={setProfileImg}
                      profileImgId={profileImg}
                      heading="Facade"
                      imageHandler={imageHandler1}
                      selectedImages={imageSet1}
                      deleteHandler={deleteImageHandler}
                      htmlFor={"file1"}
                    />
                    {imageData1?.length + imageSet1.length <= 0 ||
                      (imageData1?.length + imageSet1.length > 5 && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>Please Select Images min 1 & max 5*</span>
                        </div>
                      ))}
                  </Col>
                  <Col xs={12}>
                    <ImageUploadBox
                      activeImageState={activeImageState}
                      setProfileImg={setProfileImg}
                      profileImgId={profileImg}
                      heading="Lobby"
                      imageHandler={imageHandler2}
                      selectedImages={imageSet2}
                      deleteHandler={deleteImageHandler}
                      htmlFor={"file2"}
                    />
                    {imageData2?.length + imageSet2.length <= 0 ||
                      (imageData2?.length + imageSet2.length > 5 && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>Please Select Images min 1 & max 5*</span>
                        </div>
                      ))}
                  </Col>
                  <Col xs={12}>
                    <ImageUploadBox
                      activeImageState={activeImageState}
                      setProfileImg={setProfileImg}
                      profileImgId={profileImg}
                      heading="Reception"
                      imageHandler={imageHandler3}
                      selectedImages={imageSet3}
                      deleteHandler={deleteImageHandler}
                      htmlFor={"file3"}
                    />
                    {imageData3?.length + imageSet3.length <= 0 ||
                      (imageData3?.length + imageSet3.length > 5 && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>Please Select Images min 1 & max 5*</span>
                        </div>
                      ))}
                  </Col>
                  <Col xs={12}>
                    <ImageUploadBox
                      activeImageState={activeImageState}
                      setProfileImg={setProfileImg}
                      profileImgId={profileImg}
                      heading="Room"
                      imageHandler={imageHandler4}
                      selectedImages={imageSet4}
                      deleteHandler={deleteImageHandler}
                      htmlFor={"file4"}
                    />
                    {imageData4?.length + imageSet4.length <= 0 ||
                      (imageData4?.length + imageSet4.length > 5 && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>Please Select Images min 1 & max 5*</span>
                        </div>
                      ))}
                  </Col>
                  <Col xs={12}>
                    <ImageUploadBox
                      activeImageState={activeImageState}
                      setProfileImg={setProfileImg}
                      profileImgId={profileImg}
                      heading="Washroom"
                      imageHandler={imageHandler5}
                      selectedImages={imageSet5}
                      deleteHandler={deleteImageHandler}
                      htmlFor={"file5"}
                    />
                    {imageData5?.length + imageSet5.length <= 0 ||
                      (imageData5?.length + imageSet5.length > 5 && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>Please Select Images min 1 & max 5*</span>
                        </div>
                      ))}
                  </Col>
                  <Col xs={12}>
                    <ImageUploadBox
                      activeImageState={activeImageState}
                      setProfileImg={setProfileImg}
                      profileImgId={profileImg}
                      heading="Others"
                      imageHandler={imageHandler6}
                      selectedImages={imageSet6}
                      deleteHandler={deleteImageHandler}
                      htmlFor={"file6"}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col
                  xs={12}
                  className="form-group justify-content-between flex"
                >
                  {step === 1 && (
                    <Button
                      variant="primary"
                      type="submit"
                      className={step === 1 && "align-self-end"}
                    >
                      {editMode ? "Update" : "Next"}
                    </Button>
                  )}
                  {step === 2 && (
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        if (
                          imageData1.length + imageSet1.length <= 5 &&
                          imageData1.length + imageSet1.length > 0 &&
                          imageData2.length + imageSet2.length <= 5 &&
                          imageData2.length + imageSet2.length > 0 &&
                          imageData3.length + imageSet3.length <= 5 &&
                          imageData3.length + imageSet3.length > 0 &&
                          imageData4.length + imageSet4.length <= 5 &&
                          imageData4.length + imageSet4.length > 0 &&
                          imageData5.length + imageSet5.length <= 5 &&
                          imageData5.length + imageSet5.length > 0
                        )
                          onSubmit();
                        else
                          toast.error("Select Images In all Categories!", {
                            autoClose: 1000,
                            pauseOnHover: true,
                            position: "top-right",
                          });
                      }}
                    >
                      {editMode ? "Update" : "Next"}
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Form>
          {step === 3 && (
            <Step3
              data={{
                step: step,
                setStep: setStep,
                setShow: setShow,
                resetData: getPropertyCategory,
                selectedData: selectedData,
                editMode: editMode,
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Property;
