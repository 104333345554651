import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

const About = () => {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  return (
    <div className="hotalList">
      {/* landing page header */}
      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>
      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>About RRooms</h1>
          <h2>Introduction</h2>
          <p>
            Welcome to RRooms, your gateway to seamless travel experiences
            around the world. With its extensive reach, user-friendly interface,
            and commitment to excellence, RRooms offers hoteliers a reliable and
            efficient channel to connect with a diverse range of travelers. This
            brief highlights the key reasons why RRooms is the first choice for
            hoteliers in India for guest bookings, emphasizing its benefits and
            advantages for hotel partners.
          </p>
          <h2>Our Mission</h2>
          <p>
            RRooms aims to establish itself as the first choice for hoteliers in
            India when it comes to guest bookings. RRooms offers hotel partners
            the opportunity to connect with a vast customer base and increase
            their bookings. With RRooms, hoteliers can benefit from enhanced
            exposure, competitive visibility, and a seamless booking process,
            making it an invaluable partner in the ever-growing Indian
            hospitality Industry
          </p>
          <p>
            Our mission is clear: to empower travelers & hoteliers with the
            tools and information they need to make informed decisions about
            their guests & their stays. We believe that where you stay should be
            an integral part of your journey, and finding the perfect hotel
            should be an exciting part of the adventure.
          </p>
          <h1>What Sets Us Apart</h1>
          <h2>Extensive Network and Market Reach:</h2>
          <p>
            RRooms boasts an extensive network and a wide market reach,
            attracting a diverse pool of travelers seeking accommodation in
            India. By partnering with RRooms, hoteliers gain access to this vast
            customer base, increasing their visibility and enhancing the chances
            of attracting potential guests.
          </p>
          <h2>User Friendly Booking Platform</h2>
          <p>
            RRooms provides hoteliers with a user-friendly and intuitive booking
            platform that simplifies the process of managing and updating their
            hotel inventory. Hoteliers can easily list their properties, update
            availability, and manage bookings, ensuring a seamless experience
            while maximizing their occupancy rates. Our platform is designed
            with you (customer) in mind. It's easy to navigate, and packed with
            useful features to simplify your search for the ideal hotel.
          </p>
          <h2>Competitive Exposure and Visibility:</h2>
          <p>
            RRooms offers hoteliers competitive exposure and visibility in the
            Indian hospitality market. By featuring properties prominently on
            the platform, RRooms ensures that hotel partners can showcase their
            unique offerings, amenities, and competitive pricing to a wide
            audience of potential guests.
          </p>
          <h2>Targeted Marketing and Promotional Opportunities:</h2>{" "}
          <p>
            RRooms understands the importance of targeted marketing and
            promotion for hoteliers. Through its marketing initiatives, RRooms
            actively promotes partner hotels, leveraging various channels to
            increase their visibility and attract relevant travelers. Hoteliers
            benefit from enhanced exposure and increased bookings through these
            targeted marketing efforts.
          </p>
          <h2>Flexible Booking Policies:</h2>{" "}
          <p>
            RRooms recognizes the importance of flexibility in booking policies
            for hoteliers. The platform allows hotel partners to define their
            own cancellation policies, room rates, and other terms, providing
            them with the flexibility to tailor their offerings to their
            specific requirements and market dynamics. We provide detailed and
            accurate information about each property, including photos,
            amenities, guest reviews, and real-time pricing. You can trust that
            what you see is what you get.
          </p>
          <h2>Secure and Reliable Payment Processing:</h2>{" "}
          <p>
            RRooms ensures secure and reliable payment processing for hoteliers,
            providing them with peace of mind when it comes to financial
            transactions. The platform handles the payment process efficiently,
            ensuring prompt remittances and minimizing the risk of
            payment-related issues.
          </p>
          <h2>Dedicated Support and Account Management:</h2>{" "}
          <p>
            RRooms offers dedicated support and account management for its hotel
            partners. The platform provides a responsive and knowledgeable
            support team to assist hoteliers with any queries, technical issues,
            or general assistance they may require, ensuring a smooth and
            efficient partnership experience.
          </p>
          <h2>Best Deals:</h2>{" "}
          <p>
            {" "}
            We work tirelessly to secure the best deals and exclusive discounts
            for our users, helping you get the most value out of your travel
            budget.
          </p>
          <h2>Extensive Selection:</h2>
          <p>
            {" "}
            We offer an extensive selection of hotels, from budget-friendly
            options to opulent retreats, ensuring there's something for every
            traveler and every occasion.
          </p>
          <h2>24/7 Support:</h2>
          <p>
            {" "}
            Our dedicated customer support team is available around the clock to
            assist you with any inquiries, reservations, or concerns you may
            have. Join us in exploring the world of opportunities. Let's
            redefine the hospitality industry together. Let us be your trusted
            companion on your next adventure. Thank you for choosing RRooms.{" "}
          </p>
        </div>
      </section>
      {/* content section end here */}
      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default About;
