import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Moment from "react-moment";
import { Rating } from "@smastrom/react-rating";
import ReactGA from "react-ga4";

const CustomerReviews = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "customer_ratings" });
    window.scrollTo(0, 0);
    getReview(userDetail?.propertyId);
  }, [userDetail]);

  const [tableData, setTableData] = useState([]);

  const getReview = async (id) => {
    try {
      const res =
        await services.globalService.getCustomerReviewPropertyIdService(id);

      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  return (
    <Layout title="Customer Reviews List" openHandler={false}>
      <Row className="mx-3 reviewAdminSection mt-2">
        {tableData.map((data, key) => {
          let arr = new Array(data.rating);
          arr.fill(1);
          return (
            data?.userId && (
              <div className="reviewCard" key={key}>
                <div className="comment">
                  <div className="comment_header flex justify-content-between mb-2">
                    <div className="comment_header m-0">
                      <div className="comment_header-pic"></div>
                      <h2>{data?.User?.name ?? "N/A"}</h2>
                    </div>
                    <div>
                      <Rating
                        style={{ maxWidth: 140 }}
                        value={data.rating}
                        readOnly
                      />
                      <div className="comment_footer flex flex-column align-items-end">
                        {data.createdAt && (
                          <span>
                            <Moment format="DD/MM/YYYY">
                              {data.createdAt}
                            </Moment>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="comment_content mt-0 ml-5 text-justify">
                    <p>{data.review}</p>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </Row>
    </Layout>
  );
};

export default CustomerReviews;
