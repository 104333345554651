import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import Services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import ReactGA from "react-ga4";

let foodTax = 2.5;
const STORY_HEADERS = [
  {
    prop: "id",
    title: "Order ID",
    isFilterable: true,
    isSortable: true,
  },
  {
    prop: "BookingHotel[bookingCode]",
    title: "Booking ID",
    cell: (row) => `#${row?.BookingHotel?.bookingCode ?? "N/A"}`,
    isFilterable: true,
    isSortable: true,
  },
  {
    prop: "orderAmount",
    title: "Amount",
    isFilterable: true,
    isSortable: true,
    cell: (row) =>
      `₹${(
        row?.orderAmount -
        row?.orderAmount * ((foodTax + foodTax) / 100)
      )?.toFixed(2)}`,
  },
  {
    prop: "ncType",
    title: "NC Type",
    isSortable: true,
    isFilterable: true,
    cell: (row) => (row?.ncType == 1 ? "YES" : "NO"),
  },
  {
    prop: "cgst",
    title: "CGST",
    isFilterable: true,
    isSortable: true,
    cell: (row) => `₹${((row?.orderAmount * foodTax) / 100)?.toFixed(2)}`,
  },
  {
    prop: "sgst",
    title: "SGST",
    isSortable: true,
    isFilterable: true,
    cell: (row) => `₹${((row?.orderAmount * foodTax) / 100)?.toFixed(2)}`,
  },
  {
    prop: "orderAmount",
    title: "Total",
    isSortable: true,
    isFilterable: true,
    cell: (row) => `₹${row?.orderAmount}`,
  },
];

// Then, use it in a component.
export default function FoodReport() {
  const { userDetail } = useSelector(authenticationSeletor);
  const [tableData, setTableData] = useState([]);
  const [exportFilter, setExportFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const getDataHandler = async () => {
    try {
      const res = await Services.property.getFoodOrdersByProperty(
        userDetail?.propertyId
      );
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "food_reporting" });
    getDataHandler();
  }, []);
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Food_Orders_Details",
    sheet: "Food_Orders_Details",
  });

  const exportHandler = async () => {
    window.open(
      `https://api.rrooms.in/api/kpi/food-order-report?fromDate=${moment(
        exportFilter.startDate
      ).format("YYYY-MM-DD")}&toDate=${moment(exportFilter.endDate).format(
        "YYYY-MM-DD"
      )}&propertyId=${userDetail?.propertyId}`,
      "_blank"
    );
  };
  return (
    <Layout
      title="Food Report"
      extraBtnHandler={exportHandler}
      extraButton="Export Table"
      setFromDate={(date) =>
        setExportFilter({ ...exportFilter, startDate: date })
      }
      searchfilter={true}
      setToDate={(date) => setExportFilter({ ...exportFilter, endDate: date })}
      dateFilter={exportFilter}
      searchType={"date"}
      fromDate={exportFilter.startDate}
      toDate={exportFilter.endDate}
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 15,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <Table className="food-report" ref={tableRef}>
            <TableHeader />
            <TableBody />
          </Table>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
    </Layout>
  );
}
