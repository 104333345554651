import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getInvoiceById } from "API/services/newServices";
import { paymentModes } from "constant/constants";
import Layout from "layout";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { showPrice } from "utils/utils";

function InvoicePayment() {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [showProof, setShowProof] = useState(false);
  const navigate = useNavigate();

  // param.id
  const getInvoiceData = async () => {
    const res = await getInvoiceById(id);
    if (res) {
      setSelectedItem(res.data.invoice_detail);
    }
  };

  const hideProof = () => {
    setShowProof(false);
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  return (
    <Layout title={"Hotel Invoice Payment"}>
      <Container>
        <Row>
          <div className="mt-4 d-flex justify-content-center align-items-center">
            <div>
              <div className="mb-4 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="75"
                  height="75"
                  fill="currentColor"
                  className="bi bi-check-circle-fill text-success"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </div>
              <div>
                <h1 className="text-center">Thank You !</h1>
                <p className="text-center">
                  We've send the link to your inbox. Lorem ipsum dolor sit,{" "}
                </p>
                <Container className="invoicePaymentDetails">
                  <Row>
                    <Col>Invoice Number:</Col>
                    <Col className="text-end text-primary">
                      #{selectedItem.invoice_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Amount:</Col>
                    <Col className="text-end">
                      {showPrice(selectedItem.totalPayableAmount)}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Transaction ID:</Col>
                    <Col className="text-end">
                      {selectedItem.merchantTransactionId ?? "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Payment Mode:</Col>
                    <Col className="text-end text-success text-capitalize">
                      {selectedItem.paymentSource != "ONLINE" ? (
                        <FontAwesomeIcon icon="fa-solid fa-money-check" />
                      ) : (
                        <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" />
                      )}{" "}
                      {selectedItem.paymentSource}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Payment Source:</Col>
                    <Col className="text-end">
                      {paymentModes[selectedItem.paymentMode]}
                    </Col>
                  </Row>
                  <Row>
                    <Col>Payment Status:</Col>
                    <Col className="text-end text-danger text-capitalize">
                      {selectedItem.paymentStatus}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>Payment Proof:</Col>
                    <Col className="text-end">
                      <Button
                        className="btn-sm"
                        onClick={() => {
                          setShowProof(true);
                        }}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-eye" /> Show
                      </Button>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>Payment Date:</Col>
                    <Col className="text-end">
                      {moment(selectedItem.createdAt).format(
                        "DD-MM-YYYY HH:MM A"
                      )}
                    </Col>
                  </Row>
                </Container>
                <Col className="d-flex justify-content-center">
                  <button
                    onClick={() => navigate("/hotel-finance/invoice")}
                    className="btn btn-primary mt-4"
                  >
                    Back to Invoice Listing
                  </button>
                </Col>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Modal
        size="md"
        show={showProof}
        onHide={hideProof}
        aria-labelledby="contained-modal-title-vcenter"
        className="checkInModal"
        backdropClassName="bg-dark"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Proof Image
          </Modal.Title>
          <button className="closeButton" onClick={hideProof}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container className="invoicePaymentDetails">
            <img alt="" src={selectedItem.documentUrl} alt="" />
          </Container>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default InvoicePayment;
