import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

export const InputField = (props) => {
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const {
    value,
    text,
    htmlFor,
    field,
    autoFocus,
    register = () => {},
    type,
    placeholder,
    disabled = false,
    max,
    customClass,
    min,
    notnull = false,
    options = [],
    serchable = false,
    control,
    hideLable,
    minVal,
    required = false,
    readOnly = false,
  } = props;

  return (
    <>
      {text && !hideLable && (
        <label htmlFor={htmlFor} className="label form-label">
          {text}{" "}
          {notnull && <small className="text-danger fw-bold fs-6">*</small>}
        </label>
      )}
      <div
        className="inputWrap mb24"
        style={{ cursor: disabled ? "not-allowed" : "default" }}
      >
        {type === "options" && serchable ? (
          <Controller
            control={control}
            name={field}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <>
                  <Select
                    options={options}
                    placeholder={text}
                    isSearchable
                    value={value}
                    onBlur={onBlur}
                    ref={ref}
                    onChange={onChange}
                  />
                </>
              );
            }}
          />
        ) : type === "textArea" ? (
          <textarea
            id={htmlFor}
            rows={5}
            value={value}
            className={`formControl h-100 border textarea ${
              customClass ? customClass : ""
            }`}
            {...register(field)}
            placeholder={placeholder}
          ></textarea>
        ) : type === "options" ? (
          <select {...register(field)}>
            <option selected value="">
              Select {text}
            </option>
            {options.map((item, ind) => (
              <option key={ind} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            value={value}
            className={`formControl ${customClass ? customClass : ""} border`}
            autoComplete="off"
            disabled={disabled}
            autoFocus={autoFocus}
            type={type === "password" ? passwordType : type}
            placeholder={placeholder}
            {...register(field)}
            maxLength={max}
            minLength={min}
            min={minVal}
            required={required}
            readOnly={readOnly}
          />
        )}
        {type === "password" && (
          <span className="p-viewer" onClick={togglePassword}>
            {passwordType === "password" ? (
              <i className="fa fa-eye-slash eye-icon" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye eye-icon" aria-hidden="true"></i>
            )}
          </span>
        )}
      </div>
    </>
  );
};
