import { ErrorMessage } from "@hookform/error-message";
import { Imagesdata } from "assets/images/imageData";
import { InputField } from "components/InputField/InputField";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Form, Button } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addMenu,
  addModule,
  deleteMenu,
  deleteModule,
  getModules,
} from "API/services/newServices";
import { toast } from "react-toastify";
import { createOptions } from "utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validation = Yup.object().shape({
  subModuleName: Yup.string().required("Please enter Sub Module name*"),
  url: Yup.string().required("Please enter web Route URL*"),
  appUrl: Yup.string().required("Please enter App Route*"),
  Icon: Yup.string().required("Please enter Icon Class*"),
});
const validationGroup = Yup.object().shape({
  module: Yup.string().required("Please enter Module Group name*"),
  Icon: Yup.string().required("Please enter Icon Class*"),
});

const schema = Yup.object({
  module: Yup.object().required("Please Select Module name*"),
  subModules: Yup.array().of(validation),
});

const rule1 = { resolver: yupResolver(schema) };
const rule2 = { resolver: yupResolver(validationGroup) };

const ModuleConfigurationAdmin = () => {
  const { AlertIcon } = Imagesdata;
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [rule, setRule] = useState(rule1);
  const [groupOption, setGroupOption] = useState([]);
  const [selected, setSelected] = useState([]);
  const {
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    ...rule,
    defaultValues: {
      subModules: [{ subModuleName: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "subModules",
    control,
  });
  const onHide = () => {
    setShow(false);
    remove([]);
    reset();
    setValue("module", null);
  };
  const onHide2 = () => {
    setShow2(false);
    reset();
  };

  const getDataHandler = async () => {
    const param = { page: 0, size: 100 };
    const res = await getModules(param);
    if (res) {
      setGroupOption(createOptions(res, "groupName", "id"));
      setTableData(res);
    }
  };

  const onSubmit = async (payload) => {
    const param = {
      editMode,
      id: selected.id,
    };
    if (show2) {
      param.body = {
        name: payload.module,
        icon: payload.Icon,
      };
      const res = await addModule(param);
      if (res) {
        onHide2();
        getDataHandler();
      }
    } else {
      let count = 0;
      payload.subModules.map(async (menu) => {
        param.body = {
          group_id: payload.module.value,
          menu_name: menu.subModuleName,
          icon: menu.Icon,
          web_route: menu.url,
          app_route: menu.appUrl,
        };
        const res = await addMenu(param);
        if (res) {
          count++;
        }
        if (count == payload.subModules.length) {
          onHide();
          getDataHandler();
        }
      });
    }
    setEditMode(false);
  };
  const deleteModuleGroup = async (id) => {
    if (!window.confirm("Are you sure want to delete!")) {
      return;
    }
    const res = await deleteModule(id);
    if (res) {
      toast.success("Group Deleted Successfull");
      getDataHandler();
    }
  };
  const deleteMenuItem = async (id) => {
    if (!window.confirm("Are you sure want to delete!")) {
      return;
    }
    const res = await deleteMenu(id);
    if (res) {
      toast.success("Module Menu Deleted Successfull");
      getDataHandler();
    }
  };
  const editHandler = (item, state = false) => {
    if (state) {
      setValue("module", item?.groupName);
      setValue("Icon", item?.icon);
      setShow2(true);
    } else {
      setValue(
        "module",
        groupOption.find((i) => i.value == item.groupId)
      );
      remove();
      append({
        subModuleName: item.menuName,
        url: item.webRoute,
        appUrl: item.appRoute,
        Icon: item.icon,
      });
      setShow(true);
    }
    setEditMode(true);
    setSelected(item);
  };
  const groupHandler = () => {
    setShow2(true);
  };
  const addMenuHandler = (id) => {
    setShow(true);
    setValue(
      "module",
      groupOption.find((i) => i.value == id)
    );
  };
  useEffect(() => {
    getDataHandler();
  }, []);
  useEffect(() => {
    if (show) {
      setRule(rule1);
    } else {
      setRule(rule2);
    }
  }, [show, show2]);

  return (
    <Layout
      title="Module Configuration"
      extraButton="Add Group"
      extraBtnHandler={groupHandler}
    >
      <Row className="flex justify-content-center">
        <ul className="Module mt-2 categoryWrap w-100">
          {!tableData.length ? (
            <div className="flex flex-column align-center justify-content-center w-100 text-black p-5">
              <FontAwesomeIcon
                className="mb-2"
                icon="fa-solid fa-ban"
                size="lg"
                beat
              />
              <span style={{ fontSize: ".8rem" }}>Menu not Available</span>
            </div>
          ) : (
            tableData.map((item, ind) => {
              return (
                <li
                  key={ind}
                  className="Module_Item mb-4 border rounded shadow-sm px-1 px-md-0"
                >
                  <label
                    htmlFor={`module${ind}`}
                    className="d-flex align-items-center justify-content-between p-1 px-2 p-md-2"
                  >
                    <input id={`module${ind}`} type="checkbox" hidden />
                    <Col
                      xs={7}
                      md={7}
                      className="d-flex gap-md-2 align-items-center gap-1 p-0"
                      style={{ fontSize: ".7rem" }}
                    >
                      <FontAwesomeIcon icon={item.icon} color="#0076FC" />{" "}
                      <span>{item?.groupName}</span>
                    </Col>
                    <Col
                      xs={4}
                      className="d-flex justify-content-end gap-2 gap-md-3 p-0"
                    >
                      <button
                        className="m-0 p-0 btn border-primary border btn-sm px-md-2 px-1"
                        style={{ fontSize: ".7rem" }}
                        onClick={() => addMenuHandler(item.id)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-plus"
                          color="#0076FC"
                        />{" "}
                        Add
                      </button>
                      <button
                        className="btn m-0 p-0"
                        onClick={() => editHandler(item, true)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square"
                          color="#0076FC"
                        />
                      </button>
                      <button
                        className="btn m-0 p-0"
                        onClick={() => deleteModuleGroup(item.id)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash-can"
                          color="red"
                        />
                      </button>
                    </Col>
                  </label>
                  <ul className="Module_inner p-1 pt-2 gap-0">
                    {item?.menues.length ? (
                      item?.menues.map((menu) => (
                        <li key={menu.id} className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={menu.icon}
                            color="#0076FC"
                            className="showIcon mx-1"
                            size="md"
                            style={{ visibility: "visible", opacity: 1 }}
                          />
                          <label
                            htmlFor={`module_sub${menu.id}`}
                            className={`border-0 p-1`}
                          >
                            <input
                              id={`module_sub${menu.id}`}
                              type="checkbox"
                              hidden
                            />
                            <div className="d-flex flex-column align-items-start">
                              <span className="text-uppercase">
                                {menu.menuName}
                              </span>
                              <small>Web: {menu.webRoute}</small>
                              <small>App: {menu.appRoute}</small>
                            </div>
                          </label>
                          <button
                            className="btn m-0 p-0"
                            onClick={() => editHandler(menu)}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-pen-to-square"
                              color="#0076FC"
                            />
                          </button>
                          <button
                            className="btn m-0 p-0"
                            onClick={() => deleteMenuItem(menu.id)}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-trash-can"
                              color="red"
                            />
                          </button>
                        </li>
                      ))
                    ) : (
                      <div className="flex flex-column align-center justify-content-center w-100">
                        <FontAwesomeIcon
                          className="mb-2"
                          icon="fa-solid fa-ban"
                          size="lg"
                          beat
                        />
                        <span style={{ fontSize: ".8rem" }}>
                          Menu not Available
                        </span>
                      </div>
                    )}
                  </ul>
                </li>
              );
            })
          )}
        </ul>
      </Row>
      <Modal show={show} onHide={onHide} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Module Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="popup-form">
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="module"
                    text="Module Name"
                    autoFocus={true}
                    placeholder="Enter Module Name"
                    field={"module"}
                    register={register}
                    errors={errors}
                    notnull
                    type="options"
                    control={control}
                    serchable
                    options={groupOption}
                  />
                  {errors?.module?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"module"} />
                      </span>
                    </div>
                  )}
                </Col>
                {fields.map((_, key) => (
                  <React.Fragment key={key}>
                    <Col xs={6} className="form-group position-relative">
                      <InputField
                        htmlFor="name"
                        text={`Sub Module ${key + 1}`}
                        type="text"
                        autoFocus={true}
                        placeholder="Enter Sub Module"
                        field={`subModules.[${key}].subModuleName`}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.subModules?.[key]?.subModuleName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`subModules.${key}.subModuleName`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group position-relative">
                      {key !== 0 && (
                        <button
                          style={{ height: 19, width: 12, right: 10 }}
                          type="button"
                          className="btn btn-sm btn-close btn-position position-absolute top-0"
                          onClick={() => remove(key)}
                        ></button>
                      )}
                      <InputField
                        htmlFor="url"
                        text={`Url Name ${key + 1}`}
                        type="text"
                        autoFocus={true}
                        placeholder="Enter Url Name"
                        field={`subModules.[${key}].url`}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.subModules?.[key]?.url?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`subModules.${key}.url`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group position-relative">
                      <InputField
                        htmlFor="appUrl"
                        text={`App route ${key + 1}`}
                        type="text"
                        autoFocus={true}
                        placeholder="Enter App route"
                        field={`subModules.[${key}].appUrl`}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.subModules?.[key]?.appUrl?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`subModules.${key}.appUrl`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group position-relative">
                      <InputField
                        htmlFor="Icon"
                        text={`Icon class Name ${key + 1}`}
                        type="text"
                        autoFocus={true}
                        placeholder="Icon class Name"
                        field={`subModules.[${key}].Icon`}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.subModules?.[key]?.url?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`subModules.${key}.Icon`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                  </React.Fragment>
                ))}
                <Col
                  xs={12}
                  className="form-group justify-content-between flex"
                >
                  {!editMode && (
                    <Button
                      variant="primary"
                      className="btn btn-sm"
                      type="button"
                      onClick={() => append({ subModuleName: "" })}
                    >
                      + Add Sub Module
                    </Button>
                  )}
                  <Button
                    variant="success"
                    type="submit"
                    className="btn btn-sm"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={onHide2} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="popup-form">
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="module"
                    text="Module Name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter Module Name"
                    field={"module"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.module?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"module"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col md={6} className="form-group">
                  <InputField
                    htmlFor="Icon"
                    text={`Icon class Name`}
                    type="text"
                    autoFocus={true}
                    placeholder="Icon class Name"
                    field={`Icon`}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.Icon?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={`Icon`} />
                      </span>
                    </div>
                  )}
                </Col>

                <Col
                  xs={12}
                  className="form-group justify-content-between flex"
                >
                  <Button
                    variant="success"
                    type="submit"
                    className="btn btn-sm"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default ModuleConfigurationAdmin;
