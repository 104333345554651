import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

function Loyalty() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>Loyality</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
            exercitationem asperiores velit sint optio, assumenda consectetur
            beatae ea nihil inventore repellendus error atque obcaecati illo
            possimus dolores minus repellat soluta dignissimos adipisci magnam
            ut aliquam? Asperiores tempore maxime vitae rerum nostrum beatae,
            iste animi dolorem distinctio provident nihil aut quia eveniet sint
            facilis repellat officiis? Vitae nemo numquam inventore, aperiam
            molestiae ipsa tempore nostrum eveniet magni alias omnis laborum
            vero aut nam nisi voluptates voluptatibus. Officia doloremque
            reprehenderit possimus nisi incidunt maiores ratione atque
            recusandae natus, nemo eius amet quaerat, eveniet optio hic ullam
            voluptatem obcaecati unde, blanditiis alias quis voluptas fugit.
            Corporis odio facere nisi similique error iste, cum, architecto ea
            quaerat, voluptates in hic magni totam inventore odit incidunt
            placeat iusto deleniti ratione amet pariatur animi. Esse obcaecati
            laudantium rem temporibus cumque hic repellendus voluptates illum ea
            dicta minus, deserunt distinctio eius non ab dolorum sed consequatur
            necessitatibus debitis error quidem totam. Maxime totam, nihil
            obcaecati alias doloremque ullam? Optio, nostrum fuga! Laborum
            aliquid sed dicta iusto optio dolor enim natus non assumenda earum
            magni fuga, repudiandae porro accusamus saepe temporibus a qui
            exercitationem. Beatae voluptatum debitis eum consequuntur nobis
            ullam tempora officia! Voluptatibus vero excepturi nam architecto.
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
}

export default Loyalty;
