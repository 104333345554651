import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../layout";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form, Row, Col } from "react-bootstrap";

import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";

const RoomPriceProperty = () => {
  const [tableData, setTableData] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  const [rooms, setRooms] = useState([]);
  const [range, setRange] = useState([]);
  const [tax, setTax] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [toDateActive, setToDateActive] = useState(false);
  const [byDate, setByDate] = useState(0);

  const getRoomTax = async () => {
    try {
      const res = await services.globalService.getTax();
      if (res.status == 200) {
        setTax(res.data?.data?.amount);
      }
    } catch (error) {}
  };

  const onSubmit = async (item, e, range) => {
    e.preventDefault();
    if (item.regularPrice == range && byDate) {
      if (
        !window.confirm(
          "your current price and new price are same, Are you sure ?"
        )
      ) {
        return;
      }
    }
    const realPayload = {
      id: item.id,
      offerPrice: Number(range),
      fromDate: byDate ? fromDate : null,
      toDate: byDate ? toDate : null,
    };
    try {
      const res = await services.property.updateRoomPrice(realPayload);
      if (res.status === 200) {
        getDataHandler();
        toast.success("Room Price Updated Successfull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  const getDataHandler = async () => {
    const fd = fromDate
      ? `&fromDate=${fromDate?.toISOString().substring(0, 10)}  00:00:00`
      : "";

    const td = toDate
      ? `&toDate=${toDate?.toISOString().substring(0, 10)}  00:00:00`
      : "";
    try {
      const res = await services.property.getRroomsPropertyByFilter(
        `/${userDetail.propertyId}?${fd}${td}`
      );

      setRooms(res.data.data.Rooms);
      setRange(res.data.data.Rooms?.map((i) => i.regularPrice));
    } catch (error) {}
  };

  const getRoomCategory = async () => {
    try {
      const res = await services.property.getRoomCategoryService();
      if (res.status === 200) {
        setTableData(res?.data?.data);
      }
    } catch (error) {}
  };

  // const getDataHandler = async () => {
  //   try {
  //     const res = await services.property.getRoomCategoryService();
  //     const rooms = await services.globalService.getRoomByPropertyIdService(
  //       userDetail?.propertyId
  //     );
  //     if (res.status === 200) {
  //       setTableData(res?.data?.data);
  //       setRooms(rooms?.data?.data);
  //       setRange(rooms?.data?.data?.map((i) => i.regularPrice));
  //     }
  //   } catch (error) {}
  // };
  const changeHandler = (e, ind) => {
    const temp = [...range];
    temp[ind] = e.target.value;
    setRange(temp);
  };

  useMemo(() => {
    getDataHandler();
  }, [fromDate, toDate, byDate]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "room_tarif" });
    window.scrollTo(0, 0);
    getRoomTax();
    getRoomCategory();
  }, []);
  return (
    <Layout title="Room Price Settings">
      <Row>
        <Col xs={12}>
          <div className="categoryWrap">
            <h3>Rooms Category</h3>
            <ul className="d-flex">
              {rooms.map((item, ind) => {
                // let minPrice = Math.round(
                //   Number(item?.regularPrice) -
                //     (Number(item?.regularPrice) *
                //       (Number(roomPrice?.amount) / 2)) /
                //       100
                // );
                // let maxPrice = Math.round(
                //   Number(item?.regularPrice) +
                //     (Number(item?.regularPrice) * Number(roomPrice?.amount)) /
                //       100
                // );
                return (
                  <li
                    key={ind}
                    className="w-100 flex justify-content-between align-items-center active"
                  >
                    <div className="flex flex-column gap-2 w-25">
                      <span>
                        {tableData.find((i) => i.id === item.categoryId)?.name}
                      </span>
                      <span>{`\u20B9 ` + item?.regularPrice?.toFixed(2)}</span>
                    </div>
                    <Form
                      className="popup-form w-75"
                      onSubmit={(e) =>
                        onSubmit(
                          item,
                          e,
                          range?.find((data, i) => i === ind)
                        )
                      }
                    >
                      <Row className="flex align-items-center justify-content-between">
                        <Col xs={9} className="form-group m-0">
                          {/* <div className="flex align-items-center justify-content-between">
                            <span>{minPrice}</span>
                            <input
                              type="range"
                              value={range?.find((data, i) => i === ind)?.value}
                              min={minPrice}
                              className="p-0 py-2 w-75"
                              max={
                                tableData.find((i) => i.id === item.categoryId)
                                  ?.maxPrice ?? minPrice
                              }
                              onChange={(e) => {
                                changeHandler(e, ind);
                              }}
                            />
                            <span>
                              {tableData.find((i) => i.id === item.categoryId)
                                ?.maxPrice ?? minPrice}
                            </span>
                          </div> */}
                          <div className="byDateRoomPrice d-flex flex-column">
                            <button
                              type="button"
                              onClick={() => {
                                setByDate(byDate == item.id ? 0 : item.id);
                                if (byDate) {
                                  setFromDate(new Date());
                                  setToDate(new Date());
                                }
                              }}
                              className="flex align-items-center"
                            >
                              <FontAwesomeIcon
                                className="mb-2"
                                icon="fa fa-clock"
                              />
                              By Date
                            </button>
                            {byDate == item.id ? (
                              <div className="d-flex gap-2">
                                <div className="form-group flex-column flex">
                                  <label htmlFor="searchText">
                                    From Date :{" "}
                                  </label>
                                  <ReactDatePicker
                                    className="dataForm"
                                    placeholderText="Select Date"
                                    dateFormat="dd-MMM-yyyy"
                                    onChange={(date) => {
                                      setFromDate(date);
                                      setToDateActive(true);
                                    }}
                                    value={new Date(fromDate)}
                                    selected={fromDate}
                                  />
                                </div>
                                <div className="form-group flex-column flex">
                                  <label htmlFor="searchText">To Date : </label>
                                  <ReactDatePicker
                                    onFocus={() => setToDateActive(true)}
                                    open={toDateActive}
                                    onCalendarClose={() =>
                                      setToDateActive(false)
                                    }
                                    onClickOutside={() =>
                                      setToDateActive(false)
                                    }
                                    minDate={fromDate}
                                    className="dataForm"
                                    placeholderText="Select Date"
                                    dateFormat="dd-MMM-yyyy"
                                    onChange={(date) => {
                                      setToDate(date);
                                      setToDateActive(false);
                                    }}
                                    value={new Date(toDate)}
                                    selected={toDate}
                                  />
                                </div>{" "}
                              </div>
                            ) : null}
                          </div>
                          <input
                            value={range?.find((data, i) => i === ind)}
                            onChange={(e) => changeHandler(e, ind)}
                            className="text-center mb-1"
                            style={{
                              border: "1px solid rgba(0,0,0,.3)",
                              borderRadius: 4,
                            }}
                          />
                          <span
                            style={{
                              textAlign: "center",
                              fontSize: ".8rem",
                              letterSpacing: 1,
                              color: "#000",
                              fontFamily: "poppins",
                            }}
                          >
                            GST Inclusive Rate : {`\u20B9 `}{" "}
                            {(
                              Number(range?.find((_, i) => i === ind)) +
                              Number(range?.find((_, i) => i === ind) * tax) /
                                100
                            )?.toFixed(2) ?? ""}
                          </span>
                          {/* <input
                            value={range?.find((data, i) => i === ind)?.value}
                            onChange={(e) => setRange(e.target.value)}
                            className="text-center w-25s"
                          /> */}
                        </Col>
                        <Col
                          xs={3}
                          className="form-group flex justify-content-between m-0"
                        >
                          <Button
                            // disabled={
                            //   range?.find((data, i) => i === ind)?.value
                            //     ? false
                            //     : true
                            // }
                            disabled={
                              range?.find((_, i) => i === ind) ? false : true
                            }
                            className="my-0 mx-2"
                            variant="primary"
                            type="submit"
                          >
                            {byDate == item.id ? "Submit" : "Update"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </li>
                );
              })}
            </ul>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default RoomPriceProperty;
