import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

const faqs = [
  {
    id: 1,
    header: "Getting Started",
    text: `If you're new to our platform, we'll
              guide you through the initial setup process, including creating an
              account, listing your property, and managing your profile.`,
  },
  {
    id: 2,
    header: "Booking and Reservations",
    text: `Learn how to manage
              reservations, view booking details, and understand the booking
              process, including payment procedures and cancellations.`,
  },
  {
    id: 3,
    header: "Property Management",
    text: `Get answers to questions about
              updating your property's information, including room types, rates,
              availability, and amenities.`,
  },
  {
    id: 4,
    header: "Marketing and Promotion",
    text: `Discover how to enhance your
              property's visibility, market effectively to potential guests, and
              take advantage of our promotional tools.`,
  },
  {
    id: 5,
    header: "Payment and Fees",
    text: `Understand how payments work, including
              commission structures, payment processing, and when and how you
              receive your earnings.`,
  },
  {
    id: 6,
    header: "Review Management",
    text: `Learn how to respond to guest reviews,
              address feedback, and maintain a positive online reputation.`,
  },
  {
    id: 7,
    header: "Technical Support",
    text: `Get assistance with any technical
              issues, troubleshooting, or questions about using our platform
              effectively.`,
  },
  {
    id: 8,
    header: "Legal and Compliance",
    text: `Find information on legal and
              regulatory considerations, including tax collection, data privacy,
              and industry standards.`,
  },
  {
    id: 9,
    header: "Performance Analytics",
    text: `Explore how to access and
              interpret performance data, reports, and insights to optimize your
              property's performance.`,
  },
  {
    id: 10,
    header: " Marketing and Partnership Opportunities",
    text: `Learn about
              potential marketing collaborations, partnership programs, and how
              to maximize your property's exposure through our platform.`,
  },
  {
    id: 11,
    header: " Security and Data Protection",
    text: `Understand the measures we
              take to ensure the security of your data and your guests'
              information.`,
  },
];

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 flex justify-content-between ${
            active === id ? "active" : ""
          }`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={` px-3 rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl?.current?.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

function Community() {
  const [active, setActive] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card p-lg-5 p-2">
                <div className="card-body">
                  <h4 className="form-heading mb-4 text-center">FAQs</h4>
                  <h6>"Your Questions, Our Answers"</h6>
                  <p>
                    Welcome to our "FAQs" segment, a dedicated resource for
                    hotel owners, managers, and hospitality professionals. At
                    RRooms, we understand that navigating the world of online
                    travel and hospitality can raise many questions. This FAQ
                    section is designed to provide you with clear, concise
                    answers to common queries and concerns you may encounter
                    while using our platform.
                  </p>
                  <p>
                    We've compiled a comprehensive list of questions that
                    hoteliers frequently ask us. Our goal is to ensure that you
                    have the information you need to make the most of our
                    services and to help you succeed in the competitive
                    hospitality industry.
                  </p>
                  <h1>As a community, you get to :</h1>
                  {faqs.map((faq, index) => {
                    return (
                      <AccordionItem
                        key={index}
                        active={active}
                        handleToggle={handleToggle}
                        faq={faq}
                      />
                    );
                  })}
                  <p>
                    We're committed to helping you make the most of our services
                    and succeed in the competitive world of hospitality. If you
                    have a question that's not covered in our FAQs, feel free to
                    reach out to our dedicated customer support team for
                    personalized assistance.
                  </p>
                  <p>
                    Your success is our priority, and we're here to ensure your
                    journey in the hospitality industry is as smooth and
                    rewarding as possible. Explore our FAQs, and let us empower
                    you with the knowledge and tools you need to thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
}

export default Community;
