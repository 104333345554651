import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { InputField } from "components/InputField/InputField";
import useBanquetBookingAddEdit from "controllers/AddEditbanquetBookings";
import Layout from "layout";
import moment from "moment";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { showPrice } from "utils/utils";

function AddBunquetBookings() {
  const [searchParams] = useSearchParams();
  const fromMobile = searchParams.get("platform") == "mobile_app";
  const fromEnquiry = searchParams.get("fromEnquiry");
  const {
    register,
    errors,
    FormInputs,
    AlertIcon,
    goBanquetBooking,
    VenueInputs,
    fields,
    deleteItem,
    bookServiceInputs,
    fieldsServices,
    deleteItemService,
    showFoodItems,
    fieldsFood,
    handleAddItemFood,
    deleteItemFood,
    toPDF,
    changeValidation,
    targetRef,
    venueAmout,
    servicesAmout,
    finalAmount,
    dueAmount,
    netPayableAmount,
    control,
    categories,
    foodMenus,
    editMode,
    editService,
    editVenue,
    menuTypes,
  } = useBanquetBookingAddEdit(fromMobile, fromEnquiry);

  return (
    <Layout title={`${editMode ? "Edit" : "Add"} Banquet Booking`}>
      <Form
        className="popup-form onboardForm"
        onSubmit={(e) => {
          e.preventDefault();
          changeValidation(3);
        }}
      >
        <Row className="bg-white pl-1 py-3 pl-lg-5 py-lg-3 addBookingRow">
          <Col xs={12}>
            <h5 className="heading">Customer Details</h5>
          </Col>
          {FormInputs.map((input, ind) => {
            return (
              <Col md={input.col ?? 4} xs={12} className="form-group" key={ind}>
                {ind === 0 && editMode ? (
                  <InputField
                    htmlFor="showEnquiry"
                    text="Select Enquiry *"
                    type="text"
                    field={"showEnquiry"}
                    register={register}
                    errors={errors}
                    disabled
                  />
                ) : (
                  <>
                    <InputField
                      htmlFor={input.name}
                      text={input.text}
                      type={input.type}
                      autoFocus
                      placeholder={input.placeholder}
                      field={input.name}
                      register={register}
                      errors={errors}
                      notnull={input.notNull == false ? false : true}
                      options={input.options}
                      serchable={input.serchable}
                      control={control}
                      max={input.length}
                      min={input.length}
                    />
                    {errors?.[input.name]?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={input.name} />
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Col>
            );
          })}
          <div style={{ border: "1px solid #00000017", margin: "1rem 0" }} />
          <Col xs={12}>
            <h5 className="heading">Choose Venue</h5>
          </Col>
          {VenueInputs.map((input, ind) => {
            return (
              <Col md={input.col ?? 4} xs={12} className="form-group" key={ind}>
                <InputField
                  htmlFor={input.name}
                  text={input.text}
                  type={input.type}
                  placeholder={input.placeholder}
                  field={input.name}
                  register={register}
                  errors={errors}
                  notnull={input.notNull == false ? false : true}
                  options={input.options}
                  disabled={input.disabled}
                  serchable={input.serchable}
                  control={control}
                />
                {errors?.[input.name]?.message && (
                  <div className="error-text">
                    <img alt="" src={AlertIcon} />
                    <span>
                      <ErrorMessage errors={errors} name={input.name} />
                    </span>
                  </div>
                )}
              </Col>
            );
          })}
          <Col xs={12} className="form-group flex mt-3">
            <Button
              variant="primary"
              className="btn btn-sm"
              type="button"
              onClick={() => changeValidation(1)}
            >
              Add
            </Button>
          </Col>
          <Col xs={12} className="form-group">
            <label>Venues</label>
            <div className="scrollTable">
              <table className="table-bordered table-hover w-100 text-center common-table">
                <thead>
                  <th>SR NO</th>
                  <th>Venue Name</th>
                  <th>Function Name</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Rate</th>
                  <th>Session</th>
                  <th>Discount(%)</th>
                  <th>GST(%)</th>
                  {/* <th>Net Amount</th> */}
                  <th>Total Amount</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {fields.map((item, ind) => (
                    <tr key={ind}>
                      <td>{ind + 1}</td>
                      <td>{item.venue?.label}</td>
                      <td>{item.functionName?.label}</td>
                      <td>{moment(item.fromDate).format("DD MMM, YYYY")}</td>
                      <td>{moment(item.fromDate).format("HH:MM A")}</td>
                      <td>{moment(item.toDate).format("HH:MM A")}</td>
                      <td>{item.rate}</td>
                      <td>{item.session.label}</td>
                      <td>{item.discount}</td>
                      <td>{item.gstPercentage}</td>
                      {/* <td>{item.netAmount}</td> */}
                      <td>{item.amount}</td>
                      <td className="text-center">
                        {item.total}

                        {editMode ? (
                          <button
                            type="button"
                            className="btn text-primary p-0 ml-2"
                            onClick={() => editVenue(item, ind)}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-pen-to-square"
                              size="lg"
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn text-danger p-0 ml-2"
                            onClick={() => deleteItem(ind)}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon="fa-solid fa-trash-can"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {errors?.foodItems?.message && (
              <div className="error-text">
                <img src={AlertIcon} alt="error-icons" />
                <span>
                  <ErrorMessage errors={errors} name={`foodItems`} />
                </span>
              </div>
            )}
          </Col>
          <div style={{ border: "1px solid #00000017", margin: "1rem 0" }} />
          <Col xs={12}>
            <h5 className="heading">Add Services</h5>
          </Col>
          {bookServiceInputs.map((input, ind) => {
            return (
              <Col md={input.col ?? 4} xs={12} className="form-group" key={ind}>
                <InputField
                  htmlFor={input.name}
                  text={input.text}
                  type={input.type}
                  placeholder={input.placeholder}
                  field={input.name}
                  register={register}
                  errors={errors}
                  notnull={input.notNull == false ? false : true}
                  options={input.options}
                  disabled={input.disabled}
                />
                {errors?.[input.name]?.message && (
                  <div className="error-text">
                    <img alt="" src={AlertIcon} />
                    <span>
                      <ErrorMessage errors={errors} name={input.name} />
                    </span>
                  </div>
                )}
              </Col>
            );
          })}
          <InputField
            htmlFor="servicesId"
            text=""
            type="number"
            field={"servicesId"}
            register={register}
            errors={errors}
            customclassName="d-none"
          />
          <InputField
            htmlFor="venueId"
            text=""
            type="number"
            field={"venueId"}
            register={register}
            errors={errors}
            customclassName="d-none"
          />
          <Col xs={12} className="form-group flex mt-3">
            <Button
              variant="primary"
              className="btn btn-sm"
              type="button"
              onClick={() => changeValidation(2)}
            >
              Add
            </Button>
          </Col>
          <Col xs={12} className="form-group">
            <label>Services</label>
            <div className="scrollTable">
              <table className="table-bordered table-hover w-100 text-center common-table">
                <thead>
                  <th>SR NO</th>
                  <th>Booking Date</th>
                  <th>Special Instruction</th>
                  <th>Rate</th>
                  <th>Quantity</th>
                  <th>Expected Quantity</th>
                  <th>Discount(%)</th>
                  <th>GST(%)</th>
                  {/* <th>IGST(%)</th> */}
                  <th>Total Amount</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {fieldsServices.map((item, ind) => (
                    <tr key={ind}>
                      <td>{ind + 1}</td>
                      <td>
                        {moment(item.dateOfBooking).format("DD MMM,YYYY")}
                      </td>
                      <td>{item.instructionService}</td>
                      <td>{item.rateService}</td>
                      <td>{item.quantityService}</td>
                      <td>{item.expQuantityService}</td>
                      <td>{item.discountService}</td>
                      <td>{item.gstPercentageService}</td>
                      <td>{item.amountService}</td>
                      <td className="text-center">
                        {item.total}

                        {editMode ? (
                          <button
                            type="button"
                            className="btn text-primary p-0 ml-2"
                            onClick={() => editService(item, ind)}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-pen-to-square"
                              size="lg"
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn text-danger p-0 ml-2"
                            onClick={() => deleteItemService(ind)}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon="fa-solid fa-trash-can"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {errors?.foodItems?.message && (
              <div className="error-text">
                <img src={AlertIcon} alt="error-icons" />
                <span>
                  <ErrorMessage errors={errors} name={`foodItems`} />
                </span>
              </div>
            )}
          </Col>
          <Col xs={12}>
            <h5 className="heading">Food Items</h5>
          </Col>
          <Col md={4} xs={12} className="form-group">
            <InputField
              htmlFor="menuName"
              text="Menu Name"
              type="options"
              placeholder="Enter Menu Name"
              field={"menuName"}
              customclassName="form-control"
              register={register}
              errors={errors}
              options={foodMenus}
              serchable
              control={control}
            />
            {errors?.menuName?.message && (
              <div className="error-text">
                <img alt="" src={AlertIcon} />
                <span>
                  <ErrorMessage errors={errors} name={"menuName"} />
                </span>
              </div>
            )}
          </Col>
          <Col md={4} xs={12} className="form-group">
            <InputField
              htmlFor="menuFile"
              text="Upload Menu"
              type="file"
              field={"menuFile"}
              customclassName="form-control"
              register={register}
              errors={errors}
            />
            {errors?.menuFile?.message && (
              <div className="error-text">
                <img alt="" src={AlertIcon} />
                <span>
                  <ErrorMessage errors={errors} name={"menuFile"} />
                </span>
              </div>
            )}
          </Col>
          <Col md={4} xs={12} className="form-group">
            <InputField
              htmlFor="menuType"
              text="Menu Type"
              type="options"
              placeholder="Enter Menu Type"
              field={"menuType"}
              customclassName="form-control"
              register={register}
              errors={errors}
              options={menuTypes}
              serchable
              control={control}
            />
            {errors?.menuType?.message && (
              <div className="error-text">
                <img alt="" src={AlertIcon} />
                <span>
                  <ErrorMessage errors={errors} name={"menuType"} />
                </span>
              </div>
            )}
          </Col>
          {fieldsFood.length > 0 ? (
            <Col xs={12} ref={targetRef}>
              <div className="categoryWrap mw-100">
                <div className="flex justify-content-between align-items-center border-bottom pr-3">
                  <h3 className="border-0">Added Food Items</h3>

                  <button className="btn  btn-sm" onClick={toPDF}>
                    <FontAwesomeIcon icon="fa-solid fa-print" />
                  </button>
                </div>
                <ul className="d-flex">
                  {fieldsFood.map((data, key) => (
                    <li
                      key={key}
                      className="d-flex align-items-center active"
                      style={{ cursor: "default" }}
                    >
                      <div className="d-flex flex-column">
                        <span>{data.name}</span>
                      </div>

                      <button
                        className="btn m-0 p-0"
                        onClick={() => deleteItemFood(key)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash-can"
                          color="red"
                        />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              {errors?.foodItems?.message && (
                <div className="error-text">
                  <img src={AlertIcon} alt="error-icons" />
                  <span>
                    <ErrorMessage errors={errors} name={`foodItems`} />
                  </span>
                </div>
              )}
            </Col>
          ) : null}
          <div className="categoryWrap mw-100 foodItemsShow flex row-gap-3 column-gap-2 overflow-auto h-100">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <Col md={4}>
                <h3 className="border-0 fs-6">Food Items</h3>
              </Col>
              <Col md={4} className="form-group m-0">
                <InputField
                  text={"Category"}
                  hideLable
                  htmlFor="category"
                  type="options"
                  field={"category"}
                  customclassName="form-control"
                  register={register}
                  errors={errors}
                  options={categories}
                />
                {errors?.category?.message && (
                  <div className="error-text">
                    <img alt="" src={AlertIcon} />
                    <span>
                      <ErrorMessage errors={errors} name={"category"} />
                    </span>
                  </div>
                )}
              </Col>
            </div>
            {showFoodItems.length > 0 ? (
              <ul className="flex gap-3 bg-white border py-3 px-1 w-100">
                {showFoodItems.map((item) => {
                  return (
                    <li
                      style={{
                        cursor: "pointer",
                        boxShadow:
                          fieldsFood.some((i) => i.itemId === item.id) &&
                          "3px 3px 4px rgba(255, 180, 0, 1), -3px -3px 4px rgba(255, 180, 0, 1)",
                      }}
                      className="foodCard py-2 px-3 m-0 rounded border"
                      key={item.id}
                      onClick={() => handleAddItemFood(item)}
                    >
                      <div className="item-name">
                        <h4 className="text-black">{item.itemName}</h4>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div
                className="flex flex-column align-center justify-content-center full-width text-black w-100"
                style={{ height: "10rem" }}
              >
                <FontAwesomeIcon
                  className="mb-2"
                  icon="fa-solid fa-ban"
                  style={{ width: "40px", height: "40px" }}
                  beat
                />
                <span>Menu Item not available</span>
              </div>
            )}
          </div>
          <div style={{ border: "1px solid #00000017", margin: "1rem 0" }} />
          <Col xs={12}>
            <h5 className="heading">Summary</h5>
          </Col>
          <Col md={6} xs={12}>
            <div className="summaryCard d-flex">
              <p className="w-50">Venue Taxable Amount:</p>{" "}
              <p className="w-50">{showPrice(venueAmout.withoutTax)}</p>
            </div>
            <div className="summaryCard d-flex">
              <p className="w-50">Venue Tax Amount:</p>{" "}
              <p className="w-50">{showPrice(venueAmout.tax)}</p>
            </div>
            <div className="summaryCard d-flex">
              <p className="w-50">Venue Total Amount:</p>{" "}
              <p className="w-50">{showPrice(venueAmout.total)}</p>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="summaryCard d-flex">
              <p className="w-50">Service Taxable Amount:</p>{" "}
              <p className="w-50">{showPrice(servicesAmout.withoutTax)}</p>
            </div>
            <div className="summaryCard d-flex">
              <p className="w-50">Service Tax Amount:</p>{" "}
              <p className="w-50">{showPrice(servicesAmout.tax)}</p>
            </div>
            <div className="summaryCard d-flex">
              <p className="w-50">Service Total Amount:</p>{" "}
              <p className="w-50">{showPrice(servicesAmout.total)}</p>
            </div>
          </Col>
          <Col xs={12} className="d-flex flex-column flex-md-row mt-2">
            <div className="summaryCard d-flex w-100 gap-3 border justify-content-start justify-content-md-center pl-2 pl-md-0 border-primary">
              <p>Sub Total:</p> <p>{showPrice(finalAmount.withoutTax)}</p>
            </div>
            <div className="summaryCard d-flex w-100 gap-3 border justify-content-start justify-content-md-center pl-2 pl-md-0 border-primary">
              <p>Total Tax:</p> <p>{showPrice(finalAmount.tax)}</p>
            </div>
            <div className="summaryCard d-flex w-100 gap-3 border justify-content-start justify-content-md-center pl-2 pl-md-0 border-primary">
              <p>Final Amount:</p> <p>{showPrice(finalAmount.total)}</p>
            </div>
          </Col>
          <Col xs={12} className="d-flex mt-2">
            <div className="summaryCard d-flex w-100 mr-0 gap-3 mr-md-5 gap-md-3 justify-content-end">
              <p className="text-danger fw-bold fs-5">Net Payable Amount:</p>{" "}
              <p className="text-danger fw-bold fs-5">
                {showPrice(netPayableAmount > 0 ? netPayableAmount : 0)}
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} className="form-group m-0">
            <InputField
              text="Extra Charges"
              htmlFor="extraAmount"
              type="number"
              placeholder="Enter Extra Charges"
              field={"extraAmount"}
              customclassName="form-control"
              register={register}
              errors={errors}
            />
            {errors?.extraAmount?.message && (
              <div className="error-text">
                <img alt="" src={AlertIcon} />
                <span>
                  <ErrorMessage errors={errors} name={"extraAmount"} />
                </span>
              </div>
            )}
          </Col>
          <Col xs={12} md={4} className="form-group m-0">
            <InputField
              text="Paid Amount"
              htmlFor="paidAmout"
              type="number"
              field={"paidAmout"}
              placeholder="Enter Paid Amount"
              customclassName="form-control"
              register={register}
              errors={errors}
            />
            {errors?.paidAmout?.message && (
              <div className="error-text">
                <img alt="" src={AlertIcon} />
                <span>
                  <ErrorMessage errors={errors} name={"paidAmout"} />
                </span>
              </div>
            )}
          </Col>
          <Col xs={12} md={4} className="mt-4">
            <div className="summaryCard d-flex w-100 mr-5 gap-3">
              <p className="text-black fw-medium">Due Amount :</p>{" "}
              <p className="text-black fw-medium">
                {showPrice(dueAmount > 0 ? dueAmount : 0)}
              </p>
            </div>
          </Col>
          {/* <Col xs={12} className="form-group mt-3">
            <label>Payment Mode</label>
            <div className="form-group flex gap-3 mt-2">
              {banquetPaymentMode.map((item, ind) => {
                return (
                  <div className="flex w-25" key={ind}>
                    <input
                      {...register("paymode")}
                      type="radio"
                      name="paymentMode"
                      className="mr-3"
                      style={{ width: 20 }}
                      value={item}
                      id={item}
                    />
                    <label className="form-check-label w-50" htmlFor={item}>
                      {item}
                    </label>
                  </div>
                );
              })}
            </div>
          </Col> */}
        </Row>

        <Row className="mr-5 mb-5">
          <Col
            xs={12}
            className="form-group justify-content-end flex gap-3 mt-3"
          >
            <Button
              variant="danger"
              className="btn btn-sm"
              type="button"
              onClick={goBanquetBooking}
            >
              Cancle
            </Button>
            <Button variant="primary" className="btn btn-sm" type="submit">
              {editMode ? "Update" : "Book Now"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
}

export default AddBunquetBookings;
