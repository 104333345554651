import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Col, Container, Form, Row } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";

import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";

const RatingReport = () => {
  const { showProperties } = useSelector((s) => s.AllData);
  const [renderProperty, setRenderProperty] = useState(new Date());

  const [fromdate, setFromDate] = useState(new Date());
  const [bookingCode, setBookingCode] = useState("");
  const [searchSelectVal, setSearchSelectVal] = useState({});
  const [todate, setToDate] = useState(new Date());
  const [onOpenPicker, setOnOpenPicker] = useState(false);
  const submitFilter = async (e) => {
    e.preventDefault();
    const filterState = `fromDate=${moment(fromdate).format(
      "YYYY-MM-DD"
    )}&toDate=${moment(todate).format("YYYY-MM-DD")}${
      searchSelectVal.value ? `&propertyId=${searchSelectVal.value}` : ""
    }${bookingCode.length ? `&bookingCode=${bookingCode}` : ""}`;

    window.open(
      `https://api.rrooms.in/api/kpi/get-rating-report?${filterState}`
    );
  };

  useEffect(() => {
    setRenderProperty([{ label: "All", value: 0 }, ...showProperties]);
  }, [showProperties]);

  return (
    <Layout title="Rating Report" wantSearchableListFor="property" hideProperty>
      <Container className="flex justify-content-center align-items-center">
        <Row>
          <Form className="bg-white rounded-2 p-5 mt-3">
            <Container>
              <h6 className="text-black">Select Date for Rating Report:</h6>
              <Row className="addBookingRow">
                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">From Date: </label>
                  <ReactDatePicker
                    className="form-control"
                    customclassName="form-control"
                    onChange={(date) => {
                      setFromDate(date);
                      setOnOpenPicker(true);
                    }}
                    selected={new Date(fromdate)}
                    dateFormat="yyyy/MM/dd"
                  />
                </Col>

                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">To Date: </label>
                  <ReactDatePicker
                    open={onOpenPicker}
                    onCalendarClose={() => setOnOpenPicker(false)}
                    onClickOutside={() => setOnOpenPicker(false)}
                    className="form-control"
                    customclassName="form-control"
                    onChange={(date) => {
                      setToDate(date);
                      setOnOpenPicker(false);
                    }}
                    selected={new Date(todate)}
                    dateFormat="yyyy/MM/dd"
                  />
                </Col>
                {/* <Col
                  xs={6}
                  className="form-group gap-2 d-flex flex-column mt-2"
                >
                  <label className="text-black m-0 fs-6">Booking Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="booking Code"
                    onChange={(e) => setBookingCode(e.target.value)}
                    value={bookingCode}
                  />
                </Col> */}
                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label htmlFor="searchText text-primary">
                    Select Property:
                  </label>
                  <Select
                    options={renderProperty}
                    placeholder={"Select"}
                    value={searchSelectVal}
                    onChange={(dataSet) => {
                      setSearchSelectVal(dataSet);
                    }}
                    isSearchable={true}
                  />
                </Col>

                <Col className="flex align-items-center">
                  <button
                    onClick={(e) => submitFilter(e)}
                    className="btn-success btn"
                  >
                    Export Excel
                  </button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Row>
      </Container>
    </Layout>
  );
};

export default RatingReport;
