import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import {
  getRroomUsersAction,
  usersSeletor,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import decodeEntities from "../../Common/DecodeHtml";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import {
  USER_DEG_TEXT,
  USER_DEG_TEXT_WITH_ANUM,
} from "../../constant/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";

const RRoomsUsers = () => {
  const dispatch = useDispatch();
  const { rRoomUsers } = useSelector(usersSeletor);
  const [selectData, setSelectData] = useState({});
  const [showAllRoles, setShowAllRoles] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getRroomUsersAction());
  }, []);

  const tableRef = useRef(null);

  useEffect(() => {
    setUserList(rRoomUsers);
  }, [rRoomUsers]);

  const { AlertIcon } = Imagesdata || {};
  const { userDetail } = useSelector(authenticationSeletor);

  const [show, setShow] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showUserList, setShowUserList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      name: payload.firstName + " " + payload.lastName,
      email: payload.email,
      mobile: payload.phoneNumber,
      role: Number(payload.role),
      password: payload.password,
      designation: payload.designation,
    };
    try {
      let res = {};
      let msg = "";
      if (editMode) {
        realPayload.updatedBy = userDetail?.id;
        res = await services.user.editRRoomUserService(
          selectData.id,
          realPayload
        );
        msg = "Updated";
      } else {
        realPayload.createdBy = userDetail?.id;
        res = await services.user.addRRoomUserService(realPayload);
        msg = "Created";
      }
      setEditMode(false);
      if (res.status === 200) {
        reset();
        setValue("designation", "");
        setValue("role", "");
        dispatch(getRroomUsersAction());
        setShow(false);
        toast.success(`Rrooms User ${msg} SuccessFull !`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  const getRoles = async () => {
    const res = await services.auth.getAllRoles();
    if (res.status === 200) {
      setShowAllRoles(res.data.data);
    }
  };
  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure want to delete User")) {
      try {
        const res = await services.auth.deleteRroomsUser(id);
        if (res.status == 200) {
          dispatch(getRroomUsersAction());
          toast.success("User Deleted Successfull !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      } catch (error) {}
    }
  };
  const editHandler = async (data) => {
    setSelectData(data);
    setEditMode(true);
    setValue("firstName", data.firstName);
    setValue("lastName", data.lastName);
    setValue("email", data.email);
    setValue("phoneNumber", data.mobile);
    setValue("designation", data.designation);
    setValue("password", data.password);
    setValue("confirmPassword", data.password);
    setValue("role", data.role);
    setShow(true);
  };
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  const filterData = [
    { name: "Department", id: "department" },
    { name: "Manager", id: "manager" },
    { name: "Designation", id: "designation" },
  ];
  const searchfilter = (text) => {
    const results = userList.filter((item) => {
      if (text == "0") return userList;
      return item?.designation?.toLocaleLowerCase()?.includes(text);
    });
    setShowUserList(results);
  };
  useEffect(() => {
    getRoles();
    setShowUserList(userList);
  }, [userList]);
  return (
    <Layout
      title="RRooms Users"
      openHandler={() => setShow(true)}
      extraButton="Export Table"
      extraBtnHandler={onDownload}
      optionData={filterData}
      type="dropdown"
      searchfilter={searchfilter}
    >
      <Table
        striped
        bordered
        hover
        className="common-table text-start"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Name</th>
            <th>User Code</th>
            <th>Designation</th>
            <th>Role</th>
            <th>Email</th>
            <th>mobile</th>
            <th>Created By</th>
            <th>Edited By</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {showUserList.map((data, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{data.firstName + " " + data.lastName}</td>
              <td>{data.userCode}</td>
              <td>{USER_DEG_TEXT[data.designation]}</td>
              <td>{data.Role?.roleName ?? "N/A"}</td>
              <td>{data.email ? decodeEntities(data.email) : "N/A "}</td>
              <td>{data.mobile}</td>
              <td>
                {userList?.find((i) => i.id == data.createdBy)?.firstName ??
                  "N/A"}
              </td>
              <td>
                {userList?.find((i) => i.id == data.updatedBy)?.firstName ??
                  "N/A"}
              </td>
              <td>
                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={() => editHandler(data)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                </Button>
                <Button
                  variant="danger"
                  className="mr-2"
                  onClick={() => deleteHandler(data.id)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-trash" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setValue("designation", "");
          setValue("role", "");
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>New user details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <p>New user details</p>
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="firstName"
                    text="First Name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter first name"
                    field={"firstName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.firstName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"firstName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="lastName"
                    text="Last Name"
                    type="text"
                    placeholder="Enter Last name"
                    field={"lastName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.lastName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"lastName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="phoneNumber"
                    text="Phone No."
                    type="text"
                    placeholder="Enter phone number"
                    field={"phoneNumber"}
                    register={register}
                    errors={errors}
                    max={10}
                  />
                  {errors?.phoneNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"phoneNumber"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="email"
                    text="Email address "
                    type="email"
                    placeholder="Enter email address"
                    field={"email"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.email?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"email"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group flex flex-column">
                  <label>Role</label>
                  <select
                    style={{ height: 45, outline: 0 }}
                    register={register}
                    {...register("role")}
                    className={`formControl border`}
                  >
                    <option disabled selected value="">
                      Select User Role
                    </option>

                    {userDetail.role == 1
                      ? showAllRoles?.map((item) => {
                          if (!(item.id == 3 || item.id == 4)) {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.roleName}
                              </option>
                            );
                          }
                        })
                      : showAllRoles?.map((item) => {
                          if (
                            !(item.id == 1 || item.id == 2) &&
                            item.id > userDetail.role
                          ) {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.roleName}
                              </option>
                            );
                          }
                        })}
                  </select>
                  {errors?.role?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"role"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group flex flex-column">
                  <label>Designation</label>
                  <select
                    style={{ height: 45, outline: 0 }}
                    register={register}
                    {...register("designation")}
                    className={`formControl border`}
                  >
                    <option disabled selected value="">
                      Select User Designation
                    </option>
                    {userDetail.designation == "1"
                      ? USER_DEG_TEXT_WITH_ANUM.map((item, ind) => {
                          return (
                            <option value={item.value} key={ind}>
                              {item.name}
                            </option>
                          );
                        })
                      : USER_DEG_TEXT_WITH_ANUM.map((item, ind) => {
                          if (userDetail.designation < item.value) {
                            return (
                              <option value={item.value} key={ind}>
                                {item.name}
                              </option>
                            );
                          }
                        })}
                  </select>
                  {errors?.designation?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"designation"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="password"
                    text="Create Password"
                    type="password"
                    placeholder="Enter new password"
                    field={"password"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.password?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"password"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="confirmPassword"
                    text="Confirm Password"
                    type="password"
                    placeholder="Enter confirm password"
                    field={"confirmPassword"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.confirmPassword?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"confirmPassword"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default RRoomsUsers;
