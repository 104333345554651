import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  PropertyLoginAction,
  saveUser,
} from "../../store/slices/authenticationSlice";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import PropertyModal from "./PropertyModal";

const Register = () => {
  const [viewBtn, setViewBtn] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const { Logolandingpage } = Imagesdata || {};

  const [userInfo, setUserInfo] = useState({ email: "", password: "" });
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userInfo.email || !userInfo.password) {
      toast.error("Please Enter Email & Username to login*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }

    const user = await dispatch(PropertyLoginAction(userInfo));

    if (user == "Pass") {
      toast.error("Username or Password is Incorrect*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }
    if (user.isOwner && user.agreement != 1) {
      navigate(`/property-agreement-accept/${user.id}`, { state: user });
      return;
    }

    setUserData(user);

    if (user.propertyLength > 1) {
      setShow(true);
      return;
    }
    dispatch(saveUser(user, user.property, navigate));
  };

  return (
    <section className="loginSection flex flex-column flex-md-row signUpSection">
      <div className="container-lg">
        <div className="topbar d-flex">
          <figure>
            <a href="/">
              <img alt="" src={Logolandingpage} alt="Main logo" />
            </a>
          </figure>
          <p>
            We make your stay just as enjoyable an experience as you can imagine
          </p>
        </div>
      </div>
      <div className="container-lg">
        <div className="wrapper flex">
          <div className="content">
            <h1>Don't think Book in a blink!</h1>
            <p>
              Sign up with your phone number and get exclusive access to
              discounts and savings on RRooms stays and with our many Hotel
              partners.
            </p>
          </div>
          <div className="form-wrap">
            <div className="head" style={{ textTransform: "uppercase" }}>
              Property Login
            </div>
            <div className="admin-login-form form-inner">
              <h2>Login</h2>
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="Email"
                    value={userInfo.email}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, email: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="icon_input">
                    <input
                      type={viewBtn ? "text" : "password"}
                      placeholder="**********"
                      value={userInfo.password}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, password: e.target.value })
                      }
                    />
                    <div onClick={() => setViewBtn(!viewBtn)}>
                      <FontAwesomeIcon
                        icon={`fa-solid  ${
                          viewBtn ? "fa-eye-slash" : "fa-eye"
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit">login</button>
                </div>
                <p>
                  <a href="#">Forget Password</a>
                </p>
              </form>
            </div>
            <div className="tag"></div>
            <ul className="login-btn-list">
              <li className="d-flex gap-2">
                <Link to="/login" className="registerBtn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i> back
                  to login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <PropertyModal show={show} setShow={setShow} user={userData} />
    </section>
  );
};

export default Register;
