import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Select,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import services from "../../API/services";
import { useDispatch, useSelector } from "react-redux";
import {
  getRroomPropertyAction,
  propertySeletor,
} from "../../store/slices/propertySlice";
import Moment from "react-moment";
import ImageUploadBox from "../../components/imageUploadBox";
import { City, Country, State } from "country-state-city";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const PropertyCategory = () => {
  const { InternetIcon, AlertIcon } = Imagesdata || {};
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ ...validation, mode: "onSubmit" });
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    getPropertyCategory();
  }, []);

  const getPropertyCategory = async () => {
    try {
      const res = await services.property.getPropertyCategoryService();
      if (res.status === 200) {
        setCategoryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const onSubmit = async (payload) => {
    try {
      if (editMode) {
        let realPayload = {
          id: payload.id,
          body: { name: payload.categoryName },
        };
        const res = await services.property.editPropertyCategoryService(
          realPayload
        );
        if (res.status === 200) {
          setEditMode(false);
          reset();
          getPropertyCategory();
          setShow(false);
          toast.success("Property Category Updated SuccessFull !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      } else {
        let realPayload = { name: payload.categoryName };
        const res = await services.property.createPropertyCategoryService(
          realPayload
        );
        if (res.status === 200) {
          reset();
          getPropertyCategory();
          setShow(false);
          toast.success("Property Category Created SuccessFull !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {}
  };

  const editHandler = (data) => {
    setValue("categoryName", data.name);
    setValue("id", data.id);
    setEditMode(true);
    setShow(true);
  };

  const deleteHandler = async () => {
    try {
      const res = await services.property.deletePropertyCategoryService(
        selectedData.id
      );
      if (res.status === 200) {
        getPropertyCategory();
        setShowDelete(false);
        toast.success("Property Category Deleted SuccessFull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };
  return (
    <Layout title="Property Category" openHandler={() => setShow(true)}>
      <div className="onboardDetails">
        <div className="wrap">
          <div className="inner">
            <div className="table-box">
              <table>
                <thead>
                  <tr>
                    <th>Serial Number</th>
                    <th>Category Name</th>
                    <th>Onboard date</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryData.map((data, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{data.name}</td>
                      <td>
                        <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                      </td>
                      <td>
                        <div className="button-group">
                          <Button
                            variant="primary"
                            onClick={() => editHandler(data)}
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => {
                              setShowDelete(true);
                              setSelectedData(data);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setEditMode(false);
          reset();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit" : "Create Property Category "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="categoryName"
                    text="Category Name *"
                    type="text"
                    placeholder="Enter Category Name"
                    field={"categoryName"}
                    register={register}
                    errors={errors}
                    autoFocus={true}
                  />
                  {errors?.categoryName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"categoryName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="deleteModal">
              <span>Are you sure? Do you want to delete this record?</span>
              <span>You will not be able to recover this record!</span>
            </div>
            <Button
              variant="primary"
              className="btn-sm"
              type="button"
              onClick={deleteHandler}
            >
              Yes , Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default PropertyCategory;
