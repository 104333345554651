import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getResturantTable,
  addResturantTable,
  deleteResturantTable,
} from "API/services/newServices";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";

const validation = Yup.object().shape({
  tableNo: Yup.string().required("Enter Table Number*"),
  occupancy: Yup.string().required("Enter Table Occupancy*"),
});

const rules = { resolver: yupResolver(validation) };

const useResturantTable = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  const tableRef = useRef();

  const { AlertIcon } = Imagesdata;

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onSubmit", ...rules });

  // states
  const [tableData, setTableData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selected, setSelected] = useState({});

  const getResturantTables = async () => {
    const res = await getResturantTable(userDetail.propertyId);
    if (res) setTableData(res);
  };

  const onSubmit = async (payload) => {
    if (tableData.some((i) => i.tableNumber == payload.tableNo) && !editMode) {
      setError(
        "tableNo",
        { message: "Table number already exist!", type: "value" },
        { shouldFocus: true }
      );
      return;
    }
    const param = {
      editMode,
      id: selected.id,
      body: {
        tableNumber: payload.tableNo,
        capacity: payload.occupancy,
        status: "Available",
        propertyId: userDetail.propertyId,
        bookingTime: new Date(),
      },
    };
    const res = await addResturantTable(param);
    if (res) {
      hideAddModalHanlder();
      getResturantTables();
    }
  };

  const editHandler = (item) => {
    setEditMode(true);
    setSelected(item);
    setValue("tableNo", item.tableNumber);
    setValue("occupancy", item.capacity);
    openAddModalHanlder();
  };
  const deleteHandler = async (id) => {
    if (!window.confirm("Are you sure want to delete table")) {
      return;
    }
    const res = await deleteResturantTable(id);
    if (res) {
      getResturantTables();
    }
  };

  const STORY_HEADERS = [
    {
      prop: "id",
      title: "Sr. No.",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "tableNumber",
      title: "Table Number",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "capacity",
      title: "Occupancy",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "createdAt",
      title: "Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
    },

    {
      prop: "action",
      title: "Actions",
      isSortable: true,
      isFilterable: true,
      cellProps: { className: "text-center" },
      cell: (row) => {
        return (
          <>
            <Button className="btn-sm mr-2" onClick={() => editHandler(row)}>
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            </Button>
            <Button
              className="btn-sm"
              variant="danger"
              onClick={() => deleteHandler(row.id)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash" />
            </Button>
          </>
        );
      },
    },
  ];
  const openAddModalHanlder = () => {
    setOpenAddModal(true);
  };
  const hideAddModalHanlder = () => {
    setOpenAddModal(false);
    reset();
  };

  useEffect(() => {
    getResturantTables();
  }, []);
  return {
    STORY_HEADERS,
    tableData,
    tableRef,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
  };
};

export default useResturantTable;
