import React, { useEffect, useRef, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useFieldArray, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDownloadExcel } from "react-export-table-to-excel";
import * as XLSX from "xlsx";
import decodeEntities from "../../Common/DecodeHtml";
import { ErrorMessage } from "@hookform/error-message";
import { InputField } from "components/InputField/InputField";
import ReactGA from "react-ga4";

const FoodsItems = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showItems, setShowItems] = useState([]);
  const [excelData, setExcelData] = useState([]);

  const [selectedItemAction, setSelectedItemAction] = useState([]);

  const selectAllRef = useRef();

  const formateTable = useRef();
  const [importByExcelState, setImportByExcelState] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    ...validation,
    mode: "onSubmit",
    defaultValues: {
      inputs: [
        {
          property_id: userDetail.propertyId,
          name: "",
          gstType: "",
          gstPercent: 0,
          price: "",
          quantity: 1,
        },
      ],
    },
  });

  const { fields, append, remove, replace } = useFieldArray({
    name: "inputs",
    control,
  });

  const onHide = () => {
    setShow(false);
    remove();
    reset();
    setEditMode();
  };

  const onSubmit = async (payload) => {
    const items = payload.inputs.map((item) => ({
      ...item,
      gstPercent: +item.gstPercent,
      price: +item.price,
    }));
    const realPayload = {
      items,
    };
    try {
      let res = {};
      let msg = "";
      if (editMode) {
        res = await services.property.updateFoodItems(
          items[0],
          selectedData.id
        );
        msg = "Updated";
      } else {
        res = await services.property.createFoodItemInventory(realPayload);
        msg = "Created";
      }
      if (res.status === 200) {
        if (msg) {
          toast.success(`Food Item ${msg} Successfully`, {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
        onHide();
        getDataHandler();
      }
    } catch (error) {}
  };

  const excelSubmit = async () => {
    let count = 0;
    excelData?.map(async (item) => {
      const realPayload = {
        property_id: userDetail.propertyId,
        category_id: item.Category,
        name: item.Items,
        price: Math.round(Number(item.Price) + (item.Price * 5) / 100),
      };
      try {
        const res = await services.property.createFoodItems(realPayload);
        if (res.status == 200) {
          count++;
        }
      } catch (error) {}
      if (count == excelData?.length) {
        setExcelData([]);
        setImportByExcelState(false);
        getDataHandler();
      }
    });
  };

  const editHandler = (data) => {
    replace({
      property_id: userDetail.propertyId,
      name: data.name,
      gstType: data.gstType,
      gstPercent: Number(data.gstPercent),
      price: data.price,
      quantity: 1,
    });
    setSelectedData(data);
    setShow(true);
    setValue("foodCategory", data.categoryId);
    setValue("itemName", data.name);
    setValue("itemPrice", data.price);
    setEditMode(true);
  };

  const getDataHandler = async () => {
    try {
      const res = await services.property.getFoodItemsProperty(
        `?property_id=${userDetail?.propertyId}`
      );
      // const menuCategory = await services.globalService.getMenuCardCategory();
      if (res.status == 200) {
        setTableData(res.data.data);
      }
      // if (menuCategory.status == 200) {
      // setAllCategory(menuCategory.data.data);
      // }
    } catch (error) {}
  };
  const deleteHandler = async (id, msgState = true) => {
    try {
      const res = await services.property.deleteMenuItems(id);
      if (res.status == 200) {
        if (msgState) {
          getDataHandler();
          toast.success(`Food Item Deleted Successfully!`, {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "kitchen_items" });
    getDataHandler();
  }, []);

  const importByExcel = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setExcelData(data);
    });
  };
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "FoodOrders",
    sheet: "FoodOrders",
  });
  const { onDownload: excelDownLoadFormate } = useDownloadExcel({
    currentTableRef: formateTable.current,
    filename: "bulk Menu Items",
    sheet: "FoodOrders",
  });
  const searchfilter = (text) => {
    const results = tableData.filter((order) => {
      if (text === "") return tableData;
      return (
        order.name +
        // allCategory.find((i) => i.id == order.categoryId)?.name +
        order.price +
        order?.id
      )
        .toString()
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setShowItems(results);
  };
  useEffect(() => {
    setShowItems(tableData);
  }, [tableData]);
  const selectAll = (e) => {
    if (e.target.checked) {
      setSelectedItemAction(showItems.map((i) => i.id));
    } else {
      setSelectedItemAction([]);
    }
  };
  const selectItem = (id) => {
    if (selectedItemAction.includes(id)) {
      selectAllRef.current.checked = false;
      setSelectedItemAction(selectedItemAction.filter((i) => i != id));
    } else {
      setSelectedItemAction([...selectedItemAction, id]);
    }
  };
  const deleteSelectedItem = () => {
    let count = 0;
    selectedItemAction.map((item) => {
      deleteHandler(item, false);
      count++;
    });
    if (count == selectedItemAction.length) {
      getDataHandler();
      toast.success(`Food Item Deleted Successfully!`, {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      count = 0;
      setSelectedItemAction([]);
    }
  };

  const addHandler = () => {
    append({
      property_id: userDetail.propertyId,
      name: "",
      gstType: "",
      gstPercent: "",
      price: "",
      quantity: 1,
    });
  };

  const removeHandler = (index) => {
    remove(index);
  };
  return (
    <Layout
      title="Food Items"
      openHandler={() => setShow(true)}
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      searchfilter={searchfilter}
      secondBtn={() => setImportByExcelState(true)}
      secondbuttonTitle="Import Table"
    >
      {selectedItemAction.length > 0 && (
        <Button
          className="floatingButton"
          variant="danger"
          onClick={deleteSelectedItem}
        >
          Delete Selected Item
        </Button>
      )}
      <Table
        striped
        bordered
        hover
        className="common-table text-uppercase"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th className="text-center">
              <div className="checkboxes__item">
                <label className="checkbox style-c">
                  <input
                    type="checkbox"
                    onChange={selectAll}
                    ref={selectAllRef}
                  />
                  <div className="checkbox__checkmark"></div>
                </label>
              </div>
            </th>
            <th>Item ID</th>
            <th>Item Name</th>
            <th>Item Price</th>
            <th>GST INC/EXL</th>
            <th>GST %</th>
            <th>Tax Amount</th>
            <th>Amount Before Tax </th>
            <th>Quntity</th>
            <th>Final Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {showItems?.length > 0 ? (
            showItems?.map((data, key) => {
              return (
                <tr key={key}>
                  <td>
                    <div className="checkboxes__item">
                      <label className="checkbox style-c">
                        <input
                          type="checkbox"
                          checked={selectedItemAction.includes(data.id)}
                          onChange={(e) => selectItem(data.id)}
                        />
                        <div className="checkbox__checkmark"></div>
                      </label>
                    </div>
                  </td>
                  <td className="text-center">{data.id}</td>
                  {/* <td>
                    {allCategory.find((i) => i.id == data.categoryId)?.name}
                  </td> */}
                  <td>{decodeEntities(data.name)}</td>
                  <td>₹{data.price}</td>
                  <td>{data.gstType}</td>
                  <td>{data.gstPercent}</td>
                  <td>{data.taxAmount}</td>
                  <td>{data.amountBeforeTax}</td>
                  <td>{data.quantity}</td>
                  <td>{data.finalPrice}</td>

                  {/* <td>₹{data.price}</td> */}
                  {/* {userDetail.Role?.canEdit == 1 &&
                    userDetail.Role?.canDelete == 1 && (
                      <td>
                        {userDetail.Role?.canEdit == 1 && (
                          <Button
                            variant="primary"
                            className="btn-sm mr-2"
                            onClick={() => editHandler(data)}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        )}
                        {userDetail.Role?.canDelete == 1 && (
                          <Button
                            variant="danger"
                            className="btn-sm"
                            onClick={() => deleteHandler(data.id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </Button>
                        )}
                      </td>
                    )} */}
                  <td>
                    {/* {userDetail.Role?.canEdit == 1 && ( */}
                    <Button
                      variant="primary"
                      className="btn-sm mr-2"
                      onClick={() => editHandler(data)}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </Button>
                    {/* )} */}
                    {/* {userDetail.Role?.canDelete == 1 && ( */}
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(data.id)}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </Button>
                    {/* )} */}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={12}>
                <div
                  className="flex flex-column align-center justify-content-center full-width"
                  style={{ height: "10rem" }}
                >
                  <FontAwesomeIcon
                    className="mb-2"
                    icon="fa-solid fa-ban"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span>No Data</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit " : "Add "}Food Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                {fields.map((item, key) => (
                  <div className="field-group-column">
                    {key !== 0 && (
                      <button
                        type="button"
                        className="btn btn-close btn-position"
                        onClick={() => removeHandler(key)}
                      ></button>
                    )}
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="name"
                        text="Item Name"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter Item Name"
                        field={`inputs.${key}.name`}
                        register={register}
                        errors={errors}
                      />
                      {errors?.inputs?.[key]?.name?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`inputs.${key}.name`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="price"
                        text="Item Price"
                        type="number"
                        autoFocus={true}
                        placeholder="Enter Item Price"
                        field={`inputs.${key}.price`}
                        register={register}
                        errors={errors}
                      />
                      {errors?.inputs?.[key]?.price?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`inputs.${key}.price`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col xs={6} className="form-group">
                      <label>Item GST Type</label>
                      <select {...register(`inputs.${key}.gstType`)}>
                        <option selected value="">
                          Select GST Type
                        </option>
                        <option value="INC">Inclusive</option>
                        <option value="EXL">Exclusive</option>
                      </select>

                      {errors?.inputs?.[key]?.gstType?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`inputs.${key}.gstType`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="gstPercent"
                        text="Item GST Percent"
                        type="number"
                        autoFocus={true}
                        placeholder="Enter Item GST Percent"
                        field={`inputs.${key}.gstPercent`}
                        register={register}
                        errors={errors}
                      />
                      {errors?.inputs?.[key]?.gstPercent?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={`inputs.${key}.gstPercent`}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                  </div>
                ))}

                <Col xs={12} className="flex justify-content-between">
                  {editMode ? null : (
                    <Button
                      variant="success"
                      type="button"
                      style={{ marginRight: 10 }}
                      onClick={addHandler}
                      className="btn-sm"
                    >
                      + Add New
                    </Button>
                  )}
                  <Button className="btn-sm" variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={importByExcelState}
        onHide={() => setImportByExcelState(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Bulk Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {excelData?.length > 0 ? (
            <>
              <div className="flex justify-content-end mb-2">
                <button className="btn-sm btn-primary" onClick={excelSubmit}>
                  Submit
                </button>
              </div>
              <Table striped bordered hover className="common-table">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Category</th>
                    <th>Item Name</th>
                    <th>Item Price</th>
                  </tr>
                </thead>
                <tbody>
                  {excelData?.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td className="text-center">{key + 1}</td>
                        <td>
                          {data.Category}
                          {/* {allCategory.find((i) => i.id == data.categoryId)?.name} */}
                        </td>
                        <td>{data.Items}</td>
                        <td>₹{data.Price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <div className="flex gap-1 justify-content-between align-items-center ">
                <div className="flex form-group flex-column ">
                  <label htmlFor="excel">Select Excel File</label>
                  <input
                    type="file"
                    id="excel"
                    onChange={(e) => importByExcel(e.target.files[0])}
                  />
                </div>
                <button
                  className="btn-sm btn-primary text-white"
                  onClick={excelDownLoadFormate}
                >
                  Download Formate
                </button>
              </div>
              <table
                className="table border"
                ref={formateTable}
                style={{ position: "absolute", top: -100000000000 }}
              >
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Items</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Category Ids</td>
                    <td>items name</td>
                    <td>Only Number</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default FoodsItems;
