import React, { useEffect, useMemo, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Button, Form, Row, Col } from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import Validation3 from "./Validation3";
import { useDispatch } from "react-redux";
import Services from "../../API/services";
import { getRroomPropertyAction } from "../../store/slices/propertySlice";
import { toast } from "react-toastify";
import Validation3Optional from "./Validation3Optional";

function Step3({ data }) {
  const {
    step,
    setStep,
    setShow,
    resetData,
    selectedData,
    editMode,
    setSelectedData,
  } = data;

  const [validations, setValidations] = useState(Validation3);
  const dispatch = useDispatch();
  const { AlertIcon } = Imagesdata || {};
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    unregister,
    watch,
    reset,
    formState: { errors },
  } = useForm({ ...validations, disableValidation: true });

  const { firmTypeEntity } = watch();
  useEffect(() => {
    if (firmTypeEntity == "Proprietorship") {
      setValidations(Validation3Optional);
      if (!editMode) {
        reset();
        setValue("firmTypeEntity", "Proprietorship");
      }
    } else {
      setValidations(Validation3);
    }
  }, [firmTypeEntity]);

  useEffect(() => {
    if (editMode) {
      const {
        PropertyPanNumber,
        tanNumber,
        gstNumber,
        firmType,
        bankDetails,
        legalName,
      } = selectedData;
      const bankDetail = bankDetails?.split("~");
      setValue("firmTypeEntity", firmType?.toString());
      setValue("gstNumber", gstNumber?.toString());
      setValue("tanNumber", tanNumber?.toString());
      setValue("PropertyPanNumber", PropertyPanNumber?.toString());
      setValue("entityName", legalName);
      if (bankDetail?.length > 0) {
        setValue("bankName", bankDetail[0]);
        setValue("branchName", bankDetail[1]);
        setValue("accountHolder", bankDetail[2]);
        setValue("accountNumber", bankDetail[3]);
        setValue("ifsc", bankDetail[4]);
      }
    }
  }, [register, unregister, selectedData]);

  const onSubmit = async () => {
    const {
      GSTpicture,
      PropertyPanNumber,
      chequepicture,
      firmTypeEntity,
      gstNumber,
      ownerAdharCertificate,
      ownerPanCertificate,
      panpicture,
      rentpicture,
      tanNumber,
      tanpicture,
      bankName,
      branchName,
      accountHolder,
      accountNumber,
      ifsc,
      entityName,
    } = getValues();

    let bodyFormData3 = new FormData();
    bodyFormData3.append("PropertyPanNumber", PropertyPanNumber);
    bodyFormData3.append("tanNumber", tanNumber);
    bodyFormData3.append("gstNumber", gstNumber);
    bodyFormData3.append("firmType", firmTypeEntity);
    bodyFormData3.append("legalName", entityName);
    bodyFormData3.append(
      "bankDetails",
      bankName +
        "~" +
        branchName +
        "~" +
        accountHolder +
        "~" +
        accountNumber +
        "~" +
        ifsc
    );
    if (rentpicture[0]) {
      bodyFormData3.append("rentAgreement", rentpicture?.[0]);
    }
    if (GSTpicture[0]) {
      bodyFormData3.append("gstCertificate", GSTpicture?.[0]);
    }
    if (tanpicture[0]) {
      bodyFormData3.append("tanCertificate", tanpicture?.[0]);
    }
    if (chequepicture[0]) {
      bodyFormData3.append("cancelCheque", chequepicture?.[0]);
    }
    if (ownerAdharCertificate[0]) {
      bodyFormData3.append("owneradharCertificate", ownerAdharCertificate?.[0]);
    }
    if (ownerPanCertificate[0]) {
      bodyFormData3.append("ownerpanCertificate", ownerPanCertificate?.[0]);
    }
    if (panpicture?.[0]) {
      bodyFormData3.append("PropertyPanCertificate", panpicture?.[0]);
    }

    try {
      let res = await Services.property.updateRRoomPropertyService({
        id: selectedData?.id,
        body: bodyFormData3,
      });

      if (res.status === true || res.status === 200) {
        setShow(false);
        dispatch(getRroomPropertyAction());
        resetData();
        setStep(1);
        if (editMode) {
          toast.success("Legal Entity Successfully Updated !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        } else {
          toast.success("Legal Entity Successfully Created !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
        setSelectedData({});
      }
    } catch (error) {}
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <span>Legal Entity</span>
      <Row className="popup-form onboardForm p-3">
        <Col xs={6} className="form-group">
          <label>Firm Type</label>
          <Controller
            name={"firmTypeEntity"}
            control={control}
            rules={{ required: "Please Select Firm Type*" }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <select value={value} onChange={onChange}>
                <option value={""}>select Firm</option>
                <option value={"Proprietorship"}>Proprietorship</option>
                <option value={"partnership"}>partnership</option>
                <option value={"corporation"}>corporation</option>
                <option value={"Private Limited"}>Private Limited</option>
              </select>
            )}
          />
          {errors?.firmTypeEntity?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"firmTypeEntity"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <InputField
            htmlFor="entityName"
            text="Legal Entity Name"
            type="text"
            placeholder="Enter GST number"
            field={"entityName"}
            register={register}
            errors={errors}
          />
          {errors?.entityName?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"entityName"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload Owner Adhar Certificate</label>
          <input
            {...register("ownerAdharCertificate")}
            type="file"
            accept="application/pdf"
            id="ownerAdharCertificate"
          />
          {errors?.ownerAdharCertificate?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"ownerAdharCertificate"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload Owner PAN Certificate</label>
          <input
            {...register("ownerPanCertificate")}
            type="file"
            accept="application/pdf"
            id="ownerPanCertificate"
          />
          {errors?.ownerPanCertificate?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"ownerPanCertificate"} />
              </span>
            </div>
          )}
        </Col>

        <Col xs={6} className="form-group">
          <InputField
            htmlFor="gstNumber"
            text="GST No. *"
            type="text"
            placeholder="Enter GST number"
            field={"gstNumber"}
            register={register}
            errors={errors}
          />
          {errors?.gstNumber?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"gstNumber"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload GST Certificate</label>
          <input
            {...register("GSTpicture")}
            type="file"
            accept="application/pdf"
            id="GSTpicture"
          />
          {errors?.GSTpicture?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"GSTpicture"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <InputField
            htmlFor="tanNumber"
            text="TAN No."
            type="text"
            placeholder="Enter Tan Number"
            field={"tanNumber"}
            register={register}
            errors={errors}
          />
          {errors?.tanNumber?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"tanNumber"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload TAN Document</label>
          <input
            {...register("tanpicture")}
            type="file"
            accept="application/pdf"
            id="tanpicture"
          />
          {errors?.tanpicture?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"tanpicture"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <InputField
            htmlFor="PropertyPanNumber"
            text="PAN No. *"
            type="text"
            placeholder="Enter PAN number"
            field={"PropertyPanNumber"}
            register={register}
            errors={errors}
          />
          {errors?.PropertyPanNumber?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"PropertyPanNumber"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload PAN Certificate</label>
          <input
            {...register("panpicture")}
            type="file"
            accept="application/pdf"
            id="panpicture"
            {...errors}
          />
          {errors?.panpicture?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"panpicture"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload Rent Agreement</label>
          <input
            {...register("rentpicture")}
            type="file"
            accept="application/pdf"
            id="rentpicture"
          />
          {errors?.rentpicture?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"rentpicture"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={6} className="form-group">
          <label>Upload Cancel Cheque</label>
          <input
            {...register("chequepicture")}
            type="file"
            accept="application/pdf"
            id="chequepicture"
          />
          {errors?.chequepicture?.message && (
            <div className="error-text">
              <img alt="" src={AlertIcon} />
              <span>
                <ErrorMessage errors={errors} name={"chequepicture"} />
              </span>
            </div>
          )}
        </Col>
        <Col xs={12} className="form-group mt-3">
          <h6>Bank account details</h6>
        </Col>
        <Col xs={12} className="form-group bankDetails">
          <Row>
            <Col xs={6} className="form-group">
              <InputField
                htmlFor="bankName"
                text="Bank Name"
                notnull
                type="text"
                placeholder="Enter bank name"
                field={"bankName"}
                register={register}
                errors={errors}
              />
              {errors?.bankName?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"bankName"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={6} className="form-group">
              <InputField
                htmlFor="branchName"
                text="Branch Name"
                type="text"
                notnull
                autoFocus={true}
                placeholder="Enter branch"
                field={"branchName"}
                register={register}
                errors={errors}
              />
              {errors?.branchName?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"branchName"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={6} className="form-group">
              <InputField
                htmlFor="accountHolder"
                text="Account Holder"
                type="text"
                notnull
                placeholder="Enter bank account name"
                field={"accountHolder"}
                register={register}
                errors={errors}
              />
              {errors?.accountHolder?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"accountHolder"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={6} className="form-group">
              <InputField
                htmlFor="accountNumber"
                text="Account Number"
                type="text"
                notnull
                autoFocus={true}
                placeholder="Enter account number"
                field={"accountNumber"}
                register={register}
                errors={errors}
              />
              {errors?.accountNumber?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"accountNumber"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={6} className="form-group">
              <InputField
                htmlFor="ifsc"
                text="IFSC"
                type="text"
                notnull
                autoFocus={true}
                placeholder="Enter IFSC code"
                field={"ifsc"}
                register={register}
                errors={errors}
              />
              {errors?.ifsc?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"ifsc"} />
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="form-group justify-content-between flex">
          {!editMode && (
            <Button
              variant="primary"
              type="button"
              onClick={() => setStep(step - 1)}
            >
              Previous
            </Button>
          )}
          {editMode ? (
            <Button variant="primary" type="button" onClick={() => onSubmit()}>
              Submit
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
}

export default Step3;
