import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import validation from "./validation";
import { Imagesdata } from "../../assets/images/imageData";
import { InputField } from "../../components/InputField/InputField";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Services from "../../API/services";
import { useLocation } from "react-router-dom";
import InputComponent from "./InputComponent";
import moment from "moment";
import {
  daysDiffernce,
  showDate,
  showPercentage,
  showPrice,
  taxOnly,
} from "../../utils/utils";
import { Margin, usePDF } from "react-to-pdf";
import {
  getBanquetBookingServices,
  getBanquetBookingVenue,
} from "API/services/newServices";

const MealPlanText = ["EP", "CP", "AP", "MAP"];

const Invoice = () => {
  const [editInvoice, setEditInvoice] = useState(false);
  const { AlertIcon } = Imagesdata || {};
  const { userDetail } = useSelector(authenticationSeletor);
  const { state } = useLocation();
  const [edit, setEdit] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [services, setServices] = useState([]);
  const [venues, setVenues] = useState([]);
  const [paymentMode, setPaymentMode] = useState(state?.paymentMode);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    ...validation,
    mode: "onSubmit",
  });

  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "roomInvoice.pdf",
    page: { margin: Margin.NONE, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 10,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  const getServices = async (id) => {
    const res = await getBanquetBookingServices(id);
    if (res) setServices(res);
  };
  const getVenueItem = async (id) => {
    const res = await getBanquetBookingVenue(id);
    if (res) setVenues(res);
  };

  useEffect(() => {
    if (state?.banquetData) {
      setValue("addre", state?.address);
      setValue("guestGst", state?.gst);
      setValue("cName", state?.companyName);
      setValue("cAddre", state?.address);
      getServices(state?.banquetBookingId);
      getVenueItem(state?.banquetBookingId);
    }

    setValue("date");
  }, []);
  const getInvoiceDetails = async () => {
    setLoading(true);
    const res = await Services.property.getInvoiceData(
      `?property_id=${userDetail?.propertyId}`
    );
    if (res.status === 200) {
      setLoading(false);
      if (res?.data?.data?.[0] == null) return;

      setInvoiceData(res?.data?.data[0]);
      if (Object.keys(res?.data?.data[0]).length > 0) {
        setValue("gstIn", res?.data?.data[0]?.gstNumber);
        setValue("gstCode", res?.data?.data[0]?.gstNumber);
        setEdit(true);
      }
    }
  };

  // const getData = async () => {
  //   if (state?.banquetData) {
  //     return;
  //   }
  //   const rooms = await Services.globalService.getRoomByPropertyIdService(
  //     userDetail?.propertyId
  //   );
  //   if (rooms.status === 200) {
  //     setRooms(rooms?.data?.data);
  //   }
  // };
  const getCategory = async () => {
    if (state?.banquetData) {
      return;
    }
    const res = await Services.property.getRoomCategoryService(
      userDetail?.propertyId
    );
    if (res.status === 200) {
      setCategoryName(
        res.data.data.find((i) => i.id == state.propertyRoomsCategoryId).name
      );
    }
  };

  const editHandler = () => {
    setEditInvoice(true);
    if (edit) {
      setValue("propertyName", invoiceData?.name);
      setValue("mobile", invoiceData?.phone);
      setValue("email", invoiceData?.email);
      setValue("gst", invoiceData?.gstNumber);
      setValue("mobileQuery", invoiceData?.queryContactNumber);
      setValue("address", invoiceData?.address);
      setValue("propertyContent", invoiceData?.content);
    }
  };

  const onSubmit = async ({
    address,
    email,
    gst,
    logo,
    mobile,
    mobileQuery,
    propertyContent,
    propertyName,
  }) => {
    var bodyData = new FormData();
    bodyData.append("property_id", userDetail?.propertyId);
    bodyData.append("logo", logo[0]);
    bodyData.append("name", propertyName);
    bodyData.append("address", address);
    bodyData.append("phone", mobile);
    bodyData.append("email", email);
    bodyData.append("gst_number", gst);
    bodyData.append("content", propertyContent);
    bodyData.append("query_contact_number", mobileQuery);
    // if (!edit) {
    //   if (logo.length <= 0 || logo[0].type !== "image/png") {
    //     setImgError("Please Select Property Logo only png formate");
    //     return;
    //   }
    // }
    try {
      let res = {};
      let message = "";
      if (edit) {
        res = await Services.property.reEditInvoiceData(
          bodyData,
          invoiceData?.id
        );
        message = "Invoice Details Successfully Updated!";
      } else {
        res = await Services.property.editInvoiceData(bodyData);
        message = "Invoice Details Successfully Submitted!";
      }
      if (res.status === 200) {
        toast.success(message, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        setEditInvoice(false);
      }
      reset();
    } catch (error) {}
  };

  useEffect(() => {
    if (state) {
      getCategory();
    }
    ReactGA.send({ hitType: "pageview", page: "invoice_details" });
    getInvoiceDetails();
    setValue("gstCode", invoiceData?.gstNumber);
    setValue("gstIn", invoiceData?.gstNumber);
  }, []);
  const totalDays = daysDiffernce(
    state?.checkInDateTime,
    state?.checkOutDateTime
  );

  console.log(state?.checkInDateTime, state?.checkOutDateTime, "this is time");

  const cgst = (state?.bookingAmout - state?.baseAmount) / 2;
  const sgst = (state?.bookingAmout - state?.baseAmount) / 2;

  const roomRate = state?.baseAmount / totalDays;
  const content = invoiceData?.content?.split(".");

  return (
    <Layout
      title="Invoice"
      openHandler={editHandler}
      buttonTitle="Edit Invoice"
      extraButton="Download PDF"
      extraBtnHandler={toPDF}
      withoutContainer
    >
      <div className="container flex invoice">
        {loading ? (
          <div
            className="flex flex-column align-center justify-content-center full-width text-black w-100"
            style={{ height: "10rem" }}
          >
            <FontAwesomeIcon
              className="mb-2"
              icon="fa-solid fa-spinner"
              style={{ width: "40px", height: "40px" }}
              spin
            />
            <span>Loading...</span>
          </div>
        ) : Object.keys(invoiceData).length ? (
          <Row className="justify-content-center overflow-auto bg-light w-100">
            <Col
              className="bg-white text-black my-2"
              xs={8}
              id="pdf"
              ref={targetRef}
            >
              <p className="text-end pr-2 pt-2 m-0 fs-6">
                GST IN: {invoiceData?.gstNumber ?? "N/A"}
              </p>
              <div className="p-3 mt-5">
                <div className="InvoicecontentPdf">
                  {invoiceData?.base64Image && (
                    <img
                      src={`data:image/png;base64,${invoiceData.base64Image}`}
                      alt="logo"
                    />
                  )}
                  <div>
                    <span className="HeadingInvoice">
                      {invoiceData?.name ?? "Property Name"}
                    </span>
                    <span>
                      {invoiceData?.address ?? "Your Property Address"}
                    </span>
                    <span>
                      Contact No.:{invoiceData?.phone ?? "Your Phone Number"}
                    </span>
                  </div>
                </div>
                <div className="">
                  <div className="flex justify-content-center  my-2">
                    <h3
                      className="bg-black text-white text-center d-inline px-3 pt-2"
                      style={{ letterSpacing: 0.5, fontFamily: "serif" }}
                    >
                      {state?.banquetData ? "BANQUET " : "TAX "} INVOICE
                    </h3>
                  </div>
                  <div>
                    <table className="w-100 border border-dark">
                      {!state?.banquetData && (
                        <>
                          <thead>
                            <th colSpan={2}>Bill No.</th>
                            <th>
                              {state?.banquetData ? "Service" : "Room No."}
                            </th>
                            <th>
                              {state?.banquetData ? "Function" : "Room Rate"}
                            </th>
                            {/* <th>GST IN</th> */}
                            <th>Date</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                <InputComponent
                                  name="bill"
                                  register={register}
                                  borderVis={
                                    watch().bill?.length > 0 ? false : true
                                  }
                                />
                              </td>
                              <td>
                                {(state?.banquetData
                                  ? venues?.serviceName
                                  : state?.assignRoomNo) ?? "N/A"}
                              </td>
                              <td className="text-center">
                                {(state?.banquetData
                                  ? venues?.functionName
                                  : showPrice(roomRate)) ?? "N/A"}
                              </td>
                              {/* <td className="text-center">
                              {invoiceData?.gstNumber ?? "N/A"}
                            </td> */}
                              <td className="text-center">
                                {moment(new Date()).format("D-MM-YYYY ")}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      )}
                      <thead>
                        <th colSpan={state?.banquetData ? 4 : 3}>
                          GUEST DETAILS
                        </th>
                        <th colSpan={state?.banquetData ? 5 : 2}>
                          COMPANY DETAILS
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={state?.banquetData ? 4 : 3}>
                            <div className="flex-column flex">
                              <span>
                                Guest Name:{" "}
                                {state?.otherPersonName ??
                                  state?.User?.name ??
                                  state?.customerName}
                              </span>
                              <span style={{ lineHeight: 0 }}>
                                Address:{" "}
                                <InputComponent
                                  name="addre"
                                  register={register}
                                  borderVis={
                                    watch()?.addre?.length > 0 ? false : true
                                  }
                                />
                              </span>
                              <span style={{ lineHeight: 0 }}>
                                GST Number:{" "}
                                <InputComponent
                                  name="guestGst"
                                  register={register}
                                  borderVis={
                                    watch()?.guestGst?.length > 0 ? false : true
                                  }
                                />
                              </span>
                            </div>
                          </td>
                          <td colSpan={state?.banquetData ? 5 : 2}>
                            <div className="flex-column flex">
                              <span style={{ height: 25 }}>
                                Company:{" "}
                                <InputComponent
                                  name="cName"
                                  register={register}
                                  borderVis={
                                    watch()?.cName?.length > 0 ? false : true
                                  }
                                />
                              </span>
                              <span>
                                Address:{" "}
                                <InputComponent
                                  name="cAddre"
                                  register={register}
                                  borderVis={
                                    watch()?.cAddre?.length > 0 ? false : true
                                  }
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      {!state?.banquetData ? (
                        <>
                          <thead>
                            <th>Guest{!state?.banquetData && " & Rooms"}</th>
                            <th>
                              {state?.banquetData ? "Session" : "Room Type"}
                            </th>
                            <th style={{ minWidth: 101 }}>Plan/Package</th>

                            <th colSpan={state?.banquetData ? 2 : 1}>
                              {state?.banquetData
                                ? "Reserve Date"
                                : "Arrival Date & Time"}
                            </th>
                            {!state?.banquetData && (
                              <th>Departure Date & Time</th>
                            )}
                          </thead>
                          <tbody>
                            <tr className="text-center">
                              <td>
                                {state?.banquetData
                                  ? state?.numberOfGuests ?? "0"
                                  : `${state?.adults} & ${state?.noOfRooms}`}
                              </td>
                              <td className="px-4">
                                {(state?.banquetData
                                  ? venues?.session
                                  : categoryName) ?? "N/A"}
                              </td>
                              <td className="px-4">
                                {MealPlanText[state?.breakFast]}
                              </td>
                              <td colSpan={state?.banquetData ? 2 : 1}>
                                {moment(
                                  state?.banquetData
                                    ? state?.reserveBookingDate
                                    : state?.checkInDateTime
                                ).format("D-M-Y, HH:MM")}
                              </td>

                              {!state?.banquetData && (
                                <td>
                                  {state?.bookingStatus === 3
                                    ? moment(state?.checkOutDateTime).format(
                                        "D-M-Y, HH:MM"
                                      )
                                    : "Yet to Check-Out"}
                                </td>
                              )}
                            </tr>
                          </tbody>
                          <thead>
                            <th colSpan={4}>Description</th>
                            <th colSpan={1}>Total</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                {state?.banquetData
                                  ? `Remark: ${services.serviceName}`
                                  : `Charges Per Day : ${showPrice(roomRate)}`}
                              </td>
                              <td colSpan={2}>Number Of Days: {totalDays}</td>
                              <td colSpan={1}>
                                {showPrice(state?.baseAmount)}
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={4}>
                                <div className="flex flex-column">
                                  {!state?.banquetData && (
                                    <>
                                      <span className="fw-semibold border-bottom">
                                        Discount :
                                        {showPrice(state?.discountAmount)}
                                      </span>
                                      <span className="fw-semibold border-bottom">
                                        Wallet Amount:{" "}
                                        {showPrice(state?.useWalletAmount)}
                                      </span>
                                    </>
                                  )}
                                  <span className="fw-semibold border-bottom">
                                    Extra Charges Total:{" "}
                                    {showPrice(
                                      state?.banquetData
                                        ? state?.extraCharge
                                        : state?.extra1?.price +
                                            state?.extra2?.price
                                    )}
                                  </span>
                                  Charges Details:
                                  {!!state?.extra1?.type.length && (
                                    <div className="">
                                      <span className="fw-normal text-gray">
                                        {state?.extra1?.type?.toString()}:
                                      </span>
                                      <span className="fw-medium text-black ml-2">
                                        {showPrice(state?.extra1?.price)}
                                      </span>
                                    </div>
                                  )}
                                  {!!state?.extra2?.type.length && (
                                    <div className="">
                                      <span className="fw-normal text-gray">
                                        {state?.extra2?.type?.toString()}:
                                      </span>
                                      <span className="fw-medium text-black ml-2">
                                        {showPrice(state?.extra2?.price)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td colSpan={1}>
                                <div className="flex flex-column">
                                  {state?.banquetData ? (
                                    <span>
                                      Total Tax: {showPrice(state?.totalTax)}
                                    </span>
                                  ) : (
                                    <>
                                      <span>CGST: {showPrice(cgst)}</span>
                                      <span>
                                        SGST:{" "}
                                        {/* {taxData?.amount / 2}%{" "} */}
                                        {showPrice(sgst)}
                                      </span>
                                    </>
                                  )}
                                  <span>
                                    TOTAL:{" "}
                                    {showPrice(
                                      state?.banquetData
                                        ? state?.finalAmount
                                        : state?.bookingAmout +
                                            state?.extra1?.price +
                                            state?.extra2?.price
                                    )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ) : (
                        <>
                          <thead>
                            <th>Venue</th>
                            <th>Booking Date </th>
                            <th>Start Time </th>
                            <th>End Time</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Gst</th>
                            <th>Tax</th>
                            <th>Total</th>
                          </thead>
                          <tbody>
                            {venues?.map((venue) => (
                              <tr className="text-center">
                                <td>{venue.serviceName}</td>
                                <td>{showDate(venue.reservedDate)}</td>
                                <td>{venue.reserveStartTime}</td>
                                <td>{venue.reserveStartTime}</td>
                                <td>{showPrice(venue.rate)}</td>
                                <td>
                                  {showPercentage(venue.discountPercentage)}
                                </td>
                                <td>{showPercentage(venue.gstPercentage)}</td>
                                <td>
                                  {showPrice(
                                    taxOnly(
                                      venue.totalAmount,
                                      venue.gstPercentage
                                    )
                                  )}
                                </td>
                                <td>{showPrice(venue.totalAmount)}</td>
                              </tr>
                            ))}
                          </tbody>
                          <thead>
                            <th>Booking Date</th>
                            <th>Service</th>
                            <th>Rate</th>
                            <th>Quantity</th>
                            <th>Discount</th>
                            <th>Gst</th>
                            <th colSpan={3}>Net Amount</th>
                          </thead>
                          <tbody className="text-center">
                            {services?.map((venue) => (
                              <tr>
                                <td>{showDate(venue.reservedDate)}</td>
                                <td>{venue.serviceName}</td>
                                <td>{showPrice(venue.rate)}</td>
                                <td>{venue.quantity}</td>
                                <td>
                                  {showPercentage(venue.discountPercentage)}
                                </td>
                                <td>{showPercentage(venue.gstPercentage)}</td>
                                <td colSpan={3}>
                                  {showPrice(venue.totalAmount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <thead>
                            <th colSpan={8}>Remark</th>
                            <th colSpan={1}>Guests</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={8}>{state?.remark}</td>
                              <td colSpan={1}>{state?.numberOfGuests}</td>
                            </tr>

                            <tr>
                              <td colSpan={4}>
                                <div className="flex flex-column">
                                  <span>
                                    Extra Charges :{" "}
                                    {showPrice(state?.extraCharge)}
                                  </span>
                                  <span>
                                    Venue Taxable Amount:{" "}
                                    {showPrice(state?.venueTaxableAmount)}
                                  </span>
                                  <span>
                                    Venue Tax Amount:{" "}
                                    {showPrice(state?.venueTaxAmount)}
                                  </span>
                                  <span>
                                    Total Venue Amount:{" "}
                                    {showPrice(state?.venueTotalAmount)}
                                  </span>
                                  <span>
                                    Service Taxable Amount:{" "}
                                    {showPrice(state?.serviceTaxableAmount)}
                                  </span>
                                  <span>
                                    Service Tax Amount:{" "}
                                    {showPrice(state?.serviceTaxAmount)}
                                  </span>
                                  <span>
                                    Service Total Amount:{" "}
                                    {showPrice(state?.serviceTotalAmount)}
                                  </span>
                                </div>
                              </td>
                              <td colSpan={6}>
                                <div className="flex flex-column">
                                  <span>
                                    Total Tax: {showPrice(state?.totalTax)}
                                  </span>
                                  <div className="summaryCard d-flex gap-1">
                                    Paid Amount: {showPrice(state?.paidAmount)}
                                  </div>
                                  <div className="summaryCard d-flex gap-1">
                                    Due Amount : {showPrice(state?.dueAmount)}
                                  </div>
                                  <div className="summaryCard d-flex gap-1 fw-semibold">
                                    Net Payable Amount:{" "}
                                    {showPrice(state?.finalAmount)}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      )}
                      <thead>
                        <th colSpan={state?.banquetData ? 10 : 5}>
                          {state?.banquetData
                            ? "Payment Method"
                            : "PLEASE RETURN YOUR KEY ON DEPARTURE"}
                        </th>
                      </thead>
                      <tbody>
                        <tr className="invoiceInputTd">
                          <td>Payment Mode:</td>
                          <td>
                            <input
                              type="checkbox"
                              name="payMode"
                              id="payMode1"
                              onChange={() => setPaymentMode(0)}
                              checked={paymentMode === 0 ? true : false}
                            />
                            <label htmlFor="payMode1">Cash</label>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name="payMode"
                              id="payMode2"
                              onChange={() => setPaymentMode(3)}
                              checked={paymentMode === 3 ? true : false}
                            />
                            <label htmlFor="payMode2">Credit Card</label>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name="payMode"
                              id="payMode3"
                              onChange={() => setPaymentMode(4)}
                              checked={paymentMode === 4 ? true : false}
                            />
                            <label htmlFor="payMode3">Bill to Company</label>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name="payMode"
                              id="payMode4"
                              onChange={() => setPaymentMode(1)}
                              checked={paymentMode === 1 ? true : false}
                            />
                            <label htmlFor="payMode4">UPI</label>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {state?.banquetData ? null : (
                      <div className="flex mt-3">
                        <Col xs={7}>
                          {content.map((item, ind) => (
                            <div key={ind}>
                              {item}
                              {content.length === ind + 1 ? "" : "."}
                            </div>
                          ))}
                          <div>
                            Any Suggestion/Query Then Contact:____________
                          </div>
                        </Col>
                        <Col xs={5} className="border p-3 h-50">
                          <Row>
                            <Col xs={2}>
                              <FontAwesomeIcon
                                icon="fa-solid fa-key"
                                size="2xl"
                              />
                            </Col>
                            <Col xs={10} className="mb-3">
                              <span
                                className="fs-5 fw-bold"
                                style={{ fontFamily: "serif" }}
                              >
                                PLEASE REMEMBER TO LEAVE YOUR KEYS
                              </span>
                            </Col>
                          </Row>
                          <div>
                            <span>
                              Checked and found correctly kindly send bill
                            </span>
                            <span>
                              To,
                              M/s.............................................................
                            </span>
                          </div>
                        </Col>
                      </div>
                    )}

                    <div className="mt-3">
                      <div className="flex justify-content-between ">
                        <span>Guest Signature</span>
                        <span>Cashier Signature</span>
                      </div>
                      <div className="w-100 text-center">
                        <span>
                          ................................ thank you for
                          honouring us by your visit
                          ................................
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <div
            className="flex flex-column align-center justify-content-center full-width text-black w-100"
            style={{ height: "10rem" }}
          >
            <FontAwesomeIcon
              className="mb-2"
              icon="fa-solid fa-file-invoice"
              style={{ width: "40px", height: "40px" }}
              beat
            />
            <div className="flex align-items-center">
              <span>Submit details for invoice to print, </span>
              <button
                className="btn btn-sm btn-primary ml-3"
                onClick={editHandler}
              >
                Add Details
              </button>{" "}
            </div>
          </div>
        )}
      </div>

      <Modal
        backdrop="static"
        show={editInvoice}
        onHide={() => {
          setEditInvoice(false);
          reset();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <h6 className="text-dark">Invoice Header :</h6>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="propertyName"
                    text="Property Name"
                    type="text"
                    placeholder="Enter Property Name"
                    field={"propertyName"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.propertyName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          notnull
                          name={"propertyName"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="mobile"
                    text="Property Mobile Number"
                    type="text"
                    max={10}
                    placeholder="Enter Property Mobile Number"
                    field={"mobile"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.mobile?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} notnull name={"mobile"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="email"
                    text="Property Email ID"
                    type="text"
                    placeholder="Enter Property Email ID"
                    field={"email"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.email?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} notnull name={"email"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="gst"
                    text="Property GST Number"
                    type="text"
                    placeholder="Enter Property GST Number"
                    field={"gst"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.gst?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} notnull name={"gst"} />
                      </span>
                    </div>
                  )}
                </Col>

                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="logo"
                    text="Property Logo"
                    type="file"
                    placeholder="Enter Property Logo"
                    field={"logo"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="address"
                    text="Property Address"
                    type="textArea"
                    placeholder="Enter Property Address"
                    field={"address"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.address?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>{errors?.address?.message}</span>
                    </div>
                  )}
                </Col>
                <h6 className="text-dark">Invoice Footer :</h6>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="mobileQuery"
                    text="Property Mobile Number"
                    type="text"
                    max={10}
                    placeholder="Enter Property Mobile Number"
                    field={"mobileQuery"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.mobileQuery?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          notnull
                          name={"mobileQuery"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="propertyContent"
                    text="Property Content"
                    type="textArea"
                    placeholder="Enter Property Content"
                    field={"propertyContent"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.propertyContent?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>{errors?.propertyContent?.message}</span>
                    </div>
                  )}
                </Col>
                <Row className="justify-content-center">
                  <Col xs={1}>
                    <Button type="submit">Submit</Button>
                  </Col>
                </Row>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Invoice;
