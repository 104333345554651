import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>HOW IT WORKS</h1>
          <p>
            RRooms is a platform that allows hoteliers and managers to list
            their properties, manage bookings, and access tools and services to
            increase their visibility and revenue.
          </p>
          <h2>1. Registration and Account Setup</h2>
          <p>
            Hoteliers start by creating an account on the RRooms portal/app.
            They provide essential information about their property, including
            its name, location, contact details, and amenities.
          </p>
          <h2>2. Profile Creation</h2>
          <p>
            After registration, hoteliers complete their profiles by adding
            high-quality images, descriptions, room types, rates, and policies
            for their property.
          </p>
          <h2>3. Inventory Management</h2>
          <p>
            Hoteliers can manage their room inventory through the portal,
            setting availability, rates, and restrictions for different dates
            and room types.
          </p>
          <h2>4. Booking Engine Integration</h2>
          <p>
            The portal/app provides a booking engine allowing real-time
            availability and instant booking confirmation for guests.
          </p>
          <h2>5. Pricing and Revenue Management</h2>
          <p>
            Hoteliers can set dynamic pricing strategies based on factors like
            demand, seasonality, and local events. RRooms portal/app also offers
            revenue management tools and insights to optimize pricing.
          </p>
          <h2>6. Calendar and Availability</h2>
          <p>
            Hoteliers can use a calendar view to check and adjust room
            availability, ensuring accurate and up-to-date information for
            potential guests.
          </p>
          <h2>7. Reservation Management</h2>
          <p>
            The portal/app allows hotel owners to view, modify, and manage
            reservations made through their platform. They can also communicate
            with guests regarding special requests or changes.
          </p>
          <h2>8. Payment Processing</h2>
          <p>
            The portal/app facilitates secure payment processing, allowing hotel
            owners to receive payments from guests directly or through the
            portal's/app’s payment gateway.
          </p>
          <h2>9. Review and Rating Monitoring</h2>
          <p>
            Hoteliers can track and respond to guest reviews and ratings left on
            the portal, helping to improve their property's reputation.
          </p>
          <h2>10. Marketing and Promotion</h2>
          <p>
            The portal/app offers marketing tools and services to help hoteliers
            boost their property's visibility, such as featured listings,
            promotional offers, and partnerships with other platforms.
          </p>
          <h2>11. Analytics and Reporting</h2>
          <p>
            Hoteliers can access performance analytics and reports to assess
            their property's occupancy rates, revenue, and booking trends.
          </p>
          <h2>12. Customer Support</h2>
          <p>
            The portal/app provides customer support and assistance to hoteliers
            to address any technical issues, inquiries, or concerns.
          </p>
          <h2>13. Training and Resources</h2>
          <p>
            Hoteliers will have access to training materials and resources
            provided by the RRooms to help them optimize their listings and use
            the platform effectively.
          </p>
          <h2>14. Feedback and Improvement</h2>
          <p>
            RRooms values feedback from hoteliers and continuously updates its
            features and services to better meet their needs and preferences.
          </p>
          <h2>15. Payment Settlement</h2>
          <p>
            The portal/app settles payments to hoteliers, deducting any
            commissions or fees owed to the platform based on the agreed-upon
            terms.
          </p>
          <h2>16. Legal and Regulatory Compliance</h2>
          <p>
            RRooms ensures that hoteliers comply with local and international
            regulations, such as tax collection and data protection laws. RRooms
            simplifies the process of listing, managing, and marketing their
            properties to a wider audience. It provides a comprehensive set of
            tools and services to enhance their online presence, increase
            bookings, and ultimately grow their business.
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default HowItWorks;
