import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .trim()
    .max(100)
    .email(addUserValidation.valid_email)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addUserValidation.valid_email
    )
    .required(addUserValidation.email_required),

  firstName: Yup.string()
    .trim()
    .required(addUserValidation.addUser.first_name_required)
    .matches(/^[A-Za-z ]+$/, addUserValidation.valid_name)
    .max(200, addUserValidation.max_firstname_length),

  lastName: Yup.string()
    .trim()
    .required(addUserValidation.addUser.last_name_required)
    .matches(/^[A-Za-z ]+$/, addUserValidation.valid_name)
    .max(200, addUserValidation.max_lastname_length),
  phoneNumber: Yup.string()
    .required(addUserValidation.phone_invalid)
    .test(
      "len",
      "Phone number needs to be excatly 10 digits",
      (val) => val.toString().length === 10
    )
    .matches(/^[0-9]+$/, "Enter Correct Phone Number*"),
  propertyName: Yup.string().required(addUserValidation.propertyName),
  address: Yup.string().required(addUserValidation.address),
  postalCode: Yup.string()
    .trim()
    .required(addUserValidation.postalCode)
    .max(6, "Enter Correct Pincode*")
    .min(6, "Enter Correct Pincode*")
    .matches(/^[0-9]+$/, "Enter Correct Pincode*"),
  city: Yup.string().required("Please Select City"),
});

export default { resolver: yupResolver(validation) };
