import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/style.scss";
import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import pages from "./pages";
import Interceptor from "./API/Interceptor/Interceptor";

import Dashboard from "./pages/Dashboard";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "./Common/ProtectedRoute";
import FocRequest from "./pages/Foc";
import { createBrowserHistory } from "history";
import { setFilter } from "./store/slices/propertySlice";
import { useDispatch } from "react-redux";
import UserRoutes from "./Common/UserRoutes";
import FoodInvoiceBill from "pages/foodinvoice/FoodInvoice";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import GrcDownload from "pages/Dashboard/AllBookings/components/GrcDownload";

ReactGA.initialize("G-S9FK919WJH");

const {
  Home,
  Login,
  Register,
  Property,
  Propertydetails,
  Landingpage,
  Hotallistings,
  Bookhotel,
  Payment,
  Hotaldetails,
  AdminLogin,
  RRoomsUsers,
  UserProfile,
  BookingConfirm,
  BookingCancel,
  PropertyCategory,
  RoomCategory,
  PropertyUsers,
  Amenities,
  Customer,
  CustomerEnquiry,
  CreateInventory,
  CreateInventorySupplier,
  CreateInventoryItem,
  InventoryCategory,
  FoodCategory,
  FoodsItems,
  FoodsOrders,
  CouponPage,
  CustomerReviews,
  TaxSettings,
  RoomPriceSetting,
  MenuCard,
  NcTypeSetting,
  LaundryProvider,
  LaundryService,
  Laundry,
  UserLogins,
  SearchResult,
  Invoice,
  RoomPrice,
  CancellationRefundPolicy,
  TermsAndConditions,
  Contact,
  MenuCategory,
  BreakFast,
  FoodInvoice,
  About,
  Career,
  HelpCenter,
  Blog,
  HowItWorks,
  Loyalty,
  Community,
  PromotionEvents,
  AffiliateProgram,
  PartnershipProgram,
  FAQ,
  BookingReport,
  RatingReport,
  TransactionReport,
  PaymentReport,
  UserAgreement,
  ManageRoles,
  FoodReport,
  FoodItemsReport,
  CityLocality,
  HotelSummery,
  InventoryReport,
  InvoiceDownload,
  Sale,
  InvoiceFinance,
  InvoicePayment,
  ModuleConfiguration,
  ResturantOrders,
  ResturantMenu,
  ResturantTable,
  ResturantPayment,
  BunquetFunction,
  BunquetBookings,
  BunquetInvoice,
  BunquetPayment,
  AgreementProperty,
  GuestDetails,
  BanquetMenu,
  BunquetEnquiry,
  AddBunquetBookings,
  ModuleConfigurationAdmin,
  BunquetBookingDetails,
  Expenses,
  RevenueReport,
} = pages;

function App() {
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const queryClient = new QueryClient();

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyA74KcvlNTTWtDOekk9RlHbuUneSZV7UJw",
    authDomain: "rrooms-4acba.firebaseapp.com",
    projectId: "rrooms-4acba",
    storageBucket: "rrooms-4acba.appspot.com",
    messagingSenderId: "612761860432",
    appId: "1:612761860432:web:64f9cf6850162c4441d455",
    measurementId: "G-S9FK919WJH",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  useEffect(() => {
    let getLocation = history.location.pathname;

    if (getLocation != "/hotal-listings") {
      dispatch(
        setFilter({
          propertyCategoryFilter: [],
          roomCategoryFilter: [],
          amenitiesFilter: [],
        })
      );
    }
  }, [history]);

  return (
    <div className="App">
      <Interceptor />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/hotel-details/:id" element={<Hotaldetails />} />
          <Route
            path="book-hotel/:hotelId"
            element={
              <UserRoutes>
                <Bookhotel />
              </UserRoutes>
            }
          />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route
            path="/user-profile"
            element={
              <UserRoutes ifNoLogin={true}>
                <UserProfile />
              </UserRoutes>
            }
          />
          <Route
            path="/payment"
            element={
              <UserRoutes>
                <Payment />
              </UserRoutes>
            }
          />
          <Route
            path="/booking-confirm/:id"
            element={
              <UserRoutes>
                <BookingConfirm />
              </UserRoutes>
            }
          />
          <Route
            path="/booking-cancel"
            element={
              <UserRoutes>
                <BookingCancel />
              </UserRoutes>
            }
          />
          <Route path="/hotal-listings" element={<Hotallistings />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/How-it-Works" element={<HowItWorks />} />
          <Route path="/Partnership-program" element={<PartnershipProgram />} />
          <Route path="/Affiliate-program" element={<AffiliateProgram />} />
          <Route path="/Promotion-events" element={<PromotionEvents />} />
          <Route path="/Community" element={<Community />} />
          <Route path="/Loyalty" element={<Loyalty />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route
            path="/property-agreement-accept/:id"
            element={<AgreementProperty />}
          />
          <Route
            path="/cancellation-refund-policy"
            element={<CancellationRefundPolicy />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          {/* <Route path="/Rrooms-privacy-policy" element={<PrivacyPolicy />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="*" element={NotFound} />
        </Routes>
        {/* <ProtectedRoute> */}
        <Routes>
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/booking/download-grc/:id"
            element={
              <ProtectedRoute>
                <GrcDownload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property-details/:id"
            element={
              <ProtectedRoute>
                <Property />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property-listing"
            element={
              <ProtectedRoute>
                <Propertydetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rrooms-users"
            element={
              <ProtectedRoute>
                <RRoomsUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rrooms/module-configuration"
            element={<ModuleConfigurationAdmin />}
          />
          <Route
            path="/manage-roles"
            element={
              <ProtectedRoute>
                <ManageRoles />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property-category"
            element={
              <ProtectedRoute>
                <PropertyCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/room-category"
            element={
              <ProtectedRoute>
                <RoomCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property-users"
            element={
              <ProtectedRoute>
                <PropertyUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/amenities"
            element={
              <ProtectedRoute>
                <Amenities />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer"
            element={
              <ProtectedRoute>
                <Customer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/enquiry-customer"
            element={
              <ProtectedRoute>
                <CustomerEnquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-inventory"
            element={
              <ProtectedRoute>
                <CreateInventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory-supplier"
            element={
              <ProtectedRoute>
                <CreateInventorySupplier />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory-item"
            element={
              <ProtectedRoute>
                <CreateInventoryItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory-category"
            element={
              <ProtectedRoute>
                <InventoryCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/food-category"
            element={
              <ProtectedRoute>
                <FoodCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/food-items"
            element={
              <ProtectedRoute>
                <FoodsItems />
              </ProtectedRoute>
            }
          />
          <Route
            path="/food-orders"
            element={
              <ProtectedRoute>
                <FoodsOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/restaurant/Restaurant-food-orders"
            element={
              <ProtectedRoute>
                <ResturantOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/restaurant/Restaurant-food-orders/:id"
            element={
              <ProtectedRoute>
                <ResturantOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/restaurant/Restaurant-menu-mangement"
            element={
              <ProtectedRoute>
                <ResturantMenu />
              </ProtectedRoute>
            }
          />
          <Route
            path="/restaurant/Restaurant-table-mangement"
            element={
              <ProtectedRoute>
                <ResturantTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/restaurant/Restaurant-payment"
            element={
              <ProtectedRoute>
                <ResturantPayment />
              </ProtectedRoute>
            }
          />

          <Route
            path="/banquet/banquet-function-type"
            element={
              <ProtectedRoute>
                <BunquetFunction />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-menu-item"
            element={
              <ProtectedRoute>
                <BanquetMenu />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-enquiry"
            element={
              <ProtectedRoute>
                <BunquetEnquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-bookings-enquiry/:id"
            element={
              <ProtectedRoute>
                <BunquetEnquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-bookings"
            element={
              <ProtectedRoute>
                <BunquetBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-bookings-report/:id"
            element={
              <ProtectedRoute>
                <BunquetBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-bookings/details/:id"
            element={<BunquetBookingDetails />}
          />
          <Route
            path="/banquet/add-banquet-bookings/:banquetId?"
            element={<AddBunquetBookings />}
          />
          <Route
            path="/banquet/banquet-invoice"
            element={
              <ProtectedRoute>
                <BunquetInvoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banquet/banquet-payment"
            element={
              <ProtectedRoute>
                <BunquetPayment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts/expenses"
            element={
              <ProtectedRoute>
                <Expenses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts/revenue-report"
            element={
              <ProtectedRoute>
                <RevenueReport />
              </ProtectedRoute>
            }
          />

          <Route
            path="/breakfast"
            element={
              <ProtectedRoute>
                <BreakFast />
              </ProtectedRoute>
            }
          />
          <Route
            path="/coupon"
            element={
              <ProtectedRoute>
                <CouponPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer-reviews"
            element={
              <ProtectedRoute>
                <CustomerReviews />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tax-setting"
            element={
              <ProtectedRoute>
                <TaxSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/roomprice-setting"
            element={
              <ProtectedRoute>
                <RoomPriceSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotel-finance/summary"
            element={
              <ProtectedRoute>
                <HotelSummery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotel-finance/sale"
            element={
              <ProtectedRoute>
                <Sale />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotel-finance/invoice-formate/:id/:propertyId"
            element={
              <ProtectedRoute>
                <InvoiceDownload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotel-finance/invoice"
            element={
              <ProtectedRoute>
                <InvoiceFinance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/property-users/module-configuration/:id"
            element={
              <ProtectedRoute>
                <ModuleConfiguration />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoice-payment-confirm/:id"
            element={
              <ProtectedRoute>
                <InvoicePayment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nc-type-setting"
            element={
              <ProtectedRoute>
                <NcTypeSetting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/menu-card"
            element={
              <ProtectedRoute>
                <MenuCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/laundry-provider"
            element={
              <ProtectedRoute>
                <LaundryProvider />
              </ProtectedRoute>
            }
          />
          <Route
            path="/laundry-service"
            element={
              <ProtectedRoute>
                <LaundryService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/laundry"
            element={
              <ProtectedRoute>
                <Laundry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/foc-request"
            element={
              <ProtectedRoute>
                <FocRequest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-logins"
            element={
              <ProtectedRoute>
                <UserLogins />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/guest-details"
            element={
              <ProtectedRoute>
                <GuestDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search-result"
            element={
              <ProtectedRoute>
                <SearchResult />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Invoice"
            element={
              <ProtectedRoute>
                <Invoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Food-Invoice"
            element={
              <ProtectedRoute>
                <FoodInvoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Food-Invoice-Bill"
            element={
              <ProtectedRoute>
                <FoodInvoiceBill />
              </ProtectedRoute>
            }
          />
          <Route
            path="/room-price"
            element={
              <ProtectedRoute>
                <RoomPrice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/menu-category"
            element={
              <ProtectedRoute>
                <MenuCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/booking-report"
            element={
              <ProtectedRoute>
                <BookingReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/rating-report"
            element={
              <ProtectedRoute>
                <RatingReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/transaction-report"
            element={
              <ProtectedRoute>
                <TransactionReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-report"
            element={
              <ProtectedRoute>
                <PaymentReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/food-report"
            element={
              <ProtectedRoute>
                <FoodReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/food-items-report"
            element={
              <ProtectedRoute>
                <FoodItemsReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory-report"
            element={
              <ProtectedRoute>
                <InventoryReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/city-locality"
            element={
              <ProtectedRoute>
                <CityLocality />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={NotFound} />
        </Routes>
        {/* </ProtectedRoute> */}
      </QueryClientProvider>
    </div>
  );
}
const NotFound = () => {
  return (
    <>
      <h1>Page not found</h1>
      <Link to={"/"}>Go To Home Page</Link>
    </>
  );
};

export default App;
