import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addEditSupplierItems,
  deleteSupplierItem,
  getSupplierItem,
} from "API/services/newServices";

const InventorySupplier = () => {
  const { userDetail } = useSelector(authenticationSeletor);

  const [tableData, setTableData] = useState([]);

  const { AlertIcon } = Imagesdata || {};

  const [openAddModal, setOpenAddModal] = useState(false);
  const [show, setShow] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [assigndItems, setAssigndItems] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const { fields, append, remove, replace } = useFieldArray({
    name: "supplierItems",
    control,
  });
  const hideAddModalHanlder = () => {
    setOpenAddModal(false);
    reset();
    remove();
  };

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getSupplierByPropertyIdService(
        id
      );
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };
  const getItems = async () => {
    try {
      const res =
        await services.globalService.getInventoryItemByPropertyIdService(
          userDetail.propertyId
        );
      if (res.status === 200) {
        setItems(res.data.data);
        setAllItems(res.data.data);
      }
    } catch (error) {}
  };
  const getAssignedItems = async (id) => {
    remove();
    try {
      const res = await getSupplierItem(id);
      if (res) {
        setAssigndItems(res);
        res.map((item) => {
          append({
            assignedId: item.id,
            itemId: item.itemId,
            itemName: items.find((i) => i.id == item.itemId)?.itemName ?? "N/A",
            price: ~~item.price,
            categoryId: item.categoryId,
          });
        });
      }
    } catch (error) {}
  };

  const { searchItems } = watch();

  const filterFoodItems = (query) => {
    let text = query?.toLowerCase();
    const results = allItems.filter((item) => {
      if (text == "") return allItems;

      return item.itemName.toLowerCase().includes(text);
    });
    setItems(results);
  };

  useEffect(() => {
    if (searchItems) {
      filterFoodItems(searchItems);
    }
  }, [searchItems]);

  const handleAddItem = async (item) => {
    append({
      assignedId: 0,
      itemId: item.id,
      itemName: item.itemName,
      price: ~~item.price,
      categoryId: item.categoryId,
    });
  };

  const onSubmitSupplierItems = (payload) => {
    const param = {
      editMode,
    };
    setDisabled(true);
    payload.supplierItems.map(async (item) => {
      if (!assigndItems.some((i) => i.id == item.assignedId)) {
        param.body = {
          propertyId: userDetail.propertyId,
          categoryId: item.categoryId,
          suplierId: selectedData.id,
          itemId: item.itemId,
          price: item.price,
          status: 0,
          createdBy: userDetail.id,
        };
        await addEditSupplierItems(param);
      }
    });
    setTimeout(() => {
      setViewOnly(true);
      getAssignedItems(selectedData.id);
      setDisabled(false);
    }, 1500);
  };

  const onSubmit = async (payload) => {
    const realPayload = {
      name: payload.supplierName,
      propertyId: userDetail?.propertyId,
      email: payload.suplieremail,
      mobile: payload.suplierPhoneNumber,
      address: payload.address,
      adharNumber: payload.aadhaarNumber,
      panNumber: payload.panNo,
      gst: payload.gstno,
      bankName: payload.bankName,
      branchName: payload.branchName,
      accountName: payload.accountHolder,
      accountNumber: payload.accountNumber,
      ifsc: payload.ifsc,
      createdBy: userDetail.id,
    };

    try {
      let res = {};
      if (editMode) {
        const editPayload = {
          body: realPayload,
          id: selectedData?.id,
        };
        res = await services.globalService.editSupplierService(editPayload);
      } else {
        res = await services.globalService.createSupplierService(realPayload);
      }
      if (res.status === 200) {
        reset();
        setEditMode(false);
        getDataHandler(userDetail?.propertyId);
        setShow(false);
        setSelectedData({});
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error("Supplier Email & Mobile no. already Exist*", {
          autoClose: 2000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    }
  };

  const removeItem = async (key, id) => {
    if (window.confirm("Are you sure to delete this item?")) {
      if (assigndItems.some((i) => i.id == id)) {
        const res = await deleteSupplierItem(id);
        if (res) {
          remove(key);
        }
      } else {
        remove(key);
      }
    }
  };

  const openSupplierItemsAddHandler = (data) => {
    setSelectedData(data);
    getItems();
    getAssignedItems(data.id);
    setOpenAddModal(true);
  };
  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);
    setValue("supplierName", data.name);
    setValue("suplieremail", data.email);
    setValue("suplierPhoneNumber", data.mobile);
    setValue("address", data.address);
    setValue("aadhaarNumber", data.adharNumber);
    setValue("panNo", data.panNumber);
    setValue("gstno", data.gst);
    setValue("bankName", data.bankName);
    setValue("branchName", data.branchName);
    setValue("accountHolder", data.accountName);
    setValue("accountNumber", data.accountNumber);
    setValue("ifsc", data.ifsc);
    setEditMode(true);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "inventory_supplier" });
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
  }, [userDetail]);
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "supplier",
    sheet: "supplier",
  });

  const searchfilter = (text) => {
    const results = tableData.filter((order) => {
      if (text === "") return tableData;
      return (
        order.accountName +
        order.accountNumber +
        order.adharNumber +
        order.bankName +
        order.email +
        order.mobile +
        order.name +
        order.gst +
        order.panNumber
      )
        .toString()
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterData(results);
  };

  useEffect(() => {
    setFilterData(tableData);
  }, [tableData]);
  return (
    <Layout
      title="Inventory Suppliers"
      openHandler={() => setShow(true)}
      extraBtnHandler={onDownload}
      searchfilter={searchfilter}
      extraButton="Export Table"
    >
      <div className="scrollTable">
        <Table striped bordered hover className="common-table" ref={tableRef}>
          <thead>
            <tr>
              <th>SR No.</th>
              <th>Supplier Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Alternate Mobile</th>
              <th>PAN No.</th>
              <th>GST No.</th>
              <th style={{ minWidth: 250 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((data, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{data.name}</td>
                <td>{data.email ?? "N/A"}</td>
                <td>{data.mobile}</td>
                <td>{data.address}</td>
                <td>{data.adharNumber ?? "N/A"}</td>
                <td>{data.panNumber}</td>
                <td>{data.gst ?? "N/A"}</td>
                <td>
                  <Button
                    variant="success"
                    type="button"
                    className="btn-sm mr-2"
                    onClick={() => openSupplierItemsAddHandler(data)}
                  >
                    Add/View Items
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    className="btn-sm"
                    onClick={() => editHandler(data)}
                  >
                    {userDetail?.Role?.canEdit == 1 ? "View/Edit" : "View"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        backdrop="static"
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Inventory Supplier" : "Create Inventory Supplier"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="supplierName"
                    text="Supplier Name"
                    type="text"
                    notnull
                    autoFocus={true}
                    placeholder="Enter supplier name"
                    field={"supplierName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.supplierName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"supplierName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="suplieremail"
                    text="Email Address "
                    type="email"
                    placeholder="Enter email address"
                    field={"suplieremail"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.suplieremail?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"suplieremail"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="suplierPhoneNumber"
                    text="Phone Number"
                    notnull
                    type="text"
                    placeholder="Enter phone number"
                    field={"suplierPhoneNumber"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.suplierPhoneNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"suplierPhoneNumber"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="aadhaarNumber"
                    text="Alternate Mobile"
                    type="text"
                    placeholder="Enter Alternate number"
                    field={"aadhaarNumber"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.aadhaarNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"aadhaarNumber"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="panNo"
                    text="PAN No."
                    type="text"
                    placeholder="Enter Pan Number"
                    field={"panNo"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.panNo?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"panNo"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="gstno"
                    text="GST No."
                    type="text"
                    placeholder="Enter GST number"
                    field={"gstno"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.gstno?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"gstno"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <label>
                    Address
                    {/* <small className="text-danger fw-bolder fs-6">*</small> */}
                  </label>
                  <textarea
                    // register={register}
                    {...register("address")}
                    placeholder={"Enter full address"}
                  ></textarea>
                  {errors?.address?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"address"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group mt-3">
                  <h6>Bank account details</h6>
                </Col>
                <Col xs={12} className="form-group bankDetails">
                  <Row>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="bankName"
                        text="Bank Name"
                        type="text"
                        placeholder="Enter bank name"
                        field={"bankName"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.bankName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"bankName"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="branchName"
                        text="Branch Name"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter branch"
                        field={"branchName"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.branchName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"branchName"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="accountHolder"
                        text="Account Holder"
                        type="text"
                        placeholder="Enter bank account name"
                        field={"accountHolder"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.accountHolder?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"accountHolder"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="accountNumber"
                        text="Account Number"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter account number"
                        field={"accountNumber"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.accountNumber?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"accountNumber"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="ifsc"
                        text="IFSC"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter IFSC code"
                        field={"ifsc"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.ifsc?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"ifsc"} />
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                {userDetail?.Role?.canEdit == 1 && (
                  <Col
                    xs={12}
                    className="form-group flex justify-content-between mt-4"
                  >
                    {/* <Button className="greyBtn" variant="outline-primary">Cancel</Button> */}
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={openAddModal}
        onHide={hideAddModalHanlder}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Supplier Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm "
            onSubmit={handleSubmit(onSubmitSupplierItems)}
          >
            <Container>
              <button
                type="button"
                onClick={() => setViewOnly(!viewOnly)}
                className="border px-3 py-1 btn btn-outline-primary btn-sm w-auto"
              >
                {viewOnly ? "Add/Edit" : "View Items"}
              </button>
              <Row>
                <Col xs={viewOnly ? 12 : 6}>
                  <Row>
                    <Col xs={12}>
                      <label>Supplier Items</label>
                      <table className="table-bordered table-hover w-100 text-center common-table">
                        <thead>
                          <th>Item</th>
                          <th>Price</th>
                        </thead>
                        <tbody>
                          {!!fields.length ? (
                            fields.map((item, key) => (
                              <tr key={key}>
                                <td>{item.itemName}</td>
                                <td className="flex gap-2 justify-content-center">
                                  <InputField
                                    required
                                    htmlFor="Price"
                                    text={""}
                                    type="number"
                                    autoFocus={true}
                                    placeholder="Enter Price"
                                    field={`supplierItems.[${key}].price`}
                                    register={register}
                                    customclassName="px-2"
                                    errors={errors}
                                    notnull
                                    readOnly={viewOnly}
                                  />
                                  {errors?.supplierItems?.[key]?.price
                                    ?.message && (
                                    <div className="error-text">
                                      <img alt="" src={AlertIcon} />
                                      <span>
                                        <ErrorMessage
                                          errors={errors}
                                          name={`supplierItems.${key}.price`}
                                        />
                                      </span>
                                    </div>
                                  )}
                                  <button
                                    type="button"
                                    className="btn text-danger p-0 ml-2"
                                    onClick={() =>
                                      removeItem(key, item.assignedId)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon="fa-solid fa-trash-can"
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div
                              className="flex flex-column align-center justify-content-center full-width text-black w-100"
                              style={{ height: "10rem" }}
                            >
                              <FontAwesomeIcon
                                className="mb-2"
                                icon="fa-solid fa-ban"
                                style={{ width: "30px", height: "30px" }}
                                beat
                              />
                              <span>Data not available</span>
                            </div>
                          )}
                        </tbody>
                      </table>

                      {errors?.foodItems?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} alt="error-icons" />
                          <span>
                            <ErrorMessage errors={errors} name={`foodItems`} />
                          </span>
                        </div>
                      )}
                    </Col>

                    {!viewOnly && (
                      <Col
                        xs={12}
                        className="form-group flex justify-content-between mt-4"
                      >
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={disabled}
                        >
                          Submit
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
                {!viewOnly && (
                  <Col
                    xs={6}
                    style={{ padding: "0 0rem 0 1rem", height: "80vh" }}
                    className="border mb-2 shadow rounded overflow-hidden"
                  >
                    <div className="flex justify-content-between align-items-center p-2">
                      <h5 className="text-center text-black">Supplier Items</h5>
                      <div className="form-group">
                        <InputField
                          type="search"
                          placeholder="Supplier name/code"
                          field="searchItems"
                          errors={errors}
                          register={register}
                          customClass={"p-1 m-0 bg-white"}
                        />
                      </div>
                    </div>
                    <div
                      className="foodItemsShow flex gap-3 overflow-auto"
                      style={{ padding: "1rem .5rem 5rem .5rem" }}
                    >
                      {items.length > 0 ? (
                        items.map((item) => {
                          const hasData = fields.some(
                            (i) => i.itemId === item.id
                          );
                          return (
                            <div
                              style={{
                                cursor: "pointer",
                                boxShadow:
                                  hasData &&
                                  "3px 3px 4px rgba(255, 180, 0, 1), -3px -3px 4px rgba(255, 180, 0, 1)",
                              }}
                              className="rounded px-3 py-2 border"
                              key={item.id}
                              onClick={() =>
                                hasData ? false : handleAddItem(item)
                              }
                            >
                              <div className="item-name">
                                <h4>{item.itemName}</h4>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          className="flex flex-column align-center justify-content-center full-width text-black w-100"
                          style={{ height: "10rem" }}
                        >
                          <FontAwesomeIcon
                            className="mb-2"
                            icon="fa-solid fa-ban"
                            style={{ width: "40px", height: "40px" }}
                            beat
                          />
                          <span>Data not available</span>
                        </div>
                      )}
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default InventorySupplier;
