import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addExpenses,
  deleteExpenses,
  getExpenses,
} from "API/services/newServices";
import { Imagesdata } from "assets/images/imageData";
import { InputField } from "components/InputField/InputField";
import Layout from "layout";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import { showDate, showPrice } from "utils/utils";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { useDownloadExcel } from "react-export-table-to-excel";
import ReactPaginate from "react-paginate";
import { limitItemsBooking } from "constant/constants";

const validation = Yup.object().shape({
  expenceType: Yup.string().required("Enter expanse type*"),
  expenceSubType: Yup.string().required("Enter sub expanse type*"),
  amount: Yup.string().required("Enter amount*"),
  reason: Yup.string().required("Enter Reason*"),
  refNumber: Yup.string().required("Enter reference number*"),
  expenseDate: Yup.string().required("Enter expense date*"),
  remarks: Yup.string().required("Enter remarks*"),
});

const Expenses = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "expenses" });
  }, []);

  const [subTypes, setSubTypes] = useState([]);
  const item = {
    "Kitchen Expenses": [
      { label: "Grocery", value: "Grocery" },
      { label: "RAW material", value: "RAW material" },
      { label: "GAS", value: "GAS" },
      { label: "Beverages", value: "Beverages" },
      { label: "Vegetable", value: "Vegetable" },
    ],
    "General Expenses": [
      { label: "House Keeping", value: "House Keeping" },
      { label: "Entertainment", value: "Entertainment" },
      { label: "Internet", value: "Internet" },
      { label: "Electricity", value: "Electricity" },
      { label: "Maintanence", value: "Maintanence" },
      { label: "Laundry Purchase", value: "Laundry Purchase" },
      { label: "Laundry Wash", value: "Laundry Wash" },
      { label: "Material Purchase", value: "Material Purchase" },
    ],
    "Cash Deposit": [
      { label: "Cash Deposit In Bank", value: "Cash Deposit In Bank" },
      { label: "Cash Collect", value: "Cash Collect" },
    ],
    Salary: [
      { label: "Salary Advance", value: "Salary Advance" },
      { label: " Salary", value: " Salary" },
    ],
  };

  const inputs = [
    {
      name: "expenceType",
      placeholder: "Enter Expense Type",
      text: "Expense Type",
      type: "options",
      options: [
        {
          label: "Kitchen Expenses",
          value: "Kitchen Expenses",
        },
        {
          label: "General Expenses",
          value: "General Expenses",
        },
        {
          label: "Cash Deposit",
          value: "Cash Deposit",
        },
        { label: "Salary", value: "Salary" },
      ],
    },
    {
      name: "expenceSubType",
      placeholder: "",
      text: "Sub Expense Type",
      type: "options",
      options: subTypes,
    },
    {
      name: "amount",
      placeholder: "Enter Amount",
      text: "Enter Amount",
      type: "number",
    },
    {
      name: "reason",
      placeholder: "Enter Reason",
      text: "Enter Reason",
      type: "text",
    },
    {
      name: "refNumber",
      placeholder: "Enter Reference Number",
      text: "Reference Number",
      type: "text",
    },
    {
      name: "expenseDate",
      placeholder: "Enter Expense Date",
      text: "Expense Date",
      type: "date",
    },
    {
      name: "remarks",
      placeholder: "Enter Remark",
      text: "Remark",
      type: "textArea",
      col: 12,
    },
    //   { name: "paymentSource", placeholder: "Enter ", text: "Name", type: "text" },
  ];

  const tableRef = useRef();
  const { userDetail } = useSelector(authenticationSeletor);

  const { AlertIcon } = Imagesdata;
  //   paymentSource;

  const [tableData, setTableData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(new Date());

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const {
    handleSubmit,
    reset,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({
    ...{ resolver: yupResolver(validation) },
    mode: "onSubmit",
  });

  const STORY_HEADERS = [
    {
      prop: "id",
      title: "ID",
      isFilterable: true,
      cell: (row) => "#" + row.id,
    },
    {
      prop: "expenceType",
      title: "Expense Type",
      isFilterable: true,
    },
    {
      prop: "expenceSubType",
      title: "Sub Expense Type",
      isFilterable: true,
    },
    {
      prop: "reason",
      title: "Expense Reason",
      isFilterable: true,
    },
    {
      prop: "refNumber",
      title: "Reference Number",
      isFilterable: true,
    },
    {
      prop: "remarks",
      title: "Remark",
      isFilterable: true,
    },
    {
      prop: "amount",
      title: "Amount",
      isFilterable: true,
      cell: (row) => showPrice(row.amount),
    },
    {
      prop: "dateTime",
      title: "Expense Date & Time",
      isFilterable: true,
      cell: (row) =>
        showDate(row.dateTime) + " " + moment(row.dateTime).format("HH:MM A"),
    },
    {
      prop: "createdAt",
      title: "Created Date",
      isFilterable: true,
      cell: (row) => showDate(row.createdAt),
    },
    {
      prop: "action",
      title: "Actions",
      isFilterable: true,
      cell: (row) => (
        <>
          {" "}
          <Button className="btn btn-sm mr-2" onClick={() => editHandler(row)}>
            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{" "}
          </Button>{" "}
          <Button
            variant="danger"
            className="btn btn-sm"
            onClick={() => deleteHandler(row.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" />{" "}
          </Button>
        </>
      ),
    },
  ];

  const { expenceType } = watch();
  useEffect(() => {
    if (expenceType) {
      setSubTypes(item[expenceType]);
    } else {
      setSubTypes([]);
    }
  }, [expenceType]);
  const onHide = () => {
    setShow(false);
    reset();
    setSelected({});
    setEditMode(false);
  };
  const openHandler = () => {
    setShow(true);
  };

  const getExpensesHandler = async () => {
    const param = {
      id: userDetail.propertyId,
      page: page,
      size: limit,
    };
    const res = await getExpenses(param);
    if (res) {
      // setPageCount(
      //   res.totalItems > limit ? Math.round(res.totalItems / limit) + 1 : 0
      // );
      setPageCount(res.totalPages);
      setTableData(res.data);
      setShowData(res.data);
    }
  };
  const editHandler = (item) => {
    setSelected(item);
    setEditMode(true);
    setValue("expenceType", item.expenceType);
    setValue("expenceSubType", item.expenceSubType);
    setValue("amount", item.amount);
    setValue("reason", item.reason);
    setValue("refNumber", item.refNumber);
    setValue("expenseDate", moment(item.dateTime).format("yyyy-MM-DD"));
    setValue("remarks", item.remarks);
    openHandler();
  };
  const deleteHandler = async (id) => {
    if (!window.confirm("Are you sure want to remove expanse")) {
      return;
    }
    const res = await deleteExpenses(id);
    if (res) {
      getExpensesHandler();
      toast.success("Expense removed Successfull");
    }
  };
  const onSubmit = async (payload) => {
    const params = {
      body: {
        propertyId: userDetail.propertyId,
        amount: payload.amount,
        reason: payload.reason,
        remarks: payload.remarks,
        expenceType: payload.expenceType,
        expenceSubType: payload.expenceSubType,
        paymentSource: 1,
        refNumber: payload.refNumber,
        expenseDate: moment(payload.expenseDate).format("YYYY-MM-DD"),
      },
      id: selected.id,
      editMode,
    };
    const res = await addExpenses(params);
    if (res) {
      getExpensesHandler();
      onHide();
    }
  };

  useEffect(() => {
    getExpensesHandler();
  }, [page, limit]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "expenses",
    sheet: "expenses",
  });
  const dateFilter = () => {
    const resultsData = tableData.filter((order) => {
      return (
        new Date(order?.createdAt).toJSON().slice(0, 10) >=
        new Date(startDate).toJSON().slice(0, 10)
      );
    });
    setShowData(resultsData);
  };

  useEffect(() => {
    if (startDate) {
      dateFilter();
    }
  }, [startDate]);

  return (
    <Layout
      title="Account Expenses"
      openHandler={openHandler}
      // setToDate={setEndDate}
      setFromDate={setStartDate}
      searchType="date"
      fromDate={startDate}
      // toDate={endDate}
      extraBtnHandler={onDownload}
      extraButton="Export Table"
      searchfilter
    >
      <Row>
        <DatatableWrapper
          body={showData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <select
                className="selectTableItemsSelect"
                onChange={({ target }) => setLimit(target.value)}
              >
                {limitItemsBooking.map((item, ind) => (
                  <option value={item} key={ind}>
                    {item}
                  </option>
                ))}
              </select>
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <div className="scrollTable">
            <Table className="food-report table-bordered" ref={tableRef}>
              <TableHeader />
              <TableBody />
            </Table>
          </div>
        </DatatableWrapper>
      </Row>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={({ selected }) => setPage(selected)}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className="paginationParent"
        previousLabel="<"
        activeClassName="activePaginationBtn"
        disabledClassName="disablePaginationBtn"
        renderOnZeroPageCount={null}
      />
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit " : "Add "}Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                {inputs.map((item, ind) => (
                  <Col xs={item.col ?? 6} className="form-group" key={ind}>
                    <InputField
                      htmlFor={item.name}
                      text={item.text}
                      type={item.type}
                      autoFocus={true}
                      placeholder={item.placeholder}
                      field={item.name}
                      register={register}
                      errors={errors}
                      notnull
                      options={item.options}
                    />
                    {errors?.[item.name]?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={item.name} />
                        </span>
                      </div>
                    )}
                  </Col>
                ))}

                <Col xs={12}>
                  <Button className="btn-sm" variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Expenses;
