import methods from "../methods";
import urls from "../dictionary/PropertyDictionary";
import authUrls from "../dictionary/AuthDictionary";
import AsyncHandler from "../../utils/AsyncHandler";
import { toast } from "react-toastify";

const { get, post, patch, put, deleteData } = methods;

export const homeBookingService = AsyncHandler(async (params) => {
  const res = await post(urls.getBookingDashboard(), params);

  if (res.status === 200) {
    return res;
  }
  return false;
});
export const getRoomDashboardService = AsyncHandler(async (params) => {
  const res = await post(urls.getRoomDashboard(), params);

  if (res.status === 200) {
    return res;
  }
  return false;
});
export const getFoodDashboardService = AsyncHandler(async (params) => {
  const res = await get(urls.getFoodDashboard(params));

  if (res.status === 200) {
    return res;
  }
  return false;
});
export const getPropertyDashboardService = AsyncHandler(async (params) => {
  const res = await post(urls.getPropertyDashboard(), params);
  if (res.status === 200) {
    return res;
  }
  return false;
});

export const assignUnassignPropertyUser = AsyncHandler(async (param) => {
  const res = await post(urls.propertyAssignUser(), param);
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const summaryService = AsyncHandler(async (param) => {
  const res = await get(urls.summaryUrl(param));
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const invoiceFinanceService = AsyncHandler(async (param) => {
  const res = await get(urls.invoiceFinance(param));
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const saleFinance = AsyncHandler(async (param) => {
  const res = await get(urls.saleFinance(param.id, param.date));
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const revenueReportService = AsyncHandler(async (param) => {
  const res = await get(urls.revenueReport(param.id, param.date));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const downloadSaleFinance = AsyncHandler(async (param) => {
  const res = await get(
    urls.saleFinanceDownload(param.property, param.start, param.end)
  );
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const changeCouponStatus = AsyncHandler(async (param) => {
  const res = await post(urls.couponStatus(), param);
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const invoicePayment = AsyncHandler(async (param) => {
  const res = await get(urls.invoicePaymentUrl(param));
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const invoicePaymentOffline = AsyncHandler(async (param) => {
  const res = await post(urls.invoicePaymentOfflineUrl(), param);
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const invoicePaymentVerify = AsyncHandler(async (param) => {
  const res = await put(urls.invoicePaymentOfflineUrlVerify(), param);
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const getInvoiceById = AsyncHandler(async (param) => {
  const res = await get(urls.invoiceDataById(param), param);
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const invoiceDataForRrooms = AsyncHandler(async (param) => {
  const res = await get(urls.invoiceRroomsUrl(param), param);
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const getGuestDetails = AsyncHandler(async (param) => {
  const res = await post(urls.guestDetailsUrl(), param);
  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const forgotPassword = AsyncHandler(async (param) => {
  let res = {};
  if (param.admin) {
    res = await get(authUrls.forgotPasswordRrooms(param.id));
  } else {
    res = await get(authUrls.forgotPasswordPropery(param.id));
  }

  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const updatePassword = AsyncHandler(async (param) => {
  let res = {};
  if (param.admin) {
    res = await put(authUrls.updatePasswordRrooms(param.id), param.body);
  } else {
    res = await put(authUrls.updatePasswordPropery(param.id), param.body);
  }

  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const releaseRoomUrl = AsyncHandler(async (param) => {
  let res = await post(urls.releaseRoomUrl(), param);

  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const getPropertyUser = AsyncHandler(async (param) => {
  let res = await get(authUrls.getPropetyUser(param));

  if (res.status == 200) {
    return res.data;
  }
  return false;
});
export const addBunquetEnquiryService = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.editBunquetEnquiry(param.id), param.body);
  } else {
    res = await post(urls.bunquetEnquiry(), param.body);
  }

  if (res.status == 201 || res.status === 200) {
    return res.data;
  }
  return false;
});
export const getBunquetEnquiryService = AsyncHandler(async (param) => {
  let res = await get(urls.bunquetEnquiry() + `?propertyID=${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getBunquetBookings = AsyncHandler(async (param) => {
  let res = await get(urls.banquetBooking() + `?propertyId=${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const addBunquetBookings = AsyncHandler(async (param) => {
  let res;
  if (param.editMode) {
    res = await patch(urls.banquetBooking() + param.id, param.body);
  } else {
    res = await post(urls.banquetBooking(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const getBunquetBookingsPayment = AsyncHandler(async (param) => {
  let res = await get(
    urls.banquetBookingPaymentUrl() +
      `?propertyId=${param.propertyId}${
        param.orderId ? `&banquetBookingId=${param.orderId}` : ""
      }`
  );

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const bunquetBookingsPayment = AsyncHandler(async (param) => {
  let res = await post(urls.banquetBookingPaymentUrl(), param);

  if (res.status == 201) {
    return res.data;
  }
  return false;
});
export const addBanquetBookingVenue = AsyncHandler(async (param) => {
  let res;
  if (param.editMode) {
    res = await patch(urls.banquetBookingVenue() + param.id, param.body);
  } else {
    res = await post(urls.banquetBookingVenue(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const uploadMenuTobanquetBooking = AsyncHandler(async (param) => {
  let res = await post(urls.banquetBookingUploadMenu(), param);

  if (res.status == 201) {
    return res.data;
  }
  return false;
});
export const getBanquetBookingVenue = AsyncHandler(async (param) => {
  let res = await get(
    urls.banquetBookingVenue() + `?banquetBookingId=${param}`
  );

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const addBanquetBookingServices = AsyncHandler(async (param) => {
  let res;
  if (param.editMode) {
    res = await patch(urls.banquetBookingService() + param.id, param.body);
  } else {
    res = await post(urls.banquetBookingService(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const getBanquetBookingServices = AsyncHandler(async (param) => {
  let res = await get(
    urls.banquetBookingService() + `?banquetBookingId=${param}`
  );

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});

export const createLogsOnbanquetEnquiry = AsyncHandler(async (param) => {
  if (!param.banquetEnquiryId) {
    toast.error("Can't add log, due to Enquiry id not found*");
    return;
  }
  let res = await post(urls.banquetEnquiryBooking(), param);

  if (res.status == 201) {
    return res.data;
  }
  return false;
});
export const getLogsOnbanquetEnquiry = AsyncHandler(async (param) => {
  let res = await get(
    urls.banquetEnquiryBooking() + `?banquetEnquiryId=${param}`
  );

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getFunctions = AsyncHandler(async (param) => {
  let res = await get(urls.functions() + `/property/${param}`);
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getVenue = AsyncHandler(async (param) => {
  let res = await get(urls.venue() + `/property/${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getMenuName = AsyncHandler(async (param) => {
  let res = await get(urls.menuName() + `/property/${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getMenuCategory = AsyncHandler(async (param) => {
  let res = await get(urls.menuCategory() + `/property/${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const deleteFunctions = AsyncHandler(async (param) => {
  let res = await deleteData(urls.functions() + `/${param}`);
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const deleteVenue = AsyncHandler(async (param) => {
  let res = await deleteData(urls.venue() + `/${param}`);

  if (res.status == 200) {
    return res;
  }
  return false;
});
export const deleteMenuName = AsyncHandler(async (param) => {
  let res = await deleteData(urls.menuName() + `/${param}`);

  if (res.status == 200) {
    return res;
  }
  return false;
});
export const deleteMenuCategory = AsyncHandler(async (param) => {
  let res = await deleteData(urls.menuCategory() + `/${param}`);

  if (res.status == 200) {
    return res;
  }
  return false;
});
export const addFunctions = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.functions() + `/${param.id}`, param.body);
  } else {
    res = await post(urls.functions(), param.body);
  }
  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const addVenue = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.venue() + `/${param.id}`, param.body);
  } else {
    res = await post(urls.venue(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const addMenuName = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.menuName() + `/${param.id}`, param.body);
  } else {
    res = await post(urls.menuName(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const addMenuCategory = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.menuCategory() + `/${param.id}`, param.body);
  } else {
    res = await post(urls.menuCategory(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const getMenuItem = AsyncHandler(async (param) => {
  let res = await get(urls.menuItem() + `/property/${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const addMenuItem = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.menuItem() + `/${param.id}`, param.body);
  } else {
    res = await post(urls.menuItem(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const deleteMenuItem = AsyncHandler(async (param) => {
  let res = await deleteData(urls.menuItem() + `/${param}`);

  if (res.status == 200) {
    return res;
  }
  return false;
});
export const getResturantTable = AsyncHandler(async (param) => {
  let res = await get(urls.resturantTable() + `?propertyId=${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const addResturantTable = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.resturantTable() + `/${param.id}`, param.body);
  } else {
    res = await post(urls.resturantTable(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const deleteResturantTable = AsyncHandler(async (param) => {
  let res = await deleteData(urls.resturantTable() + `/${param}`);

  if (res.status == 200) {
    return res;
  }
  return false;
});
export const getBanquetBookingDetails = AsyncHandler(async (param) => {
  let res = await get(urls.banquetBookingDetails(param));

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});

export const getExpenses = AsyncHandler(async (param) => {
  const res = await get(urls.expenses(param));
  if (res.status == 200) {
    return res.data;
  }
  return false;
});

export const addExpenses = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.expensesAction(param.id), param.body);
  } else {
    res = await post(urls.expensesCreate(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const deleteExpenses = AsyncHandler(async (param) => {
  const res = await deleteData(urls.expensesAction(param));
  if (res.status == 200) {
    return res;
  }
  return false;
});

// module configurataion apis

export const getModules = AsyncHandler(async (param) => {
  const res = await get(urls.getModuleGroup(param));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getMenusService = AsyncHandler(async (param) => {
  const res = await get(urls.getMenuGroup(param));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});

export const addModule = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.editModuleGroup(param.id), param.body);
  } else {
    res = await post(urls.moduleGroup(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const addMenu = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.editMenuGroup(param.id), param.body);
  } else {
    res = await post(urls.menuGroup(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const deleteModule = AsyncHandler(async (param) => {
  const res = await deleteData(urls.deleteModuleGroup(param));
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const deleteMenu = AsyncHandler(async (param) => {
  const res = await deleteData(urls.deleteMenuGroup(param));
  if (res.status == 200) {
    return res;
  }
  return false;
});

export const addResturantOrder = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.oneResturantOrderUrl(param.id), param.body);
  } else {
    res = await post(urls.resturantOrderUrl(), param.body);
  }

  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});
export const getResturantOrder = AsyncHandler(async (param) => {
  let res = await get(urls.resturantOrderUrl());

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getResturabtBookingsPayment = AsyncHandler(async (param) => {
  let res = await get(urls.banquetBookingPaymentUrl() + `?propertyId=${param}`);

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const resturantOrderPayment = AsyncHandler(async (param) => {
  let res = await post(urls.resturantOrderPaymentUrl(), param);

  if (res.status == 201) {
    return res.data;
  }
  return false;
});
export const getResturantOrderPayment = AsyncHandler(async (param) => {
  let res = await get(
    urls.resturantOrderPaymentUrl() +
      `?propertyId=${param.propertyId}${
        param.orderId ? `&orderId=${param.orderId}` : ""
      }`
  );

  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const deleteResturantOrder = AsyncHandler(async (param) => {
  const res = await deleteData(urls.oneResturantOrderUrl(param));
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const assingModules = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.assingModulesUrl(), param.body);
  } else {
    res = await post(urls.assingModulesUrl(), param.body);
  }
  if (res.status == 201) {
    return res.data;
  }
  return false;
});

export const getAssingModules = AsyncHandler(async (param) => {
  const res = await get(urls.getModulesUrlUser(param));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getBookingByBookingCode = AsyncHandler(async (param) => {
  const res = await get(urls.bookingDetailByCode(param));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});

export const addEditSupplierItems = AsyncHandler(async (param) => {
  let res = {};
  if (param.editMode) {
    res = await put(urls.supplierItems(), param.body);
  } else {
    res = await post(urls.supplierItems(), param.body);
  }
  if (res.status == 200) {
    return res.data;
  }
  return false;
});

export const deleteSupplierItem = AsyncHandler(async (param) => {
  const res = await deleteData(urls.supplierItems() + param);
  if (res.status == 200) {
    return res;
  }
  return false;
});
export const getSupplierItem = AsyncHandler(async (suplierId) => {
  const res = await post(urls.supplierItemsGet(), {
    keyName: "suplierId",
    value: suplierId,
  });
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});

export const addInAndOutStockService = AsyncHandler(async (param) => {
  let res = {};
  if (param.inStock) {
    delete param.inStock;
    res = await post(urls.inventoryInStock(), param);
  } else {
    res = await post(urls.inventoryOutStock(), param);
  }
  if (res.status == 201 || res.status == 200) {
    return res.data;
  }
  return false;
});

export const getSuggetions = AsyncHandler(async (val) => {
  const res = await get(urls.suggetions(val));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getTaxService = AsyncHandler(async () => {
  const res = await get(urls.taxUrl());
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getBookingByDate = AsyncHandler(async (param) => {
  const res = await get(urls.bookingByDate(param));
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
export const getBookingByNumber = AsyncHandler(async (param) => {
  const res = await post(urls.bookingByNumber(), param);
  if (res.status == 200) {
    return res.data.data;
  }
  return false;
});
