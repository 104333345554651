import dictionary from "../dictionary";
import methods from "../methods";

const { property } = dictionary;
const { get, post, patch, put, deleteData } = methods;

const PropertyService = {
  createRRoomPropertyService: (body) => {
    return post(property.createRroomProperty(), body);
  },
  updateRRoomPropertyService: (data) => {
    return put(property.updateRroomProperty(data.id), data.body);
  },
  getRroomsPropertyService: () => {
    return get(property.getRroomProperty());
  },
  getRroomsPropertyServiceByOwner: (email) => {
    return get(property.getRroomProperty() + `?ownerEmail=${email}`);
  },
  getRroomsPropertyByFilter: (filter) => {
    return get(property.getRroomProperty() + filter);
  },
  deleteRRoomPropertyService: (id) => {
    return deleteData(property.getRroomProperty() + "/" + id);
  },
  getSinglePropertyService: (id, fullDetail) => {
    return get(
      property.getRroomProperty() +
        "/" +
        id +
        `${fullDetail ? "?showDetails=true" : ""}`
    );
  },
  createRRoomService: (body) => {
    return post(property.createRroom(), body);
  },
  updateRRoomService: (data) => {
    return put(property.updateRroom(data.id), data.body);
  },
  getRRoomService: () => {
    return get(property.createRroom());
  },
  getPropertyCategoryService: () => {
    return get(property.getPropertyCategory());
  },
  getPropertyCategoryServiceId: (id) => {
    return get(property.getPropertyById(id) + "?showDetails=true");
  },
  createPropertyCategoryService: (body) => {
    return post(property.getPropertyCategory(), body);
  },
  editPropertyCategoryService: (data) => {
    return put(property.getPropertyCategory() + `/${data.id}`, data.body);
  },
  deletePropertyCategoryService: (id) => {
    return deleteData(property.getPropertyCategory() + `/${id}`);
  },
  getRoomCategoryService: () => {
    return get(property.getRoomCategory());
  },
  createRoomCategoryService: (body) => {
    return post(property.getRoomCategory(), body);
  },
  editRoomCategoryService: (data) => {
    return put(property.getRoomCategory() + `/${data.id}`, data.body);
  },
  deleteRoomCategoryService: (id) => {
    return deleteData(property.getRoomCategory() + `/${id}`);
  },
  getAmenitiesService: () => {
    return get(property.getAmenities());
  },
  createAmenitiesService: (body) => {
    return post(property.getAmenities(), body);
  },
  editAmenitiesService: (data) => {
    return put(property.getAmenities() + `/${data.id}`, data.body);
  },
  deleteAmenitiesService: (id) => {
    return deleteData(property.getAmenities() + `/${id}`);
  },
  bookHotelService: (body) => {
    return post(property.bookHotel(), body);
  },
  getBookHotelService: (body) => {
    return get(property.bookHotel());
  },
  getBookHotelByUser: (id) => {
    return get(property.bookHotelByUser(id));
  },
  createEnquiryService: (body) => {
    return post(property.createEnquiry(), body);
  },
  getEnquiryService: (body) => {
    return get(property.createEnquiry());
  },

  createWalkingBookingService: (body) => {
    return post(property.createWalkingBooking(), body);
  },
  searchPropertyService: (body) => {
    return post(property.searchProperty(), body);
  },
  searchPropertyServiceGet: (body) => {
    return get(
      body ? property.searchPropertyGet() + body : property.searchPropertyGet()
    );
  },
  updateBookingService: (data) => {
    return put(property.updateBooking(data.id), data.body);
  },
  getBookingServiceByProperty: (id) => {
    return get(property.updateBooking(id));
  },
  deletePropertyImageService: (id) => {
    return deleteData(property.deletePropertyImage(id));
  },
  deleteRoomImageService: (id) => {
    return deleteData(property.deleteRoomImage(id));
  },
  getInventoryEmplyoee: () => {
    return get(property.getEmployee());
  },
  editInvoiceData: (data) => {
    return post(property.invoiceDatasubmit(), data);
  },
  reEditInvoiceData: (data, id) => {
    return put(property.invoiceDatasubmit() + `/${id}`, data);
  },
  getInvoiceData: (id) => {
    return get(property.invoiceDatasubmit() + id);
  },
  getFoodOrders: () => {
    return get(property.foodOrders());
  },
  getFoodOrdersByBooking: (id) => {
    return get(property.foodOrdersByBooking(id));
  },
  getFoodOrdersByProperty: (id) => {
    return get(property.foodOrdersByPrt(id));
  },
  createFoodOrders: (data) => {
    return post(property.foodOrders(), data);
  },
  updateFoodOrders: (data, id) => {
    return put(property.foodOrders() + "/" + id, data);
  },
  deleteFoodOrders: (id) => {
    return get(property.foodOrders() + "/" + id);
  },
  getFoodItems: () => {
    return get(property.foodItems());
  },
  getFoodItemsProperty: (id) => {
    return get(property.foodItems() + id);
  },
  createFoodItems: (data) => {
    return post(property.foodItems(), data);
  },
  createFoodItemInventory: (data) => {
    return post(property.foodItems(), data);
  },
  updateFoodItems: (data, id) => {
    return put(property.foodItems() + "/" + id, data);
  },
  deleteMenuItems: (id) => {
    return deleteData(property.foodItems() + "/" + id);
  },
  createPaymentFood: (data) => {
    return post(property.foodPayment(), data);
  },
  updatePaymentFood: (data, id) => {
    return put(property.foodPayment() + id, data);
  },
  updateFoodOrderStatus: (data, id) => {
    return patch(property.foodOrderStatus(id), data);
  },
  getCountry: (id) => {
    return get(property.country() + id);
  },
  paymentGateway: (id) => {
    return get(property.paymentGatewayUrl(id));
  },
  paymentGatewayStatus: (id) => {
    return get(property.paymentStatusUrl(id));
  },
  updateBookingModeStatus: (id, params) => {
    return put(property.updateBookingModeStatus(id), params);
  },
  getBookingById: (id) => {
    return get(property.updateBooking(id));
  },
  getStatusOfPaymentSucces: (id) => {
    return get(property.paymentStatus(id));
  },
  getNcType: () => {
    return get(property.ncType());
  },
  getNcTypeByProperty: (id) => {
    return get(property.ncTypeByProperty(id));
  },
  setNcType: (data) => {
    return post(property.ncType(), data);
  },
  editNcType: (id, data) => {
    return put(property.ncType() + id, data);
  },
  getwalletMoney: (id) => {
    return get(property.walletMoney(id));
  },
  getActivityByBooking: (id) => {
    return get(property.activityByBooking(id));
  },
  createActivity: (params) => {
    return post(property.activity(), params);
  },
  getFocRequest: (id) => {
    return get(property.focRequest(id));
  },
  editFocRequest: (id, params) => {
    return put(property.focRequestEdit(id), params);
  },
  updatePropertyProfile: (id, data) => {
    return put(property.updatePropetyHeroImg(id), data);
  },
  updateRoomPrice: (payload) => {
    return put(property.updateRoomPriceURL(), payload);
  },
  bookingUserListForRrooms: (payload) => {
    return post(property.bookingByNumber(), payload);
  },
};

export default PropertyService;
