import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link } from "react-router-dom";
import Layout from "../../layout";
import { useForm, SubmitHandler } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "../inventorySupplier/validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import ReactGA from "react-ga4";

const LaundryProvider = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
  }, [userDetail]);

  const [tableData, setTableData] = useState([]);
  const { AlertIcon } = Imagesdata || {};

  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [filterData, setFilterData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      name: payload.supplierName,
      property_id: userDetail?.propertyId,
      email: payload.suplieremail?.toString(),
      phone: payload.suplierPhoneNumber,
      address: payload.address,
      alternate_mobile: payload.aadhaarNumber?.toString(),
      pan_number: payload.panNo,
      gst: payload.gstno?.toString(),
      bank_name: payload.bankName,
      branch_name: payload.branchName,
      account_name: payload.accountHolder,
      account_number: payload.accountNumber,
      ifsc: payload.ifsc,
    };

    try {
      let res = {};
      if (editMode) {
        const editPayload = {
          body: realPayload,
          id: selectedData?.id,
        };
        res = await services.globalService.updateLaundryProviderService(
          editPayload
        );
      } else {
        res = await services.globalService.createLaundryProviderService(
          realPayload
        );
      }
      if (res.status === 201 || res.status === 200) {
        reset();
        setShow(false);
        setEditMode(false);
        getDataHandler(userDetail?.propertyId);
        setSelectedData({});
      }
    } catch (error) {}
  };

  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);

    setValue("supplierName", data.name);
    setValue("suplieremail", data.email);
    setValue("suplierPhoneNumber", data.phone);
    setValue("address", data.address);
    setValue("aadhaarNumber", data.alternateMobile);
    setValue("panNo", data.panNumber);
    setValue("gstno", data.gst);
    setValue("bankName", data.bankName);
    setValue("branchName", data.branchName);
    setValue("accountHolder", data.accountName);
    setValue("accountNumber", data.accountNumber);
    setValue("ifsc", data.ifsc);
    setEditMode(true);
  };

  const getDataHandler = async (id) => {
    try {
      const res = await services.globalService.getLaundryProviderByIdService(
        id
      );
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  const deleteHandler = async (id) => {
    try {
      let res = {};
      res = await services.globalService.deleteLaundryProviderByIdService(id);
      if (res.status === 200) {
        getDataHandler(userDetail?.propertyId);
      }
    } catch (error) {}
  };
  const searchfilter = (text) => {
    const results = tableData.filter((order) => {
      if (text === "") return tableData;
      return (
        order.accountName +
        order.accountNumber +
        order.adharNumber +
        order.bankName +
        order.email +
        order.phone +
        order.name +
        order.gst +
        order.panNumber
      )
        .toString()
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterData(results);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "laundry_provider" });
    setFilterData(tableData);
  }, [tableData]);

  return (
    <Layout
      title="Laundry Provider"
      openHandler={() => setShow(true)}
      searchfilter={searchfilter}
    >
      <Table striped bordered hover className="inventoryTable">
        <thead>
          <tr>
            {/* <th>SR No.</th> */}
            <th>Service Provider Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Alternate Mobile</th>
            <th>PAN No. </th>
            <th>GST No.</th>
            <th>Bank Deatils</th>
          </tr>
        </thead>
        <tbody>
          {filterData?.map((data, key) => (
            <tr key={key}>
              <td>{data.name}</td>
              <td>{data.email ?? "N/A"}</td>
              <td>{data.phone}</td>
              <td>{data.address}</td>
              <td>{data.alternateMobile ?? "N/A"}</td>
              <td>{data.panNumber}</td>
              <td>{data.gst ?? "N/A"}</td>
              <td>
                <Button
                  variant="primary"
                  className="mr-2 btn-sm"
                  onClick={() => editHandler(data)}
                >
                  {userDetail?.Role?.canEdit == 1 ? "View/Edit" : "View"}
                </Button>
                {userDetail?.Role?.canDelete == 1 && (
                  <Button
                    className="btn-sm"
                    variant="primary"
                    onClick={() => deleteHandler(data?.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        backdrop="static"
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Laundry Provider" : "Create Laundry Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="supplierName"
                    text="Supplier Name"
                    type="text"
                    notnull
                    autoFocus={true}
                    placeholder="Enter supplier name"
                    field={"supplierName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.supplierName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"supplierName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="suplieremail"
                    text="Email Address "
                    type="email"
                    notnull
                    placeholder="Enter email address"
                    field={"suplieremail"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.suplieremail?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"suplieremail"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="suplierPhoneNumber"
                    text="Phone Number"
                    notnull
                    type="text"
                    placeholder="Enter phone number"
                    field={"suplierPhoneNumber"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.suplierPhoneNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"suplierPhoneNumber"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="aadhaarNumber"
                    text="Alternate Mobile"
                    type="text"
                    placeholder="Enter Alternate Mobile Number"
                    field={"aadhaarNumber"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.aadhaarNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"aadhaarNumber"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="panNo"
                    text="PAN No."
                    notnull
                    type="text"
                    placeholder="Enter Pan Number"
                    field={"panNo"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.panNo?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"panNo"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="gstno"
                    text="GST No."
                    type="text"
                    placeholder="Enter GST number"
                    field={"gstno"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.gstno?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"gstno"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <label>
                    Address
                    <small className="text-danger fw-bolder fs-6">*</small>
                  </label>
                  <textarea
                    // register={register}
                    {...register("address")}
                    placeholder={"Enter full address"}
                  ></textarea>
                  {errors?.address?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"address"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group mt-3">
                  <h6>Bank account details</h6>
                </Col>
                <Col xs={12} className="form-group bankDetails">
                  <Row>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="bankName"
                        text="Bank Name"
                        notnull
                        type="text"
                        placeholder="Enter bank name"
                        field={"bankName"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.bankName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"bankName"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="branchName"
                        text="Branch Name"
                        type="text"
                        notnull
                        autoFocus={true}
                        placeholder="Enter branch"
                        field={"branchName"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.branchName?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"branchName"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="accountHolder"
                        text="Account Holder"
                        type="text"
                        notnull
                        placeholder="Enter bank account name"
                        field={"accountHolder"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.accountHolder?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"accountHolder"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="accountNumber"
                        text="Account Number"
                        type="text"
                        notnull
                        autoFocus={true}
                        placeholder="Enter account number"
                        field={"accountNumber"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.accountNumber?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"accountNumber"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="ifsc"
                        text="IFSC"
                        type="text"
                        notnull
                        autoFocus={true}
                        placeholder="Enter IFSC code"
                        field={"ifsc"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.ifsc?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"ifsc"} />
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                {userDetail?.Role?.canEdit == 1 && (
                  <Col
                    xs={12}
                    className="form-group flex justify-content-between mt-4"
                  >
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default LaundryProvider;
