import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link } from "react-router-dom";
import Layout from "../../layout";
import { useForm, SubmitHandler } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import {
  getRroomUsersAction,
  usersSeletor,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { toast } from "react-toastify";
const LaundryProvider = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataHandler();
  }, [userDetail]);

  const [tableData, setTableData] = useState([]);
  const { AlertIcon } = Imagesdata || {};

  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      name: payload.name,
    };
    try {
      let res = {};
      let msg = "";
      if (editMode) {
        const editPayload = {
          body: realPayload,
          id: selectedData?.id,
        };
        res = await services.globalService.updateMenuCardCategory(editPayload);
        msg = "Updated";
      } else {
        res = await services.globalService.createMenuCardCategory(realPayload);
        msg = "Created";
      }
      if (res.status === 201 || res.status === 200) {
        reset();
        setShow(false);
        setEditMode(false);
        getDataHandler(userDetail?.propertyId);
        setSelectedData({});
        if (msg.length > 0) {
          toast.success(`Menu Category ${msg} Successfully!`, {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {}
  };

  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);
    setValue("name", data.name);
    setEditMode(true);
  };

  const getDataHandler = async () => {
    try {
      const res = await services.globalService.getMenuCardCategory();
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  const deleteHandler = async (id) => {
    try {
      let res = {};
      if (window.confirm("Are You Sure Want to Delete")) {
        res = await services.globalService.deleteMenuCardCategory(id);
        if (res.status === 200) {
          getDataHandler();
          toast.success(`Menu Category Deleted Successfully!`, {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {}
  };

  return (
    <Layout title="Menu Category" openHandler={() => setShow(true)}>
      <Table striped bordered hover className="inventoryTable">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Category Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((data, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{data.name}</td>
              <td>
                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={() => editHandler(data)}
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </Button>
                <Button
                  variant="primary"
                  onClick={() => deleteHandler(data?.id)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setSelectedData({});
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Laundry Provider" : "Create Laundry Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="name"
                    text="Menu Category Name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter Menu Category name"
                    field={"name"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.name?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"name"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default LaundryProvider;
