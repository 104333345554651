import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  state: Yup.string().required("Please Select State Name"),
  city: Yup.string().required("Please Enter City Name")
});

export default { resolver: yupResolver(validation) };
