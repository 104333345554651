import dictionary from "../dictionary";
import UserDictionary from "../dictionary/UserDictionary";
import methods from "../methods";

const { auth } = dictionary;
const { get, post, patch, put, deleteData } = methods;

const AuthService = {
  propertyLoginService: (body) => {
    return post(auth.propertyLogin(), body);
  },
  mainPropertyLoginService: (body) => {
    return post(auth.mainPropertyLogin(), body);
  },
  userLogin: (body) => {
    return post(auth.userLogin(), body);
  },
  userVerify: (body) => {
    return post(auth.userVerify(), body);
  },
  updateUser: (body) => {
    return put(auth.editUser(body.id), body.data);
  },
  getUserDetails: (id) => {
    return get(auth.getUserUrl(id));
  },
  getPropetyUserData: (id) => {
    return get(auth.getPropetyUser(id));
  },
  getAllRoles: () => {
    return get(auth.getRoles());
  },
  createRole: (data) => {
    return post(auth.getRoles(), data);
  },
  updateRole: (id, data) => {
    return put(auth.getRoles() + "/" + id, data);
  },
  deleteRole: (id) => {
    return deleteData(auth.getRoles() + "/" + id);
  },
  deleteRroomsUser: (id) => {
    return deleteData(UserDictionary.delRroomsUsers(id));
  },
  deletePrtUser: (id) => {
    return deleteData(UserDictionary.delPropertyUsers(id));
  },
  getAllRroomsUser: (id) => {
    return get(auth.getRoomsUser());
  },
  getAppService: (data) => {
    return post(auth.getApp(), data);
  },
};

export default AuthService;
