import { createSlice } from "@reduxjs/toolkit";
import services from "../../API/services";

export const initialState = {
  rRoomProperty: [],
  searchVal: "",
  loading: true,
  filterValues: {
    propertyCategoryFilter: [],
    roomCategoryFilter: [],
    travellerChoice: [],
    localityFilter: [],
    latlongFilter: { lat: null, long: null },
    cityId: 0,
  },
};

const propertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {
    clearPropertyState: () => initialState,
    getRroomProperty: (state, { payload }) => {
      state.rRoomProperty = payload;
    },
    setFilter: (state, { payload }) => {
      state.filterValues = { ...state.filterValues, ...payload };
    },
    setSearch: (state, { payload }) => {
      state.searchVal = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const {
  clearPropertyState,
  getRroomProperty,
  setFilter,
  setSearch,
  clearFilter,
  setLoading,
} = propertySlice.actions;

// Action creators are generated for each case reducer function
export const propertySeletor = (state) => state.propertys;

export default propertySlice.reducer;

export const getRroomPropertyAction = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await services.property.searchPropertyServiceGet(params);
      if (res.status === 200) {
        await dispatch(setLoading(false));
        await dispatch(getRroomProperty(res?.data?.data));
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};
