import moment from "moment";

const PropertyDictionary = {
  createRroomProperty: () => "rrooms-property/property",
  updateRroomProperty: (id) => `rrooms-property/property/${id}`,
  getPropertyById: (id) => `rrooms-property/property/${id}`,
  getRroomProperty: () => "rrooms-property/property",
  createRroom: () => "rrooms-property/room",
  updateRroom: (id) => `rrooms-property/room/${id}`,
  getPropertyCategory: () => "rrooms-property/property-category",
  getRoomCategory: () => "rrooms-property/rroom-category",
  getAmenities: () => "rrooms-property/amenities",
  bookHotel: () => "booking/room-booking",
  bookHotelByUser: (id) => `/booking/room-booking/by-user/${id}`,
  createEnquiry: () => "customer/enquiry",
  createWalkingBooking: () => "booking/room-booking",
  searchProperty: () => "rrooms-property/property/search",
  searchPropertyGet: () => "rrooms-property/search",
  updateBooking: (id) => `booking/room-booking/${id}`,
  updateBookingModeStatus: (id) => `booking/room-booking-confirm/${id}`,
  deletePropertyImage: (id) => `rrooms-property/property-image/${id}`,
  deleteRoomImage: (id) => `rrooms-property/room-image/${id}`,
  getEmployee: () => `/auth/property-users`,
  invoiceDatasubmit: () => "rrooms-property/invoice-site-setting",
  foodOrders: () => "rrooms-property/food-order",
  foodOrdersByBooking: (id) => `rrooms-property/food-order/booking-id/${id}`,
  foodOrdersByPrt: (id) => `rrooms-property/food-order/property-id/${id}`,
  foodItems: () => "rrooms-property/food-menu-item",
  foodItemInventory: () => "inventory/items",
  foodPayment: () => "rrooms-property/food-order-payment",
  foodOrderStatus: (id) => `rrooms-property/food-order/status/${id}`,
  country: () => `country/state/`,
  paymentGatewayUrl: (id) =>
    `rrooms-property/initiate-payment?booking_id=${id}`,
  paymentStatusUrl: (id) => `rrooms-property/check-status?booking_id=${id}`,
  paymentStatus: (id) => `/rrooms-property/check-status?booking_id=${id}`,
  ncType: () => `rrooms-property/nc-type-setting/`,
  ncTypeByProperty: (id) => `rrooms-property/nc-type-setting?property_id=${id}`,
  walletMoney: (id) => `auth/user-wallet?user_id=${id}`,
  activity: () => `booking/booking-logs`,
  activityByBooking: (id) => `booking/booking-logs-bookingid/${id}`,
  focRequest: (id) => `booking/foc-request?property_id=${id}`,
  focRequestEdit: (id) => `booking/foc-request/${id}`,
  updatePropetyHeroImg: (id) => `rrooms-property/property-image-profile/${id}`,
  updateRoomPriceURL: () => `rrooms-property/apply-offers`,
  getBookingDashboard: () => `kpi/booking-count-with-status`,
  getRoomDashboard: () => `kpi/room-count-with-status`,
  // getFoodDashboard: () => `kpi/food-order-count-with-status`,
  getFoodDashboard: ({ id, nc, from, end }) =>
    `kpi/food-order-payment-report?propertyId=${id}&ncType=${nc}&fromDate=${moment(
      from
    ).format("YYYY-MM-DD")}&toDate=${moment(end).format("YYYY-MM-DD")}`,
  getPropertyDashboard: () => `kpi/property-count-with-status`,
  propertyAssignUser: () => `rrooms-property/assign-unassign-property`,
  summaryUrl: (id) => `rrooms-property/property-sale-summary/${id}`,
  invoiceFinance: (id) => `rrooms-property/property-invoices/${id}`,
  saleFinance: (id, date) =>
    `rrooms-property/property-sale-by-month?propertyId=${id}&endDate=${date}`,
  revenueReport: (id, date) =>
    `revenue-report/revenue-report-by-date?propertyId=${id}&month=${moment(
      date
    ).format("YYYY-MM-DD")}`,
  saleFinanceDownload: (property, start, end) =>
    `rrooms-property/property-sale-download/${property}?startDate=${start}&endDate=${end}`,
  revenueReportDownload: (property, date) =>
    `revenue-report/download-revenue-report-by-date?propertyId=${property}&month=${moment(
      date
    ).format("YYYY-MM-DD")}`,
  couponStatus: () => `rrooms-property/inactive-coupon-property`,
  invoicePaymentUrl: (id) =>
    `rrooms-property/initiate-invoice-payment?invoice_id=${id}`,
  invoicePaymentOfflineUrl: () => `rrooms-property/invoice-offline-payment`,
  invoiceRroomsUrl: (param) =>
    `rrooms-property/invoices-transaction-detail${param}`,
  // invoiceDataById: (id) =>
  //   `rrooms-property/invoice-payment-check-status?invoice_id=${id}`,
  invoiceDataById: (id) => `rrooms-property/property-invoice/${id}`,
  // invoicePaymentOfflineUrlVerify: (id, st) =>
  //   `rrooms-property/status-update-invoice-offline-payment/${id}?payment_status=${st}`,
  invoicePaymentOfflineUrlVerify: () =>
    `rrooms-property/invoice-offline-payment`,
  guestDetailsUrl: () => `booking/guest-list`,
  releaseRoomUrl: () => `booking/checkout-guest`,
  bunquetEnquiry: () => `banquet/banquet-enquiry`,
  editBunquetEnquiry: (id) => `banquet/banquet-enquiry/${id}`,
  banquetBooking: () => `banquet/banquet-booking/`,
  banquetBookingPaymentUrl: () => `banquet/banquet-booking-payment`,

  banquetBookingService: () => `banquet/booked-service/`,
  banquetBookingVenue: () => `banquet/booked-venue/`,
  banquetBookingUploadMenu: () => `rest-order/upload`,
  banquetEnquiryBooking: (id) => `banquet/banquet-enquiry-log/`,
  functions: () => "functions",
  venue: () => "venue",
  menuName: () => "menuName",
  menuItem: () => "menuItem",
  menuCategory: () => "menuCategory",
  resturantTable: () => "table/table-booking/",
  banquetBookingDetails: (id) => `banquet/banquet-booking/${id}`,
  expenses: (param) =>
    `rrooms-property/daily-expense?propertyId=${param.id}&page=${param.page}&size=${param.size}`,
  expensesAction: (id) => `rrooms-property/daily-expense/${id}`,
  expensesCreate: () => `rrooms-property/daily-expense/`,
  moduleGroup: () => `module-config/add-group`,
  getMenuGroup: () => `module-config/all-menu`,
  editModuleGroup: (id) => `module-config/update-group/${id}`,
  editMenuGroup: (id) => `module-config/update-menu/${id}`,
  menuGroup: () => `module-config/add-menu`,
  deleteModuleGroup: (id) => `module-config/delete-group/${id}`,
  deleteMenuGroup: (id) => `module-config/delete-menu/${id}`,
  getModuleGroup: ({ page, size }) =>
    `module-config/all-group?page=${page}&size=${size}`,
  resturantOrderUrl: () => `rest-order/order`,
  oneResturantOrderUrl: (id) => `rest-order/order/${id}`,
  assingModulesUrl: () => `rrooms-property/add-update-module-config/`,
  getModulesUrlUser: ({ id, property }) =>
    `rrooms-property/get-module-config?propertyId=${property}&property_user_id=${id}`,
  resturantOrderPaymentUrl: () => `rest-payment/payment`,
  bookingDetailByCode: (code) => `booking/room-booking/booking-details/${code}`,
  supplierItems: () => `inventory/inventory-suplier-item/`,
  supplierItemsGet: () => `inventory/inventory-suplier-item-get/`,
  inventoryInStock: () => `inventory/inventory-stock`,
  inventoryOutStock: () => `inventory/inventory-stock-out`,
  suggetions: (val) =>
    `rrooms-property/suggestion${val ? `?query=${val}` : ""}`,
  taxUrl: () => "rrooms-property/service-tax/1",
  bookingByDate: ({ id, from, to }) =>
    `booking/room-booking/by-property/${id}?fromDate=${from} 00:00:00 &to=${to} 00:00:00`,
  bookingByNumber: () => `booking/user-booking-list`,
};

export default PropertyDictionary;
