import React, { useEffect } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link, useNavigate } from "react-router-dom";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { useSelector, useDispatch } from "react-redux";
import { setShowModal } from "../../store/slices/modalState";

const LandingHeader = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  const { showModal } = useSelector((s) => s.showModal);
  const dispatch = useDispatch();

  const { Logolandingpage, UserLoginIcon, HotelIcon } = Imagesdata || {};
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="headerLanding">
        <div className="headerTop">
          <div className="container flex justify-content-between">
            <figure className="dashboardLogo">
              <Link to="/">
                <img alt="" src={Logolandingpage} alt="" />
                <h2>RRooms</h2>
              </Link>
            </figure>
            <ul className="flex">
              <li onClick={() => dispatch(setShowModal(!showModal))}>
                <figure>
                  <img alt="" src={HotelIcon} alt="" />
                </figure>
                <div className="content">
                  <p>Become a Member</p>
                  <a href="javascript:void(0)">List your property</a>
                </div>
              </li>

              <li>
                {userDetail.id ? (
                  <>
                    {userDetail?.logintype === "propertyUser" ||
                    userDetail?.logintype === "rroomUser" ? (
                      <button
                        className="loginButton"
                        onClick={() => navigate("/dashboard")}
                      >
                        <img alt="" src={UserLoginIcon} alt="" /> Dashboard
                      </button>
                    ) : (
                      <button
                        className="loginButton"
                        onClick={() => navigate("/user-profile")}
                      >
                        <img alt="" src={UserLoginIcon} alt="" />{" "}
                        {userDetail.name != "" && userDetail.name
                          ? userDetail.name?.split(" ")[0]
                          : "Profile"}
                      </button>
                    )}
                  </>
                ) : (
                  <Link className="loginButton" to="/login">
                    <img alt="" src={UserLoginIcon} alt="" /> Login / Signup
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
