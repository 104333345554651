import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import { getCustomersAction, usersSeletor } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import moment from "moment";

const Customer = () => {
  const dispatch = useDispatch();
  const { customers } = useSelector(usersSeletor);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCustomersAction());
  }, []);

  useEffect(() => {
    if (customers) {
      setUserList(customers);
    }
  }, [customers]);

  const { AlertIcon } = Imagesdata || {};

  const [show, setShow] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [showUserList, setShowUserList] = useState([]);
  const [resetDate, setResetDate] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      name: payload.firstName + " " + payload.lastName,
      email: payload.email,
      mobile: payload.phoneNumber,
      role: 1,
      password: payload.password,
      propertyId: 1,
      designation: payload.designation,
    };
    try {
      const res = await services.user.addPropertyUserService(realPayload);

      if (res.status === 200) {
        reset();
        dispatch(getCustomersAction());
        setShow(false);
      }
    } catch (error) {}
  };

  const onError = (error) => {};
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const searchfilter = (toDate, fromDate) => {
    const results = userList.filter((item) => {
      if (!resetDate) return userList;
      return (
        new Date(item?.createdAt).getTime() >= new Date(fromDate).getTime() &&
        new Date(item?.createdAt).getTime() <= new Date(toDate).getTime()
      );
    });
    setShowUserList(results);
  };
  useEffect(() => {
    searchfilter(toDate, fromDate);
    let x = toDate;
    let y = fromDate;
    if ((x != y && x) || y) {
      setResetDate(true);
    } else {
      setResetDate(false);
    }
  }, [toDate, fromDate]);
  useEffect(() => {
    setShowUserList(userList);
    setResetDate(false);
  }, [userList]);
  const resetFilter = () => {
    setShowUserList(userList);
    setResetDate(false);
  };

  return (
    <Layout
      title="Customer"
      buttonTitle="Show All"
      extraButton="Export Table"
      extraBtnHandler={onDownload}
      searchfilter={searchfilter}
      searchType="date"
      setFromDate={setFromDate}
      setToDate={setToDate}
      fromDate={fromDate}
      toDate={toDate}
      openHandler={resetDate ? resetFilter : false}
    >
      <Table striped bordered hover className="userTable" ref={tableRef}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Register Date</th>
          </tr>
        </thead>
        <tbody>
          {showUserList.map((data, key) => {
            // if (data.name) {
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{data.name ?? "Name not Available"}</td>
                <td>{data.email ?? "Email not Available"}</td>
                <td>{data.mobile ?? "Mobile Number not Available"}</td>
                <td>
                  {moment(data.createdAt).format("Do MMM, yyyy") ?? "N/A"}
                </td>
              </tr>
            );
            // }
          })}
        </tbody>
      </Table>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New user details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <p>New user details</p>
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="firstName"
                    text="First Name"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter first name"
                    field={"firstName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.firstName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"firstName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="lastName"
                    text="Last Name"
                    type="text"
                    placeholder="Enter Last name"
                    field={"lastName"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.lastName?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"lastName"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="phoneNumber"
                    text="Phone No."
                    type="text"
                    placeholder="Enter phone number"
                    field={"phoneNumber"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.phoneNumber?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"phoneNumber"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="email"
                    text="Email address "
                    type="email"
                    placeholder="Enter email address"
                    field={"email"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.email?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"email"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="designation"
                    text="Designation"
                    type="text"
                    placeholder="Enter designation"
                    field={"designation"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.designation?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"designation"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="password"
                    text="Create Password"
                    type="password"
                    placeholder="Enter new password"
                    field={"password"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.password?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"password"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <InputField
                    htmlFor="confirmPassword"
                    text="Confirm Password"
                    type="password"
                    placeholder="Enter confirm password"
                    field={"confirmPassword"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.confirmPassword?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"confirmPassword"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12} className="form-group">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Customer;
