import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { baseUrl } from "../../API/client/api";
import * as XLSX from "xlsx";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";

const InventoryItems = () => {
  // const dispatch = useDispatch();
  // const { rRoomUsers } = useSelector(usersSeletor);
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "inventory_item" });
    window.scrollTo(0, 0);
    getDataHandler(userDetail?.propertyId);
    getCategoryHandler(userDetail?.propertyId);
  }, [userDetail]);

  const { AlertIcon } = Imagesdata || {};
  const [show, setShow] = useState(false);
  // const [userList, setUserList] = useState([]);
  const [items, setItems] = useState([{ itemName: "", unit: "" }]);
  const [importByExcelState, setImportByExcelState] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [category, setCategory] = useState([]);
  const [editPopUp, setEditPopUp] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [editData, setEditData] = useState({
    editCategory: selectedData.categoryId,
    editName: selectedData.itemName,
    editunit: selectedData.unit,
    editprice: selectedData.price,
  });
  const importByExcel = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setExcelData(data);
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      propertyId: userDetail?.propertyId,
      categoryId: payload.selectCategory,
      createdBy: userDetail?.id,
      items: items,
    };

    try {
      const res = await services.globalService.addInventoryItemService(
        realPayload
      );
      if (res.status === 200) {
        reset();
        getDataHandler(userDetail?.propertyId);
        setShow(false);
        setItems([{ itemName: "", unit: "" }]);
      }
    } catch (error) {}
  };

  const excelSubmit = async () => {
    let count = 0;
    excelData?.map(async (item) => {
      const realPayload = {
        propertyId: userDetail?.propertyId,
        categoryId: item.Category?.toString(),
        createdBy: userDetail?.id,
        items: [
          {
            itemName: item.Items,
            unit: item.Unit,
            price: item.Price?.toString(),
            createdBy: userDetail?.id,
          },
        ],
      };
      try {
        const res = await services.globalService.addInventoryItemService(
          realPayload
        );
        if (res.status == 200) {
          count++;
        }
      } catch (error) {}
      if (count == excelData?.length) {
        setExcelData([]);
        setImportByExcelState(false);
        getDataHandler(userDetail?.propertyId);
      }
    });
  };

  const getDataHandler = async (id) => {
    try {
      const res =
        await services.globalService.getInventoryItemByPropertyIdService(id);
      if (res.status === 200) {
        setTableData(res.data.data);
        setAllData(res.data.data);
      }
    } catch (error) {}
  };

  const getCategoryHandler = async (id) => {
    try {
      const res = await services.globalService.getInventoryCategoryService(id);
      if (res.status === 200) {
        setCategory(res.data.data);
      }
    } catch (error) {}
  };

  const onError = (error) => {};

  const changeHandler = (e, index) => {
    let tempData = [...items];
    tempData[index][e.target.name] = e.target.value;
    tempData[index].createdBy = userDetail?.id;
    setItems(tempData);
  };
  const closeHandler = (index) => {
    let tempData = [...items];
    tempData.splice(index, 1);
    setItems(tempData);
  };
  const openEditPopUp = (item) => {
    setSelectedData(item);
    setEditPopUp(true);
  };

  const changeEditHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditData({ ...editData, [name]: value });
  };

  const formateTable = useRef();

  const { onDownload: excelDownLoadFormate } = useDownloadExcel({
    currentTableRef: formateTable.current,
    filename: "bulk inventory Items",
    sheet: "FoodOrders",
  });

  const editSubmit = async () => {
    try {
      const bodyData = {
        itemName: editData?.editName ?? selectedData?.itemName,
        propertyId: userDetail?.propertyId,
        categoryId: editData?.editCategory ?? selectedData?.categoryId,
        unit: editData?.editunit ?? selectedData?.unit,
        price: editData?.editprice ?? selectedData?.price,
        createdBy: userDetail?.id,
      };
      const res = await fetch(`${baseUrl}inventory/items/${selectedData?.id}`, {
        method: "put",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(bodyData),
      });
      const data = await res.json();
      setEditPopUp(false);
      getDataHandler(userDetail?.propertyId);
    } catch (error) {}
  };

  const searchfilter = (text) => {
    if (text === "") return setTableData(allData);
    const resultsData = allData.filter((order) => {
      return (order.InventoryCategory?.name + order?.itemName + order?.quantity)
        ?.toString()
        .toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setTableData(resultsData);
  };

  return (
    <Layout
      title="Inventory Items"
      openHandler={() => setShow(true)}
      secondBtn={() => setImportByExcelState(true)}
      secondbuttonTitle="Import Table"
      searchType="search"
      searchfilter={searchfilter}
    >
      <Table striped bordered hover className="common-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Item</th>
            <th>Unit</th>
            {/* <th>Item Price</th> */}
            {/* <th>Quantity</th> */}
            {userDetail?.Role?.canEdit == 1 && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, key) => (
            <tr key={key}>
              {/* <td>{data.propertyId}</td> */}
              {/* <td>{data?.InventoryCategory?.name}</td> */}
              <td>{data?.InventoryCategory?.name}</td>
              <td>{data.itemName}</td>
              <td>{data.unit}</td>
              {/* <td>{data.price}</td> */}
              {/* <td>{data.quantity}</td> */}
              {userDetail?.Role?.canEdit == 1 && (
                <td>
                  <Button
                    variant="primary"
                    onClick={() => openEditPopUp(data)}
                    className="btn-sm"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setItems([{ itemName: "", unit: "" }]);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Inventory Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <label>Select Category</label>
                  <select register={register} {...register("selectCategory")}>
                    <option selected disabled>
                      Select
                    </option>
                    {category.map((data) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                  </select>
                  {errors?.selectCategory?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"selectCategory"} />
                      </span>
                    </div>
                  )}
                </Col>
                {items.map((data, i) => (
                  <Col xs={12} className="addItems">
                    <Row>
                      {items.length > 1 ? (
                        <Col xs={12}>
                          <div
                            className="cutIcon"
                            onClick={() => closeHandler(i)}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={6} className="form-group">
                        <label>Item Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter item name"
                          value={data.itemName}
                          onChange={(e) => changeHandler(e, i)}
                          name="itemName"
                        />
                      </Col>
                      <Col xs={6} className="form-group">
                        <label>Select Unit</label>
                        <select
                          value={data.unit}
                          onChange={(e) => changeHandler(e, i)}
                          name="unit"
                        >
                          <option selected disabled value={""}>
                            Select
                          </option>
                          <option>Kg</option>
                          <option>Gm</option>
                          <option>Lt</option>
                          <option>Ml</option>
                          <option>Met</option>
                          <option>Piece</option>
                        </select>
                      </Col>
                      {/* <Col xs={6} className="form-group">
                        <label>Item price</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter item price"
                          value={data.price}
                          onChange={(e) => changeHandler(e, i)}
                          name="price"
                        />
                      </Col> */}
                    </Row>
                  </Col>
                ))}

                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2 align-center"
                >
                  <Button
                    variant="primary"
                    type="button"
                    className="buttonCenter"
                    onClick={() =>
                      setItems([...items, { itemName: "", unit: "" }])
                    }
                  >
                    + Add Item
                  </Button>
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button
                    className="greyBtn"
                    variant="outline-primary"
                    onClick={() => {
                      setShow(false);
                      reset();
                      setItems([{ itemName: "", unit: "" }]);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={editPopUp}
        onHide={() => {
          setEditPopUp(!editPopUp);
          reset();
          setItems([{ itemName: "", unit: "" }]);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Inventory Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Container>
              <Row>
                <Col xs={12} className="form-group">
                  <label>Select Category</label>
                  <select
                    name="editCategory"
                    onChange={(e) => changeEditHandler(e)}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    {category.map((data) => (
                      <option
                        value={data.id}
                        selected={data?.id === selectedData?.categoryId}
                      >
                        {data.name}
                      </option>
                    ))}
                  </select>
                  {errors?.selectCategory?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"selectCategory"} />
                      </span>
                    </div>
                  )}
                </Col>
                {items.map((data, i) => (
                  <Col xs={12} className="addItems">
                    <Row>
                      {items.length > 1 ? (
                        <Col xs={12}>
                          <div
                            className="cutIcon"
                            onClick={() => closeHandler(i)}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={12} className="form-group">
                        <label>Item Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter item name"
                          value={editData?.editName ?? selectedData?.itemName}
                          onChange={(e) => changeEditHandler(e)}
                          name="editName"
                        />
                      </Col>
                      <Col xs={6} className="form-group">
                        <label>Select Unit</label>
                        <select
                          value={editData?.editunit ?? selectedData?.unit}
                          onChange={(e) => changeEditHandler(e)}
                          name="editunit"
                        >
                          <option selected disabled value={""}>
                            Select
                          </option>
                          <option>Kg</option>
                          <option>Gm</option>
                          <option>Lt</option>
                          <option>Ml</option>
                          <option>Met</option>
                          <option>Piece</option>
                        </select>
                      </Col>
                      {/* <Col xs={6} className="form-group">
                        <label>Item price</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter item price"
                          value={editData?.editprice ?? selectedData?.price}
                          onChange={(e) => changeEditHandler(e)}
                          name="editprice"
                        />
                      </Col> */}
                    </Row>
                  </Col>
                ))}

                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2 align-center"
                >
                  {/* <Button
                    variant="primary"
                    type="button"
                    className="buttonCenter"
                    onClick={() =>
                      setItems([
                        ...items,
                        { itemName: "", unit: "", price: "" },
                      ])
                    }
                  >
                    + Add Item
                  </Button> */}
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button
                    className="greyBtn"
                    variant="outline-primary"
                    onClick={() => {
                      setEditPopUp(false);
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="button" onClick={editSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={importByExcelState}
        onHide={() => setImportByExcelState(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Bulk Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {excelData?.length > 0 ? (
            <>
              <div className="flex justify-content-end mb-2">
                <button className="btn-sm btn-primary" onClick={excelSubmit}>
                  Submit
                </button>
              </div>
              <Table striped bordered hover className="common-table">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Category</th>
                    <th>Item Name</th>
                    <th>Unit</th>
                    <th>Item Price</th>
                  </tr>
                </thead>
                <tbody>
                  {excelData?.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td className="text-center">{key + 1}</td>
                        <td>{data.Category}</td>
                        <td>{data.Items}</td>
                        <td>{data.Unit}</td>
                        <td>₹{data.Price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <div className="flex gap-1 justify-content-between align-items-center ">
              <div className="flex form-group flex-column ">
                <label htmlFor="excel">Select Excel File</label>
                <input
                  type="file"
                  id="excel"
                  onChange={(e) => importByExcel(e.target.files[0])}
                />
              </div>
              <button
                className="btn-sm btn-primary text-white"
                onClick={excelDownLoadFormate}
              >
                Download Formate
              </button>
              <table
                className="table border"
                ref={formateTable}
                style={{ position: "absolute", top: -100000000000 }}
              >
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Items</th>
                    <th>Unit</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Category Ids</td>
                    <td>items name</td>
                    <td>Kg/gm/lt/</td>
                    <td>Only Number</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default InventoryItems;
