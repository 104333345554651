import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { encryptTransform } from "redux-persist-transform-encrypt";
import authenticationReducer from "./slices/authenticationSlice";
import userSlice from "./slices/userSlice";
import propertySlice from "./slices/propertySlice";
import PropertyData from "./slices/PropertyData";
import ModalSlice from "./slices/modalState";

const reducer = {
  authentication: authenticationReducer,
  users: userSlice,
  propertys: propertySlice,
  AllData: PropertyData,
  showModal: ModalSlice,
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["users", "propertys", "AllData", "showModal"],
  transforms: [
    encryptTransform({
      secretKey: "Sq@r3G@m3-$3cr3t-k3y",
      onError: function (error) {},
    }),
  ],
};

const rootReducers = combineReducers(reducer);
const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
