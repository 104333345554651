import React, { useState, useEffect } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link, useNavigate } from "react-router-dom";
import IconLink from "./iconLink";
import {
  authenticationSeletor,
  setMenu,
} from "../../store/slices/authenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { onLogout } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import { USER_DEG_TEXT } from "../../constant/constants";

function Sidebar({ sideAction, sideVal }) {
  const dispatch = useDispatch();
  const { userDetail, sideBarMenu } = useSelector(authenticationSeletor);
  const { activeMenu, activeMasterMenu } = sideBarMenu;
  const { Logolandingpage } = Imagesdata || {};
  const { assignedModules } = useSelector((s) => s.AllData);

  const menuChangeHandler = (data, type) => {
    if (type) {
      if (data === activeMasterMenu) {
        dispatch(setMenu({ activeMasterMenu: "" }));
      } else {
        dispatch(setMenu({ activeMasterMenu: data }));
      }
    } else {
      if (data === activeMenu) {
        dispatch(setMenu({ activeMenu: "" }));
      } else {
        dispatch(setMenu({ activeMenu: data }));
      }
    }
  };
  const clearSideMenus = () => {
    dispatch(setMenu({ activeMenu: "" }));
    dispatch(setMenu({ activeMasterMenu: "" }));
  };

  return (
    <div
      className={`sidebar ${sideVal ? "" : "sideOpen "}${
        userDetail?.logintype == "propertyUser" && " propertyUser"
      }`}
    >
      <div>
        <figure className="position-relative">
          <Link to={"/"} className="logo-box">
            <img src={Logolandingpage} alt="Side Bar Main Logo" />
            <h2>RRooms</h2>
          </Link>
          <div
            onClick={() => sideAction(false)}
            className="position-absolute closeSidebarBtn"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-circle-xmark"
              size="xl"
              color="white"
            />
          </div>
        </figure>
        <ul className="left-nav">
          <li>
            <Link
              to={
                userDetail?.logintype == "rroomUser"
                  ? "/dashboard"
                  : "/dashboard/home"
              }
              onClick={clearSideMenus}
              className="dashboard-btn"
            >
              <i className="fa fa-tachometer" aria-hidden="true"></i>
              Dashboard
            </Link>
          </li>
          {userDetail?.logintype != "rroomUser" && userDetail.role != 3 ? (
            assignedModules?.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <li>
                    <div
                      className="master-group"
                      onClick={() => menuChangeHandler(item.groupId, "master")}
                    >
                      <span>{item.group?.groupName ?? "Module Name"}</span>
                      {activeMasterMenu == item.groupId ? (
                        <FontAwesomeIcon icon="fa fa-angle-down" />
                      ) : (
                        <FontAwesomeIcon icon="fa fa-angle-right" />
                      )}
                    </div>
                  </li>
                  <ul
                    style={{
                      display:
                        activeMasterMenu == item.groupId ? "block" : "none",
                    }}
                  >
                    {item.group?.menues.map((menu) => {
                      return (
                        menu.isActive && (
                          <li key={menu.id}>
                            <Link
                              to={menu.webRoute}
                              className="text-capitalize"
                            >
                              <FontAwesomeIcon
                                icon={menu.icon}
                                className="mr-2"
                              />
                              {menu.menuName?.toLowerCase()}
                            </Link>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </React.Fragment>
              );
            })
          ) : (
            <>
              {USER_DEG_TEXT[userDetail?.designation] == "FOM" ||
              USER_DEG_TEXT[userDetail?.designation] == "SDC" ||
              USER_DEG_TEXT[userDetail?.designation] == "HK Executive" ||
              USER_DEG_TEXT[userDetail?.designation] ==
                "Accounts Executive" ? null : (
                <>
                  <li>
                    <div
                      className="master-group"
                      onClick={() =>
                        menuChangeHandler("administration", "master")
                      }
                    >
                      <span>Administration</span>
                      {activeMasterMenu === "administration" ? (
                        <FontAwesomeIcon icon="fa fa-angle-down" />
                      ) : (
                        <FontAwesomeIcon icon="fa fa-angle-right" />
                      )}
                    </div>
                  </li>
                  <ul
                    style={{
                      display:
                        activeMasterMenu === "administration"
                          ? "block"
                          : "none",
                    }}
                  >
                    {userDetail?.logintype == "rroomUser" ? (
                      <>
                        <li>
                          <Link to="/manage-roles">
                            {" "}
                            <FontAwesomeIcon
                              icon="fa fa-user"
                              className="mr-2"
                            />
                            Manage Roles
                          </Link>
                        </li>
                        <li>
                          <Link to="/rrooms-users">
                            {" "}
                            <FontAwesomeIcon
                              icon="fa fa-user"
                              className="mr-2"
                            />
                            RRooms
                          </Link>
                        </li>
                        <li>
                          <Link to="/property-users">
                            {" "}
                            <FontAwesomeIcon
                              icon="fa fa-user"
                              className="mr-2"
                            />
                            Property User
                          </Link>
                        </li>
                        <li>
                          <Link to="/customer">
                            {" "}
                            <FontAwesomeIcon
                              icon="fa fa-user"
                              className="mr-2"
                            />
                            Customer
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li>
                        <Link to="/property-users">
                          {" "}
                          <FontAwesomeIcon icon="fa fa-user" className="mr-2" />
                          Property User
                        </Link>
                      </li>
                    )}
                  </ul>
                </>
              )}
              {USER_DEG_TEXT[userDetail?.designation] ==
                "Store Manager (Kitchen)" ||
              USER_DEG_TEXT[userDetail?.designation] ==
                "Store Incharge" ? null : (
                <>
                  <li>
                    <div
                      className="master-group"
                      onClick={() =>
                        menuChangeHandler("customerRelation", "master")
                      }
                    >
                      <span>Customer Relation</span>
                      {activeMasterMenu === "customerRelation" ? (
                        <FontAwesomeIcon icon="fa fa-angle-down" />
                      ) : (
                        <FontAwesomeIcon icon="fa fa-angle-right" />
                      )}
                    </div>
                  </li>
                  <div
                    style={{
                      display:
                        activeMasterMenu === "customerRelation"
                          ? "block"
                          : "none",
                    }}
                  >
                    {userDetail?.logintype == "rroomUser" ? (
                      <li>
                        <li>
                          <Link to="/enquiry-customer">
                            <FontAwesomeIcon
                              icon="fa-solid fa-building"
                              className="mr-2"
                            />
                            Property Inquiry
                          </Link>
                        </li>
                        <li>
                          <Link to="/coupon">
                            <FontAwesomeIcon
                              icon="fa-solid fa-ticket"
                              className="mr-2"
                            />
                            Coupons
                          </Link>
                        </li>
                        <li>
                          <Link type="button" to={"/menu-category"}>
                            <FontAwesomeIcon
                              icon="fa-solid fa-bowl-food"
                              className="mr-2"
                            />
                            Menu Category
                          </Link>
                        </li>
                        <li>
                          <Link to="/dashboard/all-bookings">
                            {" "}
                            <FontAwesomeIcon icon="fa-solid fa-hotel" />
                            Find Bookings
                          </Link>
                        </li>
                      </li>
                    ) : null}
                    {userDetail?.logintype == "propertyUser" ? (
                      <>
                        <li>
                          {USER_DEG_TEXT[userDetail?.designation] == "SDC" ||
                          USER_DEG_TEXT[userDetail?.designation] ==
                            "SDM" ? null : (
                            <>
                              <li>
                                <Link to="/dashboard/all-bookings">
                                  {" "}
                                  <FontAwesomeIcon icon="fa-solid fa-hotel" />
                                  All Bookings
                                </Link>
                              </li>
                              <li>
                                <Link to="/dashboard/guest-details">
                                  {" "}
                                  <FontAwesomeIcon icon="fa-solid fa-user" />
                                  Guest Details
                                </Link>
                              </li>
                            </>
                          )}
                          {USER_DEG_TEXT[userDetail?.designation] ==
                            "Accounts Manager" ||
                          USER_DEG_TEXT[userDetail?.designation] ==
                            "Accounts Executive" ? null : (
                            <li>
                              <Link to="/dashboard/rooms">
                                <FontAwesomeIcon icon="fa-solid fa-bed" />
                                Rooms
                              </Link>
                            </li>
                          )}
                        </li>
                        {USER_DEG_TEXT[userDetail?.designation] == "MD" ||
                        USER_DEG_TEXT[userDetail?.designation] == "GM" ||
                        USER_DEG_TEXT[userDetail?.designation] == "AGM" ||
                        USER_DEG_TEXT[userDetail?.designation] == "FOM" ? (
                          <>
                            {/* <li>
                              <Link to="/foc-request">
                                <i className="fa fa-user"></i>
                                FOC Request
                              </Link>
                            </li> */}
                            {USER_DEG_TEXT[userDetail?.designation] !=
                              "FOM" && (
                              <li>
                                <Link to="/coupon">
                                  <i
                                    className="fa fa-ticket"
                                    aria-hidden="true"
                                  ></i>
                                  Coupons
                                </Link>
                              </li>
                            )}

                            <li>
                              <Link to="/Invoice">
                                <i
                                  className="fa fa-file"
                                  aria-hidden="true"
                                ></i>
                                Invoice
                              </Link>
                            </li>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </>
              )}
              {userDetail?.logintype == "propertyUser" ? (
                <>
                  {USER_DEG_TEXT[userDetail?.designation] == "HK Supervisor" ||
                  USER_DEG_TEXT[userDetail?.designation] == "HK Executive" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Accounts Manager" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Accounts Executive" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Store Manager (Kitchen)" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Store Incharge" ? null : (
                    <li>
                      <div
                        className="master-group"
                        onClick={() => menuChangeHandler("kitchen", "master")}
                      >
                        <span>kitchen</span>
                        {activeMasterMenu === "kitchen" ? (
                          <FontAwesomeIcon icon="fa fa-angle-down" />
                        ) : (
                          <FontAwesomeIcon icon="fa fa-angle-right" />
                        )}
                      </div>
                      <div
                        style={{
                          display:
                            activeMasterMenu === "kitchen" ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link type="button" to={"/food-orders"}>
                            <FontAwesomeIcon
                              icon="fa-solid fa-utensils"
                              className="mr-2"
                            />
                            Food Orders
                          </Link>

                          {USER_DEG_TEXT[userDetail?.designation] == "SDM" ||
                          USER_DEG_TEXT[userDetail?.designation] == "FOM" ||
                          USER_DEG_TEXT[userDetail?.designation] ==
                            "SDC" ? null : (
                            <Link type="button" to={"/breakfast"}>
                              <FontAwesomeIcon
                                icon="fa-solid fa-mug-saucer"
                                className="mr-2"
                              />
                              Meal Plan Setting
                            </Link>
                          )}
                          {USER_DEG_TEXT[userDetail?.designation] ==
                          "FOM" ? null : (
                            <Link type="button" to={"/menu-card"}>
                              <FontAwesomeIcon
                                icon="fa-solid fa-receipt"
                                className="mr-2"
                              />
                              Food Menu
                            </Link>
                          )}
                          <Link type="button" to={"/food-items"}>
                            <FontAwesomeIcon
                              icon="fa-solid fa-bowl-food"
                              className="mr-2"
                            />
                            Food Items
                          </Link>
                        </li>
                      </div>
                    </li>
                  )}
                  {USER_DEG_TEXT[userDetail?.designation] == "HK Supervisor" ||
                  USER_DEG_TEXT[userDetail?.designation] == "HK Executive" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Accounts Manager" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Accounts Executive" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Store Manager (Kitchen)" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Store Incharge" ? null : (
                    <li>
                      <div
                        className="master-group"
                        onClick={() =>
                          menuChangeHandler("Restaurant", "master")
                        }
                      >
                        <span>Restaurant</span>
                        {activeMasterMenu === "Restaurant" ? (
                          <FontAwesomeIcon icon="fa fa-angle-down" />
                        ) : (
                          <FontAwesomeIcon icon="fa fa-angle-right" />
                        )}
                      </div>
                      <div
                        style={{
                          display:
                            activeMasterMenu === "Restaurant"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            type="button"
                            to={"/restaurant/Restaurant-food-orders"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-bowl-food" />
                            Food Orders
                          </Link>
                          {/* <Link
                      type="button"
                      to={"/restaurant/Restaurant-menu-mangement"}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-utensils"
                        className="mr-2"
                      />
                      Menu Mangement
                    </Link> */}
                          <Link
                            type="button"
                            to={"/restaurant/Restaurant-table-mangement"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-chair" />
                            Table Mangement
                          </Link>
                          <Link
                            type="button"
                            to={"/restaurant/Restaurant-payment"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" />
                            Restaurant Payment
                          </Link>
                          <Link
                            type="button"
                            to={"/restaurant/Restaurant-food-orders/reporting"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-torii-gate" />
                            Restaurant Orders Report
                          </Link>
                        </li>
                      </div>
                    </li>
                  )}
                  {USER_DEG_TEXT[userDetail?.designation] == "HK Supervisor" ||
                  USER_DEG_TEXT[userDetail?.designation] == "HK Executive" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Accounts Manager" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Accounts Executive" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Store Manager (Kitchen)" ||
                  USER_DEG_TEXT[userDetail?.designation] ==
                    "Store Incharge" ? null : (
                    <li>
                      <div
                        className="master-group"
                        onClick={() => menuChangeHandler("bunquet", "master")}
                      >
                        <span>Banquet</span>
                        {activeMasterMenu === "bunquet" ? (
                          <FontAwesomeIcon icon="fa fa-angle-down" />
                        ) : (
                          <FontAwesomeIcon icon="fa fa-angle-right" />
                        )}
                      </div>
                      <div
                        style={{
                          display:
                            activeMasterMenu === "bunquet" ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link
                            type="button"
                            to={"/banquet/banquet-function-type"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" />
                            Banquet Master
                          </Link>
                          <Link type="button" to={"/banquet/banquet-menu-item"}>
                            <FontAwesomeIcon
                              icon="fa-solid fa-receipt"
                              className="mr-2"
                            />
                            Banquet Menu Item
                          </Link>
                          <Link type="button" to={"/banquet/banquet-enquiry"}>
                            <FontAwesomeIcon icon="fa-solid fa-bullhorn" />
                            Banquet Enquiry
                          </Link>
                          <Link type="button" to={"/banquet/banquet-bookings"}>
                            <FontAwesomeIcon icon="fa-solid fa-torii-gate" />
                            Banquet Bookings
                          </Link>
                          <Link type="button" to={"/banquet/banquet-payment"}>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" />
                            Banquet Payment
                          </Link>
                          <Link
                            type="button"
                            to={"/banquet/banquet-bookings-report/reporting"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-torii-gate" />
                            Banquet Booking Report
                          </Link>
                          <Link
                            type="button"
                            to={"/banquet/banquet-bookings-enquiry/reporting"}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-bullhorn" />
                            Banquet Enquiry Report
                          </Link>
                        </li>
                      </div>
                    </li>
                  )}
                </>
              ) : null}
              <li>
                <div
                  className="master-group"
                  onClick={() =>
                    menuChangeHandler("propertyManagement", "master")
                  }
                >
                  <span>Property Management</span>
                  {activeMasterMenu === "propertyManagement" ? (
                    <FontAwesomeIcon icon="fa fa-angle-down" />
                  ) : (
                    <FontAwesomeIcon icon="fa fa-angle-right" />
                  )}
                </div>
              </li>
              <div
                style={{
                  display:
                    activeMasterMenu === "propertyManagement"
                      ? "block"
                      : "none",
                }}
              >
                {userDetail?.logintype == "rroomUser" ? (
                  <ul>
                    <li>
                      <Link to="/property-category">Property Category</Link>
                    </li>
                    <li>
                      <Link to="/room-category">Room Category</Link>
                    </li>
                    <li>
                      <Link to="/amenities">Amenities</Link>
                    </li>
                    <li>
                      <Link to="/property-listing">Properties</Link>
                    </li>
                  </ul>
                ) : null}
                {userDetail?.logintype == "propertyUser" ? (
                  <>
                    {USER_DEG_TEXT[userDetail?.designation] == "FOM" ||
                    USER_DEG_TEXT[userDetail?.designation] == "SDC" ||
                    USER_DEG_TEXT[userDetail?.designation] == "HK Supervisor" ||
                    USER_DEG_TEXT[userDetail?.designation] ==
                      "HK Executive" ? null : (
                      <ul>
                        <li>
                          <Link to="/create-inventory">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Inventory
                          </Link>
                        </li>
                        <li>
                          <Link to="/inventory-category">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Inventory Category
                          </Link>
                        </li>
                        <li>
                          <Link to="/inventory-item">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Inventory Item
                          </Link>
                        </li>
                        <li>
                          <Link to="/inventory-supplier">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Inventory Supplier
                          </Link>
                        </li>
                      </ul>
                    )}
                    {USER_DEG_TEXT[userDetail?.designation] == "SDM" ||
                    USER_DEG_TEXT[userDetail?.designation] == "SDC" ||
                    USER_DEG_TEXT[userDetail?.designation] ==
                      "HK Executive" ? null : (
                      <ul>
                        <li>
                          <Link to="/laundry">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Laundry
                          </Link>
                        </li>
                        <li>
                          <Link to="/laundry-service">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Laundry Service
                          </Link>
                        </li>
                        <li>
                          <Link to="/laundry-provider">
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Laundry Provider
                          </Link>
                        </li>
                      </ul>
                    )}
                    {userDetail?.logintype == "propertyUser" && (
                      <li>
                        <Link to={"/roomprice-setting"}>
                          <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                          Room Tariff
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/customer-reviews">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        Customer Rating
                      </Link>
                    </li>
                  </>
                ) : null}
              </div>
              {USER_DEG_TEXT[userDetail?.designation] == "MD" ||
              USER_DEG_TEXT[userDetail?.designation] == "GM" ||
              USER_DEG_TEXT[userDetail?.designation] == "FOM" ||
              USER_DEG_TEXT[userDetail?.designation] == "Accounts Manager" ||
              USER_DEG_TEXT[userDetail?.designation] == "Accounts Executive" ||
              USER_DEG_TEXT[userDetail?.designation] == "AGM" ? (
                <li>
                  <div
                    className="master-group"
                    onClick={() => menuChangeHandler("reporting", "master")}
                  >
                    <span>Reporting</span>
                    {activeMasterMenu === "reporting" ? (
                      <FontAwesomeIcon icon="fa fa-angle-down" />
                    ) : (
                      <FontAwesomeIcon icon="fa fa-angle-right" />
                    )}
                  </div>
                  <div
                    style={{
                      display:
                        activeMasterMenu === "reporting" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={"/booking-report"}>
                        <i className="fa fa-cubes" aria-hidden="true"></i>
                        Bookings
                      </Link>
                    </li>

                    <li>
                      <Link to={"/payment-report"}>
                        <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                        Payment Report
                      </Link>
                    </li>

                    {userDetail?.logintype == "rroomUser" ? (
                      <>
                        <li>
                          <Link to={"/search-result"}>
                            <i className="fa fa-search"></i>Search Results
                          </Link>
                        </li>
                        <li>
                          <Link to={"/report/transaction-report"}>
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Transaction Report
                          </Link>
                        </li>
                        <li>
                          <Link to={"/report/rating-report"}>
                            <i className="fa fa-cubes" aria-hidden="true"></i>
                            Rating Reporting
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to={"/food-report"}>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                            Food Report
                          </Link>
                        </li>

                        <li>
                          <Link to={"/food-items-report"}>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                            Food Items Report
                          </Link>
                        </li>
                        <li>
                          <Link to={"/inventory-report"}>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                            Inventory Report
                          </Link>
                        </li>
                        <li>
                          <Link to="/laundry">
                            <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                            Laundry
                          </Link>
                        </li>
                      </>
                    )}
                  </div>
                </li>
              ) : null}
              <li>
                <div
                  className="master-group"
                  onClick={() => menuChangeHandler("hotelFinance", "master")}
                >
                  <span>Hotel Finance</span>
                  {activeMasterMenu === "hotelFinance" ? (
                    <FontAwesomeIcon icon="fa fa-angle-down" />
                  ) : (
                    <FontAwesomeIcon icon="fa fa-angle-right" />
                  )}
                </div>
                <div
                  style={{
                    display:
                      activeMasterMenu === "hotelFinance" ? "block" : "none",
                  }}
                >
                  <li>
                    <Link to={"/hotel-finance/summary"}>
                      <i className="fa fa-cubes" aria-hidden="true"></i>
                      Summary
                    </Link>
                  </li>
                  <li>
                    <Link to={"/hotel-finance/invoice"}>
                      <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                      Invoice
                    </Link>
                  </li>
                  {/* <li>
              <Link to={"/hotel-finance/invoice-payment"}>
                <FontAwesomeIcon icon="fa-solid fa-indian-rupee-sign" />
                Invoice Payment
              </Link>
            </li> */}
                  <li>
                    <Link to={"/hotel-finance/sale"}>
                      <FontAwesomeIcon icon="fa-solid fa-scale-unbalanced-flip" />
                      Sale
                    </Link>
                  </li>
                </div>
              </li>
              {userDetail?.logintype == "propertyUser" && (
                <li>
                  <div
                    className="master-group"
                    onClick={() => menuChangeHandler("account", "master")}
                  >
                    <span>Account</span>
                    {activeMasterMenu === "account" ? (
                      <FontAwesomeIcon icon="fa fa-angle-down" />
                    ) : (
                      <FontAwesomeIcon icon="fa fa-angle-right" />
                    )}
                  </div>
                  <div
                    style={{
                      display:
                        activeMasterMenu === "account" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={"/accounts/expenses"}>
                        <i className="fa fa-cubes" aria-hidden="true"></i>
                        Expenses
                      </Link>
                    </li>
                    <li>
                      <Link to={"/accounts/revenue-report"}>
                        <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                        Revenue Report
                      </Link>
                    </li>
                  </div>
                </li>
              )}
              {userDetail?.logintype == "rroomUser" && (
                <li>
                  <IconLink type="link" action="/rrooms/module-configuration">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    Module Configuration
                  </IconLink>
                </li>
              )}

              <li>
                <IconLink
                  type="button"
                  action={() => menuChangeHandler("settings")}
                  active={activeMenu === "settings"}
                >
                  <i className="fa fa-gear" aria-hidden="true"></i>
                  SETTINGS
                </IconLink>
                <ul
                  style={{
                    display: activeMenu === "settings" ? "block" : "none",
                  }}
                >
                  {userDetail?.logintype == "rroomUser" ? (
                    <>
                      <li>
                        <Link to="/tax-setting">Tax settings</Link>
                      </li>
                      <li>
                        <Link to="/room-price">Room price settings</Link>
                      </li>
                      <li>
                        <Link to="/city-locality">City & Locality</Link>
                      </li>
                    </>
                  ) : null}
                  {userDetail?.logintype == "propertyUser" ? (
                    <>
                      {USER_DEG_TEXT[userDetail?.designation] == "MD" ||
                      USER_DEG_TEXT[userDetail?.designation] == "GM" ||
                      USER_DEG_TEXT[userDetail?.designation] == "AGM" ? (
                        <>
                          <li>
                            <Link to="/user-logins">User Logins</Link>
                          </li>
                          {/* <li>
                      <Link to="/roomprice-setting">Room price settings</Link>
                    </li> */}
                          <li>
                            <Link to="/nc-type-setting">NC Type settings</Link>
                          </li>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {/* <li>
                    <Link onClick={() => onLogout(navigate)}>Logout</Link>
                  </li> */}
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
      <span
        className="text-white pl-3 mb-5"
        style={{ fontSize: ".7rem", letterSpacing: 0.5 }}
      >
        <Moment format="MMMM Do YYYY, h:mm:ss a">
          {userDetail?.lastLogged}
        </Moment>
      </span>
    </div>
  );
}

export default Sidebar;
