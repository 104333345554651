import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";
import client from "../client";
import { useNavigate } from "react-router-dom";
import { getSessionData, onLogout } from "../../utils/utils";
import { store } from "../../store";
import loderLogo from "./loderLogo.png";
import loader from "./load.json";
import { Player } from "@lottiefiles/react-lottie-player";

let requestInterceptorRef;
let responseInterceptorRef;
let requestInterceptorRefStaging;
let responseInterceptorRefStaging;

const hideToast = ["leaderboards"];

const Interceptor = () => {
  const { api } = client;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const history = createBrowserHistory();
  useEffect(
    () =>
      history.listen(() => {
        window.scrollTo(0, 0);
      }),
    [history]
  );

  useEffect(() => {
    const state = store.getState();
    const authenticationState = state?.authentication;

    const AUTH_TOKEN = authenticationState?.access_token
      ? authenticationState?.access_token
      : getSessionData("access_token");
    api.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
    api.interceptors.request.eject(requestInterceptorRef);
    requestInterceptorRef = api.interceptors.request.use(
      (config) => {
        setIsLoading(true);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    api.interceptors.request.eject(responseInterceptorRef);
    responseInterceptorRef = api.interceptors.response.use(
      (response) => {
        if (
          response.config.method !== "get" &&
          hideToast.indexOf(response?.config.url || "") === -1
        ) {
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
        return response;
      },
      (error) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        if (error.response) {
          if (hideToast.indexOf(error.response?.config.url || "") === -1) {
          }
          toast.clearWaitingQueue({ containerId: "main-toast" });

          let configUrl = error?.response?.config?.url;
          if (
            error.response.status === 401 &&
            !configUrl.includes("eligibility")
          ) {
            onLogout(navigate);
            navigate("/");
          }
        }
        return Promise.reject(error);
      }
    );
  }, [navigate]);

  return (
    <>
      <div className="loaderCss">
        {isLoading && (
          <section className="loader-wrapper">
            <Player
              autoplay
              loop
              src={loader}
              style={{ height: "600px", width: "600px" }}
            ></Player>
            <img
              src={loderLogo}
              alt="logoLoder"
              style={{ borderRadius: "50%" }}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default Interceptor;
