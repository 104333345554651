import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";
function AffiliateProgram() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>Affiliate Program</h1>
          <h2>"Hotelier Affiliate Program: Earn More with Us"</h2>
          <p>
            Welcome to our "Hotelier Affiliate Program", where hotel owners and
            managers can explore an exciting opportunity to boost their revenue
            by partnering with RRooms. We understand that in the competitive
            hospitality industry, every opportunity to increase bookings and
            income is valuable. Our affiliate program is designed to help
            hoteliers achieve just that – more bookings, more guests, and more
            income.
          </p>
          <h1>In the "Hotelier Affiliate Program", we offer: </h1>
          <ol className="bulletNotHide">
            <li>
              <h2>Introduction to Affiliate Marketing:</h2> Discover the
              fundamentals of affiliate marketing and how it can benefit your
              hotel business. Learn how the program works and what you can
              expect when you join.
            </li>

            <li>
              <h2>Benefits of Affiliate Marketing:</h2> Explore the advantages
              of becoming an affiliate partner. From increased bookings and
              revenue to access to a vast network of potential guests, you'll
              see why this program is a game-changer.
            </li>

            <li>
              <h2>How to Join:</h2> Find out how easy it is to become an
              affiliate partner with www.rrooms.in. We'll guide you through the
              simple process of signing up and getting started.
            </li>
            <li>
              <h2>Affiliate Tools and Resources:</h2> Gain access to a suite of
              tools and resources that will help you promote your hotel through
              our affiliate program effectively. From banners and promotional
              materials to tracking links, we provide what you need to succeed.
            </li>
            <li>
              <h2>Commission Structure:</h2> Learn about our competitive
              commission structure and how you can earn a percentage of every
              successful booking made through your affiliate link.
            </li>
            <li>
              <h2>Promotional Strategies:</h2> Explore strategies for promoting
              your affiliate link, including tips on social media marketing,
              email campaigns, website integration, and more.
            </li>
            <li>
              <h2>Real Success Stories:</h2> Discover real-life success stories
              from hoteliers who have thrived as affiliate partners. Learn from
              their experiences and see the potential for your own business.
            </li>
            <li>
              <h2>Performance Tracking:</h2> Understand how we track and report
              on your affiliate performance. You'll have access to detailed
              analytics and reports to monitor your earnings.
            </li>
            <li>
              <h2>Payment and Payouts:</h2> Find out how you'll receive your
              commission payments and what you need to do to ensure timely
              payouts.
            </li>

            <li>
              <h2> Dedicated Support:</h2> Count on our dedicated support team
              to assist you throughout your affiliate journey. We're here to
              answer your questions and provide guidance.
            </li>
            <li>
              <h2> Customization:</h2> The affiliate program is flexible and can
              be tailored to suit your property's unique needs and goals. We'll
              work with you to create a plan that aligns with your objectives.
            </li>
          </ol>
          <p>
            By joining our "Hotelier Affiliate Program," you'll tap into a world
            of possibilities to increase your hotel's bookings and revenue. We
            believe that your success is our success, and we're committed to
            helping you achieve your business goals.
          </p>
          <p>
            Stay tuned for updates, success stories, and tips on making the most
            of your affiliate partnership with RRooms. It's time to start
            earning more with us.
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
}

export default AffiliateProgram;
