import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticationSeletor,
  saveUser,
} from "../../store/slices/authenticationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { onLogout } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { Container, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getallProperty,
  propertyAllData,
} from "../../store/slices/PropertyData";
import OTPInput from "react-otp-input";
import { forgotPassword, updatePassword } from "API/services/newServices";

const Header = ({ setToggleSideBar, sideVal }) => {
  const [otp, setOtp] = useState("");
  const [ifOtp, setIfOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [counter, setCounter] = useState(30);
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState("");
  const { ProfileImg, Logolandingpage, sliderImgOnly, user_agreement } =
    Imagesdata || {};
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDetail } = useSelector(authenticationSeletor);
  const { allProperty } = useSelector(propertyAllData);

  const [userData, setUserData] = useState({});
  const [show, setShow] = useState(false);
  const getPropertiesFun = async () => {
    dispatch(
      getallProperty(
        userDetail.isOwner ? userDetail.ownerProperty : userDetail.userProperty
      )
    );
    setShow(true);
  };

  useEffect(() => {
    setUserData(userDetail);
  }, [userDetail]);

  const loginUser = async (item) => {
    // store.dispatch(clearAuthenticationState());
    const statusMain = await dispatch(saveUser(userDetail, item, navigate));
    if (statusMain) {
      setShow(false);
      window.location.reload();
      toast.success("Property Switched*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };
  const changePassword = async (e) => {
    e.preventDefault();
    const body = {
      admin: userDetail.logintype == "rroomUser",
      id: userDetail.id,
    };
    const res = await forgotPassword(body);

    if (res?.status) {
      if (!ifOtp) {
        setIfOtp(true);
      } else {
        setCounter(30);
        setResendOtp(false);
      }
    }
  };

  const verifyHandler = async (e) => {
    e.preventDefault();
    const param = {
      id: userDetail.id,
      body: {
        otp,
        password,
      },
      admin: userDetail.logintype == "rroomUser",
    };
    const res = await updatePassword(param);
    if (!res) {
      toast.error("Wrong OTP !");
      return;
    }
    setIfOtp(false);
    toast.success("successfully changed Password!");
  };
  const timerForOtp = () => {
    const countRef = setTimeout(() => {
      setCounter(() => counter - 1);
    }, 1000);
    if (counter <= 0) {
      clearTimeout(countRef);
      setResendOtp(true);
    }
  };
  useEffect(() => {
    if (ifOtp) {
      timerForOtp();
    } else {
      setCounter(30);
    }
  }, [counter, ifOtp]);

  return (
    <header
      className={
        userData?.logintype == "propertyUser"
          ? "flex propertyUserHeader"
          : ` flex`
      }
    >
      <figure className="position-relative headerLogoAdmin">
        <a href="/" className="logo-box">
          <img alt="" src={Logolandingpage} alt="Side Bar Main Logo" />
          <h2>RRooms</h2>
        </a>
        <div
          className={`ml-2 openSidebarBtn ${!sideVal && "addedTogglerClaas"}`}
          onClick={() => setToggleSideBar(!sideVal)}
        >
          <FontAwesomeIcon icon="fa-solid fa-bars" size="2xl" color="black" />
        </div>
      </figure>
      <div className="profile flex">
        <div className="name">
          <span>Hello </span>
          <h4 className="m-0">{userDetail?.name && userDetail?.name}</h4>
          {userDetail?.propertyLength <= 1 ? (
            <span>{userDetail?.propertyName}</span>
          ) : (
            <span onClick={getPropertiesFun} style={{ cursor: "pointer" }}>
              {userDetail?.propertyName}
            </span>
          )}
        </div>

        <div
          className="userIcon flex align-items-center border rounded p-0 gap-2 ml-3 p-1 position-relative"
          style={{ cursor: "pointer" }}
        >
          <figure>
            <img alt="" src={ProfileImg} />
          </figure>
          <div className="">
            <FontAwesomeIcon
              icon="fa-solid fa-chevron-down"
              color="#000"
              className="fs-6"
            />
          </div>
          <div className="userDropDown">
            <ul>
              <li onClick={changePassword}>Change Password</li>
              {userDetail.logintype !== "rroomUser" && (
                <li>
                  <a className="d-block" href={user_agreement} target="_blank">
                    Download Agreement
                  </a>
                </li>
              )}

              <hr />
              <li onClick={() => onLogout(navigate)}>Logout</li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xl"
        backdrop="static"
        className="checkInModal"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="color-black"
          >
            Your Properties
          </Modal.Title>
          <button className="closeButton" onClick={() => setShow(false)}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="gap-4 mb-4">
              {allProperty?.map((item, ind) => {
                return (
                  <div className="card" key={ind}>
                    <div className="top-section">
                      <img alt="" src={sliderImgOnly} alt="" />
                    </div>
                    <div className="bottom-section">
                      <span className="title">{item?.name}</span>
                      <span className="text-center w-100">
                        {item?.propertyCode}
                      </span>
                    </div>
                    <div className="flex justify-content-center">
                      <button
                        onClick={() => loginUser(item)}
                        disabled={
                          userDetail?.propertyId == item.id ? true : false
                        }
                        style={{
                          cursor: userDetail?.propertyId == item.id && "auto",
                          opacity: userDetail?.propertyId == item.id && 0.5,
                        }}
                      >
                        Go To Dashboard
                        <FontAwesomeIcon
                          icon="fa-solid fa-right-from-bracket"
                          className="ml-2"
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <div className={`otpModal ${ifOtp ? "active" : ""}`}>
        <div className="innerOtpModal">
          <section className="otpIfNoLogin verify-otp-booking position-relative">
            <button
              className="btn closeBtn text-black position-absolute top-0 end-0 m-0"
              onClick={() => setIfOtp(!ifOtp)}
            >
              <FontAwesomeIcon icon="fa-solid fa-x" />
            </button>
            <div className="title">OTP</div>
            <div className="title">Verification Code</div>
            <p className="m-0">
              We have sent a verification code to your mobile number
            </p>

            <form id="inputs" onSubmit={verifyHandler}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="number"
                    className="Otp-form-control"
                  />
                )}
              />

              <div className="left-and-right-inner-addon input-container mt-4 d-flex">
                <i className="fa fa-lock left"></i>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={passwordShow ? "text" : "password"}
                  className="w-100 text-start fs-6 p-2"
                  placeholder="Enter Password"
                />
                {passwordShow ? (
                  <button
                    type="button"
                    className="right btn text-black p-1"
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    <i className="fa fa-eye-slash"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="right btn text-black p-1"
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    <i className="fa fa-eye"></i>
                  </button>
                )}
              </div>
              <button
                className="btn border border-primary text-primary submitBtn"
                type="submit"
                disabled={otp && password ? false : true}
              >
                Change Password
              </button>
            </form>
            {resendOtp ? (
              <a
                href="#"
                className="termsparagraph m-0 mt-3"
                style={{ color: "#ff0018" }}
                onClick={changePassword}
              >
                Resend OTP
              </a>
            ) : (
              <p className="m-0 termsparagraph mt-3">
                Resend OTP in {`00:${counter > 9 ? counter : `0${counter}`}`}
              </p>
            )}
          </section>
        </div>
      </div>
    </header>
  );
};

export default Header;
