import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {
  getRroomPropertyAction,
  propertySeletor,
  setFilter,
  setSearch,
} from "../../store/slices/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import LandingHeader from "../../components/landingHeader";
import SearchBar from "../../components/searchBar";
import services from "../../API/services";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Button from "react-bootstrap/Button";
import soldOut from "../../assets/images/soldOut.png";
import FrontFooter from "../../layout/footer/FrontFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { baseImgUrl } from "../../API/client/api";
import { setShowModal } from "../../store/slices/modalState";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactGA from "react-ga4";

const Hotallistings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sliderImgOnly } = Imagesdata || {};

  const reviewKeynumber = {
    1: "Very Poor",
    2: "Poor",
    3: "Good",
    4: "Very Good",
    5: "Excellent",
  };

  const settingsImgSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
  };
  const dispatch = useDispatch();
  const [propertyList, setPropertyList] = useState([]);
  const [propertyCategoryData, setPropertyCategoryData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const { rRoomProperty, filterValues, searchVal, loading } =
    useSelector(propertySeletor);
  const [tableData, setTableData] = useState([]);
  const [avrageReview, setAvrageReview] = useState({});
  const { userDetail } = useSelector(authenticationSeletor);
  const [sortValue, setSortValue] = useState(0);
  const [filterBar, setFilterBar] = useState(false);
  const { showModal } = useSelector((s) => s.showModal);

  const [localities, setLocalities] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRoomCategory();
    getPropertyCategory();
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "hotel_listing" });
    setPropertyList(rRoomProperty);
  }, [rRoomProperty]);

  useEffect(() => {
    let byPrice =
      sortValue == 1
        ? "order_by=price&order_direction=desc"
        : sortValue == 2
        ? "order_by=price&order_direction=asc"
        : sortValue == 3
        ? "order_by=rating&order_direction=desc"
        : "order_direction=asc";
    let getFilterValuesData = { ...filterValues };
    if (getFilterValuesData?.latlongFilter) {
      const { lat, long } = getFilterValuesData?.latlongFilter;
      if (lat && long) {
        dispatch(
          getRroomPropertyAction(`?order_direction=asc&lat=${lat}&long=${long}`)
        );
        return;
      }
    }
    let choice = getFilterValuesData?.travellerChoice?.length
      ? `&traveller_choice=${getFilterValuesData?.travellerChoice?.toString()}`
      : "";
    let locality =
      getFilterValuesData?.localityFilter?.length != 0
        ? `&pin_code=${getFilterValuesData.localityFilter}`
        : "";

    if (
      locality.length ||
      sortValue ||
      getFilterValuesData?.propertyCategoryFilter?.length ||
      getFilterValuesData?.roomCategoryFilter?.length ||
      getFilterValuesData?.travellerChoice?.length
    ) {
      let propertyType =
        getFilterValuesData?.propertyCategoryFilter?.toString();
      let roomType = getFilterValuesData?.roomCategoryFilter?.toString();
      dispatch(
        getRroomPropertyAction(
          `?${byPrice}${
            locality ? locality : searchVal ? `&query=${searchVal}` : ""
          }${propertyType ? `&property_type=${propertyType}` : ""}${
            roomType ? `&room_type=${roomType}` : ""
          }${choice}`
        )
      );
    } else {
      dispatch(
        getRroomPropertyAction(
          `?order_direction=asc${
            locality ? locality : searchVal ? `&query=${searchVal}` : ""
          }`
        )
      );
    }
  }, [sortValue, filterValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userDetail]);

  // const getReview = async (id) => {
  //   try {
  //     const res =
  //       await services.globalService.getCustomerReviewPropertyIdService(1);
  //     if (res.status === 200) {
  //       setTableData(res?.data?.data);
  //       setAvrageReview(res?.data?.data[res?.data?.data.length - 1]);
  //     }
  //   } catch (error) {}
  // };

  const traveller = [
    {
      name: "Short-Stays",
      enum: 1,
    },
    {
      name: "Business Travel",
      enum: 2,
    },
    {
      name: "Couple Friendly",
      enum: 7,
    },
    {
      name: "Family Vacation",
      enum: 3,
    },
    // {
    //   name: "Guaranteed Check-In",
    //   enum: 4,
    // },
    {
      name: "Group Travel",
      enum: 5,
    },

    {
      name: "Solo Travel",
      enum: 6,
    },
  ];
  const getRoomCategory = async () => {
    try {
      const res = await services.property.getRoomCategoryService();
      if (res.status === 200) {
        setCategoryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const getPropertyCategory = async () => {
    try {
      const res = await services.property.getPropertyCategoryService();
      if (res.status === 200) {
        setPropertyCategoryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const [cancelShare, setShowShare] = useState(false);
  const showShare = () => {
    setShowShare(!cancelShare);
  };

  const filterCheckHAndler = (e, id) => {
    if (e.target.checked) {
      let getFilterValue = { ...filterValues };
      let mainObj = [...getFilterValue[e.target.name]];
      mainObj.push(id);
      getFilterValue[e.target.name] = mainObj;
      dispatch(setFilter({ [e.target.name]: mainObj }));
    } else {
      let getFilterValue = { ...filterValues };
      const resultfilter = getFilterValue[e.target.name].filter((d, index) => {
        return d !== id;
      });
      getFilterValue[e.target.name] = resultfilter;
      dispatch(setFilter({ [e.target.name]: resultfilter }));
    }
  };

  const getLocality = async (cityId) => {
    try {
      const res = await services.globalService.getLocalityByCity(cityId);
      if (res.status === 200) {
        setLocalities(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // if (filterValues.cityId) {
    //   getLocality(filterValues.cityId);
    // } else {
    //   dispatch(setFilter({ localityFilter: "" }));
    // }
  }, []);

  const clearHandler = () => {
    dispatch(
      setFilter({
        propertyCategoryFilter: [],
        roomCategoryFilter: [],
        travellerChoice: [],
        localityFilter: [],
        latlongFilter: { lat: null, long: null },
      })
    );
    setSortValue("");
    dispatch(setSearch(""));
    dispatch(getRroomPropertyAction());
  };
  return (
    <div className="hotalList">
      <LandingHeader />
      {/* banner section */}
      <div className="homeBanner"></div>

      <SearchBar />

      {/* main listing section start here */}
      <div className="listing-wrapper">
        <div className="container flex">
          <div className={`leftSidebar ${filterBar ? "showFilter" : ""}`}>
            <div className="heading flex">
              <h2>Filter</h2>
              {!searchVal &&
              !sortValue &&
              filterValues.propertyCategoryFilter.length === 0 &&
              filterValues.travellerChoice.length === 0 &&
              filterValues.localityFilter.length == 0 &&
              filterValues.roomCategoryFilter.length === 0 ? null : (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={clearHandler}
                  className="btn-sm text-white"
                >
                  Clear All
                </Button>
              )}

              {/* {!searchVal &&
              !sortValue &&
              filterValues.amenitiesFilter.length === 0 &&
              filterValues.propertyCategoryFilter.length === 0 &&
              filterValues.roomCategoryFilter.length === 0 ? null : (
                <Button
                  type="button"
                  variant="warning"
                  onClick={completeChangeHandler}
                  className="btn-sm"
                >
                  Apply
                </Button>
              )} */}

              <Button
                type="button"
                variant="dark"
                className={`btn-sm rounded-circle user_Sidebar_closeBtn`}
                onClick={() => setFilterBar(false)}
              >
                <FontAwesomeIcon icon="fa-solid fa-x" />
              </Button>
            </div>
            <div className="price-range multi-checkbox">
              <p>Sort By Price</p>
              <ul className="d-flex justify-content-between align-items-center m-0">
                <li className="m-0">
                  <input
                    type="checkbox"
                    id="low"
                    checked={sortValue === 1 ? true : false}
                    onChange={(e) => setSortValue(1)}
                    name="byPrice"
                  />
                  <label
                    className="d-flex justify-content-between align-items-center m-0"
                    htmlFor="low"
                  >
                    Low to High
                  </label>
                </li>
                <li className="m-0">
                  <input
                    type="checkbox"
                    id="high"
                    checked={sortValue === 2 ? true : false}
                    onChange={(e) => setSortValue(2)}
                    name="byPrice"
                  />
                  <label
                    htmlFor="high"
                    className="d-flex justify-content-between align-items-center m-0"
                  >
                    High to Low
                  </label>
                </li>
              </ul>
            </div>

            <div className="multi-checkbox">
              <p>Property By Category</p>
              <ul>
                {propertyCategoryData?.map((data, key) => (
                  <li key={key}>
                    <input
                      id={`hotel${key}`}
                      type="checkbox"
                      name="propertyCategoryFilter"
                      checked={filterValues?.propertyCategoryFilter?.includes(
                        data.id
                      )}
                      onChange={(e) => filterCheckHAndler(e, data.id)}
                    />
                    <label htmlFor={`hotel${key}`}>{data.name}</label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="multi-checkbox">
              <p>Rooms By Category</p>
              <ul>
                {categoryData?.map((data, key) => (
                  <li key={key}>
                    <input
                      id={`room${key}`}
                      type="checkbox"
                      name="roomCategoryFilter"
                      checked={filterValues?.roomCategoryFilter?.includes(
                        data.id
                      )}
                      onChange={(e) => filterCheckHAndler(e, data.id)}
                    />
                    <label htmlFor={`room${key}`}>{data.name}</label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="multi-checkbox">
              <p>Traveller Choice</p>
              <ul>
                {traveller?.map((data, key) => (
                  <li key={key}>
                    <input
                      type="checkbox"
                      name="travellerChoice"
                      id={`traveller${data.enum}`}
                      onChange={(e) => filterCheckHAndler(e, data.enum)}
                      checked={filterValues?.travellerChoice?.includes(
                        data.enum
                      )}
                    />
                    <label htmlFor={`traveller${data.enum}`}>{data.name}</label>
                  </li>
                ))}
              </ul>
            </div>
            {localities?.length > 0 && (
              <div className="multi-checkbox">
                <p>Sort By Locality</p>
                <ul>
                  {localities?.map((data, key) => (
                    <li key={key}>
                      <input
                        type="checkbox"
                        name="localityFilter"
                        id={`locality${data.id}`}
                        checked={filterValues?.localityFilter == data.pin_code}
                        // onChange={(e) => filterCheckHAndler(e, data.pin_code)}
                        onChange={() => {
                          let temp =
                            data.pin_code == filterValues.localityFilter
                              ? ""
                              : data.pin_code;
                          dispatch(setFilter({ localityFilter: temp }));
                        }}
                      />
                      <label htmlFor={`locality${data.id}`}>{data.name}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="rightContant">
            <div className="heading flex">
              <h1>
                {loading ? (
                  <>
                    <FontAwesomeIcon icon="fa-solid fa-spinner" spin />{" "}
                    <span>Please wait, while loading best hotels</span>
                  </>
                ) : (
                  `Showing ${propertyList?.length} Best Hotels`
                )}
              </h1>
              <div className="flex gap-1">
                <Button
                  className="btn-sm flex justify-content-between px-3"
                  id="filterBtnVis"
                  onClick={() => setFilterBar(!filterBar)}
                >
                  <span>Filter</span>
                  <FontAwesomeIcon icon="fa-solid fa-filter" className="ml-1" />
                </Button>
              </div>
            </div>
            {loading ? (
              new Array(5).fill().map((_, ind) => (
                <div className="listCard flex position-relative" key={ind}>
                  <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
                    <Skeleton
                      containerClassName="imageslider"
                      style={{ height: 240 }}
                    />
                  </SkeletonTheme>

                  <div className="content">
                    <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
                      <Skeleton />
                    </SkeletonTheme>
                    <div className="flex flex-column hotelNameTag justify-content-center">
                      <div className="flex align-items-center gap-2">
                        <h2>
                          <Skeleton />
                        </h2>
                        <div
                          style={{
                            padding: 4,
                            textTransform: "capitalize",
                          }}
                        >
                          <Skeleton />
                        </div>
                      </div>
                      <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
                        <Skeleton />
                      </SkeletonTheme>
                      <div className="tags d-flex gap-3">
                        <SkeletonTheme
                          baseColor="#fff"
                          highlightColor="#cfcfcf"
                        >
                          <Skeleton />
                        </SkeletonTheme>
                        <SkeletonTheme
                          baseColor="#fff"
                          highlightColor="#cfcfcf"
                        >
                          <Skeleton />
                        </SkeletonTheme>
                      </div>
                      <ul className="amenities flex w-100 gap-3">
                        {[...new Array(20)].fill(1).map((_, index) => (
                          <li key={index}>
                            <Skeleton style={{ width: 30, height: 30 }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                {propertyList?.length > 0 ? (
                  [...propertyList]?.reverse()?.map((data, ind1) => {
                    const profileImg = data?.PropertyImage?.length
                      ? data?.PropertyImage.find(
                          (i) => i.id === data?.profileImageID
                        )
                      : null;
                    let allRating = 0;
                    data?.Ratings?.map(
                      (rating) => (allRating += rating?.rating)
                    );
                    const rateAverage =
                      allRating / data?.Ratings?.length
                        ? allRating / data?.Ratings?.length
                        : 0;

                    const imageList = data?.PropertyImages || [];
                    if (profileImg) {
                      imageList.unshift(profileImg);
                    }

                    return (
                      <div
                        className="listCard flex position-relative"
                        key={ind1}
                      >
                        {data?.status ? null : (
                          <div className="position-absolute soldOutImg">
                            <img
                              src={soldOut}
                              alt="sold out img"
                              loading="lazy"
                            />
                          </div>
                        )}

                        {imageList.length > 0 ? (
                          <div className="imageslider">
                            {data?.Ratings?.length <= 0 && (
                              <p
                                className="text-white m-0 fw-medium py-1 px-2 d-inline rounded"
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: 0,
                                  fontSize: ".8rem",
                                  letterSpacing: 0.5,
                                  zIndex: 1,
                                  backgroundColor: "#000",
                                }}
                              >
                                New Listing
                              </p>
                            )}
                            <div
                              className="flex gap-2 categoryTag align-items-center position-absolute top-0 start-0"
                              style={{
                                backgroundColor:
                                  data?.PropertyCategory?.name === "Economy"
                                    ? "green"
                                    : data?.PropertyCategory?.name === "Elite"
                                    ? "#5b0f57"
                                    : "orange",
                              }}
                            >
                              {data?.PropertyCategory?.name === "Economy" ? (
                                <FontAwesomeIcon
                                  icon="fa-solid fa-crown"
                                  style={{
                                    visibility: "hidden",
                                    marginRight: -13,
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon icon="fa-solid fa-crown" />
                              )}
                              {(data?.PropertyCategory?.name == "Luxury"
                                ? "LUXE"
                                : data?.PropertyCategory?.name) ?? ""}
                            </div>
                            <Slider {...settingsImgSlider}>
                              {imageList.map((img, ind2) => {
                                return (
                                  <div key={ind2}>
                                    <figure className="sliderImg">
                                      {data?.status ? (
                                        <Link to={`/hotel-details/${data.id}`}>
                                          <img
                                            loading="lazy"
                                            src={
                                              img
                                                ? `${baseImgUrl}${img.image}`
                                                : sliderImgOnly
                                            }
                                            alt=""
                                          />
                                        </Link>
                                      ) : (
                                        <img
                                          loading="lazy"
                                          src={
                                            img
                                              ? `${baseImgUrl}${img.image}`
                                              : sliderImgOnly
                                          }
                                          alt=""
                                        />
                                      )}
                                    </figure>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        ) : (
                          <div className="imageslider">
                            <Slider {...settingsImgSlider}>
                              <Link to={`/hotel-details/${data.id}`}>
                                <figure className="sliderImg">
                                  <img
                                    src={sliderImgOnly}
                                    alt=""
                                    loading="lazy"
                                  />
                                </figure>
                              </Link>
                              {/* ))} */}
                            </Slider>
                            <div className="cartBtns">
                              <i
                                className="fa fa-share-alt"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        )}
                        <div className="content">
                          {avrageReview?.averageRatings ? (
                            <div className="rating flex">
                              <div
                                className={`col-right flex ${
                                  reviewKeynumber[avrageReview?.averageRatings]
                                }`}
                              >
                                <span>{avrageReview?.averageRatings}/5</span>
                                <p>
                                  ({tableData?.length - 1} Ratings) ·{" "}
                                  {
                                    reviewKeynumber[
                                      avrageReview?.averageRatings
                                    ]
                                  }
                                </p>
                              </div>
                            </div>
                          ) : null}

                          <div className="flex flex-column hotelNameTag justify-content-center">
                            <div className="flex align-items-center gap-2">
                              {data?.status ? (
                                <Link to={`/hotel-details/${data.id}`}>
                                  <h2>{data?.name ?? "N/A"}</h2>
                                </Link>
                              ) : (
                                <h2>{data?.name ?? "N/A"}</h2>
                              )}
                              <div className="flex gap-2 categoryTag reviewBox p-1 text-capitalize  align-items-center bg-success">
                                <FontAwesomeIcon
                                  icon="fa-solid fa-star"
                                  color="yellow"
                                  size="lg"
                                />
                                {rateAverage.toFixed(1)} (
                                {data?.Ratings?.length + " Ratings"})
                              </div>
                            </div>
                            {data?.status ? (
                              <Link
                                to={`/hotel-details/${data.id}`}
                                className="text-dark address flex"
                              >
                                {`${data?.locality ?? "N/A"} ${
                                  data?.distance
                                    ? data?.distance?.toFixed(2) + " km"
                                    : ""
                                }`}
                              </Link>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                className="text-dark address flex"
                              >
                                {`${data?.locality} ${
                                  data?.distance
                                    ? data?.distance?.toFixed(2) + " km"
                                    : ""
                                }`}
                              </a>
                            )}
                            <div className="tags d-flex gap-3">
                              {data?.coupleFriendly == 1 && (
                                <p>
                                  <i className="bi bi-hearts"></i> Couple
                                  Friendly
                                </p>
                              )}
                              {data?.locaidAccept == 1 && (
                                <p>
                                  <FontAwesomeIcon icon="fa-solid fa-id-card" />{" "}
                                  Local ID Accepted
                                </p>
                              )}
                            </div>
                            {data.PropertyAmenities?.length > 0 ? (
                              <ul className="amenities flex w-100 gap-3">
                                {data.PropertyAmenities?.map(
                                  (amenities, ind3) => {
                                    return (
                                      <li key={ind3}>
                                        <img
                                          loading="lazy"
                                          src={
                                            `${baseImgUrl}` +
                                            amenities?.Amenity?.icon
                                          }
                                          alt=""
                                        />
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : null}
                          </div>

                          {/* <div className="price-wrap flex border-top "> */}
                          {data?.status ? (
                            <div className="buttons flex justify-content-end border-top">
                              {/* <Link
                          className="activeLink"
                          to={`/hotel-details/${data?.id}`}
                        >
                          <span>
                          //  ₹
                          //   {data?.Rooms[0]?.regularPrice
                          //     ? Math.round(data?.Rooms[0]?.regularPrice / 4)
                          //     : 0}
                          //   <del>
                          //     ₹
                          //     {Math.round(
                          //       data?.Rooms[0]?.regularPrice / 4 +
                          //         (data?.Rooms[0]?.regularPrice / 4) * 0.5
                          //     )}
                          //   </del>
                            Coming Soon
                          </span>
                          <span>6 HRS</span>
                        </Link>
                        <Link
                          className="activeLink"
                          to={`/hotel-details/${data?.id}`}
                        >
                          <span>
                            //  ₹
                            // {data?.Rooms[0]?.regularPrice
                            //   ? Math.round(data?.Rooms[0]?.regularPrice / 2)
                            //   : 0}
                            // <del>
                            //   ₹
                            //   {Math.round(
                            //     data?.Rooms[0]?.regularPrice / 2 +
                            //       (data?.Rooms[0]?.regularPrice / 2) * 0.5
                            //   )}
                            // </del>
                            Coming Soon
                          </span>
                          <span>12 HRS</span>
                        </Link> */}
                              {/* <Link
                          className="activeLink"
                          to={`/hotel-details/${data?.id}`}
                        >
                          <span>
                            <del>
                              ₹
                              {Math.round(
                                data?.Rooms[0]?.regularPrice /
                                  ((100 - 20) / 100)
                              )}
                            </del>
                            <small> 20% OFF</small>
                          </span>
                          <span>
                            ₹ {data?.Rooms[0]?.regularPrice ?? 0} Full Day
                          </span>
                        </Link> */}
                              <Link
                                className="activeLink"
                                to={`/hotel-details/${data?.id}`}
                              >
                                <span>
                                  ₹ {data?.Rooms[0]?.regularPrice ?? 0}
                                  <del>
                                    ₹
                                    {Math.round(
                                      data?.Rooms[0]?.regularPrice ??
                                        0 / ((100 - 20) / 100)
                                    )}
                                  </del>{" "}
                                  <span className="text-success"> 20% OFF</span>
                                </span>
                                <small>Per Night</small>
                              </Link>
                            </div>
                          ) : (
                            <div className="buttons flex justify-content-end border-top">
                              {/* <a href="javascript:void(0)">
                          <span>
                            ₹
                            {data?.Rooms[0]?.regularPrice
                              ? Math.round(data?.Rooms[0]?.regularPrice / 4)
                              : 0}
                          </span>
                          <span>6 HRS</span>
                        </a>
                        <a href="javascript:void(0)">
                          <span>
                            ₹
                            {data?.Rooms[0]?.regularPrice
                              ? Math.round(data?.Rooms[0]?.regularPrice / 2)
                              : 0}
                          </span>
                          <span>12 HRS</span>
                        </a> */}
                              <a href="javascript:void(0)">
                                <span>
                                  ₹ {data?.Rooms[0]?.regularPrice ?? 0}
                                  <del>
                                    ₹
                                    {Math.round(
                                      data?.Rooms[0]?.regularPrice /
                                        ((100 - 20) / 100)
                                    )}
                                  </del>{" "}
                                  <small>Per Night</small>
                                </span>
                                <span> 20% OFF</span>
                              </a>
                            </div>
                          )}
                          {/* </div> */}
                          {/* <div className="priceMain">
                      {data?.Rooms?.length ? <span>room / night</span> : null}
                      {data?.Rooms?.length ? (
                        <h3>
                          ₹ {data?.Rooms[0]?.regularPrice}
                          <span>
                            <s>
                              ₹
                              {data?.Rooms[0]?.regularPrice +
                                (data?.Rooms[0]?.regularPrice * 20) / 100}
                            </s>
                          </span>
                        </h3>
                      ) : null}
                    </div> */}
                          {/* <div className="buttons">
                      {data?.status ? (
                        <Link to={`/hotel-details/${data?.id}`}>
                          View Details
                        </Link>
                      ) : (
                        <a
                          href="#"
                          style={{
                            pointerEvents: "none",
                            backgroundColor: "#1c8afaa1",
                          }}
                        >
                          View Details
                        </a>
                      )}
                    </div> */}

                          {data?.Coupons?.length ? (
                            <p className="useCode">
                              Use <strong>{data?.Coupons[0].code}</strong> this
                              code and get{" "}
                              <strong>{data?.Coupons[0].amount}%</strong> of on
                              your booking
                            </p>
                          ) : null}
                        </div>
                        <div className="shareBtn" onClick={() => showShare()}>
                          <i className="fa fa-share-alt" aria-hidden="true"></i>
                        </div>
                        {cancelShare && (
                          <div className="sharesBtns">
                            <a
                              target="_blank"
                              href={`mailto:?Subject=Best Deals on Hotel Booking&Body=I%20saw%20best%20price%20room%20i%20thought%20you%20would%20like%20 https://rrooms.in/hotel-details/${data.id}`}
                            >
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              target="_blank"
                              href={`https://api.whatsapp.com/send?text=https://rrooms.in/hotel-details/${data.id}`}
                            >
                              <i
                                className="fa fa-whatsapp"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a
                              target="_blank"
                              href={`http://www.facebook.com/sharer.php?u=https://rrooms.in/hotel-details/${data.id}`}
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="ifNoPrt">
                    <FontAwesomeIcon icon="fa-solid fa-hotel" bounce />
                    <h4>Coming Soon.....</h4>
                    <h6 onClick={() => dispatch(setShowModal(!showModal))}>
                      Refer a Property and Get one night free stay all over
                      India
                      <a onClick={(e) => e.preventDefault()} href="#">
                        click here
                      </a>
                    </h6>
                    <span>T&C apply*</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Hotallistings;
