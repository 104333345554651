import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  gstNumber: Yup.string()
    .required(addUserValidation.gstNumber)
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Please Enter Correct Gst Number*"
    ),
  tanNumber: Yup.string()
    .required(addUserValidation.tanNumber)
    .matches(/^[A-Z]{4}[0-9]{5}[A-Z]{1}$/, "Please Enter Correct Tan Number*"),
  entityName: Yup.string().required("Please Enter Entity Name"),
  firmTypeEntity: Yup.string().required("Please Select Firm Type*"),
  PropertyPanNumber: Yup.string()
    .required("Enter Pan Number.")
    .matches(
      /^[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/,
      "Please Enter Correct Pan Number*"
    ),
  ownerAdharCertificate: Yup.mixed().test(
    "file",
    "Please Select Owner Adhar Certificate PDF file",
    (value) => {
      if (value?.length > 0) {
        return value && value[0].type === "application/pdf";
      }
      return false;
    }
  ),
  GSTpicture: Yup.mixed().test(
    "file",
    "Please Select GST Cetificate PDF file",
    (value) => {
      if (value?.length > 0) {
        return value && value[0].type === "application/pdf";
      }
      return false;
    }
  ),
  tanpicture: Yup.mixed().test(
    "file",
    "Please Select Tan Cetificate PDF file",
    (value) => {
      if (value?.length > 0) {
        return value && value[0].type === "application/pdf";
      }
      return false;
    }
  ),
  panpicture: Yup.mixed().test(
    "file",
    "Please Select Pan Cetificate PDF file",
    (value) => {
      if (value?.length > 0) {
        return value && value[0].type === "application/pdf";
      }
      return false;
    }
  ),
  ownerPanCertificate: Yup.mixed().test(
    "file",
    "Please Select Pan Cetificate PDF file",
    (value) => {
      if (value?.length > 0) {
        return value && value[0].type === "application/pdf";
      }
      return false;
    }
  ),
  chequepicture: Yup.mixed().test(
    "file",
    "Please Select Rent Agreement Cetificate PDF file",
    (value) => {
      if (value?.length > 0) {
        return value && value[0].type === "application/pdf";
      }
      return false;
    }
  ),
  bankName: Yup.string().required("Please Enter Bank Name*"),
  branchName: Yup.string().required("Please Enter Branch Name*"),
  accountHolder: Yup.string()
    .required("Please Enter Account Holder*")
    .matches(/^[a-zA-Z\s]+$/, "Please Enter Correct Name*"),
  accountNumber: Yup.string().required("Please Enter Account Number*"),
  ifsc: Yup.string().required("Please Enter IFSC*"),
});

export default { resolver: yupResolver(validation) };
