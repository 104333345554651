import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";
function Community() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>Community</h1>
          <h2>"Hotelier Community: Connect, Collaborate, and Grow Together"</h2>
          <p>
            At RRooms we understand that the hospitality industry thrives on
            collaboration, shared knowledge, and networking. Our community is a
            dedicated space for hotel owners, managers, and professionals to
            come together, exchange ideas, and support one another in the
            pursuit of success.
          </p>
          <h1>As a community, you get to :</h1>
          <ol className="bulletNotHide">
            <li>
              <h2>Connect with Peers:</h2> Join a vibrant community of fellow
              hoteliers from around the world. Connect, network, and share
              experiences with professionals who understand the unique
              challenges and opportunities of the hospitality sector.
            </li>
            <li>
              <h2>Ask Questions and Seek Advice:</h2> Have a burning question or
              need advice on a particular challenge? Our community is here to
              help. Ask questions, seek insights, and benefit from the
              collective wisdom of your peers.
            </li>
            <li>
              <h2>Share Success Stories:</h2> Celebrate your wins and
              achievements with the community. Share your success stories,
              innovative ideas, and strategies that have worked for your
              property.
            </li>
            <li>
              <h2>Discuss Industry Trends:</h2> Stay informed about the latest
              trends and developments in the hospitality industry. Engage in
              discussions on topics such as guest preferences, technology,
              sustainability, and more.
            </li>
            <li>
              <h2>Collaborate on Projects:</h2> Explore collaboration
              opportunities with other hoteliers, whether it's co-marketing
              campaigns, joint initiatives, or partnerships that can benefit
              your property.
            </li>
            <li>
              <h2>Learn from Experts:</h2> Access exclusive content, webinars,
              and interviews with industry experts. Gain valuable insights into
              best practices, strategies, and emerging trends.
            </li>
            <li>
              <h2>Support and Mentorship:</h2> Offer and receive support and
              mentorship from seasoned professionals. Whether you're a veteran
              hotelier or just starting in the industry, our community is a
              source of guidance and inspiration.
            </li>
            <li>
              <h2>Stay Informed:</h2> Stay updated with news, regulations, and
              industry events that may impact your hotel business. Our community
              keeps you in the loop.
            </li>
            <li>
              <h2>Engage in Polls and Surveys:</h2> Participate in polls and
              surveys to share your opinions and contribute to industry
              research.
            </li>
            <li>
              <h2> Exclusive Benefits:</h2> Enjoy exclusive benefits and offers
              available only to members of our hotelier community.
            </li>
          </ol>
          <p>
            At RRooms, we believe that together, we can achieve more. The
            "Hotelier Community" is a place where you can connect with
            like-minded professionals, gain knowledge, and find the support you
            need to succeed in the competitive world of hospitality.
          </p>
          <p>
            Join us in building a strong and collaborative community of
            hoteliers. Your insights and experiences are valuable, and we're
            here to help you thrive in the hospitality industry. Together, we
            can create exceptional guest experiences and drive the success of
            your hotel business.
          </p>
        </div>
      </section>
      {/* content section end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
}

export default Community;
