import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Badge,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";

const MultiSelectInput = ({ onChange, selectedItems, setSelectedItems }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [parentHeight, setParentHeight] = useState(43);
  const { userDetail } = useSelector(authenticationSeletor);
  const childRef = useRef(null);
  useEffect(() => {
    getPropertyData();
  }, []);
  useEffect(() => {
    if (childRef.current) {
      setParentHeight(childRef.current.offsetHeight + 30);
    }
  }, [selectedItems]);
  const getPropertyData = async () => {
    try {
      const res = await services.property.getRroomsPropertyServiceByOwner(
        userDetail?.email
      );
      if (res.status === 200) {
        setPropertyList(res.data.data);
      }
    } catch (error) {}
  };

  const toggleOption = (propertyCode) => {
    let newSelectedItems = selectedItems;
    if (selectedItems.includes(propertyCode)) {
      newSelectedItems = selectedItems.filter((item) => item !== propertyCode);
    } else {
      newSelectedItems = [...selectedItems, propertyCode];
    }
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  return (
    <div>
      <label>Select Properties: </label>
      <div style={{ position: "relative", marginBottom: 20 }}>
        {!selectedItems.length && (
          <p className="border text-capitalize text-primary w-auto d-inline-block mb-2 px-3">
            no property assigned
          </p>
        )}
        {selectedItems.map((item, index) => (
          <OverlayTrigger
            key={index}
            overlay={<Tooltip id={`tooltip-${index}`}>Remove</Tooltip>}
          >
            <Badge
              pill
              variant="primary"
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() =>
                setSelectedItems(selectedItems.filter((i) => i !== item))
              }
            >
              {propertyList.find((i) => i.id == item)?.name} &#10005;
            </Badge>
          </OverlayTrigger>
        ))}

        <FormControl
          style={{ cursor: "pointer", height: parentHeight }}
          type="text"
          placeholder="Select options..."
          onClick={() => setShowOptions(!showOptions)}
          value={selectedItems?.length ? " " : ""}
          readOnly
        />

        <div
          style={{
            position: "absolute",
            right: 10,
            top: "60%",
            fontWeight: "bold",
            color: "black",
          }}
        >
          {showOptions ? <>&#8595;</> : <>&#8593;</>}
        </div>
      </div>
      {showOptions && (
        <ListGroup style={{ marginTop: "-15px", marginBottom: "15px" }}>
          {propertyList.length ? (
            propertyList.map((option, index) => {
              const isSelected = selectedItems.includes(option.id);
              return (
                <ListGroup.Item
                  key={index}
                  action
                  type="button"
                  variant={isSelected ? "primary" : ""}
                  active={isSelected}
                  onClick={() => toggleOption(option.id)}
                >
                  {option.name}
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item disabled>No Data Found</ListGroup.Item>
          )}
        </ListGroup>
      )}
      {/* </Form> */}
    </div>
  );
};

export default MultiSelectInput;
