import { createSlice } from "@reduxjs/toolkit";
import Services from "../../API/services";
import { getallProperty } from "./PropertyData";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  access_token: null,
  userDetail: {},
  sideBarMenu: {
    activeMenu: "",
    activeMasterMenu: "",
  },
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    clearAuthenticationState: () => initialState,
    loginSuccess: (state, { payload }) => {
      state.access_token = payload.token;
      state.userDetail = payload.data;
    },
    saveUserData: (state, { payload }) => {
      state.userDetail = payload;
    },
    setMenu: (state, { payload }) => {
      state.sideBarMenu = { ...state.sideBarMenu, ...payload };
    },
  },
});

export const { clearAuthenticationState, loginSuccess, saveUserData, setMenu } =
  authenticationSlice.actions;

// Action creators are generated for each case reducer function
export const authenticationSeletor = (state) => state.authentication;

export default authenticationSlice.reducer;

export function logOutAction() {
  return async (dispatch) => {};
}

export const LoginAction = (data) => {
  return async (dispatch) => {
    try {
      const res = await Services.auth.propertyLoginService(data);
      if (res.status === 200) {
        await dispatch(
          loginSuccess({
            data: {
              ...res?.data?.data,
              logintype: "rroomUser",
              name:
                res?.data?.data?.firstName + " " + res?.data?.data?.lastName,
            },
            token: res?.data?.token,
          })
        );
        return true;
      }
    } catch (error) {
      return false;
    }
  };
};

export const PropertyLoginAction = (data) => {
  return async (dispatch) => {
    try {
      const res = await Services.auth.mainPropertyLoginService(data);
      if (res.status === 200) {
        const isOwner = res.data?.data?.role == 3;
        const propertyLength = isOwner
          ? res.data.data.ownerProperty.length
          : res.data.data.userProperty.length;
        if (propertyLength <= 1) {
          const property = await Services.property.getPropertyCategoryServiceId(
            res.data?.data.propertyId
          );
          if (property.status === 200) {
            res.data.data.property = {
              id: property.data.data.id,
              propertyCode: property.data.data.propertyCode,
              name: property.data.data.name,
            };
          }
        } else {
          dispatch(
            getallProperty(
              isOwner ? res.data.data.ownerProperty : res.data.data.userProperty
            )
          );
        }
        return {
          ...res.data?.data,
          isOwner,
          propertyLength,
          token: res.data.token,
        };
      } else {
        return "Pass";
      }
    } catch (error) {
      return "Pass";
    }
  };
};

export const saveUser = (user, property, navigate) => {
  return async (dispatch) => {
    // delete user.userProperty;
    const userData = {
      data: {
        ...user,
        name: user.firstName + " " + user.lastName,
        logintype: "propertyUser",
        propertyName: property.name,
        propertyRole: user.isOwner ? "PropertyAdmin" : "prtUser",
        propertyId: property.id,
      },
      token: user?.token,
    };

    dispatch(loginSuccess(userData));
    navigate("/dashboard/home");
    return true;
  };
};
