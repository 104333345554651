import React, { useEffect, useRef, useState } from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../store/slices/authenticationSlice";
import Select from "react-select";
import { getConfigModule, getPropertiesAll } from "store/slices/PropertyData";
import { useParams, useSearchParams } from "react-router-dom";
import Services from "API/services";

const Layout = ({
  children,
  title,
  openHandler,
  buttonTitle = "Add",
  extraButton = false,
  extraBtnHandler,
  type = false,
  optionData = [],
  searchfilter = false,
  otherFilters = null,
  searchType = "search",
  setFromDate,
  setToDate,
  fromDate,
  toDate,
  setFilterDate,
  dateFormate = "dd-MM-yyyy",
  withoutDate = false,
  filterDate = false,
  secondBtn = false,
  secondbuttonTitle = "",
  dateFilterText = "From Date : ",
  searchSelectVal,
  changeHandler,
  wantSearchableListFor,
  withoutContainer = false,
  setSearchfilter,
  hideProperty = false,
  changeHandlerState = () => {},
}) => {
  const { userDetail } = useSelector(authenticationSeletor);
  const [toggleSideBar, setToggleSideBar] = useState(true);
  const [user, setUser] = useState({});
  const dateRef = useRef();
  const [selectedOption, setSelectedOption] = useState(false);
  const { showProperties, assignedModules } = useSelector((s) => s.AllData);
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const fromMobile = searchParams.get("platform") == "mobile_app";

  const dispatch = useDispatch();

  const getSelectedUser = async () => {
    const res = await Services.auth.getPropetyUserData(id);
    if (res.status === 200) {
      setUser(res.data.data);
      if (!assignedModules.length) dispatch(getConfigModule(res.data.data));
    }
  };

  useEffect(() => {
    if (!showProperties?.length) dispatch(getPropertiesAll());
  }, []);
  useEffect(() => {
    if (!userDetail.id) {
      getSelectedUser();
    } else {
      if (!assignedModules.length) dispatch(getConfigModule(userDetail));
    }
  }, []);

  return (
    <div className={`dashboard-wrap flex ${toggleSideBar ? "" : "closeBar"}`}>
      {!fromMobile && (
        <Sidebar sideAction={setToggleSideBar} sideVal={toggleSideBar} />
      )}
      <div className={`main-content ${toggleSideBar ? "" : "closeBar"}`}>
        {!fromMobile && (
          <Header setToggleSideBar={setToggleSideBar} sideVal={toggleSideBar} />
        )}
        <div className="breadcrumb flex">
          <ul className="flex">
            <li>{title}</li>
          </ul>
          <div className="flex gap-1 align-items-center">
            {wantSearchableListFor && !hideProperty ? (
              <div className="searchFilter flex gap-1 align-items-center">
                <label htmlFor="searchText text-primary">
                  Select Property:
                </label>
                <Select
                  options={[{ value: 0, label: "All" }, ...showProperties]}
                  placeholder={"Select"}
                  value={searchSelectVal}
                  onChange={(dataSet) => {
                    changeHandler(dataSet);
                  }}
                  isSearchable={true}
                />
              </div>
            ) : null}
            {filterDate && (
              <div
                className={`searchFilter ${withoutDate && "hideDate"}`}
                onClick={() => setSelectedOption(true)}
              >
                <label htmlFor="searchText">{dateFilterText}</label>
                <ReactDatePicker
                  ref={dateRef}
                  className="dataForm"
                  placeholderText="Select Date"
                  dateFormat={dateFormate}
                  onChange={(date, e) => {
                    e.stopPropagation();
                    setFilterDate(date);
                    setSelectedOption(false);
                  }}
                  value={new Date(filterDate)}
                  selected={filterDate}
                  monthsShown={selectedOption}
                  showMonthDropdown
                  showYearDropdown
                  open={selectedOption}
                  dropdownMode="select"
                  onMonthChange={
                    withoutDate
                      ? (date) => {
                          setFilterDate(date);
                          setSelectedOption(false);
                        }
                      : () => {}
                  }
                  onCalendarClose={() => setSelectedOption(false)}
                />
              </div>
            )}
            {searchfilter && (
              <div className="searchFilter">
                {searchType === "search" ? (
                  <>
                    <input
                      type={searchType}
                      placeholder="search here..."
                      onChange={(e) => searchfilter(e.target.value)}
                    />
                  </>
                ) : searchType === "date" ? (
                  <>
                    <label htmlFor="searchText">From Date : </label>
                    <ReactDatePicker
                      className="dataForm"
                      placeholderText="Select Date"
                      dateFormat="yyyy/MM/dd"
                      onChange={(date) => {
                        setFromDate(date);
                        changeHandlerState();
                      }}
                      selected={fromDate}
                    />
                    {setToDate ? (
                      <>
                        <label htmlFor="searchText">To Date : </label>
                        <ReactDatePicker
                          className="dataForm"
                          placeholderText="Select Date"
                          dateFormat="yyyy/MM/dd"
                          onChange={(date) => {
                            setToDate(date);
                            changeHandlerState();
                          }}
                          minDate={fromDate}
                          selected={toDate}
                        />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            )}
            {otherFilters ? (
              <div className="searchFilter">{otherFilters}</div>
            ) : null}
            {type && (
              <div className="searchFilter">
                <label htmlFor="searchFilter">Filter : </label>
                <select
                  name=""
                  id="searchFilter"
                  onChange={(e) => setSearchfilter(e.target.value)}
                >
                  <option value="">All</option>
                  {optionData?.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {extraButton && (
              <button
                type="button"
                onClick={extraBtnHandler}
                className="addnewBtn"
              >
                {extraButton}
              </button>
            )}
            {(userDetail.id ? userDetail : user).Role?.canView == 1 && (
              <>
                {openHandler && (
                  <button
                    type="button"
                    onClick={openHandler}
                    className="addnewBtn"
                  >
                    {buttonTitle}
                  </button>
                )}
              </>
            )}
            {secondBtn && (
              <button type="button" onClick={secondBtn} className="addnewBtn">
                {secondbuttonTitle}
              </button>
            )}
          </div>
        </div>

        <div className="main-content-section">
          {withoutContainer ? (
            children
          ) : (
            <div className="container">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
