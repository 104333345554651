import dictionary from "../dictionary";
import methods from "../methods";

const { global } = dictionary;
const { get, post, patch, put, deleteData } = methods;

const GlobalService = {
  createInventoryCategoryService: (body) => {
    return post(global.createInventoryCategory(), body);
  },
  getInventoryCategoryService: (id) => {
    return get(global.getInventoryCategory(id));
  },
  editInventoryCategoryService: (data) => {
    return put(global.editInventoryCategory(data.id), data.body);
  },

  createSupplierService: (body) => {
    return post(global.supplier(), body);
  },
  getSupplierService: (body) => {
    return get(global.supplier());
  },
  getSupplierByPropertyIdService: (id) => {
    return get(global.supplierByPropertyId(id));
  },
  editSupplierService: (data) => {
    return put(global.editSupplier(data.id), data.body);
  },

  createInStockService: (body) => {
    return post(global.InStock(), body);
  },
  getInStockService: (id) => {
    return get(global.InStockByPropertyId(id));
  },
  editInStockService: (data) => {
    return put(global.editInStock(data.id), data.body);
  },

  createOutStockService: (body) => {
    return post(global.outStock(), body);
  },
  getOutStockService: (id) => {
    return get(global.getOutStockByPropertyId(id));
  },
  editOutStockService: (data) => {
    return put(global.outStock(data.id), data.body);
  },

  getInventoryItemByPropertyIdService: (id) => {
    return get(global.getInventoryItemByPropertyId(id));
  },
  addInventoryItemService: (data) => {
    return post(global.getInventoryItem(), data);
  },
  updateCheckInStatusService: (data) => {
    return patch(global.updateCheckInStatus(data.id), data.body);
  },
  getSingleBookingDetail: (id) => {
    return get(global.getSingleBookingDetail(id));
  },
  addGusetService: (body) => {
    return post(global.addGuset(), body);
  },

  getRoomByPropertyIdService: (data) => {
    return get(global.getRoomByPropertyId(data));
  },
  getRoomByIdService: (id) => {
    return get(global.getRoomById(id));
  },
  getBookingPropertyIdService: (data, query) => {
    return get(global.getBookingPropertyId(data, query));
  },
  getBookingReportFilter: (data) => {
    return post(global.getBookingsFilter(), data);
  },
  getAllBookingDetails: (query) => {
    return get(global.getAllBooking() + (query ?? ""));
  },
  addPaymentService: (body) => {
    return post(global.payment(), body);
  },
  createFocRequest: (data) => {
    return post(global.focRequest(), data);
  },

  getCustomerReviewPropertyIdService: (data) => {
    return get(global.getCustomerReviewById(data));
  },
  createCustomerReviewService: (body) => {
    return post(global.createCustomerReview(), body);
  },

  getCouponCodeIdService: (data) => {
    return get(global.getCouponCodeById(data));
  },
  getAllCoupons: () => {
    return get(global.createCouponCode());
  },
  getCouponsByUser: (id) => {
    return get(global.getCouponByUserId(id));
  },
  createCouponCodeService: (body) => {
    return post(global.createCouponCode(), body);
  },
  updateCouponCodeService: (data) => {
    return put(global.updateCouponCode(data.id), data.body);
  },
  deleteCouponCodeByIdService: (data) => {
    return deleteData(global.deleteCouponCodeById(data));
  },
  assignRoomService: (data) => {
    return post(global.assignRoom(), data);
  },
  applyCouponService: (data) => {
    return get(global.applyCoupon(data));
  },
  getCoupons: (id) => {
    return get(global.coupons(id));
  },

  getMenuCardByIdService: (data) => {
    return get(global.getMenuCardById(data));
  },
  createMenuCardService: (body) => {
    return post(global.createMenuCard(), body);
  },
  updateMenuCardService: (data) => {
    return put(global.updateMenuCard(data.id), data.body);
  },
  deleteMenuCardByIdService: (data) => {
    return deleteData(global.deleteMenuCardById(data));
  },

  // laundry provider
  getLaundryProviderByIdService: (data) => {
    return get(global.getLaundryProviderById(data));
  },
  createLaundryProviderService: (body) => {
    return post(global.createLaundryProvider(), body);
  },
  updateLaundryProviderService: (data) => {
    return put(global.updateLaundryProvider(data.id), data.body);
  },
  deleteLaundryProviderByIdService: (data) => {
    return deleteData(global.deleteLaundryProviderById(data));
  },
  getLaundryServiceResByIdService: (data) => {
    return get(global.getLaundryServiceResById(data));
  },

  // laundry service
  getLaundryServicesByIdService: (data) => {
    return get(global.getLaundryServiceById(data));
  },
  createLaundryServicesService: (body) => {
    return post(global.createLaundryService(), body);
  },
  updateLaundryServicesService: (data) => {
    return put(global.updateLaundryService(data.id), data.body);
  },
  deleteLaundryServicesByIdService: (data) => {
    return deleteData(global.deleteLaundryServiceById(data));
  },

  changeRoomStatusService: (body) => {
    return put(global.changeRoomStatus(), body);
  },
  createLaundryReqService: (body) => {
    return post(global.createLaundryReq(), body);
  },
  changeStatusLaunrdryService: (data) => {
    return put(global.changeStatusLaunrdry(data.id), data.body);
  },
  getMenuCardCategory: () => {
    return get(global.menuCardCategory());
  },
  deleteMenuCardCategory: (id) => {
    return deleteData(global.menuCardCategory() + "/" + id);
  },
  createMenuCardCategory: (data) => {
    return post(global.menuCardCategory(), data);
  },
  updateMenuCardCategory: (data) => {
    return put(global.menuCardCategory() + "/" + data.id, data.body);
  },
  getServiceTaxData: () => {
    return get(global.getServiceTax());
  },
  getPaymentDetailsData: (data) => {
    return post(global.getPaymentDetails(), data);
  },
  getFoodPaymentDetailsData: (params) => {
    return get(global.getFoodPaymentDetails(params));
  },
  getAllState: () => {
    return get(global.getAllStateUrl());
  },
  getAllCities: () => {
    return get(global.getAllCitiesUrl());
  },
  getAllLocality: () => {
    return get(global.getAllLocalityUrl());
  },
  createCity: (params) => {
    return post(global.getAllCitiesUrl(), params);
  },
  editCityData: (id, params) => {
    return deleteData(global.getAllCitiesUrl() + id, params);
  },
  getAllCitiesByState: (id) => {
    return get(global.getAllCitiesByStateUrl(id));
  },
  deleteCityData: (id) => {
    return deleteData(global.getAllCitiesUrl() + id);
  },
  editCity: (id, params) => {
    return put(global.getAllCitiesUrl() + id, params);
  },
  editLocality: (id, params) => {
    return put(global.getAllLocalityUrl() + id, params);
  },
  getLocalityByCity: (id) => {
    return get(global.localityByCity(id));
  },
  createLocality: (params) => {
    return post(global.getAllLocalityUrl(), params);
  },
  deleteLocality: (id) => {
    return deleteData(global.getAllLocalityUrl() + id);
  },
  getTax: () => {
    return get(global.getTaxUrl());
  },
  exportBookingHandler: (quaryParam) => {
    return get(global.exportBookingExle(quaryParam));
  },
};

export default GlobalService;
