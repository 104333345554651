import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";
import { Imagesdata } from "../../assets/images/imageData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contact() {
  const { FbIcon, InstaIcon, TwitterIcon, DribbleIcon, YoutubeIcon } =
    Imagesdata || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hotalList">
      <LandingHeader />
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      <section className="contentMains contactUs">
        <div className="container">
          <h1 className="text-center">Contact Us</h1>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 bg-white rounded p-2">
              <div className="row">
                <div className="col-6">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1779.593456684615!2d81.00506863859613!3d26.86580198688202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be2bef8e5f9d3%3A0xa0cd5cc1e323292c!2sDLF%20MyPad!5e0!3m2!1sen!2sin!4v1693913511068!5m2!1sen!2sin"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="col-6 information_contactPage">
                  <h1>Contact Information</h1>
                  {/* <h6>
                    Fill up the form and our team will get back to you within 24
                    hours.
                  </h6> */}
                  <ul>
                    <li>
                      <FontAwesomeIcon icon="fa-solid fa-phone" />
                      <span> +9190123 90125</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon="fa-solid fa-envelope" />
                      <a href="mailTo:contact@rrooms.in">contact@rrooms.in</a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon="fa-solid fa-location-pin" />
                      <span>
                        B2-1409, DLF My Pad, VIBHUTI KHAND GOMTI NAGAR, Gomti
                        Nagar, Lucknow, Lucknow, Uttar Pradesh, 226010
                      </span>
                    </li>
                  </ul>
                  <ul className="icons_contacts">
                    <li>
                      <a href="">
                        <img alt="" src={FbIcon} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img alt="" src={InstaIcon} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img alt="" src={TwitterIcon} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img alt="" src={DribbleIcon} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img alt="" src={YoutubeIcon} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FrontFooter />
    </div>
  );
}

export default Contact;
