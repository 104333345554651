import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";
import { Imagesdata } from "../../assets/images/imageData";

const CancellationRefundPolicy = () => {
  const { GooglePlay, ApplePlay, ScanCode } = Imagesdata || {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hotalList">
      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>RROOMS BOOKING CANCELLATION AND REFUND POLICY</h1>
          <h2>
            The below mentioned are the policies through which RROOMS will deal
            with any sort of CANCELLATION / REFUND/TERMS AND conditions for the
            bookings done.
          </h2>
          <ul>
            <li>FREE CANCELLATION only up to 1 hr before CHECK-IN time.</li>
            <li>
              RROOMS will not give any refund or compensation against confirmed
              booking canceled from the hotelier's end.
            </li>
            <li>
              RROOMS reserves the right to cancel the bookings due to any
              uncertainty.
            </li>
            <li>
              RROOMS is not liable for refund/compensation in case of any
              natural or manmade calamity.
            </li>
            <li>RROOMS is not liable for any refund in case of a NO SHOW.</li>
            <li>
              NO SHOW will be accounted for in the category of active booking.
            </li>
            <li>
              In case you avail of the Website's hotel reservation service and
              are, thereafter, not contactable on the contact details provided
              in this regard, then the hotel reservation selected by you will
              stand canceled and RROOMS would not be liable for any
              charges/expenses incurred by you, either directly or indirectly in
              this regard.
            </li>
            <li>
              The refund shall be credited to the same payment mode by which the
              payment was received.
            </li>
            <li>
              Any special offer being provided by RROOMS shall be governed by
              the specific terms & conditions of such special offer as displayed
              on the Website.
            </li>
            <li>
              Your reservation is guaranteed for arrival on the confirmed
              check-in date and time only.
            </li>
          </ul>
          <h2>PAY@HOTEL:</h2>
          <ul>
            <li>Pay at hotel is valid for selected hotels only.</li>
            <li>
              The remaining amount needs to be paid by the guest at the hotel.
              Guests might be asked to pay in cash if the credit card machine at
              the hotel is absent or dysfunctional.
            </li>
            <li>
              Offers/Discounts are not applicable on bookings made using the
              "Pay at Hotel" option.
            </li>
            <li>
              Some hotels might ask to pay the complete amount at the time of
              check-in; denial of paying the security deposit may lead to
              hoteliers not allowing check-in.
            </li>
            <li>
              Guest must pay full amount at the time of check-out; hotel's
              payment cannot be with held by customer in any case after
              completing stay.
            </li>
          </ul>
        </div>
      </section>

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default CancellationRefundPolicy;
