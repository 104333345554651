import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validation = Yup.object().shape({
  paymentMode: Yup.string().required("Please Select Payment Method*"),
  payNumber: Yup.string().required("This Field is Required*"),
  proof: Yup.mixed().test("file", "Please Select payment Proof", (value) => {
    if (value?.length > 0) {
      return value;
    }
    return false;
  }),
  date: Yup.string().required("Please select payment date"),
});

export default { resolver: yupResolver(validation) };
