import React, { useEffect, useMemo, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link, useNavigate } from "react-router-dom";
import {
  authenticationSeletor,
  loginSuccess,
} from "../../store/slices/authenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import services from "../../API/services";
import Moment from "react-moment";
import LandingHeader from "../../components/landingHeader";
import Slider from "react-slick";
import {
  Table,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Rating } from "@smastrom/react-rating";
import { useForm, Controller, useFieldArray } from "react-hook-form";

import "@smastrom/react-rating/style.css";
import { baseImgUrl, baseUrl } from "../../API/client/api";
import FrontFooter from "../../layout/footer/FrontFooter";
import { toast } from "react-toastify";
import { onLogout, showPrice } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validation from "./validation";
import { ErrorMessage } from "@hookform/error-message";
import { createLogs } from "../../store/slices/PropertyData";
import { InputField } from "../../components/InputField/InputField";
import moment from "moment";
import ReactGA from "react-ga4";

const Userprofile = () => {
  const { WalletIcon, popularSliderImg, UserlogoutIcon, AlertIcon, userImg } =
    Imagesdata || {};

  const settingsImgSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    adaptiveHeight: true,
  };

  const [roomNumbers, setRoomNumbers] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [orderAmt, setOrderAmt] = useState(0);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { userDetail } = useSelector(authenticationSeletor);
  const [bookedHotel, setBookedHotel] = useState([]);
  const [showBookings, setShowBookings] = useState([]);
  const [rating, setRating] = useState(0); // Initial value
  const [reviewBox, setReviewBox] = useState(0);
  const [cancelPopup, setCencelPopup] = useState(0);
  const [reasonVal, setReasonVal] = useState("");
  const [openFoodPopup, setOpenFoodPopup] = useState("");
  const [menuCards, setMenuCards] = useState([]);
  const [showInd, setShowInd] = useState([]);
  const [filterBar, setFilterBar] = useState(false);
  // const [foodCategory, setFoodCategory] = useState([]);
  // const [selectedFoods, setSelectedFoods] = useState([]);
  // const [btnClick, setBtnClick] = useState(0);
  const [filterFoodItems, setFilterFoodItems] = useState([]);
  const [qty, setQty] = useState([]);
  const [allFoodOrders, setAllFoodOrders] = useState([]);
  const [cancleModal, setCancleModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cancleData, setCancleData] = useState([]);
  const [referAmt, setReferAmt] = useState({});
  const [checkReview, setReviewCheck] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const [sortValue, setSortValue] = useState(0);
  const [oldNew, setOldNew] = useState(-1);
  const [imgFile, setImgFile] = useState(null);
  const [showUser, setShowUser] = useState({});
  const [userData, setUserData] = useState({
    name: showUser?.name ?? userDetail?.name,
    img: "",
    email: showUser?.email ?? userDetail?.email,
  });
  const [editSave, setEditSave] = useState(false);
  const [gstDetails, setGstDetails] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    ...validation,
    mode: "onSubmit",
  });

  const { fields, append, remove, update } = useFieldArray({
    name: "selectedFoodItems",
    control,
  });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "customer_profile" });
    getBookedHotel();
  }, []);

  const onSubmit = async (payload) => {
    if (!rating) {
      toast.error("Please select star to rating");
      return;
    }
    const realPayload = {
      user_id: payload.userId,
      bookingCode: payload.bookingCode,
      fromDate: moment(payload.fromDate).format("YYYY-MM-DD"),
      toDate: moment(payload.toDate).format("YYYY-MM-DD"),
      property_id: payload.propertyId,
      ratings: rating,
      reviews: payload.enterReview,
    };
    try {
      const res = await services.globalService.createCustomerReviewService(
        realPayload
      );
      if (res.status === 201) {
        setReviewBox(0);
        reset();
        setRating(0);
      }
    } catch (error) {}
  };

  const getBookedHotel = async () => {
    setLoading(true);
    try {
      const res = await services.property.getBookHotelByUser(userDetail.id);
      if (res.status === 200) {
        setBookedHotel(res?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const checkOutHandler = async (e, data) => {
    e.preventDefault();
    try {
      let arr = [];
      for (let i in checkReview) {
        arr.push(checkReview[i]);
      }
      const payload = {
        id: data.id,
        cancelledBy: `guest_${userDetail?.name}_${userDetail?.mobile}`,
        body: {
          bookingStatus: 4,
          reason: arr.toString() + " " + reasonVal,
        },
      };
      // checkReview

      const res = await services.globalService.updateCheckInStatusService(
        payload
      );
      if (res.status === 200) {
        const params = {
          bookingId: data.id,
          action: `Booking Cancelled`,
          actionBy: userDetail?.name,
          userType: "Guest",
          cancelBy: "guest",
        };
        dispatch(createLogs(params));
        setCencelPopup(0);
        getBookedHotel();
        setReasonVal("");
      }
    } catch (error) {}
  };

  const setCheckReview = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setReviewCheck({ ...checkReview, [name]: value });
  };

  const setShowSection = (ind) => {
    if (showInd.includes(ind)) {
      setShowInd((i) => i.filter((i) => i !== ind));
    } else {
      setShowInd([ind]);
    }
  };
  useEffect(() => {
    // getRecentBooking();
    window.scrollTo(0, 0);
    //  let c = document.getElementById("myCanvas");
    //  let ctx = c.getContext("2d");
    //  let img = document.getElementById("preview");
    //  ctx.drawImage(img, 10, 10);
    //  alert(c.toDataURL());
  }, []);

  // adding food items to food order modal
  const handleAddItem = async (item) => {
    const tempPrice = item?.finalPrice || item?.price;
    if (fields.some((i) => i.itemId == item.id)) {
      quantityHanlder(
        fields.find((i) => i.itemId == item.id),
        0,
        true
      );
    } else {
      append({
        itemId: +item.id,
        name: item.name,
        price: tempPrice,
        qty: 1,
        amountBeforeTax: item?.amountBeforeTax,
        taxAmount: item?.taxAmount * 1,
        propertyId: item?.propertyId,
        totalAmount: tempPrice * 1,
      });
    }
  };

  // remove food order in  food order modal
  const deleteItem = (index) => {
    if (window.confirm("Are you sure to delete this item?")) {
      remove(index);
    }
  };
  // quantity handler for food order
  const quantityHanlder = async (item, index, isIncrement) => {
    let temp = item;
    if (isIncrement) {
      temp.qty++;
      temp.totalAmount = temp.price * temp.qty;
    } else {
      if (item.qty > 1) {
        temp.qty--;
        temp.totalAmount = temp.price * temp.qty;
      } else {
        deleteItem(index);
        return;
      }
    }

    update(index, temp);
  };

  const getUserData = async () => {
    try {
      const res = await services.auth.getUserDetails(userDetail?.id);
      if (res.status === 200) {
        setShowUser(res.data.data);
        setUserData({
          name: res?.data?.data?.name,
          img: "",
          email: res?.data?.data?.email,
        });

        setImgFile(res?.data?.data?.profileImage);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getUserData();
  }, []);

  const checkRatingDone = async (data) => {
    // if (data.id == reviewBox) {
    //   setReviewBox(0);
    // } else {
    //   setReviewBox(data.id);
    // }
    // reset();
    // setRating(0);
    // return;
    try {
      const res = await fetch(
        `${baseUrl}rrooms-property/ratings?bookingCode=${data?.bookingCode}`
      );
      const rating = await res.json();
      if (rating?.data?.find((i) => i.userId == userDetail?.id)?.id) {
        toast.warning("You have Already Rated this Property*", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      } else {
        setReviewBox(data.id);
        reset();
        setRating(0);
      }
    } catch (error) {}
  };

  const editHandler = async (data) => {
    // setBtnClick(0);
    const foodResponse = await services.property.getFoodItemsProperty(
      `?property_id=${data?.propertyId}`
    );
    if (foodResponse.status === 200) {
      setFoodItems(foodResponse.data.data);
      setFilterFoodItems(foodResponse.data.data);
    }
    setSelectedData(data);
    setShow(true);
    const rooms = data.assignRoomNo?.split(",");
    setRoomNumbers(rooms);
    if (rooms) {
      setValue("roomNo", rooms[0]);
    }
  };

  const getMenuCards = async (id) => {
    try {
      const res = await services.globalService.getMenuCardByIdService(id);
      if (res.status === 200) {
        setMenuCards(res.data.data);
        setOpenFoodPopup(true);
      }
    } catch (error) {}
  };

  // const changeHandler = (e, data) => {
  //   if (e.target.checked) {
  //     setSelectedFoods([
  //       ...selectedFoods,
  //       {
  //         id: Number(e.target.value),
  //         price: data.price,
  //       },
  //     ]);
  //     setQty([...qty, { id: Number(e.target.value), qtyValue: 1 }]);
  //   } else {
  //     const resultfilter = selectedFoods.filter((d, index) => {
  //       return d.id !== Number(e.target.value);
  //     });
  //     setQty(qty.filter((i) => i.id !== data.id));
  //     setSelectedFoods(resultfilter);
  //   }
  // };
  // const foodItemsFilter = (ind, item) => {
  //   setBtnClick(ind);
  //   setFilterFoodItems(foodItems.filter((i) => i.categoryId === item.id));
  // };

  const getTotalFoodBooking = async () => {
    const res = await services.property.getFoodOrders();
    if (res.status === 200) {
      const filterData = res.data.data.filter(
        (item) => item.userId === userDetail.id
      );
      setAllFoodOrders(filterData);
    }
  };

  useEffect(() => {
    getTotalFoodBooking();
  }, []);

  const onSubmitfood = async (payload) => {
    if (payload.selectedFoodItems.length < 1) {
      toast.error("Please Select Food Items*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }
    const items = payload.selectedFoodItems.map((item) => {
      const temp = {
        ...item,
        id: item.itemId,
      };
      delete temp.itemId;
      return temp;
    });

    const realPayload = {
      user_id: selectedData?.userId,
      booking_id: selectedData.id,
      room_number: payload.roomNo,
      order_amount: orderAmt,
      order_items: items,
      created_by: 0,
      otherGuestName: userDetail.name,
    };
    try {
      const res = await services.property.createFoodOrders(realPayload);
      if (res.status === 200) {
        getBookedHotel();
        getTotalFoodBooking();
        hideFoodBookingModal();
      }
    } catch (error) {}
  };

  const hideFoodBookingModal = () => {
    setShow(false);
    reset();
    setOrderAmt(0);
    remove([]);
  };

  const filterFoodItemsToShow = (query) => {
    let text = query?.toLowerCase();
    const results = foodItems.filter((item) => {
      if (text == "") return foodItems;

      return (item.itemCode + item.name).toLowerCase().includes(text);
    });
    setFilterFoodItems(results);
  };

  const { searchItems, selectedFoodItems } = watch();

  useEffect(() => {
    if (searchItems) {
      filterFoodItemsToShow(searchItems);
    }
  }, [searchItems]);
  useEffect(() => {
    if (selectedFoodItems) {
      setOrderAmt(
        selectedFoodItems.reduce((acc, arr) => acc + arr.price * arr.qty, 0)
      );
    }
  }, [selectedFoodItems]);

  const cancleFoodOrder = async (item) => {
    try {
      if (!window.confirm("Are You Sure Want to Cancle Order")) {
        return;
      }
      const realPayload = {
        order_status: 4,
      };
      const res = await services.property.updateFoodOrderStatus(
        realPayload,
        item.id
      );
      if (res.status == 200) {
        setCancleData(cancleData.filter((i) => i.id === item.id));
        getBookedHotel();
        getTotalFoodBooking();
        toast.success("Food Order Cancel Successfull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  const cancleSetup = (data) => {
    setCancleData(data);
    setCancleModal(true);
  };

  const paymentFromUser = async (item) => {
    try {
      const res = await services.property.paymentGateway(item?.id);
      if (res.status === 200) {
        window.open(
          res.data.data.data.instrumentResponse.redirectInfo.url,
          "_blank"
        );
      }
    } catch (error) {}
  };

  const referalHandler = async () => {
    try {
      const res = await services.property.getwalletMoney(userDetail?.id);
      if (res.status === 200) {
        setReferAmt(res.data.data[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    referalHandler();
  }, []);
  const saveEditHandler = async (payload) => {
    if (editSave) {
      const { address, company, gst } = payload;
      try {
        const formData = new FormData();
        formData.append("name", userData.name ?? "");
        formData.append("email", userData.email ?? "");
        formData.append("profileImage", userData.img);
        formData.append("address", address ?? "");
        formData.append("company", company ?? "");
        formData.append("gst", gst ?? "");
        const body = {
          id: userDetail?.id,
          data: formData,
        };
        const res = await services.auth.updateUser(body);
        if (res.status === 200) {
          dispatch(
            loginSuccess({
              data: {
                ...userDetail,
                logintype: "normalUser",
                name: userData.name,
                email: userData.email,
                gst,
                company,
                address,
              },
            })
          );
          getUserData();
          reset();
        }
      } catch (error) {}
    }
    setEditSave(!editSave);
    setGstDetails(!editSave);
  };

  useEffect(() => {
    if (sortValue == "") {
      setShowBookings(bookedHotel);
    } else {
      const filterBookings = bookedHotel?.filter(
        (i) => i.bookingStatus == sortValue
      );
      setShowBookings(filterBookings);
    }
  }, [sortValue, bookedHotel]);
  useMemo(() => {
    let filterVal = [];
    if (oldNew == 1) {
      filterVal = bookedHotel.sort(function (a, b) {
        var keyA = new Date(a.createdAt),
          keyB = new Date(b.createdAt);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
    } else if (oldNew == 0) {
      filterVal = bookedHotel.sort(function (a, b) {
        var keyA = new Date(a.createdAt),
          keyB = new Date(b.createdAt);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
    filterVal = bookedHotel;
    setShowBookings(filterVal);
  }, [oldNew]);

  return (
    <div className="hotalList">
      <LandingHeader />
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>
      <div className="userProfile">
        <div className="container flex">
          <div
            className={`leftSidebar col-left ${filterBar ? "showFilter" : ""}`}
          >
            <div className="wrap">
              <div className="profile-inner user_Sidebar">
                <Button
                  type="button"
                  variant="dark"
                  className={`btn-sm rounded-circle user_Sidebar_closeBtn 
                  `}
                  onClick={() => setFilterBar(false)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-x" />
                </Button>
                <button
                  type="button"
                  className={`editBtn ${editSave ? "bg-primary" : "bg-dark"}`}
                  onClick={handleSubmit(saveEditHandler)}
                >
                  {editSave ? (
                    <>
                      <i
                        className="bi bi-floppy"
                        style={{ fontSize: "1rem", marginRight: 6 }}
                      ></i>
                      Save
                    </>
                  ) : (
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen-to-square"
                      size="lg"
                    />
                  )}
                </button>
                <figure className={editSave && "active"}>
                  <label htmlFor="userImg">
                    <img
                      src={imgFile ? `${baseImgUrl}${imgFile}` : userImg}
                      alt=""
                    />
                  </label>
                  <input
                    hidden
                    type="file"
                    id="userImg"
                    disabled={editSave ? false : true}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setImgFile(URL.createObjectURL(e.target.files[0]));
                        setUserData({ ...userData, img: e.target.files[0] });
                      }
                    }}
                    accept="img/png"
                  />
                  <i className="bi bi-upload"></i>
                  <span className="active"></span>
                </figure>
                <div className="flex justify-content-center">
                  <input
                    onChange={(e) =>
                      setUserData({ ...userData, name: e.target.value })
                    }
                    type="text"
                    disabled={editSave ? false : true}
                    value={userData.name ?? "Your Name"}
                    className={editSave ? "activeInput" : ""}
                  />
                </div>
                <p className="customerId">
                  Referral Code : <span>{userDetail?.referralCode}</span>
                </p>
                <div className="list">
                  <input
                    type="number"
                    disabled
                    value={userDetail ? userDetail.mobile : ""}
                  />
                  <p>Phone Number</p>
                  <div className="tick">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="list">
                  <input
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                    type="email"
                    className={editSave ? "activeInput" : ""}
                    disabled={editSave ? false : true}
                    value={userData.email}
                  />
                  <p>Email address</p>
                  <div className="tick">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                </div>

                <button
                  className="addGstButton"
                  onClick={() => {
                    setGstDetails(!gstDetails);
                    setEditSave(true);
                  }}
                >
                  {gstDetails ? "hide" : "Add"} Gst Details
                </button>
                <Row
                  className="gstDetailsAdd"
                  style={{
                    display: gstDetails ? "block" : "none",
                  }}
                >
                  <Col xs={12} className="form-group">
                    <InputField
                      htmlFor="company"
                      type="text"
                      autoFocus={true}
                      placeholder="Enter Company Name"
                      field={"company"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.company?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"company"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} className="form-group">
                    <InputField
                      htmlFor="address"
                      type="text"
                      autoFocus={true}
                      placeholder="Enter Company Address"
                      field={"address"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.address?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"address"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} className="form-group">
                    <InputField
                      htmlFor="gst"
                      type="text"
                      autoFocus={true}
                      placeholder="Enter Company Details"
                      field={"gst"}
                      register={register}
                      errors={errors}
                    />
                    {errors?.gst?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"gst"} />
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="wallet align-items-center">
                <div className="wraps">
                  <h4>
                    <img alt="" src={WalletIcon} /> RRooms Wallet
                  </h4>
                  <p>₹ {referAmt?.balance?.toFixed(2) ?? "0.00"}</p>
                </div>
              </div>

              {userDetail?.mobile && (
                <div className="flex bottom">
                  <button
                    className="logoutButton"
                    onClick={() => onLogout(navigate)}
                  >
                    Logout
                    <img alt="" src={UserlogoutIcon} />
                  </button>
                </div>
              )}
              <div className="bottom flex">
                <form>
                  <div className="form_group flex">
                    <input
                      type="checkbox"
                      id="deactivate"
                      name="deactivate"
                      value="deactivate"
                    />
                    <label for="deactivate">Account Deactivation</label>
                  </div>
                </form>
                <a href="" className="dlt-btn">
                  Delete account
                </a>
              </div>
            </div>
          </div>
          <div className="col-right">
            <div className="heading flex">
              <h3>
                {loading ? (
                  <>
                    <FontAwesomeIcon icon="fa-solid fa-spinner" spin />{" "}
                    <span>Please wait, while loading your bookings..</span>
                  </>
                ) : (
                  `Booking History`
                )}
              </h3>
              <div className="sort-fillter flex gap-2 align-items-center justify-content-end">
                <select
                  id="sortType"
                  name="sort"
                  onChange={(e) => setOldNew(e.target.value)}
                >
                  <option value="">Sort By</option>
                  <option value="0">Old To New</option>
                  <option value="1">New To Old</option>
                </select>
                <select
                  id="sortType"
                  name="sort"
                  onChange={(e) => setSortValue(e.target.value)}
                >
                  <option value="" selected={sortValue == "" ? true : false}>
                    {sortValue == "" ? "Select " : "Clear "}Filter
                  </option>
                  <option value="1" selected={sortValue == 1 ? true : false}>
                    Upcoming
                  </option>
                  <option value="4" selected={sortValue == 4 ? true : false}>
                    Cancelled
                  </option>
                  <option value="3" selected={sortValue == 3 ? true : false}>
                    Complete
                  </option>
                </select>
                <Button
                  className="btn-sm flex justify-content-between"
                  id="filterBtnVis"
                  onClick={() => setFilterBar(!filterBar)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-user" />
                </Button>
              </div>
            </div>
            {loading ? (
              new Array(5)
                .fill()
                .map((_, ind) => (
                  <div
                    className="bookingList flex position-relative shimmerEffect w-100 mb-2"
                    style={{ height: 200 }}
                    key={ind}
                  ></div>
                ))
            ) : (
              <>
                {showBookings.map((data, key) => {
                  return (
                    <div className="bookingList flex" key={key}>
                      {data?.PropertyMaster ? (
                        <Link to={`/hotel-details/${data?.PropertyMaster?.id}`}>
                          <figure>
                            <img
                              src={
                                data?.PropertyMaster?.PropertyImages?.length
                                  ? `${baseImgUrl}${data?.PropertyMaster?.PropertyImages[0].image}`
                                  : popularSliderImg
                              }
                              alt=""
                            />
                          </figure>
                        </Link>
                      ) : (
                        <figure>
                          <img alt="" src={popularSliderImg} />
                        </figure>
                      )}

                      <div className="content flex">
                        <div className="left-content">
                          <div className="flex justify-content-between">
                            <h2>{data.PropertyMaster?.name}</h2>
                            <div className="showfoodButtons">
                              <Button
                                className="btnBlue mr-2 btn-sm btn btn-success"
                                onClick={() => setShowSection(key)}
                              >
                                {showInd.find((i) => i === key) === key
                                  ? "Less Details"
                                  : "Show Details"}
                              </Button>
                              {data.bookingStatus === 2 && (
                                <Button
                                  variant="success"
                                  className="btnBlue mr-2 btn-sm"
                                  type="button"
                                  onClick={() => editHandler(data)}
                                >
                                  Order Food
                                </Button>
                              )}
                              <Button
                                variant="success"
                                className="btnBlue mr-2 btn-sm"
                                type="button"
                                onClick={() => getMenuCards(data?.propertyId)}
                              >
                                Menu Card
                              </Button>
                              {allFoodOrders.filter(
                                (i) =>
                                  i.bookingId === data.id && i.orderStatus === 0
                              )?.length && data.bookingStatus == 2 ? (
                                <Button
                                  variant="danger"
                                  className="btn-sm mr-2"
                                  type="button"
                                  onClick={() =>
                                    cancleSetup(
                                      allFoodOrders.filter(
                                        (i) =>
                                          i.bookingId === data.id &&
                                          i.orderStatus === 0
                                      )
                                    )
                                  }
                                >
                                  cancle Food
                                </Button>
                              ) : null}
                            </div>
                          </div>
                          <p className="fw-bold">
                            <Moment format="DD MMM YYYY">
                              {data.fromDate}
                            </Moment>{" "}
                            -{" "}
                            <Moment format="DD MMM YYYY">{data.toDate}</Moment>
                          </p>
                          <p>
                            {data.adults} Adults | {data.noOfRooms} Room
                            <br />
                            &nbsp;{data?.RroomCategory?.name}
                          </p>
                          {showInd.find((i) => i === key) === key && (
                            <div className="flex showDetails">
                              <div>
                                <p className="text-dark fw-bold">
                                  Other Details:
                                </p>
                                <div className="flex gap-2">
                                  <p>Assign Room :</p>{" "}
                                  <p> {data.assignRoomNo ?? "N/A"}</p>
                                </div>
                                <div className="flex gap-2">
                                  <p>Booking ID :</p>{" "}
                                  <p> {data.bookingCode ?? "N/A"}</p>
                                </div>
                                <div className="flex gap-2">
                                  <p>Booking Amount :</p>{" "}
                                  <p> {data.bookingAmout ?? "N/A"}</p>
                                </div>
                                <div className="flex gap-2">
                                  <p>Booked By : </p>{" "}
                                  <p>{data.User?.name ?? "N/A"}</p>
                                </div>
                                <div className="flex gap-2">
                                  <p>Email : </p>{" "}
                                  <p>{data?.User?.email ?? "N/A"}</p>
                                </div>
                                <div className="flex gap-2">
                                  <p>Mobile : </p>{" "}
                                  <p>{data?.User?.mobile ?? "N/A"}</p>
                                </div>
                              </div>
                              <div>
                                <p className="text-dark fw-bold">
                                  Guest Details:
                                </p>
                                {data?.GuestDetails?.length > 0 ? (
                                  data?.GuestDetails?.map((guest, ind) => (
                                    <React.Fragment key={ind}>
                                      <div className="flex gap-2">
                                        <p>Name : </p>{" "}
                                        <p>{guest?.name ?? "N/A"}</p>
                                      </div>
                                      <div className="flex gap-2">
                                        <p>Gender : </p>{" "}
                                        <p>{guest?.gender ?? "N/A"}</p>
                                      </div>
                                      <div className="flex gap-2">
                                        <p>Document : </p>{" "}
                                        <p>{guest?.document_type ?? "N/A"}</p>
                                      </div>
                                      <div className="flex gap-2">
                                        <p>Document Number :</p>{" "}
                                        <p>
                                          {" "}
                                          {guest?.document_number ?? "N/A"}
                                        </p>
                                      </div>
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <p>Guest Not Available !</p>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="flex align-items-center justify-content-between pr-2">
                            <div className="d-flex align-items-start gap-1">
                              {/* <img alt=""src={mapIcon} alt="" /> */}
                              <div>
                                <p className="address text-dark mb-1">
                                  {data.PropertyMaster?.address}
                                </p>
                                <Link
                                  to={`https://maps.google.com/maps?q=${data?.PropertyMaster?.latitude},${data?.PropertyMaster?.longitude}&hl=es;z=14&amp;output=embed`}
                                  target="_deepak"
                                  className=" mb-1"
                                >
                                  View on Map
                                </Link>
                              </div>
                            </div>
                            {/* {data.PaymentStatus !== 1 && data.bookingStatus < 2 && (
                          <Button
                            className="btnBlue btn-sm mb-2"
                            onClick={() => paymentFromUser(data)}
                          >
                            Pay Now
                          </Button>
                        )} */}
                            {data.paymentMode == 1 ? (
                              <Button
                                className="btnBlue btn-sm mb-2"
                                onClick={() => paymentFromUser(data)}
                              >
                                Pay Now
                              </Button>
                            ) : null}
                          </div>
                        </div>
                        <div className="right-content">
                          <div className="bookId">
                            <p>Booking ID: {data.bookingCode}</p>
                          </div>
                          <div className="amount">
                            <div className="textLeft">
                              <p>Booking Status</p>
                              <h6
                                className={
                                  data.bookingStatus == 1
                                    ? "text-success"
                                    : data.bookingStatus == 2
                                    ? "text-warning"
                                    : data.bookingStatus == 3
                                    ? "text-secondary"
                                    : data.bookingStatus == 8
                                    ? "text-warning"
                                    : "text-danger"
                                }
                              >
                                {data.bookingStatus == 1
                                  ? "Confirm Booking"
                                  : data.bookingStatus == 2
                                  ? "Checked In"
                                  : data.bookingStatus == 3
                                  ? "Checked Out"
                                  : data.bookingStatus == 4
                                  ? "Cancelled"
                                  : data.bookingStatus == 8
                                  ? "Pending"
                                  : data.bookingStatus == 7
                                  ? "Rejected"
                                  : "No Status"}
                              </h6>
                            </div>
                            <div className="bothBtns">
                              {data.bookingStatus == 3 && (
                                <Button
                                  variant="primary"
                                  className="btnBlue btn-sm"
                                  type="button"
                                  onClick={() => checkRatingDone(data)}
                                >
                                  Write a review
                                </Button>
                              )}

                              {data.bookingStatus == 1 && (
                                <Button
                                  variant="primary"
                                  className="btnRed"
                                  type="button"
                                  onClick={() => {
                                    if (cancelPopup) {
                                      setCencelPopup(0);
                                    } else {
                                      setCencelPopup(data.id);
                                    }
                                    setReasonVal("");
                                  }}
                                >
                                  Cancel Booking
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {data.id == reviewBox ? (
                        <form
                          className="ratingReview"
                          onSubmit={handleSubmit((dataset) =>
                            onSubmit({ ...dataset, ...data })
                          )}
                        >
                          <Container>
                            <Row>
                              <Col xs={12} className="form-group">
                                <Rating
                                  style={{ maxWidth: 140 }}
                                  value={rating}
                                  onChange={setRating}
                                />
                              </Col>
                              <Col xs={12} className="form-group">
                                <textarea
                                  {...register("enterReview")}
                                  placeholder={"Enter review here"}
                                  className="w-100 p-2"
                                ></textarea>
                              </Col>
                              <Col
                                xs={12}
                                className="form-group flex justify-content-between"
                              >
                                <Button
                                  variant="primary"
                                  className="btnBlue"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </form>
                      ) : null}

                      {data.id == cancelPopup && (
                        <form
                          className="cancelReason"
                          onSubmit={(e) => checkOutHandler(e, data)}
                        >
                          <Container>
                            <Row>
                              <Col
                                xs={8}
                                className="form-group flex justify-content-between"
                              >
                                <div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="first"
                                      value="Found a better price"
                                      className="mr-2"
                                      id="source1"
                                      onChange={(e) => setCheckReview(e)}
                                    />
                                    <label htmlFor="source1">
                                      Found a better price
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="second"
                                      value="Facing an issue at the property"
                                      className="mr-2"
                                      id="source2"
                                      onChange={(e) => setCheckReview(e)}
                                    />
                                    <label htmlFor="source2">
                                      Facing an issue at the property
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="third"
                                      value="property detail didn't match"
                                      className="mr-2"
                                      id="source3"
                                      onChange={(e) => setCheckReview(e)}
                                    />
                                    <label htmlFor="source3">
                                      property detail didn't match
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="fourth"
                                      value="property asked for additional money"
                                      className="mr-2"
                                      id="source4"
                                      onChange={(e) => setCheckReview(e)}
                                    />
                                    <label htmlFor="source4">
                                      property asked for additional money
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="fifth"
                                      value="Had a different issue"
                                      className="mr-2"
                                      id="source5"
                                      onChange={(e) => setCheckReview(e)}
                                    />
                                    <label htmlFor="source5">
                                      Had a different issue
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="sixth"
                                      value="Don't need this stay"
                                      className="mr-2"
                                      id="source6"
                                      onChange={(e) => setCheckReview(e)}
                                    />
                                    <label htmlFor="source6">
                                      Don't need this stay
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} className="form-group">
                                <textarea
                                  // {...register("enterReason")}
                                  placeholder={"Enter Reason here"}
                                  className="w-100 p-2"
                                  name="enterReason"
                                  value={reasonVal}
                                  onChange={(e) => setReasonVal(e.target.value)}
                                ></textarea>
                              </Col>
                              <Col
                                xs={12}
                                className="form-group flex justify-content-between"
                              >
                                <Button
                                  variant="primary"
                                  className="btnBlue"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </form>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      {/* popup start here */}
      <div className="popup-second">
        <div className="wraps">
          <div className="closeBtn">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <h2>Transfer wallet money</h2>
          <form>
            <div className="form_group">
              <input type="text" placeholder="Cosutomer id" />
            </div>
            <div className="form_group">
              <input type="text" placeholder="Mobile Number" />
            </div>
            <div className="form_group">
              <input type="text" placeholder="₹ Transfer amount" />
            </div>
            <div className="form_group">
              <button type="submit" className="btnBlue">
                Transfer
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* popup end here */}

      <FrontFooter />
      <Modal
        show={openFoodPopup}
        onHide={() => {
          setOpenFoodPopup(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkInModal menuCardShowFull"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="color-black"
          >
            Menu Card
          </Modal.Title>
          <button
            className="closeButton"
            onClick={() => setOpenFoodPopup(false)}
          >
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="listCard">
            <div className="imageslider flex justify-content-center">
              <div className="width90">
                <Slider {...settingsImgSlider}>
                  {menuCards?.map((item, ind) => {
                    const { menuCard } = item;
                    return (
                      <div key={ind}>
                        <figure className="sliderImg">
                          <img
                            src={`${baseImgUrl}${menuCard}`}
                            alt="menucard"
                          />
                        </figure>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={hideFoodBookingModal}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm FoodOrders"
            onSubmit={handleSubmit(onSubmitfood)}
          >
            <Container>
              <Row>
                <Col xs={6}>
                  <Col xs={12} className="form-group">
                    <label>Room Number</label>
                    <Controller
                      control={control}
                      name="roomNo"
                      rules={{ required: "Please Select Room Number*" }}
                      render={({ field: { onChange, value } }) => (
                        <select onChange={onChange} value={value}>
                          <option selected value="" disabled>
                            Select Room
                          </option>
                          {roomNumbers?.map((item, key) => (
                            <option value={item} key={key}>
                              {item}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.roomNo?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"roomNo"} />
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={12}>
                    <label>Food Items</label>
                    <table className="table-bordered table-hover w-100 text-center common-table">
                      <thead>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total Price</th>
                      </thead>
                      <tbody>
                        {fields.map((item, ind) => (
                          <tr key={ind}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                            <td>
                              <div className="quantityHandler">
                                <button
                                  type="button"
                                  onClick={() =>
                                    quantityHanlder(item, ind, false)
                                  }
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  value={item.qty}
                                  readOnly
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    quantityHanlder(item, ind, true)
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="text-center">
                              {item.totalAmount}
                              <button
                                type="button"
                                className="btn text-danger p-0 ml-2"
                                onClick={() => deleteItem(ind)}
                              >
                                <FontAwesomeIcon
                                  size="lg"
                                  icon="fa-solid fa-trash-can"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {errors?.foodItems?.message && (
                      <div className="error-text">
                        <img src={AlertIcon} alt="error-icons" />
                        <span>
                          <ErrorMessage errors={errors} name={`foodItems`} />
                        </span>
                      </div>
                    )}
                  </Col>
                </Col>
                <Col xs={6}>
                  <Row>
                    {/* <Col xs={12} className="orderFoodCategory">
                      {foodCategory.map((item, ind) => (
                        <button
                          key={ind}
                          type="button"
                          className={`foodCategoryBtn text-uppercase ${
                            btnClick == ind ? "active" : ""
                          }`}
                          onClick={() => foodItemsFilter(ind, item)}
                        >
                          {item.name} &nbsp;(
                          {
                            foodItems.filter((i) => i.categoryId === item.id)
                              ?.length
                          }
                          )
                        </button>
                      ))}
                    </Col> */}
                    <Col
                      xs={12}
                      style={{ padding: "0 0rem 0 1rem", height: "80vh" }}
                      className="border mb-2 shadow rounded overflow-hidden"
                    >
                      <div className="flex justify-content-between align-items-center p-2">
                        <h5 className="text-center text-black">Food Items</h5>
                        <div className="form-group">
                          <InputField
                            type="search"
                            placeholder="Food name/code"
                            field="searchItems"
                            errors={errors}
                            register={register}
                            customClass={"p-1 m-0 bg-white"}
                          />
                        </div>
                      </div>
                      <div
                        className="foodItemsShow flex row-gap-3 column-gap-2 overflow-auto h-100"
                        style={{ padding: "1rem .5rem 5rem .5rem" }}
                      >
                        {filterFoodItems.length > 0 ? (
                          filterFoodItems.map((item) => {
                            return (
                              <div
                                style={{
                                  cursor: "pointer",
                                  boxShadow:
                                    fields.some((i) => i.itemId === item.id) &&
                                    "3px 3px 4px rgba(255, 180, 0, 1), -3px -3px 4px rgba(255, 180, 0, 1)",
                                }}
                                className="rounded-md px-4 py-1 border border-black"
                                key={item.id}
                                onClick={() => handleAddItem(item)}
                              >
                                <div className="item-name">
                                  <h4>{item.name}</h4>
                                  <p className="m-0">
                                    {showPrice(item.finalPrice)}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="flex flex-column align-center justify-content-center full-width text-black w-100"
                            style={{ height: "10rem" }}
                          >
                            <FontAwesomeIcon
                              className="mb-2"
                              icon="fa-solid fa-ban"
                              style={{ width: "40px", height: "40px" }}
                              beat
                            />
                            <span>Data not available</span>
                          </div>
                        )}
                      </div>
                    </Col>
                    {/* <Col>
                      <div className="itemsCategory mt-3">
                        {filterFoodItems?.length > 0 ? (
                          <ul className="flex">
                            {filterFoodItems?.map((c, i) => (
                              <li key={i}>
                                <input
                                  id={`amn${i}`}
                                  type="checkbox"
                                  value={c.id}
                                  className="d-none"
                                  checked={
                                    selectedFoods.find((i) => i.id === c.id)
                                      ?.id == c.id
                                  }
                                  onChange={(e) => changeHandler(e, c)}
                                />
                                <label
                                  htmlFor={`amn${i}`}
                                  className={
                                    selectedFoods.find((i) => i.id === c.id)
                                      ?.id == c.id
                                      ? `activeLable w-75`
                                      : "w-100"
                                  }
                                >
                                  <span className="text-uppercase">
                                    {c.name}
                                  </span>
                                  <span>&#8377;{c.price.toFixed(2)}</span>
                                </label>
                                {selectedFoods.find((i) => i.id === c.id)?.id ==
                                c.id ? (
                                  <div className="w-25 incDec">
                                    <button
                                      type="button"
                                      onClick={() => quatitySetup(c.id, false)}
                                    >
                                      -
                                    </button>
                                    <input
                                      type="number"
                                      value={
                                        qty.find((i) => i.id === c.id)?.qtyValue
                                      }
                                      name="qty"
                                      readOnly
                                      disabled
                                    />
                                    <button
                                      type="button"
                                      onClick={() => quatitySetup(c.id, true)}
                                    >
                                      +
                                    </button>
                                  </div>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>Food Items not Available !</p>
                        )}
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-2 align-center"
                >
                  <h6 className="d-flex flex-column">
                    <span> Grand Total -{showPrice(orderAmt)}</span>
                    <span style={{ fontSize: ".75rem", color: "#666" }}>
                      Including GST
                    </span>
                  </h6>
                </Col>
                <Col
                  xs={12}
                  className="form-group flex justify-content-end mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={cancleModal} onHide={() => setCancleModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Food Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="common-table table-bordered">
            <tr>
              <th>Booking Code</th>
              <th>Amount</th>
              <th>Items</th>
              <th>Action</th>
            </tr>
            <tbody>
              {cancleData?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.BookingHotel?.bookingCode}</td>
                    <td>{item?.orderAmount}</td>
                    <td>
                      {item?.orderItems?.map((data, key) => (
                        <span key={key}>
                          {data.name}-{data.price},&nbsp;
                        </span>
                      ))}
                    </td>
                    <td className="text-center">
                      <Button
                        variant="danger"
                        className="btn-sm"
                        type="button"
                        onClick={() => cancleFoodOrder(item)}
                      >
                        cancle
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Userprofile;
