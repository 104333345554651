import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  expiryDate: Yup.string().required("Please Enter Expiry Date*"),
  startDate: Yup.string().required("Please Enter Start Date*"),
  bookingFromDate: Yup.string().required("Please Enter Booking From Date*"),
  bookingToDate: Yup.string().required("Please Enter Booking To Date*"),
  couponTitle: Yup.string().required("Please Enter Coupon Title*"),
  couponCode: Yup.string().required("Please Enter Coupon Code*"),
  discountAmount: Yup.string()
    .required("Please Enter Coupon Amount*")
    .matches(
      /^[1-9]\d?$|^100$/,
      "Coupon Amount Should be a Number & between 1 - 100*"
    ),
});

export default { resolver: yupResolver(validation) };
