import { Row, Table } from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import Layout from "../../layout";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { revenueReportService } from "../../API/services/newServices";
import { sendPreviousMonth } from "utils/utils";
import { baseUrl } from "API/client/api";
import urls from "../../API/dictionary/PropertyDictionary";
import ReactGA from "react-ga4";

function RevenueReport() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "revenue_report" });
  }, []);
  const { userDetail } = useSelector(authenticationSeletor);
  const tableRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(new Date());
  // const [firstTime, setFirstTime] = useState(true);
  const [searchSelectVal, setSearchSelectVal] = useState({
    id: userDetail?.propertyId,
  });

  const getDataHandler = async (endDate, prt) => {
    const res = await revenueReportService({
      id: prt,
      date: endDate,
    });
    if (res) {
      setDate(date);
      // setFirstTime(false);
      setTableData(res);
    }
  };
  const getDownload = async ({ month }) => {
    window.open(
      baseUrl + urls.revenueReportDownload(userDetail?.propertyId, month),
      "_blank",
      "noopener,noreferrer"
    );
  };
  const STORY_HEADERS = [
    {
      prop: "date",
      title: "Date",
      isFilterable: true,
      isSortable: true,
    },

    {
      prop: "total_sale_rrooms",
      title: "Room Sale RRooms (₹)",
      isFilterable: true,
      cellProps: { style: { minWidth: 180 } },
    },
    {
      prop: "total_sale_walk_in",
      title: "Room Sale Walk-In (₹)",
      isFilterable: true,
      cellProps: { style: { minWidth: 180 } },
    },
    {
      prop: "total_sale_ota",
      title: "Room Sale OTA (₹)",
      isFilterable: true,
    },
    {
      prop: "total_sale_ta",
      title: "Room Sale TA (₹)",
      isFilterable: true,
    },
    {
      prop: "total_commission",
      title: "Rrooms Commission (₹)",
      isFilterable: true,
      cellProps: { style: { minWidth: 200 } },
    },
    {
      prop: "total_expense",
      title: "Room Expense (₹)",
      isFilterable: true,
    },
    {
      prop: "total_sale_sum",
      title: "Room Sale",
      isFilterable: true,
    },
    {
      prop: "net_profit",
      title: "Net Profit (₹)",
      isFilterable: true,
    },

    // {
    //   prop: "action",
    //   title: "Export Excel",
    //   cell: (row) => (
    //     <Button variant="success" className="btn-sm" onClick={getDownload}>
    //       <FontAwesomeIcon icon="fa-solid fa-download" />
    //     </Button>
    //   ),
    // },
  ];
  const changeHandler = (item) => {
    setSearchSelectVal(item);
    getDataHandler(date, item.id);
  };
  useEffect(() => {
    getDataHandler(date, searchSelectVal.id);
  }, [date]);
  return (
    <Layout
      title={"Revenue Report"}
      filterDate={date}
      withoutDate={true}
      setFilterDate={setDate}
      dateFormate={"MMM-yyyy"}
      dateFilterText="Select Month"
      wantSearchableListFor={
        userDetail?.logintype === "rroomUser" ? "property" : ""
      }
      searchSelectVal={searchSelectVal}
      changeHandler={changeHandler}
      openHandler={getDownload}
      buttonTitle="Download Report"
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <div className="scrollTable">
            <Table className="food-report" ref={tableRef} bordered>
              <TableHeader />
              <TableBody />
            </Table>
          </div>
        </DatatableWrapper>{" "}
        :
      </Row>
    </Layout>
  );
}

export default RevenueReport;
