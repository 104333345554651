import dictionary from "../dictionary";
import methods from "../methods";

const { user } = dictionary;
const { get, post, patch, put } = methods;

const UserService = {
  addRRoomUserService: (body) => {
    return post(user.addRRoomUser(), body);
  },
  editRRoomUserService: (id, body) => {
    return put(user.addRRoomUser() + "/" + id, body);
  },
  getRroomsUsersService: () => {
    return get(user.getRroomsUsers());
  },
  getPropertyUsersService: () => {
    return get(user.getPropertyUsers());
  },
  getPropertyUsersServiceByProperty: (id) => {
    return get(user.getPropertyUsersByPrt(id));
  },
  addPropertyUserService: (body) => {
    return post(user.getPropertyUsers(), body);
  },
  editPrtUserService: (id, body) => {
    return put(user.delPropertyUsers(id), body);
  },
  getCustomersService: () => {
    return get(user.getCustomers());
  },
};

export default UserService;
