import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const RenderBox = ({
  amount = null,
  count,
  title,
  handler,
  style,
  bg = "#00a3ff",
  para = "Amount",
  loading = false,
}) => {
  return loading ? (
    <li className="shimmerDashBoard flex flex-column">
      <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
        <Skeleton containerClassName="w-100 skeleton mb-3" height={25} />
      </SkeletonTheme>
      <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
        <Skeleton
          containerClassName="skeleton align-self-center mb-3"
          width={30}
          height={25}
        />
      </SkeletonTheme>
      <SkeletonTheme baseColor="#fff" highlightColor="#cfcfcf">
        <Skeleton
          containerClassName="skeleton align-self ml-2 mb-1"
          width={80}
          height={20}
        />
      </SkeletonTheme>
    </li>
  ) : (
    <li style={style} onClick={handler ? handler : () => {}}>
      <div className="d-flex">
        {amount != null && (
          <p>
            {para}: ₹ {Math.round(amount)}
          </p>
        )}
        <h4>{count ?? 0}</h4>
        <span style={{ backgroundColor: bg }}>{title ?? "N/A"}</span>
      </div>
    </li>
  );
};
export default RenderBox;
