import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import moment from "moment";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import logo from "../../API/Interceptor/loderLogo.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeCouponStatus } from "../../API/services/newServices";
import ReactGA from "react-ga4";

const CouponPage = () => {
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "coupons" });
    window.scrollTo(0, 0);
    getReview();
  }, [userDetail]);

  const [tableData, setTableData] = useState([]);

  const getReview = async () => {
    try {
      const res = await services.globalService.getAllCoupons();
      if (res.status === 200) {
        setTableData(res.data.data);
      }
    } catch (error) {}
  };

  const { AlertIcon } = Imagesdata || {};

  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    ...validation,
    mode: "onSubmit",
    defaultValues: {
      couponTitle: "",
      couponCode: "",
      discountAmount: "",
      startDate: "",
      expiryDate: "",
      bookingFromDate: "",
      bookingToDate: "",
    },
  });

  const [offerMode, setOfferMode] = useState(0);

  const onSubmit = async (payload) => {
    let prices = JSON.parse(selectedData.updatedPrice ?? "[]");

    let arr = [];
    if (prices) {
      if (prices?.find((i) => i?.property_id === userDetail?.propertyId)) {
        arr = prices?.map((item) => {
          if (item?.property_id === userDetail?.propertyId) {
            return {
              property_id: item?.property_id,
              price: payload.discountAmount,
            };
          } else {
            return item;
          }
        });
      } else {
        arr = prices?.map((item) => item);
        arr.push({
          property_id: userDetail?.propertyId,
          price: payload.discountAmount,
        });
      }
    } else {
      arr.push({
        property_id: userDetail?.propertyId,
        price: payload.discountAmount,
      });
    }

    const realPayload = {
      property_id: 0,
      title: payload.couponTitle,
      code: payload.couponCode,
      amount:
        editMode && userDetail?.logintype === "propertyUser"
          ? selectedData.amount
          : payload.discountAmount,
      expireAt: moment(payload.expiryDate).format("YYYY-MM-DD"),
      allowChange: editMode ? selectedData.allowChange : 0,
      status: 0,
      start_at: moment(payload.startDate).format("YYYY-MM-DD"),
      booking_from: moment(payload.bookingFromDate).format("YYYY-MM-DD"),
      booking_to: moment(payload.bookingToDate).format("YYYY-MM-DD"),
      updatedPrice:
        userDetail?.logintype === "propertyUser"
          ? JSON.stringify(arr)
          : selectedData?.updatedPrice ?? "[]",
      offerMode:
        userDetail?.logintype === "propertyUser"
          ? selectedData.offerMode
          : offerMode,
      status:
        userDetail?.logintype === "propertyUser"
          ? selectedData.status
          : payload.status
          ? 1
          : 0,
    };

    try {
      let res = {};
      if (editMode) {
        const dataEdit = {
          id: selectedData.id,
          body: realPayload,
        };
        res = await services.globalService.updateCouponCodeService(dataEdit);
      } else {
        res = await services.globalService.createCouponCodeService(realPayload);
      }

      if (res.status === 201 || res.status === 200) {
        if (editMode && userDetail?.logintype === "propertyUser") {
          changeCouponStatus({
            propertyId: [userDetail.propertyId],
            status: payload.status,
            createdBy: userDetail?.id,
            couponId: selectedData.id,
          });
        }
        setOfferMode(0);
        reset();
        setShow(false);
        getReview();
        setEditMode(false);
        setSelectedData({});
      }
    } catch (error) {}
  };

  const editHandler = (data, newPrice) => {
    setSelectedData(data);
    setShow(true);
    setOfferMode(data.offerMode);
    setValue("couponTitle", data.title);
    setValue("couponCode", data.code);
    setValue("startDate", new Date(data.startAt));
    setValue("bookingFromDate", new Date(data.bookingFrom));
    setValue("bookingToDate", new Date(data.bookingTo));
    if (userDetail?.logintype === "propertyUser") {
      setValue(
        "status",
        data.InactiveCoupanProperties.includes(userDetail?.propertyId)
          ? "0"
          : "1"
      );
    } else {
      setValue("status", data.status == 0 ? "0" : "1");
    }
    if (
      Object.keys(newPrice)?.length !== 0 &&
      userDetail?.logintype === "propertyUser"
    ) {
      setValue("discountAmount", newPrice?.price);
    } else {
      setValue("discountAmount", data.amount);
    }
    setValue("expiryDate", new Date(data.expireAt));
    setEditMode(true);
  };

  const deleteHandler = async (id) => {
    try {
      let res = {};
      res = await services.globalService.deleteCouponCodeByIdService(id);
      if (res.status === 200) {
        getReview();
      }
    } catch (error) {}
  };
  const changeStatus = async (data) => {
    try {
      const realPayload = {
        property_id: data?.propertyId,
        title: data.title,
        code: data.code,
        amount: data.amount,
        expireAt: moment(data.expireAt).format("YYYY-MM-DD"),
        allowChange: data.allowChange == 0 ? 1 : 0,
        status: 0,
        start_at: moment(data.startAt).format("YYYY-MM-DD"),
        booking_from: moment(data.bookingFrom).format("YYYY-MM-DD"),
        booking_to: moment(data.bookingTo).format("YYYY-MM-DD"),
      };
      const dataEdit = {
        id: data.id,
        body: realPayload,
      };
      let res = await services.globalService.updateCouponCodeService(dataEdit);
      if (res.status === 200) {
        toast.success(
          data.allowChange == 0
            ? "Allowed To change Discount by Property!"
            : "Prevent To change Discount by Property!",
          {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          }
        );
        getReview();
        reset();
      }
    } catch (error) {}
  };

  return (
    <Layout
      title="All Coupons"
      openHandler={
        userDetail?.logintype === "propertyUser" ? false : () => setShow(true)
      }
    >
      <div className="couponSet">
        {tableData.map((data, key) => {
          let prices = JSON.parse(data.updatedPrice);
          let newPrice = {};
          if (prices) {
            let getData = prices?.find(
              (i) => i?.property_id === userDetail?.propertyId
            );
            if (getData) {
              newPrice = getData;
            }
          }
          return (
            <div className="coupon-card tooltip_Fire" key={key}>
              <span className="Liketooltip" style={{ top: 0, right: "-100%" }}>
                {data.offerMode == 1
                  ? "Coupon Available only for Mobile App"
                  : data.offerMode == 2
                  ? "Coupon Available only for Website"
                  : "Coupon Available for Both App and Website"}
              </span>
              <div
                className="position-absolute d-flex gap-2"
                style={{ top: "3%", right: "3%" }}
              >
                {data.offerMode == 1 ? (
                  <FontAwesomeIcon size="lg" icon="fa fa-mobile" />
                ) : data.offerMode == 2 ? (
                  <FontAwesomeIcon size="lg" icon="fa fa-desktop" />
                ) : (
                  <>
                    <FontAwesomeIcon size="lg" icon="fa fa-mobile" />
                    <FontAwesomeIcon size="lg" icon="fa fa-desktop" />
                  </>
                )}
              </div>
              <img alt="" src={logo} className="logo" />
              <div>
                <h3>{data.title}</h3>
                <h4>
                  {Object.keys(newPrice)?.length !== 0 && prices
                    ? newPrice?.price
                    : data.amount}
                  % OFF
                </h4>
              </div>
              <di className="coupon-row">
                <span id="cpnCode">{data.code}</span>
                <CopyToClipboard
                  text={data.code}
                  onCopy={() =>
                    toast.success(
                      `Coupon Code ${data.code} Copied To Clipboard*`,
                      {
                        autoClose: 1000,
                        pauseOnHover: true,
                        position: "top-right",
                      }
                    )
                  }
                >
                  <span id="cpnBtn">Copy</span>
                </CopyToClipboard>
              </di>
              <p>Valid Till: {moment(data.expireAt).format("DD MMM, YYYY")}</p>
              {data?.allowChange || userDetail?.logintype !== "propertyUser" ? (
                <>
                  {userDetail?.Role?.canEdit == 1 && (
                    <div
                      className="circle1"
                      onClick={() => editHandler(data, newPrice)}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </div>
                  )}
                </>
              ) : null}
              {userDetail?.logintype === "propertyUser" ? null : (
                <>
                  <div
                    className="circle2"
                    onClick={() => deleteHandler(data?.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </div>
                  <div
                    class={`circle3 ${
                      data.allowChange ? "text-danger" : "text-success"
                    }`}
                    onClick={() => changeStatus(data)}
                  >
                    <i className="fa fa-percent" aria-hidden="true"></i>
                    <span>{data?.allowChange ? "Allowed" : "Allow"}</span>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setEditMode(false);
          setSelectedData({});
          setOfferMode(0);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{!editMode ? "Create " : "Edit "}Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm couponForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                {userDetail?.logintype === "propertyUser" ? null : (
                  <>
                    <Col xs={12}>
                      <div className="form-group full">
                        <label>Offer Mode</label>
                        <ul className="flex">
                          <li className="amns">
                            <input
                              type="checkbox"
                              id="app"
                              value={offerMode}
                              onChange={() => setOfferMode(1)}
                            />
                            <label
                              className={`checkLable ${
                                offerMode == 1 ? "activeLable" : ""
                              }`}
                              htmlFor="app"
                            >
                              Application
                            </label>
                          </li>
                          <li className="amns">
                            <input
                              type="checkbox"
                              id="web"
                              value={offerMode}
                              onChange={() => setOfferMode(2)}
                            />
                            <label
                              className={`checkLable ${
                                offerMode == 2 ? "activeLable" : ""
                              }`}
                              htmlFor="web"
                            >
                              Website
                            </label>
                          </li>
                          <li className="amns">
                            <input
                              type="checkbox"
                              id="both"
                              value={offerMode}
                              onChange={() => setOfferMode(0)}
                            />
                            <label
                              className={`checkLable ${
                                offerMode == 0 ? "activeLable" : ""
                              }`}
                              htmlFor="both"
                            >
                              Both
                            </label>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="couponTitle"
                        text="Coupon Title*"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter coupon title"
                        field={"couponTitle"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.couponTitle?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"couponTitle"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="couponCode"
                        text="Coupon Code*"
                        type="text"
                        placeholder="Enter coupon code"
                        field={"couponCode"}
                        register={register}
                        errors={errors}
                      />
                      {errors?.couponCode?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"couponCode"} />
                          </span>
                        </div>
                      )}
                    </Col>
                  </>
                )}
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="discountAmount"
                    text="Coupon Amount(%)*"
                    type="text"
                    placeholder="Enter coupon amount(%)"
                    field={"discountAmount"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.discountAmount?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"discountAmount"} />
                      </span>
                    </div>
                  )}
                </Col>

                <Col xs={6} className="form-group">
                  <label>Status</label>
                  <select register={register} {...register("status")}>
                    <option disabled selected>
                      Select Status
                    </option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </Col>
                {userDetail?.logintype === "propertyUser" ? null : (
                  <>
                    <Col xs={6} className="form-group">
                      <label>Start Date</label>
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="form-control w-100"
                            customclassName="form-control"
                            onChange={(date) => onChange(date)}
                            selected={value}
                          />
                        )}
                      />
                      {errors?.startDate?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"startDate"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <label>Expire Date</label>
                      <Controller
                        control={control}
                        name="expiryDate"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="form-control w-100"
                            customclassName="form-control"
                            onChange={(date) => onChange(date)}
                            selected={value}
                          />
                        )}
                      />
                      {errors?.expiryDate?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"expiryDate"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <label>Booking From Date</label>
                      <Controller
                        control={control}
                        name="bookingFromDate"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="form-control w-100"
                            customclassName="form-control"
                            onChange={(date) => onChange(date)}
                            selected={value}
                          />
                        )}
                      />
                      {errors?.bookingFromDate?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"bookingFromDate"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <label>Booking To Date</label>
                      <Controller
                        control={control}
                        name="bookingToDate"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="form-control w-100"
                            customclassName="form-control"
                            onChange={(date) => onChange(date)}
                            selected={value}
                          />
                        )}
                      />
                      {errors?.bookingToDate?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"bookingToDate"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                  </>
                )}
                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default CouponPage;
