import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addMenuItem,
  deleteMenuItem,
  getMenuCategory,
  getMenuItem,
  getMenuName,
} from "API/services/newServices";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import decodeEntities from "Common/DecodeHtml";

const validation = Yup.object().shape({
  name: Yup.string().required("Enter Menu Item*"),
  menu: Yup.object().required("Select Menu*"),
  category: Yup.object().required("Select Menu Category*"),
});

const rules = { resolver: yupResolver(validation) };

const useBanquetMenuItem = () => {
  const tableRef = useRef();

  const { userDetail } = useSelector(authenticationSeletor);

  const { AlertIcon } = Imagesdata;

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onSubmit", ...rules });

  // states
  const [tableData, setTableData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [menus, setMenus] = useState([]);
  const [category, setCategory] = useState([]);
  const [selected, setSelected] = useState({});

  const getMenus = async () => {
    const res = await getMenuName(userDetail.propertyId);
    if (res) {
      const filter = res.map((i) => ({ value: i.id, label: i.menuName }));
      setMenus(filter);
    }
  };
  const getCategory = async () => {
    const res = await getMenuCategory(userDetail.propertyId);
    if (res) {
      const filter = res.map((i) => ({ value: i.id, label: i.categoryName }));
      setCategory(filter);
    }
  };

  const getDataHandler = async () => {
    const res = await getMenuItem(userDetail.propertyId);
    if (res) setTableData(res);
  };

  const onSubmit = async (payload) => {
    const param = {
      id: selected.id,
      editMode,
      body: {
        propertyId: userDetail.propertyId,
        menuNameId: payload.menu.value,
        menuCategoryId: payload.category.value,
        itemName: payload.name,
      },
    };
    const res = await addMenuItem(param);
    if (res) {
      hideAddModalHanlder();
      getDataHandler();
    }
  };

  const editHandler = (row) => {
    setEditMode(true);
    setValue("name", row.itemName);
    setValue(
      "menu",
      menus.find((i) => i.value == row.menuNameId)
    );
    setValue(
      "category",
      category.find((i) => i.value == row.menuCategoryId)
    );
    setSelected(row);
    openAddModalHanlder();
  };

  const deleteHandler = async (id) => {
    if (!window.confirm("Are You sure want to delete menu item")) {
      return;
    }
    const res = await deleteMenuItem(id);
    if (res) {
      getDataHandler();
    }
  };

  const STORY_HEADERS = [
    {
      prop: "id",
      title: "ID",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "itemName",
      title: "Name",
      isFilterable: true,
      isSortable: true,
      cell: (row) => decodeEntities(row.itemName),
    },
    {
      prop: "price",
      title: "Menu Name",
      isFilterable: true,
      isSortable: true,
      cell: (row) => menus.find((i) => i.value == row.menuNameId)?.label,
    },
    {
      prop: "menuCategoryId",
      title: "Menu Category",
      isFilterable: true,
      isSortable: true,
      cell: (row) => category.find((i) => i.value == row.menuCategoryId)?.label,
    },
    {
      prop: "createdAt",
      title: "Created Date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => moment(row.createdAt).format("DD-MM-YYYY H:M A"),
    },
    {
      prop: "action",
      title: "Actions",
      isSortable: true,
      isFilterable: true,
      cellProps: { className: "text-center" },
      cell: (row) => {
        return (
          <>
            <Button className="btn-sm mr-2" onClick={() => editHandler(row)}>
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            </Button>
            <Button
              className="btn-sm"
              variant="danger"
              onClick={() => deleteHandler(row.id)}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash" />
            </Button>
          </>
        );
      },
    },
  ];
  const openAddModalHanlder = () => {
    setOpenAddModal(true);
  };
  const hideAddModalHanlder = () => {
    setOpenAddModal(false);
    reset();
    setValue("menu", null);
    setValue("category", null);
  };
  useEffect(() => {
    getMenus();
    getCategory();
    getDataHandler();
  }, []);
  return {
    STORY_HEADERS,
    tableData,
    tableRef,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    menus,
    category,
    control,
  };
};

export default useBanquetMenuItem;
