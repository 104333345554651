import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FrontFooter from "../../layout/footer/FrontFooter";

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    Logolandingpage,
    UserLoginIcon,
    LanguageIcon,
    HotelIcon,
    FooterLogo,
    FbIcon,
    InstaIcon,
    TwitterIcon,
    DribbleIcon,
    YoutubeIcon,
    GooglePlay,
    ApplePlay,
    ScanCode,
    CoupleFriendly,
    UpiIcon,
    CardIcon,
    InternetBankingIcon,
    GooglepayIcon,
    PayLaterIcon,
    mapIcon,
    forwardIconWhite,
    ArrowIcon,
    VisaIcon,
    ParcialPay,
    HotalPay,
  } = Imagesdata || {};

  const settingsImgSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
  };

  const navigate = useNavigate();

  return (
    <div className="hotalList">
      {/* landing page header */}
      <div className="headerLanding">
        <div className="headerTop">
          <div className="container flex">
            <figure>
              <a href="/">
                <img alt="" src={Logolandingpage} alt="" />
              </a>
            </figure>
            <ul className="flex">
              <li>
                <figure>
                  <img alt="" src={HotelIcon} alt="" />
                </figure>
                <div className="content">
                  <p>Become a Member</p>
                  <a href="">List your property</a>
                </div>
              </li>
              <li>
                <figure>
                  <img alt="" src={HotelIcon} alt="" />
                </figure>
                <div className="content">
                  <p>RRooms for Business</p>
                  <a href="">Corporate booking </a>
                </div>
              </li>
              <li>
                <figure>
                  <img alt="" src={LanguageIcon} alt="" />
                </figure>
                <div className="content">
                  <p>English</p>
                  <select>
                    <option>Select your language</option>
                    <option>English</option>
                    <option>French</option>
                    <option>Spanish</option>
                  </select>
                </div>
              </li>
              <li>
                <Link className="loginButton" to="/login">
                  <img alt="" src={UserLoginIcon} alt="" /> Login / Signup
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* banner section */}
      <div className="homeBanner"></div>

      {/* banner form */}
      <div className="banner-form">
        <div className="wrapper">
          <form className="flex">
            <div className="form-group">
              <input type="search" placeholder="Search by city, locality" />
              <span>
                Find places near me
                <img alt="" src={forwardIconWhite} alt="" />
              </span>
            </div>
            <div className="form-group">
              <input type="date" placeholder="Tue, 17 Mar" />
              <img alt="" src={ArrowIcon} alt="" />
              <input type="date" placeholder="wed, 18 Mar" />
            </div>
            <div className="form-group">
              <select>
                <option>2 Adults, 2 Kids, 1 Room</option>
                <option>2 Adults, 1 Kids, 2 Room</option>
                <option>1 Adults, 2 Kids, 2 Room</option>
              </select>
            </div>
            <div className="form-group">
              <button type="button">Search</button>
            </div>
          </form>
        </div>
      </div>

      {/* main book section start here */}
      <div className="mainBook">
        <div className="container flex">
          <div className="col-left">
            <div className="hotelsBox">
              <div className="rating flex">
                <div className="col-left flex">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <span>hotel</span>
                </div>
                <div className="col-right flex">
                  <p>
                    <img alt="" src={CoupleFriendly} alt="" /> Couple Friendly
                  </p>
                </div>
              </div>
              <h2>
                <a href="">
                  Flagship Vibhu Residency Near Noida Sector 81 Metro Station
                </a>
              </h2>
              <a href="" className="address flex">
                <img alt="" src={mapIcon} alt="" />
                3.9 km Salar Pur Bhangel Bhangel Near Hdfc Bank, Noida
              </a>
              <ul className="filterRes flex">
                <li>
                  <h5>
                    <span>Check-in</span>
                    10 Mar 2023
                    <span>Friday, 12 PM</span>
                  </h5>
                  <div className="timelines">
                    <p>1 night</p>
                  </div>
                  <h5>
                    <span>Check-out</span>
                    11 Mar 2023
                    <span>Saturday, 11 AM</span>
                  </h5>
                </li>
                <li>
                  <h4>1 Night | 2 Adults | 1 Room</h4>
                </li>
                <li>
                  <div className="ratingCal">
                    <span>4.5/6</span>
                    <p>(2218 Ratings) · Very Good</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="payment-section">
              <h2>Payment options</h2>
              <ul className="flex tabBtns">
                <li>
                  <img alt="" src={CardIcon} alt="" />
                  <h6>Pay Online</h6>
                  <p>Visa, MasterCard, Amex, Rupay And More</p>
                </li>
                <li className="active">
                  <img alt="" src={ParcialPay} alt="" />
                  <h6>Parcial pay to your confirm booking</h6>
                </li>
                <li>
                  <img alt="" src={HotalPay} alt="" />
                  <h6>Pay at Hotel</h6>
                </li>
              </ul>
              <button type="button" className="booking-btn">
                Confirm Booking
              </button>
            </div>
          </div>
          <div className="col-right">
            <h3>Price Details</h3>
            <ul>
              <li>
                <span>Room price for 1 Night X 2 Guests</span>
                <p>₹2686</p>
              </li>
              <li>
                <span>Instant discount</span>
                <p className="green">-₹974</p>
              </li>
              <li>
                <span>55% Coupon Discount</span>
                <p className="green">-₹860</p>
              </li>
              <li>
                <span>Base Price</span>
                <p>₹1712</p>
              </li>
              <li>
                <span>Hotel taxes</span>
                <p>₹777</p>
              </li>
              <li>
                <span>Service Fee</span>
                <p>₹82</p>
              </li>
            </ul>
            <div className="total-price flex">
              <h4>
                Total Amount to be paid <br />{" "}
                <span>inclusive of all taxes</span>
              </h4>
              <p>₹1454</p>
            </div>
          </div>
        </div>
      </div>

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Payment;
