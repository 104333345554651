import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import Services from "API/services";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import loader from "../../../../API/Interceptor/load.json";
import loderLogo from "../../../../API/Interceptor/loderLogo.png";
import { isJsonString } from "utils/utils";

const GrcDownload = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const [data, setData] = useState({});
  const [extraCharges, setExtraCharges] = useState("");
  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "roomInvoice.pdf",
    page: { margin: Margin.MEDIUM, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 10,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  const getBookingData = async () => {
    setIsLoading(true);
    const res = await Services.property.getBookingById(id);
    if (res) {
      setData(res.data.data);
      let charges = [];
      if (!!data?.extraCharge1 && isJsonString(data?.extraCharge1)) {
        charges = JSON.parse(data.extraCharge1)?.map((i) => i.types);
      }
      if (!!data?.extraCharge2 && isJsonString(data?.extraCharge2)) {
        charges = [
          ...charges,
          ...JSON.parse(data.extraCharge2)?.map((i) => i.types),
        ];
      }
      setExtraCharges(charges.toString());
      setTimeout(() => {
        toPDF();
        navigate(-1);
        setIsLoading(false);
      }, 2500);
    }
  };

  const getRoomCategory = async () => {
    try {
      const res = await Services.property.getRoomCategoryService();
      if (res.status === 200) {
        setCategories(res.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getBookingData();
    getRoomCategory();
  }, []);

  return (
    <div className="invoice-for-commision documentGrc py-5 bg-white">
      {/* <div className="bg-white rounded-lg" ref={targetRef}> */}
      <div
        className="container m-auto"
        style={{ width: "60%" }}
        ref={targetRef}
      >
        <div
          className="header flex justify-content-start border-bottom"
          style={{ gap: "10rem" }}
        >
          <h6>GRC Number: {data.id}</h6>
          <h1>Guest Registration Card</h1>
        </div>
        <div className="flex-row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Name:</label>
              <div className="bottom-line">
                {data.otherPersonName ?? data.User?.name}
              </div>
            </div>
            <div className="form-group">
              <label>Company:</label>
              <div className="bottom-line"></div>
            </div>
            <div className="form-group">
              <label>Address:</label>
              <div className="bottom-line"></div>
            </div>
            <div className="form-group">
              <label>E-Mail ID:</label>
              <div className="bottom-line">{data?.User?.email}</div>
            </div>
            <div className="form-group">
              <label>Mobile No:</label>
              <div className="bottom-line">
                {data.User?.mobile ?? data.otherPersonNumber}
              </div>
            </div>
            <div className="flex-row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Birthdate:</label>
                  <div className="bottom-line"></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Anniversary:</label>
                  <div className="bottom-line"></div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="box"></div>
          </div> */}
        </div>

        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Arrived From:</label>
              <div className="bottom-line"></div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Arrival Date:</label>
              <div className="bottom-line">
                {moment(data.fromDate).format("DD MMM, YYYY")}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Expected Checkout:</label>
              <div className="bottom-line">
                {moment(data.toDate).format("DD MMM, YYYY")}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Departure Date:</label>
              <div className="bottom-line">
                {moment(data.checkOutDateTime).format("DD MMM, YYYY")}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Proceeding to:</label>
              <div className="bottom-line"></div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Arrival Time:</label>
              <div className="bottom-line">
                {moment(data.fromDate).format("hh:mm A")}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Departure Time:</label>
            <div className="bottom-line">
              {moment(data.checkOutDateTime).format("hh:mm A")}
            </div>
          </div>
        </div>
        {/* <div className="bottom-line"></div>

        <div className="section-title">Foreigners Only</div>
        <div className="form-group">
          <label>Nationality:</label>
          <div className="bottom-line"></div>
        </div>
        <div className="form-group">
          <label>Passport No:</label>
          <div className="bottom-line"></div>
        </div>
        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Date of Issue:</label>
              <div className="bottom-line"></div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Date of Expiry:</label>
              <div className="bottom-line"></div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Whether employed in India:</label>
          <div className="bottom-line"></div>
        </div>
        <div className="form-group">
          <label>Proposed duration stay in India:</label>
          <div className="bottom-line"></div>
        </div> */}
        <div className="bottom-line"></div>

        <div className="section-title">Hotel Use</div>
        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Room No:</label>
              <div className="bottom-line">{data.assignRoomNo}</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Room Type:</label>
              <div className="bottom-line">
                {
                  categories?.find((i) => i.id == data.propertyRoomsCategoryId)
                    ?.name
                }
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Room Rate:</label>
              <div className="bottom-line">{data.bookingAmout}</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Folio No:</label>
              <div className="bottom-line"></div>
            </div>
          </div>
        </div>

        <div className="flex-row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="adults_children">Adult/Child:</label>
              <div className="bottom-line">
                {data.adults + "/" + (data.children ?? 0)}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="extra_bed">Extra Charges:</label>
              <div className="bottom-line">{extraCharges}</div>
            </div>
          </div>
        </div>

        <div className="instructions">
          <p className="m-0" style={{ fontSize: "1rem" }}>
            1. The management does not take any responsibility for guest
            valuables. Lockers are available with cashiers.
          </p>
          <p className="m-0" style={{ fontSize: "1rem" }}>
            2. I have undergone the terms & conditions of my stay in the hotel &
            I agree to abide by the same.
          </p>
          <p className="m-0" style={{ fontSize: "1rem" }}>
            3. Hotel check-in and check-out times(11:00 AM Check-Out and
            1200:PM Check-In)
          </p>
        </div>

        <div className="flex justify-content-between align-items-center mt-5 mb-3">
          <div className="mt-5">(Guest signature)</div>
          <div className="mt-5">(Property Manager signature)</div>
        </div>
      </div>

      <div className="loaderCss">
        {isLoading && (
          <section className="loader-wrapper">
            <Player
              autoplay
              loop
              src={loader}
              style={{ height: "600px", width: "600px" }}
            ></Player>
            <img
              src={loderLogo}
              alt="logoLoder"
              style={{ borderRadius: "50%" }}
            />
          </section>
        )}
      </div>
    </div>
    // </div>
  );
};

export default GrcDownload;
