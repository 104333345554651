import React, { useEffect, useRef, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  getRroomPropertyAction,
  setFilter,
} from "../../store/slices/propertySlice";
import { useDispatch } from "react-redux";
import { propertySeletor, setSearch } from "../../store/slices/propertySlice";
import { debounce, getNextDay } from "utils/utils";
import { getSuggetions } from "API/services/newServices";

const SearchBar = ({
  locationData,
  customClass = "",
  nearByClick = () => {},
}) => {
  const dispatch = useDispatch();
  const suggetionRef = useRef(null);
  const { searchVal, filterValues } = useSelector(propertySeletor);
  const { forwardIconWhite, ArrowIcon } = Imagesdata || {};
  const navigate = useNavigate();
  const [suggetion, setSuggetion] = useState([]);
  const [errorVal, setErrorVal] = useState(false);
  const searchRef = useRef(null);
  const searchBarData = useRef(null);
  const toDateRef = useRef(null);
  const [onTopShow, setTopShow] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(getNextDay());

  const onSubmit = async () => {
    if (searchVal.length <= 0) {
      setErrorVal(true);
      searchRef.current.focus();
      return;
    }

    let getFilterValuesData = { ...filterValues };

    if (
      getFilterValuesData.propertyCategoryFilter.length ||
      getFilterValuesData.roomCategoryFilter.length ||
      getFilterValuesData.localityFilter.length
    ) {
      let propertyType = getFilterValuesData.propertyCategoryFilter.join(",");
      let roomType = getFilterValuesData.roomCategoryFilter.join(",");
      let pin = getFilterValuesData.localityFilter;
      dispatch(
        getRroomPropertyAction(
          `?order_direction=asc${
            pin ? `&pin_code=${pin}` : searchVal ? `&query=${searchVal}` : ""
          }
          ${propertyType ? `&property_type=${propertyType}` : ""}${
            roomType ? `&room_type=${roomType}` : ""
          }`
        )
      );
    } else {
      dispatch(
        getRroomPropertyAction(
          `?order_direction=asc${searchVal ? `&query=${searchVal}` : ""} `
        )
      );
    }
    navigate("/hotal-listings");
  };

  const searchFun = async (e) => {
    if (e.target.value.length <= 0) {
      setErrorVal(true);
      setSuggetion([]);
    } else {
      setErrorVal(false);
    }
    dispatch(setSearch(e.target.value));
    try {
      const sugg = await getSuggetions(e.target.value);
      if (sugg) {
        setSuggetion(sugg);
      }
    } catch (error) {}
  };
  // const searchFun = async (query) => {

  // };

  const changeHandler = debounce(searchFun, 2000);

  function getOffset() {
    let el = searchRef.current;
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }

    window.scrollTo(_x, _y - 10);
  }

  useEffect(() => {
    searchRef.current.value = searchVal;
    window.addEventListener("scroll", () => {
      let el = searchBarData.current;
      var _x = 0;
      var _y = 0;
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
      }
      const scrollTop = window.scrollY;
      if (_y < scrollTop) {
        setTopShow(true);
      } else {
        setTopShow(false);
      }
    });
  }, [locationData]);
  return (
    <div
      ref={searchBarData}
      className={`banner-form ${customClass ?? ""} ${
        onTopShow ? "active" : ""
      }`}
    >
      <div className="wrapper">
        {errorVal && <div className="error-text">Please Search Something*</div>}
        <form className="flex">
          <div className="form-group position-relative">
            <input
              ref={searchRef}
              type="search"
              className="searchMainBox"
              placeholder="Search by city, locality"
              // value={searchVal}
              onChange={(e) => {
                changeHandler(e);
              }}
              onFocus={(e) => {
                getOffset();
                changeHandler(e);
              }}
              // onBlur={(e) => window.scrollTo(0, 0)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  setSuggetion([]);
                }
              }}
            />

            {suggetion?.length > 0 && searchVal && (
              <div
                ref={suggetionRef}
                id="sugg"
                className=" suggetions position-absolute w-100 bg-white top-100 border start-0 rounded"
                onClick={getOffset}
                style={{ overflow: "auto", maxHeight: "50vh" }}
              >
                {suggetion?.map((item, ind) => {
                  const { name, pinCode, type } = item;
                  return (
                    <React.Fragment key={ind}>
                      <div
                        onClick={() => {
                          if (type === "CITY") {
                            window.scrollTo(450, 450);
                            dispatch(setSearch(name));
                          } else if (type === "LOCALITY") {
                            dispatch(setSearch(name));
                            dispatch(
                              setFilter({
                                ...filterValues,
                                localityFilter: `${pinCode}`,
                              })
                            );
                          } else {
                            getOffset();
                            navigate(`/hotel-details/${item.id}`);
                          }
                          setSuggetion([]);
                        }}
                        className="flex justify-content-between align-items-center p-2 border-bottom"
                        style={{ cursor: "pointer" }}
                      >
                        <p className="searchSuggestionText">
                          {name.length > 35
                            ? name.substring(0, 40) + "..."
                            : name}
                        </p>
                        <i className="fa fa-search"></i>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}

            {locationData?.latitude ? (
              <span style={{ cursor: "pointer" }} onClick={nearByClick}>
                Find places near me
                <img alt="" src={forwardIconWhite} alt="" />
              </span>
            ) : null}
          </div>

          <div className="form-group">
            <DatePicker
              className="form-control dataForm"
              selected={fromDate}
              dateFormat="dd/MM/yyyy"
              value={fromDate}
              minDate={new Date()}
              onChange={(date) => {
                setFromDate(date);
                toDateRef.current.setFocus(true);
              }}
            />
            <img alt="" src={ArrowIcon} alt="" />
            <DatePicker
              ref={toDateRef}
              className="form-control dataForm"
              selected={toDate}
              minDate={fromDate}
              dateFormat="dd/MM/yyyy"
              value={toDate}
              onChange={(date) => setToDate(date)}
            />
          </div>
          <div className="form-group">
            <select>
              <option>2 Adults, 2 Kids, 1 Room</option>
              <option>2 Adults, 1 Kids, 2 Room</option>
              <option>1 Adults, 2 Kids, 2 Room</option>
            </select>
          </div>
          <div className="form-group">
            <button type="button" onClick={onSubmit}>
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;
