import Layout from "layout";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import useBanquetBooking from "controllers/banquetBookings";
import { banquetPaymentMode, banquetStatus } from "constant/constants";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { bunquetBookingsPayment } from "API/services/newServices";
import { useEffect } from "react";
import { InputField } from "components/InputField/InputField";
import { showPrice } from "utils/utils";
import ReactGA from "react-ga4";

const validation = Yup.object().shape({
  paymentMode: Yup.string().required("Please Select Payment Method*"),
  amount: Yup.string().required("Please Enter amount*"),
  reference: Yup.string().required("Please Enter Reference*"),
  paymentDate: Yup.string().required("Please Select Date*"),
});

const rules = { resolver: yupResolver(validation) };

const BunquetBookings = () => {
  const {
    tableData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    STORY_HEADERS,
    addBooking,
    reportState,
    AlertIcon,
    show,
    setShow,
    selected,
    userDetail,
    getDataHandler,
    searchfilter,
    onDownload,
    tableRef,
  } = useBanquetBooking();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onSubmit", ...rules });
  const onHide = () => {
    setShow(false);
    reset();
  };
  const onSubmit = async (payload) => {
    // if (payload.amount ) {
    // }
    const param = {
      propertyId: userDetail.propertyId,
      banquetBookingId: selected.banquetBookingId,
      paidAmount: selected.finalAmount,
      paymentSource: payload.paymentMode,
      referenceNumber: payload.reference,
      paymentDate: payload.paymentDate,
    };
    const res = await bunquetBookingsPayment(param);
    if (res) {
      onHide();
      getDataHandler();
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: reportState ? "banquet_booking_report" : "banquet_bookings",
    });
    if (selected) {
      setValue("amount", selected.dueAmount);
    }
  }, [selected, show]);

  return (
    <Layout
      title={reportState ? "Banquet Bookings Reporting" : "Banquet Bookings"}
      openHandler={reportState ? () => onDownload() : () => addBooking()}
      setToDate={setEndDate}
      setFromDate={setStartDate}
      searchType={"date"}
      toDate={endDate}
      fromDate={startDate}
      searchfilter={!reportState ? false : startDate}
      type={reportState}
      optionData={banquetStatus}
      setSearchfilter={searchfilter}
      buttonTitle={reportState ? "Export Table" : "Add"}
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <div className="scrollTable">
            <Table className="food-report" ref={tableRef}>
              <TableHeader />
              <TableBody />
            </Table>
          </div>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkInModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Collect Payment
          </Modal.Title>
          <button className="closeButton" onClick={onHide}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Col xs={12} className="form-group">
              <Form.Label className="label">Select Payment Mode</Form.Label>
              <select
                {...register("paymentMode")}
                className="form-control pb-2"
              >
                <option selected disabled value="">
                  select
                </option>
                {banquetPaymentMode.map((mode, ind) => (
                  <option value={mode} key={ind}>
                    {mode}
                  </option>
                ))}
              </select>

              {errors?.paymentMode?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"paymentMode"} />
                  </span>
                </div>
              )}
            </Col>

            <Col xs={12} className="form-group">
              <InputField
                text="Amount"
                type="number"
                field="amount"
                autoFocus
                register={register}
                errors={errors}
                placeholder={"amount"}
              />
              {errors?.amount?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"amount"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={12} className="form-group">
              <InputField
                text="Reference Number"
                type="text"
                field="reference"
                autoFocus
                register={register}
                errors={errors}
                placeholder={"Reference Number"}
              />
              {errors?.reference?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"reference"} />
                  </span>
                </div>
              )}
            </Col>
            <Col xs={12} className="form-group">
              <InputField
                text="Payment Date"
                type="date"
                field="paymentDate"
                autoFocus
                register={register}
                errors={errors}
                placeholder={"Payment Date"}
              />
              {errors?.paymentDate?.message && (
                <div className="error-text">
                  <img alt="" src={AlertIcon} />
                  <span>
                    <ErrorMessage errors={errors} name={"paymentDate"} />
                  </span>
                </div>
              )}
            </Col>

            <Modal.Footer>
              <Button type="submit">Pay</Button>
              <div className="amount-section flex justify-content-between w-100">
                <span>Net Payable amount</span>
                <span className="amount">
                  {showPrice(selected.finalAmount)}
                </span>
              </div>
              <div className="amount-section flex justify-content-between w-100">
                <span>Paid amount</span>
                <span className="amount">{showPrice(selected.paidAmount)}</span>
              </div>
              <div className="amount-section flex justify-content-between w-100">
                <span>Due amount</span>
                <span className="amount">{showPrice(selected.dueAmount)}</span>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default BunquetBookings;
