import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>RRooms Hospitality India Private Limited, Inc. Privacy Policy</p>
          <h2>Privacy of children</h2>
          <p>
            We do not knowingly collect or solicit personal information from
            children under the age of 18 years and the Platform and its content
            are not directed at children under the age of 18 years. In the event
            that we learn that we have collected personal information from a
            child under the age of 18 years without verification of parental
            consent, we will delete that information as quickly as possible. If
            you believe that we might have any information from or about a child
            under 18 years. Please contact us at support@rrooms.in
          </p>
          <h2>Information we collect about you</h2>
          <p>
            when you access the Site or use the Service, as a user we may
            collect some personal information about you. This includes :
          </p>
          <ul>
            <li>Your email address</li>
            <li>Your first and last name</li>
            <li>Your phone number</li>
            <li>Your postal address</li>
            <li>images of you</li>
            <li>Calendar – For Booking Purpose and scheduling</li>
          </ul>
          <p>
            This also includes information that relates to you but may not
            specifically identify you, such as:
          </p>
          <ul>
            <li>information about your internet connection;</li>
            <li>
              information about the equipment you use to access the Site or
              Service;
            </li>
            <li>your usage details;</li>
            <li>your IP addresses; and</li>
            <li>
              information collected through cookies and other tracking
              technologies.
            </li>
          </ul>
          <h2>
            <i>Information We Collect Directly From You</i>
          </h2>
          <p>
            We collect personal information from various sources, such as
            directly from you when you access or use the Site or Service. This
            may include:-
          </p>
          <ul>
            <li>
              information you provide by filling the forms on the site or app or
              through the service. This includes information provided at the
              time of registering or booking and while using the service.
            </li>
            <li>
              information you provide when providing comments or reviews on, or
              communicating with others through, the Service including, without
              limitation, by posting User Contributions (as defined below).
            </li>
            <li>
              records and copies of your correspondence (including email
              addresses), if you contact us.
            </li>
            <li>your search queries on the Site.</li>
          </ul>
          <h2>
            <i>Information We Collect by Automatic Means</i>
          </h2>
          <p>
            When you navigate through and interact with the Site, we may use
            automatic data collection technologies to collect certain
            information about your equipment, browsing, actions, and patterns,
            including:
          </p>
          <ul>
            <li>
              information about the devices you use to access the Site (such as
              the IP address and the type of the device, operating system, and
              web browser);
            </li>
            <li>
              dates, times, and duration of use of the Site (including whether
              you are a repeat or first time visitor);
            </li>
            <li>
              information on actions taken when using the Service (such as page
              views and website navigation patterns);
            </li>
            <li>
              information about your network provider, mobile carrier, mobile
              browser type, and your click-sequence and tap-sequence when using
              your device or the App;
            </li>
            <li>
              click-sequence and tap-sequence when using your device or the App;
              information about your online activities over time and across
              third-party websites or other online services (behavioral
              tracking);
            </li>
            <li>
              certain geolocational information, such as your time zone and
              information about the speed, bearing, orientation, and altitude of
              your mobile device when using the App.
            </li>
          </ul>
          <p>
            In addition, we use third-party web analytics services to help us
            understand your use of the Site and the popularity of certain
            content so we can improve your experience. For more information on
            our use of third-party analytics services, see Third Party Providers
            below.
          </p>
          <p>
            Information we collect automatically may be statistical data that
            does not include personal information. We may maintain or associate
            any such statistical data with personal information we collect in
            other ways or receive from third parties. It helps us to estimate
            our audience size and usage patterns, speed up user experience, and
            to improve and personalize the Service, including enabling us to
            identify and authenticate users. To the extent that we associate any
            of this non-personal information with your personal information, we
            will treat it as personal information under the terms of this
            Privacy Policy.
          </p>
          <p>
            Technologies we use for automatic data collection may include
            cookies. A cookie is a small file placed on the hard drive of your
            computer. There are two types of cookies: session and persistent
            cookies:
          </p>
          <ul>
            <li>
              {" "}
              Session Cookies. Session cookies exist only during an online
              session. They disappear from your computer when you close your
              browser or turn off your computer. We use session cookies to allow
              our systems to uniquely identify you during a session or while you
              are logged into the Site. This allows us to process your online
              transactions and requests and verify your identity, after you have
              logged in, as you move through our Site.
            </li>
            <li>
              {" "}
              Persistent Cookies. Persistent cookies remain on your computer
              after you have closed your browser or turned off your computer. We
              use persistent cookies to track aggregate and statistical
              information about user activity.{" "}
            </li>
          </ul>
          <p>
            Pages of our Site and our emails may also contain small electronic
            files known as web beacons (also referred to as clear gifs, pixel
            tags, and single-pixel gifs) that permit us, for example, to count
            users who have visited those pages or opened an email and for other
            related website statistics (for example, recording the popularity of
            certain website content and verifying system and server integrity).
          </p>
          <h2>How we use your information</h2>
          <p>
            We use your information, including your personal information, for
            the following purposes:
          </p>
          <ul>
            <li>
              To provide our service and offers : We use the Personal
              Information to contact you for confirmation of your booking on our
              Platform, for delivery of booking confirmation voucher, for
              intimation of any discounts or schemes by the Company or for other
              promotional purposes.
            </li>
            <li>
              For grievance and query resolution: We use your Personal
              Information to respond to your requests or questions or
              grievances.
            </li>
            <li>
              To enhance user experience: We use information to improve our
              Platform and Services. We might use your information to customize
              your experience with us. This could include displaying content
              based upon your preferences, feedbacks or reviews.
            </li>
            <li>
              For user experience analysis: We use information to look at site
              trends and customer interests. We may use your information to make
              our Platform and Services better.
            </li>
          </ul>
          <p>
            We may also use your information to contact you about our own and
            third-parties’ goods and services that may be of interest to you. If
            you do not want us to use your information in this way, please email
            support@rrooms.in.{" "}
          </p>
          <h2>Information from Other Sources:</h2>
          <p>
            We might receive information about you when you sign- up/log-in
            through a third-party account (i.e., Google, Facebook, etc.).
            Depending on the third- party accounts you choose and subject to the
            privacy settings that you have set in such third- party accounts,
            you understand that by granting us access to the public profile of
            your thirdparty account, we will access (if applicable and as
            permitted by the third-party service provider and authorized by you)
            information in the public profile of your third-party account so
            that it is available on and through your account on the Platform. We
            also collect information from Hotel Partners who wish to register
            with us under the ‘List Your Hotel’ feature on the Platform. During
            the stage of registration, the Platform collects certain information
            including without limitation the name of the hotel, address of the
            hotel, phone number of the hotel, state and city in India in which
            the hotel is located, number of rooms at the hotel, average room
            rate of the hotel, current occupancy of the hotel, name of the
            contact person at the hotel, personal phone number of the contact
            person at the hotel and such other information as may be sought by
            us ("Accommodation Information"). For the purposes of this Privacy
            Policy, the Accommodation Information along with any information.
          </p>
          <h2>Permission Needed</h2>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            permissions. Permissions includes:
          </p>
          <ul>
            <li>MEDIA/Camera - For Updating Profile Photo/Property Photo</li>
            <li>Calendar - For Inserting Events/Bookings.</li>
            <li>NOTIFICATIONS - For Notifications of App</li>
            <li>Location – For finding the nearest property</li>
          </ul>
          <h2>How we share your information</h2>
          <ul>
            <li>
              With service providers: We may employ third party companies and
              individuals to facilitate our Platform (“Service Providers”),
              provide services on our behalf, perform Platform-related services
              or assist us in analyzing how our Platform is used. These Service
              Providers may collect your Personal Data. The Personal Data
              collected by the Service Providers shall be governed by their
              respective privacy policies and We are not liable with regards to
              such Personal Data.
            </li>
            <li>
              With Hotel Partners: We will share information with our Hotel
              Partners for confirming bookings/reservations on the Platform. The
              Personal Data collected by the Hotel Partners shall be governed by
              their respective privacy policies and we are not liable with
              regards to such Personal Data.
            </li>
            <li>
              With Public Authorities(if required): We may share information if
              we think we have to in order to comply with the law or to protect
              ourselves. We will share information to respond to any judicial
              proceeding. We may also share it with any Public Authorities
              requests for such information.
            </li>
            <li>
              For Business Transfers: We may share information with any
              successor to all or part of our business. If we are involved in a
              merger, acquisition or asset sale, your Personal Data may be
              transferred. We will provide notice before your Personal Data is
              transferred and becomes subject to a different Privacy Policy.
            </li>
          </ul>
          <h2>Third Party Service Providers</h2>
          <ul>
            <li>
              Payment Gateway - PhonePe (Privacy Policy -
              https://www.phonepe.com/privacy-policy)
            </li>
            <li>
              Message Sender - Webgetty (Privacy Policy -
              https://www.webgetty.com/privacy-policy)
            </li>
            <li>
              E-Mail Sender - ZOHO Mail (Privacy Policy -
              https://www.zoho.com/privacy.html)
            </li>
            <li>
              Whatsapp Notification Sender - Interakt (Privacy Policy -
              https://www.interakt.shop/privacy-policy){" "}
            </li>
            SHARING OF INFORMATION WITH THIRD-PARTIES
            <li>
              With service providers: We may employ third party companies and
              individuals to facilitate our Platform (“Service Providers”),
              provide services on our behalf, perform Platform-related services
              or assist us in analyzing how our Platform is used. These Service
              Providers may collect your Personal Data. The Personal Data
              collected by the Service Providers shall be governed by their
              respective privacy policies and We are not liable with regards to
              such Personal Data.
            </li>
            <li>
              With Hotel Partners: We will share information with our Hotel
              Partners for confirming bookings/reservations on the Platform. The
              Personal Data collected by the Hotel Partners shall be governed by
              their respective privacy policies and we are not liable with
              regards to such Personal Data.
            </li>
            <li>
              With Public Authorities(if required): We may share information if
              we think we have to in order to comply with the law or to protect
              ourselves. We will share information to respond to any judicial
              proceeding. We may also share it with any Public Authorities
              requests for such information.
            </li>
            <li>
              For Business Transfers: We may share information with any
              successor to all or part of our business. If we are involved in a
              merger, acquisition or asset sale, your Personal Data may be
              transferred. We will provide notice before your Personal Data is
              transferred and becomes subject to a different Privacy Policy.
            </li>
          </ul>
          <h2>Security</h2>
          <p>
            We strive to ensure the security, integrity and privacy of Your
            Personal Data and to protect your Personal Data against unauthorized
            access, alteration, disclosure or destruction. The Platform has
            stringent security measures (physical, electronic and managerial) in
            place to protect against the loss, misuse, and alteration of the
            Personal Data under our control.
          </p>
          <p>
            You shall have a right to view and modify the contents of your
            account on the Platform. Whenever you change or access your Account
            Information, we offer the use of a secure server. The Platform's
            servers are accessible only to authorized personnel and your
            information is shared with our employees and authorized personnel on
            a 'need to know' basis, to complete the transaction and to provide
            the Services.
          </p>
          <p>
            We are not responsible for any breach of security or for any actions
            of any third parties or Service Providers that receive your Personal
            Data. We shall not be responsible for the privacy practices or the
            content of third parties or Service Providers.
          </p>
          <p>
            Notwithstanding anything contained in this Privacy Policy or
            elsewhere, we shall not be held responsible for any loss, damage or
            misuse of your Personal Data, if such loss, damage or misuse is
            attributable to a Force Majeure Event.
          </p>
          <h2>LINK TO THIRD-PARTY WEBSITES</h2>
          <p>
            If you click on one of the links to third-party websites, you may be
            taken to websites we do not control. This Privacy Policy does not
            apply to the privacy practices of those websites. Read the privacy
            policy of other websites carefully. We are not responsible for these
            third-party sites.
          </p>
          <h2>DATA RETENTION AND DATA DELETION</h2>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws, resolve disputes and enforce
            our legal agreements and policies).
          </p>
          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period of time, except when
            this data is used to strengthen the security or to improve the
            functionality of our Platform, or we are legally obligated to retain
            this data for longer time periods.
          </p>
          <h2>
            In order to determine how long it is necessary to retain your
            personal information, we calculate retention periods in accordance
            with all of the following criteria:
          </h2>
          <ul>
            <li>
              {" "}
              The currency of your relationship with us from the date of User
              registration on the Platform;
            </li>
            <li>
              {" "}
              Any limitation periods under applicable law within which claims
              might be made;
            </li>
            <li>
              {" "}
              Any retention periods prescribed by applicable law or recommended
              by regulators, industry bodies or associations; and
            </li>
            <li> The existence of any relevant legal proceedings.</li>
          </ul>
          <p>
            If you are not satisfied with our Privacy Policy and you want to
            delete your Personal Data from our database you can send us a
            request on support@rrooms.in, Where your personal data is no longer
            required, we will ensure it is either securely deleted or stored in
            a way that means it will no longer be used by us for business
            purposes.
          </p>
          <h2>OPTING OUT PROCEDURE</h2>
          <p>
            You can opt-out of receiving our marketing emails. To stop receiving
            our promotional emails, please email support@rrooms.in. It may take
            about 10 (ten) days to process your request. Even if you opt-out of
            getting marketing messages, we will still be sending you
            transactional messages through email and SMS about your bookings.
          </p>
          <h2>UPDATES TO PRIVACY POLICY</h2>
          <p>
            We will let you know via email and/or a prominent notice on our
            Platform, prior to the change becoming effective and update the
            “Effective Date” at the top of this Privacy Policy. Please review
            this Privacy Policy periodically for any changes. Changes to this
            Privacy Policy are effective when they are posted on this page.
          </p>
          <h2>GRIEVANCE REDRESSAL</h2>

          <p>
            If You believe that Your rights have been violated by any Services
            or have any other grievance due to the use of the Platform, the name
            and contact details of the Grievance Redressal Officer to redress
            the same are provided below:
          </p>
          <ul>
            <li>
              Name: <strong>Mr Brijesh Kumar</strong>
            </li>
            <li>
              Email ID: <strong>brijesh.kumar@rrooms.in </strong>
            </li>
          </ul>

          <h2>CONTACT US</h2>
          <ul>
            <li>
              For any questions about this Privacy Policy, please contact us at
              support@rrooms.in.
            </li>
            <li>
              To access your account, booking details and avail exciting offers
              please login.
            </li>
          </ul>
          <h2>Address</h2>
          <p>
            ● B2-1409, DLF My Pad, VIBHUTI KHAND GOMTI NAGAR, Gomti Nagar,
            Lucknow, Lucknow, Uttar Pradesh, 226010
          </p>
        </div>
      </section>
      {/* content section end here */}
      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default PrivacyPolicy;
