// import { AES, enc } from "crypto-js";
// import conf from "../Config/conf";
import { toast } from "react-toastify";
import { store } from "../store";
import { clearAuthenticationState } from "../store/slices/authenticationSlice";
import moment from "moment";
import { setAssignedModules } from "store/slices/PropertyData";

// import { clearBuyerState } from "../store/slices/buyerSlice";
// import { clearPaymentState } from "../store/slices/paymentSlice";
// import { clearTransactionState } from "../store/slices/transactionSlice";
// import { clearNftState } from "../store/slices/nftSlice";

/**
 * Method will set session storage and will encrypt selected keys only.
 * @param {string} key
 * @param {any} data
 */
export const setSessionData = async (key, data) => {
  if (key) {
    const newString = JSON.stringify(data);
    const value = newString;
    sessionStorage.setItem(key, value);
  } else {
    setSessionData(key, data);
  }
};

/**
 *
 * @param {string} key
 * @return {any}
 */
export const getSessionData = (key) => {
  let data;
  const storageData = sessionStorage.getItem(key) || "";
  try {
    // const newString = aes.decrypt(storageData, conf.SecretKey);
    const value = storageData;
    data = JSON.parse(value);
  } catch (e) {
    data = storageData;
  }
  return data ? data : null;
};

/**
 * Method will remove given session storage item.
 * @param {string} key
 */
export const removeSessionKey = (key) => {
  if (key) {
    sessionStorage.removeItem(key);
  }
};

/**
 * Method will clear session storage.
 * @param {string} key
 */
export const clearSession = () => {
  sessionStorage.clear();
};

/**
 * Method to Encrypt values
 * @param {string} value
 * @return {string}
 */

/**
 * Method to convert phone number into asterisk pattern
 * @param {string} value
 * @return {string}
 */
export const phonePattern = (value) => {
  if (!!value) {
    return value.slice(0, 4) + " ** ** ** " + value.slice(-2);
  }
};

/**
 * Method to round off number to two decimal points
 * @param {number} value
 * @return {string}
 */
export const fixedDecimal = (value) => {
  if (!!value) {
    return value.toFixed(2);
  }
};

/**
 * Method to close supermojo iframe
 */
export const handleOnClose = () => {
  let callBackResponse = {
    status: "SUCCESS",
    event: "onCancel",
    data: { message: "close modal" },
  };
  window.parent.postMessage(callBackResponse, "*");
};

/**
 * Method to close supermojo iframe
 */
export const numberOnly = (event) => {
  event.target.value = event.target.value
    .replace(/[^0-9]/g, "")
    .replace(/(\..*)\./g, "$1");
};

export const onLogout = (navigate, login = false) => {
  let logoutRoute = "/login";
  if (
    store.getState()?.authentication.userDetail.logintype === "propertyUser"
  ) {
    logoutRoute = "/register";
  } else if (
    store.getState()?.authentication.userDetail.logintype === "rroomUser"
  ) {
    logoutRoute = "/admin-login";
  }
  clearSession();
  store.dispatch(clearAuthenticationState());
  store.dispatch(setAssignedModules([]));
  if (navigate) {
    toast.success("Log Out Successfull!", {
      autoClose: 1000,
      pauseOnHover: true,
      position: "top-center",
    });
    navigate(logoutRoute);
  }
  if (login) {
    toast.success("Please Login As Customer!", {
      autoClose: 1000,
      pauseOnHover: true,
      position: "top-center",
    });
    login("/login");
  }
};

export const alphabetsOnly = (event) => {
  event.target.value = event.target.value
    .replace(/[^A-Za-z ]/g, "")
    .replace(/(\..*)\./g, "$1");
};

/**
 * Method will be used for identifying whether an object is empty or not
 * @param {any} obj
 * @return {boolean}
 */
export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const getCardType = (cardNumber) => {
  var re = new RegExp("^4");
  if (cardNumber.match(re) != null) return { type: "Visa", logo: "visa.svg" };

  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      cardNumber
    )
  )
    return { type: "Mastercard", logo: "mastercard.svg" };

  var re = new RegExp("^5");
  if (cardNumber.match(re) != null)
    return { type: "Mastercard", logo: "mastercard.svg" };

  re = new RegExp("^3[47]");
  if (cardNumber.match(re) != null) return { type: "AMEX", logo: "amex.svg" };

  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (cardNumber.match(re) != null)
    return { type: "Discover", logo: "discover.svg" };

  re = new RegExp("^36");
  if (cardNumber.match(re) != null)
    return { type: "Diners", logo: "diners.svg" };

  re = new RegExp("^30[0-5]");
  if (cardNumber.match(re) != null)
    return { type: "Diners - Carte Blanche", logo: "diners-carte-blanche.svg" };

  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (cardNumber.match(re) != null) return { type: "JCB", logo: "JCB.svg" };

  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (cardNumber.match(re) != null)
    return { type: "Visa Electron", logo: "electron.svg" };

  return { type: "", logo: "" };
};

export const getCardLogByCardType = (cardType) => {
  if (cardType.toLowerCase() === "visa") return "visa.svg";

  if (cardType.toLowerCase() === "mastercard") return "mastercard.svg";

  if (cardType.toLowerCase() === "amex") return "amex.svg";

  if (cardType.toLowerCase() === "discover") return "discover.svg";

  if (cardType.toLowerCase() === "diners") return "diners.svg";

  if (cardType.toLowerCase() === "diners - carte blanche")
    return "diners-carte-blanche.svg";

  if (cardType.toLowerCase() === "jcb") return "JCB.svg";

  if (cardType.toLowerCase() === "visa electron") return "electron.svg";

  return "";
};

export const getFormattedDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

export const daysDiffernce = (dateString1, dateString2) => {
  if (!dateString1 || !dateString2) return 0;
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  return daysDiff;
};

export const arrayColumn = (arr, n) => arr.map((x) => x[n]);
export const getWeekDayTime = (date) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dateObj = new Date(date);

  const dayOfWeek = daysOfWeek[dateObj.getDay()];
  const hours = dateObj.getHours();
  const period = hours >= 12 ? "PM" : "AM";

  return `${dayOfWeek}, ${hours % 12} ${period}`;
};

export const createOptions = (data, label, value) => {
  let dataSet = [];
  if (data?.length > 0) {
    data.map((option) => {
      let dataObj = {
        value: option?.[value],
        label: option?.[label],
      };
      dataSet.push(dataObj);
    });
  }
  if (value === "uuid") {
    dataSet.sort((a, b) => a?.label?.localeCompare(b?.label));
  }
  return dataSet;
};
export const createInventoryOptions = (data, label, value, innerKey) => {
  let dataSet = [];
  if (data?.length > 0) {
    data.map((option) => {
      let dataObj = {
        label: option[label]?.[innerKey],
        value: option[value],
      };
      dataSet.push(dataObj);
    });
  }
  if (value === "uuid") {
    dataSet.sort((a, b) => a?.label?.localeCompare(b?.label));
  }
  return dataSet;
};

export const reverseGstCalculation = (inclusiveCost = 0, gstPercent) => {
  const originalCost = ((inclusiveCost * 100) / (100 + gstPercent)).toFixed(2);
  const gstAmount = ((inclusiveCost * gstPercent) / 100).toFixed(2);

  return {
    originalCost,
    gstAmount,
  };
};
export const reverseGstCalculationWithAddition = (
  inclusiveCost = 0,
  gstPercent
) => {
  const originalCost = (inclusiveCost * 100) / (100 + gstPercent);
  const gstAmount = (originalCost * gstPercent) / 100;

  return Math.round(Number(originalCost) + Number(gstAmount));
};
export const getReverseTaxOnly = (inclusiveCost = 0, gstPercent) => {
  const originalCost = (inclusiveCost * 100) / (100 + gstPercent);
  const gstAmount = (originalCost * gstPercent) / 100;
  return Math.round(+gstAmount);
};
export const getReverseOnlyAmount = (inclusiveCost = 0, gstPercent) => {
  const originalCost = (inclusiveCost * 100) / (100 + gstPercent);
  return Math.round(+originalCost);
};

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const datesAreNextTowDays = (first, second = new Date()) => {
  const d1 = new Date(first);
  const d2 = new Date(second);
  const diff = d1.getTime() - d2.getTime();
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  return diffDays >= 2;
};

export const getForamateDateForFilter = (date) => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

export const foodAmountwithGST = (amount, foodGST = 5) => {
  const final = Number(amount) + (amount * foodGST) / 100;
  return Math.round(final);
};
export const getWithTaxAmout = (amount = 0, tax = 0) => {
  const final = Number(amount) + (amount * tax) / 100;
  return Math.round(final);
};

export const showPrice = (price) =>
  isNaN(price) ? "₹ 00" : `₹ ${Math.round(price ?? 0)}.00`;
export const showPercentage = (price) =>
  isNaN(price) ? "00" : `${Math.round(price ?? 0)}%`;
export const showDate = (date) => moment(date).format("DD MMM, YYYY");

export const number2words = (price) => {
  const n = Number(price);
  if (isNaN(n)) return "00";
  const num =
    "Zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve Thirteen Fourteen Fifteen Sixteen Seventeen Eighteen Nineteen".split(
      " "
    );
  const tens = "Twenty Thirty Forty Fifty Sixty Seventy Eighty Ninety".split(
    " "
  );

  if (n < 20) return num[~~n];
  var digit = n % 10;
  if (n < 100) return tens[~~(n / 10) - 2] + (digit ? " " + num[~~digit] : "");
  if (n < 1000)
    return (
      num[~~(n / 100)] +
      " Hundred" +
      (n % 100 == 0 ? "" : " " + number2words(n % 100))
    );
  return (
    number2words(~~(n / 1000)) +
    " Thousand" +
    (n % 1000 != 0 ? " " + number2words(n % 1000) : "")
  );
};

const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

export const sendPreviousMonth = (date, firstTime = false) => {
  const d = new Date(date);
  let y = d.getFullYear();
  let m = d.getMonth() + 1;
  if (firstTime) {
    if (m == 1) {
      m = 12;
      y -= 1;
    }
    m -= 1;
  }

  let finalDate = `${y}-${m > 9 ? m : `0${m}`}-${daysInMonth(y, m)}`;
  return finalDate;
};
export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const getPercentageFinal = (rs = 0, per = 0) =>
  Math.round(+rs - (rs * per) / 100);

export const taxOnly = (rs = 0, per = 0) => Math.round((rs * per) / 100);

const showFullIfOneDigitExist = (n) => (n > 9 ? n : `0${n}`);

export const showFullDateByDateAndTime = (d, time) => {
  const date = new Date(d);
  const temp = `${showFullIfOneDigitExist(
    date.getFullYear()
  )}-${showFullIfOneDigitExist(date.getMonth() + 1)}-${showFullIfOneDigitExist(
    date.getDate()
  )}T${time}.000Z`;
  return temp;
};
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getNextDay = (date = new Date()) =>
  new Date(new Date(date).getTime() + 24 * 60 * 60 * 1000);
