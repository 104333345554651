import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticationSeletor } from "../store/slices/authenticationSlice";
import { onLogout } from "../utils/utils";

function UserRoutes({ children, ifNoLogin }) {
  const navigate = useNavigate();
  const { userDetail } = useSelector(authenticationSeletor);
  useEffect(() => {
    if (ifNoLogin) {
      if (Object.keys(userDetail).length <= 0) {
        navigate("/login");
      }
    }
    if (Object.keys(userDetail).length) {
      if (userDetail?.logintype != "normalUser") {
        onLogout(false, navigate);
      }
    }
  }, [userDetail]);
  return <>{children}</>;
}

export default UserRoutes;
