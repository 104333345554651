import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import LandingHeader from "../../components/landingHeader";
import services from "../../API/services";
import {
  getRroomPropertyAction,
  setFilter,
  setSearch,
} from "../../store/slices/propertySlice";
import { useDispatch } from "react-redux";
import SearchBar from "../../components/searchBar";
import FrontFooter from "../../layout/footer/FrontFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import { Rating } from "@smastrom/react-rating";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

const LandingPage = () => {
  const navigate = useNavigate();
  // const [propertyList, setPropertyList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // const { rRoomProperty } = useSelector(propertySeletor);

  const [locationData, setLocation] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    GooglePlay,
    ApplePlay,
    ScanCode,
    ChooseSectionImg,
    // noProperty,
    slider1,
    slider2,
    slider3,
    topTiles1,
    topTiles2,
    topTiles3,
    topTiles4,
    topTiles5,
    topTiles7,
    userImg,
    lucknow,
    mumbai,
    indiagate,
    kolkate,
    pune,
    gurgown,
    city5,
    city3,
    noida,
    allCities,
    agraImg,
  } = Imagesdata || {};

  const { control, setValue, handleSubmit } = useForm();
  const submitMobileNumber = async (payload) => {
    try {
      const res = await services.auth.getAppService({
        mobile: payload.mobileNumber,
      });
      if (res.status == 200) {
        setValue("mobileNumber", "");
        toast.success(`Url sent successfully!`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  const settingsforslider = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    loop: true,
    autoplay: true,
    prevArrow: <FontAwesomeIcon icon="fa-solid fa-chevron-left" color="#fff" />,
    nextArrow: (
      <FontAwesomeIcon icon="fa-solid fa-chevron-right" color="#fff" />
    ),
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    arrows: true,
    infinite: true,

    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToListing = (e, item) => {
    e.preventDefault();
    dispatch(setSearch(""));
    dispatch(setFilter({ travellerChoice: [item.id] }));
    navigate("/hotal-listings");
  };

  const showCity = [
    { city: "Lucknow", img: lucknow, id: 1 },
    { city: "Agra", img: agraImg, id: 31 },
    { city: "Mumbai", img: mumbai, id: 34 },
    { city: "Delhi", img: indiagate, id: 32 },
    { city: "Kolkata", img: kolkate, id: 35 },
    { city: "Pune", img: pune, id: 36 },
    { city: "Gurugram", img: gurgown, id: 37 },
    { city: "Hyderabad", img: city5, id: 38 },
    { city: "Chennai", img: city3, id: 39 },
    { city: "Noida", img: noida, id: 40 },
    { city: "", img: allCities, id: 0 },
  ];
  const showImages = [
    {
      city: "Short-Stays",
      name: "Time at your command. RROOMS offers hourly stays – your cozy escape, your way.",
      id: 1,
      img: topTiles5,
    },

    {
      city: "Business Travel",
      name: "Unlock exclusive corporate discounts with our online hotel booking platform, making business travel efficient and cost-effective.",
      id: 2,
      img: topTiles1,
    },
    {
      city: "Couple Friendly",
      name: "Love without compromise. RROOMS welcomes couples to a secure and romantic retreat.",
      id: 7,
      img: topTiles2,
    },
    {
      city: "Family Vacation",
      name: "Safe, warm, and family-approved. RROOMS, your perfect base for a memorable family getaway.",
      id: 3,
      img: topTiles3,
    },
    // {
    //   city: "Guaranteed Check-In",
    //   name: "Say goodbye to check-in worries – our online hotel bookings guarantee a hassle-free arrival, so you can relax from the moment you arrive.",
    //   id: 4,
    //   img: topTiles6,
    // },
    {
      city: "Group Travel",
      name: "Group adventures, individual comforts. Your collective journey finds a cozy refuge here.",
      id: 5,
      img: topTiles4,
    },

    {
      city: "Solo Traveler",
      name: "Solo adventures, pocket-friendly stays. RROOMS is your trusted companion for the solo explorer.",
      id: 6,
      img: topTiles7,
    },
  ];

  const getAllProperty = async () => {
    // setLoading(true);
    // try {
    //   const res = await PropertyService.getRroomsPropertyService();
    //   if (res.status === 200) {
    //     setPropertyList(res.data.data);
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    getAllProperty();
  }, []);

  const goToPropertyListing = (e, item) => {
    e.preventDefault();
    dispatch(setSearch(item?.city));
    dispatch(
      setFilter({
        cityId: item.id,
        latlongFilter: { lat: null, long: null },
      })
    );
    dispatch(
      getRroomPropertyAction(`?order_direction=asc&query=${item?.city}`)
    );
    navigate("/hotal-listings");
  };

  const showPosition = (position) => {
    setLocation(position?.coords);
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Location is not supported by this browser.");
    }
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "first_visit" });
    getLocation();
  }, []);

  // useEffect(() => {
  //   setPropertyList(rRoomProperty);
  // }, [rRoomProperty]);

  const nearByClick = async () => {
    const { latitude, longitude } = locationData;
    dispatch(setFilter({ latlongFilter: { lat: latitude, long: longitude } }));
    navigate("/hotal-listings");
  };

  return (
    <div className="homePageMain">
      <LandingHeader />
      <div className="homeBanner">
        <div className="slider__wrapper w-100">
          <Slider {...settingsforslider} className="mainSlider">
            <div className="mainSliderImg">
              <img alt="" src={slider1} alt="safhdsiufh" />
            </div>
            <div className="mainSliderImg">
              <img alt="" src={slider2} alt="safhdsiufh" />
            </div>
            <div className="mainSliderImg">
              <img alt="" src={slider3} alt="safhdsiufh" />
            </div>
            {/* <div className="mainSliderImg">
              <img alt=""src={slider4} alt="safhdsiufh" />
            </div> */}
          </Slider>
        </div>
        <h1>
          <span> RROOMS - Unlock India's Hotel Diversity</span>
          <span>
            Find the perfect room for every occasion. Book budget, premium, and
            luxury stays.
          </span>
        </h1>
      </div>
      <SearchBar
        locationData={locationData}
        nearByClick={nearByClick}
        customclassName="homeSearch"
      />

      <div className="popular-section">
        <div className="container">
          <div className="explore-box">
            <div className="heading">
              <h4>Explore By Cities</h4>
            </div>
            <div className="show-explore-city">
              <div className="city-box">
                <div onClick={nearByClick} className="show-city">
                  <figure className="bg-black d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-location-arrow "
                      color="#fff"
                      size="2xl"
                    />
                  </figure>
                  <p>Nearby</p>
                </div>
                {showCity?.map((item, ind) => {
                  return (
                    <div
                      onClick={(e) => goToPropertyListing(e, item)}
                      className="show-city"
                      key={ind}
                    >
                      <figure>
                        <img alt="" src={item.img} alt="" />
                      </figure>
                      <p>{item?.city}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="explore-section showFeatures">
        <div className="container">
          <div className="explore-box">
            <div className="heading">
              {/* <h4>Explore unique places to stay</h4> */}
              {/* <p>Get most booked rooms from all over the India</p> */}
            </div>
            <div className="slider__wrapper upperSliderHomepage">
              <Slider {...settings}>
                {showImages?.map((data, key) => (
                  <div key={key}>
                    <a
                      href="#"
                      onClick={(e) => goToListing(e, data)}
                      className="sliderImg "
                    >
                      <figure>
                        <img alt="" src={data.img} alt="" />
                      </figure>
                      <div className="content">
                        <p>{data?.city}</p>
                        <span>{data?.name || ""}</span>
                      </div>
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* popular section */}
      {/* <div className="popular-section">
        <div className="container">
          <div className="explore-box">
            <div className="heading">
              <h4>Popular rooms</h4>
              <p className="text-black">
                Get most booked rooms from all over the India
              </p>
            </div>
            <div className="slider__wrapper">
              <Slider {...settingsOne}>
                {rroomData?.map((data, key) => (
                  <div key={key}>
                    <div
                      className="sliderImg"
                      onClick={() =>
                        navigate(`/hotel-details/${data.propertyId}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="wrapper">
                        <figure>
                          <img
                            src={
                              data.RoomImages[0]
                                ? `${baseImgUrl}${data.RoomImages[0].imageName}`
                                : popularSliderImg
                            }
                            alt=""
                          />
                        </figure>
                        <div className="content">
                          <p>{data?.PropertyMaster?.name ?? "N/A"}</p>

                          <p>
                            <img alt=""src={mapIcon} alt="" />
                            {data.PropertyMaster?.city}
                          </p>
                          <h3>
                            ₹ {data.regularPrice}{" "}
                            <span>
                              <s>
                                ₹
                                {data?.regularPrice +
                                  (data?.regularPrice * 20) / 100}
                              </s>
                            </span>
                          </h3>
                          <div className="button-wrap flex">
                            <Link
                              className="btnBlue"
                              to={`/hotel-details/${data.propertyId}`}
                            >
                              Book Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div> */}

      {/* exclusive section */}
      <div className="exclusive-section">
        <div className="container">
          <h2>Exclusive offer for you</h2>
          <div className="wrap flex">
            <div className="box">
              <h4>Don't think Book in a blink!</h4>
              <p>book your first stay & get 30% discount</p>
              <Link to="/hotal-listings">Book Now</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="appDownloadSection">
        <div className="container flex justify-content-between">
          <div className="first">
            <ul>
              <li>
                <FontAwesomeIcon icon="fa-solid fa-wallet" /> <span>10% </span>
                Additional OFF with Wallet Payment
              </li>
              <li>
                <FontAwesomeIcon icon="fa-solid fa-user-group" /> Refer Friend
                to <span> Earn Wallet Points</span>
              </li>
              <li>
                <FontAwesomeIcon icon="fa-solid fa-percent" /> App Exclusive
                Offers, <span> Upto 30% OFF</span>
              </li>
            </ul>
          </div>
          <div className="second">
            <h1>
              Download Our <br /> App Now
            </h1>
            <div className="mobileInput">
              <Controller
                name="mobileNumber"
                control={control}
                rules={{
                  required: "Enter Mobile Number*",
                  minLength: {
                    value: 10,
                    message: "Enter Correct Mobile Number*",
                  },
                  maxLength: {
                    value: 10,
                    message: "Enter Correct Mobile Number*",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Enter Correct Mobile Number*",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <input
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Mobile Number*"
                        minLength="10"
                        maxLength={10}
                      />
                      {error?.message && (
                        <div className="error-text">
                          <span style={{ fontSize: ".75rem" }}>
                            {error?.message}
                          </span>
                        </div>
                      )}
                    </>
                  );
                }}
              />
              <button onClick={handleSubmit(submitMobileNumber)}>
                Get Link
              </button>
            </div>
          </div>
          <div className="image">
            <ul>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.rrooms"
                  target="_black"
                >
                  <img alt="" src={GooglePlay} alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img alt="" src={ApplePlay} alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img alt="" width={135} src={ScanCode} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* choose section */}
      <div className="choose-section">
        <div className="heading flex align-items-center flex-column">
          <h2>Why you should choose us?</h2>
          <p>
            Imagine waking up to a place where luxury meets nature, where every
            moment feels like a cherished memory <br /> in the making. Welcome
            to RRooms, where dreams become reality and the extraordinary is
            simply our everyday.
          </p>
        </div>

        <section className="testimonialSeaction testimonialHomePage">
          <div className="customer-feedback">
            <div className="container text-center">
              <div className="row">
                <div className="col-12">
                  <div>
                    <h2 className="section-title">
                      What Clients Say About RROOMS
                    </h2>
                  </div>
                </div>
              </div>

              <Slider {...settings}>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; Booking through RROOMS made my travel experience
                    seamless and stress-free. The website's user-friendly
                    interface and wide range of hotel options exceeded my
                    expectations. Highly recommended!
                  </p>
                  <h4>Umesh Yadav</h4>
                  {/* <span>Web Developer at Circle Ltd.</span> */}
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; I absolutely love using this hotel room booking app!
                    It's so user-friendly, and I always find the perfect
                    accommodation for my trips. The variety of options is
                    fantastic!
                  </p>
                  <h4>Arpan Mishra</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; I travel frequently for work, and this app has become
                    my reliable companion. It saves me time and effort, and I
                    can trust that I'll get quality accommodations wherever I
                    go.
                  </p>
                  <h4>Vijay Sharma</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; The customer service is outstanding! I had a small
                    issue with a reservation, and the support team resolved it
                    promptly and efficiently. Great service!
                  </p>
                  <h4>Alok Singh</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; I appreciate the app's transparency. The photos and
                    reviews give me a clear picture of what to expect, and I've
                    never been disappointed with my bookings.
                  </p>
                  <h4>Akhilesh Chaurashia</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                {/* <div className="testimonial-item">
                  <figure>
                    <img alt=""src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; I love the variety of options RROOMS offers. Whether
                    it's a luxury hotel or a budget-friendly stay, I always find
                    the perfect match for my needs. Kudos to the RROOMS team!
                  </p>
                  <h4>Shivam Sharma</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div> */}
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; Rrooms.in has exclusive deals that I haven't found on
                    other platforms. It's my secret weapon for securing great
                    discounts on premium hotels.
                  </p>
                  <h4>Rohit Rao</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; I appreciate RROOMS'S commitment to sustainability.
                    It offers eco-friendly hotel options, allowing me to make
                    responsible choices during my travels.
                  </p>
                  <h4>Aditya Tiwari</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; The flexibility to modify bookings on RROOMS is a
                    huge relief. Life is unpredictable, and knowing I can adjust
                    my plans without hassle is priceless.
                  </p>
                  <h4>Puneet Shukla</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; Discovering boutique hotels with charm is easy on
                    RROOMS. The curated selection caters to those seeking unique
                    and memorable stays.
                  </p>
                  <h4>Manish Gupta</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                <div className="testimonial-item">
                  <figure>
                    <img alt="" src={userImg} alt="" />
                  </figure>
                  <p>
                    <strong>
                      <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                    </strong>{" "}
                    &nbsp; The ease of canceling and rebooking on RROOMS saved
                    us when our plans suddenly changed. The flexibility it
                    provides is unmatched.
                  </p>
                  <h4>Kuldeep Dwivedi</h4>
                  <Rating style={{ maxWidth: 80 }} readOnly />
                </div>
                {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item, ind) => (
                  <div className="testimonial-item" key={ind}>
                    <figure>
                      <img alt=""src={userImg} alt="" />
                    </figure>
                    <p>
                      <strong>
                        <FontAwesomeIcon icon="fa-solid fa-quote-left" />
                      </strong>{" "}
                      &nbsp; hasellus vitae suscipit justo. Mauris pharetra
                      feugiat ante id lacinia. Etiam faucibus mauris id tempor
                      egestas. Duis luctus turpis at accumsan tincidunt.
                      Phasellus risus risus, volutpat vel tellus ac, tincidunt
                      fringilla massa. Etiam hendrerit dolor eget rutrum.
                    </p>
                    <h4>PAULA WILSON</h4>
                    <span>Web Developer at Circle Ltd.</span>
                    <Rating style={{ maxWidth: 80 }} readOnly />
                  </div>
                ))} */}
              </Slider>
            </div>
          </div>
        </section>

        <div className="wrap">
          <div className="content">
            <ul>
              <li className="d-flex">
                {/* <span>01</span> */}
                <h6>Exceptional Location</h6>
              </li>
              <li className="d-flex">
                {/* <span>02</span> */}
                <h6>Offordable Accommodations</h6>
              </li>
              <li className="d-flex">
                {/* <span>05</span> */}
                <h6>Amenities and Facilities</h6>
              </li>
              <li className="d-flex">
                {/* <span>06</span> */}
                <h6>Our Commitment to Cleanliness and Safety</h6>
              </li>
              <li className="d-flex">
                {/* <span>07</span> */}
                <h6>Exclusive Packages and Offers</h6>
              </li>
            </ul>
          </div>
          <figure>
            <img alt="" src={ChooseSectionImg} alt="" />
          </figure>
        </div>
      </div>
      {/* explore section start here */}
      {/* <div className="explore-section">
        <div className="container">
          <div className="explore-box">
            <div className="heading">
              <h4>Explore unique places to stay</h4>
              <p>Get most booked rooms from all over the India</p>
            </div>
            <div className="slider__wrapper">
              {loading ? (
                <div className="shimmersListLanding d-flex gap-2">
                  <div className="shimmerEffect"></div>
                  <div className="shimmerEffect"></div>
                  <div className="shimmerEffect"></div>
                  <div className="shimmerEffect"></div>
                </div>
              ) : propertyList?.length > 0 ? (
                <Slider
                  {...settings2}
                  slidesToShow={propertyList?.length > 3 ? 4 : 3}
                >
                  {propertyList?.map((data, key) => (
                    <div key={key}>
                      <Link
                        to={`/hotel-details/${data.id}`}
                        className="sliderImg"
                      >
                        <figure>
                          <img
                            src={
                              data.PropertyImages[0]
                                ? `${baseImgUrl}${data.PropertyImages[0].image}`
                                : sliderImgOnly
                            }
                            alt=""
                          />
                        </figure>
                        <div className="content">
                          <p>{data?.city?.name}</p>
                          <span>{data?.name}</span>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="flex justify-content-center">
                  <img
                    src={noProperty}
                    className="w-25 rounded-5"
                    style={{ border: "1px solid #00000024" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}

      <FrontFooter />
    </div>
  );
};

export default LandingPage;
