export const ROOM_STATUS = {
  READY: "ready",
  DIRTY: "dirty",
  OCCUPIED: "occupied",
  BLOCKED: "blocked",
};

export const ROOM_CHECKIN = {
  checkIn: 2,
  pendingBooking: 0,
  confirmBooking: 1,
  checkedOut: 3,
  cancelledBooking: 4,
  noShow: 5,
  callNotPicked: 6,
};

export const ROOM_STATUS_BG_COLOR = {
  [ROOM_STATUS.READY]: "#E8FFF3",
  [ROOM_STATUS.DIRTY]: "#FFF8DD",
  [ROOM_STATUS.OCCUPIED]: "#FFF5F8",
  [ROOM_STATUS.BLOCKED]: "#ebeae8",
};

export const ROOM_STATUS_COLOR = {
  [ROOM_STATUS.READY]: "#47BE7D",
  [ROOM_STATUS.DIRTY]: "#E9B500",
  [ROOM_STATUS.OCCUPIED]: "#D9214E",
  [ROOM_STATUS.BLOCKED]: "#ebeae8",
};

export const PAYMENT_MODE = {
  ONLINE: 1,
  CASH: 0,
  ONLINE_AND_CASH: 3,
};

export const PAYMENT_MODE_TEXT = {
  [PAYMENT_MODE.ONLINE]: "Online",
  [PAYMENT_MODE.CASH]: "Pay at hotel",
  [PAYMENT_MODE.ONLINE_AND_CASH]: "Partial Payment",
};

export const USER_ROLE_TEXT = [
  "User Roles",
  "Rrooms Super Admin",
  "Rrooms Admin",
  "Property Super Admin",
  "Property Admin",
  "Manager",
  "Captain",
  "Executive",
];
export const USER_DEG_TEXT = [
  "User Designation",
  "MD",
  "GM",
  "AGM",
  "FOM",
  "SDM",
  "SDC",
  "HK Supervisor",
  "HK Executive",
  "Accounts Manager",
  "Accounts Executive",
  "Store Manager (Kitchen)",
  "Store Incharge",
  "RSOT",
];
export const USER_DEG_TEXT_WITH_ANUM = [
  { name: "MD", value: 1 },
  { name: "GM", value: 2 },
  { name: "AGM", value: 3 },
  { name: "FOM", value: 4 },
  { name: "SDM", value: 5 },
  { name: "SDC", value: 6 },
  { name: "RSOT", value: 13 },
  { name: "HK Supervisor", value: 7 },
  { name: "HK Executive", value: 8 },
  { name: "Accounts Manager", value: 9 },
  { name: "Accounts Executive", value: 10 },
  { name: "Store Manager (Kitchen)", value: 11 },
  { name: "Store Incharge", value: 12 },
];
export const USER_DEG_ENUM = {
  MD: 1,
  GM: 2,
  AGM: 3,
  FOM: 4,
  SDM: 5,
  SDC: 6,
  HKSupervisor: 7,
  HKExecutive: 8,
  AccountsManager: 9,
  AccountsExecutive: 10,
  StoreManager: 11,
  StoreIncharge: 12,
  RSOT: 13,
};
export const USER_ROLE_ENUM = {
  rroomsSuperAdmin: 1,
  rroomsAdmin: 2,
  propertySuperAdmin: 3,
  propertyAdmin: 4,
  manager: 5,
  captain: 6,
  executive: 7,
};

export const TRAVELLER_CHOICE_TEXT = {
  short: 1,
  Corporate: 2,
  FamilyVacation: 3,
  GuaranteedCheckIn: 4,
  GroupCheckIn: 5,
  SoloTraveler: 6,
};

export const PAYMENT_STATUS = {
  PENDING: 1,
  PARTIAL_PAYMENT: 2,
  FULL_PAYMENT: 3,
};

export const PAYMENT_STATUS_TEXT = {
  [PAYMENT_STATUS.PENDING]: "Confirmed",
  [PAYMENT_STATUS.PARTIAL_PAYMENT]: "Confirmed",
  [PAYMENT_STATUS.FULL_PAYMENT]: "Confirmed",
};

export const DEFAULT_QUERY_KEYS = {
  staleTime: 1800000,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  refetchOnMount: false,
  retry: 0,
};

export const BOOKING_TEXT_SHOW = [
  "Pending",
  "Upcoming",
  "Checked- In",
  "Checked-Out",
  "Cancelled",
  "No-Show",
  "Call Not picked",
  "Rejected",
  "Pending",
];

export const propertyPolicy = [
  "Unmarried Couples above 18 years allowed.",
  "Unmarried Couples above 18 years not allowed.",
  "Local ID accepted.",
  "Local ID not accepted.",
  "No cancellation charges before 24hrs of hotel check-in And within 15 min of same day.",
];

export const paymentModes = ["Select Source", "Bank Transfer", "Cheque", "UPI"];
export const paymentMainModes = ["Offline", "Online"];
export const paymentInvoiceStatus = [
  { id: 0, name: "Pending" },
  { id: 1, name: "Paid" },
  { id: 2, name: "In Review" },
];

export const limitItems = [10, 20, 30, 50, 100, 200];
export const limitItemsBooking = [25, 10, 50, 100, 200];

export const ORDER_STATUS = {
  KOT: 0,
  PROCESS: 1,
  DELIVERED: 2,
  COMPLETE: 3,
  CANCELED: 4,
  ALL: 5,
  NCKOT: 6,
};
export const statusfood = [
  "KOT",
  "In-process",
  "Delivered ",
  "Complete",
  "Cancelled",
];

export const renderTags = [
  { id: 1, name: "NC KOT" },
  { id: 2, name: "Staff" },
  // { id: 3, name: "Table" },
  // { id: 4, name: "Direct" },
];

export const statusfoodColor = [
  "#03a5fc",
  "#094f12",
  "#4f3b09",
  "#b58307",
  "red",
];
export const PAYMENT_MODES_TEXT = [
  "N/A",
  "N/A",
  "CASH",
  "CARD",
  "FOC",
  "UPI",
  "PAYLATER",
  "PREPAID",
];

export const ResturantGuestType = [
  "Select Guest Type",
  "MD References",
  "Rooms",
  "Online",
  "Corporate",
  "Walk-In",
  "Staff References",
];

export const banquetPaymentMode = [
  "Cash",
  "Cheque/DD",
  "Cards",
  "IMPS",
  "Bill to Company",
  "Credit to party",
];
export const banquetStatus = [
  {
    id: 0,
    name: "In-Process",
  },
  {
    id: 1,
    name: "Completed",
  },
  {
    id: 2,
    name: "Cancelled",
  },
];
export const resturantStatus = [
  {
    id: 0,
    name: "In-Process",
    color: "primary",
  },
  {
    id: 1,
    name: "Confirm",
    color: "green",
  },
  {
    id: 2,
    name: "In-Kitchen",
    color: "orange",
  },
  {
    id: 3,
    name: "Completed",
    color: "#aa0eb3",
  },
  {
    id: 4,
    name: "Cancelled",
    color: "red",
  },
];
export const PAYMENT_MODES = {
  PAYLATER: 6,
  CASH: 2,
  CARD: 3,
  // FOC: 4,
  UPI: 5,
  PAYLATER: 6,
  PREPAID: 7,
};
