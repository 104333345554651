import React, { useState } from "react";
import Layout from "../../layout";
import { Col, Container, Form, Row } from "react-bootstrap";
import moment from "moment";

import ReactDatePicker from "react-datepicker";

const TransactionReport = () => {
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(new Date());
  const [onOpenPicker, setOnOpenPicker] = useState(false);
  const submitFilter = async (e) => {
    e.preventDefault();
    const filterState = `fromDate=${moment(fromdate).format(
      "YYYY-MM-DD"
    )}&toDate=${moment(todate).format("YYYY-MM-DD")}`;

    window.open(
      `https://api.rrooms.in/api/kpi/get-transaction-report?${filterState}`
    );
  };

  return (
    <Layout title="Transaction Report">
      <Container className="flex justify-content-center align-items-center">
        <Row>
          <Form className="bg-white rounded-2 p-5 mt-3">
            <Container>
              <h6 className="text-black">
                Select Date for Transaction Report :
              </h6>
              <Row className="addBookingRow">
                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">From Date: </label>
                  <ReactDatePicker
                    className="form-control"
                    customclassName="form-control"
                    onChange={(date) => {
                      setFromDate(date);
                      setOnOpenPicker(true);
                    }}
                    selected={new Date(fromdate)}
                    dateFormat="yyyy/MM/dd"
                  />
                </Col>

                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">To Date: </label>
                  <ReactDatePicker
                    open={onOpenPicker}
                    onCalendarClose={() => setOnOpenPicker(false)}
                    onClickOutside={() => setOnOpenPicker(false)}
                    className="form-control"
                    customclassName="form-control"
                    onChange={(date) => {
                      setToDate(date);
                      setOnOpenPicker(false);
                    }}
                    selected={new Date(todate)}
                    dateFormat="yyyy/MM/dd"
                  />
                </Col>

                <Col className="flex align-items-center">
                  <button
                    onClick={(e) => submitFilter(e)}
                    className="btn-success btn"
                  >
                    Export Excel
                  </button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Row>
      </Container>
    </Layout>
  );
};

export default TransactionReport;
