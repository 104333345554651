import Layout from "layout";
import useResturantTable from "../../controllers/ResturantTable";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { InputField } from "components/InputField/InputField";
import { ErrorMessage } from "@hookform/error-message";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const ResturantTable = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "restaurant_table" });
  }, []);

  const {
    tableData,
    tableRef,
    STORY_HEADERS,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
  } = useResturantTable();

  return (
    <Layout
      title="Restaurant Table Management"
      openHandler={openAddModalHanlder}
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <Table className="food-report" ref={tableRef}>
            <TableHeader />
            <TableBody />
          </Table>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
      <Modal show={openAddModal} onHide={hideAddModalHanlder} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit " : "Create "} Restaurant Table
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="tableNo"
                    text="Table Number"
                    type="number"
                    autoFocus={true}
                    placeholder="Enter Table Number"
                    field={"tableNo"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.tableNo?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"tableNo"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="occupancy"
                    text="Table Occupancy"
                    type="number"
                    placeholder="Enter Table Occupancy"
                    field={"occupancy"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.occupancy?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"occupancy"} />
                      </span>
                    </div>
                  )}
                </Col>

                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default ResturantTable;
