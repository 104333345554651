import Home from "./home";
import Login from "./login";
import Register from "./signup";
import Property from "./property";
import Propertydetails from "./propertydetails";
import Landingpage from "./landingpage";
import Hotallistings from "./hotallistings";
import Bookhotel from "./bookhotel";
import Payment from "./payment";
import Hotaldetails from "./hotaldetails";
import AdminLogin from "./adminLogin";
import RRoomsUsers from "./users/rroomUsers";
import UserProfile from "./userprofile";
import BookingConfirm from "./bookingconfirm";
import BookingCancel from "./bookingcancel";
import PropertyCategory from "./propertyCategory";
import RoomCategory from "./roomCategory";
import PropertyUsers from "./users/propertyUsers";
import Amenities from "./amenities";
import Customer from "./users/customer";
import CustomerEnquiry from "./Enquiry/Customer";
import CreateInventory from "./inventory";
import CreateInventorySupplier from "./inventorySupplier";
import CreateInventoryItem from "./inventoryItem";
import InventoryCategory from "./inventoryCategory";
import FoodCategory from "./foodCategory";
import FoodsItems from "./foodItems";
import FoodsOrders from "./foodOrders";
import CouponPage from "./coupon";
import CustomerReviews from "./customerReviews";
import TaxSettings from "./taxSettings";
import RoomPriceSetting from "./roomPriceSetting/RoomPriceProperty";
import RoomPrice from "./roomPriceSetting";
import MenuCard from "./menuCard";
import MenuCategory from "./menucategory";
import LaundryProvider from "./laundryProvider";
import LaundryService from "./laundryService";
import Laundry from "./laundry";
import UserLogins from "./users/userLogins";
import SearchResult from "./SearchResults";
import Invoice from "./Invoice";
import CancellationRefundPolicy from "./cancellationRefundPolicy";
import TermsAndConditions from "./termsAndConditions";
import PrivacyPolicy from "./FooterPages/privacyPolicy";
import Contact from "./contact";
import BreakFast from "./breakfast";
import FoodInvoice from "./foodinvoice";
import NcTypeSetting from "./nctypesetting";
import About from "./FooterPages/About";
import Career from "./FooterPages/Career";
import HelpCenter from "./FooterPages/HelpCenter";
import Blog from "./FooterPages/Blog";
import HowItWorks from "./FooterPages/HowItWorks";
import Loyalty from "./FooterPages/Loyalty";
import Community from "./FooterPages/Community";
import PromotionEvents from "./FooterPages/PromotionEvents";
import AffiliateProgram from "./FooterPages/AffiliateProgram";
import PartnershipProgram from "./FooterPages/PartnershipPrograms";
import FAQ from "./FooterPages/FAQ";
import BookingReport from "./bookingReport/BookingReport";
import PaymentReport from "./bookingReport/PaymentReport";
import UserAgreement from "./FooterPages/UserAgreement";
import ManageRoles from "./manageroles";
import FoodReport from "./bookingReport/FoodReport";
import FoodItemsReport from "./bookingReport/FoodItemsReport";
import CityLocality from "./cityLocality/CityLocality";
import HotelSummery from "./HotelFinance/Summary";
import InventoryReport from "./bookingReport/inventoryReport";
import InvoiceDownload from "./HotelFinance/InvoiceDownload";
import Sale from "./HotelFinance/Sale";
import InvoiceFinance from "./HotelFinance/InvoiceFinance";
import InvoicePayment from "./HotelFinance/InvoicePayment";
import ModuleConfiguration from "./users/ModuleConfiguration";
import ResturantOrders from "./foodOrders/ResturantOrders";
import ResturantMenu from "./foodOrders/ResturantMenu";
import ResturantTable from "./foodOrders/ResturantTable";
import BunquetFunction from "./bunquet/BunquetFunction";
import BunquetBookings from "./bunquet/BunquetBookings";
import BunquetInvoice from "./bunquet/BunquetInvoice";
import BunquetPayment from "./bunquet/BunquetPayment";
import AgreementProperty from "./signup/AgreementProperty";
import GuestDetails from "./Dashboard/guests";
import BanquetMenu from "./bunquet/BanquetMenu";
import BunquetEnquiry from "./bunquet/BanquetEnquiry";
import AddBunquetBookings from "./bunquet/AddBunquetBookings";
import ModuleConfigurationAdmin from "./users/ModuleConfigurationAdmin";
import BunquetBookingDetails from "./bunquet/BunquetBookingDetails";
import Expenses from "./accounts/Expenses";
import RevenueReport from "./accounts/RevenueReport";
import TransactionReport from "./bookingReport/TransactionReport";
import RatingReport from "./bookingReport/RatingReport";
import ResturantPayment from "./foodOrders/ResturantPayment";

export default {
  Home,
  Login,
  Register,
  Property,
  Propertydetails,
  Landingpage,
  Hotallistings,
  Bookhotel,
  Payment,
  Hotaldetails,
  AdminLogin,
  RRoomsUsers,
  UserProfile,
  BookingConfirm,
  BookingCancel,
  PropertyCategory,
  RoomCategory,
  PropertyUsers,
  Amenities,
  Customer,
  CustomerEnquiry,
  CreateInventory,
  CreateInventorySupplier,
  CreateInventoryItem,
  InventoryCategory,
  FoodCategory,
  FoodsItems,
  FoodsOrders,
  CouponPage,
  CustomerReviews,
  TaxSettings,
  RoomPriceSetting,
  MenuCard,
  LaundryProvider,
  LaundryService,
  Laundry,
  UserLogins,
  SearchResult,
  Invoice,
  RoomPrice,
  CancellationRefundPolicy,
  TermsAndConditions,
  PrivacyPolicy,
  Contact,
  MenuCategory,
  BreakFast,
  FoodInvoice,
  NcTypeSetting,
  About,
  Career,
  HelpCenter,
  Blog,
  HowItWorks,
  Loyalty,
  Community,
  PromotionEvents,
  AffiliateProgram,
  PartnershipProgram,
  FAQ,
  BookingReport,
  TransactionReport,
  RatingReport,
  PaymentReport,
  UserAgreement,
  ManageRoles,
  FoodReport,
  FoodItemsReport,
  CityLocality,
  HotelSummery,
  InventoryReport,
  InvoiceDownload,
  Sale,
  InvoiceFinance,
  InvoicePayment,
  ModuleConfiguration,
  ResturantOrders,
  ResturantMenu,
  ResturantTable,
  BunquetFunction,
  BunquetBookings,
  BunquetInvoice,
  BunquetPayment,
  AgreementProperty,
  GuestDetails,
  BanquetMenu,
  BunquetEnquiry,
  AddBunquetBookings,
  ModuleConfigurationAdmin,
  BunquetBookingDetails,
  Expenses,
  RevenueReport,
  ResturantPayment,
};
