import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Services from "../../API/services";
import { PAYMENT_MODE_TEXT, limitItems } from "../../constant/constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { daysDiffernce } from "../../utils/utils";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import ReactGA from "react-ga4";

const MealPlanText = ["EP", "CP", "AP", "MAP"];

const BookingReport = () => {
  const [booking, setBookings] = useState([]);
  const [filterBooking, setFilterBooking] = useState([]);
  const { userDetail } = useSelector(authenticationSeletor);
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(new Date());
  const [onOpenPicker, setOnOpenPicker] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(0);
  const [sourceStatus, setSourceStatus] = useState(0);

  const [pageCount, setPageCount] = useState(0);
  const [exportFilter, setExportFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    source: null,
    bookingStatus: null,
  });
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const getData = async () => {
    try {
      const query = `page=${page}&size=${limit}`;
      const res = await Services.globalService.getBookingPropertyIdService(
        userDetail?.propertyId,
        query
      );
      if (res.status === 200) {
        setPageCount(
          res.data.totalItems > limit
            ? Math.round(res.data.totalItems / limit) + 1
            : 0
        );
        setBookings(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "booking_reporting" });
    if (userDetail?.logintype != "rroomUser") getData();
  }, [userDetail, page, limit]);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "bookingDetails",
    sheet: "bookingDetails",
  });

  const exportHandler = async () => {
    const status =
      bookingStatus != "0" ? `&bookingStatus=${bookingStatus}` : "";
    const source = sourceStatus != "0" ? `&source=${sourceStatus}` : "";
    window.open(
      `https://api.rrooms.in/api/kpi/booking-report?fromDate=${moment(
        exportFilter.startDate
      ).format("YYYY-MM-DD")}&toDate=${moment(exportFilter.endDate).format(
        "YYYY-MM-DD"
      )}&propertyId=${userDetail?.propertyId}${status}${source}`,
      "_blank"
    );
    // try {
    //   const query = `?fromDate=${moment(exportFilter.startDate).format(
    //     "YYYY-MM-DD"
    //   )}&toDate=${moment(exportFilter.endDate).format(
    //     "YYYY-MM-DD"
    //   )}&propertyId=${userDetail?.propertyId}`;
    //   const res = await Services.globalService.exportBookingHandler(query);
    //   if (res.status === 200) {
    //     window.location.href = res.data;
    //   }
    // } catch (error) {}
  };
  useEffect(() => {
    setFilterBooking(booking);
  }, [booking]);

  const [filterDate, setFilterDate] = useState(new Date());

  const dateFilter = () => {
    const resultsData = booking.filter((order) => {
      return (
        new Date(order?.createdAt).toJSON().slice(0, 25) >=
        new Date(filterDate).toJSON().slice(0, 25)
      );
    });
    setFilterBooking(resultsData);
  };
  const submitFilter = async (e) => {
    e.preventDefault();
    const status =
      bookingStatus != "0" ? `&bookingStatus=${bookingStatus}` : "";
    const source = sourceStatus != "0" ? `&source=${sourceStatus}` : "";
    const filterState = `fromDate=${moment(fromdate).format(
      "YYYY-MM-DD"
    )}&toDate=${moment(todate).format("YYYY-MM-DD")}${status}${source}`;
    try {
      window.open(
        `https://api.rrooms.in/api/kpi/booking-report?${filterState}`
      );
    } catch (error) {}
  };
  useEffect(() => {
    dateFilter();
  }, [filterDate]);

  const searchfilter = (text) => {
    if (text === "") return booking;
    const resultsData = booking.filter((order) => {
      return (
        order?.PropertyMaster?.propertyCode +
        order?.bookingCode +
        order?.source +
        order?.PropertyMaster?.name +
        order?.otherPersonName +
        order?.RroomCategory?.name +
        MealPlanText[order?.breakFast]
      )
        ?.toString()
        .toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilterBooking(resultsData);
  };

  const renderExtraFilters = (
    <>
      <label className="text-black m-0 fs-6">Status: </label>

      <select
        style={{ minWidth: "120px" }}
        onChange={(e) => setBookingStatus(e.target.value)}
        className="form-control"
      >
        <option value="0" selected>
          All
        </option>
        <option value="1">Upcoming</option>
        <option value="2">In-House</option>
        <option value="3">Completed</option>
        <option value="4">Cancelled</option>
        <option value="5">No-Show</option>
      </select>
      <label className="text-black m-0 fs-6">Source: </label>
      <select
        style={{ minWidth: "120px" }}
        onChange={(e) => setSourceStatus(e.target.value)}
        className="form-control"
      >
        <option value="0" selected>
          All
        </option>
        <option value="RRooms">RRooms</option>
        <option value="Walk-In">Walk In</option>
        <option value="OTA">OTA</option>
        <option value="TA">TA</option>
      </select>
    </>
  );
  return userDetail?.logintype == "rroomUser" ? (
    <Layout title="Booking Report">
      <Container className="flex justify-content-center align-items-center">
        <Row>
          <Form className="bg-white rounded-2 p-5">
            <Container>
              <h6 className="text-black">Select Date for Booking Report :</h6>
              <Row className="addBookingRow">
                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">From Date: </label>
                  <ReactDatePicker
                    className="form-control"
                    customclassName="form-control"
                    onChange={(date) => {
                      setFromDate(date);
                      setOnOpenPicker(true);
                    }}
                    selected={new Date(fromdate)}
                    dateFormat="yyyy/MM/dd"
                  />
                </Col>

                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">To Date: </label>
                  <ReactDatePicker
                    open={onOpenPicker}
                    onCalendarClose={() => setOnOpenPicker(false)}
                    onClickOutside={() => setOnOpenPicker(false)}
                    className="form-control"
                    customclassName="form-control"
                    onChange={(date) => {
                      setToDate(date);
                      setOnOpenPicker(false);
                    }}
                    selected={new Date(todate)}
                    dateFormat="yyyy/MM/dd"
                  />
                </Col>
                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">Status </label>
                  <select
                    onChange={(e) => setBookingStatus(e.target.value)}
                    className="form-control"
                  >
                    <option value="0" selected>
                      All
                    </option>
                    <option value="1">Upcoming</option>
                    <option value="2">In-House</option>
                    <option value="3">Completed</option>
                    <option value="4">Cancelled</option>
                    <option value="5">No-Show</option>
                  </select>
                </Col>
                <Col xs={6} className="form-group gap-2 d-flex flex-column">
                  <label className="text-black m-0 fs-6">Source </label>
                  <select
                    onChange={(e) => setSourceStatus(e.target.value)}
                    className="form-control"
                  >
                    <option value="0" selected>
                      All
                    </option>
                    <option value="RRooms">RRooms</option>
                    <option value="Walk-In">Walk In</option>
                    <option value="OTA">OTA</option>
                    <option value="TA">TA</option>
                  </select>
                </Col>
                <Col className="flex align-items-center">
                  <button
                    onClick={(e) => submitFilter(e)}
                    className="btn-success btn"
                  >
                    Export Excel
                  </button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Row>
      </Container>
    </Layout>
  ) : (
    <Layout
      title="Booking Report"
      extraBtnHandler={exportHandler}
      extraButton="Export Table"
      searchfilter={searchfilter}
      setFilterDate={setFilterDate}
      // filterDate={filterDate}
      setFromDate={(date) =>
        setExportFilter({ ...exportFilter, startDate: date })
      }
      setToDate={(date) => setExportFilter({ ...exportFilter, endDate: date })}
      dateFilter={exportFilter}
      searchType={"date"}
      fromDate={exportFilter.startDate}
      toDate={exportFilter.endDate}
      otherFilters={renderExtraFilters}
    >
      <div className="flex space-between pr-3">
        <div>
          <label
            className="text-black m-0 fs-6"
            style={{ paddingLeft: "15px" }}
          >
            Search:{"\u00A0"}
          </label>
          <input type="text" onChange={(e) => searchfilter(e.target.value)} />
        </div>
        <select
          className="selectTableItemsSelect"
          onChange={({ target }) => setLimit(target.value)}
        >
          {limitItems.map((item, ind) => (
            <option value={item} key={ind}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="scrollTable paymentreport">
        <Table className="common-table new_table" bordered ref={tableRef}>
          <thead>
            <tr>
              <th>Property Code</th>
              <th>Property Name</th>
              <th>Guest Name</th>
              <th>Booking ID</th>
              <th>Source</th>
              <th>Check-In</th>
              <th>Check-Out</th>
              <th>Room Count</th>
              <th>Room Night</th>
              <th>Booking Amount</th>
              <th>Meal Plan</th>
              <th>Payment Mode</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filterBooking?.map((row, ind) => {
              return (
                <tr key={ind}>
                  <td>{row?.PropertyMaster?.propertyCode ?? "N/A"}</td>
                  <td>{row?.PropertyMaster?.name ?? "N/A"}</td>
                  <td>{(row?.otherPersonName || row?.User?.name) ?? "N/A"}</td>
                  <td className="text-uppercase">
                    {/* #{row?.otaBookingId ? row?.otaBookingId : row?.bookingCode} */}
                    <Link to={`/dashboard/all-bookings/${row?.id}`}>
                      #
                      {row?.otaBookingId ? row?.otaBookingId : row?.bookingCode}
                    </Link>
                  </td>
                  <td>{row.source}</td>
                  <td>{moment(row.fromDate).format("DD-MM-YYYY")}</td>
                  <td>{moment(row.toDate).format("DD-MM-YYYY")}</td>
                  <td>{row?.noOfRooms}</td>
                  <td>{daysDiffernce(row?.fromDate, row?.toDate)}</td>
                  <td>{row?.bookingAmout}</td>
                  <td>
                    <small>{MealPlanText[row.breakFast]}</small>
                  </td>
                  <td>
                    <small>{PAYMENT_MODE_TEXT[row?.paymentMode]}</small>
                  </td>
                  <td>{moment(row.createdAt).format("DD-MM-YYYY")}</td>
                  <td>{bookingStatus[row.bookingStatus]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={({ selected }) => setPage(selected)}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className="paginationParent"
        previousLabel="<"
        activeClassName="activePaginationBtn"
        disabledClassName="disablePaginationBtn"
        renderOnZeroPageCount={null}
      />
    </Layout>
  );
};

export default BookingReport;
