import React, { useRef, useState } from "react";
import Layout from "../../layout";
import {
  Col,
  Container,
  Modal,
  Row,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import { useDownloadExcel } from "react-export-table-to-excel";
import Services from "../../API/services";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import validation from "./validation";
import { InputField } from "../../components/InputField/InputField";
import { ErrorMessage } from "@hookform/error-message";
import { Imagesdata } from "../../assets/images/imageData";
import { toast } from "react-toastify";

function ManageRoles() {
  const { AlertIcon } = Imagesdata;
  const [show, setShow] = useState(false);
  const tableRef = useRef(null);
  const [showAllRoles, setShowAllRoles] = useState([]);

  const [edit, setEdit] = useState(false);
  const [selectData, setSelectData] = useState({});

  const [actions, setActions] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const getDataHandler = async () => {
    const res = await Services.auth.getAllRoles();
    if (res.status === 200) {
      setShowAllRoles(res.data.data);
    }
  };

  const onHide = () => {
    setShow(false);
    setActions({
      canAdd: false,
      canDelete: false,
      canEdit: false,
    });
    reset();
    setEdit(false);
  };

  const editHandler = (data) => {
    setShow(true);
    setValue("role", data.roleName);
    setActions({
      canAdd: data.canView,
      canDelete: data.canDelete,
      canEdit: data.canEdit,
    });
    setSelectData(data);
    setEdit(true);
  };
  const deleteRole = async (id) => {
    try {
      if (window.confirm("Are You Sure Want to Delete!")) {
        const res = await Services.auth.deleteRole(id);
        if (res.status === 200) {
          getDataHandler();
          toast.success(`Role Deleted Successfully`, {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {}
  };

  const onSubmit = async (payload) => {
    const realPayload = {
      roleName: payload.role,
      status: 1,
      canEdit: actions.canEdit,
      canDelete: actions.canDelete,
      canView: actions.canAdd,
    };
    let res = {};
    let msg = "";
    try {
      if (edit) {
        res = await Services.auth.updateRole(selectData.id, realPayload);
        msg = "Updated";
      } else {
        res = await Services.auth.createRole(realPayload);
        msg = "Created";
      }
      if (res.status === 200) {
        onHide();
        getDataHandler();
        toast.success(`Role ${msg} Successfully`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDataHandler();
  }, []);
  return (
    <Layout
      title="User Roles"
      openHandler={() => setShow(true)}
      extraButton="Export Table"
      extraBtnHandler={onDownload}
    >
      <Table
        striped
        bordered
        hover
        className="common-table text-start"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Role</th>
            <th>Add</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {showAllRoles?.length > 0 ? (
            showAllRoles.map((data, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{data.roleName}</td>
                <td>
                  {data.canView ? (
                    <span className="text-success">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-check"
                        color="green"
                      />{" "}
                      Yes
                    </span>
                  ) : (
                    <span className="text-danger">
                      <FontAwesomeIcon icon="fa-solid fa-ban" color="red" /> No
                    </span>
                  )}
                </td>

                <td>
                  {data.canEdit ? (
                    <span className="text-success">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-check"
                        color="green"
                      />{" "}
                      Yes
                    </span>
                  ) : (
                    <span className="text-danger">
                      <FontAwesomeIcon icon="fa-solid fa-ban" color="red" /> No
                    </span>
                  )}
                </td>
                <td>
                  {data.canDelete ? (
                    <span className="text-success">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-check"
                        color="green"
                      />{" "}
                      Yes
                    </span>
                  ) : (
                    <span className="text-danger">
                      <FontAwesomeIcon icon="fa-solid fa-ban" color="red" /> No
                    </span>
                  )}
                </td>
                <td>
                  <Button
                    variant="primary"
                    className="btn-sm mr-2"
                    onClick={() => editHandler(data)}
                  >
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                  </Button>
                  {/* <Button
                    variant="danger"
                    className="btn-sm mr-2"
                    onClick={() => deleteRole(data.id)}
                  >
                    <i className="fa fa-solid fa-trash" aria-hidden="true"></i>
                  </Button> */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <div
                  className="flex flex-column align-center justify-content-center full-width"
                  style={{ height: "10rem" }}
                >
                  <FontAwesomeIcon
                    className="mb-2"
                    icon="fa-solid fa-ban"
                    beat
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span>No Data</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={onHide} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Create Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    notnull
                    htmlFor="Role"
                    text="Role"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter Role"
                    field={"role"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.role?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"role"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12}>
                  <div className="form-group full">
                    <label>Rights</label>
                    <ul className="flex customecheckbox">
                      <li className="amns">
                        <input
                          type="checkbox"
                          id="Add"
                          checked={actions.canAdd}
                          onChange={() =>
                            setActions({ ...actions, canAdd: !actions.canAdd })
                          }
                        />
                        <label
                          className={`checkLable ${
                            actions.canAdd ? "activeLable" : ""
                          }`}
                          htmlFor="Add"
                        >
                          Add
                        </label>
                      </li>
                      <li className="amns">
                        <input
                          type="checkbox"
                          id="Edit"
                          checked={actions.canEdit}
                          onChange={() =>
                            setActions({
                              ...actions,
                              canEdit: !actions.canEdit,
                            })
                          }
                        />
                        <label
                          className={`checkLable ${
                            actions.canEdit ? "activeLable" : ""
                          }`}
                          htmlFor="Edit"
                        >
                          Edit
                        </label>
                      </li>
                      <li className="amns">
                        <input
                          type="checkbox"
                          id="View"
                          checked={actions.canDelete}
                          onChange={() =>
                            setActions({
                              ...actions,
                              canDelete: !actions.canDelete,
                            })
                          }
                        />
                        <label
                          className={`checkLable ${
                            actions.canDelete ? "activeLable" : ""
                          }`}
                          htmlFor="View"
                        >
                          Delete
                        </label>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={3}>
                  <button className="btn btn-primary">Submit</button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default ManageRoles;
