import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import validation from "../taxSettings/validation";
import { Button, Form, Row, Col } from "react-bootstrap";
import { InputField } from "../../components/InputField/InputField";
import { toast } from "react-toastify";
import Services from "../../API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import ReactGA from "react-ga4";

const NcTypeSetting = () => {
  const [roomPrice, setRoomPrice] = useState({});

  const { userDetail } = useSelector(authenticationSeletor);

  const getDataHandler = async () => {
    try {
      const res = await Services.property.getNcTypeByProperty(
        userDetail?.propertyId
      );

      if (res.status === 200) {
        setRoomPrice(res.data.data[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "nc_type_setting" });
    getDataHandler();
    window.scrollTo(0, 0);
  }, []);
  const { AlertIcon } = Imagesdata || {};
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });

  const onSubmit = async (payload) => {
    const realPayload = {
      nc_type: payload.taxPercantage,
      property_id: userDetail?.propertyId,
    };
    try {
      let res = {};
      if (roomPrice?.ncType) {
        res = await Services.property.editNcType(roomPrice?.id, realPayload);
      } else {
        res = await Services.property.setNcType(realPayload);
      }
      if (res.status === 200) {
        reset();
        getDataHandler();
        toast.success("NC Type Price Updated Successfull !", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };

  return (
    <Layout title="NC Type Price Settings">
      <Row>
        <Col xs={12}>
          <div className="categoryWrap taxRooms">
            <Form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={9} className="form-group">
                  <InputField
                    htmlFor="taxPercantage"
                    text="NC Type Percentage"
                    type="text"
                    autoFocus={true}
                    placeholder="Enter Price %"
                    field={"taxPercantage"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.taxPercantage?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"taxPercantage"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col
                  xs={3}
                  className="form-group flex justify-content-between mt-2"
                >
                  <Button className="width100" variant="primary" type="submit">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
            <div className="flex gap-2">
              <span>Current Price Percentage:</span>
              <p>{roomPrice?.ncType} %</p>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default NcTypeSetting;
