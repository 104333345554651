import React, { useEffect, useState, useRef } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { InputField } from "../../components/InputField/InputField";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import services from "../../API/services";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import LandingHeader from "../../components/landingHeader";
import validation from "./validation";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Col } from "react-bootstrap";
import { daysDiffernce } from "../../utils/utils";
import { toast } from "react-toastify";
import FrontFooter from "../../layout/footer/FrontFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../API/Interceptor/loderLogo.png";
import { createLogs } from "../../store/slices/PropertyData";
import Swal from "sweetalert2";
import { loginSuccess } from "../../store/slices/authenticationSlice";
import OTPInput from "react-otp-input";
import ReactGA from "react-ga4";
import { getTaxService } from "API/services/newServices";

const meal_Modes = {
  ep: 0,
  breakFastPrice: 1,
  ap: 2,
  map: 3,
};

const Bookhotel = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "hotel_booking" });
    window.scrollTo(0, 0);
  }, []);
  const { CardIcon, ParcialPay, HotalPay, AlertIcon } = Imagesdata || {};

  const dispatch = useDispatch();

  const todayMoment = moment();
  const tomorrowMoment = todayMoment.clone().add(1, "days");
  const location = useLocation();
  const [bookingData, setBookingData] = useState({
    room: 1,
    adults: 1,
    children: 0,
  });
  const { userDetail, access_token } = useSelector(authenticationSeletor);
  const navigate = useNavigate();

  const [checkinDate, setCheckinDate] = useState(new Date(todayMoment));
  const [categoryData, setCategoryData] = useState([]);
  const [checkOutDate, setCheckOutDate] = useState(
    new Date(
      location?.state?.hotelData?.slot >= 24 ? tomorrowMoment : todayMoment
    )
  );

  const [inputCouponCode, setInputCouponCode] = useState("");
  const [hoterData, setHotelData] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [anotherPerson, setAnotherPerson] = useState(false);
  const [selectedPayOptipn, setSelectedPayOptipn] = useState("");
  const [couponCode, setCouponCode] = useState({});
  const [couponAmount, removeCouponAmount] = useState("");
  const [bookingDetail, setBookingDetail] = useState("");
  const [fetchCoupon, setFetchCoupon] = useState([]);
  const [tax, setTax] = useState({});
  const [cancelBulk, setCencelBulk] = useState(false);
  const [withValidation, setWithValidation] = useState({});
  const [cpnShow, setCpnShow] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [breakFastCharge, setBreakFastCharge] = useState(false);
  const [roomBreakFastPrice, setRoomBreakFastPrice] = useState({
    breakFastPrice: location?.state?.hotelData?.breakFastPrice,
    ap: location?.state?.hotelData?.ap,
    map: location?.state?.hotelData?.map,
  });
  const [cuponCode, setCuponCode] = useState("");
  const [mealPlan, setMealPlan] = useState("ep");
  const [breakFastMoney, setBreakFastMoney] = useState(0);
  const [sendHours, setSendHours] = useState(location?.state?.hotelData?.slot);
  const [finalShowAmount, setFinalShowAmount] = useState(0);
  const [payloadData, setPayloadData] = useState({});
  const [showScreen, setShowScreen] = useState({
    newuser: false,
    otpScreen: false,
  });
  const [resendOtp, setResendOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const [fakePrice, setFakePrice] = useState(0);

  const [count, setCount] = useState(1);
  const [counter, setCounter] = useState(30);
  const [countRoom, setCountRoom] = useState(1);
  const [countChild, setCountChild] = useState(0);
  const timerForOtp = () => {
    const countRef = setTimeout(() => {
      setCounter(() => counter - 1);
    }, 1000);
    if (counter <= 0) {
      clearTimeout(countRef);
      setResendOtp(true);
    }
  };
  useEffect(() => {
    if (showScreen.otpScreen) {
      timerForOtp();
    }
  }, [showScreen, counter]);

  // timerForOtp();

  const resendOTP = async (e) => {
    e.preventDefault();
    try {
      const res = await services.auth.userLogin({
        mobile: payloadData.phoneNumber,
      });
      if (res.status === 200) {
        toast.success(`OTP resend on ${payloadData.phoneNumber}*`, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        setCounter(30);
        setResendOtp(false);
      }
    } catch (error) {}
  };

  const verifyHandler = async (e) => {
    e.preventDefault();
    let payloadMain = {};
    if (otp.length < 4) {
      toast.error("Please Enter OTP*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }
    payloadMain = {
      mobile: payloadData.phoneNumber,
      name: payloadData.fullName,
      email: payloadData.email,
      otp: otp,
      platform: 1,
    };
    try {
      const res = await services.auth.userVerify(payloadMain);
      if (res.status === 200 && res.data.success === true) {
        if (res?.data?.data.name && res?.data?.data.email) {
          dispatch(
            loginSuccess({
              data: { ...res?.data?.data, logintype: "normalUser" },
              token: res?.data?.token,
            })
          );
        } else {
          const {
            createdAt,
            deletedAt,
            id,
            lastLogged,
            mobile,
            otp,
            password,
            updatedAt,
          } = res?.data?.data;
          const { name, email } = payloadMain;
          dispatch(
            loginSuccess({
              data: {
                createdAt,
                deletedAt,
                id,
                name,
                email,
                lastLogged,
                mobile,
                otp,
                password,
                updatedAt,
                logintype: "normalUser",
              },
              token: res?.data?.token,
            })
          );
        }
        // navigate("/");

        bookHotalHandler(payloadData, res.data.data.id);
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Wrong OTP*", {
          autoClose: 2000,
          pauseOnHover: true,
          // position: "top-right",
        });
      }
    }
  };
  const getRoomsById = async (id) => {
    try {
      const res = await services.globalService.getRoomByIdService(id);
      if (res.status === 200) {
        navigate(`/hotel-details/${res.data.data.propertyId}`);
      }
    } catch (error) {}
  };
  // getHours
  const { hotelId } = useParams();
  useEffect(() => {
    if (!!location?.state?.hotelData) {
      setHotelData(location?.state?.hotelData);
      getTaxApi();
      getCoupan();
      setSendHours(location?.state?.hotelData?.slot);
      setRoomBreakFastPrice({
        breakFastPrice: location?.state?.hotelData?.breakFastPrice,
        ap: location?.state?.hotelData?.ap,
        map: location?.state?.hotelData?.map,
      });
    } else {
      if (hotelId) {
        getRoomsById(hotelId);
      }
    }
  }, [location?.state?.hotelData]);

  useEffect(() => {
    if (!userDetail?.name && access_token) {
      Swal.fire({
        title: "Update Profile",
        text: "Please update your profile to complete your booking.",
        icon: "question",
      }).then((result) => {
        navigate("/user-profile");
      });
    }
  }, [userDetail, access_token]);

  useEffect(() => {
    if (access_token) {
      setWithValidation({ mode: "onSubmit" });
    } else {
      setWithValidation({ ...validation, mode: "onSubmit" });
    }
  }, [access_token]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(withValidation);

  const onSubmit = async (payload) => {
    if (access_token) {
      bookHotalHandler(payload);
    } else {
      let mainPayload = { mobile: payload.phoneNumber };
      const res = await services.auth.userLogin(mainPayload);
      if (res.status === 200) {
        setPayloadData(payload);
        timerForOtp();
        if (res.data.isNewUser) {
          setShowScreen({
            otpScreen: true,
            newuser: true,
          });
        } else {
          setShowScreen({
            otpScreen: true,
            newuser: false,
          });
        }
      }
    }
  };

  const bookHotalHandler = async (payload, id) => {
    let payTotal = Math.round(
      finalShowAmount + (finalShowAmount * tax?.amount) / 100
    );
    const realPayload = {
      propertyId: hoterData.propertyId,
      propertyRoomsCategoryId: hoterData.categoryId,
      userId: userDetail.id ?? id,
      fromDate: moment(checkinDate).format("YYYY-MM-DD"),
      toDate: moment(checkOutDate).format("YYYY-MM-DD"),
      noOfRooms: bookingData.room,
      adults: bookingData.adults,
      children: bookingData.children,
      paymentMode: 0,
      PaymentStatus: 0,
      bookingStatus: 0,
      checkInDateTime: checkinDate,
      checkOutDateTime: checkOutDate,
      bookingAmout: payTotal,
      dueAmount: payTotal,
      guestDetails: [],
      otherPersonName:
        userDetail?.logintype === "normalUser"
          ? payload.otherPersonName
          : userDetail?.name,
      otherPersonNumber: payload.otherPersonNumber,
      source: "RRooms",
      breakFast: meal_Modes[mealPlan],
      bookingHours: sendHours,
      discountAmount: Math.round(couponAmount),
    };
    if (cuponCode !== "") {
      realPayload.cuponCode = cuponCode;
    }

    try {
      const res = await services.property.bookHotelService(realPayload);
      if (res.status === 200) {
        const params = {
          bookingId: res.data?.data?.id,
          action: `Booking Created`,
          actionBy: userDetail?.name,
          userType: "Guest",
        };
        dispatch(createLogs(params));
        setBookingDetail(res?.data?.data);
        setShowPayment(true);
      }
    } catch (error) {}
  };

  const changeHandler = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  const changeCounts = (state, minVal, countTemp, setFun, countType) => {
    let rooms = countRoom;

    if (state == "up") {
      if (
        countType === "room" &&
        countRoom < 5 &&
        hoterData.RoomDetails.length > countRoom
      ) {
        setFun(++countTemp);
      } else if (countType == "adult") {
        if (countTemp < 10 && countTemp < hoterData.RoomDetails.length * 2) {
          setFun(++countTemp);
          if (countTemp > countRoom * 2) {
            setCountRoom(++rooms);
          }
        }
      } else if (countType == "child") {
        if (countTemp < 10 && countRoom * 2 > countTemp) {
          setFun(++countTemp);
          if (countTemp > countRoom * 2) {
            setCountRoom(++rooms);
          }
        }
      }
    } else {
      if (countTemp > minVal) setFun(--countTemp);
      if (countType == "adult") {
        if (countTemp + 1 < countRoom * 2) {
          setCountRoom(--rooms);
          if (countChild > minVal) {
            if (countChild == countRoom * 2) {
              let tempdata = countChild - 2;
              setCountChild(tempdata);
            } else {
              let tempdata = countChild;
              setCountChild(--tempdata);
            }
          }
        }
      } else if (countType == "room") {
        if (count > countTemp * 2) {
          if (count == countRoom * 2) {
            let tempdata = count - 2;
            setCount(tempdata);
          } else {
            let tempdata = count;
            setCount(--tempdata);
          }
        }
        if (countChild > countTemp * 2) {
          if (countChild == countRoom * 2) {
            let tempdata = countChild - 2;
            setCountChild(tempdata);
          } else {
            let tempdata = countChild;
            setCountChild(--tempdata);
          }
        }
      }
    }
  };

  useEffect(() => {
    setBookingData({ room: countRoom, adults: count, children: countChild });
  }, [count, countChild, countRoom]);

  const bookHandler = async (option) => {
    try {
      const bookRes = await services.property.updateBookingModeStatus(
        bookingDetail?.id,
        {
          status: 1,
          paymentMode: option === "online" ? 1 : 0,
        }
      );
      if (bookRes.status == 200) {
        if (option === "online") {
          const res = await services.property.paymentGateway(bookingDetail?.id);
          if (res.status === 200) {
            window.location.replace(
              res.data.data.data.instrumentResponse.redirectInfo.url,
              "_blank"
            );
          }
        } else {
          navigate(`/booking-confirm/${bookingDetail?.id}`, {
            state: "offline",
          });
        }
      }
    } catch (error) {}
  };

  const onSubmitCoupon = async (e, code) => {
    e.preventDefault();
    if (code !== "") {
      if (
        fetchCoupon.find((i) => i.code.toLowerCase() == code?.toLowerCase())
          ?.code
      ) {
        const payload = {
          coupon: code,
          propertyId: hoterData?.propertyId,
        };
        try {
          let cAmount = 0;
          const res = await services.globalService.applyCouponService(payload);
          if (res.status === 200) {
            const prtDiscount = JSON.parse(
              res?.data?.data?.updatedPrice ?? "[]"
            );
            if (res?.data?.data?.amount) {
              setCouponCode(res.data.data);
              if (prtDiscount?.length > 0) {
                const ifPropertyChange = prtDiscount?.find(
                  (i) => i.property_id === hoterData?.propertyId
                );
                if (ifPropertyChange) {
                  cAmount =
                    (finalShowAmount *
                      Number(
                        prtDiscount?.find(
                          (i) => i.property_id === hoterData?.propertyId
                        )?.price
                      )) /
                    100;
                } else {
                  cAmount =
                    (finalShowAmount * Number(res?.data?.data?.amount)) / 100;
                }
                removeCouponAmount(cAmount);
              } else {
                cAmount =
                  (finalShowAmount * Number(res?.data?.data?.amount)) / 100;
                removeCouponAmount(cAmount);
              }
            }
            setFinalShowAmount(finalShowAmount - cAmount);
            setInputCouponCode("");
            setCuponCode(code);
            setErrorText("");
            setErrorText(false);
            toast.success("Coupon Applied Successfull*", {
              autoClose: 1000,
              pauseOnHover: true,
              position: "top-right",
            });
            setCpnShow(false);
          }
        } catch (error) {
          // toast.error("Coupon not Available For this Booking Date*", {
          toast.error("Coupon not Eligible for you*", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
          setErrorText("Enter Correct Coupon Code*");
          setCouponCode({});
          removeCouponAmount("");
        }
      } else {
        setErrorText("Invalid coupon code*");
      }
    } else {
      setErrorText("Enter Coupon Code*");
    }
  };
  const clearCoupon = () => {
    setFinalShowAmount(finalShowAmount + couponAmount);
    setCuponCode("");
    removeCouponAmount("");
    setCouponCode({});
    setErrorText(false);
  };
  const getTaxApi = async () => {
    try {
      const res = await getTaxService();
      if (res) {
        setTax(res);
      }
    } catch (error) {}
  };

  const getCoupan = async () => {
    try {
      const res = await services.globalService.getCouponsByUser(userDetail?.id);
      if (res.status === 200) {
        const filterCpn = res.data.data?.filter(
          (item) =>
            new Date(item.expireAt) >= new Date() &&
            new Date(item.startAt) <= new Date()
        );
        setFetchCoupon(filterCpn);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const priceWithDiscount = hoterData.priceLast / ((100 - 20) / 100);
    let fakePrice =
      priceWithDiscount *
      bookingData.room *
      daysDiffernce(checkinDate, checkOutDate);
    let calPrice =
      hoterData.priceLast *
      bookingData.room *
      daysDiffernce(checkinDate, checkOutDate);
    // (hoterData.slot >= 24 ? daysDiffernce(checkinDate, checkOutDate) : 1);

    setFakePrice(fakePrice);
    if (mealPlan !== "ep") {
      const amt =
        roomBreakFastPrice[mealPlan] *
        daysDiffernce(checkinDate, checkOutDate) *
        bookingData.adults;
      setBreakFastMoney(amt);
      setFinalShowAmount(Number(calPrice) + Number(amt));
    } else {
      setBreakFastMoney(0);
      if (breakFastMoney) {
        setFinalShowAmount(finalShowAmount - breakFastMoney);
      } else {
        setFinalShowAmount(calPrice - breakFastMoney);
      }
    }
  }, [hoterData, checkinDate, checkOutDate, bookingData, tax, mealPlan]);

  const mealPlanHandler = (value) => {
    if (mealPlan === value) {
      setMealPlan("ep");
      toast.warning("Meal Plan Removed from Your Booking", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    } else {
      setMealPlan(value);
      toast.success("Meal Plan Added to Your Booking", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const checkinChangeHandler = (date) => {
    setCheckinDate(date);
    setCheckOutDate("");
  };

  const handleCalendarOpen = () => {};
  const calenderHandl = useRef();

  return (
    <div className="hotalList">
      {/* landing page header */}

      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>
      {/* main book section start here */}
      <form className="mainBook" onSubmit={handleSubmit(onSubmit)}>
        <div className="container flex">
          <Col xs={12} className="p-0">
            <div className=" filterRes flex justify-content-between p-2">
              <div>
                <h2>
                  <h4 className="m-0">{hoterData.propertyName}</h4>

                  <p className="fw-bold fs-6">{hoterData.categoryName}</p>
                </h2>
                <a href="" className="address flex text-dark">
                  {hoterData.address}, {hoterData?.city?.name}
                </a>
              </div>
              <h6>
                {bookingData.adults ? bookingData.adults : 1} Adults |{" "}
                {bookingData.children ? bookingData.children : 0} children |{" "}
                {bookingData.room ? bookingData.room : 1} Room
              </h6>

              <ul className="flex flex-column justify-content-between m-0">
                <li>
                  <h5>
                    <span className="text-dark">Check-in</span>
                    <Moment format="DD/MM/YYYY">{checkinDate}</Moment>
                    <span>
                      <Moment format="dddd">{checkinDate}</Moment>, 12:00 PM
                    </span>
                  </h5>
                  <div className="timelines"></div>
                  <h5>
                    <span className="text-dark">Check-out</span>
                    <Moment format="DD/MM/YYYY">{checkOutDate}</Moment>
                    <span>
                      <Moment format="dddd">{checkOutDate}</Moment>, 11:00 AM
                    </span>
                  </h5>
                </li>
              </ul>
            </div>
          </Col>

          <div className="col-left">
            {showPayment ? (
              <div className="payment-section">
                <h2>Payment options</h2>
                <ul className="flex tabBtns">
                  <li
                    className={selectedPayOptipn === "online" ? "active" : null}
                    onClick={() => {
                      setSelectedPayOptipn("online");
                      bookHandler("online");
                    }}
                  >
                    <img alt="" src={CardIcon} />
                    <h6>Pay Online</h6>
                    <p>Visa, MasterCard, Amex, Rupay And More</p>
                  </li>
                  {hoterData?.partialPayment == 1 && (
                    <li
                      className={
                        selectedPayOptipn === "parcialpay" ? "active" : null
                      }
                      onClick={() => {
                        setSelectedPayOptipn("parcialpay");
                        bookHandler("parcialpay");
                      }}
                    >
                      <img src={ParcialPay} alt="" />
                      <h6>Parcial pay to your confirm booking</h6>
                    </li>
                  )}
                  <li
                    className={
                      selectedPayOptipn === "PayatHotel" ? "active" : null
                    }
                    onClick={() => {
                      setSelectedPayOptipn("PayatHotel");
                      bookHandler("PayatHotel");
                    }}
                  >
                    <img alt="" src={HotalPay} />
                    <h6>Pay at Hotel</h6>
                  </li>
                </ul>
              </div>
            ) : showScreen.otpScreen ? (
              <section className="otpIfNoLogin verify-otp-booking">
                <div className="title">OTP</div>
                <div className="title">Verification Code</div>
                <p className="m-0">
                  We have sent a verification code to your mobile number
                </p>
                <div className="otpEditMobile d-flex align-items-center gap-2">
                  <span>{payloadData.phoneNumber}</span>
                  <button
                    className="btn m-0 p-0"
                    type="button"
                    onClick={() =>
                      setShowScreen({ newuser: false, otpScreen: false })
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen-to-square"
                      color="#000"
                    />
                  </button>
                </div>
                <form id="inputs" onSubmit={verifyHandler}>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="number"
                        className="Otp-form-control"
                      />
                    )}
                  />
                  <Button className="mb-3" type="submit">
                    Submit
                  </Button>
                </form>
                {resendOtp ? (
                  <a
                    href="#"
                    className="termsparagraph m-0"
                    style={{ color: "#ff0018" }}
                    onClick={(e) => resendOTP(e)}
                  >
                    Resend OTP
                  </a>
                ) : (
                  <p className="m-0 termsparagraph">
                    Resend OTP in{" "}
                    {`00:${counter > 9 ? counter : `0${counter}`}`}
                  </p>
                )}
              </section>
            ) : (
              <div className="booking-form">
                <div className="row">
                  {!access_token ? (
                    <div className="flex">
                      <div className="form-group col-md-4">
                        <InputField
                          htmlFor="fullName"
                          text="Full Name"
                          type="text"
                          autoFocus={true}
                          placeholder="Enter Full name"
                          field={"fullName"}
                          customclassName="form-control"
                          register={register}
                          errors={errors}
                        />
                        {errors?.fullName?.message && (
                          <div className="error-text">
                            <img alt="" src={AlertIcon} />
                            <span>
                              <ErrorMessage errors={errors} name={"fullName"} />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <InputField
                          htmlFor="email"
                          text="Email"
                          type="email"
                          placeholder="Enter Email ID"
                          field={"email"}
                          customclassName="form-control"
                          register={register}
                          errors={errors}
                        />
                        {errors?.email?.message && (
                          <div className="error-text">
                            <img alt="" src={AlertIcon} />
                            <span>
                              <ErrorMessage errors={errors} name={"email"} />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <InputField
                          htmlFor="phoneNumber"
                          text="Phone Number"
                          type="number"
                          placeholder="Enter Phone Number"
                          field={"phoneNumber"}
                          register={register}
                          customclassName="form-control"
                          errors={errors}
                        />
                        {errors?.phoneNumber?.message && (
                          <div className="error-text">
                            <img alt="" src={AlertIcon} />
                            <span>
                              <ErrorMessage
                                errors={errors}
                                name={"phoneNumber"}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="flex justify-content-between bookingActions">
                    <div className="form-group ">
                      <label className="text-dark">Room</label>
                      <div className="BtnsWrapBoth">
                        <button
                          type="button"
                          className="BtnDown"
                          onClick={() =>
                            changeCounts(
                              false,
                              1,
                              countRoom,
                              setCountRoom,
                              "room"
                            )
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="form-control detailSmallInput"
                          name="room"
                          value={bookingData.room}
                        />
                        <button
                          type="button"
                          className="BtnUp"
                          onClick={() =>
                            changeCounts(
                              "up",
                              1,
                              countRoom,
                              setCountRoom,
                              "room"
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="form-group ">
                      <label className="text-dark">Adults</label>
                      <div className="BtnsWrapBoth">
                        <button
                          type="button"
                          className="BtnDown"
                          onClick={() =>
                            changeCounts(false, 1, count, setCount, "adult")
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="form-control detailSmallInput"
                          name="adults"
                          value={bookingData.adults}
                        />
                        <button
                          type="button"
                          className="BtnUp"
                          onClick={() =>
                            changeCounts("up", 1, count, setCount, "adult")
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="form-group tooltip_Fire">
                      <label className="text-dark">Child</label>
                      <div className="BtnsWrapBoth">
                        <button
                          type="button"
                          className="BtnDown"
                          onClick={() =>
                            changeCounts(
                              false,
                              0,
                              countChild,
                              setCountChild,
                              "child"
                            )
                          }
                        >
                          -
                        </button>

                        <input
                          type="number"
                          className="form-control detailSmallInput"
                          name="children"
                          value={bookingData.children}
                        />
                        <button
                          type="button"
                          className="BtnUp"
                          onClick={() =>
                            changeCounts(
                              "up",
                              0,
                              countChild,
                              setCountChild,
                              "child"
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      <span className="Liketooltip">
                        Age below than 9 years only
                      </span>
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label className="text-dark">Check-In</label>
                      <DatePicker
                        className="form-control ml-2"
                        customclassName="form-control"
                        onChange={(date) => {
                          checkinChangeHandler(date);
                          calenderHandl.current.setOpen(true);
                        }}
                        selected={checkinDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        wrapperClassName="detailDatePicker"
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label className="text-dark">Check-Out</label>
                      <DatePicker
                        className="form-control ml-2"
                        name="checkout"
                        onChange={(date) => setCheckOutDate(date)}
                        selected={checkOutDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={checkinDate}
                        wrapperClassName="detailDatePicker"
                        // onCalendarOpen={handleCalendarOpen}
                        ref={calenderHandl}
                      />
                    </div>
                  </div>

                  <ul>
                    <li className="flex flex-column mt-2">
                      <li className="gap-2 flex justify-content-between">
                        <div className="gap-2 flex">
                          <input
                            type="checkbox"
                            id="breckFast"
                            onChange={() =>
                              setBreakFastCharge(!breakFastCharge)
                            }
                            value={breakFastCharge ? "checked" : "unchecked"}
                          />
                          <label
                            htmlFor="breckFast"
                            className="m-0 flex justify-content-between"
                          >
                            <span>Add Meal Plan </span>
                          </label>
                        </div>
                        {breakFastMoney > 0 && breakFastCharge && (
                          <span>
                            + ₹ {roomBreakFastPrice?.[mealPlan]}
                            /Adult/Day
                          </span>
                        )}
                      </li>
                      {breakFastCharge && (
                        <div className="mealPlan mt-2">
                          <div className="lables flex gap-2 align-items-center mb-2">
                            <input
                              type="checkbox"
                              name="mealPlan"
                              className="hotalMealPlane"
                              id="mealPlan1"
                              checked={mealPlan === "breakFastPrice"}
                              value="breakFastPrice"
                              onChange={(e) => mealPlanHandler(e.target.value)}
                            />
                            <label htmlFor="mealPlan1" className="m-0">
                              CP
                            </label>
                            <small className="text-black">(Breakfast)</small>
                          </div>

                          <div className="lables flex gap-2 align-items-center mb-2">
                            <input
                              type="checkbox"
                              name="mealPlan"
                              className="hotalMealPlane"
                              id="mealPlan3"
                              checked={mealPlan === "map"}
                              value="map"
                              onChange={(e) => mealPlanHandler(e.target.value)}
                            />
                            <label htmlFor="mealPlan3" className="m-0">
                              MAP
                            </label>
                            <small className="text-black">
                              (Breakfast + Lunch or Dinner)
                            </small>
                          </div>
                          <div className="lables flex gap-2 align-items-center mb-2">
                            <input
                              type="checkbox"
                              name="mealPlan"
                              className="hotalMealPlane"
                              id="mealPlan2"
                              checked={mealPlan === "ap"}
                              value="ap"
                              onChange={(e) => mealPlanHandler(e.target.value)}
                            />
                            <label htmlFor="mealPlan2" className="m-0">
                              AP
                            </label>

                            <small className="text-black">
                              (Breakfast + Lunch + Dinner)
                            </small>
                          </div>
                        </div>
                      )}
                    </li>
                  </ul>

                  <div className="form-group col-12">
                    <input
                      type="checkbox"
                      checked={anotherPerson}
                      onChange={(e) => setAnotherPerson(e.target.checked)}
                    />{" "}
                    <label> Book for another person</label>
                  </div>

                  {anotherPerson ? (
                    <>
                      <div className="form-group col-12 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                        <label>Other Person Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="otherPersonName"
                          {...register("otherPersonName", {
                            onChange: (e) => {
                              changeHandler(e);
                            },
                          })}
                        />
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                        <label>Other Person Contact no.</label>
                        <input
                          type="text"
                          className="form-control"
                          name="otherPersonNumber"
                          {...register("otherPersonNumber", {
                            onChange: (e) => {
                              changeHandler(e);
                            },
                          })}
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="form-group col-12 col-sm-6 col-lg-6 col-xl-6 col-md-6 bulkBooking">
                    <label onClick={() => setCencelBulk(!cancelBulk)}>
                      Click here Bulk Booking
                    </label>

                    {cancelBulk && (
                      <ul className="innerBulkBooking">
                        <li>
                          <a
                            href="https://api.whatsapp.com/send?phone=7906205755"
                            target="_blank"
                          >
                            <i
                              className="fa fa-whatsapp"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li>
                          <a href="mailto:brijesh.kumar@rrooms.in">
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li>
                          <a href="tel:+917906205755">
                            <i
                              className="fa fa-phone-square"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-right">
            <h3>Price Details</h3>
            <ul>
              <li>
                <span>Room Tariff /{bookingData.adults} Guest/Day</span>
                <p>
                  ₹ {fakePrice.toFixed(2)} <br />
                </p>
              </li>
              <li>
                <span>Instant Discount</span>
                <p className="text-primary">
                  - ₹{" "}
                  {Math.round(
                    fakePrice - hoterData.priceLast * bookingData.room
                  )?.toFixed(2)}{" "}
                  <br />
                </p>
              </li>

              {couponAmount ? (
                <>
                  <li>
                    <span className="d-flex flex-column">Coupon Discount</span>
                    <p className="green">- {Math.round(couponAmount)}</p>
                  </li>
                  <li className="d-flex justify-content-between">
                    <div>
                      <span>
                        <i className="text-black">'{couponCode?.code}' </i>
                        Applied
                      </span>
                      <span className="flex gap-1 mt-1">
                        <FontAwesomeIcon
                          icon="fa-solid fa-check"
                          color="green"
                        />
                        <span className="flex gap-1 text-primary">
                          {Math.round(couponAmount)} Saving Coupon
                        </span>
                      </span>
                    </div>
                    <button
                      style={{
                        border: 0,
                        backgroundColor: "transparent",
                        fontSize: ".85rem",
                      }}
                      className="text-primary"
                      onClick={clearCoupon}
                    >
                      Remove Coupon
                    </button>
                  </li>
                </>
              ) : null}
            </ul>
            <div className="total-price">
              <li className="flex mb-2">
                <h4></h4>
                <span className="text-black">
                  ₹ {Math.round(finalShowAmount)}
                </span>
              </li>
              <li className="flex">
                <h4>Taxes & Fee</h4>
                <span className="text-success">
                  + ₹ {Math.round((finalShowAmount * tax?.amount) / 100)}
                </span>
              </li>
            </div>
            <div className="total-price flex bgTotalPrice">
              <h4>
                Total Amount <br /> <span>inclusive of all taxes</span>
              </h4>
              <p>
                ₹
                {Math.round(
                  finalShowAmount + (finalShowAmount * tax?.amount) / 100
                )}
              </p>
            </div>
            {!couponAmount ? (
              <div className="applyCoupon">
                <div className="form-group position-relative m-0">
                  <input
                    type="text"
                    placeholder="Type Offer Code Here"
                    value={inputCouponCode}
                    onChange={(e) => {
                      setInputCouponCode(e.target.value);
                      setErrorText(false);
                    }}
                    style={{
                      border: "1px solid rgb(136 136 136 / 44%)",
                      padding: 5,
                      width: "100%",
                      marginTop: 10,
                      borderRadius: 6,
                      outline: 0,
                    }}
                  />
                  <button
                    onClick={(e) => {
                      onSubmitCoupon(e, inputCouponCode);
                    }}
                    style={{
                      border: "1px solid rgb(136 136 136 / 50%)",
                      borderRadius: 6,
                      position: "absolute",
                      backgroundColor: "transparent",
                      transform: "translate(-20%, -50%)",
                      top: "65%",
                      right: 0,
                      padding: "0 5px",
                      color: "#2890ff",
                      opacity: inputCouponCode.length > 0 ? 1 : 0.4,
                    }}
                    disabled={inputCouponCode.length > 0 ? false : true}
                  >
                    Apply
                  </button>
                </div>
                {errorText ? (
                  <span style={{ color: "red", fontSize: ".9rem" }}>
                    {errorText}
                  </span>
                ) : null}
                <div className="d-flex justify-content-end mt-1">
                  <button
                    type="button"
                    className="couponShowHide"
                    onClick={() => {
                      setCpnShow(!cpnShow);
                      setErrorText(false);
                    }}
                  >
                    + {!cpnShow ? "View " : "Hide "}More Offers
                  </button>
                </div>
              </div>
            ) : null}

            {!showPayment && (
              <div className="form_group flex justify-content-end mt-4 w-100">
                <Button
                  type="submit"
                  className="w-100"
                  style={{ fontFamily: "poppins" }}
                >
                  Confirm Your Booking
                </Button>
              </div>
            )}
          </div>

          {cpnShow && (
            <div className="coupons">
              <h4>Coupons</h4>
              <div className="flex mt-2 gap-2">
                {fetchCoupon?.length > 0 ? (
                  fetchCoupon?.map((item, key) => {
                    const prices = JSON.parse(item?.updatedPrice ?? "[]");
                    return (
                      <div className="card" key={key}>
                        <div className="main">
                          <div className="co-img w-25">
                            <img alt="" src={logo} />
                          </div>
                          <div className="content w-75">
                            <h2>
                              {item?.title?.length > 27
                                ? item?.title?.slice(0, 27) + "..."
                                : item?.title}
                            </h2>
                            <h1>
                              {item?.allowChange &&
                              prices?.find(
                                (item) =>
                                  item?.property_id ===
                                  location?.state?.hotelData?.propertyId
                              )
                                ? prices?.find(
                                    (item) =>
                                      item?.property_id ===
                                      location?.state?.hotelData?.propertyId
                                  )?.price
                                : item.amount}{" "}
                              % <span>OFF</span>
                            </h1>
                            <p>
                              Valid till{" "}
                              {moment(item.createdAt).format("D MMM,YYYY")}
                            </p>
                            <p>
                              Booking Date:{" "}
                              {`${moment(item.bookingFrom).format(
                                "D MMM"
                              )}- ${moment(item.bookingTo).format("D MMM")}`}
                            </p>
                          </div>
                        </div>
                        <div className="copy-button">
                          <input
                            id="copyvalue"
                            type="text"
                            readonly
                            value={item.code}
                            disabled
                          />
                          <button
                            onClick={(e) => onSubmitCoupon(e, item.code)}
                            className="copybtn"
                            style={{
                              opacity: couponCode.id == item.id ? 0.5 : 1,
                              cursor:
                                couponCode.id == item.id ? "auto  " : "pointer",
                            }}
                            disabled={couponCode.id == item.id ? true : false}
                          >
                            {couponCode.id == item.id ? "Applied" : "Apply"}
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex gap-2 align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-face-sad-tear"
                      className="text-danger"
                      size="xl"
                    />
                    <span>Coupons Not Availabe!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </form>

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Bookhotel;
