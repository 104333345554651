import { Imagesdata } from "assets/images/imageData";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addFunctions,
  addMenuCategory,
  addMenuName,
  addVenue,
  getFunctions,
  getMenuCategory,
  getMenuName,
  getVenue,
  deleteFunctions,
  deleteVenue,
  deleteMenuName,
  deleteMenuCategory,
} from "API/services/newServices";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";

const validation = Yup.object().shape({
  name: Yup.string().required(""),
});
const validationVenue = Yup.object().shape({
  name: Yup.string().required(""),
  gstPercentage: Yup.string()
    .required("Please Enter Percentage*")
    .matches(
      /^[1-9]\d?$|^100$/,
      "Percentage Should be a Number & between 1 - 100*"
    ),
});

const SettingTabs = [
  { id: 1, name: "Function" },
  { id: 2, name: "Venue" },
  { id: 3, name: "Menu" },
  { id: 4, name: "Menu Category" },
];

const useBanquetSetting = () => {
  const { AlertIcon } = Imagesdata;
  const [activeTab, setActiveTab] = useState(SettingTabs?.[0].id);
  const { userDetail } = useSelector(authenticationSeletor);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    ...{ resolver: yupResolver(activeTab == 2 ? validationVenue : validation) },
  });

  // states
  const [tableData, setTableData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [activeTabName, setActiveTabName] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const getDataHandler = async (apiHanlder) => {
    const res = await apiHanlder(userDetail.propertyId);
    if (res) setTableData(res);
  };

  const openAddModalHanlder = () => {
    setOpenAddModal(true);
  };
  const hideAddModalHanlder = () => {
    setOpenAddModal(false);
    reset();
  };
  const editHandler = (data) => {
    setSelectedData(data);
    setOpenAddModal(true);
    const name =
      data.venueName ?? data.menuName ?? data.categoryName ?? data.functionName;
    setValue("name", name);
    setValue("gstPercentage", data.gstPercent);
    setEditMode(true);
  };

  const onSubmit = async (payload) => {
    const params = {
      editMode,
      id: selectedData.id ?? selectedData.venueId,
      body: { propertyId: userDetail.propertyId },
    };
    let res = {};
    let handler = () => {};
    switch (activeTab) {
      case 1:
        params.body.functionName = payload.name;
        res = await addFunctions(params);
        handler = getFunctions;
        break;
      case 2:
        params.body.venueName = payload.name;
        params.body.gstPercent = payload.gstPercentage;
        res = await addVenue(params);
        handler = getVenue;
        break;
      case 3:
        params.body.menuName = payload.name;
        res = await addMenuName(params);
        handler = getMenuName;
        break;
      case 4:
        params.body.categoryName = payload.name;
        res = await addMenuCategory(params);
        handler = getMenuCategory;
        break;
      default:
    }
    if (res) {
      setEditMode(false);
      hideAddModalHanlder();
      getDataHandler(handler);
    }
  };

  useMemo(() => {
    const tabName = SettingTabs.find((i) => i.id == activeTab)?.name;
    setActiveTabName(tabName);
    switch (activeTab) {
      case 1:
        getDataHandler(getFunctions);
        break;
      case 2:
        getDataHandler(getVenue);
        break;
      case 3:
        getDataHandler(getMenuName);
        break;
      case 4:
        getDataHandler(getMenuCategory);
        break;
      default:
    }
  }, [activeTab]);

  const deleteHandler = async (id) => {
    if (window.confirm(`Are you sure delete this ${activeTabName} Name`)) {
      let res = {};
      let handler = () => {};
      switch (activeTab) {
        case 1:
          res = await deleteFunctions(id);
          handler = getFunctions;
          break;
        case 2:
          res = await deleteVenue(id);
          handler = getVenue;
          break;
        case 3:
          res = await deleteMenuName(id);
          handler = getMenuName;
          break;
        case 4:
          res = await deleteMenuCategory(id);
          handler = getMenuCategory;
          break;
        default:
      }

      if (res) {
        getDataHandler(handler);
      }
      //       deleteFunctions,
      // deleteVenue,
      // deleteMenuName,
      // deleteMenuCategory,
    }
  };

  return {
    tableData,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    SettingTabs,
    activeTab,
    setActiveTab,
    activeTabName,
    editHandler,
    deleteHandler,
  };
};

export default useBanquetSetting;
