import Layout from "layout";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

import { InputField } from "components/InputField/InputField";
import useBanquetSetting from "controllers/BanquetSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showPercentage } from "utils/utils";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { ErrorMessage } from "@hookform/error-message";

const BunquetFunction = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "banquet_master" });
  }, []);
  const {
    tableData,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    activeTab,
    setActiveTab,
    SettingTabs,
    activeTabName,
    editHandler,
    deleteHandler,
  } = useBanquetSetting();

  return (
    <Layout title="Banquet Master" openHandler={openAddModalHanlder}>
      <Row className="px-3">
        <Col
          lg={12}
          className="flex justify-content-between foodOrderTabs bg-white mb-3"
        >
          <Tabs
            className="tabs mb-2"
            activeKey={activeTab}
            defaultActiveKey={activeTab}
            onSelect={(tabKey) => setActiveTab(+tabKey)}
          >
            {SettingTabs.map((tab) => {
              return <Tab eventKey={tab.id} key={tab.id} title={tab.name} />;
            })}
          </Tabs>
        </Col>
        <Col xs={12}>
          <div className="categoryWrap">
            <h3>{activeTabName}</h3>
            {tableData.length > 0 ? (
              <ul className="d-flex">
                {tableData.map((data, key) => (
                  <li
                    key={key}
                    className={`d-flex align-items-center ${
                      openAddModal && "active"
                    }`}
                    style={{ cursor: "default" }}
                  >
                    <div className="d-flex flex-column">
                      <span>
                        {data.venueName ??
                          data.menuName ??
                          data.categoryName ??
                          data.functionName}
                      </span>
                      {data.gstPercent && (
                        <small className="fw-bold text-primary">
                          GST: {showPercentage(data.gstPercent)}
                        </small>
                      )}
                    </div>
                    <button
                      className="btn m-0 p-0"
                      onClick={() => editHandler(data)}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen-to-square"
                        color="#0076FC"
                      />
                    </button>
                    <button
                      className="btn m-0 p-0"
                      onClick={() => deleteHandler(data.id ?? data.venueId)}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-trash-can"
                        color="red"
                      />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div
                className="flex flex-column align-center justify-content-center full-width text-black w-100"
                style={{ height: "10rem" }}
              >
                <FontAwesomeIcon
                  className="mb-2"
                  icon="fa-solid fa-ban"
                  style={{ width: "40px", height: "40px" }}
                  beat
                />
                <span>Menu Item not available</span>
              </div>
            )}
          </div>
        </Col>
        {/* <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <Table className="food-report" ref={tableRef}>
            <TableHeader />
            <TableBody />
          </Table>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper> */}
      </Row>
      <Modal show={openAddModal} onHide={hideAddModalHanlder} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit " : "Create "} {activeTabName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="name"
                    text={`${activeTabName} Name`}
                    type="text"
                    autoFocus={true}
                    placeholder={`Enter ${activeTabName} Name`}
                    field={"name"}
                    register={register}
                    errors={errors}
                    notnull
                  />
                  {errors?.name?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>Please Enter {activeTabName} Name*</span>
                    </div>
                  )}
                </Col>
                {activeTab == 2 && (
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="gstPercentage"
                      text={`GST Percentage`}
                      type="text"
                      max={3}
                      autoFocus={true}
                      placeholder={`Enter GST Percentage`}
                      field={"gstPercentage"}
                      register={register}
                      errors={errors}
                      notnull
                    />
                    {errors?.gstPercentage?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <ErrorMessage
                          errors={errors}
                          name="gstPercentage"
                        ></ErrorMessage>
                      </div>
                    )}
                  </Col>
                )}

                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default BunquetFunction;
