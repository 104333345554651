import React, { useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { LoginAction } from "../../store/slices/authenticationSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import validation from "./validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const AdminLogin = () => {
  const navigate = useNavigate();
  const { Logolandingpage } = Imagesdata || {};
  const [userInfo, setUserInfo] = useState({ email: "", password: "" });
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ ...validation, mode: "onSubmit" });

  const [viewBtn, setViewBtn] = useState(false);

  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    const statusMain = await dispatch(
      LoginAction({
        email: userInfo.email,
        password: userInfo.password,
      })
    );
    if (statusMain) {
      navigate("/dashboard");
      toast.success("Login Successfull*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    } else {
      toast.error("Username or Password is Incorrect*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };
  return (
    <section className="loginSection flex flex-column flex-md-row">
      <div className="container-lg">
        <div className="topbar d-flex">
          <figure>
            <a href="/">
              <img alt="" src={Logolandingpage} alt="Main logo" />
            </a>
          </figure>
          <p>
            We make your stay just as enjoyable an experience as you can imagine
          </p>
        </div>
      </div>
      <div className="container-lg">
        <div className="wrapper flex">
          <div className="content">
            {/* <h1>There’s a smarter way to RRooms</h1> */}
            <h1>Don't think Book in a blink!</h1>
            <p>
              Sign up with your phone number and get exclusive access to
              discounts and savings on RRooms stays and with our many Hotel
              partners.
            </p>
          </div>
          <div className="form-wrap">
            <div className="head">Admin Login</div>
            <div className="admin-login-form form-inner">
              <h2>Login</h2>
              <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="Email"
                    value={userInfo.email}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, email: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="icon_input">
                    <input
                      type={viewBtn ? "text" : "password"}
                      placeholder="**********"
                      value={userInfo.password}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, password: e.target.value })
                      }
                    />
                    <div onClick={() => setViewBtn(!viewBtn)}>
                      <FontAwesomeIcon
                        icon={`fa-solid  ${
                          viewBtn ? "fa-eye-slash" : "fa-eye"
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit">login</button>
                </div>
                <p>
                  <a href="#">Forget Password</a>
                </p>
              </form>
            </div>
            <div className="tag"></div>
            <ul className="login-btn-list">
              <li>
                <Link to="/login" className="registerBtn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i> back
                  to login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
