import Layout from "layout";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableHeader,
} from "react-bs-datatable";
import { InputField } from "components/InputField/InputField";
import { ErrorMessage } from "@hookform/error-message";
import useBanquetEnquiry from "controllers/banquetEnquiry";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const BunquetEnquiry = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: reportState ? "banquet_enquiry_report" : "banquet_enquiry",
    });
  }, []);
  const navigate = useNavigate();
  const {
    tableData,
    tableRef,
    STORY_HEADERS,
    openAddModalHanlder,
    hideAddModalHanlder,
    openAddModal,
    register,
    errors,
    editMode,
    handleSubmit,
    onSubmit,
    AlertIcon,
    STATUS,
    changeMode,
    control,
    hideLogsModal,
    logsModal,
    enquiryLogs,
    venue,
    functionNames,
    watchData,
    editHandler,
    showLogs,
    onDownload,
    targetRef,
    toPDF,
    reportState,
    searchfilter,
    findDataByMobile,
    showFindEnquiry,
    clearFindDataToShowAllTableData,
  } = useBanquetEnquiry();

  return (
    <Layout
      type={reportState}
      title={reportState ? "Banquet Enquiry Reporting" : "Banquet Enquiry"}
      openHandler={
        reportState ? () => onDownload() : () => openAddModalHanlder()
      }
      buttonTitle={reportState ? "Export Table" : "Add"}
      optionData={STATUS.map((i) => ({ id: i.value, name: i.label }))}
      setSearchfilter={searchfilter}
      extraButton={!!findDataByMobile.length ? "clear" : ""}
      extraBtnHandler={clearFindDataToShowAllTableData}
    >
      <Row>
        <DatatableWrapper
          body={tableData}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 50,
              options: [5, 10, 15, 20, 50, 100, 300],
            },
          }}
        >
          <Row className="px-2 flex justify-content-end">
            <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <div className="scrollTable">
            <Table className="food-report table-bordered" ref={tableRef}>
              <TableHeader />
              <tbody>
                {tableData.map((row, ind) => {
                  const tempStatus = STATUS.find((i) => i.value == row.status);
                  const bgColor = {
                    backgroundColor:
                      tempStatus.value == 1 && "rgb(108 223 108 / 56%)",
                  };
                  return (
                    <tr key={ind}>
                      <td style={bgColor}>{row.enquiryCode}</td>
                      <td style={bgColor}>{row.name}</td>
                      <td style={bgColor}>{row.mobile}</td>
                      <td style={bgColor}>{row.email}</td>
                      <td style={bgColor}>{row.enquiryType}</td>
                      <td style={bgColor}>{row.functionType}</td>
                      <td style={bgColor}>
                        {moment(row.eventDate).format("DD MMM, YYYY")}
                      </td>
                      <td style={bgColor}>{row.numberOfGuest}</td>
                      <td style={bgColor}>{row.query ?? "N/A"}</td>
                      <td style={{ ...bgColor, color: tempStatus.color }}>
                        {tempStatus?.label}
                      </td>
                      <td style={bgColor}>
                        {moment(row.followUpDate).format("DD MMM, YYYY")}
                      </td>
                      <td style={bgColor}>{row.remark ?? "N/A"}</td>
                      <td style={bgColor}>{row.createdBy}</td>
                      <td style={bgColor}>
                        {moment(row.createdAt).format("DD MMM, YYYY")}
                      </td>
                      {!reportState && (
                        <td
                          style={{ minWidth: 290, ...bgColor }}
                          className="text-end"
                        >
                          <>
                            <Button
                              onClick={() => showLogs(row.banquetEnquiryId)}
                              className="btn btn-sm mr-1"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-eye" />
                            </Button>
                            {tempStatus.value != 1 && (
                              <>
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/banquet/add-banquet-bookings?fromEnquiry=${row.banquetEnquiryId}`
                                    )
                                  }
                                  className="btn btn-sm mr-1"
                                >
                                  Add Booking
                                </Button>

                                <Button
                                  onClick={() => editHandler(row, true)}
                                  className="btn btn-sm mr-1"
                                >
                                  Change Status
                                </Button>

                                <Button
                                  onClick={() => editHandler(row, false)}
                                  className="btn btn-sm"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                                </Button>
                              </>
                            )}
                          </>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
              {/* <TableBody /> */}
            </Table>
          </div>
          <Col className="d-flex flex-col justify-content-end align-items-end paginationFoodReport">
            <Pagination />
          </Col>
        </DatatableWrapper>
      </Row>
      <Modal show={openAddModal} onHide={hideAddModalHanlder} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit " : changeMode ? "Change Status:" : "Create "}{" "}
            Banquet Enquiry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                {!changeMode && (
                  <>
                    <Col xs={12} className="form-group">
                      {!!findDataByMobile.length && (
                        <div
                          className="flex gap-3 align-items-center"
                          style={{ fontFamily: "poppins" }}
                        >
                          <span>Enquiry available for this mobile: </span>
                          <Button
                            className="btn-sm"
                            variant="success"
                            onClick={showFindEnquiry}
                          >
                            Show Enquiry
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="mobileNumber"
                        text="Customer Mobile"
                        type="text"
                        min={10}
                        max={10}
                        autoFocus={true}
                        placeholder="Enter Customer Mobile"
                        field={"mobileNumber"}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.mobileNumber?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage
                              errors={errors}
                              name={"mobileNumber"}
                            />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="venue"
                        text="Select Venue"
                        autoFocus={true}
                        type="options"
                        field={"venue"}
                        errors={errors}
                        notnull
                        control={control}
                        options={venue}
                        serchable
                      />
                      {errors?.venue?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"venue"} />
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="function"
                        text="Select Function"
                        autoFocus={true}
                        type="options"
                        field={"function"}
                        register={register}
                        errors={errors}
                        notnull
                        control={control}
                        options={functionNames}
                        serchable
                      />
                      {errors?.function?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"function"} />
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="guestCount"
                        text="Number of Guest"
                        type="number"
                        autoFocus={true}
                        placeholder="Enter Number of Guest"
                        field={"guestCount"}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.guestCount?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"guestCount"} />
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="eventDate"
                        text="Event Date"
                        type="date"
                        autoFocus={true}
                        placeholder="Enter Event Date"
                        field={"eventDate"}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.eventDate?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"eventDate"} />
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="name"
                        text="Customer Name"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter Customer Name"
                        field={"name"}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.name?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"name"} />
                          </span>
                        </div>
                      )}
                    </Col>

                    <Col xs={6} className="form-group">
                      <InputField
                        htmlFor="email"
                        text="Customer Email"
                        type="email"
                        autoFocus={true}
                        placeholder="Enter Customer Email"
                        field={"email"}
                        register={register}
                        errors={errors}
                        notnull
                      />
                      {errors?.email?.message && (
                        <div className="error-text">
                          <img alt="" src={AlertIcon} />
                          <span>
                            <ErrorMessage errors={errors} name={"email"} />
                          </span>
                        </div>
                      )}
                    </Col>
                  </>
                )}
                {changeMode && (
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="status"
                      text="Select Status"
                      autoFocus={true}
                      type="options"
                      field={"status"}
                      errors={errors}
                      notnull
                      control={control}
                      options={STATUS}
                      serchable
                    />
                    {errors?.status?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"status"} />
                        </span>
                      </div>
                    )}
                  </Col>
                )}
                {(!changeMode || watchData?.status?.value == 3) && (
                  <Col xs={6} className="form-group">
                    <InputField
                      htmlFor="followUpDate"
                      text="Follow Up Date"
                      type="date"
                      autoFocus={true}
                      placeholder="Enter Follow Up Date"
                      field={"followUpDate"}
                      register={register}
                      errors={errors}
                      notnull
                      minVal={moment().format("YYYY-MM-DD")}
                    />
                    {errors?.followUpDate?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>
                          <ErrorMessage errors={errors} name={"followUpDate"} />
                        </span>
                      </div>
                    )}
                  </Col>
                )}

                <Col xs={12} className="form-group">
                  <label htmlFor={"query"} className="label form-label">
                    {changeMode ? "Remark" : "Query"}
                  </label>
                  <textarea
                    className="form-control"
                    placeholder={`Enter ${changeMode ? "Remark" : "Query"}`}
                    id="query"
                    {...register("query")}
                  ></textarea>
                  {errors?.query?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"query"} />
                      </span>
                    </div>
                  )}
                </Col>

                <Col
                  xs={12}
                  className="form-group flex justify-content-between mt-4"
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={logsModal} onHide={hideLogsModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Banquet Enquiry Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dashboard-wrap h-50">
            <div className="round-card w-100">
              <div className="activity-wrapper">
                <div ref={targetRef}>
                  <div className="flex justify-content-between">
                    <div className="color-black fw-medium fs-6">
                      Banquet Enquiry Logs
                    </div>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-black fw-medium">Remark</th>
                        <th className="text-black fw-medium">Status</th>
                        <th className="text-black fw-medium">Performed By</th>
                        <th className="text-black fw-medium">FollowUp Date</th>
                        <th className="text-black fw-medium">Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enquiryLogs.map((log, index) => {
                        const tempStatus = STATUS.find(
                          (i) => i.value == log.status
                        );
                        return (
                          <tr key={index}>
                            <td className="text-black fw-medium">
                              {log.remark ?? "N/A"}
                            </td>
                            <td className="text-black fw-bold">
                              <span
                                style={{
                                  color: tempStatus?.color,
                                }}
                              >
                                {tempStatus?.label}
                              </span>
                            </td>
                            <td className="text-black fw-medium">
                              {log.createdBy}
                            </td>
                            <td className="text-black fw-medium">
                              {moment(log?.followUpDate).format("DD MMM,YYYY")}
                            </td>
                            <td className="text-success">
                              {moment(log?.createdAt).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <Button
                  variant="outline-primary btn-sm printBtn"
                  onClick={toPDF}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default BunquetEnquiry;
