import Layout from "layout";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  getBanquetBookingDetails,
  getBanquetBookingServices,
  getBanquetBookingVenue,
} from "API/services/newServices";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { showDate, showPercentage, showPrice, taxOnly } from "utils/utils";
import { banquetStatus } from "constant/constants";
import Services from "API/services";
import { useSelector } from "react-redux";
import { authenticationSeletor } from "store/slices/authenticationSlice";
import logoImage from "../../API/Interceptor/loderLogo1.png";
import { Margin, usePDF } from "react-to-pdf";

const BunquetBookingDetails = () => {
  const [searchParams] = useSearchParams();
  const fromMobile = searchParams.get("platform") == "mobile_app";

  const { id } = useParams();
  const [data, setData] = useState({});
  const [services, setServices] = useState([]);
  const [venues, setVenues] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const { userDetail } = useSelector(authenticationSeletor);
  const printPdf = () => {
    window.open(data.attachmentUrl);
  };
  const getServices = async () => {
    const res = await getBanquetBookingServices(id);
    if (res) setServices(res);
  };
  const getVenue = async () => {
    const res = await getBanquetBookingVenue(id);
    if (res) setVenues(res);
  };

  const getDataHandler = async () => {
    const response = await getBanquetBookingDetails(id);
    if (response) {
      getServices();
      getVenue();
      setData(response);
    }
  };
  const getInvoiceDetails = async () => {
    const res = await Services.property.getInvoiceData(
      `?property_id=${userDetail?.propertyId}`
    );

    if (res.status === 200) {
      setInvoiceData(res?.data?.data[0]);
    }
  };

  const { toPDF, targetRef } = usePDF({
    filename: "banquet-Bookings.pdf",
    page: { margin: Margin.SMALL, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 2,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  useEffect(() => {
    getDataHandler();
    getInvoiceDetails();
  }, []);

  return (
    <Layout
      title="Bunquet Booking Details"
      buttonTitle="Print"
      openHandler={fromMobile ? false : () => toPDF()}
    >
      <div ref={targetRef}>
        <Row className="ml-md-3 ml-0 banquetBookingDetails">
          {!fromMobile && (
            <div className="p-md-3 mt-md-5 mt-2 mb-md-2">
              <div className="InvoicecontentPdf text-black justify-content-start">
                <div className="w-25">
                  {invoiceData?.base64Image ? (
                    <img
                      className="w-100 w-md-75 mb-3"
                      src={`data:image/png;base64,${invoiceData.base64Image}`}
                      alt="logo"
                    />
                  ) : (
                    <img alt="" className="w-50" src={logoImage} alt="logo" />
                  )}
                </div>
                <div className="w-75 flex justify-content-start">
                  <div>
                    {" "}
                    <span>{invoiceData?.name ?? "Property Name"}</span>
                    <span className="text-capitalize">
                      {invoiceData?.address ?? "Your Property Address"}
                    </span>
                    <span>
                      Contact No.:{invoiceData?.phone ?? "Your Phone Number"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr />

          <Col xs={12}>
            <div className="p-0 p-md-3 pl-2 pl-md-0 bg-white mb-2">
              <div className="flex justify-content-between">
                {" "}
                <h5 className="text-dark fw-600">{data.customerName}</h5>
              </div>
              <div className="flex justify-content-between">
                <div className="text-dark fw-600 mr-3">
                  <h6 className="text-dark">
                    Booking Id:{" "}
                    <span className="text-success fw-600">
                      #{data.bookingCode}
                    </span>
                  </h6>
                  <h6 className="text-dark">
                    Contact Number:{" "}
                    <span className="text-success">{data.mobile}</span>
                  </h6>
                  <h6 className="text-dark">
                    Email-Id: <span className="text-danger">{data.email}</span>
                  </h6>

                  <h6 className="text-dark">
                    Address: <span className="text-info">{data.address}</span>
                  </h6>
                  <h6 className="text-dark">
                    Company:{" "}
                    <span className="text-danger">{data.companyName}</span>
                  </h6>
                </div>
                <div className="text-dark fw-600 mr-3">
                  <h6>
                    Booking Status:{" "}
                    <span className="text-success">
                      {
                        banquetStatus.find((i) => i.id == data.bookingStatus)
                          ?.name
                      }
                    </span>
                  </h6>
                  <h6 className="text-dark">
                    Invoice Date:
                    <span className="text-danger">
                      {moment(data.createdAt).format("YYYY-MM-DD")}
                    </span>
                  </h6>

                  <h6 className="text-dark">
                    No Of Guest:
                    <span className="text-danger">{data.numberOfGuests}</span>
                  </h6>
                  <h6 className="text-dark">
                    Pan No: <span className="text-dark">{data.pan}</span>
                  </h6>
                  <h6 className="text-dark">
                    gst No: <span className="text-danger">{data.gst}</span>
                  </h6>
                </div>
              </div>
            </div>
            <hr />
          </Col>
          {/* <Col xs={12}>
            <h5 className="text-dark fw-600">Banquet Booking Details</h5>
            <div className="scrollTable mb-3 mt-2">
              <Table className="common-table table-bordered m-0">
                <thead>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  
                </thead>
                <tbody>
                  <tr>
                    <td className="text-success fw-600">#{data.bookingCode}</td>
                    <td>{moment(data.createdAt).format("DD MMM, YYYY")}</td>
                    <td>{data.customerName}</td>
                    <td>{data.mobile}</td>
                    <td>{data.altmobile}</td>
                    <td>{data.companyName}</td>
                    <td>{data.email}</td>
                    <td>{data.numberOfGuests ?? "N/A"}</td>
                    <td>{data.address}</td>
                    <td>{"funtion type"}</td>
                    <td>{data.pan}</td>
                    <td>{showPrice(data.venueTaxableAmount)}</td>
                    <td>{showPrice(data.venueTaxAmount)}</td>
                    <td>{showPrice(data.venueTotalAmount)}</td>
                    <td>{showPrice(data.serviceTaxableAmount)}</td>
                    <td>{showPrice(data.serviceTaxAmount)}</td>
                    <td>{showPrice(data.serviceTotalAmount)}</td>
                    <td>{showPrice(data.subTotal)}</td>
                    <td>{showPrice(data.totalTax)}</td>
                    <td>{showPrice(data.finalAmount)}</td>
                    <td>{showPrice(data.paidAmount)}</td>
                    <td className="text-center">{showPrice(data.dueAmount)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col> */}

          <Col xs={12}>
            <h5 className="text-dark fw-600">Venue Details</h5>
            <Table className="common-table table-bordered m-0">
              <thead>
                <th>Name</th>
                <th>Date </th>
                <th>Start Time </th>
                <th>End Time</th>
                <th>Rate</th>
                <th>Discount(%)</th>
                <th>Gst(%)</th>
                <th>Gst ₹</th>
                <th>Net ₹</th>
              </thead>
              <tbody>
                {venues?.map((venue) => (
                  <tr>
                    <td>{venue.serviceName}</td>
                    <td>{showDate(venue.reservedDate)}</td>
                    <td>{venue.reserveStartTime}</td>
                    <td>{venue.reserveStartTime}</td>
                    <td>{showPrice(venue.rate)}</td>
                    <td>{showPercentage(venue.discountPercentage)}</td>
                    <td>{showPercentage(venue.gstPercentage)}</td>
                    <td>
                      {showPrice(
                        taxOnly(venue.totalAmount, venue.gstPercentage)
                      )}
                    </td>
                    <td>{showPrice(venue.totalAmount)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <hr />
          <Col xs={12}>
            <h5 className="text-dark fw-600">Service Details</h5>
            <Table className="common-table table-bordered m-0">
              <thead>
                <th>Booking Date</th>
                <th>Service</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Discount(%)</th>
                <th>Gst(%)</th>
                <th>Net Amount</th>
              </thead>
              <tbody>
                {services?.map((venue) => (
                  <tr>
                    <td>{showDate(venue.reservedDate)}</td>
                    <td>{venue.serviceName}</td>
                    <td>{showPrice(venue.rate)}</td>
                    <td>{venue.quantity}</td>
                    <td>{showPercentage(venue.discountPercentage)}</td>
                    <td>{showPercentage(venue.gstPercentage)}</td>
                    <td>{showPrice(venue.totalAmount)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col xs={12} className="border mt-3">
            <div className="px-3 py-2">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h5 className="text-dark fw-600">Food Items</h5>
                  <div className="d-flex gap-0 gap-md-5">
                    <h4
                      className="text-dark flex gap-2"
                      style={{ fontFamily: "poppins", fontSize: ".9rem" }}
                    >
                      <span>Menu Type:</span>
                      <span className="fw-bold">{data.menuType}</span>
                    </h4>
                    <h4
                      className="text-dark flex gap-2"
                      style={{ fontFamily: "poppins", fontSize: ".9rem" }}
                    >
                      <span>Menu Name:</span>
                      <span className="fw-bold">{data.menuName}</span>
                    </h4>
                  </div>
                </div>

                <Button className="btn-sm" onClick={printPdf}>
                  Print
                </Button>
              </div>
              <div
                className="scrollTable mb-3 mt-2 text-dark text-capitalize"
                style={{ letterSpacing: 1 }}
              >
                {data.menuItems}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between mr-md-4 mb-md-5 mt-2 ml-md-3 mr-0 mb-2 ml-0 px-1 flex-column flex-md-row">
          <Col xs={12} md={4}>
            <div className="py-2 px-2 bg-light border w-auto rounded-lg">
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="fs-6">Venue Taxable Amount</p>{" "}
                <p className="fs-6">{showPrice(data.venueTaxableAmount)}</p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Venue Tax Amount</p>{" "}
                <p className="text-dark fs-6">
                  {showPrice(data.venueTaxAmount)}
                </p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Total Venue Amount</p>{" "}
                <p className="text-dark fs-6">
                  {showPrice(data.venueTotalAmount)}
                </p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="fs-6">Service Taxable Amount</p>{" "}
                <p className="fs-6">{showPrice(data.serviceTaxableAmount)}</p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Service Tax Amount</p>{" "}
                <p className="text-dark fs-6">
                  {showPrice(data.serviceTaxAmount)}
                </p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Service Total Amount</p>{" "}
                <p className="text-dark fs-6">
                  {showPrice(data.serviceTotalAmount)}
                </p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="fs-6">Sub Total</p>{" "}
                <p className="fs-6">{showPrice(data.subTotal)}</p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Total Tax</p>{" "}
                <p className="text-dark fs-6">{showPrice(data.totalTax)}</p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Net Payable</p>{" "}
                <p className="text-dark fs-6">{showPrice(data.finalAmount)}</p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-between">
                <p className="text-dark fw-medium">Paid Amount</p>{" "}
                <p className="text-dark fs-6">{showPrice(data.paidAmount)}</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} className="mt-3 mt-md-0">
            <div className="py-2 pr-2 bg-light border w-auto rounded-lg p-2 p-md-0">
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-md-end justify-content-between">
                <p className="fw-bold fs-6">Net Payable Amount:</p>{" "}
                <p className="fw-bold fs-6">{showPrice(data.finalAmount)}</p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-md-end justify-content-between">
                <p className="text-dark fw-medium">Paid Amount:</p>{" "}
                <p className="text-dark fw-bold  fs-6">
                  {showPrice(data.paidAmount)}
                </p>
              </div>
              <div className="summaryCard d-flex w-100 mr-md-5 gap-md-3 justify-content-md-end justify-content-between">
                <p className="text-dark fw-medium">Due Amount :</p>{" "}
                <p className="text-dark fw-bold  fs-6">
                  {showPrice(data.dueAmount)}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default BunquetBookingDetails;
