import Layout from "layout";
import useGuestDetails from "../../../controllers/Guest.controller";
import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function GuestDetails() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "guest_details" });
  }, []);
  const {
    guests,
    tableRef,
    STORY_HEADERS,
    searchfilter,
    codeFilter,
    endDate,
    startDate,
    setEndDate,
    setStartDate,
  } = useGuestDetails();

  return (
    <Layout
      title="Guest Details"
      searchfilter
      searchType="date"
      lableFilter="Phone"
      toDate={endDate}
      fromDate={startDate}
      setToDate={setEndDate}
      setFromDate={setStartDate}
      otherFilters={
        <>
          <input
            type={"search"}
            placeholder="Booking Code"
            onChange={(e) => codeFilter(e.target.value)}
          />
          <input
            type={"search"}
            placeholder="Mobile Number"
            onChange={(e) => searchfilter(e.target.value)}
          />
        </>
      }
    >
      <Row>
        <DatatableWrapper
          body={guests}
          headers={STORY_HEADERS}
          paginationOptionsProps={
            {
              // initialState: {
              //   rowsPerPage: 50,
              //   options: [5, 10, 15, 20, 50, 100, 300],
              // },
            }
          }
        >
          <Row className="px-2 flex justify-content-end">
            {/* <Col
              lg={2}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col> */}
            <Col
              lg={2}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
          </Row>
          <Table className="food-report" ref={tableRef} bordered>
            <TableHeader />
            <TableBody />
          </Table>
        </DatatableWrapper>{" "}
      </Row>
    </Layout>
  );
}

export default GuestDetails;
