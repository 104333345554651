import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import Layout from "../../layout";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import validation from "./validation";
import services from "../../API/services";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadBox from "../../components/imageUploadBox";
import { Link, Outlet, Route, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { InputField } from "../../components/InputField/InputField";
import { arrayColumn, isJsonString } from "../../utils/utils";
import { baseImgUrl, baseUrl } from "../../API/client/api";
import { toast } from "react-toastify";
import Slider from "react-slick";
import { useCallback } from "react";
import { useMemo } from "react";
import { propertyPolicy } from "constant/constants";

const ADULT_CHILD_RADIO_GROUP = [
  { label: "1x", value: 1 },
  { label: "2x", value: 2 },
  { label: "3x", value: 3 },
  { label: "4x", value: 4 },
  { label: "5x", value: 5 },
];

const Property = () => {
  const { sliderImgOnly, AlertIcon, homeIcon } = Imagesdata || {};
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [imageSet, setImageSet] = useState([]);
  const [propertyData, setPropertyData] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ ...validation, mode: "onSubmit" });
  const [imageData, setImageData] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [addedRoomDetails, setAddedRDetails] = useState([]);
  const [roomDetails, setRoomDetails] = useState({
    floorNumber: "",
    roomNumber: "",
    occupancy: 1,
  });
  const [categoryPropertyData, setCategoryPropertyData] = useState([]);
  const [selectedAminites, setselectedAminites] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [showImagesPopup, setShowImagesPopup] = useState(false);
  const [imgFilter, setImgFilter] = useState(0);
  const [showImages, setShowImages] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [reason, setReason] = useState("");
  const [showPolicy, setShowPolicy] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [fillText, setFillText] = useState(0);

  const params = useParams();
  useEffect(() => {
    getPropertyDetail(params.id);
  }, []);

  const { roomDescription } = watch();
  useEffect(() => {
    setFillText(roomDescription?.length);
  }, [roomDescription]);

  useEffect(() => {
    getAmenities();
    getRoomCategory();
  }, []);

  const [profileImg, setProfileImg] = useState(0);
  const [activeImageState, setActiveImageState] = useState(false);

  const getAmenities = async () => {
    try {
      const res = await services.property.getAmenitiesService();
      if (res.status === 200) {
        setAmenitiesData(res?.data?.data);
      }
    } catch (error) {}
  };
  const getRoomCategory = async () => {
    try {
      const res = await services.property.getRoomCategoryService();
      if (res.status === 200) {
        setCategoryData(res?.data?.data);
      }
    } catch (error) {}
  };

  const changeHeroImg = async () => {
    try {
      const res = await services.property.updatePropertyProfile(
        selectedData?.id,
        { profileImageID: profileImg }
      );

      if (res.status === 200) {
        // onHide();
      }
    } catch (error) {}
  };

  const getPropertyDetail = async (id) => {
    try {
      const res = await services.property.getPropertyCategoryServiceId(id);
      if (res.status === 200) {
        setPropertyData(res?.data?.data);
        let policy = [res?.data?.data.bookingPolicy];
        if (isJsonString(res?.data?.data.bookingPolicy)) {
          policy = JSON.parse(res?.data?.data.bookingPolicy);
        }
        if (policy) {
          setShowPolicy(policy);
        }
        if (res?.data?.data?.bankDetails) {
          let bankData = {};
          res?.data?.data?.bankDetails?.split("~").map((item, index) => {
            if (index === 0) {
              bankData.BankName = item;
            }
            if (index === 1) {
              bankData.BranchName = item;
            }
            if (index === 2) {
              bankData.BankHolder = item;
            }
            if (index === 3) {
              bankData.BankAccountNumber = item;
            }
            if (index === 4) {
              bankData.BankIFSCCode = item;
            }
          });
          setBankDetails(bankData);
        }
      }
    } catch (error) {}
  };

  const onSubmit = async (payload) => {
    var bodyFormData = new FormData();
    bodyFormData.append("categoryId", payload.category);
    bodyFormData.append("roomDescription", payload.roomDescription);
    bodyFormData.append("status", 0);
    bodyFormData.append("propertyId", propertyData.id);
    bodyFormData.append("regularPrice", payload.regularPrice);
    if (profileImg && activeImageState) {
      bodyFormData.append("heroImage", profileImg);
    }
    for (let i = 0; i < payload.amenities.length; i++) {
      bodyFormData.append(`amenities[${i}]`, payload.amenities[i]);
    }
    if (imageData?.length + imageSet?.length > 0) {
      imageData.map((dataImg) => {
        bodyFormData.append("images", dataImg);
      });
    } else {
      toast.error("Please Select Images*", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }
    if (addedRoomDetails.length) {
      for (let i = 0; i < addedRoomDetails.length; i++) {
        bodyFormData.append(
          "roomDetails[]",
          JSON.stringify(addedRoomDetails[i])
        );
      }
    } else {
      bodyFormData.append("roomDetails", roomDetails);
    }

    try {
      let res = {};
      if (editMode) {
        let editPayload = {
          id: selectedData.id,
          body: bodyFormData,
        };
        res = await services.property.updateRRoomService(editPayload);
      } else {
        if (!addedRoomDetails.length) {
          toast.error("Please Add Rooms*", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
          return;
        }
        res = await services.property.createRRoomService(bodyFormData);
      }

      if (res.status === 200) {
        getPropertyDetail(params.id);
        setEditMode(false);
        setShow(false);
        reset();
        setImageData([]);
        setSelectedData({});
        setAddedRDetails([]);
        setImageSet([]);
        setselectedAminites([]);
        if (editMode) {
          toast.success("Room Updated SuccessFull !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        } else {
          toast.success("Room Created SuccessFull !", {
            autoClose: 1000,
            pauseOnHover: true,
            position: "top-right",
          });
        }
      } else {
        toast.error("Some Error Occured*", {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };
  const imageHandler = (data) => {
    setImageData((preValue) => {
      return [...preValue, data];
    });
  };

  useEffect(() => {
    getPropertyCategory();
  }, []);

  const getPropertyCategory = async () => {
    try {
      const res = await services.property.getPropertyCategoryService();
      if (res.status === 200) {
        setCategoryPropertyData(res?.data?.data);
      }
    } catch (error) {}
  };

  const amenitiesRender = (dataType, dataset) => {
    let selectedAminits = [];
    amenitiesData?.map((data) => {
      propertyData.PropertyAmenities?.filter((innerData) => {
        if (innerData.propertyAmenitiesId === data.id) {
          selectedAminits.push(data);
        }
      });
    });
    return selectedAminits;
  };

  const addHandler = () => {
    if (
      roomDetails.floorNumber &&
      roomDetails.roomNumber &&
      roomDetails.occupancy
    ) {
      setAddedRDetails([...addedRoomDetails, roomDetails]);
    } else {
      toast.error("Please Enter Floor and Room Number!", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const isBookHotel = location.pathname.includes("book-hotel");

  const editHandler = (data) => {
    setSelectedData(data);
    setShow(true);
    setEditMode(true);
    setValue("category", data.categoryId);
    setValue("regularPrice", data.regularPrice);
    setValue("roomDescription", data.roomDescription);
    setValue("amenities", arrayColumn(data.RoomAmenities, "amenitiesId"));
    setselectedAminites(arrayColumn(data.RoomAmenities, "amenitiesId"));
    let tempObj = [];
    data.RoomDetails.map((data) => {
      let obj = {
        floorNumber: data.floorNumber,
        roomNumber: data.roomNumber,
        occupancy: data?.occupancy?.toString(),
      };
      tempObj.push(obj);
    });
    setAddedRDetails(tempObj);

    let imagData = [];
    data?.RoomImages.map((data) => {
      let obj = {
        id: data.id,
        filename: data.imageName,
        filetype: "",
        fileimage: `${baseImgUrl}${data.imageName}`,
        datetime: "",
        filesize: "",
      };

      imagData.push(obj);
    });
    setImageSet(imagData);
  };
  const changeHandler = (e, data) => {
    if (e.target.checked) {
      setselectedAminites([...selectedAminites, Number(e.target.value)]);
    } else {
      const resultfilter = selectedAminites.filter((d, index) => {
        return d !== Number(e.target.value);
      });
      setselectedAminites(resultfilter);
    }
  };

  const removeRoomHandler = (i) => {
    let getRooms = [...addedRoomDetails];
    getRooms.splice(i, 1);
    setAddedRDetails(getRooms);
  };

  const deleteImageHandler = async (id) => {
    try {
      const res = await services.property.deleteRoomImageService(id);
      if (res.status === 200) {
        getPropertyDetail(params.id);
      }
    } catch (error) {}
  };
  const changeStatus = async (approved) => {
    try {
      let message = "";
      const bodyData = {
        approved,
        id: propertyData?.id,
      };

      if (approved === 1) {
        message = "Property Approved SuccessFull !";
      } else {
        bodyData.remarks = reason;
        message = "Property Rejected SuccessFull !";
      }
      const res = await fetch(`${baseUrl}rrooms-property/property-status`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      if (res.status === 200) {
        toast.success(message, {
          autoClose: 1000,
          pauseOnHover: true,
          position: "top-right",
        });
        getPropertyDetail(params.id);
        setRejectModal(false);
      }
    } catch (error) {}
  };

  const settingsImgSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    adaptiveHeight: true,
  };

  const imageFilterFun = (filter) => {
    const img = propertyData?.PropertyImage?.filter((i) => {
      if (filter) {
        return i.title === filter || i.title === filter.charAt(0);
      } else if (filter === 0) {
        return i;
      }
    });
    setShowImages(() =>
      img?.length > 0
        ? img
        : [
            {
              title: "all",
              image: "",
            },
          ]
    );
  };

  const imgCategoryFilter = useCallback(
    (filter) => {
      setImgFilter(filter);
    },
    [imgFilter, propertyData]
  );
  useMemo(() => {
    imageFilterFun(imgFilter);
  }, [imgFilter, propertyData]);

  const rejectWithReason = (e) => {
    e.preventDefault();
    if (!reason.length) {
      toast.error("Enter Reason for Reject Property !", {
        autoClose: 1000,
        pauseOnHover: true,
        position: "top-right",
      });
      return;
    }
    changeStatus(2);
  };

  return (
    <Layout title="Property Detail" withoutContainer>
      <Container>
        <Row className="ml-4">
          <div className="onboardDetails">
            <div className="wrap">
              <div className="head flex py-2 justify-content-between">
                <h5>Property Details</h5>
                <div className="flex gap-2">
                  <Button
                    type="button"
                    className="btn-sm"
                    onClick={() => setShow(true)}
                  >
                    Create Room
                  </Button>

                  <Button
                    className="btn-sm bg-danger"
                    variant="danger"
                    onClick={() => setRejectModal(true)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={() => changeStatus(1)}
                  >
                    Approve
                  </Button>
                </div>
              </div>
              <div className="inner">
                <div className="row ">
                  <ul className="flex mt-3">
                    <li>
                      <h4>{propertyData.name}</h4>
                      <span>Property Name</span>
                    </li>
                    <li>
                      <h4>
                        {propertyData.latitude + "," + propertyData.longitude}
                      </h4>
                      <span>Lat Long</span>
                    </li>

                    <li>
                      <h4>{propertyData.tanNumber}</h4>
                      <span>TAN number</span>
                    </li>

                    <li>
                      {categoryPropertyData.map((data, ind) => {
                        if (data.id === propertyData.propertyCategoryId) {
                          return <h4 key={ind}>{data.name}</h4>;
                        }
                      })}

                      <span>Category</span>
                    </li>
                    <li>
                      <h4>{propertyData.gstNumber}</h4>
                      <span>GST number</span>
                    </li>
                    <li>
                      <h4>{propertyData.propertyMobileNumber}</h4>
                      <span>Mobile Number</span>
                    </li>
                    <li>
                      <h4>{propertyData.propertyEmailId}</h4>
                      <span>Email</span>
                    </li>
                    <li>
                      <h4>
                        {propertyData?.city ? propertyData?.city[0]?.name : ""}
                      </h4>
                      <span>City</span>
                    </li>
                    <li>
                      <h4>{propertyData.stateId}</h4>

                      <span>State</span>
                    </li>
                    <li>
                      <h4>{propertyData.pincode}</h4>
                      <span>Pincode</span>
                    </li>
                    <li>
                      <h4>{propertyData.landmark}</h4>
                      <span>Landmark</span>
                    </li>
                    <li>
                      <h4>{propertyData.PropertyPanNumber}</h4>
                      <span>Property Pan Number</span>
                    </li>

                    {propertyData.PropertyPanCertificate ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.PropertyPanCertificate}`}
                            target="_blank"
                          >
                            {propertyData.PropertyPanCertificate}
                          </a>
                        </h4>
                        <span>Property Pan Certificate</span>
                      </li>
                    ) : null}
                    {propertyData.tanCertificate ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.tanCertificate}`}
                            target="_blank"
                          >
                            {propertyData.tanCertificate}
                          </a>
                        </h4>
                        <span>Tan Certificate</span>
                      </li>
                    ) : null}
                    {propertyData.gstCertificate ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.gstCertificate}`}
                            target="_blank"
                          >
                            {propertyData.gstCertificate}
                          </a>
                        </h4>
                        <span>GST Certificate</span>
                      </li>
                    ) : null}
                    {propertyData.rentAgreement ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.rentAgreement}`}
                            target="_blank"
                          >
                            {propertyData.rentAgreement}
                          </a>
                        </h4>
                        <span>Rent Agreement</span>
                      </li>
                    ) : null}
                    {propertyData.cancelCheque ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.cancelCheque}`}
                            target="_blank"
                          >
                            {propertyData.cancelCheque}
                          </a>
                        </h4>
                        <span>Cancel Cheque</span>
                      </li>
                    ) : null}
                    <li className="w-50">
                      <h4>{propertyData.address}</h4>
                      <span>Property address</span>
                    </li>
                    <li className="full">
                      <ol>
                        {showPolicy.map((item, ind) => (
                          <li>{isNaN(item) ? item : propertyPolicy[item]}</li>
                        ))}
                      </ol>

                      <span>Hotel Policy</span>
                    </li>
                    <li className="full">
                      <h4>{propertyData.propertyDescription}</h4>
                      <span>Description</span>
                    </li>
                    <li
                      className="full flex justify-content-between"
                      onClick={() => setShowImagesPopup(true)}
                    >
                      <h4>Property Images</h4>
                      <button className="mr-3 btn- btn-primary">
                        Click To show Images
                      </button>
                    </li>
                    <li className="full">
                      <span>Property Amenities</span>
                      <ul className="flex amenities">
                        {propertyData.PropertyAmenities
                          ? amenitiesRender("property").map((data, ind) => (
                              <li className="flex" key={ind}>
                                <img alt="" src={`${baseImgUrl}${data.icon}`} />
                                <p>{data.name}</p>
                              </li>
                            ))
                          : null}
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <h3>Owner Details</h3>
                  <ul className="flex">
                    <li>
                      <h4>
                        {propertyData.ownerFirstName +
                          " " +
                          propertyData.ownerLastName}
                      </h4>
                      <span>Full name</span>
                    </li>
                    <li>
                      <h4>{propertyData.ownerPan}</h4>
                      <span>Pan number</span>
                    </li>
                    <li>
                      <h4>{propertyData.ownerAdhar}</h4>
                      <span>Adhar number</span>
                    </li>
                    <li>
                      <h4>{propertyData.ownerMobile}</h4>
                      <span>Contact Number</span>
                    </li>
                    <li>
                      <h4>
                        <a href={`mailto:${propertyData.ownerEmail}`}>
                          {propertyData.ownerEmail}
                        </a>
                      </h4>
                      <span>Email address</span>
                    </li>
                    {propertyData.owneradharCertificate ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.owneradharCertificate}`}
                            target="_blank"
                          >
                            {propertyData.owneradharCertificate}
                          </a>
                        </h4>
                        <span>Owner Adhar Certificate</span>
                      </li>
                    ) : null}
                    {propertyData.ownerpanCertificate ? (
                      <li>
                        <h4>
                          <a
                            href={`${baseImgUrl}${propertyData.ownerpanCertificate}`}
                            target="_blank"
                          >
                            {propertyData.ownerpanCertificate}
                          </a>
                        </h4>
                        <span>Owner Pan Certificate</span>
                      </li>
                    ) : null}
                  </ul>
                </div>

                <div className="row">
                  <h3>Bank Details</h3>
                  <ul className="flex">
                    <li>
                      <h4>{bankDetails?.BankHolder ?? "N/A"}</h4>
                      <span>Bank Holder</span>
                    </li>
                    <li>
                      <h4>{bankDetails?.BankName ?? "N/A"}</h4>
                      <span>Bank Name</span>
                    </li>
                    <li>
                      <h4>{bankDetails?.BranchName ?? "N/A"}</h4>
                      <span>Branch Name</span>
                    </li>
                    <li>
                      <h4>{bankDetails?.BankAccountNumber ?? "N/A"}</h4>
                      <span>Bank Account Number</span>
                    </li>
                    <li>
                      <h4>{bankDetails?.BankIFSCCode ?? "N/A"}</h4>
                      <span>Bank IFSC code</span>
                    </li>
                  </ul>
                </div>

                <div className="row">
                  <div className="table-box">
                    <table>
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Room Description</th>
                          <th>Regular Price</th>
                          <th>Total Room</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {propertyData.Rooms &&
                          propertyData.Rooms.map((data, key) => (
                            <tr key={key}>
                              <td>
                                {categoryData.map((innerData) => {
                                  if (innerData.id === data.categoryId) {
                                    return innerData.name;
                                  }
                                })}
                              </td>
                              <td>
                                {data?.roomDescription?.length > 100
                                  ? data?.roomDescription?.substring(0, 100) +
                                    "...."
                                  : data?.roomDescription}
                              </td>
                              <td>₹ {data.regularPrice}</td>
                              <td>{data?.RoomDetails?.length}</td>
                              <td>
                                <div className="button-group">
                                  <Button
                                    variant="primary"
                                    onClick={() => editHandler(data)}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset();
          setAddedRDetails([]);
          setselectedAminites([]);
          setSelectedData({});
          setImageData([]);
          setImageSet([]);
          setEditMode(false);
        }}
        size="xl"
        backdrop="static"
        className="modal_add_property"
      >
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Update Room" : "Create Room"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Row>
                <Col xs={6} className="form-group">
                  <label>Category</label>
                  <select register={register} {...register("category")}>
                    <option disabled selected value="">
                      Select room category
                    </option>
                    {categoryData.map((innerData, key) => (
                      <option key={key} value={innerData.id}>
                        {innerData.name}
                      </option>
                    ))}
                  </select>
                  {errors?.category?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"category"} />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="form-group">
                  <InputField
                    htmlFor="regularPrice"
                    text="Regular Price"
                    type="number"
                    placeholder="Enter Regular Price"
                    field={"regularPrice"}
                    register={register}
                    errors={errors}
                  />
                  {errors?.regularPrice?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage errors={errors} name={"regularPrice"} />
                      </span>
                    </div>
                  )}
                </Col>

                <Col xs={12} className="form-group">
                  <label>
                    Room Description{" "}
                    <span
                      className="text-primary fw-medium"
                      style={{ fontSize: ".8rem" }}
                    >
                      {fillText + "/" + 1500}
                    </span>
                  </label>
                  <textarea
                    register={register}
                    {...register("roomDescription")}
                    placeholder={"Enter property description"}
                  ></textarea>
                  {errors?.roomDescription?.message && (
                    <div className="error-text">
                      <img alt="" src={AlertIcon} />
                      <span>
                        <ErrorMessage
                          errors={errors}
                          name={"roomDescription"}
                        />
                      </span>
                    </div>
                  )}
                </Col>
                <Col xs={12}>
                  <div className="form-group full">
                    <label>Amenities</label>
                    <ul className="flex">
                      {amenitiesData.map((c, i) => (
                        <li key={i}>
                          <input
                            type="checkbox"
                            id={c.id}
                            value={c.id}
                            {...register("amenities", {
                              onChange: (e) => changeHandler(e, c),
                            })}
                            checked={selectedAminites.includes(Number(c.id))}
                          />
                          <label
                            for={c.id}
                            className={
                              selectedAminites.includes(c.id)
                                ? `activeLable`
                                : ""
                            }
                          >
                            {c.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                    {errors?.amenities?.message && (
                      <div className="error-text">
                        <img alt="" src={AlertIcon} />
                        <span>Please Select Amenities*</span>
                      </div>
                    )}
                  </div>
                </Col>

                <Col>
                  <ImageUploadBox
                    activeImageState={activeImageState}
                    setProfileImg={setProfileImg}
                    profileImgId={profileImg}
                    imageHandler={imageHandler}
                    selectedImages={imageSet}
                    deleteHandler={deleteImageHandler}
                  />
                </Col>
                {editMode ? (
                  <Col xs={12}>
                    <button
                      type="button"
                      className={`btn ${
                        activeImageState ? "btn-success" : "btn-danger"
                      }`}
                      style={{
                        padding: 3,
                        fontSize: ".85rem",
                        marginTop: -20,
                      }}
                      onClick={() => setActiveImageState(!activeImageState)}
                    >
                      Choose Profile Image
                    </button>
                  </Col>
                ) : null}
                <Col xs={12}>
                  <p>Add room</p>
                </Col>
                <Col xs={12} className="dasdasdas">
                  <div className="room-listing">
                    {addedRoomDetails.map((data, i) => (
                      <div className="room-box" key={i}>
                        <span
                          className="close-btn"
                          onClick={() => removeRoomHandler(i)}
                        >
                          X
                        </span>
                        <img alt="" src={homeIcon} />
                        <p>Floor Number: {data.floorNumber}</p>
                        <p>Room Number: {data.roomNumber}</p>
                        <p>occupancy: {data.occupancy}x</p>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <label>Floor Number</label>
                  <input
                    type="number"
                    className="form-control"
                    value={roomDetails.floorNumber}
                    onChange={(e) =>
                      setRoomDetails({
                        ...roomDetails,
                        floorNumber: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={6} className="form-group">
                  <label>Room Number</label>
                  <input
                    type="number"
                    className="form-control"
                    value={roomDetails.roomNumber}
                    onChange={(e) =>
                      setRoomDetails({
                        ...roomDetails,
                        roomNumber: e.target.value.toString(),
                      })
                    }
                  />
                </Col>
                <Form className="flex align-center mb-3 ml-2">
                  {ADULT_CHILD_RADIO_GROUP.map(({ label, value }) => {
                    return (
                      <Form.Check className="mr-4" key={label}>
                        <Form.Check.Input
                          type="radio"
                          value={value}
                          checked={roomDetails.occupancy === value}
                          onChange={(e) =>
                            setRoomDetails({
                              ...roomDetails,
                              occupancy: +e.target.value.toString(),
                            })
                          }
                        />
                        <Form.Check.Label>{label}</Form.Check.Label>
                      </Form.Check>
                    );
                  })}
                </Form>
                <Col xs={12} className="form-group">
                  <Button variant="primary" type="button" onClick={addHandler}>
                    Add
                  </Button>
                </Col>

                <Col xs={12} className="form-group">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showImagesPopup}
        onHide={() => {
          setShowImagesPopup(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        className="checkInModal menuCardShowFull"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="color-black"
          >
            Property Images
          </Modal.Title>
          <button
            className="closeButton"
            onClick={() => setShowImagesPopup(false)}
          >
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="listCard">
            <div className="imageslider flex justify-content-center">
              <div className="width90 position-relative">
                <Slider {...settingsImgSlider}>
                  {(showImages?.length > 0
                    ? showImages
                    : propertyData?.PropertyImage
                  )?.map((item, ind) => {
                    const { image } = item;
                    return (
                      <div key={ind}>
                        <figure className="sliderImg fitImg">
                          {image ? (
                            <img
                              src={`${baseImgUrl}${image}`}
                              alt="property Images"
                            />
                          ) : (
                            <img src={sliderImgOnly} alt="property Images" />
                          )}
                        </figure>
                      </div>
                    );
                  })}
                </Slider>
                <div className="category_images flex gap-1">
                  <button
                    style={{
                      borderColor: imgFilter !== 0 ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter(0)}
                  >
                    All
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Facade" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Facade")}
                  >
                    Facade
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Lobby" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Lobby")}
                  >
                    Lobby
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "DReception" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("DReception")}
                  >
                    Reception
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Room" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Room")}
                  >
                    Room
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Washroom" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Washroom")}
                  >
                    Washroom
                  </button>
                  <button
                    style={{
                      borderColor:
                        imgFilter !== "Others" ? "transparent" : "#fff",
                    }}
                    onClick={() => imgCategoryFilter("Others")}
                  >
                    Others
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={rejectModal}
        onHide={() => {
          setRejectModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        className="checkInModal menuCardShowFull"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="color-black"
          >
            Reject Property
          </Modal.Title>
          <button className="closeButton" onClick={() => setRejectModal(false)}>
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="popup-form onboardForm"
            onSubmit={(e) => rejectWithReason(e)}
          >
            <Container>
              <Row>
                <Col className="form-group" xs={12}>
                  <label htmlFor="reason">Reason For Reject Property</label>

                  <textarea
                    value={reason}
                    placeholder={"Enter Reason For Reject Property"}
                    id="reason"
                    name="reason"
                    onChange={(e) => setReason(e.target.value)}
                  ></textarea>
                </Col>
                <Col className="flex justify-content-center ">
                  <Button type="submit">Reject Property</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
      {/* <Outlet /> */}
    </Layout>
  );
};

export default Property;
