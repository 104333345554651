import React, { useEffect, useState } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import { Link, useParams } from "react-router-dom";
import LandingHeader from "../../components/landingHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getRroomPropertyAction,
  propertySeletor,
} from "../../store/slices/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSeletor } from "../../store/slices/authenticationSlice";
import Moment from "react-moment";
import FrontFooter from "../../layout/footer/FrontFooter";
import Services from "../../API/services";
import { toast } from "react-toastify";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { baseImgUrl } from "../../API/client/api";
import ReactGA from "react-ga4";

const Bookingconfirm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sliderImgOnly } = Imagesdata || {};

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [hoterData, setHotelData] = useState({});
  const [propertyList, setPropertyList] = useState([]);
  const { rRoomProperty } = useSelector(propertySeletor);
  const { userDetail } = useSelector(authenticationSeletor);

  const data = useParams();

  const getBooking = async (id) => {
    try {
      const res = await Services.property.getBookingById(id);
      if (res.status === 200) {
        if (res.data.data.bookingStatus == 1) {
          toast.success("Room Booking Successfull*", {
            autoClose: 2000,
            pauseOnHover: true,
          });
        } else if (res.data.data.bookingStatus == 7) {
          toast.error("Room Booking Failed*", {
            autoClose: 2000,
            pauseOnHover: true,
          });
        } else if (res.data.data.bookingStatus == 8) {
          toast.warning("Your Transaction In Process*", {
            autoClose: 2000,
            pauseOnHover: true,
          });
        }
        setHotelData(res?.data?.data);
        const reqData = {
          countryCode: "+91",
          phoneNumber: res.data.data.User.mobile,
          // fullPhoneNumber: "918447027360", // Optional, Either fullPhoneNumber or phoneNumber + CountryCode is required
          callbackData: "some text here",
          type: "Template",
          template: {
            name: "room_reservation_confirmation",
            languageCode: "en",
            bodyValues: [
              res.data.data.otherPersonName ??
                res.data.data.User.name ??
                "Guest",
              res.data.data.PropertyMaster.name,
              moment(res.data.data.createdAt).format("DD-MM-YYYY HH:MM A"),
            ],
            buttonValues: {
              1: ["room Booking"],
            },
          },
        };
        const data = await fetch(`https://api.interakt.ai/v1/public/message/`, {
          method: "post",
          headers: {
            Authorization: `Basic T1VZU0lGLXNyZzVXWENSengzdTdGSTdXWlNjbzBvWGJLSEQ4Y0VubWtXZzo=`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });
      } else {
        navigate("/");
      }
    } catch (error) {}
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "booking_confirm" });
    dispatch(getRroomPropertyAction());
  }, []);

  // const paymentStatus = async () => {
  //   try {
  //     const res = await Services.property.getStatusOfPaymentSucces(data.id);

  //     if (res.status === 200) {
  //       getBooking(data.id);
  //     }
  //   } catch (err) {
  //     navigate("/booking-cancel");
  //   }
  // };

  useEffect(() => {
    if (data.id) {
      // if (location.state !== "offline") {
      getBooking(data.id);
    } else {
      navigate("/");
    }
  }, [location]);

  useEffect(() => {
    setPropertyList(rRoomProperty);
  }, [rRoomProperty]);

  const addressRenderer = (id, datatype) => {
    const getData = propertyList.find((innerdata) => {
      if (innerdata.id === id) {
        return innerdata.city;
      }
    });
    if (getData) {
      return getData[datatype];
    }
  };

  return (
    <div className="hotalList">
      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>

      {/* booking confirm section start here */}
      <div className="bookConfirm">
        <div className="container">
          <div className="heading flex">
            <div className="content">
              {hoterData.bookingStatus == 8 ? (
                <>
                  <h2 className="yellow flex gap-2 align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-exclamation"
                      color="red"
                      beat
                    />
                    Your Transaction In Process, It may be confirmed or Failed
                  </h2>
                  <p>
                    You will soon receive an email for Booking Status on{" "}
                    <a href={`mailto:${userDetail.email}`}>
                      {userDetail.email}
                    </a>
                  </p>
                  <button
                    onClick={() =>
                      navigate(`/hotel-details/${hoterData?.propertyId}`)
                    }
                    className="goBackRoom"
                  >
                    Go To Room Booking
                  </button>
                </>
              ) : hoterData.bookingStatus == 7 ? (
                <>
                  <h2 className="red flex gap-2 align-items-center">
                    <FontAwesomeIcon icon="fa fa-triangle-exclamation" beat />
                    Your booking has been Failed
                  </h2>
                  <button
                    className="goBackRoom"
                    onClick={() =>
                      navigate(`/hotel-details/${hoterData?.propertyId}`)
                    }
                  >
                    Go To Room Booking
                  </button>
                </>
              ) : (
                <>
                  <h2 className="green flex gap-2 align-items-center">
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                    Great! Your stay is confirmed.
                  </h2>
                  <p>
                    You will soon receive an email confirmation on{" "}
                    <a href={`mailto:${userDetail.email}`}>
                      {userDetail.email}
                    </a>
                  </p>
                  <button
                    className="goBackRoom"
                    onClick={() => navigate(`/user-profile`)}
                  >
                    Go To Your Bookings
                  </button>
                </>
              )}
            </div>
            {/* <button type="button" className="printBtn">Print now</button> */}
          </div>
          <div className="wrap">
            <div className="book flex">
              {hoterData?.bookingCode ? (
                <div className="col-left">
                  <h4 className="flex gap-1 align-items-center">
                    <FontAwesomeIcon icon="fa-solid fa-hashtag" />
                    Booking ID
                  </h4>
                  <p>{hoterData?.bookingCode}</p>
                </div>
              ) : null}
              <div className="col-right">
                <p>
                  <span className="fw-bold text-dark fs-6">
                    Booked by {userDetail.name} on
                  </span>{" "}
                  <br />
                  <div className="flex gap-2 align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-stopwatch"
                      color="green"
                    />
                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                      {hoterData?.createdAt}
                    </Moment>
                  </div>
                </p>
              </div>
            </div>
            <div className="product flex">
              <div className="col-left">
                <h4>{addressRenderer(hoterData.propertyId, "name")}</h4>
                {/* <h4>Flagship Vibhu</h4> */}
                <a href="" className="address text-dark">
                  <FontAwesomeIcon
                    icon="fa-solid fa-location-dot"
                    className="mr-1"
                    size="lg"
                    color="#333"
                  />{" "}
                  {hoterData?.PropertyMaster?.address}
                </a>
                <h4>Hotel direction</h4>
                <p className="text-dark">
                  <span>Landmark:</span>
                  {hoterData?.PropertyMaster?.landmark}
                </p>
              </div>
              <div className="col-right">
                <figure>
                  {hoterData?.PropertyMaster?.PropertyImages.length ? (
                    <img
                      src={
                        hoterData?.PropertyMaster?.PropertyImages
                          ? `${baseImgUrl}${hoterData?.PropertyMaster?.PropertyImages[0].image}`
                          : sliderImgOnly
                      }
                      alt=""
                    />
                  ) : (
                    <img alt="" src={sliderImgOnly} alt="" />
                  )}
                </figure>
              </div>
            </div>
            <ul className="think-section flex">
              <h4>Thinks to know</h4>
              <li>
                <p className="text-dark">
                  Somthing not right chat with us for help
                </p>
                <a href="#">Need help?</a>
              </li>
              <li>
                <p className="text-dark">RRooms Term and Condition</p>
                <a href="#">Read more</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* popup start here */}
      <div className="popup-second">
        <div className="wraps">
          <div className="closeBtn">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <h2>Transfer wallet money</h2>
          <form>
            <div className="form_group">
              <input type="text" placeholder="Cosutomer id" />
            </div>
            <div className="form_group">
              <input type="text" placeholder="Mobile Number" />
            </div>
            <div className="form_group">
              <input type="text" placeholder="₹ Transfer amount" />
            </div>
            <div className="form_group">
              <button type="submit">Transfer</button>
            </div>
          </form>
        </div>
      </div>
      {/* popup end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Bookingconfirm;
