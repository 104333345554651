import React from "react";
import { Container, Modal, Row } from "react-bootstrap";
import { propertyAllData } from "../../store/slices/PropertyData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Imagesdata } from "assets/images/imageData";
import { saveUser } from "store/slices/authenticationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PropertyModal = ({ user, show, setShow }) => {
  const { allProperty } = useSelector(propertyAllData);
  const { sliderImgOnly } = Imagesdata || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginUser = async (item = {}) => {
    dispatch(saveUser(user, item, navigate));
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      backdrop="static"
      className="checkInModal"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="color-black">
          Your Properties
        </Modal.Title>
        <button className="closeButton" onClick={() => setShow(false)}>
          X
        </button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="gap-4 mb-4">
            {allProperty?.map((item, ind) => (
              <div className="card" key={ind}>
                <div className="top-section">
                  <img alt="" src={sliderImgOnly} alt="" />
                </div>
                <div className="bottom-section">
                  <span className="title">{item.name}</span>
                  <span className="text-center w-100">
                    {item?.propertyCode}
                  </span>
                </div>
                <div className="flex justify-content-center">
                  <button onClick={() => loginUser(item)}>
                    Go To Dashboard
                    <FontAwesomeIcon
                      icon="fa-solid fa-right-from-bracket"
                      className="ml-2"
                    />
                  </button>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PropertyModal;
