/**
 * @description: importing all the images and exporting with in ImagesData Object
 * **/

import Logo from "./logo.png";
import DashboardIcon from "./dashboard-icon.png";
import BackArrow from "./backBtn.png";
import SearchIcon from "./search-icon.png";
import ChatIcon from "./chat-icon.png";
import ProfileImg from "./profile-img.jpg";
import PropertyImg from "./property-img.png";
import OnboardImg from "./onboard-img.png";
import PendingOnboardImg from "./pending-onboard-img.png";
import UserIcon from "./user-img.png";
import mainBigImg from "./mainbig-img.jpg";
import DropIcon from "./drop.png";
import SettingIcon from "./settings.png";
import ForwardIcon from "./forward-icon.png";
import BagIcon from "./Cart#3.png";
import InternetIcon from "./internet.png";
import Logolandingpage from "./logolandingpage.png";
import UserLoginIcon from "./userLoginIcon.png";
import LanguageIcon from "./LanguageIcon.png";
import HotelIcon from "./HotelIcon.png";
import FooterLogo from "./FooterLogo.png";
import FbIcon from "./facebook-icon.png";
import InstaIcon from "./instagram-icon.png";
import TwitterIcon from "./twitter-icon.png";
import TwitterIconNew from "./twitter.png";
import DribbleIcon from "./dribbble-icon.png";
import YoutubeIcon from "./youtube-icon.png";
import GooglePlay from "./google-play.png";
import ApplePlay from "./apple-play.png";
import ScanCode from "./scan-code.png";
import sliderImgOnly from "./sliderImgOnly.jpg";
import ChooseSectionImg from "./choose-right-img.png";
import popularSliderImg from "./popularSliderImg.png";
import mapIcon from "./mapIcon.png";
import forwardIconWhite from "./forwardIconWhite.png";
import ArrowIcon from "./Arrow-icon.png";
import cartIcon from "./cartIcons-list.png";
import CoupleFriendly from "./couple-friendlly.png";
import UpiIcon from "./upi-icon.png";
import CardIcon from "./card-icon.png";
import InternetBankingIcon from "./internet-banking-icon.png";
import GooglepayIcon from "./googlepay-icon.png";
import PayLaterIcon from "./pay-later-icon.png";
import VisaIcon from "./visaIcon.png";
import UserGrey from "./user-grey.png";
import CardPropImg from "./cardPropImg.png";
import WifiBlue from "./wifiBlue.png";
import RoomSize from "./room-size.png";
import AlertIcon from "./alert.svg";
import WalletIcon from "./walletIcon.png";
import HotalPay from "./hotal-pay.png";
import ParcialPay from "./parcial-pay.png";
import rroom1 from "./rroom-1.png";
import rroom2 from "./rroom-2.png";
import rroom3 from "./rroom-3.png";
import rroom4 from "./rroom-4.png";
import booking1 from "./booking1.png";
import booking2 from "./booking2.png";
import booking3 from "./booking3.png";
import UserlogoutIcon from "./UserlogoutIcon.png";
import booking4 from "./booking4.png";
import application1 from "./application1.png";
import application2 from "./application2.png";
import homeIcon from "./home.png";
import mainLogo from "./RROOMSlogo.png";
import WhatsappIcon from "./whatsapp.png";
import LinkedinIcon from "./linkedin.png";
import id1 from "./id1.png";
import id2 from "./id2.png";
import pay2 from "./pay2.png";
import kiss from "./kiss.png";
import id3 from "./id3.png";
import pay1 from "./pay1.png";
import userImg from "./user.png";
import city4 from "./city4.jpg";
import city2 from "./city2.jpg";
import lucknow from "./lucknowImge.jpg";
import mumbai from "./mumbai.jpg";
import indiagate from "./indiagate.jpg";
import kolkate from "./kolkata.jpg";
import pune from "./pune.jpg";
import gurgown from "./gurgown.jpg";
import city5 from "./city5.jpg";
import city3 from "./city3.jpg";
import noida from "./noida.png";
import allCities from "./allCities.png";
import noProperty from "./noProperty.png";
import slider1 from "./Banner1.jpg";
import slider2 from "./Banner2.jpg";
import slider3 from "./Banner3.jpg";
import slider4 from "./banner4.jpg";
import loginImg from "./loginImg.png";

import topTiles1 from "./Corporate Deal.jpg";
import topTiles2 from "./Couple Friendlly.jpg";
import topTiles3 from "./Family Vacation.jpg";
import topTiles4 from "./Group Check.jpg";
import topTiles5 from "./Guaranteed Check-Ins.jpg";
import topTiles6 from "./Late night Stay.jpg";
import topTiles7 from "./Solo Traveler.jpg";
import appAds from "./Slide12.png";
import testimonial from "./testimonial.png";
import dummyImg from "./dummyImg.png";
import agraImg from "./agra.jpg";
import logoRR from "./rr-logo.png";

// documents
import user_agreement from "../docs/user_agreement.pdf";

export const Imagesdata = {
  Logo,
  LinkedinIcon,
  DashboardIcon,
  BackArrow,
  SearchIcon,
  ChatIcon,
  ProfileImg,
  PropertyImg,
  OnboardImg,
  PendingOnboardImg,
  UserIcon,
  mainBigImg,
  DropIcon,
  SettingIcon,
  ForwardIcon,
  BagIcon,
  InternetIcon,
  Logolandingpage,
  UserLoginIcon,
  LanguageIcon,
  HotelIcon,
  FooterLogo,
  FbIcon,
  InstaIcon,
  TwitterIcon,
  DribbleIcon,
  YoutubeIcon,
  GooglePlay,
  ApplePlay,
  ScanCode,
  sliderImgOnly,
  ChooseSectionImg,
  popularSliderImg,
  mapIcon,
  forwardIconWhite,
  ArrowIcon,
  cartIcon,
  CoupleFriendly,
  UpiIcon,
  CardIcon,
  InternetBankingIcon,
  GooglepayIcon,
  PayLaterIcon,
  VisaIcon,
  UserGrey,
  CardPropImg,
  WifiBlue,
  RoomSize,
  AlertIcon,
  WalletIcon,
  HotalPay,
  ParcialPay,
  rroom1,
  rroom2,
  rroom3,
  rroom4,
  booking1,
  booking2,
  booking3,
  mainLogo,
  booking4,
  application1,
  application2,
  homeIcon,
  UserlogoutIcon,
  WhatsappIcon,
  TwitterIconNew,
  id1,
  id2,
  pay2,
  kiss,
  id3,
  pay1,
  userImg,
  city4,
  city2,
  allCities,
  noProperty,
  mainBigImg,
  slider1,
  slider2,
  slider3,
  slider4,
  loginImg,
  topTiles1,
  topTiles2,
  topTiles3,
  topTiles4,
  topTiles5,
  topTiles6,
  topTiles7,
  appAds,
  testimonial,
  lucknow,
  mumbai,
  indiagate,
  kolkate,
  pune,
  gurgown,
  city5,
  city3,
  noida,
  allCities,
  dummyImg,
  agraImg,
  logoRR,
  // docs
  user_agreement,
};
