import PropertyService from "API/services/PropertyService";
import { Imagesdata } from "assets/images/imageData";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { number2words, showPercentage, showPrice } from "utils/utils";
import { Player } from "@lottiefiles/react-lottie-player";
import loderLogo from "../../API/Interceptor/loderLogo.png";
import loader from "../../API/Interceptor/load.json";
import { qrcodeGenerator } from "react-easy-qrcode-generator";
import { getInvoiceById } from "API/services/newServices";

const InvoiceDownload = () => {
  const { id, propertyId } = useParams();
  const { logoRR } = Imagesdata;
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  // const data = location.state;
  const [data, setData] = useState({});
  const [currectProperty, setCurrectProperty] = useState({});
  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "roomInvoice.pdf",
    page: { margin: Margin.MEDIUM, format: "letter" },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 10,
    },
    overrides: {
      pdf: {
        compress: false,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  const getInvoiceData = async () => {
    const res = await getInvoiceById(id);
    if (res) {
      setData(res.data);
    }
  };

  const getCurrentProperty = async () => {
    try {
      const res = await PropertyService.getPropertyCategoryServiceId(
        propertyId
      );
      if (res.status === 200) {
        setCurrectProperty(res.data.data);
        setTimeout(() => {
          toPDF();
          navigate("/hotel-finance/invoice");
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    qrcodeGenerator({
      value: "https://rrooms.in/",
      size: "100x100",
      title: "Title",
      qrAlt: "QR Code Image",
      showQrId: "qr-code",
    });
    getInvoiceData();
    getCurrentProperty();
  }, []);

  return (
    <div className="invoice-for-commision my-3">
      <div
        ref={targetRef}
        className="container bg-white rounded-lg"
        style={{ margin: "0 auto", width: 760, boxSizing: "border-box" }}
        id="download-invoice"
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <img alt="" src={logoRR} style={{ width: 113 }} />
          <div className="bar-code m-2">
            <div id="qr-code" />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <table style={{ width: "auto" }}>
            <tbody>
              {/* <tr>
                  <th
                    style={{
                      textAlign: "left",
                      fontSize: 11,
                      fontWeight: 600,
                    }}
                  >
                    IRN:
                  </th>
                  <td
                    style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                  >
                    55d41751b0cbaee2a0df49ea1e5fed0041cd64a3f39577a0bb6c0225a9a4b4a8
                  </td>
                </tr> */}
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Ack No:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  {data.invoice_id}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Ack Date:{" "}
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  &nbsp;{moment(data.createdAt).format("DD-MM-YYYY HH:MM A")}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "auto" }}>
            <tbody>
              {/* <tr>
                  <th
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      fontWeight: 600,
                    }}
                  >
                    IRN:
                  </th>
                  <td
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      fontWeight: 600,
                    }}
                  >
                    20232402IN021301
                  </td>
                </tr> */}
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Invoice:
                </th>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  {data.invoice_id}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Generated:
                </th>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  &nbsp;{moment().format("DD-MM-YYYY HH:MM A")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 10,
          }}
        >
          <table style={{ width: "auto" }}>
            <tbody>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Bill From:
                </th>
                <td />
              </tr>
              <tr>
                <th
                  style={{ textAlign: "left", fontSize: 13, fontWeight: 800 }}
                >
                  RRooms Hospitality India Private Limited
                </th>
                <td />
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  GSTIN/UIN:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  09AAMCR6291B1Z4
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  State Name:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  Uttar Pradesh
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  State Code:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  101
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "auto" }}>
            <tbody>
              <tr>
                <th />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Bill To:
                </td>
              </tr>
              <tr>
                <th />
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 13,
                    fontWeight: 800,
                  }}
                >
                  {currectProperty.id} - {currectProperty.name}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  GSTIN/UIN:
                </th>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  {currectProperty.gstNumber}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  Legal Name:
                </th>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {currectProperty.legalName}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  State Name:
                </th>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  {currectProperty.hasOwnProperty("state")
                    ? currectProperty?.state[0]?.name
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  State Code:
                </th>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    fontWeight: 600,
                  }}
                >
                  {currectProperty.stateId}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="product-table" style={{ paddingTop: 10 }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    minWidth: 20,
                    textAlign: "left",
                    backgroundColor: "#ddd",
                    padding: "5px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  S.No.
                </th>
                <th
                  style={{
                    textAlign: "left",
                    backgroundColor: "#ddd",
                    padding: "5px 8px 10px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  Particulars
                </th>
                <th
                  style={{
                    textAlign: "left",
                    backgroundColor: "#ddd",
                    padding: "5px 8px 10px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  Rate
                </th>
                <th
                  style={{
                    textAlign: "right",
                    backgroundColor: "#ddd",
                    padding: "5px 8px 10px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  1.
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  Commission
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                />
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                    textAlign: "right",
                  }}
                >
                  {showPrice(data.totalCommission)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  2.
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  Output CGST
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  {showPercentage(data.cgstPercentage)}
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                    textAlign: "right",
                  }}
                >
                  {showPrice(data.cgstAmount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  3.
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  Output SGST
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  {showPercentage(data.sgstPercentage)}
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                    textAlign: "right",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  {showPrice(data.sgstAmount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: 15,
                    fontWeight: 800,
                    padding: "7px 6px",
                    textAlign: "right",
                  }}
                  colSpan={4}
                >
                  Total: {showPrice(data.totalPayableAmount)}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h5
                style={{
                  marginTop: 5,
                  marginBottom: 0,
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                chargeable Amount (in words) :
              </h5>
              <h5
                style={{
                  marginTop: 5,
                  marginBottom: 0,
                  fontSize: 13,
                  fontWeight: 800,
                }}
              >
                INR: {number2words(data.totalPayableAmount)}
              </h5>
            </div>
            <h5
              style={{
                marginTop: 5,
                marginBottom: 0,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              E. &amp; O.E
            </h5>
          </div>
        </div>
        <div className="product-table" style={{ paddingTop: 15 }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    verticalAlign: "top",
                    textAlign: "left",
                    backgroundColor: "#ddd",
                    padding: "5px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  HSN/SAC
                </th>
                <th
                  style={{
                    verticalAlign: "top",
                    textAlign: "left",
                    backgroundColor: "#ddd",
                    padding: "5px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  Taxable Value
                </th>
                <th
                  style={{
                    verticalAlign: "top",
                    textAlign: "center",
                    backgroundColor: "#ddd",
                    padding: "5px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  Central Tax
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                    >
                      Rate
                    </h5>
                    <h5
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                    >
                      Amount
                    </h5>
                  </div>
                </th>
                <th
                  style={{
                    verticalAlign: "top",
                    textAlign: "center",
                    backgroundColor: "#ddd",
                    padding: "5px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  State Tax
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                    >
                      Rate
                    </h5>
                    <h5
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                    >
                      Amount
                    </h5>
                  </div>
                </th>
                <th
                  style={{
                    verticalAlign: "top",
                    textAlign: "right",
                    backgroundColor: "#ddd",
                    padding: "5px 8px",
                    fontWeight: 800,
                    fontSize: 13,
                  }}
                >
                  Total Tax Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  1.
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  Commission
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5
                      style={{
                        margin: 5,
                        fontSize: 11,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {showPercentage(data.cgstPercentage)}
                    </h5>
                    <h5
                      style={{
                        margin: 5,
                        fontSize: 11,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {showPrice(data.cgstAmount)}
                    </h5>
                  </div>
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5
                      style={{
                        margin: 5,
                        fontSize: 11,
                        fontWeight: 600,
                        paddingRight: 10,
                      }}
                    >
                      {showPercentage(data.sgstPercentage)}
                    </h5>
                    <h5 style={{ margin: 5, fontSize: 11, fontWeight: 600 }}>
                      {showPrice(data.sgstAmount)}
                    </h5>
                  </div>
                </td>
                <td
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    padding: "7px 6px",
                    textAlign: "right",
                  }}
                >
                  {showPrice(+data.cgstAmount + +data.sgstAmount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: 13,
                    fontWeight: 800,
                    padding: "3px 6px",
                  }}
                >
                  Total
                </td>
                <td
                  style={{
                    fontSize: 13,
                    fontWeight: 800,
                    padding: "3px 6px",
                  }}
                >
                  {showPrice(+data.cgstAmount + +data.sgstAmount)}
                </td>
                <td
                  style={{
                    fontSize: 13,
                    fontWeight: 800,
                    padding: "3px 6px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 />
                    <h5
                      style={{
                        margin: 5,
                        fontSize: 13,
                        fontWeight: 800,
                        textAlign: "center",
                      }}
                    >
                      {showPrice(data.cgstAmount)}
                    </h5>
                  </div>
                </td>
                <td
                  style={{
                    fontSize: 13,
                    fontWeight: 800,
                    padding: "3px 6px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 />
                    <h5 style={{ margin: 5, fontSize: 13, fontWeight: 800 }}>
                      {showPrice(data.sgstAmount)}
                    </h5>
                  </div>
                </td>
                <td
                  style={{
                    fontSize: 13,
                    fontWeight: 800,
                    padding: "3px 6px",
                    textAlign: "right",
                  }}
                >
                  {showPrice(+data.cgstAmount + +data.sgstAmount)}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5
              style={{
                marginTop: 5,
                marginBottom: 0,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Tax Amount (in words) :
            </h5>
            <h5
              style={{
                marginTop: 5,
                marginBottom: 0,
                fontSize: 13,
                fontWeight: 800,
              }}
            >
              INR: {number2words(+data.cgstAmount + +data.sgstAmount)}
            </h5>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 20,
          }}
        >
          <table style={{ width: "auto" }}>
            <tbody>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Company Bank Details
                </th>
              </tr>
              <tr>
                <th
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  Bank Name:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  HDFC BANK
                </td>
              </tr>
              <tr>
                <th
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  A/c No:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  99979999999992
                </td>
              </tr>
              <tr>
                <th
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  Branch &amp; IFSC Code:
                </th>
                <td
                  style={{ textAlign: "left", fontSize: 11, fontWeight: 600 }}
                >
                  HDFC0009451
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "auto" }}>
            <tbody>
              <tr>
                <th
                  style={{
                    textAlign: "right",
                    fontSize: 14,
                    fontWeight: 600,
                    verticalAlign: "top",
                  }}
                >
                  RROOMS HOSPITALITY INDIA PRIVATE LIMITED
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th style={{ textAlign: "right", fontWeight: 800 }}>
                Authorized Signatory
              </th>
            </tr>
          </tbody>
        </table>
        <ul
          style={{
            textAlign: "center",
            borderTop: "5px solid #ddd",
            paddingTop: 10,
          }}
        >
          <li
            style={{
              listStyle: "none",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            RROOMS HOSPITALITY INDIA PRIVATE LIMITED
          </li>
          <li
            style={{
              listStyle: "none",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            B2-1409, DLF My Pad, VIBHUTI KHAND GOMTI NAGAR, Gomti Nagar,
            Lucknow, Lucknow, Uttar Pradesh, 226010
          </li>
          <li
            style={{
              listStyle: "none",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            AAMCR6291B | GST Number: 09AAMCR6291B1Z4
          </li>
          <li
            style={{
              listStyle: "none",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            CIN: U55101UP2023PTC180891 | E-Mail : contact@rrooms.in
          </li>
          <li
            style={{
              listStyle: "none",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            This is a Computer Generated Invoice and no signature is required.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InvoiceDownload;
