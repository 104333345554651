import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserValidation from "../../constant/validations/form.validation.messages";

const validation = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .trim()
    .max(100)
    .email(addUserValidation.valid_email)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addUserValidation.valid_email
    )
    .required(addUserValidation.email_required),

  propertyEmail: Yup.string()
    .label("Email")
    .trim()
    .max(100)
    .email(addUserValidation.valid_email)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addUserValidation.valid_email
    )
    .required(addUserValidation.email_required),

  firstName: Yup.string()
    .trim()
    .required(addUserValidation.addUser.first_name_required)
    .matches(/^[A-Za-z ]+$/, addUserValidation.valid_name)
    .max(200, addUserValidation.max_firstname_length),

  phoneNumber: Yup.string()
    .required(addUserValidation.phone_invalid)
    .matches(/^\+?[0-9]{10}$/, "Enter Correct Mobile Number*"),
  propertyPhone: Yup.string()
    .required(addUserValidation.phone_invalid)
    .min(10)
    .max(10),
  propertyPhone: Yup.string()
    .required(addUserValidation.phone_invalid)
    .min(10)
    .max(10),
  panNumber: Yup.string()
    .required(addUserValidation.panNumber)
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please Enter Correct Pan Number*"),
  adharNumber: Yup.string()
    .required(addUserValidation.adharNumber)
    .min(12)
    .max(12),
  propertyName: Yup.string()
    .required(addUserValidation.propertyName)
    .min(4)
    .max(50),
  Locality: Yup.string().required("Please Enter Locality*"),
  propertyAddress: Yup.string().required(addUserValidation.propertyAddress),
  // bookingPolicy: Yup.string().required(addUserValidation.require).min(5),
  longitude: Yup.string()
    .required("Please Enter Longitude*")
    .matches(/^\d{2}\.\d{7}$/, "Enter Correct Longitude*"),
  latitude: Yup.string()
    .required("Please Enter Latitude*")
    .matches(/^\d{2}\.\d{7}$/, "Enter Correct Latitude*"),
  // .min(10, "Enter Correct Latitude*"),
  postalCode: Yup.string().required(addUserValidation.postalCode).min(6).max(6),
  propertyDescription: Yup.string()
    .required(addUserValidation.propertyDescription)
    .min(10),
  firmType: Yup.string().required("Please Select Category*"),
  amenities: Yup.array().min(1).of(Yup.string().required()).required(),
  city: Yup.string().required("select City"),
  noOfRooms: Yup.string().required(" Enter Number of Rooms*"),
});

export default { resolver: yupResolver(validation) };
