import React, { useEffect } from "react";
import LandingHeader from "../../components/landingHeader";
import FrontFooter from "../../layout/footer/FrontFooter";

function UserAgreement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hotalList">
      {/* landing page header */}
      <LandingHeader />
      {/* banner section */}
      <div className="hotalListHeight">
        <div className="homeBanner"></div>
      </div>
      {/* content section start here */}
      <section className="contentMains">
        <div className="container">
          <h1>User Agreement</h1>
          <h2>APPLICABILITY</h2>
          <ol className="bulletNotHide">
            <li>
              This User Agreement along with Terms of Service (collectively, the
              "User Agreement") forms the terms and conditions for the use of
              services and products of RRooms Hospitality India Private Limited.
            </li>
            <li>
              Any person ("User") who inquiries about or purchases any products
              or services of RRooms through its Website/Apps, mobile
              applications, sales persons, offices, call centers, branch
              offices, franchisees, agents etc. (all the aforesaid platforms
              collectively referred to as “Sales Channels”) agree to be governed
              by this User Agreement. The Website/Apps and the mobile
              applications of RRooms are collectively referred to as
              ‘Website/App’.
            </li>
            <li>
              Both User and RRooms are individually referred to as 'Party' and
              collectively referred to as 'Parties' to the User Agreement.
            </li>
            <li>
              “Terms of Service” available on RRooms’s Website/App details out
              terms & conditions applicable on various services or products
              facilitated by RRooms. The User should refer to the relevant Terms
              of Service applicable for the given product or service as booked
              by the User. Such Terms of Service are binding on the User.
            </li>
          </ol>
          <h2>ELIGIBILITY TO USE</h2>
          <ol className="bulletNotHide">
            <li>
              The User must be atleast 18 years of age and must possess the
              legal authority to enter into an agreement so as become a User and
              use the services of RRooms. If you are a minor or are below the
              age of 18 years, you shall not register as a User of the
              Website/App and shall not transact on or use the Website/App.
            </li>
            <li>
              As a minor if you wish to use or transact on the Website/App, such
              use or transaction shall only be made by a person of legal
              contracting age (legal guardian or parents). We reserve the right
              to terminate your membership and/or block access to the
              Website/App if it is discovered that you are a minor or
              incompetent to contract according to the law or any information
              pertaining to your age entered at the time of creation of account
              is false.
            </li>
            <li>
              Before using the Website/App, approaching any Sales Channels or
              procuring the services of RRooms, the Users shall compulsorily
              read and understand this User Agreement, and shall be deemed to
              have accepted this User Agreement as a binding document that
              governs User’s dealings and transactions with RRooms. If the User
              does not agree with any part of this Agreement, then the User must
              not avail RRooms's services and must not access or approach the
              Sales Channels of RRooms.
            </li>
            <li>
              All rights and liabilities of the User and RRooms with respect to
              any services or product facilitated by RRooms shall be restricted
              to the scope of this User Agreement.
            </li>
          </ol>
          <h2>CONTENT</h2>
          <ol className="bulletNotHide">
            <li>
              All content provided through various Sales Channels, including but
              not limited to audio, images, software, text, icons and such
              similar content ("Content"), are registered by RRooms and
              protected under applicable intellectual property laws. User cannot
              use this Content for any other purpose, except as specified
              herein.
            </li>
            <li>
              User agrees to follow all instructions provided by RRooms which
              will prescribe the way such User may use the Content.
            </li>
            <li>
              There are a number of proprietary logos, service marks and
              trademarks displayed on the Website/App and through other Sales
              Channels of RRooms, as may be applicable. RRooms does not grant
              the User a license, right or authority to utilize such proprietary
              logos, service marks, or trademarks in any manner. Any
              unauthorized use of the Content, will be in violation of the
              applicable law.
            </li>
            <li>
              RRooms respects the intellectual property rights of others. If you
              notice any act of infringement on the Website/App, you are
              requested to send us a written notice/ intimation which must
              include the following information;
            </li>
            <li>
              clear identification of such copyrighted work that you claim has
              been infringed;
            </li>
            <li>
              location of the material on the Website/App, including but not
              limited to the link of the infringing material
            </li>
            <li>
              The proof that the alleged copyrighted work is owned by you;
            </li>
          </ol>
          <h2>Contact information</h2>
          <li>
            The aforesaid notices can be sent to RRooms by email at
            <a href="mailto:support@rrooms.in">support@rrooms.in</a>.
          </li>
          <h2>WEBSITE/APP</h2>
          <ol className="bulletNotHide">
            <li>
              The Website/App is meant to be used by bonafide User(s) for a
              lawful use.
            </li>
            <li>
              User shall not distribute exchange, modify, sell or transmit
              anything from the Website/App, including but not limited to any
              text, images, audio and video, for any business, commercial or
              public purpose.
            </li>
            <li>
              The User Agreement grants a limited, non-exclusive,
              non-transferable right to use this Website/App as expressly
              permitted in this User Agreement. The User agrees not to interrupt
              or attempt to interrupt the operation of the Website/App in any
              manner whatsoever.
            </li>
            <li>
              Access to certain features of the Website/App may only be
              available to registered User(s). The process of registration, may
              require the User to answer certain questions or provide certain
              information that may or may not be personal in nature. Some such
              fields may be mandatory or optional. User represents and warrants
              that all information supplied to RRooms is true and accurate.
            </li>
            <li>
              RRooms reserves the right, in its sole discretion, to terminate
              the access to the Website/App and the services offered on the same
              or any portion thereof at any time, without notice, for general
              maintenance or any other reason whatsoever.
            </li>
            <li>
              RRooms will always make its best endeavors to ensure that the
              content on its Website/Apps or other sales channels are free of
              any virus or such other malwares. However, any data or information
              downloaded or otherwise obtained through the use of the
              Website/App or any other Sales Channel is done entirely at the
              User’s own discretion and risk and they will be solely responsible
              for any damage to their computer systems or loss of data that may
              result from the download of such data or information.
            </li>
            <li>
              RRooms reserves the right to periodically make improvements or
              changes in its Website/App at any time without any prior notice to
              the User. User(s) are requested to report any content on the
              Website/App which is deemed to be unlawful, objectionable,
              libelous, defamatory, obscene, harassing, invasive to privacy,
              abusive, fraudulent, against any religious beliefs, spam, or is
              violative of any applicable law to support@rrooms.in On receiving
              such report, RRooms reserves the right to investigate and/or take
              such action as the Company may deem appropriate.
            </li>
          </ol>
          <h2>BOOKINGS BY TRAVEL AGENTS</h2>
          <ol className="bulletNotHide">
            <li>
              Except with the prior registration with RRooms as B2B agents,
              priority partner or a franchisee, and explicit permission of
              RRooms to use the Website/App, all travel agents, tour operators,
              consolidators or aggregators (“Travel Agents”) are barred from
              using this Website/App for any commercial or resale purpose. If
              any such bookings are detected, RRooms reserves the right,
              including without limitation, to cancel all such bookings
              immediately without any notice to such travel agents and also to
              withhold payments or any refunds thereto. RRooms shall not be held
              liable for any incidental loss or damage that may arise from the
              bookings made by any person through such Travel Agents. The
              liability in case of such cancellations shall be solely borne by
              such Travel Agents.
            </li>
            <li>
              All discounts and offers mentioned on the Website/App are
              applicable only to the User(s) of RRooms for legitimate bookings.
            </li>
          </ol>
          <h2>LIMITED LIABILITY OF RRooms</h2>
          <ol className="bulletNotHide">
            <li>
              Unless RRooms explicitly acts as a reseller in certain scenarios,
              RRooms always acts as a facilitator by connecting the User with
              the respective service providers like airlines, hotels,
              restaurants, bus operators etc. (collectively referred to as
              “Service Providers”). RRooms’s liability is limited to providing
              the User with a confirmed booking as selected by the User.
            </li>
            <li>
              Any issues or concerns faced by the User at the time of availing
              any such services shall be the sole responsibility of the Service
              Provider. RRooms will have no liability with respect to the acts,
              omissions, errors, representations, warranties, breaches or
              negligence on part of any Service Provider
            </li>
          </ol>
          <h2>
            Unless explicitly committed by RRooms as a part of any product or
            service:
          </h2>
          <ol className="bulletNotHide">
            <li>
              RRooms assumes no liability for the standard of services as
              provided by the respective Service Providers.
            </li>
            <li>
              RRooms provides no guarantee with regard to their quality or
              fitness as represented.
            </li>
            <li>
              RRooms doesn’t guarantee the availability of any services as
              listed by a Service Provider
            </li>
            <li>
              By making a booking, User understands RRooms merely provides a
              technology platform for booking of services and products and the
              ultimate liability rests on the respective Service Provider and
              not RRooms. Thus the ultimate contract of service is between User
              and Service Provider.
            </li>
            <li>
              User further understands that the information displayed on the
              Website/App with respect to any service is displayed as furnished
              by the Service Provider. RRooms, therefore cannot be held liable
              in case if the information provided by the Service Provider is
              found to be inaccurate, inadequate or obsolete or in contravention
              of any laws, rules, regulations or directions in force.
            </li>
          </ol>
          <h2>USER’S RESPONSIBILITY</h2>
          <ol className="bulletNotHide">
            <li>
              Users are advised to check the description of the services and
              products carefully before making a booking. User(s) agree to be
              bound by all the conditions as contained in booking confirmation
              or as laid out in the confirmed booking voucher. These conditions
              are also to be read in consonance with the User Agreement.
            </li>
            <li>
              If a User intends to make a booking on behalf of another person,
              it shall be the responsibility of the User to inform such person
              about the terms of this Agreement, including all rules and
              restrictions applicable thereto.
            </li>
            <li>
              The User undertakes to abide by all procedures and guidelines, as
              modified from time to time, in connection with the use of the
              services available through RRooms. The User further undertakes to
              comply with all applicable laws, regulations, orders, directions
              etc. issued by either the Central Government, State Government,
              District Authorities or any other statutory body empowered to do
              so w.r.t use of services or for each transaction.
            </li>
            <li>
              The services are provided on an "as is" and "as available" basis.
              RRooms may change the features or functionality of the services
              being provided at any time, in its sole discretion, without any
              prior notice. RRooms expressly disclaims all warranties of any
              kind, whether express or implied, including, but not limited to
              the implied warranties of merchantability, reasonably fit for all
              purposes. No advice or information, whether oral or written, which
              the User obtains from RRooms or through the services opted shall
              create any warranty not expressly made herein or in the terms and
              conditions of the services.
            </li>
            <li>
              User also authorizes RRooms’s representative to contact such user
              over phone, message and email. This consent shall supersede any
              preferences set by such User through national customer preference
              register (NCPR) or any other similar preferences.
            </li>
          </ol>
          <h2>SECURITY AND ACCOUNT RELATED INFORMATION</h2>
          <ol className="bulletNotHide">
            <li>
              While registering on the Website/App, the User will have to choose
              a password to access that User’s account and User shall be solely
              responsible for maintaining the confidentiality of both the
              password and the account as well as for all activities on the
              account. It is the duty of the User to notify RRooms immediately
              in writing of any unauthorized use of their password or account or
              any other breach of security. RRooms will not be liable for any
              loss that may be incurred by the User as a result of unauthorized
              use of the password or account, either with or without the User’s
              knowledge. The User shall not use anyone else's account at any
              time.
            </li>
            <li>
              For logging-in or sign-up on the Website/App and/or mobile & web
              applications, the User has an option to voluntarily sign-up or
              login through a phone number verification tool integrated with a
              third-party partner of RRooms. For the avoidance of doubt, login
              or sign-up of the User via such SDK verification process will at
              all times be subject to the User giving its consent to RRooms for
              engaging the third-party partner. Under this login or sign-up
              option, no Personal Information or Data of the User will be shared
              by RRooms with the third-party partner.
            </li>
            <li>
              User understands that any information that is provided to this
              Website/App may be read or intercepted by others due to any breach
              of security at the User’s end.
            </li>
            <li>
              RRooms keeps all the data in relation to credit card, debit card,
              bank information etc. secured and in an encrypted form in
              compliance with the applicable laws and regulations. However, for
              cases of fraud detection, offering bookings on credit (finance)
              etc., RRooms may at times verify certain information of its Users
              like their credit score, as and when required.
            </li>
            <li>
              Additionally, RRooms may share your Personal Information in an
              anonymized and/ or aggregated form with a third party that RRooms
              may engage to perform certain tasks on its behalf or to fulfill
              the desired booking, including but not limited to payment
              processing, data hosting, data processing , credit score and
              assessing credit worthiness for offering bookings on credit in
              accordance with the applicable laws.
            </li>
            <li>
              Additionally, RRooms may share your Personal Information in an
              anonymized and/ or aggregated form with a third party that RRooms
              may engage to perform certain tasks on its behalf, including but
              not limited to payment processing, data hosting, data processing ,
              credit score and assessing credit worthiness for offering bookings
              on credit in accordance with the applicable laws.
            </li>
            <li>
              RRooms adopts the best industry standard to secure the information
              as provided by the User. However, RRooms cannot guarantee that
              there will never be any security breach of its systems which may
              have an impact on User’s information too.
            </li>
            <li>
              The data of the User as available with RRooms may be shared with
              concerned law enforcement agencies for any lawful or investigation
              purpose without the consent of the User.
            </li>
          </ol>
          <h2>FEES AND PAYMENT</h2>
          <ol className="bulletNotHide">
            <li>
              In addition to the cost of booking as charged by the Service
              Providers, RRooms reserves the right to charge certain fees in the
              nature of convenience fees or service fees. RRooms further
              reserves the right to alter any and all fees from time to time.
              Any such additional fees, including fee towards any modifications
              thereof, will be displayed to the User before confirming the
              booking or collecting the payment from such User.
            </li>
            <li>
              In cases of short charging of the booking amount, taxes, statutory
              fee, convenience fee etc., owing to any technical error or other
              reason, RRooms shall reserve the right to deduct, charge or claim
              the balance amount from the User and the User shall pay such
              balance amount to RRooms. In cases where the short charge is
              claimed prior to the utilization of the booking, RRooms will be at
              liberty to cancel such bookings if the amount is not paid before
              the utilization date.
            </li>
            <li>
              Any increase in the price charged by RRooms on account of change
              in rate of taxes or imposition of new taxes, levies by Government
              shall have to be borne by the User. Such imposition of taxes,
              levies may be without prior notice and could also be retrospective
              but will always be as per applicable law.
            </li>
            <li>
              In the rare circumstance of a booking not getting confirmed for
              any reason whatsoever, RRooms will process the refund of the
              booking amount paid by the User and intimate the User about the
              same. RRooms is not under any obligation to provide an alternate
              booking in lieu of or to compensate or replace the unconfirmed
              booking. All subsequent bookings will be treated as new
              transactions. Any applicable refund will be processed as per the
              defined policies of the service provider and RRooms as the case
              may be.
            </li>
            <li>
              The User shall be completely responsible for all charges, fees,
              duties, taxes, and assessments arising out of the use of the
              service, as per the applicable laws
            </li>
            <li>
              The User agrees and understands that all payments shall only be
              made to bank accounts of RRooms. RRooms or its agents,
              representatives or employees shall never ask a customer to
              transfer money to any private account or to an account not held in
              the name of RRooms. The User agrees that if that user transfers
              any amount against any booking or transaction to any bank account
              that is not legitimately held by RRooms or to any personal account
              of any person, RRooms shall not be held liable for the same. User
              shall not hold any right to recover from RRooms any amount which
              is transferred by the User to any third party.
            </li>
            <li>
              The User will not share his personal sensitive information like
              credit/debit card number, CVV, OTP, card expiry date, user IDs,
              passwords etc. with any person including the agents, employees or
              representatives of RRooms. The User shall immediately inform
              RRooms if such details are demanded by any of its agents’
              employees or representatives. RRooms shall not be liable for any
              loss that the User incurs for sharing the aforesaid details.
            </li>
            <li>
              RRooms provides various modes of making payments on the
              Website/App for transacting, like UPI, Credit/Debit Cards of
              various banks, Net Banking facility of all major banks, RRooms and
              third party Wallets, Gift Cards, EMI and more.
            </li>
            <li>
              Refunds, if any, on cancelled bookings will always be processed to
              the respective account or the banking instrument (credit card,
              wallet etc.) from which payment was made for that booking.
            </li>
            <li>
              Booking(s) made by the User through RRooms are subject to the
              applicable cancellation policy as set out on the booking page or
              as communicated to the customers in writing.
            </li>
            <li>
              Any cashback earned by the User as RRoomsCash will be debited from
              User’s wallet in case of any partial/full cancellation,
              modifications including but not limited to date change, name
              change etc. of the bookings made. In event if the cashback is
              already utilized by the User for any further bookings or against
              the modified booking then RRooms reserves the right to adjust the
              cashback amount at time of refund against the cancelled booking or
              charge the utilized cashback amount with the modified booking
              amount as the case maybe. The User agrees and acknowledges that
              RRooms shall be the sole authority to decide the credit or
              adjustment of the cashback amount pursuant to this clause and in
              case of any dispute, decision taken by RRooms shall be final and
              binding.
            </li>
          </ol>
          <h2>
            USAGE OF THE MOBILE NUMBER, COMMUNICATION DETAILS OF THE USER BY
            RRooms
          </h2>
          <ol className="bulletNotHide">
            <li>
              {" "}
              RRooms will send booking confirmation, itinerary information,
              cancellation, payment confirmation, refund status, schedule change
              or any such other information relevant for the transaction or
              booking made by the User, via SMS, internet-based messaging
              applications like WhatsApp, voice call, e-mail or any other
              alternate communication detail provided by the User at the time of
              booking.
            </li>
            <li>
              {" "}
              RRooms may also contact the User through the modes mentioned above
              for any pending or failed bookings, to know the preference of the
              User for concluding the booking and also to help the User for the
              same.
            </li>
            <li>
              {" "}
              The User hereby unconditionally consents that such communications
              via SMS, internet-based messaging applications like WhatsApp,
              voice call, email or any other mode by RRooms are:
            </li>
            <li> upon the request and authorization of the User;</li>
            <li>
              {" "}
              ‘transactional’ and not an ‘unsolicited commercial communication’
              as per the guidelines of Telecom Regulation Authority of India
              (TRAI), and
            </li>
            <li>
              {" "}
              in compliance with the relevant guidelines of TRAI or such other
              authority in India and abroad.
            </li>
            <li>
              {" "}
              The User will indemnify RRooms against all types of losses and
              damages incurred by RRooms due to any action taken by TRAI, Access
              Providers (as per TRAI regulations) or any other authority due to
              any erroneous complaint raised by the User on RRooms with respect
              to the communications mentioned above or due to a wrong number or
              email id being provided by the User for any reason whatsoever.
            </li>
          </ol>
          <h2>INSURANCE</h2>

          <ol className="bulletNotHide">
            <li>
              Unless explicitly provided by RRooms in any specific service or
              deliverable, obtaining sufficient insurance coverage is the
              obligation of the User. In no case RRooms shall accept any claims
              arising out of such scenarios.
            </li>
            <li>
              Insurance, if any provided as a part of the service or product by
              RRooms shall be as per the terms and conditions of the third-party
              insurance company. RRooms merely acts as a facilitator in
              connecting the User with insurance company. The User shall contact
              the insurance company directly for any claims or disputes. RRooms
              shall not be held liable in case of partial acceptance or denial
              of the claims by the insurance company.
            </li>
          </ol>
          <h2>COMPLIANCE OF LIBERALIZED REMITTANCE SCHEME (LRS)</h2>
          <ol className="bulletNotHide">
            <li>
              The RBI mandates collection of PAN details for all transactions
              made under Liberalized Remittance Scheme (LRS) which include any
              international booking made on the Website/App or through Sales
              Channels. The User warrants and confirms that PAN details of the
              User/traveler will be shared by the User on or before the cut-off
              date prescribed by RRooms either at the time of booking or after
              the booking is made. In case the traveler is a minor, RRooms will
              require PAN details of the parent/guardian of such minor. The User
              further confirms that non-compliance of this requirement may
              result in cancellation of the booking.
            </li>
            <li>
              The User warrants and confirms that the total amount of foreign
              exchange purchased or remitted during the current financial year,
              through all sources in India (including the current transaction)
              falls within the permissible limit prescribed by the RBI. The User
              further confirms that foreign exchange, if any, purchased by User
              for the purpose of international travel under the current booking
              will be utilized for the purpose indicated above.
            </li>
            <li>
              The User authorizes RRooms to retrieve User’s/ traveler’s (in case
              booking on someone’s behalf) PAN details from Users profile,
              previous bookings or share User/traveller’s data with third
              party(ies) for collecting or verifying PAN details solely for the
              purposes mentioned in this Agreement.
            </li>
            <li>
              The User understands RRooms does not collect User’s PAN details
              without consent. If the User wishes to opt of providing the same
              or wishes to delete it if already provided, the User may do so by
              accessing the profile information through the Desktop.
            </li>
          </ol>
          <h2>OBLIGATION TO OBTAIN VISA</h2>
          <ol className="bulletNotHide">
            <li>
              International bookings made through RRooms are subject to the
              requirements of visa including but not limited to transit visa, OK
              TO BOARD which are to be obtained by the User as per the
              requirement of their travel bookings and the requirements of the
              countries the User intends to visit or transit through.
            </li>
            <li>
              RRooms is not responsible for any issues, including inability to
              travel, arising out of such visa requirements and is also not
              liable to refund any amount to the User for being unable to
              utilize the hotel booking due to absence or denial of visa,
              irrespective whether or not the User has availed the services of
              RRooms for the visa process too. Refund, if any, will be as per
              the applicable terms of booking and cancellation policy.
            </li>
          </ol>
          <h2>OBLIGATION TO OBTAIN VISA</h2>
          <ol className="bulletNotHide">
            <li>
              There can be exceptional circumstances where RRooms and / or the
              Service Providers may be unable to honor the confirmed bookings
              due to various reasons like act of God, labor unrest, insolvency,
              business exigencies, government decisions, terrorist activity, any
              operational and technical issues, route and flight cancellations
              etc. or any other reason beyond the control of RRooms. If RRooms
              has advance knowledge of any such situations where dishonor of
              bookings may happen, it will make its best efforts to provide
              similar alternative to the User or refund the booking amount after
              deducting applicable service charges, if supported and refunded by
              that respective service operators. The User agrees that RRooms
              being merely a facilitator of the services and products booked,
              cannot be held responsible for any such Force Majeure
              circumstance. The User has to contact the Service Provider
              directly for any further resolutions and refunds.
            </li>
            <li>
              The User agrees that in the event of non-confirmation of booking
              due to any technical reasons (like network downtime, disconnection
              with third party platforms such as payment gateways, banks etc.)
              or any other similar failures, RRooms’s obligation shall be
              limited refunding the booking amount, if any, received from the
              customer. Such refund shall completely discharge RRooms from all
              liabilities with respect to that transaction. Additional
              liabilities, if any, shall be borne by the User.
            </li>
            <li>
              In no event shall RRooms and be liable for any direct, indirect,
              punitive, incidental, special or consequential damages, and any
              other damages like damages for loss of use, data or profits,
              arising out of or in any way connected with the use or performance
              of the Website/App or any other Sales Channel.
            </li>
          </ol>
          <h2>ADVERTISERS ON RRooms, AND LINKED WEBSITE/APPS</h2>
          <ol className="bulletNotHide">
            <li>
              The Website/App may contain links to third party Website/Apps.
              RRooms does not control such Website/Apps and is not responsible
              for its contents. If a User accesses any third-party Website/App,
              the same shall be done entirely at the User’s risk and RRooms
              shall assume no liability for the same.
            </li>
            <li>
              RRooms is not responsible for any errors, omissions or
              representations on any of its pages, links or any linked
              Website/App pages to the extent such information is updated or
              provided directly by the Service Providers or the advertisers.
            </li>
            <li>
              RRooms may endorse any advertisers on its Website/App. The Users
              are requested to verify the accuracy of all information provided
              on the third-party web pages.
            </li>
            <li>
              The linked sites are not under the control of RRooms and hence
              RRooms is not responsible for the contents of any linked site(s)
              or any further links on such site(s), or any changes or updates to
              such sites. RRooms is providing these links to the Users only as a
              convenience.
            </li>
          </ol>
          <h2>RIGHT TO REFUSE</h2>
          <ol className="bulletNotHide">
            <li>
              RRooms at its sole discretion reserves the right to not accept any
              booking without assigning any reason thereof.
            </li>
            <li>
              RRooms will not provide any service or share confirmed booking
              details till such time the complete consideration is received from
              the User.
            </li>
            <li>
              In addition to other remedies and recourse available to RRooms
              under this User Agreement or under applicable law, RRooms may
              limit the User's activity, warn other
            </li>
            <li>
              users of the User's actions, immediately suspend or terminate the
              User's registration, or refuse to provide the User with access to
              the Website/App if:
            </li>
            <li>
              The User is in breach of this User Agreement; or RRooms is unable
              to verify or authenticate any information provided by the User; or
            </li>
            <li>
              RRooms believes that the User's actions may infringe on any
              third-party rights or breach any applicable law or otherwise
              result in any liability for the User, other users of RRooms, or
              RRooms itself.
            </li>
            <li>
              Once a User has been suspended or terminated, such User shall not
              register or attempt to register with RRooms with different
              credentials, or use the Website/App in any manner whatsoever until
              such User is reinstated by RRooms. RRooms may at any time in its
              sole discretion reinstate suspended users.
            </li>
            <li>
              If a User breaches this User Agreement, RRooms reserves the right
              to recover any amounts due to be paid by the User to RRooms, and
              to take appropriate legal action as it deems necessary.
            </li>
            <li>
              The User shall not write or send any content to RRooms which is,
              or communicate with RRooms using language or content which is:
            </li>
            <li>
              abusive, threatening, offensive, defamatory, coercive, obscene,
              belligerent, glorifying violence, vulgar, sexually explicit,
              pornographic, illicit or otherwise objectionable;
            </li>
            <li>contrary to any applicable law;</li>
            <li>
              violates third parties' intellectual property rights; a spam; or
              in breach of any other part of these terms and conditions of use.
            </li>
            <li>
              If the User violates any of the aforesaid terms, RRooms shall be
              at liberty to take appropriate legal action against the User.
            </li>
          </ol>
          <h2>RIGHT TO CANCEL</h2>
          <ol className="bulletNotHide">
            <li>
              The User expressly undertakes to provide RRooms with correct and
              valid information while making use of the Website/App under this
              User Agreement, and not to make any misrepresentation of facts.
              Any default on part of the User would disentitle the User from
              availing the services from RRooms.
            </li>
            <li>
              In case RRooms discovers or has reasons to believe at any time
              during or after receiving a request for services from the User
              that the request for services is either unauthorized or the
              information provided by the User or any of the travelers is not
              correct or that any fact has been misrepresented by that User,
              RRooms shall be entitled to appropriate legal remedies against the
              User, including cancellation of the hotel bookings, without any
              prior intimation to the User. In such an event, RRooms shall not
              be responsible or liable for any loss or damage that may be caused
              to the User or any other person in the booking, as a consequence
              of such cancellation of booking or services.
            </li>
            <li>
              If any judicial, quasi-judicial, investigation agency, government
              authority approaches RRooms to cancel any booking, RRooms will
              cancel the same without approaching the concerned User whose
              booking has been cancelled.
            </li>
            <li>
              The User shall not hold RRooms responsible for any loss or damage
              arising out of measures taken by RRooms for safeguarding its own
              interest and that of its genuine customers. This would also
              include RRooms denying or cancelling any hotel bookings on account
              of suspected fraud transactions.
            </li>
          </ol>
          <h2>
            FAKE CALLS AND OTHER SIMILAR PHISHING, SPAMMING OR FRAUDULENT
            ACTIVITIES
          </h2>
          <ol className="bulletNotHide">
            <li>
              RRooms’s employees or authorized representatives will never
              contact a User asking for his/ her credit or debit card number,
              expiry date, CVV, net banking login, passwords, OTP etc. nor will
              they ever request for a fund transfer to a personal or an
              individual bank account. Further, they will also not ask a User to
              install any third-party applications that enable them to view a
              User’s mobile or computer screen.
            </li>
            <li>
              Acting on any of these requests may make you a victim of fraud,
              and may potentially lead to loss of your valuable money or
              information.
            </li>
            <li>
              If you are ever asked for any of the aforesaid information, please
              report it immediately on riskcontrol@go-mmt.com.
            </li>
          </ol>
          <h2>INDEMNIFICATION</h2>
          <ol className="bulletNotHide">
            <li>
              The User agrees to indemnify, defend and hold harmless RRooms, its
              affiliates and their respective officers, directors, lawful
              successors and assigns from and against any and all losses,
              liabilities, claims, damages, costs and expenses (including legal
              fees and disbursements in connection therewith and interest
              chargeable thereon) asserted against or incurred by such
              indemnified persons, that arise out of, result from, or may be
              payable by virtue of, any breach of any representation or warranty
              provided by the User, or non-performance of any covenant by the
              User.
            </li>
            <li>
              The User shall be solely liable for any breach of any country
              specific rules and regulations or general code of conduct and
              RRooms cannot be held responsible for the same.
            </li>
          </ol>
          <h2>COVID-19 related terms & conditions:</h2>
          <ol className="bulletNotHide">
            <li>
              User agrees that due to recent outbreak of COVID-19 pandemic,
              which has been declared as a national disaster by the Government
              of India, there may be instances, where the end service provider
              either cancels the booking or refuses to provide the services, as
              agreed with the User, by assigning or not assigning any specific
              reason for such refusal. Refusal of service may be due to the
              reasons including but not limited to the User:
              <ol className="bulletNotHide">
                <li>exhibiting any symptoms associated with COVID 19.</li>
                <li>
                  refusing to comply with safety advisories, like wearing
                  protective gear, complying with social distancing norms etc.
                </li>
                <li>
                  suffering of any ailment making the User vulnerable to the
                  virus
                </li>
                <li>posing a threat to the health and safety of others</li>
                <li>not fulfilling Coivd-19 vaccination criteria</li>
              </ol>
              <p>
                In such cases RROOMS will assume no liability for the refusal of
                service by the end service provider. The User also agrees that
                refund, if any, for such bookings will be processed by RROOMS to
                the User subject to receipt of the same from the end service
                provider.
              </p>
            </li>
            {/* <ol className="bulletNotHide"> */}
            <li>
              User further understands that due to the outbreak of COVID-19, the
              Central Government through its ministries, State Governments and
              statutory bodies have/may come up with detailed set of guidelines
              or standard operating procedure (SOP) that a traveler will have to
              follow in order to undertake the stay/travel.
            </li>
            <li>
              The User agrees to abide with all such guidelines/SOP while
              undertaking any stay/travel. Failure to abide by such detailed set
              of guidelines/SOP, may lead to a situation where the service
              provider or any person authorized by the Central Government, State
              Government or statutory body may disallow the User to undertake
              the stay/travel. In such circumstances, RROOMS will not be held
              liable for such refusal to stay and refund, if any, shall be
              strictly as per the terms of the service provider.
            </li>
            <li>
              User understands that the Central Government, State Governments
              and various other bodies, may mandate providing Covid -19
              vaccination status and certificate, downloading of Arogya Setu App
              or submitting a self- declaration form prior to entering our
              partnered hotels & a transit point like airport, railway station
              etc. or at the time of checking-in for a flight, into a hotel etc.
              Accordingly, the User must ensure downloading of Arogya Setu App
              on their smartphones or provide any other alternate such as
              self-declaration when demanded.
            </li>
            <li>
              User undertakes that all bookings are subject to certain minimum
              medical wellbeing criteria specified by the Govt. / appropriate
              authority from time to time including submitting Vaccination
              status and certificate, negative RT-PCR report or any other
              stay/travel related guideline, especially in case of inbound
              stay/travel. In this regard the User may be required to furnish a
              medical proof or health declaration in a applicable format.
            </li>
          </ol>
          <h2>COVID-19 related terms & condition</h2>
          <ol className="bulletNotHide">
            <li>
              User agrees that due to recent outbreak of COVID-19 pandemic,
              which has been declared as a national disaster by the Government
              of India, there may be instances, where the end service provider
              either cancels the booking or refuses to provide the services, as
              agreed with the User, by assigning or not assigning any specific
              reason for such refusal. Refusal of service may be due to the
              reasons including but not limited to the User:
              <ol className="bulletNotHide">
                <li>exhibiting any symptoms associated with COVID 19.</li>
                <li>
                  refusing to comply with safety advisories, like wearing
                  protective gear, complying with social distancing norms etc.
                </li>
                <li>
                  suffering of any ailment making the User vulnerable to the
                  virus
                </li>
                <li>posing a threat to the health and safety of others</li>
              </ol>
              <p>
                submitting a self- declaration form prior to entering a transit
                point like airport, railway station etc. or at the time of
                checking-in for a flight, into a hotel etc. Accordingly, the
                User must ensure downloading of Arogya Setu App on their
                smartphones or provide any other alternate such as
                self-declaration when demanded.
              </p>
            </li>
            <li>
              User further understands that due to the outbreak of COVID-19, the
              Central Government through its ministries, State Governments and
              statutory bodies have/may come up with detailed set of guidelines
              or standard operating procedure (SOP) that a traveler will have to
              follow in order to undertake the travel.
            </li>
            <li>
              The User agrees to abide with all such guidelines/SOP while
              undertaking any travel. Failure to abide by such detailed set of
              guidelines/SOP, may lead to a situation where the service provider
              or any person authorized by the Central Government, State
              Government or statutory body may disallow the User to undertake
              the travel. In such circumstances, RRooms will not be held liable
              for such refusal to travel and refund, if any, shall be strictly
              as per the terms of the service provider.
            </li>
            <li>
              User understands that the Central Government, State Governments
              and various other bodies, may mandate downloading of Arogya Setu
              App or submitting a self- declaration form prior to entering a
              transit point like airport, railway station etc. or at the time of
              checking-in for a flight, into a hotel etc. Accordingly, the User
              must ensure downloading of Arogya Setu App on their smartphones or
              provide any other alternate such as self-declaration when
              demanded.
            </li>
            <li>
              User undertakes that all bookings are subject to certain minimum
              medical wellbeing criteria specified by the Govt. / appropriate
              authority from time to time. User should have no medical history
              of the COVID-19 virus 28 days before the date of scheduled travel,
              especially in case of inbound travel. In this regard the User may
              be required to furnish a medical proof or health declaration in an
              applicable format.
            </li>
          </ol>
          <h2>UPI related terms & conditions</h2>
          <ol className="bulletNotHide">
            <li>
              RRooms acts as a Third Party Application Provider (TPAP) under UPI
            </li>
            <li>
              RRooms is a TPAP authorized by NPCI to facilitate payments through
              PSP Bank(s). For this purpose ICICI Bank shall act as the PSP.
              RRooms is a service provider and participates in UPI through the
              PSP Bank.
            </li>
            <li>
              Through the agreement entered into between RRooms, ICICI and NPCI,
              RRooms shall facilitate grievances, complaints and provide
              resolution to the customers using our UPI application.
            </li>
            <li>
              RRooms shall act as the first point of contact for all UPI related
              grievances/ complaints for its UPI customers. In the event the
              complaint or grievance remains unresolved, the User may escalate
              the issue to ICICI Bank, followed by the User’s bank (linked to
              the UPI account) and NPCI thereafter, in the same order. If the
              User is still dissatisfied, the User may approach the Banking
              Ombudsman and / or the Ombudsman for Digital Complaints, as the
              case may be.
            </li>
            <li>
              FAQS
              <h2>What is NPCI?</h2>
              <p>
                National Payments Corporation of India (NPCI) is an authorized
                payment system operator by RBI. NPCI owns and operates the UPI
                payment system.
              </p>
              <h2>What is PSP bank?</h2>
              <p>
                Payment Service Provider (PSP) is the banking company authorized
                to act as a service provider under the UPI framework. PSP
                engages the Third Party Application Provider (TPAP) to provide
                UPI services to the end-user customers.
              </p>
              <h2>What is TPAPs?</h2>
              <p>
                Third Party Application Provider (TPAP) is an entity that
                provides the UPI compliant app(s) to the end-user customers to
                facilitate UPI based payment transactions
              </p>
              <h2>What is Customer’s Bank?</h2>
              <p>
                The Bank where the end-user customer maintains his/her account
                that has been linked for the purpose of debiting/crediting the
                payment transactions made through UPI.
              </p>
              <h2>Who is End User Customer?</h2>
              <p>
                The end-user customer is the individual who uses UPI payment
                facility to send and receive payments.
              </p>
            </li>
          </ol>
          <h1>General Terms pertaining to the use of UPI</h1>
          <ol className="bulletNotHide">
            <li>
              <h2>Roles & Responsibilities of NPCI</h2>
              <ol className="bulletNotHide">
                <li>
                  NPCI owns and operates the Unified Payments Interface (UPI)
                  platform
                </li>
                <li>
                  NPCI prescribes rules, regulations, guidelines, and the
                  respective roles, responsibilities and liabilities of the
                  participants, with respect to UPI. This also includes
                  transaction processing and settlement, dispute management and
                  clearing cut-offs for settlement
                </li>
                <li>
                  NPCI approves the participation of Issuer Banks, PSP Banks,
                  Third Party Application Providers (TPAP) and Prepaid Payment
                  Instrument issuers (PPIs) in UPI
                </li>
                <li>
                  NPCI provides a safe, secure and efficient UPI system and
                  network
                </li>
                <li>
                  NPCI provides online transaction routing, processing and
                  settlement services to members participating in UPI
                </li>
                <li>
                  NPCI can, either directly or through a third party, conduct
                  audit on UPI participants and call for data, information and
                  records, in relation to their participation in UPI
                </li>
                <li>
                  NPCI provides the banks participating in UPI access to system
                  where they can download reports, raise chargebacks, update the
                  status of UPI transactions etc.
                </li>
              </ol>
            </li>
            <li>
              <h2>Roles & responsibilities of PSP Bank</h2>
              <ol className="bulletNotHide">
                <li>
                  PSP Bank is a member of UPI and connects to the UPI platform
                  for availing UPI payment facility and providing the same to
                  the TPAP which in turn enables the end-user customers /
                  merchants to make and accept UPI payments
                </li>
                <li>
                  PSP Bank, either through its own app or TPAP’s app, on-boards
                  and registers the end-user customers on UPI and links their
                  bank accounts to their respective UPI ID.
                </li>
                <li>
                  PSP Bank is responsible for authentication of the end-user
                  customer at the time of registration of such customer, either
                  through its own app or TPAP’s app
                </li>
                <li>
                  PSP Bank engages and on-boards the TPAPs to make the TPAP’s
                  UPI app available to the end-user customers
                </li>
                <li>
                  PSP Bank has to ensure that TPAP and its systems are
                  adequately secure to function on UPI platform
                </li>
                <li>
                  PSP Bank is responsible to ensure that UPI app and systems of
                  TPAP are audited to safeguard security and integrity of the
                  data and information of the end-user customer including UPI
                  transaction data as well as UPI app security
                </li>
                <li>
                  PSP Bank has to store all the payments data including UPI
                  Transaction Data collected for the purpose of facilitating UPI
                  transactions, only in India
                </li>
                <li>
                  PSP Bank is responsible to give all UPI customers an option to
                  choose any bank account from the list of Banks available on
                  UPI platform for linking with the customer’s UPI ID.
                </li>
                <li>
                  PSP Bank is responsible to put in place a grievance redressal
                  mechanism for resolving complaints and disputes raised by the
                  end-user customer
                </li>
              </ol>
            </li>

            <li>
              <h2>Roles & responsibilities of TPAP</h2>
              <ol className="bulletNotHide">
                <li>
                  TPAP is a service provider and participates in UPI through PSP
                  Bank
                </li>
                <li>
                  TPAP is responsible to comply with all the requirements
                  prescribed by PSP Bank and NPCI in relation to TPAP’s
                  participation in UPI
                </li>
                <li>
                  TPAP is responsible to ensure that its systems are adequately
                  secure to function on the UPI platform
                </li>
                <li>
                  TPAP is responsible to comply with all applicable laws, rules,
                  regulations and guidelines etc. prescribed by any statutory or
                  regulatory authority in relation to UPI and TPAP’s
                  participation on the UPI platform including all circulars and
                  guidelines issued by NPCI in this regard
                </li>
                <li>
                  TPAP has to store all the payments data including UPI
                  Transaction Data collected by TPAP for the purpose of
                  facilitating UPI transactions, only in India
                </li>
                <li>
                  TPAP is responsible to facilitate RBI, NPCI and other agencies
                  nominated by RBI/ NPCI, to access the data, information,
                  systems of TPAP related to UPI and carry out audits of TPAP,
                  as and when required by RBI and NPCI
                </li>
                <li>
                  TPAP shall facilitate the end-user customer with an option to
                  raise grievance through the TPAP’s grievance redressal
                  facility made available through TPAP’s UPI app or Website/App
                  and such other channels as may be deemed appropriate by the
                  TPAP like email, messaging platform, IVR etc.
                </li>
              </ol>
            </li>
            <li>
              <h2>Dispute Redressal Mechanism</h2>
              <ol className="bulletNotHide">
                <li>
                  Every end-user customer can raise a complaint with respect to
                  a UPI transaction, on the PSP app / TPAP app/NPCI using the
                  following links:
                </li>
                <li>
                  NPCI Link
                  https://www.npci.org.in/what-we-do/upi/dispute-redressal-mechanism
                </li>
                <li>
                  End-user customer can select the relevant UPI transaction and
                  raise a complaint in relation thereto
                </li>
                <li>
                  A complaint shall be first raised with the relevant TPAP in
                  respect to all UPI related grievances / complaints of the
                  end-user customers on-boarded by the PSP Bank / TPAP (if the
                  UPI transaction is made through TPAP app). In case the
                  complaint / grievance remains unresolved, the next level for
                  escalation will be the PSP Bank, followed by the bank (where
                  the end-user customer maintains its account) and NPCI, in the
                  same order. After exercising these options, the end-user
                  customer can approach the Banking Ombudsman and / or the
                  Ombudsman for Digital Complaints, as the case may be.
                </li>
                <li>
                  The complaint can be raised for both the types of transactions
                  i.e. fund transfer and merchant transactions
                </li>
                <li>
                  The end-user customer shall be kept communicated by the PSP /
                  TPAP by means of updating the status of such end-user
                  customer’s complaint on the relevant app itself.
                </li>
              </ol>
            </li>
          </ol>
          <h1>MISCLLEANEOUS</h1>
          <ol className="bulletNotHide">
            <li>
              <strong>SEVERABILITY:</strong> If any provision of this User
              Agreement is determined to be invalid or unenforceable in whole or
              in part, such invalidity or unenforceability shall attach only to
              such provision or part of such provision and the remaining part of
              such provision and all other provisions of this User Agreement
              shall continue to be in full force and effect.
            </li>
            <li>
              <strong>JURISDICTION:</strong> This Agreement is subject to
              interpretation as per the laws of India, and the parties shall
              refer any unresolved disputes to the exclusive jurisdiction of
              courts in Delhi.
            </li>
            <li>
              <strong>AMENDMENT TO THE USER AGREEMENT:</strong> RRooms reserves
              the right to change the User Agreement from time to time. The User
              is responsible for regularly reviewing the User Agreement.
            </li>
            <li>
              <strong>CONFIDENTIALITY:</strong> Any information which is
              specifically mentioned by RRooms as confidential shall be
              maintained confidentially by the User and shall not be disclosed
              unless as required by law or to serve the purpose of this User
              Agreement and the obligations of both the parties herein.
            </li>
            <li>
              <strong>FEEDBACK FROM CUSTOMER:</strong> RRooms would like to know
              the feedback of the Users for improving its services. The User
              hereby authorizes RRooms to contact the User for their feedback on
              various services offered by RRooms. Such feedback may be collected
              through emails, telephone calls, SMS or any other medium from time
              to time. In case the User chooses not to be contacted, such User
              shall write to RRooms for specific exclusion at
              privacy@go-mmt.com.
            </li>
            <li>
              <strong>PRIVACY POLICY:</strong> User shall also refer to RRooms’s
              Privacy Policy available on RRooms’s Website/App which governs use
              of the Website/Apps. By using the Website/App, User agrees to the
              terms of the Privacy Policy and accordingly consents to the use of
              the User’s personal information by RRooms and its affiliates in
              accordance with the terms of the Privacy Policy.
            </li>
          </ol>
        </div>
      </section>
      <FrontFooter />
    </div>
  );
}

export default UserAgreement;
