import React, { useEffect } from "react";
import { Imagesdata } from "../../assets/images/imageData";
import FrontFooter from "../../layout/footer/FrontFooter";
import LandingHeader from "../../components/landingHeader";

const Bookingcancel = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { GooglePlay, ApplePlay, ScanCode, sliderImgOnly, mapIcon } =
    Imagesdata || {};

  return (
    <div className="hotalList">
      {/* landing page header */}
      {/* <div className="headerLanding">
        <div className="headerTop">
          <div className="container flex">
            <figure>
              <a href="/">
                <img alt=""src={Logolandingpage} alt="" />
              </a>
            </figure>
            <ul className="flex">
              <li>
                <figure>
                  <img alt=""src={HotelIcon} alt="" />
                </figure>
                <div className="content">
                  <p>Become a Member</p>
                  <a href="">List your property</a>
                </div>
              </li>
              <li>
                <figure>
                  <img alt=""src={HotelIcon} alt="" />
                </figure>
                <div className="content">
                  <p>RRooms for Business</p>
                  <a href="">Corporate booking </a>
                </div>
              </li>
              <li>
                <figure>
                  <img alt=""src={LanguageIcon} alt="" />
                </figure>
                <div className="content">
                  <p>English</p>
                  <select>
                    <option>Select your language</option>
                    <option>English</option>
                    <option>French</option>
                    <option>Spanish</option>
                  </select>
                </div>
              </li>
              <li>
                <Link className="loginButton" to="/login">
                  <img alt=""src={UserLoginIcon} alt="" /> Login / Signup
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <LandingHeader />

      {/* banner section */}
      <div className="homeBanner"></div>

      {/* banner form */}
      {/* <div className="banner-form">
        <div className="wrapper">
          <form className="flex">
            <div className="form-group">
              <input type="search" placeholder="Search by city, locality" />
              <span>
                Find places near me <img alt=""src={forwardIconWhite} alt="" />
              </span>
            </div>
            <div className="form-group">
              <input type="date" placeholder="Tue, 17 Mar" />
              <img alt=""src={ArrowIcon} alt="" />
              <input type="date" placeholder="wed, 18 Mar" />
            </div>
            <div className="form-group">
              <select>
                <option>2 Adults, 2 Kids, 1 Room</option>
                <option>2 Adults, 1 Kids, 2 Room</option>
                <option>1 Adults, 2 Kids, 2 Room</option>
              </select>
            </div>
            <div className="form-group">
              <button type="button">Search</button>
            </div>
          </form>
        </div>
      </div> */}

      {/* booking confirm section start here */}
      <div className="bookConfirm">
        <div className="container">
          <div className="heading flex">
            <div className="content">
              <h2 className="red">Your booking has been cancelled</h2>
            </div>
            {/* <button type="button" className="printBtn">Print now</button> */}
          </div>
          <div className="wrap">
            <div className="book flex">
              <div className="col-left">
                <h4>Booking ID</h4>
                <p>H2TW2386</p>
              </div>
              <div className="col-right">
                <p>
                  <span>Booked by Raj Kamal on</span> <br />
                  Sat, 1 Apr 2023
                </p>
              </div>
            </div>
            <div className="product flex">
              <div className="col-left">
                <h4>Flagship Vibhu Residency Near Noida Sector 81</h4>
                <h4>Flagship Vibhu</h4>
                <a href="" className="address">
                  <img alt="" src={mapIcon} /> Salar Pur Bhangel Bhangel Near
                  Hdfc Bank, Noida
                </a>
                <h4>Hotel direction</h4>
                <p>
                  <span>Landmark:</span> Near Hdfc Bank, Noida
                </p>
              </div>
              <div className="col-right">
                <figure>
                  <img alt="" src={sliderImgOnly} />
                </figure>
              </div>
            </div>
            <ul className="details flex">
              <li>
                <p>Raj Kamal Sharma</p>
                <p>Primary guest</p>
              </li>
              <li>
                <p>01-04-2023</p>
                <p>Check in</p>
              </li>
              <li>
                <p>12:00 PM</p>
                <p>Check in Time</p>
              </li>
              <li>
                <p>1 Night</p>
              </li>
              <li>
                <p>7042662801</p>
                <p>Mobile Number</p>
              </li>
              <li>
                <p>Mobile Number</p>
                <p>Check out</p>
              </li>
              <li>
                <p>11:00 AM</p>
                <p>Check out Time</p>
              </li>
              <li>
                <p>02</p>
                <p>Guest</p>
              </li>
              <li>
                <p>rajkamal.sharma.developer@gmail.com</p>
                <p>Email address</p>
              </li>
              <li>
                <p>Deluxe</p>
                <p>Room type</p>
              </li>
            </ul>
            <div className="payments">
              <h4>Payment details</h4>
              <div className="inner flex">
                <p>Total payable amount</p>
                <p>₹ 20,000</p>
              </div>
            </div>
            <ul className="think-section flex">
              <h4>Thinks to know</h4>
              <li>
                <p>Somthing not right chat with us for help</p>
                <a href="#">Need help?</a>
              </li>
              <li>
                <p>RRooms Term and Condition</p>
                <a href="#">Read more</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* popup start here */}
      <div className="popup-second">
        <div className="wraps">
          <div className="closeBtn">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <h2>Transfer wallet money</h2>
          <form>
            <div className="form_group">
              <input type="text" placeholder="Cosutomer id" />
            </div>
            <div className="form_group">
              <input type="text" placeholder="Mobile Number" />
            </div>
            <div className="form_group">
              <input type="text" placeholder="₹ Transfer amount" />
            </div>
            <div className="form_group">
              <button type="submit">Transfer</button>
            </div>
          </form>
        </div>
      </div>
      {/* popup end here */}

      {/* landing page footer */}
      <FrontFooter />
    </div>
  );
};

export default Bookingcancel;
